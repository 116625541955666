/**
 * ******************************************
 * Designed to be used with compoenents/Page/AppBarTitleSection.tsx
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React, { useMemo, useState } from 'react'

// MUI
import { makeStyles, Theme } from '@material-ui/core/styles'

// Mobx
import { Observer } from 'mobx-react'

// Util
import { useStores } from 'config/store'

// My component
import Select from 'components/AppBar/Select'
import UserAccountSelectorModal from 'components/MVP/Navigation/UserAccountSelectorModal'
import UserAccountList from 'components/MVP/Navigation/UserAccountList'
import useAccountLabel from 'helpers/hooks/useAccountLabel'

export default function AppBarTitleSectionFilter() {
  const classes = useStyle()

  return (
    <div className={classes.root}>
      <SelectAccountDropDownButton />
    </div>
  )
}

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    padding: '1rem 0 0.3rem',
    gap: '.5rem',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0',
    },
  },
}))

const SelectAccountDropDownButton: React.FC = () => {
  const { appState, accountStore } = useStores();
  const [isOpen, setIsOpen] = useState(false);
  const label = useAccountLabel();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    accountStore.retrieveHidden();
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Observer
        render={() => {
          const { accounts } = appState;
          const { _hiddenAccounts, hiddenAccounts } = accountStore;

          const childAccounts = accounts.filter((account: any) => account.children.length === 0);
          const pendingSelectedAccounts = childAccounts.filter((account: any) => !_hiddenAccounts.includes(account.id));

          const modalTitle = useMemo(() => {
            const _selectedCount = pendingSelectedAccounts.length;
            if (_selectedCount === 0) {
              return 'No accounts selected';
            }
            if (_selectedCount === 1) {
              return pendingSelectedAccounts[0].name;
            }
            return `Viewing ${_selectedCount}/${childAccounts.length} Accounts`;
          }, [pendingSelectedAccounts]);

          const handleApplyCallback = () => {
            if (!pendingSelectedAccounts.length) {
              alert('Please select one or more accounts');
            } else {
              accountStore.applyHidden();
              setIsOpen(false);
            }
          };

          return (
            <>
              <Select label={label} onClick={handleClick} isOpened={isOpen} />
              <UserAccountSelectorModal
                title={modalTitle}
                isOpen={isOpen}
                onClose={handleClose}
                applyCallback={handleApplyCallback}
              >
                <UserAccountList />
              </UserAccountSelectorModal>
            </>
          );
        }}
      />
    </>
  );
};