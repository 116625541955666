import React from 'react'

import { KPICardContainer, KPIGradeEvaluator, KPIValues } from '../KPICard'
import { LinearProgress } from 'components/CallPro/LinearProgress'

import { useStyles } from './AskedForSource.styles'
import { useStores } from 'config/store'
import { formatNumber } from 'helpers/utility'
import { ProTip } from '../ProTip'

import ProTipImage from '../../../../assets/img/call-pro/pro-tips/front-desk-pro-tip-refferal-source.png'
import GradeToolTipValue from '../GradeToolTipValue'
import { getGradeName } from '../Utils'
import SeeIndividualMetric from './SeeIndividualMetric/SeeIndividualMetric'

interface AskedForSourceInterface {
  isAccordionContent?: boolean
}

const AskedForSource = (props: AskedForSourceInterface) => {
  const classes = useStyles()
  const { isAccordionContent } = props
  const { frontDeskStore } = useStores()
  const { officeHandlingRate } = frontDeskStore

  const total = officeHandlingRate.validAskedForSource
  const askedPercentage = (officeHandlingRate.askedForSourceCount / total) * 100
  const didNotAskedPercentage =
    ((total - officeHandlingRate.askedForSourceCount) / total) * 100

  const grading = [
    {
      name: 'A',
      min: 86,
      max: undefined,
    },
    {
      name: 'B',
      min: 70,
      max: 85,
    },
    {
      name: 'C',
      min: 60,
      max: 69,
    },
    {
      name: 'D',
      min: 0,
      max: 59,
    },
  ]

  return (
    <KPICardContainer
      isAccordionContent={isAccordionContent}
      title="ASKED FOR SOURCE"
      tooltip="The rate at which staff asks for the caller’s referral source during opportunity calls. Excludes calls where the caller provided the referral source upfront."
    >
      <KPIGradeEvaluator
        isAccordionContent={isAccordionContent}
        value={askedPercentage}
        total={formatNumber(officeHandlingRate.validAskedForSource)}
        totalText="Calls"
        grading={grading}
      />
      <div className={classes.progressWrapper}>
        <LinearProgress
          hideProgressDetails={true}
          value={officeHandlingRate.askedForSourceCount}
          total={total}
        />
      </div>
      <KPIValues
        isAccordionContent={isAccordionContent}
        data={[
          {
            percentage: askedPercentage,
            label: 'Asked',
            color: '#4498E7',
            details: officeHandlingRate.askedForSourceCount,
          },
          {
            percentage: didNotAskedPercentage,
            label: 'Did not asked',
            color: '#d4d4d4',
            details: total - officeHandlingRate.askedForSourceCount,
          },
        ]}
      />
      <SeeIndividualMetric />
      <ProTip
        title="Get referral source"
        content={GradeToolTipValue(
          'Asked For Source',
          getGradeName(grading, askedPercentage)
        )}
        image={ProTipImage}
      />
    </KPICardContainer>
  )
}

export default AskedForSource
