import React from 'react'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

// components
import Leaderboard from './Leaderboard'
import StaffProfile from '../FrontDesk/StaffProfile'
import OfficeHandlingChart from '../FrontDesk/OfficeHandlingChart'
import ConversionRateChart from '../FrontDesk/ConversionRateChart'
import { CallOnHoldMetricsChart } from './CallOnHoldMetrics'

// STYLES
import { useFrontDeskStyles } from './FrontDesk.styles'
import { KPIChart, StaffInterface } from 'interfaces/Call.interface'
import { Theme, useMediaQuery } from '@material-ui/core'
import { MobileAccordion } from 'components/MobileAccordion'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'
import { StaffPlacedCallerOnHold } from './CallOnHoldMetrics/StaffPlacedCallerOnHold'
import { useIsEmptyChart } from './Utils'
import StaffAskedToPlaceCallerOnHold from './CallOnHoldMetrics/StaffAskedToPlaceCallerOnHold/StaffAskedToPlaceCallerOnHold'
import { HoldTime } from './CallOnHoldMetrics/HoldTIme'
import { AskedForAppointment } from './AskedForAppointment'
import { AskedForSource } from './AskedForSource'
import { AskedForAdditionalAppointment } from './AskedForAdditionalAppointment'
import { CallerInteractedWithIVR } from './CallerInteractedWithIVR'
import { useStores } from 'config/store'
import { PricingRelatedAnswers } from './PricingRelatedAnswers'
import classNames from 'classnames'
import SideDrawerContainer from '../SideDrawer/CallDetailsDrawer/CallDetailsDrawer'
import _ from 'lodash'

const FrontDesk = () => {
  const classes = useFrontDeskStyles()
  const [tabValue, setTabValue] = React.useState(0)
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )
  const { frontDeskStore } = useStores()
  const { pricingRelatedAnswers } = frontDeskStore

  const emptyAskedForAppointment = useIsEmptyChart(
    KPIChart.ASKED_FOR_APPOINTMENT
  )
  const emptyAskedForSource = useIsEmptyChart(KPIChart.ASKED_FOR_SOURCE)
  const emptyAskedForAdditionalAppointments = useIsEmptyChart(
    KPIChart.ASKED_FOR_ADDITIONAL_APPOINTMENTS
  )
  const emptyCallerInteractedWithIVR = useIsEmptyChart(
    KPIChart.CALLER_INTERACTED_WITH_IVR
  )

  const [selectedStaff, setSelectedStaff] = React.useState<StaffInterface>(
    {} as StaffInterface
  )

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  if (mobileDevice) {
    return (
      <>
        <div
          className={classNames(
            classes.leaderboard_container,
            classes.leaderboardMobile
          )}
        >
          <div className={classes.leaderboard_table}>
            <Leaderboard
              selectedStaff={selectedStaff}
              setSelectedStaff={setSelectedStaff}
            />
          </div>
          {mobileDevice ? (
            <SideDrawerContainer
              title=""
              drawerOpen={!_.isEmpty(selectedStaff)}
              handleCloseDrawer={() => {
                setSelectedStaff({} as StaffInterface)
              }}
            >
              <StaffProfile selectedStaff={selectedStaff} />
            </SideDrawerContainer>
          ) : (
            <div className={classes.leaderboard_staff}>
              <StaffProfile selectedStaff={selectedStaff} />
            </div>
          )}
        </div>
        <div className={classes.cardWrapper}>
          <ConversionRateChart />
        </div>

        <div className={classes.accordionWrapper}>
          <MobileAccordion header={true} title="Call On Hold Metrics" />
          <MobileAccordion title="Staff Placed Caller On Hold">
            <StaffPlacedCallerOnHold isAccordionContent={true} />
          </MobileAccordion>
          <MobileAccordion title="Staff Asked To Place On Hold">
            <StaffAskedToPlaceCallerOnHold isAccordionContent={true} />
          </MobileAccordion>
          <MobileAccordion title="Hold Time">
            <HoldTime isAccordionContent={true} />
          </MobileAccordion>
        </div>
        <div className={classes.accordionWrapper}>
          <MobileAccordion header={true} title="Front Desk KPI (All Staff)" />
          <MobileAccordion title="Staff Asked For Appointment">
            {!emptyAskedForAppointment ? (
              <AskedForAppointment isAccordionContent={true} />
            ) : (
              <EmptyBarChart />
            )}
          </MobileAccordion>
          <MobileAccordion title="Staff Asked For Source">
            {!emptyAskedForSource ? (
              <AskedForSource isAccordionContent={true} />
            ) : (
              <EmptyBarChart />
            )}
          </MobileAccordion>
          <MobileAccordion title="Staff Asked For Additional Appointment">
            {!emptyAskedForAdditionalAppointments ? (
              <AskedForAdditionalAppointment isAccordionContent={true} />
            ) : (
              <EmptyBarChart />
            )}
          </MobileAccordion>
          {!emptyCallerInteractedWithIVR ? (
            <MobileAccordion title="Caller Interacted With IVR">
              <CallerInteractedWithIVR isAccordionContent={true} />
            </MobileAccordion>
          ) : null}
          <MobileAccordion title="Pricing Related Answers">
            {pricingRelatedAnswers.length > 0 ? (
              <PricingRelatedAnswers isAccordionContent={true} />
            ) : (
              <EmptyBarChart />
            )}
          </MobileAccordion>
        </div>
      </>
    )
  } else {
    return (
      <>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Leaderboard" className={classes.tab_label} />
        </Tabs>
        <div className={classes.leaderboard_container}>
          <div className={classes.leaderboard_table}>
            <Leaderboard
              selectedStaff={selectedStaff}
              setSelectedStaff={setSelectedStaff}
            />
          </div>

          <div className={classes.leaderboard_staff}>
            <StaffProfile selectedStaff={selectedStaff} />
          </div>
        </div>
        <div className={classes.cardWrapper}>
          <ConversionRateChart />
        </div>
        <div className={classes.cardWrapper}>
          <CallOnHoldMetricsChart />
        </div>
        <div className={classes.cardWrapper}>
          <OfficeHandlingChart />
        </div>
      </>
    )
  }
}

export default FrontDesk
