import React, { ReactNode } from 'react'
import { CardHeader, CardContent } from '@material-ui/core'
import cx from 'classnames'

// Styles
import { useCardStyles, useStyleType } from './Card.styles'

interface CardProps {
  title: NonNullable<React.ReactNode>
  subtitle?: string
  children: ReactNode
  action?: ReactNode
  headerStyle?: object & React.CSSProperties
  classes?: useStyleType
  isAccordionContent?: boolean
}

const Card = (props: CardProps) => {
  const styles = useCardStyles()
  const {
    title,
    subtitle,
    children,
    action,
    headerStyle,
    classes,
    isAccordionContent,
  } = props
  return (
    <div className={cx(styles.root, classes?.root)}>
      <CardHeader
        className={cx(
          styles.header,
          classes?.header,
          isAccordionContent ? styles.accordionContentHeader : null
        )}
        classes={{ action: styles.headerAction }}
        title={title}
        subheader={subtitle}
        action={action}
        style={headerStyle}
      />
      <CardContent
        classes={{
          root: cx(
            styles.content,
            classes?.content,
            isAccordionContent ? styles.accordionContent : null
          ),
        }}
      >
        {children}
      </CardContent>
    </div>
  )
}

export default Card
