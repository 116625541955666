import { observable, IReactionDisposer, reaction, action, computed } from 'mobx'
import moment from 'moment-timezone'

// CONFIGS
import { CallConfig } from '../../callpro.config'

// INTERFACES
import { CallDashboardTabs } from 'interfaces/Call.interface'

import productStore from './Product/ProductStore'
import insuranceStore from './Insurance/InsuranceStore'
import offerStore from './Offer/OfferStore'
import frontDeskStore from './FrontDesk/FrontDeskStore'
import callsStore from './Call/CallsStore'
import trackingLinesStore from './TrackingLines/TrackingLinesStore'
import accountStore from 'config/store/AccountStore'
import globalFilterStore from 'config/store/GlobalFilterStore'

export class DashboardUIStore {
  private filtersDisposer: IReactionDisposer | undefined

  @observable currentTabOpened: { index: number; value: CallDashboardTabs } = {
    index: 0,
    value: CallDashboardTabs.OVERVIEW,
  }

  // --- REACTIONS --- //

  makeReactions() {
    if (!this.filtersDisposer) {
      this.filtersDisposer = this.monitorFilters()
    }
  }

  dispose() {
    this.filtersDisposer && this.filtersDisposer()
    this.filtersDisposer = undefined
  }

  /**
   * @todo technical debt
   * We can change this to non-reaction in the future.
   * But for now its more work to refactor this.
   */
  private monitorFilters() {
    return reaction(
      () => [
        accountStore.selectedAccounts.slice(),
        { ...globalFilterStore.dateFilter.dateRange },
        globalFilterStore.campaignFilter.campaignFilterType,
      ],
      () => this.refreshCurrentTab()
    )
  }

  // --- ACTION w/ SIDE EFFECTS - Refresher --- //

  /**
   * If reset = true then reset all filters
   */
  @action
  refreshCurrentTab({ reset = true } = {}) {
    this.refreshTab(this.currentTabOpened.value, { reset })
  }

  /**
   * @param {boolean} param.reset - Will only reset the connected stores and not the global filters
   */
  @action
  refreshTab(tab: CallDashboardTabs, { reset = false } = {}) {
    this.setCurrentTabbedOpened(tab)

    if (tab === CallDashboardTabs.OVERVIEW) {
      this.refreshOverviewTab({ reset })
      return
    }

    if (tab === CallDashboardTabs.FRONT_DESK) {
      this.refreshFronDeskTab({ reset })
      return
    }

    this.refreshAllCallsTab({ reset })
  }

  /**
   * @param {boolean} param.reset - When you want to reset state + do a refresh
   */
  @action
  private refreshOverviewTab({ reset = false }: { reset: boolean }) {
    trackingLinesStore.refresh({ reset })
    frontDeskStore.refreshOverviewTab({ reset })
    offerStore.refreshOverviewTab({ reset })
    productStore.refreshOverviewTab({ reset })
    insuranceStore.refreshOverviewTab({ reset })
    callsStore.refreshOverviewTab({ reset })
  }

  @action
  private refreshFronDeskTab({ reset = false }: { reset: boolean }) {
    frontDeskStore.refreshFrontDeskTab({ reset })
  }

  /**
   * @param {boolean} param.reset - When you want to reset state + do a refresh
   */
  @action
  private refreshAllCallsTab({ reset = false }: { reset: boolean }) {
    frontDeskStore.refreshAllCallsTab({ reset })
    callsStore.refreshAllCallsTab({ reset })
  }

  // --- ACTION w/o SIDE EFFECTS --- //

  @action.bound
  setCurrentTabbedOpened(currentTab: CallDashboardTabs): void {
    this.currentTabOpened.index = CallConfig.tabs.indexOf(currentTab)
    this.currentTabOpened.value = currentTab
  }

  // --- COMPUTED --- //

  @computed
  get globalFiltersQuery() {
    const { dateFilter, campaignFilter } = globalFilterStore
    const { from: dateFrom, to: dateTo } = dateFilter.dateRange

    let newDateFrom: any = moment(dateFrom ?? new Date('January 2018'))
    let newDateTo: any = moment(dateTo ?? new Date())

    newDateFrom = `${
      newDateFrom.format().toString().split('T')[0]
    }T00:00:00+00:00`

    newDateTo = `${newDateTo.format().toString().split('T')[0]}T23:59:59+00:00`

    return {
      ...campaignFilter.campaignTypesByFilterType,
      callDateTime: {
        gt: moment(newDateFrom),
        lt: moment(newDateTo),
      },
    }
  }
}

export default new DashboardUIStore()
