import React, { useState } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { runInAction } from 'mobx'
import { Checkbox, Link, Tooltip } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { useStores } from 'config/store'
import { dateFormat } from 'config/callpro.config'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'
import { CallsStore } from 'config/store/CallPro/Call/CallsStore'

import { LinearProgress } from '../LinearProgress'
import { ChartCard } from 'components/CallPro/ChartCard'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useStyles } from './InaccurateReferralSources.style'
import CallDetailsDrawer from 'components/CallPro/SideDrawer/CallDetailsDrawer/CallDetailsDrawer'
import AllInaccurateReferralSourcesDrawer from '../SideDrawer/AllInaccurateReferralSources/AllInaccurateReferralSourcesDrawer'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'
import _ from 'lodash'

interface InaccurateReferralSourcesInterface {
  isAccordionContent?: boolean
}

type InaccurateReferralSourcesType =
  CallsStore['referralSource']['records'][number]['name']

const InaccurateReferralSources = (
  props: InaccurateReferralSourcesInterface
) => {
  const { isAccordionContent } = props
  const { callsStore, dashboardUIStore } = useStores()
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [showAllCallSources, setShowAllCallSources] = useState(false)

  const { referralSource: _referralSource, loadingStates } = callsStore

  const inaccurateReferralSource = _referralSource.records.filter(
    (item) => item.wrongReferralSource
  )

  const groupedData = _.groupBy(_referralSource.records, 'name')
  const allReferralSource = _.map(groupedData, (items, name) => ({
    name,
    value: _.sumBy(items, 'value'),
  }))

  const referralSource = showAllCallSources
    ? allReferralSource
    : inaccurateReferralSource

  const firstRecordDate = _referralSource.firstRecordDate
  const total = referralSource.reduce((a, b) => a + b.value, 0)

  const handleToggleDrawer = () => setDrawerOpen((value) => !value)
  const handleCheck = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setShowAllCallSources(checked)
  }

  // TOOLTIP CONTENT
  const tooltipContent = (
    <div className={classes.metricTooltip}>
      <div className={classes.tooltipTitle}>Click to see the full list</div>
    </div>
  )

  const handleItemClick = (item: InaccurateReferralSourcesType) => () => {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS
      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters(
        showAllCallSources
          ? {
              outcome,
              callerSource: [item],
            }
          : {
              outcome,
              callerSource: [item],
              wrongSourceMentioned: true,
            }
      )
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  return (
    <>
      <ChartCard
        headerTitle="TOP INACCURATE REFERRAL SOURCES"
        tooltipText="This shows calls where an inaccurate referral source is mentioned by callers."
        headerTotalText="Total Calls"
        headerTotalValue={total}
        isAccordionContent={isAccordionContent}
      >
        <div className={classes.small_section}>
          <Checkbox
            checked={showAllCallSources}
            onChange={handleCheck}
            color="primary"
          />{' '}
          <strong>Show all call sources</strong>{' '}
          <Tooltip
            title={
              'Shows all referral sources, including correct and incorrect mentions'
            }
            placement="bottom-start"
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <InfoOutlinedIcon className={classes.grade_icon} />
          </Tooltip>
        </div>
        {loadingStates.referralSource ? (
          <div
            style={{
              textAlign: 'center',
              padding: '2rem',
            }}
          >
            <CircularProgress />
          </div>
        ) : referralSource.length > 0 ? (
          <>
            {referralSource.slice(0, 5).map((source, key) => {
              return (
                <LinearProgress
                  key={`item-${key}`}
                  value={source.value}
                  total={total}
                  label={
                    <Link
                      component="button"
                      variant="body1"
                      underline="none"
                      color="initial"
                      onClick={handleItemClick(source.name)}
                      classes={{ root: classes.link }}
                    >
                      {source.name}
                    </Link>
                  }
                />
              )
            })}
            <div className={classes.footer}>
              <div className={classes.container}>
                <div className={classes.infoContainer}>
                  <div className={classes.info}>
                    <CustomTooltip content={tooltipContent}>
                      <div
                        className={classes.infoDetail}
                        onClick={handleToggleDrawer}
                      >
                        All Call Referral Sources ({referralSource.length})
                      </div>
                    </CustomTooltip>
                  </div>
                </div>
              </div>
              {firstRecordDate.length ? (
                <div className={classes.disclaimer}>
                  <InfoOutlinedIcon className={classes.disclaimerIcon} />
                  The data used in this graph is derived from the calls we
                  scored since {moment(firstRecordDate).format(dateFormat)}.
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <EmptyBarChart />
        )}
      </ChartCard>
      <CallDetailsDrawer
        drawerOpen={drawerOpen}
        handleCloseDrawer={handleToggleDrawer}
        title="All Inaccurate Referral Sources"
      >
        <AllInaccurateReferralSourcesDrawer showAllCallSources={showAllCallSources}/>
      </CallDetailsDrawer>
    </>
  )
}

export default observer(InaccurateReferralSources)
