import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { runInAction } from 'mobx'
import { Link, CircularProgress } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { dateFormat } from 'config/callpro.config'
import { useStores } from 'config/store'
import { CallsStore } from 'config/store/CallPro/Call/CallsStore'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'

import { LinearProgress } from '../LinearProgress'
import { ChartCard } from 'components/CallPro/ChartCard'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'
import { useStyles } from './CallerLanguage.styles'

interface CallerLanguageInterface {
  isAccordionContent?: boolean
}

type CallerLanguageItem = CallsStore['callerLanguage']['records'][number]

const CallerLanguage = (props: CallerLanguageInterface) => {
  const { isAccordionContent } = props
  const { callsStore, dashboardUIStore } = useStores()
  const classes = useStyles()

  const { callerLanguage: _callerLanguage, loadingStates } = callsStore
  const callerLanguage = _callerLanguage.records
  const firstRecordDate = _callerLanguage.firstRecordDate
  const total = callerLanguage.reduce(
    (accumulator, currentElement) => accumulator + currentElement.value,
    0
  )

  const handleItemClick = (item: CallerLanguageItem) => () => {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS
      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters({
        outcome,
        callerLanguage: [item.name],
      })
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  return (
    <ChartCard
      headerTitle="CALLER LANGUAGE"
      tooltipText="This shows the primary language used during the call."
      headerTotalText="Total Calls"
      headerTotalValue={total}
      isAccordionContent={isAccordionContent}
    >
      {loadingStates.callerLanguage ? (
        <div
          style={{
            textAlign: 'center',
            padding: '2rem',
          }}
        >
          <CircularProgress />
        </div>
      ) : total > 0 ? (
        callerLanguage.map((language, key) => {
          return (
            <LinearProgress
              key={`item-${key}`}
              value={language.value}
              total={total}
              label={
                <Link
                  component="button"
                  variant="body1"
                  underline="none"
                  color="initial"
                  onClick={handleItemClick(language)}
                  classes={{ root: classes.link }}
                >
                  {language.name}
                </Link>
              }
            />
          )
        })
      ) : (
        <EmptyBarChart />
      )}
      {firstRecordDate.length ? (
        <div className={classes.disclaimer}>
          <InfoOutlinedIcon className={classes.disclaimerIcon} />
          The data used in this graph is derived from the calls we scored since{' '}
          {moment(firstRecordDate).format(dateFormat)}.
        </div>
      ) : null}
    </ChartCard>
  )
}

export default observer(CallerLanguage)
