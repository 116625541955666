import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {},

  sideDrawer: {
    minHeight: '100%',
    overflow: 'auto',
    width: '40rem',
    display: 'grid',
    gridTemplateRows: '3.5rem 1fr',
    '@media screen and (max-width: 780px)': {
      width: '100% !important',
    },
  },

  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '.5rem 1rem',
    backgroundColor: '#232323',
    color: '#929292',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '.9rem',
  },

  closeIcon: {
    cursor: 'pointer',
  },
})
