import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useAllStatsCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: '#F3F3F3',
    },
    container: {
      padding: '0 0 16px 0',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      gap: '8px',

      [theme.breakpoints.up('sm')]: {
        gap: '16px',
      },
    },
    cardContainer: {
      flex: '30%',
      backgroundColor: '#fff',
      padding: '16px',
      borderRadius: '10px',
      boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.1)',
    },
    iconWrapper: {
      backgroundColor: '4498E7',
      borderRadius: '4px',
    },
    missedCallValue: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '8px',
    },
    missedCallCount: {
      fontSize: '32px',
      fontWeight: 'bold',
      lineHeight: '120%',
      '@media screen and (max-width: 780px)': {
        fontSize: '24px',
      },
    },
    conversionRateCount: {
      fontSize: '32px',
      fontWeight: 'bold',
      lineHeight: '120%',
      '@media screen and (max-width: 780px)': {
        fontSize: '24px',
      },
    },
    missedCallRate: {
      backgroundColor: '#FFF4F4',
      padding: '0 8px',
      color: '#FF0000',
      fontSize: '14px',
    },
  })
)
