import API, { MAPS_API } from 'src/helpers/api'
import Geodata, { repository } from 'src/models/Geodata'
import centroid from '@turf/centroid'
import bbox from '@turf/bbox'

// import _get from 'lodash/get'
// import sampleGeodata from 'src/assets/geo.json'

/**
 *
 * @param {object} options
 * @return MailingArea[]
 */
export default async ({ geodataIds } = {}) => {
  const results = await API.get({
    api: MAPS_API,
    url: '/geodata',
    params: {
      ids: geodataIds.join(',')
    }
  }) || []

  // const results = _get(sampleGeodata, 'data')
  const geodata = results.map(row => {
    if (!row.geodata.features.length) {
      return
    }

    const geo = new Geodata()
    geo.id = row.id
    geo.type = row.type
    geo.geodata = row.geodata
    geo.centroid = centroid(row.geodata)
    geo.boundingBox = bbox(row.geodata)
    return repository.upsert(geo)
  })

  return geodata
}
