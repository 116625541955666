import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core'
import { useReactQuery } from './_hooks'

const EMPTY_CONTENT = 'Staff asked for additional appointment list is empty'

export const Table = (props: { data: TableData }) => {
  const classes = useStyles()
  const { data: list = [] } = props

  if (!list.length) {
    return <div className={classes.rowEmpty}>{EMPTY_CONTENT}</div>
  }

  return (
    <>
      <div style={{ overflowY: 'auto' }}>
        <table>
          <thead>
            <tr>
              <th className={classes.rowData} style={{ width: '1rem' }}>
                #
              </th>
              <th className={classes.rowData} style={{ width: '18rem' }}>
                Staff Name
              </th>
              <th className={classes.rowData} style={{ width: '4rem' }}>
                Calls
              </th>
              <th className={classes.rowData} style={{ width: '4rem' }}>
                Asked
              </th>
              <th className={classes.rowData} style={{ width: '4rem' }}>
                Did not asked
              </th>
              <th className={classes.rowData} style={{ width: '1rem' }}>
                Grade
              </th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => {
              return (
                <tr key={index + '-' + item.callHandlerName}>
                  <td className={classes.rowData}>{index + 1}</td>
                  <td className={classes.rowData}>{item.callHandlerName}</td>
                  <td className={classes.rowData}>{item.total}</td>
                  <td className={classes.rowData}>
                    {item.askedForAdditionalAppointmentTotal}
                  </td>
                  <td className={classes.rowData}>
                    {item.total - item.askedForAdditionalAppointmentTotal}
                  </td>
                  <td className={classes.rowData}>
                    {item.askedForAdditionalAppointmentGrade}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    rowData: {
      border: '1px solid #ddd',
      borderRight: 'none',
      borderBottom: 'none',
      padding: '.5rem',
      fontSize: '.85rem',
      alignItems: 'center',

      '&:last-child': {
        borderRight: '1px solid #ddd',
      },
    },

    rowEmpty: {
      textAlign: 'center',
      paddingTop: 14,
    },
  })
)

type TableData = NonNullable<
  Awaited<ReturnType<typeof useReactQuery>>['data']
>['records']
