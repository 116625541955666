import { defaultFont } from './index'

// Theming for buttons
export default (theme) => ({
  MuiInput: {
    root: {
      ...defaultFont,
      fontSize: '.9rem',
      alignItems: 'left',
      position: 'relative',
    },
    input: {
      transition: '0.3s ease border-color',
      border: '1px solid #d3d3d3',
      backgroundColor: '#fbfbfb',
      borderRadius: '5px',
      color: '#000',
      padding: '14px',
      '&:hover:not(:focus)': {
        border: '1px solid rgba(0, 0, 0, 0.15)',
      },
      '&:focus': {
        borderColor: '#ED154E',
      },
    },

    formControl: {
      'label + &': {
        marginTop: '1.2rem',
      },
    },
  },

  MuiInputAdornment: {
    root: {},
    positionEnd: {
      position: 'absolute',
      right: 10,
    },
  },

  MuiInputLabel: {
    root: {
      ...defaultFont,
      fontSize: '13px',
      color: '#7F7F7F',
      marginLeft: 2,
      marginBottom: 0,
    },
    shrink: {
      transform: 'translate(0, 0) scale(1)',
    },
  },
})
