import React, { createContext, useContext, useRef, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Location } from 'history'

const PreviousRouteContext = createContext<Location | null>(null)

export const PreviousLocationProvider = (props: any) => {
  const location = useLocation()

  const previousLocation = useRef<Location | null>(null)
  const currentLocation = useRef(location)

  useEffect(() => {
    previousLocation.current = currentLocation.current
    currentLocation.current = location
  }, [location])

  return (
    <PreviousRouteContext.Provider value={previousLocation.current}>
      {props.children}
    </PreviousRouteContext.Provider>
  )
}

export const usePreviousLocation = (): Location | null => {
  const previousLocation = useContext(PreviousRouteContext)
  return previousLocation
}
