import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogPaper: {
      borderRadius: 0,
      maxWidth: '29.5rem',
    },
  })
)

export default useStyles
