import { DateFilters } from 'containers/DateFilterTypeSelect'
import { observable, runInAction } from 'mobx'

export class GlobalFilterStore {
  dateFilter: DateFilter
  campaignFilter: CampaignFilter

  constructor() {
    this.dateFilter = new DateFilter()
    this.campaignFilter = new CampaignFilter()
  }
}

export type DateFilterType =
  | 'all'
  | 'day'
  | 'week'
  | 'month'
  | 'year'
  | 'custom'

interface DateRange {
  from: Date | undefined
  to: Date | undefined
}

class DateFilter {
  private _dateFilterType = observable.box<DateFilterType>('all')
  dateRange = observable(DateFilters.all.getFilter()) as DateRange

  setDateFilterType(value: DateFilterType) {
    runInAction(() => {
      this._dateFilterType.set(value)
    })
  }

  get dateFilterType() {
    return this._dateFilterType.get()
  }

  setDateRange(value: DateRange) {
    runInAction(() => {
      this.dateRange.from = value.from
      this.dateRange.to = value.to
    })
  }
}

export type CampaignFilterType = 'all' | 'mvp-mail' | 'non-mailing'

class CampaignFilter {
  private _campaignFilterType = observable.box<CampaignFilterType>('all')

  setCampaignFilterType(value: CampaignFilterType) {
    runInAction(() => {
      this._campaignFilterType.set(value)
    })
  }

  get campaignFilterType() {
    return this._campaignFilterType.get()
  }

  get campaignTypesByFilterType() {
    switch (this.campaignFilterType) {
      case 'mvp-mail':
        return {
          campaignTypes: [
            'MVP Mail',
            'Custom',
            'Handout Cards',
            'My Patient Mail',
            'MVP Core',
            'New Mover Mail',
          ],
        }

      case 'non-mailing':
        return { campaignTypes: ['Call Tracking', 'CallPro', 'Google Ads'] }

      default:
        return {}
    }
  }
}

export default new GlobalFilterStore()
