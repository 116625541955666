import _get from 'lodash/get'
import { isEmpty } from 'lodash'
import kebabCase from 'lodash/kebabCase'
import { NotificationSettingsData } from 'components/Settings/AlertType/AlertType'
import { alertTypeDefaultFrequency } from 'components/Settings/ManageReceivers/ManageReceivers'

const defaultAlerts = [
  'Performance Breakdown Alert',
  'Missed Call Alert',
  'En Route Alert',
  'First Opportunity Alert',
  'New Appointment Call Alert',
  'New Opportunity Call Alert'
]

export const processContactSettingsData = (notification: any[], contactId: string, name: string): Map<string, NotificationSettingsData> => {
  const _defaultRecords = new Map()
  const _notificationRecords = new Map()

  notification.forEach((item) => {
    const alertType = _get(item, 'Alert_Type__c')
    const key = kebabCase(alertType + '-' +  _get(item, 'Contact__r.Id'))
    const method = _get(item, 'Method__c') ?? []
    const email = method.includes('Email')
    const sms = method.includes('SMS')
    const value = {
      id: item.Id,
      contactId: _get(item, 'Contact__r.Id'),
      name: _get(item, 'Contact__r.MVP_Name__c'),
      alertType: alertType,
      frequency: _get(item, 'Frequency__c'),
      email: email,
      sms: sms
    }
    _notificationRecords.set(key, value)
  })

  defaultAlerts.forEach((value) => {
    const key = kebabCase(value + '-' + contactId)
    _defaultRecords.set(key, {
      id: '',
      contactId: contactId,
      name: name,
      alertType: value,
      frequency: alertTypeDefaultFrequency[value],
      email: false,
      sms: false
    })
  })

  return new Map([..._defaultRecords, ..._notificationRecords])
}

export const processReceiversSettingsData = (userContactId: string, notification: any[], contactRecord: any[]): Map<string, NotificationSettingsData> => {
  const _defaultRecords = new Map()
  const _notificationRecords = new Map()
  
  notification.forEach((item) => {
    const alertType = _get(item, 'Alert_Type__c')
    const key = kebabCase(alertType + '-' +  _get(item, 'Contact__r.Id'))
    const method = _get(item, 'Method__c') ?? []
    const email = method.includes('Email')
    const sms = method.includes('SMS')
    const value = {
      id: item.Id,
      contactId: _get(item, 'Contact__r.Id'),
      name: _get(item, 'Contact__r.MVP_Name__c'),
      alertType: alertType,
      frequency: _get(item, 'Frequency__c'),
      email: email,
      sms: sms
    }
    _notificationRecords.set(key, value)
  })

  contactRecord.forEach((item) => {
    if (userContactId !== item.id) {
      defaultAlerts.forEach((value) => {
        const key = kebabCase(value + '-' + item.id)
        _defaultRecords.set(key, {
          id: '',
          contactId: item.id,
          name: item.name,
          alertType: value,
          frequency: alertTypeDefaultFrequency[value],
          email: false,
          sms: false
        })
      })      
    }
  })
 
  return new Map([..._defaultRecords, ..._notificationRecords])
}