import React, { useState } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { runInAction } from 'mobx'
import { Link, CircularProgress } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { dateFormat } from 'config/callpro.config'
import { useStores } from 'config/store'
import { CallsStore } from 'config/store/CallPro/Call/CallsStore'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'

import { LinearProgress } from '../LinearProgress'
import { ChartCard } from 'components/CallPro/ChartCard'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import { useStyles } from './NonOpportunityReason.style'
import CallDetailsDrawer from 'components/CallPro/SideDrawer/CallDetailsDrawer/CallDetailsDrawer'
import AllNonOpportunityReasonDrawer from '../SideDrawer/AllNonOpportunityReason/AllNonOpportunityReasonDrawer'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'

interface NonOpportunityReasonInterface {
  isAccordionContent?: boolean
}

type NonOpportunityReasonItem =
  CallsStore['nonOpportunityReason']['records'][number]

const NonOpportunityReason = (props: NonOpportunityReasonInterface) => {
  const { isAccordionContent } = props
  const { dashboardUIStore, callsStore } = useStores()
  const classes = useStyles()
  const { nonOpportunityReason: _nonOpportunityReason, loadingStates } =
    callsStore
  const nonOpportunityReason = _nonOpportunityReason.records
  const firstRecordDate = _nonOpportunityReason.firstRecordDate

  const total = nonOpportunityReason.reduce(
    (accumulator, currentElement) => accumulator + currentElement.value,
    0
  )
  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleToggleDrawer = () => setDrawerOpen((value) => !value)

  const handleItemClick = (item: NonOpportunityReasonItem) => () => {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS
      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters({ outcome, nonOpportunityReason: [item.name] })
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  // TOOLTIP CONTENT
  const tooltipContent = (
    <div className={classes.metricTooltip}>
      <div className={classes.tooltipTitle}>Click to see the full list</div>
    </div>
  )

  return (
    <>
      <ChartCard
        headerTitle="TOP NON OPPORTUNITY REASONS"
        tooltipText="This shows the top reason why certain calls are classified as non-opportunities."
        headerTotalText="Total"
        headerTotalValue={total}
        isAccordionContent={isAccordionContent}
      >
        {loadingStates.nonOpportunityReason ? (
          <div
            style={{
              textAlign: 'center',
              padding: '2rem',
            }}
          >
            <CircularProgress />
          </div>
        ) : nonOpportunityReason.length > 0 ? (
          <>
            {nonOpportunityReason.slice(0, 5).map((providedSource, key) => {
              return (
                <LinearProgress
                  key={`item-${key}`}
                  value={providedSource.value}
                  total={total}
                  label={
                    <Link
                      component="button"
                      variant="body1"
                      underline="none"
                      color="initial"
                      onClick={handleItemClick(providedSource)}
                      classes={{ root: classes.link }}
                    >
                      {providedSource.name}
                    </Link>
                  }
                />
              )
            })}
            <div className={classes.footer}>
              <div className={classes.container}>
                <div className={classes.infoContainer}>
                  <div className={classes.info}>
                    <CustomTooltip content={tooltipContent}>
                      <div
                        className={classes.infoDetail}
                        onClick={handleToggleDrawer}
                      >
                        All Non Opportunity Reasons (
                        {_nonOpportunityReason.records.length})
                      </div>
                    </CustomTooltip>
                  </div>
                </div>
              </div>
              {firstRecordDate.length ? (
                <div className={classes.disclaimer}>
                  <InfoOutlinedIcon className={classes.disclaimerIcon} />
                  The data used in this graph is derived from the calls we
                  scored since {moment(firstRecordDate).format(dateFormat)}.
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <EmptyBarChart />
        )}
      </ChartCard>
      <CallDetailsDrawer
        drawerOpen={drawerOpen}
        handleCloseDrawer={handleToggleDrawer}
        title="All Non Opportunity Reasons"
      >
        <AllNonOpportunityReasonDrawer />
      </CallDetailsDrawer>
    </>
  )
}

export default observer(NonOpportunityReason)
