import React from 'react'
import { createStyles, Box, makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ExpandMore } from '@material-ui/icons'
import _isNil from 'lodash/isNil'

type CanBeArray<T> = T | Array<T>
export type FilterFieldValue = string | number | boolean
export type FilterFieldProp = {
  label: string
  source: Array<{ label: string; value: FilterFieldValue }>
  multiple?: boolean
  shown?: boolean
  value?: CanBeArray<FilterFieldValue | null | undefined>
  onChange?: (value: CanBeArray<FilterFieldValue | null | undefined>) => void
}

export default function FilterField({
  label,
  source,
  onChange,
  value = null,
  multiple = false,
  shown = true,
}: FilterFieldProp) {
  const styles = useStyles()
  const sourceMap = new Map(source.map((a) => [a.value, a]))

  return (
    <Box display={!shown ? 'none' : undefined}>
      <Autocomplete
        value={value}
        multiple={multiple}
        options={source.map((a) => a.value)}
        getOptionLabel={(option) =>
          _isNil(option) ? '' : sourceMap.get(option)?.label ?? ''
        }
        onChange={(event: any, newValue: FilterFieldProp['value']) => {
          onChange && onChange(newValue ?? undefined)
        }}
        filterSelectedOptions
        disableCloseOnSelect
        popupIcon={<ExpandMore />}
        limitTags={1}
        style={{ width: 256 }}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            InputLabelProps={{
              classes: { root: styles.textFieldLabel },
            }}
            classes={{
              root: styles.textField,
            }}
          />
        )}
      />
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      background: 'white',
    },
    textFieldLabel: {
      '&.MuiInputLabel-marginDense:not(.MuiInputLabel-shrink)': {
        transform: 'translate(14px, 14px) scale(1)',
      },
    },
  })
)
