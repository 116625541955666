import React, { useCallback } from 'react'
import { Grid, Chip, TextField } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'

import Select from 'components/AppBar/Select'

import useStyles from './SearchField.style'
import { Observer } from 'mobx-react'
import { useCallTableFilterStore } from '../store/CallTableFilterStore'

type Prop = {}

export default function SearchField({}: Prop) {
  const styles = useStyles()
  const callTableFilterStore = useCallTableFilterStore()

  const searchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    callTableFilterStore.searchByQuery(e.target.value)
  }, [])

  return (
    <Observer
      render={() => {
        const { searchQuery, moreFiltersOpened, activeVisibleFilters } =
          callTableFilterStore
        return (
          <Grid container wrap="nowrap">
            {/* Icon */}
            <Grid
              container
              alignItems="center"
              className={styles.searchIconContainer}
            >
              <SearchIcon className={styles.searchIcon} />
            </Grid>
            {/* Search input field */}
            <TextField
              placeholder="Search name or phone #"
              classes={{ root: styles.textFieldRoot }}
              InputProps={{ classes: { input: styles.textFieldInput } }}
              inputProps={{ form: { autocomplete: 'off' } }}
              onChange={searchChange}
              value={searchQuery.get()}
            />
            {/* Filters select button */}
            <Select
              classes={{
                root: styles.filterSelect,
                label: styles.filterSelectLabel,
              }}
              label={
                <>
                  <span>Filters</span>
                  {activeVisibleFilters.length > 0 && (
                    <Chip
                      size="small"
                      label={activeVisibleFilters.length}
                      color="primary"
                    />
                  )}
                </>
              }
              isOpened={moreFiltersOpened.get()}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                moreFiltersOpened.set(!moreFiltersOpened.get())
              }}
            />
          </Grid>
        )
      }}
    />
  )
}
