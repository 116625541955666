import parseDate from 'date-fns/parseISO'
import { ReviewComment } from '@mvp/mvp-react'

import API, { DATA_API } from 'helpers/api'

export const updateReviewComment = async (
  id: string,
  updates: Partial<ReviewComment>
): Promise<ReviewComment> => {
  const data = {
    ...updates,
    side: updates?.side?.toLowerCase(),
  }

  const result = await API.put({
    api: DATA_API,
    url: `/designs/review-comments/${id}`,
    data,
  })

  result.createdDate = parseDate(result.createdDate)
  result.lastModifiedDate = parseDate(result.lastModifiedDate)

  const reviewComment = new ReviewComment(result)

  return reviewComment
}
