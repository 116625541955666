import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { runInAction } from 'mobx'
import { Link, CircularProgress } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { dateFormat } from 'config/callpro.config'
import { useStores } from 'config/store'
import { CallsStore } from 'config/store/CallPro/Call/CallsStore'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'

import { LinearProgress } from '../LinearProgress'
import { ChartCard } from 'components/CallPro/ChartCard'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'
import { useStyles } from './IVROutcome.style'

interface IVROutcomeInterface {
  isAccordionContent?: boolean
}

type IVROutcomeItem = CallsStore['ivrOutcome']['records'][number]

const IVROutcome = (props: IVROutcomeInterface) => {
  const { isAccordionContent } = props
  const { callsStore, dashboardUIStore } = useStores()
  const classes = useStyles()

  const { ivrOutcome: _ivrOutcome, loadingStates } = callsStore
  const ivrOutcome = _ivrOutcome.records
  const firstRecordDate = _ivrOutcome.firstRecordDate
  const total = ivrOutcome.reduce(
    (accumulator, currentElement) => accumulator + currentElement.value,
    0
  )

  const handleItemClick = (item: IVROutcomeItem) => () => {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS
      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters({ outcome, ivrOutcome: [item.name] })
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  return (
    <ChartCard
      headerTitle="IVR OUTCOME"
      tooltipText="This is the result after the caller has interacted with the destination Interactive voice response."
      headerTotalText="Total"
      headerTotalValue={total}
      isAccordionContent={isAccordionContent}
    >
      {loadingStates.ivrOutcome ? (
        <div
          style={{
            textAlign: 'center',
            padding: '2rem',
          }}
        >
          <CircularProgress />
        </div>
      ) : total > 0 ? (
        ivrOutcome.map((outcome, key) => {
          return (
            <LinearProgress
              key={`item-${key}`}
              value={outcome.value}
              total={total}
              label={
                <Link
                  component="button"
                  variant="body1"
                  underline="none"
                  color="initial"
                  onClick={handleItemClick(outcome)}
                  classes={{ root: classes.link }}
                >
                  {outcome.name}
                </Link>
              }
            />
          )
        })
      ) : (
        <EmptyBarChart />
      )}
      {firstRecordDate.length ? (
        <div className={classes.disclaimer}>
          <InfoOutlinedIcon className={classes.disclaimerIcon} />
          The data used in this graph is derived from the calls we scored since{' '}
          {moment(firstRecordDate).format(dateFormat)}.
        </div>
      ) : null}
    </ChartCard>
  )
}

export default observer(IVROutcome)
