import AppState from 'src/config/store/AppState'
import API, { DATA_API } from 'src/helpers/api'

/**
 *
 * @param {string} id the id of the mailing object to update
 *
 * @return {boolean} if mailing is updated
 */
export default async id => {
  const result = await API.put({
    api: DATA_API,
    url: `/mailings/${id}/approve`,
	})

	return result
}


