import React, { useState } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

import mvpLogoPlaceholder from 'src/assets/img/mvp-logo.png'
import Mailing from 'models/Mailing'
import useStyles from './MailingThumbnail.style'

type Props = {
  mailing: Mailing
  onAction: (action: string, mailing: Mailing) => void
}

export default function MailingThumbnail({ mailing, onAction }: Props) {
  const classes = useStyles()
  const [isFailed, setIsFailed] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  function handleAction(action: string, mailing: Mailing) {
    return () => onAction(action, mailing)
  }

  function handleMissingDesign() {
    setIsFailed(true)
    setIsLoaded(true)
  }

  function handleLoaded() {
    setIsLoaded(true)
  }

  return (
    <div className={classes.root} onClick={handleAction('view', mailing)}>
      {!isLoaded ? (
        <CircularProgress
          className={classes.loader}
          size={50}
          color="primary"
        />
      ) : (
        <></>
      )}

      {isFailed ? (
        <img src={mvpLogoPlaceholder} className={classes.mvpImagePlaceholder} />
      ) : (
        <img
          onError={handleMissingDesign}
          onLoad={handleLoaded}
          src={mailing.frontDesignThumbnailUrl}
        />
      )}
    </div>
  )
}
