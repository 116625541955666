import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'constants/constants'

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    width: 'calc(100vw - 70px)',
    height: '100vh',
    right: 0,
    top: 0,
    backgroundColor: 'rgb(0,0,0,.9)',
    zIndex: 9999,
  },
  preview: {
    width: 'calc(100% - 430px)',
    padding: '16px',
    overflowX: 'auto',
    '& h2': {
      fontFamily: 'Roboto',
      fontWeight: '400',
      color: '#fff',
      fontSize: '30px',
      marginTop: '8px',
      marginBottom: '16px',
    },
    '& nav': {
      padding: '8px 0px',
      marginBottom: '20px',
    },
    '& nav a': {
      textDecoration: 'none',
      fontFamily: 'Roboto',
      color: '#fff',
      fontSize: '15px',
      padding: '10px 5px',
      cursor: 'pointer',
      marginRight: 12,
      '&:hover': {
        color: '#fff',
      },
    },
  },
  loader: {
    position: 'absolute',
    top: 'calc(40% - 25px)',
    left: 'calc(50% - 25px)',
    marginTop: '20%',
    '& svg': {
      color: '#e13765',
    },
  },
  previewContainer: {
    display: 'flex',
    height: '100vh',
  },
  statusContainer: {
    background: '#fff',
    width: '430px',
  },
  active: {
    borderBottom: 'solid 2px #F50D0D',
  },
  imgPreview: {
    textAlign: 'center',
    position: 'relative',
    height: 'auto',
    '& img': {
      width: '100%',
      position: 'relative',
    },
  },
  statusHeading: {
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    padding: '16px',
    borderBottom: 'solid 1px rgb(220,220,221)',
    '& h4': {
      margin: 0,
      fontFamily: 'Roboto',
      fontSize: '18px',
      marginLeft: '16px',
    },
  },
  statusBody: {
    padding: '16px',
    backgroundColor: 'rgb(239,239,239)',
    height: '100%',
    textAlign: 'center',
    paddingTop: 32,
    '& p': {
      fontFamily: 'Roboto',
      fontSize: 14,
      lineHeight: 1.5,
      color: 'rgb(144,144,145)',
      '& strong': {
        color: 'rgb(50,50,50)',
      },
      '& a': {
        color: 'rgb(225,55,101)',
        textDecoration: 'underline',
      },
    },
  },
  statusHeadingIcon: {
    color: 'rgb(201,201,201)',
  },
  status: {
    color: 'rgb(168,30,47)',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  emptyIcon: {
    height: '40px',
    width: '40px',
    color: 'rgb(201,201,201)',
  },
  emptyBody: {
    paddingTop: 64,
  },
  closeBtn: {
    position: 'absolute',
    color: '#fff',
    marginLeft: '-53px',
    top: '31px',
    cursor: 'pointer',
  },
  statusItem: {
    fontSize: '14px',
    border: 'solid 1px #e4e4e4',
    padding: '16px',
    lineHeight: '1.5',
    marginBottom: '16px',
  },
}))
