import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  disclaimer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.8rem',
    backgroundColor: '#fbfbfb',
    padding: '.5rem',
    '@media screen and (max-width: 780px)': {
      fontSize: 10,
    },
  },
  disclaimerIcon: {
    marginRight: '.5rem',
    fontSize: '1rem',
  },
  timezone: {
    fontSize: '12px',
    margin: 0,
    textAlign: 'center',
    color: '#818181',
  },
  legend: {
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    '& p': {
      position: 'relative',
      paddingLeft: 17,

      '&:first-child:before': {
        background: '#E13426',
      },
      '&:last-child:before': {
        background: '#4398E7',
      },
      '&:before': {
        content: '""',
        width: 10,
        height: 10,
        position: 'absolute',
        top: 5,
        display: 'inline-block',
        left: 0,
      },
      fontSize: 14,
      marginBottom: 0,
      marginTop: 16,
      color: '#818181',
    },
  },
  tooltip: {
    backgroundColor: '#222',
    '&::after': {
      display: 'none',
    },
  },
  grade_icon: {
    color: '#d3d3d3',
    verticalAlign: 'middle',
  },
})
