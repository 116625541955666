/**
 * ******************************************
 * Designed to be used with components/Page/AppBarTitleSection.tsx
 * example:
 * ```jsx
 * <AppBar>
 *  <AppBarTitleSection>
 *    <AppbarToolbar>
 *      <ToolbarDrawerIconButon />
 *    </AppbarToolbar>
 *  </AppBarTitleSection>
 * </AppBar>
 * ```
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React, { PropsWithChildren, useCallback } from 'react'
import { IconButton, makeStyles, Theme, useMediaQuery } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { observer } from 'mobx-react'

// My util
import { useStores } from 'config/store'

const ToolbarDrawerIconButon = () => {
  const classes = useStyle()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const { appState } = useStores()
  const { setNavDrawer, isDrawerOpen } = appState

  const handleClick = useCallback(() => {
    setNavDrawer(!isDrawerOpen)
  }, [setNavDrawer, isDrawerOpen])

  if (isDesktop) {
    return <></>
  }

  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="start"
      className={classes.root}
      onClick={handleClick}
    >
      <Menu />
    </IconButton>
  )
}

export default observer(ToolbarDrawerIconButon)

const useStyle = makeStyles(() => ({
  root: {
    color: '#141414',
    marginLeft: -6,
    marginRight: '0.5rem',
  },
}))
