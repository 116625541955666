import React, { useEffect, useState } from 'react'
import tip from '../../../../assets/img/call-pro/tip.svg'

import { useStyles } from './ProTip.styles'
import { Button } from '@material-ui/core'
import { GetApp as DownloadIcon, Close as CloseIcon } from '@material-ui/icons'

interface IProTip {
  content: string | undefined
  image: string
  title: string
}

const ProTip = (props: IProTip) => {
  const { content, image, title } = props
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)

  const getFileName = (url: string): string => {
    const match = url.match(/([^/]+)(?=\.)/)
    if (!match) {
      return ''
    }
    const fileName = match[0]
    const fileExtension = url.split('.').pop() ?? ''
    return `${fileName}.${fileExtension}`
  }

  const fileName = getFileName(image)

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpen(false)
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    // 👇️ clean up event listener
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  return (
    <>
      <div className={classes.container}>
        <img src={tip} className={classes.bulb} />
        <div className={classes.mainContent}>
          <p>{content}</p>
          <a
            onClick={() => {
              setOpen(true)
            }}
          >
            Pro Tip Training
          </a>
        </div>
      </div>

      {open ? (
        <div className={classes.modalContainer}>
          <div className={classes.heading}>
            {title}
            <div className={classes.downloadButtonWrapper}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.download}
                href={image}
                download={fileName}
              >
                <DownloadIcon />
                DOWNLOAD
              </Button>

              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setOpen(false)}
              />
            </div>
          </div>

          <div className={classes.imageContainer}>
            <img src={image} />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ProTip
