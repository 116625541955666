import React, { ChangeEvent, useEffect } from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Modal from '@material-ui/core/Modal'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@material-ui/lab/Autocomplete'
import classNames from 'classnames'

// STYLE
import check from '../../../../assets/img/call-pro/call-pro.png'
import { useStyles } from './ResolveDetails.style'
import { useStores } from 'config/store'
import {
  OpportunityResult,
  ResolveFields,
  ResultCallback,
  StaffInterface,
} from 'interfaces/Call.interface'
import { formatTextByIndustry } from 'helpers/industries'
import Account from 'models/Account'

interface ResolveDetailsProps {
  formData: (isValid: boolean, c: ResolveFields) => void
  validateForm: number | undefined
}

const ResolveDetails = (props: ResolveDetailsProps) => {
  const classes = useStyles()
  const { callStore, frontDeskStore, accountStore } = useStores()
  const call = callStore.drawer
  let { formData, validateForm } = props
  const account: Account = accountStore.accounts[0]

  const [isModalVisible, setModalVisible] = React.useState(false)
  const [formFields, setFormFields] = React.useState<ResolveFields>({
    callbackHandler: undefined,
    callbackOutcomeResult: '',
    callbackOutcomeOpportunityResult: '',
    callbackOutcomeSummary: '',
  })

  const toggleCallBackImageModal = (isVisible: boolean) => {
    setModalVisible(isVisible)
  }

  const handleCallbackHandlerChange = (
    event: ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<StaffInterface> | undefined
  ) => {
    setFormFields({ ...formFields, callbackHandler: value })
  }

  const handleCallbackOutcomeChange = (id: any) => (event: any) => {
    const data = { [id]: event.target.value }
    setFormFields({ ...formFields, ...data })
  }

  useEffect(() => {
    if (validateForm) {
      let isValid

      if (
        !formFields.callbackHandler ||
        !formFields.callbackOutcomeOpportunityResult.length ||
        !formFields.callbackOutcomeResult.length ||
        !formFields.callbackOutcomeSummary.length
      ) {
        isValid = false
      } else {
        isValid = true
      }

      formData(isValid, {
        ...formFields,
      })
    }
  }, [validateForm])

  if (!call) {
    return <div></div>
  }

  return (
    <section className={classes.resolveDetailsContainer}>
      <div className={classes.resolveDetails}>
        <form>
          <div className={classes.title}>Steps Needed to Resolve</div>
          <Timeline>
            <TimelineItem
              classes={{
                missingOppositeContent: classes.removeLeftDefaultContent,
                root: classes.timeline,
              }}
            >
              <TimelineSeparator>
                <TimelineDot classes={{ root: classes.dot }}>1</TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className={classes.step}>
                  Call this number back & try to schedule.
                </div>
                <div className={classes.phone}>{call.callerPhoneNumber}</div>
                <Accordion
                  classes={{
                    root: classes.accordion,
                    expanded: classes.accordionExpanded,
                  }}
                >
                  <AccordionSummary className={classes.accordionSummary}>
                    <span className={classes.script}>
                      {' '}
                      Show callback script
                    </span>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <span>
                      "Hello, My name is{' '}
                      <span className={classes.scriptVariable}>
                        (state your name)
                      </span>{' '}
                      with{' '}
                      <span className={classes.scriptVariable}>
                        (practice name)
                      </span>
                      {formatTextByIndustry({
                        account,
                        text: ` and we recently missed a call from this phone number. Were
                      you calling to schedule a new {CLIENT_LABEL} appointment to see
                      our dentist?"`,
                      })}
                    </span>
                  </AccordionDetails>
                </Accordion>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem
              classes={{
                missingOppositeContent: classes.removeLeftDefaultContent,
                root: classes.timeline,
              }}
            >
              <TimelineSeparator>
                <TimelineDot classes={{ root: classes.dot }}>2</TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className={classes.step}>
                  Enter callback handler{' '}
                  <span className={classes.required}>(required)</span>
                </div>
                <FormControl
                  style={{ width: '100%' }}
                  variant="outlined"
                  size="small"
                >
                  <Autocomplete
                    size={'small'}
                    value={formFields.callbackHandler}
                    options={frontDeskStore.frontDeskStaff}
                    onChange={handleCallbackHandlerChange}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Enter Name"
                        variant="outlined"
                        InputLabelProps={{
                          classes: {
                            root: classes.label,
                            focused: classes.labelFocused,
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem
              classes={{
                missingOppositeContent: classes.removeLeftDefaultContent,
                root: classes.timeline,
              }}
            >
              <TimelineSeparator>
                <TimelineDot classes={{ root: classes.dot }}>3</TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className={classes.step}>
                  Enter callback outcome{' '}
                  <span className={classes.required}>(required)</span>
                </div>
                <div className={classes.fieldContainer}>
                  <FormControl
                    style={{ width: '100%' }}
                    variant="outlined"
                    size="small"
                  >
                    <Select
                      id="callbackOutcomeResult"
                      value={formFields.callbackOutcomeResult}
                      onChange={handleCallbackOutcomeChange(
                        'callbackOutcomeResult'
                      )}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      className={classes.inputRoot}
                    >
                      <MenuItem value="">
                        <span className={classes.placeholder}>
                          Result of callback
                        </span>
                      </MenuItem>
                      {Object.values(ResultCallback).map((sortCriteria) => (
                        <MenuItem key={sortCriteria} value={sortCriteria}>
                          {sortCriteria}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    style={{ width: '100%' }}
                    variant="outlined"
                    size="small"
                  >
                    <Select
                      id="callbackOutcomeOpportunityResult"
                      value={formFields.callbackOutcomeOpportunityResult}
                      onChange={handleCallbackOutcomeChange(
                        'callbackOutcomeOpportunityResult'
                      )}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      className={classes.inputRoot}
                    >
                      <MenuItem value="">
                        <span className={classes.placeholder}>
                          Opportunity result
                        </span>
                      </MenuItem>
                      {Object.values(OpportunityResult).map((sortCriteria) => (
                        <MenuItem key={sortCriteria} value={sortCriteria}>
                          {sortCriteria}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    style={{ width: '100%' }}
                    variant="outlined"
                    size="small"
                  >
                    <TextField
                      id="callbackOutcomeSummary"
                      onChange={handleCallbackOutcomeChange(
                        'callbackOutcomeSummary'
                      )}
                      value={formFields.callbackOutcomeSummary}
                      multiline
                      rows={4}
                      placeholder="Write a brief summary of your callback"
                      className={classes.placeholder}
                      inputProps={{ maxLength: 150 }}
                      classes={{
                        root: classNames(classes.textarea),
                      }}
                    />
                  </FormControl>
                </div>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </form>
        <Modal
          open={isModalVisible}
          onClose={() => {
            toggleCallBackImageModal(false)
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className={classes.paper}>
            <img style={{ width: '500px' }} src={check} alt="image" />
          </div>
        </Modal>
      </div>
    </section>
  )
}

export default ResolveDetails
