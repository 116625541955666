import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import { Clear, Check } from '@material-ui/icons'
import InputAdornment from '@material-ui/core/InputAdornment'

import cx from 'classnames'
import { Mail, Lock } from '@material-ui/icons/'

import customInputStyle from 'src/assets/jss/material-dashboard-react/customInputStyleWithIcon'

function CustomInputWithIcon({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    icon,
    type,
    placeholder,
    onChange,
    onBlur,
    style,
    endAdornment, 
  } = props


  const labelClasses = cx({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error
  })
  const underlineClasses = cx({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  })
  const marginTop = cx({
    [classes.marginTop]: labelText === undefined
  })
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + ' ' + classes.formControl}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
          input: classes.input
        }}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        style={style}
        disableUnderline={false}
        id={id}
        startAdornment={
          <InputAdornment position='start'>
            {icon}
          </InputAdornment>
        }
        endAdornment={endAdornment}
        {...inputProps}
      />
      {error ? (
        <Clear className={classes.feedback + ' ' + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  )
}

CustomInputWithIcon.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.object,
  endAdornment: PropTypes.object
}

CustomInputWithIcon.defaultProps = {
  onChange: () => {},
  onBlur: () => {}
}

export default withStyles(customInputStyle)(CustomInputWithIcon)
