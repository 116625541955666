import React, { useState } from 'react'
import { Observer } from 'mobx-react'
import { isEmpty } from 'lodash'

import Dialog from '@material-ui/core/Dialog'

import { useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '../../components/Login/LoginForm'

import settingsStore from '../../config/store/SettingsStore'
import LoginForm from '../../components/Login/LoginForm'
import VerificationLinkForm from '../../components/Login/VerificationLinkForm'

const styles = makeStyles({
  container: {
    position: 'absolute !important',
    filter: 'blur(0px) !important',
    '& *': {
      filter: 'blur(0px) !important',
    },
  },
  heading: {
    fontSize: 25,
    margin: '35px 0px 25px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'rgb(20,20,20)',
  },
  avatar: {
    margin: '0px auto',
    display: 'block',
  },
  contentContainer: {
    width: 400,
    margin: '0 auto',
    padding: '0px 40px',
    textAlign: 'center',
    '& a': {
      color: 'rgb(248,0,61)',
    },
    '& form': {
      paddingTop: 50,
      paddingBottom: 50,
    },
  },
  email: {
    fontWeight: 'bold',
    margin: '20px 0px',
    display: 'block',
  },
})

const BlockAccessOverlay = (props) => {
  const classes = styles()
  const location = useLocation()
  const [showLoginForm, setShowLoginForm] = useState(true)
  const [showLinkForm, setShowLinkForm] = useState(false)

  return (
    <Observer
      render={() => {
        return (
          <div>
            <Dialog
              open={true}
              keepMounted
              container={() => document.getElementById('main-section')}
              classes={{
                root: classes.container,
              }}
              BackdropProps={{
                classes: {
                  root: classes.container,
                },
              }}
            >
              {typeof settingsStore.activated !== 'undefined' && (
                <div className={classes.contentContainer}>
                  {showLoginForm && (
                    <LoginForm
                      theme={Theme.Light}
                      currentPath={location.pathname}
                      handleShowLinkForm={(value) => {
                        setShowLinkForm(value)
                        setShowLoginForm(false)
                      }}
                    />
                  )}
                  {showLinkForm && (
                    <VerificationLinkForm
                      theme={Theme.Light}
                      handleChangeForm={(value) => {
                        setShowLoginForm(value)
                        setShowLinkForm(false)
                      }}
                    />
                  )}
                </div>
              )}
            </Dialog>
          </div>
        )
      }}
    />
  )
}

export default BlockAccessOverlay
