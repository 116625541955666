import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progressWrapper: {
      marginTop: 24,
    },
    cardWrapper: {
      marginTop: 15,
      width: '100%',
    },
  })
)
