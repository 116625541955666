import _isEmpty from 'lodash/isEmpty'

import api, { DATA_API } from 'helpers/api'
import accountStore from 'config/store/AccountStore'

type Filters = { [key: string]: any }

export default async function requestLargeExportCalls(filters: Filters) {
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    return
  }

  await api.post({
    api: DATA_API,
    url: '/calls/request-large-export',
    data: {
      filters,
      accountIds,
    },
  })
}
