import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  appDetailsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: '#F4F4F4',
    padding: '.85rem',
    paddingTop: '0',
    width: '100%',
  },

  apptDetails: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '0 0 10px 10px ',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: '.7rem',
    color: '#ed154e',
  },

  // TABLE
  table: {
    width: '100%',
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  tempStatus: {
    '& td': {
      fontWeight: 'bold',
    },
  },

  thead: {
    border: '1px solid #ddd',
    borderRight: 'none',
    padding: '.5rem',
    fontSize: '.85rem',

    width: '100%',
    alignItems: 'center',

    '&:last-child': {
      borderRight: '1px solid #ddd',
    },
  },

  td: {
    border: '1px solid #ddd',
    borderRight: 'none',
    borderTop: 'none',
    padding: '.5rem',
    fontSize: '.85rem',
    fontWeight: 'bold',

    width: '100%',
    alignItems: 'center',

    '&:last-child': {
      borderRight: '1px solid #ddd',
    },
  },

  noAppointment: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: '#8b9898',
    backgroundColor: '#f9f9f9',
    padding: '1rem',
    fontSize: '.85rem',
  },

  row2: {
    display: 'grid',
    gridTemplateRows: '1.8rem 1fr',
    border: '1px solid #ddd',
    padding: '.5rem',
    fontSize: '.85rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
  },

  row2label: {
    fontSize: '.85rem',
    fontWeight: 'normal',
    // color: '#929292',
  },
})
