import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    filterByTitle: {
      fontWeight: 600,
      marginBottom: 10,
    },
    filterByContainer: {
      padding: '0.625rem 0',
      background: '#F7F8F9',
      marginBottom: 32,
    },
    filterByListContainer: {
      paddingLeft: 36,
      margin: 0,
    },
  })
)

export default useStyles
