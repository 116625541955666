import React from 'react'
import { Box, LinearProgress as MUILinearProgress } from '@material-ui/core'
import cx from 'classnames'

import { useStyles } from './LinearProgress.styles'
import { formatNumber } from 'helpers/utility'

interface LinearProgressInterface {
  value: number
  total: number
  label?: string | JSX.Element
  hideProgressDetails?: boolean
  classes?: {
    root?: string
  }
}

const LinearProgress = (props: LinearProgressInterface) => {
  const classes = useStyles()
  const { value, total, label, hideProgressDetails, classes: override } = props
  let percentage = Math.round((value / total) * 100)

  percentage = !isNaN(percentage) ? percentage : 0

  return (
    <div className={cx(classes.barBox, override?.root)}>
      {typeof hideProgressDetails === 'undefined' ? (
        <div className={classes.progressDetails}>
          {typeof label === 'string' ? <label>{label}</label> : label}
          <label>
            {formatNumber(value)}
            <span>({percentage}%)</span>
          </label>
        </div>
      ) : null}

      <Box sx={{ width: '100%' }}>
        <MUILinearProgress
          classes={{
            root: classes.root,
            determinate: classes.determinate,
            bar: classes.bar,
          }}
          variant="determinate"
          value={percentage}
        />
      </Box>
    </div>
  )
}

export default LinearProgress
