import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  resolveDetailsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: '#F4F4F4',
    padding: '.85rem',
    paddingTop: '.5rem',
  },

  resolveDetails: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '10px 10px 0 0',
    padding: '1rem',
  },

  title: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },

  timeline: {
    minHeight: '100px',
  },

  step: {
    margin: '.4rem 0',
    fontWeight: 'bold',
  },

  phone: {
    color: '#ED164E',
    fontWeight: 'bold',
    fontSize: '1.3rem',
  },

  removeLeftDefaultContent: {
    '&:before': {
      flex: 0,
      padding: 0,
    },
  },

  label: {
    boxShadow: 'none',
    background: '#fff',
    color: '#a6a6a6 !important',
  },

  labelFocused: {
    color: '#ED164E !important',
  },

  required: {
    color: '#ED164E',
    fontSize: 12,
  },

  dot: {
    color: '#fff',
    width: '2rem',
    height: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ED164E',
  },

  placeholder: {
    color: '#A6A6A6',
    fontSize: '.85rem',
  },

  fieldContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  },

  textarea: {
    '& .MuiInputBase-multiline': {
      padding: '0',
    },
  },

  error: {
    '& textArea': {
      borderColor: '#ed154e !important',
    },
  },

  paper: {
    padding: theme.spacing(1),
    backgroundColor: 'transparent',
  },

  inputRoot: {
    fontSize: '.85rem',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ed154e',
      border: '1px solid #ed154e',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ed154e',
      border: '1px solid #ed154e',
    },
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  script: {
    color: '#ED164E',
    cursor: 'pointer',
    borderBottom: '1px solid red',
    fontSize: '.9rem',
  },

  scriptVariable: {
    color: '#ed164e',
  },

  accordion: {
    border: '0px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },

  accordionExpanded: {
    margin: 'auto',
  },

  accordionDetails: {
    backgroundColor: '#f5f5f5',
    border: '1px solid rgba(0, 0, 0, .125)',
    paddingTop: '1.2rem',
  },

  accordionSummary: {
    padding: '0',
    minHeight: '20px',
    '& .Mui-expanded': {
      marginTop: '0',
      padding: '0',
      minHeight: '20px',
    },
    '&.Mui-expanded': {
      minHeight: '20px',
    },
    '& .MuiAccordionSummary-content': {
      marginBottom: '.5rem',
    },
  },
}))
