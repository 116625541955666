import React, { PropsWithChildren } from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import {
  MuiThemeProvider,
  createTheme,
  makeStyles,
  createStyles,
} from '@material-ui/core/styles'

// TOOLTIP STYLE OVERRIDE
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        boxShadow: '0 4px 20px rgb(0 0 0 / 12%), 0 6px 12px rgb(0 0 0 / 8%)',
        color: '#000',
        backgroundColor: '#fff',
        borderRadius: '5px',
        border: '1px solid #d3d3d3',
        padding: 0,
      },
    },
  },
})

type Prop = {
  content: string | JSX.Element
  children: JSX.Element
}

const CustomTooltip = (props: Prop) => {
  const content =
    typeof props.content === 'string' ? (
      <TooltipContent>{props.content}</TooltipContent>
    ) : (
      props.content
    )
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Tooltip title={content}>{props.children}</Tooltip>
      </MuiThemeProvider>
    </div>
  )
}

export default CustomTooltip

const TooltipContent = (props: PropsWithChildren<{}>) => {
  const classes = useTooltipContentStyles()
  return (
    <div className={classes.metricTooltip}>
      <div className={classes.tooltipTitle}>{props.children}</div>
    </div>
  )
}

const useTooltipContentStyles = makeStyles(
  createStyles({
    metricTooltip: {
      backgroundColor: '#FFF',
      borderRadius: '5px',
      border: '1px solid #d3d3d3',
      boxShadow: '0 4px 20px rgb(0 0 0 / 12%), 0 6px 12px rgb(0 0 0 / 8%)',
      zIndex: 10,
      transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
    },
    tooltipTitle: {
      borderBottom: '1px solid #F3F3F3',
      padding: '.7rem 1rem',
      fontSize: '.8rem',
      fontWeight: 'bold',
      color: '#007AFF',
    },
  })
)
