import Account from 'models/Account'

export const formatTextByIndustry = (options: {
  account: Account
  text: string
}) => {
  const { account, text } = options
  const clientLabel = account.clientLabel ?? ''
  const businessLabel = account.businessLabel ?? ''
  const healthcare = [
    'Chiropractor',
    'Dental',
    'Dental DSO',
    'Healthcare',
    'Orthodontics',
    'Physical Therapy',
  ]

  let _text = text.replace(
    '{CLIENT_LABEL}',
    clientLabel.charAt(0).toUpperCase() + clientLabel.slice(1)
  )
  _text = _text.replace('{BUSINESS_LABEL}', businessLabel.toLowerCase())

  if (!healthcare.includes(account.industry ?? ''))
    _text = _text.replace('doctor and ', '')

  return _text
}
