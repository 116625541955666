import API, { IDENTITY_API } from '../../helpers/api'

const getSettings = async () => {
  let userSettings = await API.get({
    api: IDENTITY_API,
    url: 'user/settings',
  })

  return userSettings
}

export default getSettings
