import { makeStyles } from '@material-ui/core/styles'

// @todo technical debt update eslint
// eslint-disable-next-line no-undef
export type UseStyleType = Partial<ReturnType<typeof useStyles>>

export const useStyles = makeStyles(() => ({
  root: {},

  // CARD OVERRIDE
  cardRoot: {
    position: 'relative',
  },

  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },

  filters: {
    display: 'flex',
    gap: '1rem',
    marginBottom: 16,
    '@media screen and (max-width: 780px)': {
      flexDirection: 'column',
      padding: '0px 17px',
    },
  },

  // Search

  searchInput: {
    flex: 1,
  },
}))
