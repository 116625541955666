import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'

import useStyles from './DialogActionButton.style'

export default function DialogActionButton({
  children,
  checked = false,
  submitting = false,
  onClick = () => {},
}: {
  children: React.ReactNode
  checked?: boolean
  submitting?: boolean
  onClick?: () => void
}) {
  const styles = useStyles()

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      fullWidth={true}
      onClick={onClick}
      className={styles.root}
      disabled={!checked || submitting}
      endIcon={submitting && <CircularProgress size={18} />}
    >
      {children}
    </Button>
  )
}
