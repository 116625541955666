import React, { useCallback, useEffect } from 'react'
import { Observer } from 'mobx-react'

import FilterField, { FilterFieldProp } from './FilterField'
import { useCallTableFilterStore } from '../../store/CallTableFilterStore'
import { useStores } from 'config/store'

const key = 'wrongSourceMentioned'
const label = 'Wrong Source Mentioned'

const source: FilterFieldProp['source'] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

const  WrongSourceMentioned = () => {
  const { callsStore } = useStores()
  const store  = useCallTableFilterStore()
  const filter = store.filterRegistry.get(key)
  const initialValue = callsStore.filters[key]

  useEffect(() => {
      store.registerFilter(key, { 
        label,
        value: Boolean(initialValue),
        active: Boolean(initialValue),
      })
  }, [])

  const onChange = useCallback((value: FilterFieldProp['value']) => {
    store.setFilterValue(key, value ?? null)
    store.runFilter()
  }, [])

  return (
    <Observer
      render={() => {
        const isShown = filter?.active ?? false
        const value = filter?.value ?? null

        return (
          <FilterField
            multiple={false}
            label={label}
            value={value}
            source={source}
            shown={isShown}
            onChange={onChange}
          />
        )
      }}
    />
  )
}

export default WrongSourceMentioned