import React from 'react'

// Mui
import { useTheme } from '@material-ui/core/styles'
import { Avatar, Paper } from '@material-ui/core'
import { Person as ProfileIcon } from '@material-ui/icons'

// Util
import { Observer } from 'mobx-react'
import cx from 'classnames'

// My util
import useStyles from './UserAccountSelector.styles'
import { useStores } from 'config/store'

interface Prop {
  drawerOpen: () => void
}

const UserAccountSelector = (props: Prop) => {
  const { drawerOpen } = props
  const theme = useTheme()
  const classes = useStyles()
  const { appState } = useStores()

  const handleMultipleExpand = () => {
    drawerOpen()
  }

  return (
    <Observer
      render={() => {
        const { profileName, profilePicture } = appState

        return (
          <Paper className={classes.paper} elevation={4}>
            <div className={classes.root}>
              {profilePicture ? (
                <Avatar
                  src={profilePicture}
                  className={classes.avatar}
                  onClick={handleMultipleExpand}
                />
              ) : (
                <Avatar
                  onClick={handleMultipleExpand}
                  className={classes.avatar}
                  style={{ backgroundColor: theme.palette.primary.light }}
                >
                  <ProfileIcon
                    className={classes.avatar}
                    style={{
                      width: 30,
                      height: 30,
                      marginRight: 0,
                      color: '#fff',
                    }}
                  />
                </Avatar>
              )}
              <div className={cx(classes.details)}>
                <div className={classes.profileName}>
                  {profileName}
                  {appState.isLimitedAccess() && ' (Limited Access)'}
                </div>
              </div>
            </div>
          </Paper>
        )
      }}
    />
  )
}

export default UserAccountSelector
