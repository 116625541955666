import React from 'react'
import CallDetails from '../CallDetails/CallDetails'
import AppointmentTable from '../AppointmentTable/AppointmentTable'
import ResolveOutcome from '../ResolveOutcome/ResolveOutcome'

// STYLES
import { useStyles } from './CallDetailsWrapper.style'
import { CircularProgress } from '@material-ui/core'
import { useStores } from 'config/store'
import { Observer } from 'mobx-react'

const CallDetailsWrapper = () => {
  const classes = useStyles()
  const { callStore } = useStores()
  const resolveDate = callStore.drawer?.resolveDate

  return (
    <Observer
      render={() => {
        const { callStore } = useStores()
        const call = callStore.drawer

        return !call && callStore.isLoading ? (
          <div className={classes.loader}>
            <div className={classes.loaderContent}>
              <CircularProgress size={60} color="primary" />
              <div className={classes.loaderCaption}>Loading details...</div>
            </div>
          </div>
        ) : (
          <div className={classes.callDetailsWrapper}>
            <CallDetails call={call} />
            {resolveDate && <ResolveOutcome />}
            <AppointmentTable call={call} />
          </div>
        )
      }}
    />
  )
}

export default CallDetailsWrapper
