// LIB
import { observable, action, reaction, toJS, IReactionDisposer } from 'mobx'

// MODELS
import Call, { repository as callsRepo } from 'models/Call'

import getCallById from 'services/calls/getCallById'

export class CallStore {
  @observable isDirty: boolean = false
  @observable isLoading: boolean = false
  @observable drawerOpen: boolean = false

  // States for Call details drawer
  @observable drawer: Call | null = null

  monitorDirtyDisposer: IReactionDisposer | undefined

  makeReaction() {
    this.monitorDirtyDisposer = this.monitorDirty()
  }

  dispose() {
    this.monitorDirtyDisposer && this.monitorDirtyDisposer()
  }

  // ACTIONS
  @action
  dirty(isDirty: boolean): void {
    this.isDirty = isDirty
  }

  @action.bound
  setDrawer(call: Call | null) {
    this.drawer = call
  }

  @action.bound
  openDrawer(state: boolean) {
    this.drawerOpen = state
  }

  @action
  async fetchCall(id: string): Promise<Call | undefined> {
    this.isLoading = true
    try {
      const callId = await getCallById(id)
      this.isLoading = false

      return callsRepo.get(callId)
    } catch (e) {
      /**
       * @todo Do something with the error
       */
      console.log(e)
    }
  }

  monitorDirty(): IReactionDisposer {
    return reaction(
      () => toJS(this.isDirty),
      async () => {
        if (this.isDirty) {
          this.dirty(false)
        }
      }
    )
  }
}

const callsStore = new CallStore()
export default callsStore
