import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    // backgroundColor: 'rgb(0 0 0 / 23%)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1000,
  },

  content: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },

  icon: {},

  caption: {
    marginTop: 0,
    fontWeight: 600,
    fontSize: '16px',
    color: '#666',
  },
})

// @todo technical debt update eslint
// eslint-disable-next-line no-undef
export type useStyleType = Partial<ReturnType<typeof useStyles>>
