import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: 'center',
      marginTop: 55,
      marginBottom: 44,
      width: '100%',
      '& h2': {
        fontWeight: 'bold',
        fontSize: 30,
      },
    },
    containerMobile: {
      marginTop: 35,
      marginBottom: 24,
      '& h2': {
        fontSize: 24,
      },
    },
  })
)
