// @flow

import _isEmpty from 'lodash/isEmpty'

import API, { DATA_API } from 'src/helpers/api'

import accountStore from 'src/config/store/AccountStore'

/**
 *
 * @param {object} options
 */
export default async ({ filters }) => {
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    return {
      meta: {},
      records: [],
    }
  }

  const data = {
    accountIds,
    filters,
  }

  const result = await API.get({
    api: DATA_API,
    url: '/call-summary-count',
    params: data,
  })

  const {
    all_calls_count,
    all_appointment_set_by_staff_count,
    opportunity_calls_by_staff_count,
    opportunity_calls_count,
    appointment_calls_count,
    calls_with_Appointments_count,
    missed_calls_count,
    non_opportunity_calls_count,
    followup_opportunity_calls_count,
  } = result

  return {
    all: all_calls_count,
    allAppointmentSetByStaff: all_appointment_set_by_staff_count,
    opportunityByStaff: opportunity_calls_by_staff_count,
    opportunity: opportunity_calls_count,
    nonOpportunity: non_opportunity_calls_count,
    appointment: appointment_calls_count,
    callsWithAppointment: calls_with_Appointments_count,
    missed: missed_calls_count,
    followUp: followup_opportunity_calls_count,
  }
}
