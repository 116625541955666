import { observable, computed, action } from 'mobx'

import Repository from './Repository'
import { repository as MailingZones } from './MailingZone'

export const CampaignType = {
  NewMover: 'New Mover',
  MvpMail: 'MVP Mail',
  CallTracking: 'Call Tracking',
  Custom: 'Custom'
}

/**
 *
 */
class MailingAreaRepository extends Repository {
  /**
   *
   * @param {String} geodataId
   * @returns {any}
   */
  findByGeodataId(geodataId) {
    for (let area of this.objects.values()) {
      if (area.geodataId === geodataId) {
        return area
      }
    }

    return undefined
  }
}

export const repository = new MailingAreaRepository()

export default class MailingArea {

  @observable isLoading = false
  @observable isError = false

  @observable id
  @observable name
  @observable accountId
  @observable account
  @observable archived
  @observable campaignType
  @observable quantity
  @observable color
  @observable listProviderUrl
  @observable mailingDateFrom
  @observable mailingDateTo
  @observable mailingsCount
  @observable geodataId
  @observable carrierRoutes = []
  @observable zones = []

}
