import React from 'react'
import { Observer } from 'mobx-react'
import {
  withStyles,
} from '@material-ui/core/styles'

import { Media } from 'react-media-player'
import { Player } from 'react-media-player'
import CallAudioControls from './CallAudioControls'

const styles = (theme) => ({
  media: {
    width: '100%'
  },
  playBtnStyle: {
    position: 'relative',
    cursor: 'pointer',
    top: -2,
    marginRight: 8,
  },
  containerStyle: {
    backgroundColor: '#232323',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 32px',
    position: 'relative',
    height: 80,
    paddingTop: 6
  },
  timeStyle: {
    fontSize: 12,
    fontWeight: 400,
    minWidth: 48,
    marginTop: -6,
    linHeight: 1.3,
    display: 'block',
    textAlign: 'center'
  }
})

export const CallAudioPlayer = (props) => {
  const { classes, call, householdId } = props

  return (
    <Media>
      <div className={classes.media}>
        <div className="media-player">
          <Player id="call-audio" src={call.callAudio} vendor={'audio'} autoPlay={false} />
        </div>
        <div id="media-controls">
          <CallAudioControls householdId={householdId} callDuration={call.callDuration}/>
        </div>
      </div>
    </Media>
  )
}

export default withStyles(styles)(CallAudioPlayer)
