import _capitalize from 'lodash/capitalize'

import { useStores } from 'config/store'
import { CallOutcome } from 'interfaces/Call.interface'

export default function useFilters() {
  const { globalFilterStore, dashboardUIStore, callsStore } = useStores()

  const getFilterCallTypeFmt = () => {
    const outcome = callsStore.filters.outcome
    const outcomeTypes: { [key: string]: string } = {
      [CallOutcome.OPPORTUNITY]: 'Opportunity calls',
      [CallOutcome.APPOINTMENT]: 'Appointment calls',
      [CallOutcome.MISSED_CALL]: 'Missed calls',
      [CallOutcome.FOLLOWUP]: 'Follow-up calls',
      [CallOutcome.NON_OPPORTUNITY]: 'Non-Opportunity calls',
      [CallOutcome.ALL_CALLS]: 'All calls',
      [CallOutcome.MISSED_AND_FOLLOWUP]: 'Missed and Followup calls',
    }
    return outcomeTypes[outcome] ?? ''
  }

  const getFilterDateRangeFmt = () => {
    const dateFilter = globalFilterStore.dateFilter
    const dateFilterType = dateFilter.dateFilterType
    const dateFilterTypeFmt =
      dateFilterType === 'all' ? 'All time' : _capitalize(dateFilterType)

    const dateFormat = 'MMM D, YYYY'
    const globalFiltersQuery = dashboardUIStore.globalFiltersQuery
    const callDateTime = globalFiltersQuery.callDateTime
    const from = callDateTime.gt.format(dateFormat)
    const to = callDateTime.lt.utc().format(dateFormat)
    const dateRangeFmt = `(${from} - ${to})`

    return `${dateFilterTypeFmt} ${dateRangeFmt}`
  }

  const getFilterCampaignTypeFmt = () => {
    const campaignFilterType =
      globalFilterStore.campaignFilter.campaignFilterType
    const campaignFilterTypes = {
      all: 'All campaigns',
      'mvp-mail': 'Mailing campaigns',
      'non-mailing': 'Non-mailing campaigns',
    }

    return campaignFilterTypes[campaignFilterType]
  }

  const filters: string[] = [
    getFilterCallTypeFmt(),
    getFilterDateRangeFmt(),
    getFilterCampaignTypeFmt(),
  ]

  return filters
}
