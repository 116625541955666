import { createStyles } from '@material-ui/core'

const styles = createStyles({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '5rem 1fr 1.5rem',
    background: '#F7F7F7',
    margin: '1.4rem 25px 0px 25px',
    fontSize: '4rem',
    padding: '.7rem',
    color: '#FADCE5',
    boxShadow: '1px 4px 6px 0px rgba(0,0,0,0.16)',
    border: '2px solid #ffff',
    transition: 'all .3s',
  },
  img: {
    width: '4.3rem',
    color: '#ED154E',
    fontWeight: 'bold',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '.5rem',
    color: '#000',
  },
  title: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
  },
  subText: {
      margin: '.4rem 0 0 0',
      fontSize: '.95rem',
      lineHeight: '20px',
  },
  close: {
    color: '#D3D3D3',
    cursor: 'pointer',
  },
  highlight: {
    color: '#ED154E',
    fontSize: '1.4rem',
  },
  submitLink: {
    color: '#ED154E',
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
      },
  },
})


export default styles
