import AppState from 'config/store/AppState'
import API, { DATA_API } from 'helpers/api'
import accountStore from 'config/store/AccountStore'
import { processReceiversSettingsData } from './processContactSettingsData'

export default async () => {
  const userContactId = AppState.contactId
  const accountIds = (accountStore._selectedAccounts || []).slice()

  const params = {
    accountIds
  }

  function fetchReceiversSetting () {
    return API.get({
      api: DATA_API,
      url: '/settings/account',
      params: params
    })
  }

  function fetchAllContacts() { 
    return API.get({
      api: DATA_API,
      url: '/accounts/contacts',
      params: params
    })
  }
  
  const [userSettings, allContacts] = await Promise.all([fetchReceiversSetting(), fetchAllContacts()])
  
  return processReceiversSettingsData(userContactId, userSettings.notification, allContacts.records)
}