import React from 'react'
import { observer } from 'mobx-react'

import Button from '@material-ui/core/Button'

import Mailing from 'models/Mailing'
import { useDeliveredMailingCardActionsStyles } from './DeliveredMailingCardActions.style'

const DeliveredMailingCardActions: React.FC<{
  mailing: Mailing
  onAction: (action: string, data: Mailing) => void
}> = ({ mailing, onAction }) => {
  const classes = useDeliveredMailingCardActionsStyles()

  const handleAction = (action: string, mailing: Mailing) => {
    onAction(action, mailing)
  }

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        color="primary"
        onClick={() => handleAction('view', mailing)}
      >
        {'View Performance'}
      </Button>
    </div>
  )
}

export default observer(DeliveredMailingCardActions)
