import React from 'react'
import {
  Dialog as MuiDialog,
  useMediaQuery,
  Theme,
  DialogProps,
} from '@material-ui/core'

import useStyles from './Dialog.style'

type Prop = {
  children: React.ReactNode
  open?: boolean
  escapable?: boolean
  setOpen?: (open: boolean) => void
}

export default function DialogDirectDownload({
  children,
  open = false,
  escapable = true,
  setOpen = (_) => {},
}: Prop) {
  const styles = useStyles()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const handleClose: DialogProps['onClose'] = (_, reason) => {
    escapable && setOpen(false)
  }

  return (
    <>
      <MuiDialog
        onClose={handleClose}
        open={open}
        classes={{ paper: styles.dialogPaper }}
        fullScreen={isDesktop === false}
      >
        {children}
      </MuiDialog>
    </>
  )
}
