import { observable } from 'mobx'
import Repository from '../Repository'

export const repository = new Repository()

export default class ProductInquiry {
  @observable id: string = ''
  @observable name: string = ''
  @observable productId: string = ''
  @observable productName: string = ''
  @observable callId: string = ''

  constructor(productPlacement: any) {
    this.id = productPlacement.id
    this.name = productPlacement.name
    this.productId = productPlacement.productId
    this.productName = productPlacement.productName
    this.callId = productPlacement.callId
  }
}
