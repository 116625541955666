import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import MUITablePagination from '@material-ui/core/TablePagination'

import PaginationActions from './PaginationActions'

const propTypes = {
  classes: PropTypes.object.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,

  totalItems: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number)
}

const defaultProps = {
  totalItems: 0,
  initialPage: 1,
  initialPageSize: 15,
  pageSizeOptions: [5, 10, 15, 30, 45]
}

const styles = {

}

class Pagination extends React.Component {
  constructor(props) {
    super(props)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
  }

  handleChangePage(event, currentPage) {
    // MUI TablePagination component uses 0-based page indexing
    this.props.onChangePage(currentPage + 1)
  }

  handleChangeRowsPerPage(event) {
    this.props.onChangePageSize(event.target.value)
  }

  render() {
    const { classes, totalItems, pageSize, currentPage } = this.props
    return (
      <TableFooter>
        <TableRow>
          <MUITablePagination
            className="mvp-pagination"
            classes={{
              spacer: classes.spacer,
              select: classes.select,
              caption: classes.caption
            }}
            colSpan={3}
            count={totalItems}
            rowsPerPage={pageSize}
            page={currentPage - 1}
            labelRowsPerPage="Per Page"
            labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} of ${count} items`}
            rowsPerPageOptions={this.props.pageSizeOptions}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    )
  }
}

Pagination.propTypes = propTypes
Pagination.defaultProps = defaultProps

export default withStyles(styles)(Pagination)
