import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      display: 'flex',
      '& $col': {
        padding: '8px 50px',
      },
      '& $col:first-child': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '& $col:last-child': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        marginBottom: 30,
        marginTop: 35,
        '& $col': {
          padding: '0px',
        },
      },
    },

    col: {
      flexBasis: '25%',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        '& > label': {
          marginBottom: 12,
        },
        marginBottom: 12,
      },
    },
    tooltip: {
      backgroundColor: '#222',
      '&::after': {
        display: 'none',
      },
    },

    grade_icon: {
      color: '#d3d3d3',
      verticalAlign: 'middle',
      height: 18,
      width: 18,
      marginLeft: 4,
    },

    switchWrapper: {
      marginRight: 12,
    },
  })
)
