/**
 * ******************************************
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React, { PropsWithChildren } from 'react'

// MUI
import { makeStyles } from '@material-ui/styles'

// Just a dropdown button without menu items 😜
const useStyle = makeStyles(() => ({
  root: {
    margin: -25,
  },
}))

export default function PageContainer(props: PropsWithChildren<{}>) {
  const classes = useStyle()
  return <div className={classes.root}>{props.children}</div>
}
