import React, { useState, Fragment } from 'react'
import cx from 'classnames'

import check from '../../assets/img/check.svg'
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import styles from './LoginForm.styles'

import AppState from 'config/store/AppState'
import EmailOrPhoneField from './components/EmailOrPhoneField'
import sendSetupLink from 'services/users/sendSetupLink'

export enum Theme {
  Light = 'light',
  Dark = 'dark',
}

interface VerificationLinkFormProps {
  handleChangeForm: Function
  theme: Theme
}

const VerificationLinkForm: React.FC<VerificationLinkFormProps> = (props) => {
  const { handleChangeForm, theme } = props
  const classes = styles()
  const [isSending, setIsSending] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const handleSend = async (isResend = false) => {
    try {
      setIsSending(true)
      const status = await sendSetupLink(AppState.contactId)
      setIsSending(false)
      setIsSent(true)
      if (isResend) {
        AppState.successMessage('Setup link sent. Please check your email.')
        AppState.toggleFlash(true)
      } else {
        return status
      }
    } catch (e) {
      AppState.errorMessage('There was a problem sending the verification link')
      AppState.toggleFlash(true)
      setIsSending(false)
    }
  }

  return (
    <Fragment>
      <form onSubmit={(e) => e.preventDefault()} className={classes.form}>
        {isSent ? (
          <Fragment>
            <img src={check} alt="Check" className={classes.formTopIcon} />
            <h1 className={cx(theme === Theme.Light && classes.black)}>
              Setup Link Sent!
            </h1>
            <p
              style={{
                fontSize: 14,
                marginTop: 0,
              }}
            >
              We have sent a link to
              <a href={`mailto:${AppState.profileEmail}`}>
                {` ${AppState.profileEmail} `}
              </a>
              for you to finish setting up your account. Once done, log-in from
              there or refresh this page for full access.
            </p>

            <p className={classes.resend}>
              Didn't receive the setup-link?{' '}
              <a
                onClick={() => {
                  handleSend(true)
                }}
              >
                {isSending ? 'Sending...' : 'Resend Link'}
              </a>{' '}
              {isSending && (
                <CircularProgress size={15} className={classes.resendLoader} />
              )}
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <img
              src={`https://ui-avatars.com/api/?rounded=true&background=c60032&color=fff&size=55&name=${encodeURI(
                AppState.profileName
              )}`}
              alt="Avatar"
              className={classes.formTopIcon}
            />
            <h1
              className={cx(
                theme === Theme.Light && classes.black,
                classes.headingEmail
              )}
            >
              {AppState.profileEmail || ''}
            </h1>
            <div className={classes.warning}>
              You haven't <strong>set up your password</strong> yet, please set
              <br />
              your password first to verify your identity.
            </div>
            <EmailOrPhoneField
              defaultValue={AppState.profileEmail || ''}
              theme={theme}
              passwordFieldVisible={false}
              onChange={() => {}}
            />
            <Button
              color="primary"
              onClick={() => handleSend()}
              disabled={isSending}
              type="submit"
              classes={{
                root: classes.submit,
                disabled: classes.btnDisabled,
              }}
            >
              Send Me Setup Link
              {isSending && <CircularProgress className={classes.loader} />}
            </Button>
          </Fragment>
        )}
        <a onClick={() => handleChangeForm(true)} className={classes.alternate}>
          Verify with <strong>verification link</strong> instead
        </a>
      </form>
    </Fragment>
  )
}

export default VerificationLinkForm
