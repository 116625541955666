import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionExpanded: {
      marginTop: '0px !important',
      marginBottom: '0px !important',
      borderBottom: '1px solid #d3d3d3',
    },
    accordionSummaryExpanded: {
      minHeight: 'auto !important',
      margin: '0px !important',
    },
    expandIcon: {
      marginRight: '-12px !important',
    },
    accordionHeading: {
      fontWeight: 'bold',
      fontSize: 14,
      textTransform: 'uppercase',
    },
    accordionRoot: {
      borderBottom: '1px solid #d3d3d3',
      '&:before': {
        display: 'none',
      },
    },

    headingAccordion: {
      padding: '6px 14px',
      pointerEvents: 'none',
      fontSize: 14,
      '& $accordionHeading': {
        fontSize: 16,
      },
    },
    accordionWrapper: {
      borderRadius: 10,
      overflow: 'hidden',
      border: '1px solid #d3d3d3',
      marginBottom: 12,
    },
    accordionDetailsRoot: {
      paddingTop: 0,
    },
  })
)
