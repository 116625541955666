import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {},
  callDetailsContainer: {
    backgroundColor: '#F4F4F4',
    padding: '.85rem',
  },
  callDetails: {
    backgroundColor: '#fff',
    borderRadius: '10px 10px 0 0',
    padding: '1rem',
  },
  tableWrapper: {
    borderTop: '1px solid #ddd',
    borderLeft: '1px solid #ddd',

    display: 'grid',
    gridTemplateColumns: 'repeat(2, 8rem 1fr)'
  },
  callType: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    paddingBottom: '.7rem',
  },
  cell: {
    borderRight: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
    padding: '.5rem',
    fontSize: '.85rem',

    display: 'flex',
    alignItems: 'center',
  },
  rowData: {
    fontWeight: 'bold',
  },
  cellSpanAll: {
    display: 'grid',
    gridColumn: 'span 4'
  },
  audio: {
    border: '1px solid #ddd',
  },
  media: {
    display: 'none',
  },
  callDetailsContainerMobile: {
    '& $tableWrapper': {
      gridTemplateColumns: '8rem 1fr'
    },
    '& $cellSpanAll': {
      gridColumn: 'span 2',
    }
  },
})
