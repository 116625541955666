import React from 'react'
import cx from 'classnames'

// STYLES
import { useStyles } from './KPIValues.styles'
import { preparePercentageValues } from '../../Utils'
import classNames from 'classnames'

interface KPIValuesInterface {
  data: {
    percentage: number
    label: string
    color: string
    details?: string | number
  }[]
  isAccordionContent?: boolean
}

const KPIValues = (props: KPIValuesInterface) => {
  const classes = useStyles()
  const { data, isAccordionContent } = props

  return (
    <div
      className={classNames(
        classes.container,
        isAccordionContent ? classes.containerMobile : null
      )}
    >
      {data.map((item) => {
        let percentageValue = preparePercentageValues({
          percentage: item.percentage,
          decimalPlaces: 0,
        }).valueRounded

        percentageValue = !isNaN(parseInt(percentageValue))
          ? percentageValue
          : '0'

        return (
          <div className={classes.metricContainer}>
            <label className={classes.percentage} style={{ color: item.color }}>
              {percentageValue}%{' '}
            </label>
            <label className={classes.legend}>
              <span
                style={{ background: item.color }}
                className={classes.bullet}
              ></span>
              {item.label} {item.details ? `(${item.details})` : '(0)'}
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default KPIValues
