import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) =>
  createStyles({
    inputRowContainer: {
      display: 'flex',
      marginBottom: '20px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    passwordContainer: {
      display: 'flex',
      marginLeft: '-8%',
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
      },
    },

    fieldContainer: {
      flexBasis: '82%',
      '& > div': {
        width: '100%',
      },
      '& input': {
        backgroundColor: 'transparent',
        fontWeight: '400',
        borderColor: '#d3d3d3',
        fontFamily: 'Roboto ',
        color: '#141414',
      },
      [theme.breakpoints.down('md')]: {
        flexBasis: '100%',
      },
    },

    labelContainer: {
      flexBasis: '18%',
      display: 'flex',
      alignItems: 'center',
      '& label': {
        color: '#141414',
        fontFamily: 'Roboto ',
      },
    },

    error: {
      color: '#F50D0D',
      fontStyle: 'italic',
      flexBasis: '57.1%',
      fontFamily: 'Roboto ',
      fontSize: '13px',
      position: 'relative',
      left: '13px',
      lineHeight: '1.2',
      display: 'flex',
      alignItems: 'center',
    },

    errorPassStrength: {
      position: 'relative',
      flexBasis: '57.1%',
      '& > div': {
        display: 'block',
        position: 'absolute',
        [theme.breakpoints.down('md')]: {
          left: 0,
          marginTop: 8,
          position: 'relative',
        },
      },
      '& ul': {
        marginTop: 0,
      },
    },

    errorPartialPass: {
      color: '#4caf50',
    },

    confirmSection: {
      textAlign: 'right',
      paddingRight: '36.5%',
      '& > button:first-child': {
        marginRight: '8px',
        borderRadius: 0,
      },
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
      },
    },

    spinner: {
      marginRight: 8,
      color: '#333',
      opacity: 0.9,
    },
  })
)
