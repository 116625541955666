import { ReactNode } from 'react'

export interface CallSummaryCountInterface {
  all: number
  allAppointmentSetByStaff: number
  opportunityByStaff: number
  opportunity: number
  appointment: number
  callsWithAppointment: number
  missed: number
  followUp: number
  nonOpportunity: number
}

export interface CampaignInterface {
  Id: string
  Name: string
  checked: boolean
  CreatedDate: Date
}

export interface StaffInterface {
  id: string
  name: string
  appointmentsMadeCount: number
  staffType: string
  rank: number
  conversionRate: number
  callMadeCount: number
  askedforSource: number
  goodImpressionRate: number
  goodImpressionCount: number
  askedForSourceRate: number
  askedForSourceCount: number
  askedforAppointmentRate: number
  askedForAppointmentCount: number
  askedforAdditionalAppointmentRate: number
  askedforAdditionalAppointmentCount: number

  opportunityCallCount: number
  callHandlerId: number
  accountName: string
  appointmentsCallCount: number
}

export interface CallFilters {
  outcome: CallOutcome
  answeredBy?: string[]
  callHandlerId?: string | null
  hasAudio?: boolean | null
  [key: string]: any
}

export interface CallPaginationInterface {
  page: number
  pageSize: number
  sort: string
  dir: CallSortDirection
  searchQuery: string
}

export interface CallsPerHourInterface {
  callCount: number
  missedCallCount: number
  name: string
}

export interface CallerGenderInterface {
  male: number
  female: number
  unknown: number
}

export interface PaymentTypesInterface {
  cash: number
  insurance: number
  inHousePlan: number
  unknown: number
}

export interface AggregatedPicklistInterface {
  name: string
  value: number
}

export interface CallOfficeHandlingRateInterface {
  totalCalls: number

  appointmentRate: number
  askedForAppointmentCount: number
  validAskedForAppointment: number
  askedForAppointmentCallerMentioned: number

  askedForSourceRate: number
  askedForSourceCount: number
  validAskedForSource: number
  askedForSourceCallerMentioned: number

  askedForGoodImpressionRate: number
  askedForGoodImpressionCount: number
  validAskedForGoodImpressionCount: number

  callerInteractedWithIVRRate: number
  callerInteractedWithIVRCount: number
  validCallerInteractedWithIVRCount: number

  askedForAdditionalAppointmentRate: number
  askedForAdditionalAppointmentCount: number
  validAskedForAdditionalAppointment: number
  askedForAdditionalAppointmentCallerMentioned: number
}

export interface CallOnHoldMetricsInterface {
  staffAskedToPlaceOnHold: number
  staffPlacedCallerOnHold: number
  totalCalls: number
}

export interface Tab {
  index: number
  value: CallDashboardTabs | CallOutcome
}

export interface TabContents {
  title: string
  panel: ReactNode
}

export interface ResolveFields {
  callId?: string
  scoreId?: string
  callbackHandler?: StaffInterface
  callbackOutcomeResult: string
  callbackOutcomeOpportunityResult: string
  callbackOutcomeSummary: string
}

export enum CallDashboardTabs {
  OVERVIEW = 'Overview',
  FRONT_DESK = 'Front Desk',
  ALL_CALLS = 'All Calls',
}

export enum SortCriteria {
  CALLS = 'Calls',
  OPPORTUNITY = 'Opportunities',
  APPOINTMENTS = 'Appointments',
  APPT_CALLS = 'Appt Calls',
  CONVERSIONRATE = 'Conversion Rate',
}

export enum CallOutcome {
  OPPORTUNITY = 'Opportunity',
  APPOINTMENT = 'Appointment',
  APPOINMENT_MADE = 'Appointment Made',
  MISSED_CALL = 'Missed Call',
  FOLLOWUP = 'Followup',
  NON_OPPORTUNITY = 'Non-Opportunity',
  ALL_CALLS = 'All Call',
  MISSED_AND_FOLLOWUP = 'Missed and Followup',
}

export enum CallSortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum AppointmentFormFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  APPT_DATE = 'date',
  APPT_TIME = 'time',
}

export enum ResultCallback {
  SPOKE_WITH_CALLER = 'Spoke With Caller',
  NO_ANSWER_LEFT_VOICEMAIL = 'No Answer, Left Voicemail',
  NO_ANSWER_NO_VOICEMAIL = 'No Answer, No Voicemail',
}

export enum OpportunityResult {
  NEW_PATIENT_OPPORTUNITY = 'New Patient Opportunity',
  NOT_PATIENT_OPPORTUNITY = 'NOT a Patient Opportunity',
  UNKOWN = 'Unknown',
  SPAM = 'Spam',
}

export enum KPIChart {
  ASKED_FOR_APPOINTMENT = 'Asked For Appointment',
  ASKED_FOR_SOURCE = 'Asked For Source',
  GOOD_FIRST_IMPRESSION = 'Good First Impression',
  ASKED_FOR_ADDITIONAL_APPOINTMENTS = 'Asked For Additional Appointments',
  CALLER_INTERACTED_WITH_IVR = 'Caller Interacted With IVR',
}
