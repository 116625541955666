import API, { DATA_API } from 'helpers/api'
import { NotificationSettingsData } from 'components/Settings/AlertType/AlertType'

export default async (data: Map<string, NotificationSettingsData>) => {
  const records = Array.from(data.values()).map((item) => {
    const method = []
    if (item.email) {
      method.push('Email')
    }
    if (item.sms) {
      method.push('SMS')
    }

    return {
      id: item.id,
      contactId: item.contactId,
      frequency: item.frequency,
      method: method,
      alertType: item.alertType
    }
  })

  const result = await API.post({
    api: DATA_API,
    url: '/settings/contacts',
    data: records
  })

  if (!result) {
    return []
  }

  return result
}