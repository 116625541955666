import ApiHelper, { IDENTITY_API } from '../../helpers/api'

/**
 * Recover password. Send recovery password to email
 *
 *
 * @param {string} email
 */
export const resetPassword = async (email) => {
  const response = await ApiHelper.post({
    skipAuthentication: true,
    api: IDENTITY_API,
    url: '/users/resetPassword',
    data: {
      email
    }
  })

  return response
}

export default resetPassword
