import { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { usePreviousLocation } from 'routes/history'
import Call, { repository as callsRepo } from 'models/Call'

// STORE
import { useStores } from 'config/store'

interface CallsState {
  stopAudio: boolean
  preventFromClose?: boolean
}

const useSidePanel = () => {
  const history = useHistory()
  const previousLocation = usePreviousLocation()
  const params = useParams<{ callId: string }>()
  const location = useLocation()
  const { callStore } = useStores()
  const [state, setStateBase] = useState<CallsState>({
    stopAudio: false,
    preventFromClose: false,
  })
  const { stopAudio } = state
  const { drawerOpen, openDrawer } = callStore

  const setState = (newState: CallsState) => {
    setStateBase({ ...state, ...newState })
  }

  useEffect(() => {
    if (params.callId) {
      let call = callsRepo.get(params.callId)

      setState({
        stopAudio: true,
      })

      if (!call) {
        callStore.fetchCall(params.callId).then((call: any) => {
          callStore.setDrawer(call)
        })
      } else {
        callStore.setDrawer(call)
      }

      openDrawer(true)
    }
  }, [location])

  const handleSelectCall = async (callId: string) => {
    setState({
      stopAudio: false,
    })
    openDrawer(true)

    if (!callsRepo.get(callId)) {
      callStore.setDrawer(null)
      setState({
        stopAudio: true,
        preventFromClose: true,
      })
      const call: any = await callStore.fetchCall(callId)
      callStore.setDrawer(call)
      setState({
        stopAudio: true,
        preventFromClose: false,
      })
    }

    history.push({
      pathname: `/scoreboard/${callId}`,
      search: location.search,
    })
  }

  const handleCloseDrawer = () => {
    if (!state.preventFromClose) {
      setState({
        stopAudio: false,
      })
      openDrawer(false)

      if (previousLocation === null) {
        history.push('/scoreboard')
      } else {
        history.goBack()
      }
    }
  }
  return { drawerOpen, handleCloseDrawer, handleSelectCall }
}

export default useSidePanel
