import { observable, computed } from 'mobx'
import _get from 'lodash/get'
import Repository from './Repository'
import { repository as householdRepo } from './Household'

export const repository = new Repository()

export default class PatientLists {

  @observable id
  @observable name
  @observable account
  @observable audit
  @observable icon
  @observable archived
  @observable sourceUrl
  @observable fromDate
  @observable toDate
  @observable households = []
  @observable metadata
  @observable summary

  @computed get hasHouseholds() {
    return this.households && this.households.length > 0 || false
  }

  @computed get getAuditSummary() {
    if (!this.audit) {
      return {}
    }

    const { strongMatches, weakMatches, noMatches } = _get(this.audit, 'summary')
    const totalCampaigns = _get(this.audit, 'campaignsIds') && _get(this.audit, 'campaignsIds').length || 0

    return {
      strongMatches,
      weakMatches,
      noMatches,
      totalCampaigns,
    }
  }
}
