import AppState from 'src/config/store/AppState'
import API, { DATA_API } from 'src/helpers/api'
import { repository as callsRepo } from 'src/models/Call'
import Appointment, {
  repository as appointmentsRepo,
} from 'src/models/Appointment'
import Call from 'src/models/Call'

/**
 * @param {Call} call the call object that this appointment belongs to
 * @param {string} dateTime date in ISO 8601 format
 * @param {string} firstName the patient's first name
 * @param {string} lastName the patient's last name
 *
 * @return {Appointment} a new appointment object
 */
export default async (call, dateTime, firstName, lastName, scoreId) => {
  const data = {
    call: call.id,
    campaign: call.campaignId,
    contact: AppState.contactId,
    dateTime,
    firstName,
    lastName,
    scoreId,
  }

  const result = await API.post({
    api: DATA_API,
    url: '/appointments',
    data,
  })

  // We are only interested in the id returned
  if (result.id) {
    const appointment = new Appointment()
    appointment.id = result.id
    appointment.dateTime = dateTime
    appointment.lastName = lastName
    appointment.firstName = firstName
    appointment.contactId = AppState.contactId
    appointment.scoreId = scoreId
    appointmentsRepo.upsert(appointment)

    return appointment
  }

  throw new Error('Failed to create new appointment')
}
