import _get from 'lodash/get'
import _uniq from 'lodash/uniq'
import accountStore from 'src/config/store/AccountStore'
import API, { DATA_API } from 'src/helpers/api'
import { repository as MailingAreas } from 'src/models/MailingArea'
import { repository as MailingZones } from 'src/models/MailingZone'

import getGeodata from '../geodata/getGeodata'
import processMailingAreaData from './processMailingAreaData'

/**
 *
 * @param {object} options
 * @return MailingArea[]
 */
const getMailingAreas = async ({ accounts, cancelToken } = {}) => {

  const selectedAccounts = accountStore.selectedAccounts.slice()

  if (!selectedAccounts.length) {
    return {
      mailingAreas: [],
      locations: []
    }
  }

  const results = await API.get({
    api: DATA_API,
    url: '/mailing-areas',
    params: {
      accounts: selectedAccounts
    },
    cancelToken: cancelToken.token
  }) || []

  const locations = _get(results, 'meta.locations', [])
  const data = _get(results, 'records', [])

  const geodataIds = []
  const mailingAreas = data.map(row => {
    const mail = processMailingAreaData(row)

    if (!mail.zones.length && mail.geodataId) {
      geodataIds.push(mail.geodataId)
    }

    mail.zones.forEach((zoneId) => {
      const zone = MailingZones.get(zoneId)
      if (zone.geodataId) {
        geodataIds.push(zone.geodataId)
      }
    })
    return MailingAreas.upsert(mail)
  })

  if (geodataIds.length) {
    await getGeodata({ geodataIds: _uniq(geodataIds) })
  }

  return {
    mailingAreas,
    locations
  }
}

export default getMailingAreas
