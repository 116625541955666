import React, { useState } from 'react'
import moment from 'moment'
import Call, { repository as callsRepo } from 'models/Call'
import Appointment from 'models/Appointment'

import { Recording } from 'components/CallPro/Recording/Recording'
import { AppointmentPatientLabel } from 'components/CallPro/Appointments/AppointmentPatientLabel'
import { MailingType } from 'components/CallPro/MailingType/MailingType'
import Tooltip from '@material-ui/core/Tooltip'
import DescriptionIcon from '@material-ui/icons/Description'

// STORE
import { useStyles } from './CallsTableColumns.style'
import { getLabelWrapper } from './CallsTableColumns'
import { formatTextByIndustry } from 'helpers/industries'
import Account from 'models/Account'

export interface CallProColumns {
  id: string
  name: string
  sortable: boolean
  styles?: any
}

export const callProCallsColumns: (account: Account) => CallProColumns[] = (
  account
) => {
  return [
    {
      id: '',
      name: '',
      sortable: false,
    },
    {
      id: 'callDateTime',
      name: formatTextByIndustry({
        account,
        text: `{CLIENT_LABEL} Name & Phone`,
      }),
      sortable: true,
    },
    {
      id: 'schedule',
      name: 'Schedule',
      sortable: false,
    },
    {
      id: 'answeredBy',
      name: 'Answered By',
      sortable: false,
    },
    {
      id: 'mailing',
      name: 'Type',
      sortable: false,
    },
    {
      id: 'status',
      name: 'Status',
      sortable: true,
    },
    {
      id: 'callSummary',
      name: 'Call Summary',
      sortable: false,
    },
    {
      id: 'duration',
      name: 'Recording',
      sortable: true,
    },
  ]
}

interface renderCallProDashboardColumnsArgs {
  appointments: Appointment[]
  pageSize: number
  currentPage: number
  stopAudio: boolean
}

export const renderCallProDashboardColumns = ({
  appointments,
}: renderCallProDashboardColumnsArgs): any => {
  const classes = useStyles()
  const [currentlyPlaying, setIsCurrentlyPlaying] = useState('')

  return appointments.map((appointment: Appointment, key: number) => {
    const id = appointment.callId
    const dateTime = moment(appointment.dateTime)

    const call = {
      id,
      campaignTargetDeliveryWeek: appointment.campaignTargetDeliveryWeek,
      campaignType: appointment.campaignType,
      callDuration: appointment.callDuration
        ? parseInt(appointment.callDuration)
        : 0,
      callAudio: appointment.callAudioURL,
    }

    const resolved = !!appointment.callResolveDate
    const appointmentsCount = appointment.count ?? 0
    const label = getLabelWrapper(
      appointment.outcome ?? [],
      appointmentsCount,
      classes,
      resolved,
      true
    )

    const answeredBy = appointment.callHandler ?? appointment.answeredBy

    return {
      rowJsx: [
        <span>{key + 1}</span>,
        <AppointmentPatientLabel appointment={appointment} />,
        dateTime.isValid() ? dateTime.format('M/D/YY @ h:mm a') : '-',
        <span>{answeredBy}</span>,
        <MailingType call={call as Call} />,
        label,
        <Tooltip
          hidden={!appointment.callSummary}
          title={appointment.callSummary ?? ''}
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <div>
            <DescriptionIcon className={classes.highlightIcon} />
          </div>
        </Tooltip>,
        <Recording
          handleAudioPlayPause={(id) => {
            setIsCurrentlyPlaying(id)
          }}
          call={call as Call}
          currentlyPlaying={currentlyPlaying}
        />,
      ],
      id: id,
    }
  })
}
