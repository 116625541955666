import React, { useState } from 'react'
import clsx from 'clsx'
import { StatCardProps } from './StatCard.props'
import { useStatCardStyles } from './StatCard.style'
import Skeleton from '@material-ui/lab/Skeleton'
import CountUp from 'react-countup'

const StatCard = (props: StatCardProps) => {
  const {
    count,
    icon,
    label,
    children,
    tooltipText,
    isLoading,
    handleClick,
    callOutcome,
    actionLabel,
  } = props
  const classes = useStatCardStyles()

  const [showTooltip, setShowTooltip] = useState(false)

  const toggleShowTooltip = (show: boolean) => {
    setShowTooltip(show)
  }

  return (
    <div
      className={clsx(classes.card)}
      onMouseEnter={() => toggleShowTooltip(true)}
      onMouseLeave={() => toggleShowTooltip(false)}
      onClick={() => handleClick && handleClick(callOutcome)}
    >
      <div className={clsx(classes.cardDetails)}>
        <div className={clsx(classes.cardIcon)}>{icon}</div>

        <div className={clsx(classes.cardCount)}>
          <div className={clsx(classes.cardCountLabel)}>{label}</div>
          {isLoading ? (
            <Skeleton
              variant="rect"
              width={120}
              height={40}
              style={{ borderRadius: 4 }}
            />
          ) : children ? (
            children
          ) : (
            <div className={clsx(classes.cardCountValue)}>
              <CountUp
                separator={','}
                duration={0}
                start={0}
                end={count ?? 0}
              />
            </div>
          )}
        </div>
      </div>

      <div className={clsx(classes.cardActions)}>
        <div className={clsx(classes.cardActionView)}>
          {actionLabel && actionLabel}
        </div>
      </div>
      {!isLoading && tooltipText && showTooltip && (
        <div className={classes.metricTooltip}>
          <div className={classes.tooltipTitle}>{label}</div>
          <div className={classes.tooltipBody}>{tooltipText}</div>
        </div>
      )}
    </div>
  )
}

export default StatCard
