import { observable } from 'mobx'

import Repository from './Repository'

export const repository = new Repository()

export default class Household {

  @observable id
  @observable account
  @observable source
  @observable count
  @observable lat
  @observable lng
  @observable address
  @observable metadata
  @observable production
  @observable consolidatedMatch

}
