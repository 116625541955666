import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progressWrapper: {
      marginTop: 24,
    },
    legend: {
      marginBottom: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
      '& p': {
        position: 'relative',
        paddingLeft: 17,

        '&:nth-child(1):before': {
          background: '#4398E7',
        },
        '&:nth-child(2):before': { background: '#A9CBEA' },
        '&:nth-child(3):before': {
          background: '#E1E1E1',
        },
        '&:before': {
          content: '""',
          width: 10,
          height: 10,
          position: 'absolute',
          top: 3,
          display: 'inline-block',
          left: 0,
        },
        fontSize: 12,
        marginBottom: 0,
        marginTop: 4,
        color: '#818181',
      },
    },
    disclaimer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '.8rem',
      marginTop: 'auto',
      backgroundColor: '#fbfbfb',
      padding: '.5rem',
      '@media screen and (max-width: 780px)': {
        fontSize: 10,
      },
    },
    disclaimerIcon: {
      marginRight: '.5rem',
      fontSize: '1rem',
    },
    chartContainer: {
      marginTop: 30,
    },
  })
)
