import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #ebe7e7',
    borderTop: '1px solid #ebe7e7',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fbfbfb',
    flexDirection: 'column-reverse',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  tabsRoot: {
    height: '64px',
    padding: '0 1.5rem',
    '&.MuiTabs-flexContainer': {
      flex: 1,
    },
  },
  tabsFlexContainer: {
    flex: 1,
    '&.MuiTab-root': {
      flex: 1,
    },
  },
  tabsScroller: {
    display: 'flex',
    color: '#000',
  },

  tabItem: {
    height: '100%',
    textTransform: 'capitalize',
    fontSize: '.95rem',
    fontWeight: 'bold',
  },

  filters: {
    display: 'flex',
    padding: '1rem 1.5rem',
    gap: '.5rem',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '24px',
      padding: '0',
    },
  },

  filterItem: {
    textTransform: 'none',
    padding: '8px 16px',
  },
  filterItemDisabled: {
    opacity: 0.25,
  },
  filterItemLabel: {
    color: '#141414',
  },
  filterItemIcon: {
    width: 16,
    height: 16,
  },
  filterItemSelected: {
    color: theme.palette.primary.main,
  },
  filterItemText: {},
  advanced: {
    marginLeft: 10,
    borderColor: 'rgb(220,220,220)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '0px',
    paddingLeft: 10,
    paddingRight: 15,
    paddingTop: 3,
    paddingBottom: 3,
    backgroundColor: '#fff',
    color: '#141414',
    '&$filterItemSelected': {
      borderColor: 'rgb(242,242,242)',
      color: theme.palette.primary.main,
      backgroundColor: 'rgb(242,242,242)',
    },
    '&$filterItemSelected $icon': {
      color: theme.palette.primary.main,
    },
    '&$filterItemSelected span': {
      color: theme.palette.primary.main,
    },
    '& span': {
      color: '#141414',
    },
    '&:hover': {},
  },
  icon: {
    marginRight: 6,
    width: '0.7em',
    height: '0.7em',
  },
}))

export default useStyles
