import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import cx from 'classnames'

// STYLES
import {
  useStyles,
  useStyleType,
} from 'components/ProgressIndicator/LoadingData.styles'

interface LoadingDataProp {
  classes?: useStyleType
  // @type {boolean} - Remove or add to the DOM.
  hidden?: boolean
}

export default (props: LoadingDataProp) => {
  const { classes, hidden } = props
  const styles = useStyles()

  if (hidden === true) {
    return <></>
  }

  return (
    <div className={cx(styles.root, classes?.root)}>
      <div className={cx(styles.content, classes?.content)}>
        <div className={cx(styles.icon, classes?.icon)}>
          <CircularProgress size={60} color="primary" />
        </div>
        <div className={cx(styles.caption, classes?.caption)}>
          Loading data. Please wait...
        </div>
      </div>
    </div>
  )
}
