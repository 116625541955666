import { DesignConcepts } from '@mvp/mvp-react'

export const getDesignConcepts = (designs: any[]) => {
  const designConcepts: DesignConcepts = {}

  designs.forEach((record: any) => {
    const frontLastModified = record.currentVersion.frontDesignLastModified
    const frontUrlParams = frontLastModified ? `?mvp=${frontLastModified}` : ''

    const backLastModified = record.currentVersion.backDesignLastModified
    const backUrlParams = backLastModified ? `?mvp=${backLastModified}` : ''

    designConcepts[record.id] = {
      accountId: record.accountId,
      name: record.name,
      front: {
        id: record.id,
        src: `${record.currentVersion.frontDesignUrl}${frontUrlParams}`,
      },
      back: {
        id: record.id,
        src: `${record.currentVersion.backDesignUrl}${backUrlParams}`,
      },
    }
  })

  return designConcepts
}
