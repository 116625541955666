import React from 'react'
import moment from 'moment'
import {
  Assignment as OrderNumberIcon,
  Dashboard as MailingTypeIcon,
  DateRange as DeliveryWeekIcon,
  PermMedia as QuantityIcon,
  SelectAll as QRcodeIcon,
} from '@material-ui/icons'

import { observer } from 'mobx-react'
import { useStyles } from './MailingInfo.styles'
import { useStores } from 'config/store'
import configStore from 'config/store/ConfigStore'
import numeral from 'numeral'
import { Theme, useMediaQuery } from '@material-ui/core'
import classNames from 'classnames'
import { formatNumber } from 'helpers/utility'

interface IMailingInfo {}

export const MailingInfo = (props: IMailingInfo) => {
  const classes = useStyles()
  const { mailingsStore } = useStores()
  const mailing = mailingsStore.drawer
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  if (!mailing) {
    return <div />
  }

  const week = moment(mailing.deliveryWeek)
  const deliveryWeek = week.isValid()
    ? week.format(configStore.dateFormat)
    : '-'

  return (
    <table
      cellSpacing="0"
      className={classNames(
        classes.table,
        mobileDevice ? classes.mobileTable : null
      )}
    >
      <tr>
        <td>
          <DeliveryWeekIcon className={classes.icon} />
          <span className={classes.label}>Delivery Week:</span>
        </td>
        <td>
          <span className={classes.text}>{deliveryWeek}</span>
        </td>
      </tr>
      {mailing.quantity ? (
        <tr>
          <td>
            <QuantityIcon className={classes.icon} />
            <span className={classes.label}>Quantity:</span>
          </td>
          <td>
            <span className={classes.text}>
              {numeral(mailing.quantity).format('0,0')} pcs
            </span>
          </td>
        </tr>
      ) : null}
      <tr>
        <td>
          <OrderNumberIcon className={classes.icon} />
          <span className={classes.label}>Order Number:</span>
        </td>
        <td>
          <span className={classes.text}>{mailing.name}</span>
        </td>
      </tr>
      <tr>
        <td>
          <MailingTypeIcon className={classes.icon} />
          <span className={classes.label}>Campaign Type:</span>
        </td>
        <td>
          <span
            className={classes.text}
            style={{ textTransform: 'capitalize' }}
          >
            {(mailing.campaignType && mailing.campaignType.toLowerCase()) ||
              '-'}
          </span>
        </td>
      </tr>

      <tr>
        <td>
          <MailingTypeIcon className={classes.icon} />
          <span className={classes.label}>Media Type:</span>
        </td>
        <td>
          <span
            className={classes.text}
            style={{ textTransform: 'capitalize' }}
          >
            {(mailing.mediaType && mailing.mediaType.toLowerCase()) ||
              '-'}
          </span>
        </td>
      </tr>

      {mailing.qrScans > 0 ? (
        <tr>
          <td>
            <QRcodeIcon className={classes.icon} />
            <span className={classes.label}>QR Code Scans:</span>
          </td>
          <td>
            <span
              className={classes.text}
              style={{ textTransform: 'capitalize' }}
            >
              {formatNumber(mailing.qrScans)}
            </span>
          </td>
        </tr>
      ) : null}
    </table>
  )
}

export default observer(MailingInfo)
