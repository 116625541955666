import MailingZone from 'src/models/MailingZone'
import { repository as mailingsRepo } from 'src/models/Mailing'
import processMailData from '../mailings/processMailData'

const processMailZoneData = result => {
  const zone = new MailingZone()

  zone.id = result.id
  zone.name = result.name
  zone.mailingAreaId = result.mailingAreaId
  zone.quantity = result.quantity
  zone.color = result.color
  zone.geodataId = result.geodataId

  const carrierRoutes = result.carrierRoutes.filter(a => !!a)
  zone.carrierRoutes.replace(carrierRoutes)
  zone.mailings.replace((result.mailings || []).map(row => {
    const mailing = processMailData(row)
    return mailingsRepo.upsert(mailing)
  }))

  return zone
}

export default processMailZoneData
