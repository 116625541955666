import React, {
  Fragment,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Grid, Tooltip, CircularProgress } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Observer } from 'mobx-react'

import { useStyles } from './AlertSettings.styles'

import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase'
import AppState from 'config/store/AppState'

import AlertType, { NotificationSettingsData } from './AlertType/AlertType'
import ManageReceivers, { alertTypeDefaultFrequency, alertTypeFrequencyOptions } from './ManageReceivers/ManageReceivers'
import {
  useUserNotificationSettingsQuery,
  useReceiversNotificationSettingsQuery,
  useUpsertNotificationSettings,
} from './hooks/index'

import ConfirmDialog from 'components/MVP/Dialog/ConfirmDialog'
interface AlertSettingsProps {
  ref: React.MutableRefObject<null>
  isPrimaryContact: boolean
}

// TODO: Add ALERT TYPE to the array to activate
const activeAlertSettings = ['Missed Call Alert', 'New Appointment Call Alert', 'New Opportunity Call Alert', 'First Opportunity Alert', 'En Route Alert']

const AlertSettings = forwardRef<{}, AlertSettingsProps>(
  ({ isPrimaryContact }, ref) => {
    const classes = useStyles()
    useImperativeHandle(ref, () => {
      return {
        saveNotificationSettings: saveNotificationSettings,
      }
    })

    const filterUserSettings = (_map: Map<string, NotificationSettingsData>) => {
      return new Map(
        Array.from(_map).filter(([_key, value]) => {
          // TODO: Remove the second condition if all alert types are activated
          return value.contactId === AppState.contactId && activeAlertSettings.includes(value.alertType)
        })
      )
    }

    const {
      data: userSettings = new Map(),
      status,
    } = useUserNotificationSettingsQuery()

    const {
      data: receiversData = new Map(),
      status: receiversStatus,
    } = useReceiversNotificationSettingsQuery(isPrimaryContact)
    const { mutateAsync } = useUpsertNotificationSettings()

    const [allUpdatedSettings, setAllUpdatedSettings] = useState<
      Map<string, NotificationSettingsData>
    >(new Map())
    const [currentlyUpdatedReceiversSetting, setCurrentlyUpdatedReceiversSetting] = useState<
      Map<string, NotificationSettingsData>
    >(new Map())

    const [openDialog, setOpenDialog] = useState(false)
    const [confirmCancel, setConfirmCancel] = useState(false)
    const [alertType, setAlertType] = useState('')

    const allNotificationSettings = new Map([...receiversData, ...allUpdatedSettings])
    const currentUserSettings = filterUserSettings(new Map([...userSettings, ...allUpdatedSettings]))

    const saveNotificationSettings = async () => {
      try {
        const result = await mutateAsync(allUpdatedSettings)
        return result
      } catch (error) {
        console.error(error)
      }
    }
    
    const onFrequencyChange = (
      value: NotificationSettingsData,
      frequency: string
    ) => {
      const key = kebabCase(value.alertType + '-' + value.contactId)
      setAllUpdatedSettings(
        new Map(allUpdatedSettings.set(key, { ...value, frequency: frequency }))
      )
    }

    const onEmailChange = (value: NotificationSettingsData, checked: boolean) => {
      const key = kebabCase(value.alertType + '-' + value.contactId)
      const _value = {...value, email: checked}
      setAllUpdatedSettings(
        new Map(allUpdatedSettings.set(key, { ...value, ..._value }))
      )
    }

    const onSmsChange = (value: NotificationSettingsData, checked: boolean) => {
      const key = kebabCase(value.alertType + '-' + value.contactId)
      const _value = {...value, sms: checked}
      setAllUpdatedSettings(
        new Map(allUpdatedSettings.set(key, { ...value, ..._value }))
      )
    }

    const handleShowModal = (alertType: string) => {
      setAlertType(alertType)
      setOpenDialog(true)
    }

    const handleCloseModal = () => {
      setAlertType('')
      setOpenDialog(false)
      setCurrentlyUpdatedReceiversSetting(new Map())
    }

    if (status === 'loading') {
      return (
        <div>
          <CircularProgress size={14} className={classes.spinner} />
        </div>
      )
    }

    if (status === 'error') {
      return (<div>Error fetching data</div>)
    }

    return (
      <Observer
        render={() => (
          <Fragment>
            <Grid item xs={7} style={{ marginTop: '40px' }}>
              <div className={classes.row}>
                <div className={classes.col}>
                  <h4 className={classes.heading}>Notifications</h4>
                </div>
              </div>
            </Grid>
            <hr className={classes.separator} />
            <div className={classNames(classes.row, classes.rowHeading)}>
              <div className={classes.col}></div>
              <div className={classes.col}>
                <label>Receive alert from</label>
              </div>
              <div className={classes.col}>
                <label>Frequency</label>
              </div>
              {isPrimaryContact && (
                <div className={classes.col}>
                  <label>
                    Alert Receivers{' '}
                    <Tooltip
                      title={'Current Receivers'}
                      placement="bottom-start"
                      classes={{
                        tooltip: classes.tooltip,
                      }}
                    >
                      <InfoOutlinedIcon className={classes.grade_icon} />
                    </Tooltip>
                  </label>
                </div>
              )}
            </div>

            {Array.from(currentUserSettings.values()).map((value) => {
              return (
                <>
                  <AlertType
                    isPrimaryContact={isPrimaryContact}
                    alertType={value.alertType}
                    tooltip={value.alertType}
                    onFrequencyChange={onFrequencyChange}
                    onEmailChange={onEmailChange}
                    onSmsChange={onSmsChange}
                    data={value}
                    handleShowModal={handleShowModal}
                    alertTypeFrequencyOptions={alertTypeFrequencyOptions[value.alertType]}
                  />
                  <hr className={classes.separator} />
                </>
              )
            })}
            <ManageReceivers
              openDialog={openDialog}
              alertType={alertType}
              setOpenDialog={setOpenDialog}
              allNotificationSettings={allNotificationSettings}
              allUpdatedSettings={allUpdatedSettings}
              setAllUpdatedSettings={setAllUpdatedSettings}
              setConfirmCancel={setConfirmCancel}
              status={receiversStatus}
              currentlyUpdatedReceiversSetting={currentlyUpdatedReceiversSetting}
              setCurrentlyUpdatedReceiversSetting={setCurrentlyUpdatedReceiversSetting}
            />
            <ConfirmCancelDialog
              setConfirmCancel={setConfirmCancel}
              handleCloseModal={handleCloseModal}
              confirmCancel={confirmCancel}
            />
          </Fragment>
        )}
      />
    )
  }
)

interface ConfirmCancelDialogProps {
  setConfirmCancel: (isOpen: boolean) => void
  confirmCancel: boolean
  handleCloseModal: () => void
}

const ConfirmCancelDialog = ({
  setConfirmCancel,
  confirmCancel,
  handleCloseModal,
}: ConfirmCancelDialogProps) => {
  return (
    <ConfirmDialog
      cancelLabel="Keep editing"
      confirmLabel="Discard changes"
      onCancel={() => {
        setConfirmCancel(false)
      }}
      onConfirm={() => {
        setConfirmCancel(false)
        handleCloseModal()
      }}
      children={
        'You will loose all the changes made to Manage Receivers dialog.'
      }
      hideActions={false}
      open={confirmCancel}
      title={'Discard changes?'}
    />
  )
}

export default AlertSettings
