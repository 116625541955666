/**
 * ******************************************
 * Designed to be used with compoenents/Page/AppBar.tsx
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React from 'react'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import { Button, ButtonProps } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

// UTIL
import cx from 'classnames'

interface Prop extends ButtonProps {
  label: string | JSX.Element
  isOpened?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  classes?: Partial<ReturnType<typeof useStyle>>
}

/**
 * Just a dropdown button without menu items 😜
 */
export default function Select(props: Prop) {
  const classes = useStyle()

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        classes={{
          root: cx(classes.root, props?.classes?.root),
          label: cx(classes.label, props?.classes?.label),
        }}
        onClick={props?.onClick ?? (() => {})}
        ref={props?.ref}
      >
        {props.label}
        <ExpandMore
          className={cx(classes.arrowIcon, {
            [classes.rotate]: props.isOpened,
          })}
        />
      </Button>
    </>
  )
}

const useStyle = makeStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '.35rem 1rem',
    backgroundColor: '#fff',
    paddingLeft: 14,
    paddingRight: 7,
    color: '#444',
    fontSize: '.9rem',
    minWidth: '10rem',
  },
  label: {
    fontFamily: theme.typography.fontFamily,
  },
  arrowIcon: {
    marginLeft: '.5rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
}))
