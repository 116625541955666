import env from 'env'
import Http from 'helpers/http/http'
import interceptors from 'helpers/http/interceptors'

export const IDENTITY_API = 'identity'
export const DATA_API = 'data'
export const MAPS_API = 'maps'

const ENDPOINTS = {
  [DATA_API]: env.services.data.baseUrl ?? '',
  [IDENTITY_API]: env.services.identity.baseUrl ?? '',
  [MAPS_API]: env.services.maps.baseUrl ?? '',
}

const http = new Http(ENDPOINTS)

interceptors.forEach((func) =>
  http.client.interceptors.response.use((response) => response, func)
)

export default http

export const vanillaHttp = new Http(ENDPOINTS)
