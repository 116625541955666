import React from 'react'
import { Link } from '@material-ui/core'
import { runInAction } from 'mobx'

import { useStores } from 'config/store'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'
import ProductStore from 'config/store/CallPro/Product/ProductStore'

// STYLES
import { useStyles } from './AllServiceSection.style'

const AllServiceSection = () => {
  const classes = useStyles()

  const { productStore, callsStore, dashboardUIStore } = useStores()
  const { productInquiriesBarChartData: list } = productStore

  const total = list.length
  const isEmpty = total === 0

  function handleItemClick(item: Item) {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS
      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters({ outcome, service: [item.id] })
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  return (
    <section className={classes.root}>
      <div className={classes.container}>
        <div className={classes.title}>
          All Requested Services {isEmpty ? '' : `(${total})`}
        </div>
        {isEmpty ? (
          <div className={classes.rowEmpty}>Service list is empty</div>
        ) : (
          <Table list={list} onItemClick={handleItemClick} />
        )}
      </div>
    </section>
  )
}

type Item = (typeof ProductStore)['productInquiriesBarChartData'][number]

type TableProp = {
  list: Item[]
  onItemClick: (item: Item) => void
}

const Table = (props: TableProp) => {
  const classes = useStyles()
  const { list, onItemClick } = props

  const handleItemClick = (item: Item) => () => {
    onItemClick(item)
  }

  return (
    <>
      <div className={classes.row}>
        <div className={classes.rowData}>#</div>
        <div className={classes.rowData}>Service Name</div>
        <div className={classes.rowData}>Count</div>
      </div>
      {list.map((item, index) => {
        return (
          <div className={classes.row}>
            <div className={classes.rowData}>{index + 1}</div>
            <div className={classes.rowData}>
              <Link
                component="button"
                variant="body1"
                underline="none"
                color="initial"
                onClick={handleItemClick(item)}
                classes={{ root: classes.link }}
              >
                {item.name}
              </Link>
            </div>
            <div className={classes.rowData}>{item.value}</div>
          </div>
        )
      })}
    </>
  )
}

export default AllServiceSection
