import API, { DATA_API } from 'helpers/api'

interface ApproveCampaignMedia {
  id: string
}

export const approveCampaignMedia = async ({ id }: ApproveCampaignMedia) => {
  await API.post({
    api: DATA_API,
    url: `/campaigns/${id}/design/approve`,
  })
}
