import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) =>
  createStyles({
    modalTitle: {
      color: '#141414',
      fontSize: '20px !important',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      paddingBottom: '1rem',
      borderBottom: 'solid 1px rgb(234,234,234)',
      textTransform: 'capitalize'
    },
    tableWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr'
    },
    tableRow: {
      display: 'grid',
      gridTemplateColumns: '20rem 15rem 15rem',
      borderBottom: 'solid 1px rgb(234,234,234)',
      marginBottom: '1rem',
      paddingBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
      '&:last-child': {
        border: 'none',
        paddingBottom: '0',
      },
    },
    tableHeader: {
      display: 'grid',
      gridTemplateColumns: '20rem 15rem 15rem',
      paddingBottom: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    col: {
      flexBasis: '25%',
      display: 'flex',
      alignItems: 'center',
    },
    tooltip: {
      backgroundColor: '#222',
      '&::after': {
        display: 'none',
      },
    },
    grade_icon: {
      color: '#d3d3d3',
      verticalAlign: 'middle',
      height: 18,
      width: 18,
      marginLeft: 4,
    },
    switchWrapper: {
      marginRight: 12,
    },
    footerActions: {
      display: 'flex', 
      justifyContent: 'end', 
      paddingRight: '1rem',
      paddingBottom: '1rem'
    },
    spinner: {
      marginRight: 8,
      color: '#333',
      opacity: 0.9,
    },
  })
)
