import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { runInAction } from 'mobx'
import { Link, CircularProgress } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { useStores } from 'config/store'
import { dateFormat } from 'config/callpro.config'
import { CallsStore } from 'config/store/CallPro/Call/CallsStore'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'

import { LinearProgress } from '../LinearProgress'
import { ChartCard } from 'components/CallPro/ChartCard'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'
import { useStyles } from './PatientTypes.styles'
import { formatTextByIndustry } from 'helpers/industries'
import Account from 'models/Account'

interface PatientTypesInterface {
  isAccordionContent?: boolean
}

type PatientTypeItem = CallsStore['patientTypes']['records'][number]

const PatientTypes = (props: PatientTypesInterface) => {
  const { isAccordionContent } = props
  const { callsStore, accountStore, dashboardUIStore } = useStores()
  const classes = useStyles()

  const { patientTypes: _patientTypes, loadingStates } = callsStore
  const patientTypes = _patientTypes.records
  const firstRecordDate = _patientTypes.firstRecordDate
  const total = patientTypes.reduce(
    (accumulator, currentElement) => accumulator + currentElement.value,
    0
  )
  const account: Account = accountStore.accounts[0]
  const clientLabel = account.clientLabel ?? ''

  const handleItemClick = (item: PatientTypeItem) => () => {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS
      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters({
        outcome,
        patientType: [item.name],
      })
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  return (
    <ChartCard
      headerTitle={formatTextByIndustry({
        account,
        text: '{CLIENT_LABEL} TYPES',
      })}
      tooltipText={`This shows the different ${clientLabel.toLowerCase()} types of callers. (i.e. New versus existing ${clientLabel.toLowerCase()}.)`}
      headerTotalText="Total Calls"
      headerTotalValue={total}
      isAccordionContent={isAccordionContent}
    >
      {loadingStates.patientTypes ? (
        <div
          style={{
            textAlign: 'center',
            padding: '2rem',
          }}
        >
          <CircularProgress />
        </div>
      ) : total > 0 ? (
        patientTypes.map((patientType, key) => {
          return (
            <LinearProgress
              key={`item-${key}`}
              value={patientType.value}
              total={total}
              label={
                <Link
                  component="button"
                  variant="body1"
                  underline="none"
                  color="initial"
                  onClick={handleItemClick(patientType)}
                  classes={{ root: classes.link }}
                >
                  {patientType.name}
                </Link>
              }
            />
          )
        })
      ) : (
        <EmptyBarChart />
      )}
      {firstRecordDate.length ? (
        <div className={classes.disclaimer}>
          <InfoOutlinedIcon className={classes.disclaimerIcon} />
          The data used in this graph is derived from the calls we scored since{' '}
          {moment(firstRecordDate).format(dateFormat)}.
        </div>
      ) : null}
    </ChartCard>
  )
}

export default observer(PatientTypes)
