/**
 * ******************************************
 * Designed to be used with components/Page/AppBarTitleSection.tsx
 * example:
 * ```jsx
 * <AppBar>
 *  <AppBarTitleSection>
 *    <AppbarToolbar>
 *      <AppBarTitle>App Page Title</AppBarTitle>
 *    </AppbarToolbar>
 *  </AppBarTitleSection>
 * </AppBar>
 * ```
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React, { PropsWithChildren } from 'react'
import { Toolbar } from '@material-ui/core'

type Prop = PropsWithChildren<{}>

export default function AppBarToolbar(props: Prop) {
  return (
    <Toolbar disableGutters variant="dense">
      {props.children}
    </Toolbar>
  )
}
