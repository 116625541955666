/**
 * ******************************************
 * Designed to be used with compoenents/Page/AppBar.tsx
 * example:
 * ```jsx
 * <AppBar>
 *  <AppBarTitleSection>
      // ... more components here
 *  </AppBarTitleSection>
 * </AppBar>
 * ```
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React from 'react'

// MUI
import { makeStyles } from '@material-ui/core/styles'

interface Prop {
  children?: React.ReactNode
}

export default function AppBarTitleSection(props: Prop) {
  const classes = useStyles()
  return <div className={classes?.root}>{props.children}</div>
}

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    minHeight: 50,
    padding: '10px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
}))
