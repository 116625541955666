const tooltip = {
	position: 'absolute',
	backgroundColor: 'rgba(225,40,63)',
	color: '#fff !important',
	width: 'auto',
	whiteSpace: 'nowrap',
	padding: '8px 12px',
	lineHeight: '1',
	fontSize: '14px',
	zIndex: '10',
	'-webkit-box-shadow': '0px 0px 30px 0px rgba(50, 50, 50, 0.26)',
	'-moz-box-shadow':    '0px 0px 30px 0px rgba(50, 50, 50, 0.26)',
	'box-shadow':         '0px 0px 30px 0px rgba(50, 50, 50, 0.26)',
	display: 'none',
	'&::before': {
		position: 'absolute',
		content: '""',
		width: '0',
		height: '0',
		borderStyle: 'solid',
		borderWidth: '14px 0 14px 20px',
		borderColor: 'transparent transparent transparent rgba(225,40,63)',
		top: '12px',
		zIndex: '-1'
	}
}

export default tooltip