import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

import useStyles from './MailingAuditNotice.style'

const MailingAuditNotice: React.FC<{
  title: string
  description: string
  children: React.ReactElement<any, any>
}> = ({ title, description, children }) => {
  const classes = useStyles()

  return (
    <Tooltip
      title={
        <>
          <h3 className={classes.title}>{title}</h3>
          <span>{description}</span>
        </>
      }
      placement="top"
      classes={{ tooltip: classes.tooltip }}
    >
      <div className={classes.container}>{children}</div>
    </Tooltip>
  )
}

export default MailingAuditNotice
