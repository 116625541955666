import React from 'react'
import AllCallNeedsFollowUpSection from './AllCallNeedsFollowUpSection/AllCallNeedsFollowUpSection'

// STYLES
import { useStyles } from './AllCallNeedsFollowUpDrawer.style'

const AllCallNeedsFollowUpDrawer = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AllCallNeedsFollowUpSection />
    </div>
  )
}

export default AllCallNeedsFollowUpDrawer
