import _isEmpty from 'lodash/isEmpty'
import API, { DATA_API } from 'helpers/api'
import accountStore from 'config/store/AccountStore'

export interface ReferralSourceInterface {
  name: string
  wrongReferralSource: boolean
  value: number
}

export default async ({ filters }: any) => {
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    return {
      meta: {},
      records: [],
    }
  }

  const data = {
    accountIds,
    filters,
  }

  const result = await API.get({
    api: DATA_API,
    url: '/get-top-referral-source',
    params: data,
  })

  const { records, firstRecordDate } = result

  return {
    records: records as ReferralSourceInterface[],
    firstRecordDate,
  }
}
