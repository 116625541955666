import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'constants/constants'

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 15,
    paddingTop: 20,
  },
  smallHeading: {
    display: 'block',
    color: 'rgb(124,124,124)',
    fontFamily: 'Roboto',
    fontSize: 14,
  },
  table: {
    width: '100%',
    fontFamily: 'Roboto',
    color: 'rgb(58,58,58)',
    borderCollapse: 'collapse',
    borderSpacing: '0',
    fontSize: 14,
    marginTop: 6,
    marginBottom: 24,
    '& thead tr': {
      backgroundColor: 'transparent !important',
    },
    '& thead tr th': {
      color: 'rgb(124,124,124) !important',
      fontFamily: 'Roboto',
      fontSize: 14,
      paddingBottom: 8,
      fontWeight: 'normal',
    },
    '& thead td:nth-child(2)': {
      textAlign: 'center',
    },
    '& td': {
      padding: '4px 8px',
      textAlign: 'center',
    },
    '& td:last-child': {
      textAlign: 'right',
    },
    '& td:nth-child(2)': {
      textAlign: 'left',
    },
    '& td:first-child': {
      color: 'rgb(188,188,188)',
      paddingLeft: 12,
      textAlign: 'left',
      width: 40,
    },
    '& tr:nth-child(odd)': {
      backgroundColor: 'rgb(244,244,244)',
    },
    '& tr:nth-child(even)': {
      backgroundColor: 'rgb(250,250,250)',
    },
  },
  map: {
    backgroundColor: 'rgba(211, 211, 211, 0.4)',
    height: 'calc(100vh - 425px)',
    width: 600,
    position: 'absolute',
    top: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      marginTop: '62px',
      marginBottom: '18px',
    },
  },
  mapMobile: { width: '100vw' },
  loadingMap: {
    fontFamily: 'Roboto',
    color: '#b9b9b9',
  },
}))
