import { Outcome } from '../models/Call'

export const getTagStyle = (outcome: Outcome, classes: any) => {
  switch (outcome) {
    case Outcome.APPOINTMENT:
      return classes.appointment
    case Outcome.MISSED_CALL:
      return classes.missedCall
    case Outcome.FOLLOWUP:
      return classes.followUpNeeded
    case Outcome.CANCELLED:
      return classes.cancelled
    case Outcome.RESCHEDULED:
      return classes.rescheduled
    case Outcome.OPPORTUNITY:
      return classes.opportunityCall
    case Outcome.NON_OPPORTUNITY:
      return classes.nonOpportunity
    default:
      return classes.default
  }
}
