import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'

import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

import background from 'src/assets/bg.png'
import logo from 'src/assets/img/mvp-logo.png'

import { defaultFont, secondaryFont } from 'src/assets/jss/mvp'
import loginFormStyle from 'src/assets/jss/material-dashboard-react/loginFormStyle'

import Checkbox from '@material-ui/core/Checkbox'
import CustomInputWithIcon from 'src/components/CustomInput/CustomInputWithIcon'
import Button from 'src/components/CustomButtons/Button'
import { Warning, Lock, Visibility, VisibilityOff } from '@material-ui/icons/'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

import verifyActivationCode from '../../services/users/verifyActivationCode'
import activateUser from 'src/services/users/activateUser'
import appState from 'src/config/store/AppState'
import mapsStore from 'src/config/store/MapsStore'
import mailingsStore from 'src/config/store/MailingsStore'
import settingsStore from 'src/config/store/SettingsStore'

const emailObservable = observable.box()
const codeObservable = observable.box()
const isActivationCodeVerified = observable.box(false)

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundSize: 'cover',
    backgroundImage: `url(${background})`,
    backgroundAttachment: 'fixed',
    'min-height': '100vh',
    height: '100%',
  },

  setupAccountBody: {
    padding: '24px !important',
  },

  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  '@media (max-width: 1024px)': {
    container: {
      flexDirection: 'column',
    },
    formContainer: {
      marginBottom: '50px',
    },
  },

  termsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '1rem',
  },

  error: {
    ...defaultFont,
    color: 'rgb(126, 127, 127)',
    fontSize: '28px',
    marginBottom: 20,
  },

  form: {
    '& [input[type="disabled"]': {
      cursor: 'not-allowed',
      color: '#141414 !important',
    },
  },
  emailDetailsWrapper: {
    textAlign: 'center',
    '& strong': {
      wordBreak: 'break-all',
    },
    '& h3': {
      fontSize: '14px',
      fontWeight: '600',
    },
  },

  avatar: {
    borderRadius: '100%',
  },

  btn: {
    marginTop: '0px !important',
  },

  errorText: {
    display: 'block',
    margin: 0,
    color: '#727272',
    fontSize: 14,
    '& a': {
      color: '#B7003D',
    },
  },

  errorContainer: {
    textAlign: 'center',
  },

  actionBtn: {
    marginTop: 30,
    marginBottom: 70,
    padding: '8px 30px',
    fontSize: 15,
    textTransform: 'inherit',
    color: '#fff !important',
    borderRadius: 3,
  },

  ...loginFormStyle,
})

@withStyles(styles)
class ErrorMessage extends Component {
  render() {
    const { classes } = this.props
    if (this.props.message) {
      return (
        <div className={`${classes.toastError} ${classes.slideDown}`}>
          <Warning /> {this.props.message}
        </div>
      )
    } else {
      return true
    }
  }
}

@inject('appState')
@withRouter
@withStyles(styles)
@observer
export default class SetupPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      password: '',
      isLoading: false,
      error: null,
      visible: false,
    }
  }

  componentDidMount() {
    const { email, code } = queryString.parse(location.search)
    const { history } = this.props

    if (email && code) {
      if (appState.isLimitedAccess()) {
        appState
          .clearSession()
          .then(() => {
            mapsStore.cleanStore()
            mailingsStore.cleanStore()
            settingsStore.cleanStore()
          })
          .catch((e) => {
            console.log(e)
          })
      }
      isActivationCodeVerified.set(false)
      emailObservable.set(email)
      codeObservable.set(code)
      verifyActivationCode(email, code)
        .then(() => {
          isActivationCodeVerified.set(true)
          history.push('/setup')
        })
        .catch((error) => {
          let state = {
            status: 'error',
            message: error.message,
          }
          const pathname = error.message.includes('activated')
            ? 'login'
            : 'activation'

          if (error.message.includes('expire')) {
            state = {
              status: 'expired',
              message: error.message,
              email,
              code,
            }
          }

          history.push({
            pathname,
            state,
          })
        })
    }
  }

  handleSubmit = (e) => {
    const { appState } = this.props
    const email = emailObservable.get()
    const code = codeObservable.get()
    const { password } = this.state
    const { history } = this.props

    if (email && password) {
      // Reset component states
      this.setState({
        isLoading: true,
        error: null,
      })

      activateUser(email, password, code)
        .then(() => {
          history.push(`/campaigns`)
        })
        .catch((error) => {
          let message = 'Failed to setup account'
          const response = error.response

          if (response && response.data && response.data.message) {
            message = response.data.message
          }
          this.setState({
            isLoading: false,
            error: message,
          })
        })
    }

    e.preventDefault()
  }

  handleChangePassword = (event) => {
    this.setState({
      password: event.target.value,
    })
  }

  renderError() {
    const { classes } = this.props
    const email = emailObservable.get()
    const code = codeObservable.get()

    if (!email || !code) {
      return (
        <div className={classes.errorContainer}>
          <h1 className={classes.error}>Page has expired</h1>
          <Button
            classes={{ button: classes.actionBtn }}
            variant="contained"
            color="primary"
            href="/login"
          >
            Login
          </Button>
          <p className={classes.errorText}>Please retry the activation link</p>
          <p className={classes.errorText}>
            or email us at{' '}
            <a href="contact@mvpmailhouse.com">contact@mvpmailhouse.com</a>
          </p>
        </div>
      )
    }
  }

  renderLoading() {
    const { classes } = this.props

    return <h1 className={classes.error}>Loading...</h1>
  }

  renderForm() {
    const { classes } = this.props
    const { password, isLoading, error, visible } = this.state
    const email = emailObservable.get()

    return (
      <React.Fragment>
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <h3 className={classes.header}>CHOOSE YOUR PASSWORD</h3>
            <div className={classes.setupAccountBody}>
              <div className={classes.emailDetailsWrapper}>
                <img
                  src={`https://ui-avatars.com/api/?background=2474e3&rounded=true&color=ffffff&size=80&name=${email.charAt(
                    0
                  )}`}
                />
                <h3>{email}</h3>
              </div>
              <CustomInputWithIcon
                type={visible ? 'text' : 'password'}
                id="password"
                placeholder="Choose your password"
                icon={<loginFormStyle.icons.Lock className={classes.icon} />}
                onChange={this.handleChangePassword}
                formControlProps={{
                  fullWidth: true,
                }}
                endAdornment={
                  <InputAdornment position="end" className={classes.adornment}>
                    <IconButton
                      className={classes.iconButton}
                      disableRipple={true}
                      onClick={() => {
                        this.setState({ visible: !visible })
                      }}
                    >
                      {visible ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff
                          style={{ position: 'relative', top: '-1px' }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <Button
                classes={{
                  button: `${classes.submitButton} ${classes.btn}`,
                  disabled: classes.submitButtonDisabled,
                }}
                type="submit"
                color="primary"
                fullWidth
                disabled={!password || isLoading}
                onClick={this.handleSubmit}
              >
                <span
                  style={{
                    textTransform: 'none',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  {isLoading
                    ? 'Setting Up Your Account'
                    : 'Finish Setup and Login'}
                  {isLoading ? (
                    <CircularProgress
                      size={14}
                      style={{ marginLeft: 5, color: '#fff', opacity: 0.9 }}
                    />
                  ) : null}
                </span>
              </Button>
            </div>
          </form>
          <ErrorMessage message={error} />
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { classes } = this.props

    const email = emailObservable.get()
    const code = codeObservable.get()
    const verify = isActivationCodeVerified.get()

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          {email && code
            ? verify
              ? this.renderForm()
              : this.renderLoading()
            : this.renderError()}
        </div>
      </div>
    )
  }
}
