import API, { DATA_API } from 'helpers/api'

export const getDesigns = async (ids: string[]): Promise<any[]> => {
  const params = {
    ids,
  }

  const result = await API.get({
    api: DATA_API,
    url: '/designs',
    params,
  })

  return result.records
}
