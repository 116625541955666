import { observable, action, computed, runInAction } from 'mobx'

import accountStore from 'config/store/AccountStore'
import { repository as trackingLinesRepo } from 'models/TrackingLines'
import getTrackingLinesByAccount from 'services/trackingLines/getTrackingLinesByAccount'
import updateTrackingLineById from 'services/trackingLines/updateTrackingLineById'

enum ComputedStatus {
  ACTIVE = 'Active',
  NOT_ACTIVE = 'Not active',
}

export class TrackingLinesStore {
  private dirty = false
  private itemIds = observable.array<string>([])
  @observable private searchStr: string = ''

  @observable isLoading = false
  @observable orderBy: string | null = 'campaignName'
  @observable dir: string | null = 'desc'
  @observable currentPage = 1
  @observable pageSize = 10

  @observable showInActive = false

  // --- ACTION w/ SIDE EFFECTS - Refresher --- //

  /**
   * This setup will accomodate a scenario when there are two
   * components that are dependent on a function that updates a state.
   * The two components might or might not be mounted at the same time.
   * @param {boolean} param.reset - When you want to reset state + do a refresh
   */
  @action
  refresh({ reset = false } = {}): void {
    if (reset) {
      this.reset()
    }

    if (!this.dirty) {
      this.dirty = true
      this.fetchByAccount()
    }
  }

  // --- ACTION w/ SIDE EFFECTS --- //

  @action
  async updateFriendlyName(data: { id: string; friendlyName: string }) {
    return updateTrackingLineById(data)
  }

  @action
  private async fetchByAccount() {
    this.isLoading = true

    const accountIds = (accountStore.selectedAccounts || []).slice()
    const filters = {
      computedStatus: this.showInActive
        ? ComputedStatus.NOT_ACTIVE
        : ComputedStatus.ACTIVE,
    }

    const data = await getTrackingLinesByAccount({ accountIds, filters })

    runInAction(() => {
      const { records } = data
      if (records) {
        this.itemIds.replace(records)
      }
      this.isLoading = false
    })
  }

  // --- ACTION w/o SIDE EFFECTS --- //

  @action
  reset() {
    this.resetSearch()
    this.resetTable()
  }

  @action
  resetSearch() {
    this.searchStr = ''
  }

  @action
  private resetTable() {
    this.dirty = false
    this.orderBy = 'campaignName'
    this.dir = 'desc'
    this.currentPage = 1
    this.pageSize = 10
  }

  @action.bound
  setSearchString(value: string): void {
    this.searchStr = value
  }

  @action.bound
  setCurrentPage(page: number) {
    this.currentPage = page
  }

  @action.bound
  setPageSize(pageSize: number) {
    this.currentPage = 1
    this.pageSize = pageSize
  }

  @action.bound
  setFriendlyName(data: { id: string; name: string }) {
    const { id, name } = data
    const trackingLine = trackingLinesRepo.get(id)
    trackingLine.friendlyName = name
  }

  @action.bound
  toggleSorting(key: string) {
    this.orderBy = key
    this.dir = this.dir === 'asc' ? 'desc' : 'asc'
  }

  @action.bound
  toggleShowInActive() {
    this.showInActive = !this.showInActive
    this.resetTable()
    this.refresh()
  }

  // --- COMPUTED --- //

  @computed
  get items() {
    return this.itemIds.map((id) => trackingLinesRepo.get(id))
  }

  @computed
  get filteredRows() {
    return this.sortItems(
      this.items.filter((item) => {
        const searchRegEx = new RegExp(this.searchStr.replace(/\+/g, ''), 'gi')
        return (
          item.campaignName.match(searchRegEx) || item.number.match(searchRegEx)
        )
      })
    )
  }

  // TODO: Refactor to server side pagination
  @computed
  get paginatedRows() {
    return this.pageSize > 0
      ? this.filteredRows.filter(
          (_, key) =>
            key >= (this.currentPage - 1) * this.pageSize &&
            key < this.pageSize * this.currentPage
        )
      : this.filteredRows
  }

  // --- UTILITY --- //

  private sortItems(items: any): any[] {
    const sorted = items.sort(
      (a: any, b: any) =>
        a[this.orderBy ?? 'campaignName'] ??
        ''.localeCompare(b[this.orderBy ?? 'campaignName'] ?? '')
    )

    if (this.dir === 'desc') {
      sorted.reverse()
    }

    return sorted
  }
}

export default new TrackingLinesStore()
