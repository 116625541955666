import React from 'react'
import cx from 'classnames'

// STYLES
import { useStyles } from './KPIGradeEvaluator.styles'
import {
  getGradeColor,
  getGradeColorByName,
  getGradeName,
  IGrading,
} from '../../Utils'
import classNames from 'classnames'
import { Grade } from 'services/calls/metrics/grade'

interface KPIGradeEvaluatorInterface {
  value: number
  total: string
  totalText: string
  grading?: IGrading[]
  gradeName?: Grade
  isAccordionContent?: boolean
}

const KPIGradeEvaluator = (props: KPIGradeEvaluatorInterface) => {
  const classes = useStyles()
  const { value, total, totalText, grading, isAccordionContent, gradeName } =
    props

  const _gradeName = gradeName ?? (grading && getGradeName(grading, value))
  const grade = _gradeName ? `Grade ${_gradeName}` : undefined
  const color = grading
    ? getGradeColor(value, grading)
    : _gradeName && getGradeColorByName(_gradeName)

  return (
    <div
      className={classNames(
        classes.container,
        isAccordionContent ? classes.containerMobile : null
      )}
    >
      <div className={cx(classes.pill)}>
        {totalText}: <span className={classes.total}>{total}</span>
      </div>
      {grade ? <div className={cx(classes.pill, color)}>{grade}</div> : null}
    </div>
  )
}

export default KPIGradeEvaluator
