import { observable } from 'mobx'
import Repository from '../Repository'

export const repository = new Repository()

export default class InsuranceInquiry {
  @observable id: string = ''
  @observable name: string = ''
  @observable callId: string = ''
  @observable insuranceId: string = ''
  @observable insuranceName: string = ''
  @observable insuranceAcceptanceStatus: string = ''

  constructor(insuranceInq: any) {
    this.id = insuranceInq.id
    this.name = insuranceInq.name
    this.callId = insuranceInq.callId
    this.insuranceId = insuranceInq.insuranceId
    this.insuranceName = insuranceInq.insuranceName
    this.insuranceAcceptanceStatus = insuranceInq.insuranceAcceptanceStatus
  }
}
