import { withStyles, WithStyles, Button } from '@material-ui/core'
import React from 'react'

import referralImg from 'assets/img/ads/referral/referral-ad.svg'
import styles from './ReferralAd.styles'

interface ReferralAdProps extends WithStyles<typeof styles> { }

const ReferralAd = (props: ReferralAdProps) => {
  const { classes } = props
  return (
    <div className={classes.wrapper}>
      <h4 className={classes.header}>REFER A FRIEND!</h4>
      <img
        className={classes.image}
        src={referralImg}
        alt="MVP Mailhouse"
      />
      <Button color="primary" className={classes.action} href="https://mvpmailhouse.com/refer" target="_blank">Submit A Referral</Button>
      <a className={classes.url} href="https://mvpmailhouse.com/referral-program/" target="_blank">Referral program details?</a>
    </div>
  )
}

export default withStyles(styles)(ReferralAd)