import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },

  paper: {
    position: 'absolute',
    width: 420,
    backgroundColor: '#ffffff',
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
    outline: 'none',
    borderRadius: 5,
    color: '#808080',

    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },

  heading: {
    fontFamily: 'Roboto',
    background: '#f7f7f7',
    fontWeight: 700,
    display: 'flex',
    borderRadius: '5px 5px 0px 0px',
    fontSize: '20px',
    padding: '14px 24px',
    alignItems: 'center',
    '& svg': {
      marginLeft: 'auto',
      cursor: 'pointer',
    },
  },

  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  bodyWrapper: {
    margin: '0 8px',
    padding: '5px 18px',
    overflow: 'overlay',
    // maxHeight: 'calc(100vh - 30vh)',
    maxHeight: 'calc(100vh - 10rem)',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'lightgrey',
      borderRadius: 4,
      border: '1px solid white',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'white',
    },
  },

  buttonWrapper: {
    textAlign: 'right',
    margin: 18,
    '& button': {
      fontSize: 16,
      margin: '0 10px',
    },
  },

  btnGray: {
    color: 'rgb(136,136,136)',
  },
}))

export default useStyles
