/**
 * ******************************************
 * Designed to be used by src/views/Campaigns/Campaigns.tsx
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React from 'react'

// Mui
import { Tabs, Tab, useMediaQuery, Theme } from '@material-ui/core'

// My util
import useStyles from './CampaignTabBar.style'
import { useStores } from 'config/store'
import { CampaignTab } from 'config/store/MailingsStore'

// My component
import DateFilterTypeSelect from 'containers/DateFilterTypeSelect'
import CampaignFilterTypeSelect from 'containers/CampaignFilterTypeSelect'
import CampaignSortSelect from 'views/Campaigns/CampaignTabBar.sort'

export default function CampaignTabBar() {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const { mailingsStore } = useStores()
  const { tab } = mailingsStore

  const titles = [['overview', 'Overview']]

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: CampaignTab) => {
    mailingsStore.setTab(newValue)
  }

  return (
    <div className={classes.root}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        classes={{
          root: classes.tabsRoot,
          flexContainer: classes.tabsFlexContainer,
          scroller: classes.tabsScroller,
        }}
        variant={isDesktop ? 'standard' : 'fullWidth'}
      >
        {titles.map(([key, label]) => (
          <Tab
            key={key}
            value={key}
            label={label}
            className={classes.tabItem}
          />
        ))}
      </Tabs>
      <div className={classes.filters}>
        <DateFilterTypeSelect />
        <CampaignFilterTypeSelect />
        <CampaignSortSelect />
      </div>
    </div>
  )
}
