import { makeStyles, createStyles } from '@material-ui/styles'
import placeholderDelivered from 'assets/img/thumbnail1.png'

export default makeStyles(
  createStyles({
    placeholderDelivered: {
      backgroundImage: `url(${placeholderDelivered})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: 310,
    },
  })
)
