import classNames from 'classnames'
import React from 'react'
import { useStyles } from './AvgTime.styles'

interface AvgTimeInterface {
  data: {
    seconds: number
    minutes: number
    hours: number
  }
  isAccordionContent?: boolean
}

const AvgTime = (props: AvgTimeInterface) => {
  const classes = useStyles()
  const { seconds, minutes, hours } = props.data
  const { isAccordionContent } = props
  const time: string[] = []
  const [roundedHours, roundedMinutes, roundedSeconds] = [
    Math.round(hours),
    Math.round(minutes),
    Math.round(seconds),
  ]

  if (roundedHours) {
    arrayPushWithPrefix(time, `${roundedHours} ${roundedHours > 1 ? 'Hours' : 'Hour'}`)
  }
  if (roundedMinutes) {
    arrayPushWithPrefix(
      time,
      `${roundedMinutes} ${roundedMinutes > 1 ? 'Mins' : 'Min'}`
    )
  }
  if (roundedSeconds) {
    arrayPushWithPrefix(
      time,
      `${roundedSeconds} ${roundedSeconds > 1 ? 'Secs' : 'Sec'}`
    )
  }
  if (time.length > 1) {
    const removeLastComma = time.length > 2 ? 2 : 0
    // Makes time
    // from [hour, ', ', minute, ', ', seconds]
    // into [hour, ', ', minutes, ' and ', seconds]
    // So after concat it will look "hour, minute and seconds"
    time.splice(
      time.length - 2,
      removeLastComma,
      ' and ',
      time[time.length - 1]
    )
  }

  const timeFmt = time.join('')

  return (
    <div
      className={classNames(
        classes.container,
        isAccordionContent ? classes.containerMobile : null
      )}
    >
      <h2>{timeFmt} AVG Hold Time</h2>
    </div>
  )
}

export default AvgTime

function arrayPushWithPrefix<T, K>(array: T[] & K[], item: K) {
  if (array.length) {
    array.push(', ' as any)
  }
  array.push(item)
}
