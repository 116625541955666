import React from 'react'
import AllNonOpportunityReasonsSection from './AllNonOpportunityReasonDrawer/AllNonOpportunityReasonSection'

// STYLES
import { useStyles } from './AllNonOpportunityReasonDrawer.style'

const AllNonOpportunityReasonDrawer = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AllNonOpportunityReasonsSection />
    </div>
  )
}

export default AllNonOpportunityReasonDrawer
