import axios from 'axios'
import {
  observable,
  action,
  computed,
  reaction,
  IReactionDisposer,
  runInAction,
} from 'mobx'
import moment from 'moment'

import getMails from 'services/mailings/getMails'
import getSummary from 'services/mailings/getSummary'
import getCalls from 'services/calls/getCalls'
import approveMails from 'services/mailings/approveMails'
import Mailing, { repository as mailingsRepo } from 'models/Mailing'
import accountStore from 'config/store/AccountStore'
import updateMailingById from 'services/mailings/updateMailingById'
import callStore from './CallPro/Call/CallStore'
import globalFilterStore from 'config/store/GlobalFilterStore'
import { getMailingZone } from 'services/mailings/getMailingZone'

interface SummaryInterface {
  all: number
  leads: number
  appointments: number
  conversionRate: number
  missed: number
  followUps: number
}

interface MailingsPerPageInterface {
  mailing?: Mailing
  isAudited: boolean
  totalProduction: number
  totalNewPatients: number
}

export type CampaignTab = 'overview'

export class MailingsStore {
  @observable reloadData: boolean = false
  @observable delivered = observable.array<string>([])
  @observable isLoading: boolean = false
  @observable isLoadingDeliveredMailings: boolean = false
  @observable isDeliveredMailingsPaginated: boolean = false
  @observable isLoadingGetPatientList: boolean = false
  @observable tab: CampaignTab = 'overview'

  @observable isDirty: boolean = false
  @observable itemPerPageIdsDelivered = observable.array<string>([])
  @observable totalItemsPerPage: number = 0
  @observable itemIds = observable.array<string>([])
  @observable totalItems: number = 0
  @observable totalCountDelivered: number = 0

  @observable pageSize: number = 15
  @observable currentPage: number = 1
  @observable orderBy: string | null = 'deliveryWeek'
  @observable dir: string | null = 'desc'
  @observable error?: string | null = null

  // States for Mailing details drawer
  @observable drawer: Mailing | null = null

  // States for Quick summary component
  @observable previousSummary: SummaryInterface = {
    all: 0,
    leads: 0,
    appointments: 0,
    conversionRate: 0,
    missed: 0,
    followUps: 0,
  }

  @observable summary: SummaryInterface = {
    all: 0,
    leads: 0,
    appointments: 0,
    conversionRate: 0,
    missed: 0,
    followUps: 0,
  }

  @observable mailingsPerPage: MailingsPerPageInterface = {
    mailing: undefined,
    isAudited: false,
    totalProduction: 0,
    totalNewPatients: 0,
  }

  @observable campaignItemsPerPage: any | undefined = undefined
  @observable tempSelectedMailings: any[] = []
  @observable campaignIdForRecentCalls: string = ''
  @observable filterMode: string = 'quick' // quick or advanced

  cancelToken: any | undefined = undefined
  cancelFetchDelivered: any | undefined = undefined
  cancelFetchSummary: any | undefined = undefined
  itemsPerPage = observable.array<string>([])

  queriesDisposer?: IReactionDisposer
  dirtyDisposer?: IReactionDisposer
  globalFiltersDisposer?: IReactionDisposer

  makeReactions() {
    this.dirtyDisposer = this.monitorDirty()
    this.queriesDisposer = this.monitorQueries()
    this.globalFiltersDisposer = this.monitorGlobalFilters()
  }

  dispose() {
    this.dirtyDisposer && this.dirtyDisposer()
    this.queriesDisposer && this.queriesDisposer()
    this.globalFiltersDisposer && this.globalFiltersDisposer()
  }

  private monitorDirty() {
    return reaction(
      () => this.isDirty,
      (isDirty) => {
        if (isDirty) {
          this.getDeliveredMailings()
          this.getSummary()
          this.isDirty = false
          this.isDeliveredMailingsPaginated
            ? (this.isLoading = false)
            : (this.isLoading = true)
        }
      }
    )
  }

  private monitorGlobalFilters() {
    return reaction(
      () => [
        accountStore.selectedAccounts.slice(),
        { ...globalFilterStore.dateFilter.dateRange },
        globalFilterStore.campaignFilter.campaignFilterType,
      ],
      () => this.dirty(true)
    )
  }

  private monitorQueries() {
    return reaction(
      () => [this.currentPage, this.pageSize, this.orderBy, this.dir],
      () => this.dirty(true)
    )
  }

  @action
  dirty(isDirty = true) {
    this.isDirty = isDirty
  }

  @action
  setFilterMode(mode = 'quick') {
    this.filterMode = mode
  }

  @action.bound
  setDrawer(mailing: Mailing | null = null) {
    this.campaignIdForRecentCalls = ''
    callStore.drawer = null
    this.drawer = mailing
  }

  @action.bound
  setCurrentPage(page: number) {
    this.currentPage = page
    this.isDeliveredMailingsPaginated = true
  }

  @action.bound
  setPageSize(pageSize: number) {
    this.pageSize = pageSize
    this.isDeliveredMailingsPaginated = true
    this.currentPage = 1
  }

  @action.bound
  toggleSorting(key: string) {
    if (this.orderBy === key) {
      if (this.dir === 'asc') {
        this.dir = 'desc'
      } else if (this.dir === 'desc') {
        this.orderBy = null
        this.dir = null
      } else {
        this.dir = 'asc'
      }
    } else {
      this.orderBy = key
      this.dir = 'asc'
    }
    this.currentPage = 1
  }

  @action.bound
  setSorting(key: string, dir: string) {
    this.orderBy = key
    this.dir = dir
    this.currentPage = 1
  }

  setTab(value: CampaignTab) {
    runInAction(() => {
      this.tab = value
    })
  }

  private getCampaignDateFilters() {
    const { dateFilterType, dateRange } = globalFilterStore.dateFilter
    const { from, to } = dateRange

    if (dateFilterType === 'all' || !from || !to) {
      return {}
    }

    return {
      deliveryWeek: {
        gte: from && moment(from).format('YYYY-MM-DD'),
        lte: to && moment(to).format('YYYY-MM-DD'),
      },
    }
  }

  private getSummaryDateFilters() {
    const { from, to } = globalFilterStore.dateFilter.dateRange
    if (!from || !to) {
      return {}
    }
    return {
      callDateTime: { gte: from, lte: to },
    }
  }

  private getCampaignFilters() {
    switch (globalFilterStore.campaignFilter.campaignFilterType) {
      case 'mvp-mail':
        return {
          campaignTypes: [
            'MVP Mail',
            'Custom',
            'Handout Cards',
            'My Patient Mail',
            'MVP Core',
            'New Mover Mail',
          ],
        }

      case 'non-mailing':
        return { campaignTypes: ['Call Tracking', 'CallPro', 'Google Ads'] }

      default:
        return {}
    }
  }

  @action
  getDeliveredMailings() {
    if (this.cancelFetchDelivered) {
      this.cancelFetchDelivered.cancel()
    }
    const cancelToken = axios.CancelToken
    this.cancelFetchDelivered = cancelToken.source()

    this.isLoadingDeliveredMailings = true
    const options = {
      page: this.currentPage,
      pageSize: this.pageSize,
      sort: this.orderBy,
      dir: this.dir,
      filters: {
        status: 'deliveredInFirstCall',
        ...this.getCampaignDateFilters(),
        ...this.getCampaignFilters(),
      },
      cancelToken: this.cancelFetchDelivered,
    }

    getMails(options)
      .then((data: any) => {
        const { meta, records } = data
        this.itemIds.replace(records)
        this.itemPerPageIdsDelivered.replace(records)
        this.totalCountDelivered = meta.totalCount
      })
      .catch((error: Error) => {
        if (axios.isCancel(error)) {
          console.info('[getDeliveredMailings] cancelled')
          return
        }
        console.error('[getDeliveredMailings] error', error)
        this.fail(error)
      })
      .then(() => {
        this.isLoadingDeliveredMailings = false
        this.isDeliveredMailingsPaginated = false
      })
  }

  @action
  getMailingZone(
    options: { mailingId: string; zoneId: string } = {
      zoneId: '',
      mailingId: '',
    }
  ) {
    const { mailingId, zoneId } = options

    if (mailingId.length && zoneId.length) {
      getMailingZone({
        mailingId,
        zoneId,
      }).then((mailing) => {
        if (mailing) this.setDrawer(mailingsRepo.get(mailing))
      })
    }
  }

  @computed
  get itemsPerPageDelivered() {
    return this.itemPerPageIdsDelivered.map((id) => mailingsRepo.get(id))
  }

  @action
  getSummary() {
    if (this.cancelFetchSummary) {
      this.cancelFetchSummary.cancel()
    }
    const cancelToken = axios.CancelToken
    this.cancelFetchSummary = cancelToken.source()

    this.isLoading = true
    const options = {
      filters: {
        ...this.getSummaryDateFilters(),
        ...this.getCampaignFilters(),
      },
      pageSize: -1,
      cancelToken: this.cancelFetchSummary,
    }

    getSummary(options)
      .then(
        ({
          all,
          leads,
          appointments,
          conversionRate,
          missed,
          followUps,
        }: SummaryInterface) => {
          this.previousSummary.all = this.summary.all
          this.previousSummary.leads = this.summary.leads
          this.previousSummary.appointments = this.summary.appointments
          this.previousSummary.conversionRate = this.summary.conversionRate
          this.previousSummary.missed = this.summary.missed
          this.previousSummary.followUps = this.summary.followUps

          this.summary.all = all
          this.summary.leads = leads
          this.summary.appointments = appointments
          this.summary.conversionRate = conversionRate
          this.summary.missed = missed
          this.summary.followUps = followUps
        }
      )
      .catch((error: Error) => {
        if (axios.isCancel(error)) {
          console.info('[MailingStore:getSummary] cancelled')
          return
        }

        console.error('[MailingStore:getSummary] error', error)
        this.fail(error)
      })
      .then(() => {
        this.isLoading = false
      })
  }

  @action
  async approveMail(id: string) {
    return await approveMails(id)
  }

  @action
  async fetchRecentCalls(options: {
    id: string
    pageSize: number | null
    sort: string
    dir: string
    filters: {}
  }) {
    const { id, pageSize, sort, dir, filters } = options

    let response = await getCalls({
      page: pageSize ? 1 : -1,
      pageSize,
      sort,
      dir,
      filters: {
        mailing: id,
        ...filters,
      },
    })

    return response
  }

  @action
  async updateMailingName(data: { id: string; name: string }) {
    return updateMailingById(data)
  }

  @computed
  get items() {
    return this.itemIds.map((id) => mailingsRepo.get(id))
  }

  @action
  fail(error: Error) {
    if (error instanceof Error) {
      this.error = error.message
    } else {
      this.error = null
    }
  }

  @action
  cleanStore() {
    this.error = null
    this.itemsPerPage.clear()
    // this.items.clear()
    this.reloadData = false

    this.delivered.clear()
    this.isLoading = false
    this.isLoadingDeliveredMailings = false
    this.isDeliveredMailingsPaginated = false

    this.dirty(false)
    this.itemPerPageIdsDelivered.clear()
    this.totalItemsPerPage = 0
    this.itemIds.clear()
    this.totalItems = 0
    this.totalCountDelivered = 0

    this.pageSize = 15
    this.currentPage = 1
    this.orderBy = 'deliveryWeek'
    this.dir = 'desc'
    this.error = null

    // States for Mailing details drawer
    this.drawer = null
    this.campaignIdForRecentCalls = ''

    // States for Quick summary component
    this.previousSummary.leads = 0
    this.previousSummary.appointments = 0
    this.previousSummary.conversionRate = 0

    this.summary.leads = 0
    this.summary.appointments = 0
    this.summary.conversionRate = 0
  }
}

const mailingsStore = new MailingsStore()

export default mailingsStore
