import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import getUserNotificationSetting from 'services/settings/getUserNotificationSetting'
import getReceiversNotificationSetting from 'services/settings/getReceiversNotificationSetting'
import updateNotificationSettings from 'services/settings/updateNotificationSettings'
import { NotificationSettingsData } from '../AlertType/AlertType'

export function useUserNotificationSettingsQuery() {
  return useQuery<Map<string, NotificationSettingsData>>({
    queryKey: ['contactSettings'],
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    queryFn: getUserNotificationSetting,
  })
}

export function useReceiversNotificationSettingsQuery(isPrimary: boolean) {
  return useQuery<Map<string, NotificationSettingsData>>({
    queryKey: ['receiversSettings', isPrimary],
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    queryFn: getReceiversNotificationSetting,
    enabled: isPrimary
  })
}

export function useUpsertNotificationSettings() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: Map<string, NotificationSettingsData>) =>
      updateNotificationSettings(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['contactSettings'],
      })
      queryClient.invalidateQueries({
        queryKey: ['receiversSettings'],
      })
    },
  })
}