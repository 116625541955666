import auth0 from 'auth0-js'
import env from 'env'

export const webAuth = new auth0.WebAuth({
  domain: env.auth0.domain ?? '',
  clientID: env.auth0.clientId ?? '',
  audience: env.auth0.audience,
  scope: 'openid email profile',
  responseType: 'token id_token',
  responseMode: 'fragment',
})

export const passwordlessAuth = new auth0.WebAuth({
  domain: env.auth0.domain ?? '',
  clientID: env.auth0.clientIdPasswordless ?? '',
  audience: env.auth0.audience,
  scope: 'openid email profile',
  responseType: 'token id_token',
  responseMode: 'fragment',
})

export const authClient = new auth0.Authentication({
  domain: env.auth0.domain ?? '',
  clientID: env.auth0.clientId ?? '',
})

export default webAuth
