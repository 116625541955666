import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useLeaderboardStyles = makeStyles((theme: Theme) =>
  createStyles({
    active_column: {
      color: '#ED154E !important',
      fontWeight: 'bold',
    },
    active_row: {
      backgroundColor: '#fff5f8',
    },

    // LEADERBOARD HEADER
    leaderboard: {
      border: '1px solid #d3d3d3',
      backgroundColor: '#fff',
      borderRadius: '10px 0 0 10px',
      padding: '1rem 1.6rem',
      flexGrow: 1,
    },

    dropdownContainer: {
      width: '100%',
    },

    leaderboardMobile: {
      width: 'calc(100vw - 48px)',
      borderRadius: 10,
      '& $leaderboard_table_content': {
        overflow: 'scroll !important',
      },
      '& $leaderboard_table_label': {
        padding: '0px 10px',
      },
      '& $leaderboard__title': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      '& $leaderboard__rank': {
        width: '100%',
      },
    },

    leaderboard__title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& h1': {
        fontSize: '1.3rem',
        fontWeight: 'bold',
      },
    },
    leaderboard__rank: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      marginBottom: '.5rem',
      '& p': {
        width: 83,
      },
    },
    leaderboard_table_content: {
      maxHeight: '28rem',
      overflow: 'scroll',
      overflowX: 'hidden',
    },
    leaderboard_table_body: {
      border: '1px solid red',
    },
    leaderboard_table_header: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '.9rem 1.5rem',
      borderRadius: '10px 0 0 0px',
      border: '1px solid #ededed',
      backgroundColor: '#fbfbfb',
    },
    leaderboard_table_title: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1.2rem',
    },
    leaderboard_table_rank: {
      display: 'flex',
      alignItems: 'center',
    },
    leaderboard_table_rank_label: {
      fontSize: '.9rem',
      marginRight: '.8rem',
    },
    leaderboard_table_rank_dropdown: {
      color: '#e91e63',
      fontSize: '.9rem',
      backgroundColor: '#fff',
      fontWeight: 'bold',
    },
    leaderboard_table_container: {
      border: '1px solid #ededed',
      borderTop: '0',
      backgroundColor: '#fff',
      display: 'grid',
      gridTemplateRows: '1fr 4rem',
      borderRadius: '0 0 0 10px',
      overflow: 'auto',
      maxHeight: '480px',
    },
    leaderboard_table_labels: {
      backgroundColor: '#f8f8f8',
    },

    leaderboard_table_label: {
      color: '#444',
      border: '1px solid #d3d3d3',
      borderLeft: 0,
      lineHeight: '44px',
      fontWeight: 'bold',
      padding: '0',

      '&:first-child': {
        borderLeft: '1px solid #d3d3d3',
      },
    },
    leaderboard_table_data: {
      fontSize: '1rem',
      color: '#222',
      border: '1px solid #d3d3d3',
      borderTop: 0,
      borderLeft: 0,
      padding: '0',

      '&:first-child': {
        borderLeft: '1px solid #d3d3d3',
      },
    },
    leaderboard_table_data_row: {
      cursor: 'pointer',
    },
    leaderboard_table_pagination: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderTop: '1px solid #e6e6e6',
      borderRadius: '0 0 0 10px',
      padding: '0 1.5rem',
      backgroundColor: '#fbfbfb',
    },
  })
)
