import CampaignsPage from 'src/views/Campaigns/Campaigns'
import SettingsPage from 'src/views/Settings/SettingsPage'
import MapsPage from 'src/views/Maps/Maps'
import DesignConceptSelectionPage from 'src/views/DesignConceptSelection'
import DesignReviewPage from 'src/views/DesignReview'
import CampaignMediaReviewPage from 'src/views/DesignReview/CampaignMediaReview'
import CallPro from 'src/views/CallPro/CallPro'

import {
  Call as CallIcon,
  Dashboard as DashboardIcon,
  Mail as MailIcon,
  Settings as SettingsIcon,
  Map as MapsIcon,
} from '@material-ui/icons'

export const emailRoutes = {
  missedCalls: '/scoreboard/all-calls/missed-calls',
  appointmentCalls: '/scoreboard/all-calls/appointment-calls',
  opportunityCalls: '/scoreboard/all-calls/opportunity-calls'
}

const routes = [
  {
    path: '/',
    redirect: true,
    exact: true,
    to: '/campaigns',
  },
  {
    path: ['/campaigns', '/campaigns/:mailingId'],
    title: 'Campaigns',
    exact: true,
    icon: MailIcon,
    page: CampaignsPage,
  },
  {
    path: ['/scoreboard', '/scoreboard/:callId', Object.values(emailRoutes)],
    title: 'Scoreboard',
    exact: true,
    icon: CallIcon,
    page: CallPro,
  },
  {
    path: '/maps',
    title: 'Maps',
    exact: true,
    icon: MapsIcon,
    page: MapsPage,
  },
  {
    path: '/settings',
    title: 'Settings',
    icon: SettingsIcon,
    page: SettingsPage,
    track: {
      category: 'Settings',
      name: 'Settings',
    },
  },
  {
    path: '/design-concept-selection',
    title: 'Design Concept Selection',
    icon: DashboardIcon,
    page: DesignConceptSelectionPage,
  },
  {
    path: '/accounts/:accountId/designs/:designId',
    title: 'Design Review',
    icon: DashboardIcon,
    page: DesignReviewPage,
  },
  {
    path: '/campaigns/:campaignId/design',
    title: 'Campaign Media Review',
    icon: DashboardIcon,
    page: CampaignMediaReviewPage,
  },
]

export default routes
