import { useMemo } from 'react';
import { useStores } from 'config/store';

interface Account {
  id: string;
  name: string;
  parent: string | null;
  children: Account[];
}

const useAccountLabel = (): string => {
  const { appState, accountStore } = useStores();
  const { accounts } = appState;
  const { hiddenAccounts } = accountStore;

  const selectedAccounts = useMemo(() => {
    const childAccounts = accounts.filter((account: Account) => account.children.length === 0);
    return childAccounts.filter((account: Account) => !hiddenAccounts.includes(account.id));
  }, [accounts, hiddenAccounts]);

  const label = useMemo(() => {
    const selectedCount = selectedAccounts.length;

    if (selectedCount === 0) {
      return 'No accounts selected';
    }

    const getParentAccount = (selectedAccount: Account): Account => {
      const parentAccount = accounts.find((account: Account) => account.id === selectedAccount.parent);
      return Boolean(selectedAccount.parent) && parentAccount ? parentAccount : selectedAccount;
    };

    const firstSelectedAccount = selectedAccounts[0];

    const account = selectedAccounts.length === 1
      ? firstSelectedAccount
      : getParentAccount(firstSelectedAccount);

    return `${account.name} ${selectedCount}/${selectedAccounts.length}`;
  }, [accounts, selectedAccounts]);

  return label;
};

export default useAccountLabel;
