import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    pill: {
      border: 'solid 1px #e9e6e7',
      padding: 5,
      borderRadius: 5,
      flex: 1,
      textAlign: 'center',
      fontSize: 15,
      '&:first-child': { marginRight: 4 },
      '&:last-child': { marginLeft: 4 },
    },
    green: {
      borderColor: '#56B863 !important',
      backgroundColor: '#EBF9F1 !important',
      color: '#56B863 !important',
    },
    orange: {
      borderColor: '#F2A63B !important',
      backgroundColor: '#FFF9DE !important',
      color: '#F2A63B !important',
    },
    red: {
      borderColor: '#E13426 !important',
      backgroundColor: '#FFF0F0 !important',
      color: '#E13426 !important',
    },
    disabled: {
      borderColor: '#8c9aa9 !important',
      backgroundColor: '#f4f4f4 !important',
      color: '#8c9aa9 !important',
    },
    total: {
      color: '#4498E7',
    },
    containerMobile: {
      '& $pill': {
        padding: 3,
        fontSize: 13,
      },
    },
  })
)
