import React, { Component } from 'react'
import { withMediaProps } from 'react-media-player'
import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import cx from 'classnames'

const styles = theme => ({
  rangeProgress: {
    'height': '0.22em',
    'position': 'absolute',
    'width': '100%',
    'background-color': '#e93f33',
    'top': '10px',
    'border-radius': '3px',
    'z-index': '10'
  },
  seekBarDefault: {
    '&[type="range"]': {
      'height': '1.6em !important',
      '&::-webkit-slider-thumb': {
        'background-color': '#e93f33 !important',
      },
      '&::-moz-range-thumb': {
        'background-color': '#e93f33 !important'
      },
      '&::-ms-thumb': {
        'background-color': '#e93f33 !important',
      }
    }
  },
  seekBar: {
    'position': 'relative',
    'width': '100%',
    'margin-right': '16px',
    '&[type="range"]': {
        '--range': 'calc(var(--max) - var(--min))',
        '--ratio': 'calc((var(--val) - var(--min))/var(--range))',
        '--sx': 'calc(.5*1.5em + var(--ratio)*(100% - 1.5em))',
        'margin': '0',
        'padding': '0',
        'width': '100%',
        'height': '1.5em',
        'background': 'transparent',
        'font': '1em/1 arial, sans-serif',
        '-webkit-appearance': 'none',
        '&::-webkit-slider-runnable-track': {
            'box-sizing': 'border-box',
            'border': 'none',
            'width': '12.5em',
            'height': '0.19em',
            'background': 'rgb(201,201,201)',
            'border-radius': '3px'
        },
        '&::-moz-range-track': {
            'box-sizing': 'border-box',
            'border': 'none',
            'width': '12.5em',
            'height': '0.19em',
            'background': 'rgb(201,201,201)',
            'border-radius': '3px'
        },
        '&::-ms-track': {
            'box-sizing': 'border-box',
            'border': 'none',
            'width': '12.5em',
            'height': '0.19em',
            'background': 'rgb(201,201,201)',
            'border-radius': '3px'
        },
        '&::-moz-range-progress': {
            'height': '0.25em',
            'background': 'rgb(209,0,62)'
        },
        '&::-ms-fill-lower': {
            'height': '0.25em',
            'background': '#95a'
        },
        '&::-webkit-slider-thumb': {
            'margin-top': '-0.399em',
            'box-sizing': 'border-box',
            'border': 'none',
            'width': '1em',
            'height': '1em',
            'border-radius': '50%',
            'background': '#fff',
            '-webkit-appearance': 'none',
            'position': 'relative',
            'z-index': '100',
            'cursor': 'grab'
        },
        '&::-moz-range-thumb': {
            'box-sizing': 'border-box',
            'border': 'none',
            'width': '.8em',
            'height': '.8em',
            'border-radius': '50%',
            'background': 'rgb(209,0,62)'
        },
        '&::-ms-thumb': {
            'margin-top': '0',
            'box-sizing': 'border-box',
            'border': 'none',
            'width': '.8em',
            'height': '.8em',
            'border-radius': '50%',
            'background': 'rgb(209,0,62)'
        },
        '&::-ms-tooltip': {
            'display': 'none'
        }
    }
  }
})


@withStyles(styles)
class SeekBar extends Component {
  constructor(props) {
    super(props)
  }

  _isPlayingOnMouseDown = false
  _onChangeUsed = false

  shouldComponentUpdate({ media }) {
    return (
      this.props.media.currentTime !== media.currentTime ||
      this.props.media.duration !== media.duration
    )
  }

  _handleMouseDown = () => {
    this._isPlayingOnMouseDown = this.props.media.isPlaying
    this.props.media.pause()
  }

  _handleMouseUp = ({ target: { value } }) => {
    // seek on mouseUp as well because of this bug in <= IE11
    // https://github.com/facebook/react/issues/554
    if (!this._onChangeUsed) {
      this.props.media.seekTo(+value)
    }

    // only play if media was playing prior to mouseDown
    if (this._isPlayingOnMouseDown) {
      this.props.media.play()
    }
  }

  _handleChange = ({ target: { value } }) => {
    this.props.media.seekTo(+value)
    this._onChangeUsed = true
  }

  render() {
    const { classes, style, media, defaultPlayer } = this.props
    const { duration, currentTime } = media

    return (
      <div className={cx(classes.seekBar, defaultPlayer && classes.seekBarDefault)}>
        <div className={classes.rangeProgress} style={{width: currentTime * 100 / duration + '%'}}>
        </div>
        <input
          className={cx(classes.seekBar, defaultPlayer && classes.seekBarDefault)}
          type="range"
          step="any"
          max={duration.toFixed(4)}
          value={currentTime}
          onMouseDown={this._handleMouseDown}
          onMouseUp={this._handleMouseUp}
          onChange={this._handleChange}
          style={{
            backgroundSize: currentTime * 100 / duration + '% 100%',
            ...style,
          }}
        />
      </div>
    )
  }
}

export default withMediaProps(SeekBar)