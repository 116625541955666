// CORE
import React from 'react'
import { observer } from 'mobx-react'

// STORE
import { useStores } from '../../../config/store'

// COMPONENTS
import CustomTable from '../../Table/Table'
import Card from '../../Cards/Card'
import CallDetailsDrawer from 'components/CallPro/SideDrawer/CallDetailsDrawer/CallDetailsDrawer'
import ResolveCallDetailsWrapper from 'components/CallPro/SideDrawer/ResolveCallDetailsWrapper/ResolveCallDetailsWrapper'
import LoadingData from 'components/ProgressIndicator/LoadingData'

// UTILITY
import useSidePanel from '../../../helpers/hooks/useSidePanel'

// STYLES

// CONFIGS
import {
  callProCallsColumns,
  renderCallProDashboardColumns,
} from 'components/CallPro/Tables/CallsTableColumns'
import Account from 'models/Account'

interface OverviewMissedAndFollowUpCallsProp {}

const OverviewMissedAndFollowUpCalls = observer(
  (prop: OverviewMissedAndFollowUpCallsProp) => {
    const { callsStore, accountStore } = useStores()
    const { drawerOpen, handleCloseDrawer, handleSelectCall } = useSidePanel()
    const account: Account = accountStore.accounts[0]

    const onColumnSort = (column: string) => {
      callsStore.toggleSorting(column)
      callsStore.refreshCalls()
    }

    const onChangePage = (page: number) => {
      callsStore.setPaginationPage(page)
      callsStore.refreshCalls()
    }

    const onChangePageSize = (pageSize: number) => {
      callsStore.setPaginationSize(pageSize)
      callsStore.refreshCalls()
    }

    const {
      loadingStates,
      pagination,
      missedAndFollowupCallsPerPage,
      missedAndFollowupCallsTotalCount,
    } = callsStore

    return (
      <>
        <Card title={'Missed And Follow-up Calls (Last 30 days)'}>
          <CustomTable
            columns={callProCallsColumns(account)}
            rows={renderCallProDashboardColumns({
              calls: missedAndFollowupCallsPerPage,
              currentPage: pagination.page,
              pageSize: pagination.pageSize,
              stopAudio: false,
            })}
            defaultRowPerPage={pagination.pageSize}
            isLoading={loadingStates.missedAndFollowupCall}
            orderBy={pagination.sort}
            dir={pagination.dir}
            onColumnSort={onColumnSort}
            onChangePageSize={onChangePageSize}
            onChangePage={onChangePage}
            totalRowsCount={missedAndFollowupCallsTotalCount}
            currentPage={pagination.page}
            handleSelectCall={handleSelectCall}
          />
          <LoadingData hidden={!loadingStates.missedAndFollowupCall} />
        </Card>
        <CallDetailsDrawer
          drawerOpen={drawerOpen}
          handleCloseDrawer={handleCloseDrawer}
          title="Missed & Opportunity"
        >
          <ResolveCallDetailsWrapper />
        </CallDetailsDrawer>
      </>
    )
  }
)

export default OverviewMissedAndFollowUpCalls
