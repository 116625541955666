import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 30,
    },
    metricContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    percentage: {
      fontWeight: 'bold',
      fontSize: 35,
    },
    legend: {
      position: 'relative',
      fontSize: 13,
      paddingLeft: 12,
    },

    bullet: {
      content: '""',
      height: 8,
      width: 8,
      top: 6,
      left: 0,
      borderRadius: 8,
      position: 'absolute',
    },

    containerMobile: {
      '& $percentage': {
        fontSize: 25,
      },
      '& $legend': {
        fontSize: 11,
      },
      '& $bullet': {
        top: 4,
      },
    },
  })
)
