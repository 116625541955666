import API, { MAPS_API } from 'src/helpers/api'
import { CampaignType } from 'src/models/MailingArea'

export const updateMailingAreaColor = async ({id ,color}, options = {}) => {
  if (options && options.campaignType === CampaignType.NewMover) {
    await API.put({
      api: MAPS_API,
      url: `/mailing-areas/${id}/new-mover`,
      data: {
        color,
      }
    })
  } else {
    await API.put({
      api: MAPS_API,
      url: `/mailing-areas/${id}/color`,
      data: {
        color,
      }
    })
  }

  return true
}


export default updateMailingAreaColor
