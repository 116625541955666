import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'relative',
    borderTop: '1px solid #1c1c1c',
    backgroundColor: '#141414',
  },
  collapsed: {
    height: 0,
  },
  root: {
    display: 'flex',
    margin: '15px 10px',
  },
  avatar: {
    flex: '0 0 auto',
    width: 34,
    height: 34,
    marginLeft: 5,
    position: 'relative',
    cursor: 'pointer',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    fontFamily: '"Roboto", sans-serif',
    opacity: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: 10,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    marginRight: 10,
    '&:before,&:after': {
      content: '""',
      display: 'table',
      clear: 'both',
    },
  },
  hide: {
    opacity: 0,
  },
  profileName: {
    flex: '1 1 auto',
    fontSize: '16px',
    color: '#fff',
    marginRight: 16,
    marginLeft: 8,
    textOverflow: 'ellipsis',
    overflowWrap: 'normal',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 400,
  },
  currentAccount: {
    color: 'black',
    fontWeight: 900,
  },
  accountSelector: {
    display: 'flex',
    color: '#D3D3D395',
    fontSize: 14,
    fontWeight: 400,
  },
  filterIcon: {
    color: '#ED154E',
    width: 20,
  },
  accountDescription: {
    color: '#ffffff',
    marginLeft: 5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
    width: '100%',
  },
  singleAccountDescription: {
    marginLeft: 6,
    width: '100%',
  },
  button: {
    height: 32,
    width: 32,
    padding: 0,
  },
  listItem: {
    paddingLeft: 22,
    paddingRight: 22,
    paddingTop: 18,
    paddingBottom: 18,
    userSelect: 'none',
    flexWrap: 'wrap',
  },
  listText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '127px',
    paddingRight: '0px',
  },
  listTextSecondary: {
    color: '#727272',
    fontSize: '12px',
  },

  navTooltip: {
    left: 22,
    backgroundColor: 'rgb(16, 16, 16)',
    '&:after': {
      top: 9,
      left: -6,
      borderWidth: '7px',
      transform: 'rotate(90deg)',
      borderTopColor: 'rgb(16, 16, 16)',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}))

export default useStyles
