import { observable } from 'mobx'
import Repository from '../Repository'

export const repository = new Repository<Insurance>()

export default class Insurance {
  @observable id: string = ''
  @observable name: string = ''

  constructor(data: any) {
    this.id = data.id
    this.name = data.name
  }
}
