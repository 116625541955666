import React, { Component } from 'react'
import { withMediaProps } from 'react-media-player'
import MuteUnmute from 'src/components/AudioPlayer/MuteUnmute'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
    volumeContainer: {
        'position': 'relative',
        'padding': '10px 0px',
        'margin': '-10px 0px'
    },
    volumeSlider: {
        'transform': 'rotate(270deg)',
        'top': '-36px',
        'left': '-34px',
        'width': '55px',
        'position': 'absolute',
        '--range': 'calc(var(--max) - var(--min))',
        '--ratio': 'calc((var(--val) - var(--min))/var(--range))',
        '--sx': 'calc(.5*1.5em + var(--ratio)*(100% - 1.5em))',
        'margin': '0',
        'height': '1.5em',
        'background': 'transparent',
        'font': '1em/1 arial, sans-serif',
        'padding': '0px 18px',
        'box-sizing': 'content-box',
        '-webkit-appearance': 'none',
        '&::-webkit-slider-runnable-track': {
            'box-sizing': 'border-box',
            'border': 'none',
            'width': '12.5em',
            'height': '0.19em',
            'background': 'rgb(201,201,201)'
        },
        '&::-moz-range-track': {
            'box-sizing': 'border-box',
            'border': 'none',
            'width': '12.5em',
            'height': '0.19em',
            'background': 'rgb(201,201,201)',
            'border-radius': '3px'
        },
        '&::-ms-track': {
            'box-sizing': 'border-box',
            'border': 'none',
            'width': '12.5em',
            'height': '0.19em',
            'background': 'rgb(201,201,201)',
            'border-radius': '3px'
        },
        '&::-webkit-slider-thumb': {
            'margin-top': '-0.399em',
            'box-sizing': 'border-box',
            'border': 'none',
            'width': '.5em',
            'height': '1em',
            'background': 'rgb(203,0,57)',
            '-webkit-appearance': 'none',
            'position': 'relative',
            'z-index': '100',
            'cursor': 'grab'
        },
    }
})


@withStyles(styles)
class Volume extends Component {
    constructor(props){
        super(props)
        this.state = { isMouseInside: false }
    }

  _onChangeUsed = false

  _handleMouseUp = ({ target: { value } }) => {
    // set volume on mouseUp as well because of this bug in <= IE11
    // https://github.com/facebook/react/issues/554
    if (!this._onChangeUsed) {
      this.props.media.setVolume((+value).toFixed(4))
    }
  }

  _handleChange = ({ target: { value } }) => {
    this.props.media.setVolume((+value).toFixed(4))
    this._onChangeUsed = true
  }

  mouseEnter = () => {
    this.setState({ isMouseInside: true })
  }

  mouseLeave = () => {
    this.setState({ isMouseInside: false })
  }

  render() {
    const { className, style, media } = this.props
    const { volume } = media
    const { classes } = this.props

    return (
      <div className={classes.volumeContainer}>
        <div onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
            <MuteUnmute/>

            <input
                type="range"
                step="any"
                min={0}
                max={1}
                value={volume}
                onMouseUp={this._handleMouseUp}
                onChange={this._handleChange}
                className={classes.volumeSlider}
                style={{
                display: this.state.isMouseInside ? null : 'none',
                backgroundSize: volume * 100 / 1 + '% 100%',
                ...style,
                }}
            />
        </div>
      </div>
    )
  }
}

export default withMediaProps(Volume)
