import parseDate from 'date-fns/parseISO'
import { ReviewComment, CardFace } from '@mvp/mvp-react'

import API, { DATA_API } from 'helpers/api'

interface postReviewCommentArgs {
  designId: string
  body: string
  side: CardFace
  x: number | null
  y: number | null
  replyTo?: string
}

export const postReviewComment = async ({
  designId,
  body,
  side,
  x,
  y,
  replyTo,
}: postReviewCommentArgs): Promise<ReviewComment> => {
  const result = await API.post({
    api: DATA_API,
    url: `/designs/${designId}/review-comment`,
    data: {
      body,
      side: side.toLowerCase(),
      x,
      y,
      replyTo,
    },
  })

  result.createdDate = parseDate(result.createdDate)
  result.lastModifiedDate = parseDate(result.lastModifiedDate)

  const reviewComment = new ReviewComment(result)

  return reviewComment
}
