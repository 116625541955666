import { observable } from 'mobx'
import Repository from './Repository'

export const repository = new Repository()

export default class TrackingLines {
  @observable id: string = ''
  @observable campaignName: string = ''
  @observable campaignId: string = ''
  @observable number: string = ''
  @observable status: string = ''
  @observable type: string = ''
  @observable destinationNumber: string = ''
  @observable callsCount: number = 0
  @observable friendlyName: string = ''
  @observable campaignType: string = ''

  constructor(trackingLine: any) {
    this.id = trackingLine.id
    this.campaignName = trackingLine.campaignName
    this.campaignId = trackingLine.campaignId
    this.number = trackingLine.number
    this.status = trackingLine.status
    this.type = trackingLine.type
    this.destinationNumber = trackingLine.destinationNumber
    this.callsCount = trackingLine.callsCount
    this.friendlyName = trackingLine.friendlyName
    this.campaignType = trackingLine.campaignType
  }
}
