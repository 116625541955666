import React, { useState } from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Button from '@material-ui/core/Button'

import TextField from '@material-ui/core/TextField'
import Select from 'components/MVP/MVPSelect'
import DatePicker from 'components/MVP/Pickers/MVPDatePicker'

// STYLE
import { useStyles } from './AppointmentForm.style'
import { useStores } from 'config/store'

import { repository as appointmentsRepo } from 'src/models/Appointment'
import moment from 'moment'
import { range } from 'lodash'
import configStore from 'config/store/ConfigStore'
import { AppointmentFormFields } from 'interfaces/Call.interface'
import Appointment from 'models/Appointment'
import { Observer } from 'mobx-react'
import cx from 'classnames'
import { FormHelperText } from '@material-ui/core'
import { formatTextByIndustry } from 'helpers/industries'
import Account from 'models/Account'

type FormFields = {
  id?: string
  firstName: string | undefined
  lastName: string | undefined
  date: string | undefined
  time: string
}

const AppointmentForm = () => {
  const classes = useStyles()
  const { callStore, accountStore } = useStores()
  const call = callStore.drawer
  const fieldsDefaults = {
    firstName: '',
    lastName: '',
    date: '',
    time: '',
  }
  const [fields, setFields] = useState<FormFields>(fieldsDefaults)
  const [error, setError] = useState<FormFields>(fieldsDefaults)
  const [showForm, setShowForm] = useState(false)

  const account: Account = accountStore.accounts[0]

  const handleShowForm = () => {
    setError(fieldsDefaults)
    setFields(fieldsDefaults)
    setShowForm((prevState) => !prevState)
  }

  const handleTextFieldChange = (id: any) => (event: any) => {
    const data = { [id]: event.target.value }
    setFields({ ...fields, ...data })
  }

  const handleDateChange = (id: any) => (date: any) => {
    const data = { [id]: date }
    setFields({ ...fields, ...data })
  }

  const handleTimeChange = (id: any) => (value: any) => {
    const data = { [id]: (value && value.value) || '' }
    setFields({ ...fields, ...data })
  }

  // === SAVE APPOINTMENT HANDLER
  const handleSave = () => {
    const { firstName, lastName, date, time, id } = fields

    try {
      validate()
    } catch (e) {
      return
    }

    const apptID = id ? id : `${Date.now()}${Math.floor(Math.random() * 1000)}`
    const appointment = new Appointment({
      id: apptID,
    })
    // Assign a temporary ID
    appointment.firstName = firstName
    appointment.lastName = lastName
    appointment.scoreId = call?.scoreId
    appointment.isTemp = true
    appointment.call = call?.id
    appointment.campaign = call?.campaignId

    const h = parseInt(time) / 100
    const m = parseInt(time) % 100
    const dt = moment(date).set({ h, m, s: 0 })
    appointment.dateTime = dt.format()

    call && call.tempCreateAppointment(appointment)
    setShowForm(false)
    setFields(fieldsDefaults)
  }

  // === VALIDATE
  const validate = () => {
    const { firstName, lastName, date, time } = fields
    const error: any = {}

    if (!firstName) {
      error.firstName = formatTextByIndustry({
        account,
        text: '{CLIENT_LABEL} first name is required',
      })
    }

    if (!lastName) {
      error.lastName = formatTextByIndustry({
        account,
        text: '{CLIENT_LABEL} last name is required',
      })
    }

    if (!date) {
      error.date = 'Appointment date is required'
    }

    if (!time) {
      error.time = 'Appointment time is required'
    }

    if (Object.keys(error).length) {
      setError({ ...error })
      throw new Error('Validation error')
    }

    return true
  }

  if (!call) {
    return <div />
  }
  // === SELECT APPOINTMENT HANDLER
  const selectAppointment = (appointment: Appointment) => {
    const time = moment(appointment.dateTime).format('h:mm a')
    const formattedTime = time.replace(/[^\d]+/g, '')

    const currentAppointment = {
      id: appointment.id,
      date: appointment.dateTime,
      time: formattedTime,
      firstName: appointment.firstName,
      lastName: appointment.lastName,
    }
    setFields(currentAppointment)
    setShowForm((prevState) => !prevState)
  }

  // === DELETE APPOINTMENT HANDLER
  const deleteAppointment = (id: string) => {
    call.deleteAppt(id)
    setFields(fieldsDefaults)
    setShowForm((prevState) => !prevState)
  }

  return (
    <Observer
      render={() => {
        return (
          <section className={classes.appDetailsContainer}>
            <div className={classes.apptDetails}>
              <form>
                <Timeline>
                  <div className={classes.title}>Add Appointments</div>
                  <TimelineItem
                    classes={{
                      missingOppositeContent: classes.removeLeftDefaultContent,
                      root: classes.timeline,
                    }}
                  >
                    <TimelineSeparator>
                      <TimelineDot classes={{ root: classes.dot }}>
                        4
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div className={classes.step}>
                        Enter appointment details (if applicable)
                      </div>
                      <table className={classes.table}>
                        {!!call.appointments.length && (
                          <tr className={classes.row}>
                            <thead className={classes.thead}>Name</thead>
                            <thead className={classes.thead}>
                              Appointment Date
                            </thead>
                            <thead className={classes.thead}>
                              Appointment Time
                            </thead>
                          </tr>
                        )}
                        <tbody>
                          {call.appointments.slice().map((apptId, key) => {
                            const appointment = appointmentsRepo.get(apptId)
                            appointment.isLoading

                            return (
                              <tr
                                className={cx(
                                  classes.row,
                                  appointment.isTemp && classes.tempStatus
                                )}
                                onClick={() => selectAppointment(appointment)}
                              >
                                <td className={classes.td}>
                                  {appointment.fullName}
                                </td>
                                <td className={classes.td}>
                                  {appointment.dateTime
                                    ? moment(appointment.dateTime).format(
                                        'M/D/YY'
                                      )
                                    : '-'}
                                </td>
                                <td className={classes.td}>
                                  {appointment.dateTime
                                    ? moment(appointment.dateTime).format(
                                        'h:mm a'
                                      )
                                    : '-'}
                                </td>
                              </tr>
                            )
                          })}

                          {showForm ? (
                            <div>
                              <div className={classes.form}>
                                <TextField
                                  id={AppointmentFormFields.FIRST_NAME}
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  size="small"
                                  variant="outlined"
                                  className={classes.inputRoot2}
                                  classes={{ root: classes.textarea }}
                                  placeholder="First Name"
                                  value={fields.firstName}
                                  helperText={error.firstName}
                                  onChange={handleTextFieldChange(
                                    AppointmentFormFields.FIRST_NAME
                                  )}
                                  FormHelperTextProps={{
                                    classes: {
                                      root: classes.errorLabel,
                                    },
                                  }}
                                />
                                <TextField
                                  id={AppointmentFormFields.LAST_NAME}
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  size="small"
                                  variant="outlined"
                                  className={classes.inputRoot2}
                                  classes={{ root: classes.textarea }}
                                  placeholder="Last Name"
                                  value={fields.lastName}
                                  helperText={error.lastName}
                                  onChange={handleTextFieldChange(
                                    AppointmentFormFields.LAST_NAME
                                  )}
                                  FormHelperTextProps={{
                                    classes: {
                                      root: classes.errorLabel,
                                    },
                                  }}
                                />
                              </div>

                              <DatePicker
                                id={AppointmentFormFields.APPT_DATE}
                                inputProps={{
                                  classes: {
                                    root: classes.datePicker,
                                  },
                                  helperText: error.date,
                                  FormHelperTextProps: {
                                    classes: {
                                      root: classes.errorLabel,
                                    },
                                  },
                                }}
                                placeholder="Choose a date"
                                value={fields.date}
                                pickerProps={{}}
                                size="small"
                                onChange={handleDateChange(
                                  AppointmentFormFields.APPT_DATE
                                )}
                              />

                              <Select
                                placeholder="Choose time slot"
                                value={fields.time}
                                menuPlacement="auto"
                                maxMenuHeight={200}
                                fullWidth
                                options={range(12 * 4 + 1).map((i) => {
                                  const momentVal = moment()
                                    .startOf('day')
                                    .add(i * 15 + 7 * 60, 'minute')
                                  const label = momentVal.format(
                                    configStore.timeFormat
                                  )
                                  const value = momentVal.format('Hmm')
                                  return {
                                    label,
                                    value,
                                  }
                                })}
                                onChange={handleTimeChange(
                                  AppointmentFormFields.APPT_TIME
                                )}
                              />
                              <div
                                className={cx(
                                  classes.apptBtn,
                                  fields.id && classes.positionBtns
                                )}
                              >
                                <Button
                                  className={cx(
                                    classes.deleteApptBtn,
                                    fields.id && classes.showBtn
                                  )}
                                  onClick={() => {
                                    fields.id && deleteAppointment(fields.id)
                                  }}
                                >
                                  Delete
                                </Button>
                                <div className={classes.btnWrapper}>
                                  <Button
                                    className={classes.cancelApptBtn}
                                    onClick={handleShowForm}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className={classes.addApptBtn}
                                    onClick={handleSave}
                                  >
                                    {fields.id ? 'Update' : 'Save'}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <Button
                              className={classes.addBtn}
                              onClick={handleShowForm}
                            >
                              Add Appointment
                            </Button>
                          )}
                        </tbody>
                      </table>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </form>
            </div>
          </section>
        )
      }}
    />
  )
}

export default AppointmentForm
