import API, { IDENTITY_API } from 'src/helpers/api'

const getSfUser = async (id) => {
  let sfUser = await API.get({
    api: IDENTITY_API,
    url: `account/${id}/user`
  })

  return sfUser
}

export default getSfUser
