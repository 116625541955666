import { makeStyles } from '@material-ui/core/styles'
import placeholderDelivered from 'assets/img/thumbnail1.png'

export const useCallProStyles = makeStyles({
  root: {
    margin: -25,
  },
  container: {
    backgroundColor: 'red',
    padding: '1rem',
  },
  appBar: {
    backgroundColor: '#fff',
    flex: '0 0 auto',
    boxShadow: '0 1px 0 rgba(0, 0, 0, 0.125)',
  },
})
