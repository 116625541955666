import React from 'react'
import cx from 'classnames'

// COMPONENTS
import { ReactComponent as EmptyBarchart } from '../../../assets/img/call-pro/empty-barchart.svg'

// STYLES
import { useStyles, useStyleType } from './EmptyCharts.styles'

interface EmptyBarChartProps {
  classes?: useStyleType
}

const EmptyBarChart: React.FC<EmptyBarChartProps> = (props) => {
  const styles = useStyles()
  const { classes } = props

  return (
    <div className={cx(styles.root, classes?.root)}>
      <div className={cx(styles.emptyChart, classes?.emptyChart)}>
        <EmptyBarchart />
        <span className={cx(styles.emptyChartLabel, classes?.emptyChartLabel)}>
          No data available
        </span>
      </div>
    </div>
  )
}

export default EmptyBarChart
