import dot_0FBB58 from 'src/assets/img/patientList/patientMarkers/dot_0FBB58.svg'
import dot_1AC4AD from 'src/assets/img/patientList/patientMarkers/dot_1AC4AD.svg'
import dot_007CBA from 'src/assets/img/patientList/patientMarkers/dot_007CBA.svg'
import dot_009AEE from 'src/assets/img/patientList/patientMarkers/dot_009AEE.svg'
import dot_5117EE from 'src/assets/img/patientList/patientMarkers/dot_5117EE.svg'
import dot_666666 from 'src/assets/img/patientList/patientMarkers/dot_666666.svg'
import dot_B605D5 from 'src/assets/img/patientList/patientMarkers/dot_B605D5.svg'
import dot_F50D0D from 'src/assets/img/patientList/patientMarkers/dot_F50D0D.svg'
import dot_F77935 from 'src/assets/img/patientList/patientMarkers/dot_F77935.svg'
import dot_FC4491 from 'src/assets/img/patientList/patientMarkers/dot_FC4491.svg'
import dot_FFA200 from 'src/assets/img/patientList/patientMarkers/dot_FFA200.svg'
import dot_FFD600 from 'src/assets/img/patientList/patientMarkers/dot_FFD600.svg'

import dot_0FBB58_a from 'src/assets/img/patientList/patientMarkers/dot_0FBB58_a.svg'
import dot_1AC4AD_a from 'src/assets/img/patientList/patientMarkers/dot_1AC4AD_a.svg'
import dot_007CBA_a from 'src/assets/img/patientList/patientMarkers/dot_007CBA_a.svg'
import dot_009AEE_a from 'src/assets/img/patientList/patientMarkers/dot_009AEE_a.svg'
import dot_5117EE_a from 'src/assets/img/patientList/patientMarkers/dot_5117EE_a.svg'
import dot_666666_a from 'src/assets/img/patientList/patientMarkers/dot_666666_a.svg'
import dot_B605D5_a from 'src/assets/img/patientList/patientMarkers/dot_B605D5_a.svg'
import dot_F50D0D_a from 'src/assets/img/patientList/patientMarkers/dot_F50D0D_a.svg'
import dot_F77935_a from 'src/assets/img/patientList/patientMarkers/dot_F77935_a.svg'
import dot_FC4491_a from 'src/assets/img/patientList/patientMarkers/dot_FC4491_a.svg'
import dot_FFA200_a from 'src/assets/img/patientList/patientMarkers/dot_FFA200_a.svg'
import dot_FFD600_a from 'src/assets/img/patientList/patientMarkers/dot_FFD600_a.svg'

import star_0FBB58 from 'src/assets/img/patientList/patientMarkers/star_0FBB58.svg'
import star_1AC4AD from 'src/assets/img/patientList/patientMarkers/star_1AC4AD.svg'
import star_007CBA from 'src/assets/img/patientList/patientMarkers/star_007CBA.svg'
import star_009AEE from 'src/assets/img/patientList/patientMarkers/star_009AEE.svg'
import star_5117EE from 'src/assets/img/patientList/patientMarkers/star_5117EE.svg'
import star_666666 from 'src/assets/img/patientList/patientMarkers/star_666666.svg'
import star_B605D5 from 'src/assets/img/patientList/patientMarkers/star_B605D5.svg'
import star_F50D0D from 'src/assets/img/patientList/patientMarkers/star_F50D0D.svg'
import star_F77935 from 'src/assets/img/patientList/patientMarkers/star_F77935.svg'
import star_FC4491 from 'src/assets/img/patientList/patientMarkers/star_FC4491.svg'
import star_FFA200 from 'src/assets/img/patientList/patientMarkers/star_FFA200.svg'
import star_FFD600 from 'src/assets/img/patientList/patientMarkers/star_FFD600.svg'

export {
  dot_0FBB58,
  dot_1AC4AD,
  dot_007CBA,
  dot_009AEE,
  dot_5117EE,
  dot_666666,
  dot_B605D5,
  dot_F50D0D,
  dot_F77935,
  dot_FC4491,
  dot_FFA200,
  dot_FFD600,
  star_0FBB58,
  star_1AC4AD,
  star_007CBA,
  star_009AEE,
  star_5117EE,
  star_666666,
  star_B605D5,
  star_F50D0D,
  star_F77935,
  star_FC4491,
  star_FFA200,
  star_FFD600
}

export const PatientColors = [
  '#0FBB58',
  '#1AC4AD',
  '#007CBA',
  '#009AEE',
  '#5117EE',
  '#666666',
  '#B605D5',
  '#F50D0D',
  '#F77935',
  '#FC4491',
  '#FFA200',
  '#FFD600'
]

const getPatientIconByHex = (hex, isStarred, withAlpha) => {
  if (hex) {
    switch (hex.toUpperCase()) {
      case '#0FBB58':
        return isStarred ? star_0FBB58 : withAlpha ? dot_0FBB58_a : dot_0FBB58
      case '#1AC4AD':
        return isStarred ? star_1AC4AD : withAlpha ? dot_1AC4AD_a : dot_1AC4AD
      case '#007CBA':
        return isStarred ? star_007CBA : withAlpha ? dot_007CBA_a : dot_007CBA
      case '#009AEE':
        return isStarred ? star_009AEE : withAlpha ? dot_009AEE_a : dot_009AEE
      case '#5117EE':
        return isStarred ? star_5117EE : withAlpha ? dot_5117EE_a : dot_5117EE
      case '#666666':
        return isStarred ? star_666666 : withAlpha ? dot_666666_a : dot_666666
      case '#B605D5':
        return isStarred ? star_B605D5 : withAlpha ? dot_B605D5_a : dot_B605D5
      case '#F50D0D':
        return isStarred ? star_F50D0D : withAlpha ? dot_F50D0D_a : dot_F50D0D
      case '#F77935':
        return isStarred ? star_F77935 : withAlpha ? dot_F77935_a : dot_F77935
      case '#FC4491':
        return isStarred ? star_FC4491 : withAlpha ? dot_FC4491_a : dot_FC4491
      case '#FFA200':
        return isStarred ? star_FFA200 : withAlpha ? dot_FFA200_a : dot_FFA200
      case '#FFD600':
        return isStarred ? star_FFD600 : withAlpha ? dot_FFD600_a : dot_FFD600
      default:
        return isStarred ? star_F50D0D : withAlpha ? dot_F50D0D_a : dot_F50D0D
    }
  }

  return isStarred ? star_007CBA : withAlpha ? dot_007CBA_a : dot_007CBA
}


export default getPatientIconByHex
