import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { COLORS } from 'constants/constants'

export const datePickerStyles = {
  caption_label: {
    fontSize: 15,
    marginBottom: 10,
  },

  nav_button: {
    '& svg': {
      height: 12,
      width: 12,
    },
    color: COLORS.primary.default,
  },

  head_cell: {
    color: COLORS.neutral.shade400,
    fontSize: 14,
    fontWeight: 'normal',
  },
  day: {
    fontSize: 14,
    height: '100%',
    width: '100%',
    borderRadius: '100%',
  },
  button: {
    border: 'none',
    '&:not([disabled])': {
      cursor: 'pointer',
    },
    '&:focus-visible:not([disabled])': {
      color: '#fff',
      background: COLORS.primary.default,
    },
    '&:hover:not([disabled]):not($day_selected)': {
      background: COLORS.neutral.shade50,
    },
  },
  day_selected: {
    color: COLORS.black,
    background: COLORS.neutral.shade100,
    '&:focus-visible': {
      background: 'red !important',
    },
    '&:hover': {},
  },
  day_range_start: {
    '&:not($day_range_end)': {
      borderRadius: '100%',
      color: COLORS.black,
      background: COLORS.neutral.shade100,
    },
  },
  day_range_end: {
    '&:not($day_range_start)': {
      borderRadius: '100%',
      color: COLORS.black,
      background: COLORS.neutral.shade100,
    },
  },
  day_range_middle: {
    borderRadius: '100%',
    color: COLORS.black,
    background: COLORS.neutral.shade100,
  },
}

// @ts-ignore
export const useDatePickerStyles = makeStyles((theme: Theme) => {
  return {
    ...datePickerStyles,
    months: {
      display: 'flex',
      [theme.breakpoints.down(750)]: {
        flexDirection: 'column',
      },
    },
    month: {
      [theme.breakpoints.down(750)]: {
        margin: 0,
      },
      '&:first-child': {
        [theme.breakpoints.down(750)]: {
          marginBottom: 12,
        },
      },
    },
    cell: {
      [theme.breakpoints.down(750)]: {
        height: 35,
        width: 35,
      },
    },
  }
})
