import { useContext } from 'react'
import { configure } from 'mobx'
import { MobXProviderContext } from 'mobx-react'

import appState, { AppState } from 'config/store/AppState'
import callsStore, { CallsStore } from 'config/store/CallPro/Call/CallsStore'
import callStore, { CallStore } from 'config/store/CallPro/Call/CallStore'
import configStore, { ConfigStore } from 'config/store/ConfigStore'
import mailingsStore, { MailingsStore } from 'config/store/MailingsStore'
import settingsStore, { SettingsStore } from 'config/store/SettingsStore'
import mapsStore, { MapsStore } from 'config/store/MapsStore'
import accountStore, { AccountStore } from 'config/store/AccountStore'
import trackingLinesStore, {
  TrackingLinesStore,
} from './CallPro/TrackingLines/TrackingLinesStore'
import offerStore, { OfferStore } from './CallPro/Offer/OfferStore'
import insuranceStore, {
  InsuranceStore,
} from './CallPro/Insurance/InsuranceStore'
import productStore, { ProductStore } from './CallPro/Product/ProductStore'
import frontDeskStore, {
  FrontDeskStore,
} from './CallPro/FrontDesk/FrontDeskStore'
import appointmentStore, {
  AppointmentStore,
} from './CallPro/Appointment/AppointmentStore'
import dashboardUIStore, { DashboardUIStore } from './CallPro/DashboardUIStore'
import globalFilterStore, { GlobalFilterStore } from './GlobalFilterStore'

// Set to true to ensure that all state mutations
// are produced by mobx actions only
configure({ enforceActions: 'never' })

interface Stores {
  appState: AppState
  callsStore: CallsStore
  callStore: CallStore
  configStore: ConfigStore
  frontDeskStore: FrontDeskStore
  appointmentStore: AppointmentStore
  mailingsStore: MailingsStore
  settingsStore: SettingsStore
  mapsStore: MapsStore
  accountStore: AccountStore
  trackingLinesStore: TrackingLinesStore
  offerStore: OfferStore
  insuranceStore: InsuranceStore
  productStore: ProductStore
  dashboardUIStore: DashboardUIStore
  globalFilterStore: GlobalFilterStore
}

export const useStores = (): Stores => {
  return useContext<Stores>(MobXProviderContext)
}

const stores: Stores = {
  appState,
  configStore,
  frontDeskStore,
  appointmentStore,
  callsStore,
  callStore,
  mailingsStore,
  settingsStore,
  mapsStore,
  accountStore,
  trackingLinesStore,
  offerStore,
  insuranceStore,
  productStore,
  dashboardUIStore,
  globalFilterStore,
}

export default stores
