import _get from 'lodash/get'
import ApiHelper, { IDENTITY_API } from 'src/helpers/api'

/**
 * Retrieves credentials for scoreboard access via grant code
 *
 * @param {string} code The authorization code for the account access grant
 */
export const scoreboardGrant = async (code) => {
  const response = await ApiHelper.request({
    method: 'post',
    api: IDENTITY_API,
    url: '/auth/scoreboard/grant',
    data: {
      code
    },
  })

  const data = _get(response, 'data.data')
  return data
}

export default scoreboardGrant
