import React, { Fragment, createContext, useContext } from 'react'

import { groupStringByMatches, groupPhoneNumberByMatches } from 'helpers/text'

export const HighlightContext = createContext<RegExp | undefined>(undefined)

interface HighlightedTextBaseProps {
  children: React.ReactNode
  matchFn: (input: string, exp: RegExp) => Array<[string, boolean]>
  highlightedClassName?: string
  highlightedTextStyle?: React.CSSProperties
  defaultClassName?: string
  defaultTextStyle?: React.CSSProperties
  highlightPattern?: RegExp
}

export const HighlightedTextBase: React.FC<HighlightedTextBaseProps> = props => {
  if (typeof props.children !== 'string') {
    return <span>{props.children}</span>
  }

  const highlightPattern = props.highlightPattern ?? useContext(HighlightContext)

  if (typeof highlightPattern === 'undefined') {
    return <span>{props.children}</span>
  }

  const groupedMatches = props.matchFn(props.children, highlightPattern)

  const defaultTextStyle = props.defaultTextStyle ?? {}
  const highlightedTextStyle = props.highlightedTextStyle ?? {
    backgroundColor: '#FFF3A1',
    fontWeight: 'bold'
  }

  return (
    <Fragment>
      {groupedMatches.map(([str, highlighted], i) => (
        <span
          key={`${i}${str}`}
          style={highlighted ? highlightedTextStyle : defaultTextStyle}
          className={highlighted ? props.highlightedClassName : props.defaultClassName}
        >
          {str}
        </span>
      ))}
    </Fragment>
  )
}

interface HighlightedTextProps extends Partial<HighlightedTextBaseProps> {
  children: React.ReactNode
  matchFn?: (input: string, exp: RegExp) => Array<[string, boolean]>
}

export const HighlightedText: React.FC<HighlightedTextProps> = props => (
  <HighlightedTextBase matchFn={groupStringByMatches} {...props} />
)

export const HighlightedPhoneNumber: React.FC<HighlightedTextProps> = props => (
  <HighlightedTextBase matchFn={groupPhoneNumberByMatches} {...props} />
)
