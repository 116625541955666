import React from 'react'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import ToolTip from '@material-ui/core/Tooltip'
import { default as DumbCard } from '../../Cards/Card'

import { useStyles } from './ChartCard.styles'
import { formatNumber } from 'helpers/utility'

interface ChartCardInterface {
  children: React.ReactNode
  headerTitle: string
  headerTotalText: string
  headerTotalValue: number
  tooltipText: string
  isAccordionContent?: boolean
}

const ChartCard = (props: ChartCardInterface) => {
  const classes = useStyles()
  const {
    headerTitle,
    tooltipText,
    headerTotalText,
    headerTotalValue,
    children,
    isAccordionContent,
  } = props

  return (
    <DumbCard
      isAccordionContent={isAccordionContent}
      title={
        <div className={classes.headerWrapper}>
          <div className={classes.titleWrapper}>
            <span>{headerTitle}</span>
            <ToolTip
              title={tooltipText}
              placement="bottom-start"
              classes={{
                tooltip: classes.tooltip,
              }}
            >
              <InfoOutlinedIcon className={classes.grade_icon} />
            </ToolTip>
          </div>
          {headerTotalText.length && headerTotalValue ? (
            <div>
              <span className={classes.totalLabel}>{headerTotalText}: </span>
              <span className={classes.totalCount}>
                {formatNumber(headerTotalValue)}
              </span>
            </div>
          ) : null}
        </div>
      }
    >
      {children}
    </DumbCard>
  )
}

export default ChartCard
