import { makeStyles } from '@material-ui/core/styles'

export const useOverviewCardStyles = makeStyles({
  root: {
    '& .MuiCard-root': {
      marginBottom: '2rem',
    },
  },

  emptyState: {
    flexGrow: 1,
    backgroundColor: '#F3F3F3',
  },

  placeholder: {
    marginTop: 16,
  },

  // LAYOUTS
  col1: {
    marginBottom: '1.5rem',
  },

  col2: {
    marginBottom: '1.5rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
  },
  accordionWrapper: {
    borderRadius: 10,
    overflow: 'hidden',
    border: '1px solid #d3d3d3',
    marginBottom: 12,
  },
})
