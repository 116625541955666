import React, { Component } from 'react'
import { Theme } from '@material-ui/core'
import { PlayCircleFilled, PauseCircleFilled } from '@material-ui/icons'
import { withMediaProps, utils } from 'react-media-player'
import withStyles from '@material-ui/core/styles/withStyles'

const { formatTime } = utils

const styles = (theme: Theme) => ({
  playBtnStyle: {
    position: 'relative',
    top: '7px',
    marginRight: '8px',
    cursor: 'pointer',
    zIndex: '999',
  },
})

interface PlayPauseProps {
  stopAudio: boolean
  callDuration: number
  media: any
  classes: any
  id: string
  handleAudioPlayPause: (id: string) => void
  currentlyPlaying: string
}

interface PlayPauseState {
  time: any
}

class PlayPause extends Component<PlayPauseProps, PlayPauseState> {
  interval: NodeJS.Timeout | null = null

  constructor(props: any) {
    super(props)
    this.state = { time: formatTime(0) }
  }

  componentDidUpdate(a: any, b: any) {
    if (this.props.stopAudio) {
      this.props.media.stop()
    }

    if (
      this.props.media.isPlaying &&
      this.props.currentlyPlaying !== this.props.id
    ) {
      this.props.media.stop()
    }
  }

  handlePlayPause = (e: React.MouseEvent) => {
    this.props.handleAudioPlayPause(e.currentTarget.id)
    this.props.media.playPause()

    this.interval = setInterval(
      () => this.setState({ time: formatTime(this.props.media.currentTime) }),
      1000
    ) as unknown as NodeJS.Timeout

    e.stopPropagation()
  }

  render() {
    const { classes } = this.props

    return (
      <div>
        {this.props.media.isPlaying ? (
          <PauseCircleFilled
            id={this.props.id}
            onClick={this.handlePlayPause}
            className={classes.playBtnStyle}
            color="primary"
          />
        ) : (
          <PlayCircleFilled
            id={this.props.id}
            onClick={this.handlePlayPause}
            className={classes.playBtnStyle}
            color="primary"
          />
        )}

        {this.props.media.isPlaying ? (
          <time> {this.state.time}</time>
        ) : (
          <time> {formatTime(this.props.callDuration)}</time>
        )}
      </div>
    )
  }
}

export default withMediaProps(withStyles(styles as any)(PlayPause))
