import background from 'src/assets/bg.png'
import { defaultFont } from 'src/assets/jss/mvp'
import loginFormStyle from 'src/assets/jss/material-dashboard-react/loginFormStyle'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundSize: 'cover',
    backgroundImage: `url(${background})`,
    backgroundAttachment: 'fixed',
    'min-height': '100vh',
    height: '100%'
  },

  setupAccountBody: {
    padding: '24px !important'
  },

  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  '@media (max-width: 1024px)': {
    container: {
      flexDirection: 'column'
    },
    contentWrapper: {
      marginTop: '20px !important',
      textAlign: 'center',
      marginRight: '0px',
      width: '100%',
      '& img': {
        height: '100px',
        width: '194px'
      }
    },
    formContainer: {
      marginBottom: '50px'
    }
  },
  contentWrapper: {
    marginTop: '1.3%',
    marginRight: '16%'
  },

  link: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.primary.dark
    }
  },

  error: {
    ...defaultFont,
    color: '#fff',
    fontSize: '28px'
  },

  form: {
    '& [input[type="disabled"]': {
      cursor: 'not-allowed',
      color: '#141414 !important'
    }
  },
  emailDetailsWrapper: {
    textAlign: 'center',
    '& strong': {
      wordBreak: 'break-all'
    },
    '& h3': {
      fontSize: '14px',
      fontWeight: '600'
    }
  },

  emalInstruction: {
    textAlign: 'left',
    fontSize: 13,
    lineHeight: 1.5,
    marginBottom: 24
  },

  btn: {
    marginTop: '0px !important'
  },

  ...loginFormStyle
})

export default styles