import React from 'react'
import clsx from 'clsx'

import { useStores } from 'config/store'

import { Observer } from 'mobx-react'
import { ReactComponent as Percent } from 'assets/img/call-pro/percent_white.svg'
import {
  CallOutlined,
  PersonAddOutlined,
  PhoneMissedOutlined,
  CachedOutlined,
  ThumbUpOutlined,
} from '@material-ui/icons'

// Styles
import { useAllStatsCardStyles } from './AllStats.style'
import StatCard from 'components/StatCard/StatCard'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'
import { useHistory } from 'react-router'
import { formatTextByIndustry } from 'helpers/industries'
import Account from 'models/Account'

const AllStats = () => {
  const classes = useAllStatsCardStyles()
  const { mailingsStore, dashboardUIStore, callsStore, accountStore } =
    useStores()
  const history = useHistory()
  const account: Account = accountStore.accounts[0]
  const clientLabel = account.clientLabel ?? ''

  const handleClick = (outcome: CallOutcome) => () => {
    dashboardUIStore.setCurrentTabbedOpened(CallDashboardTabs.ALL_CALLS)
    callsStore.resetByTab(CallDashboardTabs.ALL_CALLS)
    callsStore.setFilters({ outcome })
    callsStore.refreshAllCallsTab()
    history.push('/scoreboard', { reset: false })
  }

  return (
    <Observer
      render={() => {
        const { summary, isLoading } = mailingsStore
        const { all, leads, appointments, conversionRate, missed, followUps } =
          summary

        const missedRate = Math.round((missed / (missed + leads)) * 100)

        return (
          <div className={clsx(classes.container)}>
            <StatCard
              label="All Calls"
              count={all}
              icon={<CallOutlined />}
              isLoading={isLoading}
              tooltipText="This is the total number of calls received through your tracking line. This may include test calls, spam calls, missed calls, unanswered calls, and voicemails, etc."
              actionLabel="View Calls"
              handleClick={handleClick(CallOutcome.ALL_CALLS)}
            />

            <StatCard
              label="Opportunity Calls"
              count={leads}
              icon={<ThumbUpOutlined />}
              isLoading={isLoading}
              tooltipText="This is the total number of calls we scored as valid appointment opportunities, including potential opportunities from missed calls, unanswered calls, and voicemails that require follow-up."
              actionLabel="View Calls"
              handleClick={handleClick(CallOutcome.OPPORTUNITY)}
            />
            <StatCard
              label="Appointments"
              count={appointments}
              icon={<PersonAddOutlined />}
              isLoading={isLoading}
              tooltipText="This is the total number of scheduled appointments, including missed calls where a voicemail was left with contact information and details for scheduling an appointment."
              actionLabel="View Calls"
              handleClick={handleClick(CallOutcome.APPOINTMENT)}
            />
            <StatCard
              label="Conversion Rate"
              icon={<Percent />}
              isLoading={isLoading}
              tooltipText="This is the average rate at which the staff converts answered opportunity calls into scheduled appointments."
              handleClick={handleClick(CallOutcome.APPOINTMENT)}
            >
              <div className={clsx(classes.conversionRateCount)}>
                {conversionRate ? conversionRate : 0}%
              </div>
            </StatCard>
            <StatCard
              label="Missed Calls"
              icon={<PhoneMissedOutlined />}
              isLoading={isLoading}
              tooltipText="This is the total number of calls that were missed, unanswered, handled by IVR, or sent to voicemail. These calls are considered potential opportunities requiring staff follow-up."
              actionLabel="View Calls"
              handleClick={handleClick(CallOutcome.MISSED_CALL)}
            >
              <div className={clsx(classes.missedCallValue)}>
                <div className={clsx(classes.missedCallCount)}>{missed}</div>
                <div className={clsx(classes.missedCallRate)}>
                  {missedRate ? missedRate.toFixed() : 0}%
                </div>
              </div>
            </StatCard>
            <StatCard
              label="Follow-up Opportunities"
              count={followUps}
              icon={<CachedOutlined />}
              isLoading={isLoading}
              tooltipText={`Total number of potential new ${clientLabel.toLowerCase()} opportunities over the last 30 days that need staff follow-up to resolve and remove from this list.`}
              actionLabel="View Calls"
              handleClick={handleClick(CallOutcome.FOLLOWUP)}
            />
          </div>
        )
      }}
    ></Observer>
  )
}

export default AllStats
