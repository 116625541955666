import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  callId: { display: 'none' },

  label: {
    padding: '6px 10px',
    borderRadius: '2px',
    color: '#929292',
    border: '1px solid #929292',
    backgroundColor: '#fff',
    fontSize: '.8rem',
    width: 'fit-content',
  },

  appointment: {
    color: '#56B863 !important',
    border: '1px solid #56B863 !important',
  },
  missedCall: {
    color: '#E13426 !important',
    border: '1px solid #E13426 !important',
  },
  followUpNeeded: {
    color: '#E13426 !important',
    border: '1px solid #E13426 !important',
  },
  cancelled: {
    color: 'rgb(154, 162, 172) !important',
    border: '1px solid rgb(154, 162, 172) !important',
  },
  rescheduled: {
    color: 'rgb(154, 162, 172) !important',
    border: '1px solid rgb(154, 162, 172) !important',
  },
  opportunityCall: {
    color: '#4498E7 !important',
    border: '1px solid #4498E7 !important',
  },
  nonOpportunity: {
    color: 'rgb(154, 162, 172) !important',
    border: '1px solid rgb(154, 162, 172) !important',
  },
  default: {
    color: 'rgb(131, 141, 158) !important',
    border: '1px solid rgb(131, 141, 158) !important',
    background: 'rgb(231, 233, 238) !important',
  },

  colStackedText: {
    lineHeight: '1.4rem',
  },

  callbackIcon: {
    fontSize: '1.2rem',
    color: '#929292',
    verticalAlign: 'middle',
    marginLeft: '.7rem',
  },

  // TOOLTIP
  tooltip: {
    cursor: 'pointer',
    backgroundColor: '#222',
    '&::after': {
      display: 'none',
    },
  },
  appointmentsCount: {
    position: 'absolute',
    top: '-6px !important',
    left: '-14px !important',
  },
  highlightIcon: {
    verticalAlign: 'middle',
    color: '#8D8D8D',
    '&:hover': {
      color: '#ED154E',
    },
  },
  count: {
    position: 'relative',
    textAlign: 'center',
    width: '22px',
    height: '25px',
    margin: 'auto',
    '& span': {
      color: '#fff !important',
      fontFamily: 'Roboto',
      fontWeight: '600',
      fontSize: '10px',
      position: 'relative',
      top: '-30px',
    },
    '& img': {
      width: 22,
    },
  },
  tooltipWrapper: {
    display: 'flex',
    padding: 10,
    backgroundColor: 'rgb(54, 54, 54)',
    flexDirection: 'column',
  },
  moreTabWrapper: {
    color: 'rgb(114, 141, 141)',
    position: 'absolute',
    right: -20,
    top: 0,
    fontSize: 12,
  },
}))
