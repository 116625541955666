import { DesignVersionStatus, DesignStatus, Contact } from '@mvp/mvp-react'
import { getDesigns } from 'services/designs/getDesigns'

export interface GetDesignInterface {
  id: string
  account: {
    name: string
  }
  name: string
  comments: any[]
  currentVersion: {
    id: string
    version: number
    status: DesignVersionStatus
    comments: any[]
    file: string
    started: string
    frontDesignLastModified?: string
    backDesignLastModified?: string
    frontDesignThumbnailLastModified?: string
    backDesignThumbnailLastModified?: string
  }
  nextReview: DesignStatus
  currentApprovalLevel: DesignStatus
  lastReviewCompleted: string
  initialReviewVersion: string
  marketingReviewVersion: string
  mediaType: string
  customerReviewCompleted?: string
  customerReviewVersion?: string
  customerReviewer?: Contact
}

export default async (id: string): Promise<GetDesignInterface> => {
  const results = await getDesigns([id])
  return results[0]
}
