import { sampleData } from '@mvp/mvp-react'
import appState from 'config/store/AppState'
import accountStore from 'config/store/AccountStore'

const {
  designMailings,
  officeMarkerUrl,
  officeMarkerCoordinates,
  carrierRoutes,
} = sampleData

export const getProps = () => {
  const { photoUrl, name, email, position, phone } = accountStore.sfUserData
  const mc = {
    avatar: `data:image/png;base64,${photoUrl}`,
    name,
    email,
    position,
    phone,
  }

  const currentUser = appState.profileName

  return {
    mc,
    currentUser,
    designMailings,
    officeMarkerUrl,
    officeMarkerCoordinates,
    carrierRoutes,
  }
}
