import API, { DATA_API } from 'helpers/api'

interface RejectCampaignMedia {
  id: string
}

export const rejectCampaignMedia = async ({ id }: RejectCampaignMedia) => {
  await API.post({
    api: DATA_API,
    url: `/campaigns/${id}/design/reject`,
  })
}
