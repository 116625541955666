import {
  CallFilters,
  CallOfficeHandlingRateInterface,
  CallOutcome,
  CallSortDirection,
  CallDashboardTabs,
  AggregatedPicklistInterface,
} from 'interfaces/Call.interface'
import { TopCampaignSourceInterface } from 'services/calls/getTopCampaignSource'
import { ReferralSourceInterface } from 'services/calls/getReferralSource'

const summaryCount = {
  all: 0,
  allAppointmentSetByStaff: 0,
  opportunityByStaff: 0,
  opportunity: 0,
  nonOpportunity: 0,
  appointment: 0,
  callsWithAppointment: 0,
  missed: 0,
  followUp: 0,
}

const pagination = {
  page: 1,
  pageSize: 15,
  sort: 'callDateTime',
  dir: CallSortDirection.DESC,
  searchQuery: '',
}

const globalFilters = {
  year: 2018,
  month: -1,
  dateFrom: new Date('January 2018') as Date | null | undefined,
  dateTo: new Date() as Date | null | undefined,
  campaignIds: [] as string[],
  currentPage: 1,
}

const callFilters: CallFilters = {
  outcome: CallOutcome.OPPORTUNITY,
  callHandlerId: null,
  answeredBy: undefined,
  hasAudio: null,
}

const officeHandlingRate: CallOfficeHandlingRateInterface = {
  totalCalls: 0,

  appointmentRate: 0,
  askedForAppointmentCount: 0,
  validAskedForAppointment: 0,
  askedForAppointmentCallerMentioned: 0,

  askedForSourceRate: 0,
  askedForSourceCount: 0,
  validAskedForSource: 0,
  askedForSourceCallerMentioned: 0,

  askedForGoodImpressionRate: 0,
  askedForGoodImpressionCount: 0,
  validAskedForGoodImpressionCount: 0,

  askedForAdditionalAppointmentRate: 0,
  askedForAdditionalAppointmentCount: 0,
  validAskedForAdditionalAppointment: 0,
  askedForAdditionalAppointmentCallerMentioned: 0,

  callerInteractedWithIVRRate: 0,
  callerInteractedWithIVRCount: 0,
  validCallerInteractedWithIVRCount: 0,
}

export const CallConfig = {
  initialValues: {
    summaryCount,
    pagination,
    officeHandlingRate,
  },
  filters: {
    global: globalFilters,
    call: callFilters,
  },
  tabs: [
    CallDashboardTabs.OVERVIEW,
    CallDashboardTabs.FRONT_DESK,
    CallDashboardTabs.ALL_CALLS,
  ],
}

export const dateFormat = 'MM/DD/YYYY'

export const FollowUpReason: AggregatedPicklistInterface[] = []
export const NoApptScheduledReason: AggregatedPicklistInterface[] = []
export const CallerGender: AggregatedPicklistInterface[] = []
export const PaymentTypes: AggregatedPicklistInterface[] = []
export const NonOpptyReason: AggregatedPicklistInterface[] = []
export const AnsweredBy: AggregatedPicklistInterface[] = []
export const IVROutcome: AggregatedPicklistInterface[] = []
export const VoicemailOutcome: AggregatedPicklistInterface[] = []
export const CallerLanguage: AggregatedPicklistInterface[] = []
export const CallerInteractedWithIVR: AggregatedPicklistInterface[] = []
export const PatientTypes: AggregatedPicklistInterface[] = []
export const TopCampaignSource: TopCampaignSourceInterface[] = []
export const InaccurateReferralSource: ReferralSourceInterface[] = []
