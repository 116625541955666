import API, { DATA_API } from 'helpers/api'
import { ResolveFields } from 'interfaces/Call.interface'
import Appointment from 'models/Appointment'

/**
 * @param {ResolveFields} data the resolve field object
 */
export default async (options: {
  appointments: Appointment[]
  data: ResolveFields
}) => {
  try {
    const { data, appointments } = options

    await API.post({
      api: DATA_API,
      url: `calls/${data.callId}/resolve`,
      data,
    })

    await Promise.all(
      appointments.map((data: Appointment) => {
        API.post({
          api: DATA_API,
          url: '/appointments',
          data: {
            call: data.call,
            campaign: data.campaign,
            contact: data.contactId,
            dateTime: data.dateTime,
            firstName: data.firstName,
            lastName: data.lastName,
            scoreId: data.scoreId,
          },
        })
      })
    )
  } catch (e) {
    throw new Error('Failed to resolve missed call.')
  }
}
