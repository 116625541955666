import { defaultFont } from './index'

// Theming for buttons
export default theme => ({
  MuiButtonBase: {
    root: {
      ...defaultFont,
      '&:focus': {
        outline: 0
      },
      '&:hover': {
        color: null
      }
    }
  },

  MuiButton: {
    root: {
      outline: 0,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.06)'
      }
    },

    label: {
      ...defaultFont,
      letterSpacing: 0
    },

    containedPrimary: {
    },

    textPrimary: {
      color: theme.palette.primary.main,
    }
  },

})
