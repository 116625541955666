import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment-timezone'
import Grid from '@material-ui/core/Grid'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { ChartCard } from 'components/CallPro/ChartCard'

import { dateFormat } from '../../../config/callpro.config'
import { useStores } from 'config/store'

import EmptyBarChart from '../EmptyCharts/EmptyBarChart'
import { useStyles } from './CallsPerHour.styles'

interface CallsPerHourChartInterface {
  isAccordionContent?: boolean
}

const CallsPerHourChart = (props: CallsPerHourChartInterface) => {
  const { isAccordionContent } = props
  const classes = useStyles()
  const { callsStore, accountStore } = useStores()
  const { callsPerHour, callsPerHourStart, loadingStates } = callsStore
  const { timezone } = accountStore

  // RENDER CALLS PER HOUR CHART
  const renderCallsPerHourChart = (): any => {
    return (
      <BarChart data={callsPerHour}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar
          isAnimationActive={false}
          dataKey="missedCallCount"
          stackId="a"
          fill="#E13426"
          name="Missed Calls"
        />
        <Bar
          isAnimationActive={false}
          dataKey="callCount"
          stackId="a"
          fill="#4498E7"
          name="Opportunity Calls"
        />
      </BarChart>
    )
  }

  return (
    <ChartCard
      headerTitle="OPPORTUNITY CALLS BY HOUR"
      tooltipText="This shows all opportunity calls grouped by hour based on customer's local time zone."
      headerTotalText=""
      headerTotalValue={0}
      isAccordionContent={isAccordionContent}
    >
      {loadingStates.callsPerHour ? (
        <div
          style={{
            textAlign: 'center',
            padding: '2rem',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ResponsiveContainer width="100%" height={250}>
              {callsPerHour.length > 0 ? (
                renderCallsPerHourChart()
              ) : (
                <EmptyBarChart />
              )}
            </ResponsiveContainer>
            {callsPerHourStart && (
              <div>
                <p className={classes.timezone}>({timezone})</p>
                <div className={classes.legend}>
                  <p>Missed</p>
                  <p>Opportunities</p>
                </div>
                <div className={classes.disclaimer}>
                  <InfoOutlinedIcon className={classes.disclaimerIcon} />
                  The data used in this graph is derived from the calls we
                  scored since {moment(callsPerHourStart).format(dateFormat)}.
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </ChartCard>
  )
}

export default observer(CallsPerHourChart)
