import { defaultFont } from './index'

// Theming for utility components
export default (theme) => ({
  MuiTooltip: {
    tooltip: {
      ...defaultFont,
      fontSize: '14px',
      padding: '6px 10px',
      backgroundColor: '#F22151',
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
      position: 'relative',
      '&:after': {
        top: '100%',
        left: '50%',
        border: 'solid transparent',
        content: '" "',
        height: '0',
        width: '0',
        position: 'absolute',
        pointerEvents: 'none',
        borderColor: '#F2215100',
        borderTopColor: '#F22151',
        borderWidth: '8px',
        marginLeft: '-8px',
      },
    },
  },
})
