import React, { PropsWithChildren } from 'react'

// Mui
import { makeStyles } from '@material-ui/styles'

interface Prop extends PropsWithChildren<{}> {
  classes?: { root?: string }
  children: React.ReactNode
}

const HeaderBottom = ({ children }: Prop) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

export default HeaderBottom

const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 15px 0px',
  },
}))
