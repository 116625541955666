import _isEmpty from 'lodash/isEmpty'

import AppState from 'src/config/store/AppState'
import API, { DATA_API } from 'src/helpers/api'

import accountStore from 'src/config/store/AccountStore'

export default async ({ filters }) => {
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    return {
      meta: {},
      records: [],
    }
  }

  const data = {
    accountIds,
    filters,
  }

  const result = await API.get({
    api: DATA_API,
    url: `/get-calls-per-day`,
    params: data,
  })

  const { startDate, callsPerHour } = result

  return {
    records: callsPerHour,
    startRecordDate: startDate,
  }
}
