import React from 'react'

import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles'

import _merge from 'lodash/merge'

import defaultTheme from './themes/default'

let theme = createTheme({
  palette: defaultTheme.palette(),
})

theme.overrides = _merge(theme.overrides, defaultTheme.overrides(theme))
theme.props = _merge(theme.props, defaultTheme.props(theme))

export default (props) => {
  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
}
