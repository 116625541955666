import { observable } from 'mobx'
import Repository from '../Repository'

export const repository = new Repository<Product>()

export default class Product {
  @observable id: string = ''
  @observable displayName: string = ''

  constructor(data: any) {
    this.id = data.id
    this.displayName = data.displayName
  }
}
