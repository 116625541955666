import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  progressDetails: {
    display: 'flex',
    marginBottom: 4,
    justifyContent: 'space-between',
    '& label:last-child span': {
      color: 'rgb(191, 191, 191)',
      marginLeft: 12,
      display: 'inline-block',
      textAlign: 'right',
    },
    '@media screen and (max-width: 780px)': {
      fontSize: 12,
    },
  },
  determinate: {
    backgroundColor: 'rgb(212, 212, 212)',
  },
  bar: {
    backgroundColor: 'rgb(59, 144, 223)',
  },
  root: {
    height: 8,
  },
  barBox: {
    marginBottom: 24,
  },
})
