import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    disclaimer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '.8rem',
      backgroundColor: '#fbfbfb',
      padding: '.5rem',
      marginTop: 'auto',
      '@media screen and (max-width: 780px)': {
        fontSize: 10,
      },
    },
    disclaimerIcon: {
      marginRight: '.5rem',
      fontSize: '1rem',
    },
    link: {
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  })
)
