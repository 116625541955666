import React, { ReactNode } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import cx from 'classnames'

import { useStores } from 'config/store'
import { useStyles } from './Tabs.style'
import { CallOutcome, TabContents } from 'interfaces/Call.interface'
import { getEnumIndex } from 'helpers/utility'
import { Observer } from 'mobx-react'
import { useHistory, useLocation } from 'react-router'
import { emailRoutes } from 'routes'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}
interface CustomTabProps {
  tabContents: TabContents[]
  disabled?: boolean
  children?: ReactNode
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ marginTop: '1rem' }}>
          <Typography component="div">{children}</Typography>
        </div>
      )}
    </div>
  )
}

function getTabProps(index: any): any {
  return {
    id: `simple-tab-${index}`,
  }
}

export default function CustomTab(props: CustomTabProps) {
  const classes = useStyles()
  const location = useLocation<{ reset?: boolean }>()
  const history = useHistory()
  const { callsStore, appointmentStore } = useStores()
  const { tabContents, disabled = false } = props
  const titles = tabContents.map((tab) => tab.title)
  const panels = tabContents.map((tab) => tab.panel)
  let defaultOutcome: CallOutcome | undefined

  switch(location.pathname) {
    case emailRoutes.missedCalls:
      defaultOutcome = CallOutcome.MISSED_CALL
      break;
    case emailRoutes.appointmentCalls:
      defaultOutcome = CallOutcome.APPOINTMENT
      break;
      case emailRoutes.opportunityCalls:
        defaultOutcome = CallOutcome.OPPORTUNITY
      break;
    default:
      defaultOutcome = undefined
  }
  
  const handleChange = (_: React.ChangeEvent<{}>, index: number) => {
    const outcome = Object.values(CallOutcome)[index]

    if (outcome === CallOutcome.APPOINMENT_MADE) {
      appointmentStore.resetAppointmentsTab()
      appointmentStore.setFilters({ outcome })
      appointmentStore.refreshAppointments()
    } else {
      appointmentStore.resetAppointmentsTab()
      callsStore.resetAllCallsSubTab()
      callsStore.setFilters({ outcome })
      callsStore.refreshCalls()
    }

    history.push('/scoreboard')
  }

  return (
    <Observer>
      {() => {
        const outcome = defaultOutcome ?? appointmentStore.filters.outcome ?? callsStore.filters.outcome
        const tabIndex = getEnumIndex(CallOutcome, outcome)
        return (
          <div className={classes.root}>
            <Tabs
              value={tabIndex}
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChange}
              classes={{
                root: classes.tabs,
                indicator: classes.indicator,
              }}
              aria-label="simple tabs example"
            >
              {titles.map((tab, key) => (
                <Tab
                  key={key}
                  className={cx(
                    classes.label,
                    tabIndex === key ? classes.active : null
                  )}
                  label={tab}
                  {...getTabProps(key)}
                  disabled={disabled}
                />
              ))}
            </Tabs>
            {panels.map((panel, key) => (
              <TabPanel key={key} value={tabIndex} index={key}>
                {panel}
              </TabPanel>
            ))}
          </div>
        )
      }}
    </Observer>
  )
}
