import PatientList from 'src/models/PatientList'
import Household, { repository as householdRepo } from 'src/models/Household'

const processPatientList = result => {
  if (result && result.households) {
    const households = (result.households || [])
    const auditMatches = result.audit ? result.audit.matches : []
    result.households = households.map(row => {
      const household = new Household()
      const consolidatedMatch = auditMatches.filter(match => match.addressHash === row.id)
      household.id = row.id
      household.account = row.account
      household.source = row.source
      household.count = row.metrics.count
      household.production = row.metrics.production
      household.lat = row.geocoded.latitude
      household.lng = row.geocoded.longitude
      household.address = row.geocoded.formattedAddress
      consolidatedMatch && (household.consolidatedMatch = consolidatedMatch[0])
      household.metadata = row.metadata
      return householdRepo.upsert(household)
    })
  }

  const patientList = new PatientList()
  Object.assign(patientList, result)
  delete result.households
  return patientList
}

export default processPatientList
