import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {
    margin: '0 20px 20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '97%',
      top: 'calc(50% - 13px)',
      borderBottom: '2px solid #D3D3D3',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    '&:hover label': {
      display: 'block',
    },
  },
  tooltip: { opacity: 1 },
  active: {
    '& $iconWrapper': {
      backgroundColor: '#ED154E',
      boxShadow: 'none',
    },

    '& $icon': {
      color: '#fff',
    },

    '& $label': {
      color: '#F22151',
    },
  },

  done: {
    '& $iconWrapper': {
      backgroundColor: '#ED154E',
      boxShadow: 'none',
    },

    '& $icon': {
      color: '#fff',
    },

    '& $label': {
      color: '#3f575e',
    },
  },

  iconWrapper: {
    width: 33,
    height: 33,
    backgroundColor: '#fff',
    borderRadius: '100%',
    boxShadow: 'inset 0 0 0 2px #D3D3D3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    cursor: 'pointer',
  },

  icon: {
    width: 14,
    height: 14,
    color: '#D3D3D3',
  },

  label: {
    marginTop: 4,
    fontSize: '10px',
    textTransform: 'capitalize',
    color: '#D3D3D3',
  },
  tooltipLeft: {
    left: -18,
    '&:before': {
      left: 35,
    },
  },
  tooltipRight: {
    right: -18,
    '&:before': {
      right: 35,
      transform: 'rotate(180deg)',
    },
  },
  tooltipCenter: {
    right: 'auto !important',
    left: 'auto !important',
    '&:before': {
      left: '50%',
    },
  },
})
