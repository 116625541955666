import API, { DATA_API } from 'src/helpers/api'
import accountStore from 'src/config/store/AccountStore'
import _isEmpty from 'lodash/isEmpty'

export default async ({ filters }) => {
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    console.log('pricingRelatedAnswers empty', filters)
    return {
      meta: {},
      records: [],
    }
  }

  const data = {
    accountIds,
    filters,
  }

  const result = await API.get({
    api: DATA_API,
    url: `/get-pricing-related-answer`,
    params: data,
  })

  const { records, startDate } = result

  return {
    records: records,
    startRecordDate: startDate,
  }
}
