import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { Map } from 'immutable'
import _upperFirst from 'lodash/upperFirst'
import moment from 'moment'
import numeral from 'numeral'
import Img from 'react-image'

import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton/IconButton'
import ColorPickerIcon from '@material-ui/icons/Stop'
import withStyles from '@material-ui/core/styles/withStyles'

import BodyEnd from '../MVP/Helpers/BodyEnd'
import ColorPicker from './ColorPicker'
import mapStyleDefault, { hover as mapStyleHover } from './Maps.styles'
import MapsStore from 'src/config/store/MapsStore'
import { repository as Mailings } from 'src/models/Mailing'
import mailingPlaceholder from '../../assets/img/mailing-placeholder.svg'

const COLOR_OPTIONS = [
  '#F50D0D',
  '#F77935',
  '#FFA200',
  '#FFD600',
  '#0FBB58',
  '#1AC4AD',
  '#009AEE',
  '#007CBA',
  '#5117EE',
  '#B605D5',
  '#FC4491',
  '#666666'
]

const styles = theme => ({

  subAccordionBodyItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
    position: 'relative',
    color: theme.palette.primary.main,
    cursor: 'default',
    transition: 'background-color 0.25s ease',
    lineHeight: '1.1rem',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% + 66px)',
      height: 1,
      background: 'rgb(0, 0, 0, 0.08  )',
      bottom: 0,
      left: -25
    },
    '&:hover': {
      transition: 'background-color 0.25s ease',
      backgroundColor: '#fff',
    },
    '&:last-child': {
      marginBottom: -9,
      '&:before': {
        display: 'none'
      }
    },
    '& $checkbox': {
      marginLeft: 40,
      marginRight: 0
    }
  },
  subAccordionBodySummary: {
    flex: '1 1 auto',
    minWidth: 0,
    width: 166,
    textAlign: 'left',
    color: '#232323',
    '& p': {
      fontSize: '14px',
      margin: 0,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '& span': {
      color: 'rgb(136,136,136)',
      fontSize: '12px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block',
      textOverflow: 'ellipsis',
    }
  },

  areaColor: {
  },

  stackedImage: {
    position: 'relative',
    top: 5,
    left: 4,
    marginRight: 16,
    '& *': {
      position: 'absolute',
      backgroundSize: 'contain',
      width: 65,
      height: 36,
      backgroundColor: 'rgb(192, 192, 192)',
      outline: '1px solid rgb(192, 192, 192)',
      objectFit: 'cover'
    },
    '& *:last-child': {
      position: 'relative',
    },
    '& *:nth-child(1)': {
      top: '-4px',
      left: '-4px',
    },
    '& *:nth-child(2)': {
      top: '-2px',
      left: '-2px',
    }
  },

  checkbox: {
    width: 48,
    height: 48
  },

  areaColorWrapper: {
    position: 'relative',
    width: 56
  }
})

/**
 *
 * @param {Mailing} mailing
 */
const mailingDate = mailing => {
  if (!mailing.deliveryWeek) {
    return _upperFirst(mailing.status)
  }
  return moment(mailing.deliveryWeek).format('MMM D')
}

/**
 * @param {MailingZone} zone
 * @param {Mailing[]} mailings
 */
const multipleMailings = (zone, mailings) => {
  return (
    <Fragment>
      <p>
        <strong>{zone.name}</strong> - {mailings.length} Mailing{mailings.length > 1 ? 's' : ''}
      </p>
      <span>
        {numeral(zone.quantity).format('0a')} pcs
        {' '}&bull;{' '}
        {
          mailings.map(mailing => {
            return mailingDate(mailing)
          }).join(', ')
        }
      </span>
    </Fragment>
  )
}

/**
 * @param {MailingZone} zone
 * @param {Mailing} mailings
 */
const singleMailing = (zone, mailing) => {
  return multipleMailings(zone, [mailing])
}

/**
 * @param {MailingZone} zone
 */
const noMailings = (zone) => {
  return (
    <Fragment>
      <p>
        <strong>{zone.name}</strong> - No Mailings
      </p>
      <span>
        {zone.quantity} pcs
      </span>
    </Fragment>
  )
}

@withStyles(styles)
@observer
export default class MailingZoneCard extends React.Component {

  state = {
    showColorPicker: new Map(),
    colorPicker: {},
    positionX: null,
    positionY: null
  }

  constructor(props) {
    super(props)

    this.pickerContainerRef = React.createRef()
  }

  /**
   *
   */
  handleColorPicker = zoneId => event => {
    event.stopPropagation()
    event.preventDefault()
    const showColorPicker = this.state.showColorPicker.set(zoneId, true)
    let positionX = event.pageX
    let positionY = event.pageY
    if (this.pickerContainerRef.current) {
      const rect = this.pickerContainerRef.current.getBoundingClientRect()
      positionX = rect.left
      positionY = rect.top + rect.height / 2
    }
    this.setState({
      showColorPicker,
      positionX,
      positionY
    })
  }

  /**
   *
   */
  handleCloseColorPicker = zoneId => () => {
    const showColorPicker = this.state.showColorPicker.set(zoneId, false)
    this.setState({ showColorPicker })
  }

  /**
   *
   */
  handleZoneClick = (zone) => () => {
    // Handle click here
  }

  /**
   *
   */
  handleMouseOver = (zone) => () => {
    MapsStore.applyStyles(zone.geodataId, mapStyleHover)
  }

  /**
   *
   */
  handleMouseOut = (zone) => () => {
    MapsStore.applyStyles(zone.geodataId, mapStyleDefault)
  }


  /**
   *
   */
  render() {
    const {
      classes,
      zone,
      onToggleZone
    } = this.props
    const {
      showColorPicker,
      positionX,
      positionY
    } = this.state

    const selectedGeodataIds = MapsStore.selectedGeodataIds.slice()
    const checked = selectedGeodataIds.includes(zone.geodataId)
    const mailings = zone.mailings.map(id => Mailings.get(id))
    return (
      <div
        className={classes.subAccordionBodyItem}
        onClick={this.handleZoneClick(zone)}
        onMouseOver={this.handleMouseOver(zone)}
        onMouseOut={this.handleMouseOut(zone)}
      >
        <Checkbox className={classes.checkbox}
          color='primary'
          checked={checked}
          onClick={onToggleZone}
          value={zone.id || null}
        />
        {
          mailings.length ?
            <div className={classes.stackedImage}>
              {
                mailings.map(mailing => {
                  return (
                    <Img
                      key={mailing.id}
                      src={[mailing.frontDesignThumbnailUrl, mailingPlaceholder]}
                      className={classes.stackedImageBorder}
                    />
                  )
                })
              }
            </div> :
            null
        }
        <div className={classes.subAccordionBodySummary}>
          {
            mailings.length ?
              mailings.length > 1 ?
                multipleMailings(zone, mailings) :
                singleMailing(zone, mailings[0]) :
              noMailings(zone)
          }
        </div>
        <div className={classes.areaColorWrapper} ref={this.pickerContainerRef}>
          {
            showColorPicker.get(zone.id) ?
              <BodyEnd>
                <ColorPicker
                  close={this.handleCloseColorPicker(zone.id)}
                  item={zone}
                  colorOptions={COLOR_OPTIONS}
                  position={{ x: positionX, y: positionY }}
                />
              </BodyEnd> :
              false
          }
          <IconButton
            onClick={this.handleColorPicker(zone.id)}
            className={classes.areaColor}
          >
            <ColorPickerIcon style={{ color: zone.color }} />
          </IconButton>
        </div>
      </div>
    )
  }
}

