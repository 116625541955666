import queryString from 'query-string'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import CircularProgress from '@material-ui/core/CircularProgress'

import appState, {
  SCOREBOARD_ACCESS_HEADER,
  AUTH_GRANTS,
} from 'src/config/store/AppState'
import processAccessToken from 'src/services/grants/processAccessToken'
import scoreboardGrant from 'src/services/grants/scoreboardGrant'

const ScoreboardGrant = (props) => {
  const { history, location } = props

  const { code } = queryString.parse(location.search)

  useEffect(() => {
    if (!code) {
      return history.push('/')
    }

    const processGrantToken = async () => {
      const data = await scoreboardGrant(code)
      const session = await processAccessToken(data.accessToken, {
        scoreboardGrant: data.grantToken,
      })
      return {
        session,
        grants: [
          [
            SCOREBOARD_ACCESS_HEADER,
            {
              value: data.grantToken,
              expires: data.expires,
            },
          ],
        ],
      }
    }

    processGrantToken()
      .then(async ({ session, grants }) => {
        appState.setSession(session, grants)
        history.push('/campaigns')
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <CircularProgress size={60} color="primary" />
      <span
        style={{
          marginTop: 16,
        }}
      >
        Obtaining scoreboard access ...
      </span>
    </div>
  )
}

export default withRouter(ScoreboardGrant)
