import _0fbb58 from '../../../assets/img/toothVariation/Tooth_icon_0fbb58.svg'
import _1ac4ad from '../../../assets/img/toothVariation/Tooth_icon_1ac4ad.svg'
import _007cba from '../../../assets/img/toothVariation/Tooth_icon_007cba.svg'
import _009aee from '../../../assets/img/toothVariation/Tooth_icon_009aee.svg'
import _5117ee from '../../../assets/img/toothVariation/Tooth_icon_5117ee.svg'
import _666666 from '../../../assets/img/toothVariation/Tooth_icon_666666.svg'
import _b605d5 from '../../../assets/img/toothVariation/Tooth_icon_b605d5.svg'
import _f50d0d from '../../../assets/img/toothVariation/Tooth_icon_f50d0d.svg'
import _f77935 from '../../../assets/img/toothVariation/Tooth_icon_f77935.svg'
import _fc4491 from '../../../assets/img/toothVariation/Tooth_icon_fc4491.svg'
import _ffa200 from '../../../assets/img/toothVariation/Tooth_icon_ffa200.svg'
import _ffd600 from '../../../assets/img/toothVariation/Tooth_icon_ffd600.svg'

export {
  _0fbb58,
  _1ac4ad,
  _007cba,
  _009aee,
  _5117ee,
  _666666,
  _b605d5,
  _f50d0d,
  _f77935,
  _fc4491,
  _ffa200,
  _ffd600,
}

export const OfficeColors = [
  '#f50d0d',
  '#0fbb58',
  '#009aee',
  '#f77935',
  '#b605d5',
  '#ffa200',
  '#ffd600',
  '#1ac4ad',
  '#007cba',
  '#5117ee',
  '#fc4491',
  '#666666',
]

const getPracticeIconByHex = (hex: string) => {
  if (hex) {
    switch (hex.toLowerCase()) {
      case '#0fbb58':
        return _0fbb58
      case '#1ac4ad':
        return _1ac4ad
      case '#007cba':
        return _007cba
      case '#009aee':
        return _009aee
      case '#5117ee':
        return _5117ee
      case '#666666':
        return _666666
      case '#b605d5':
        return _b605d5
      case '#f50d0d':
        return _f50d0d
      case '#f77935':
        return _f77935
      case '#fc4491':
        return _fc4491
      case '#ffa200':
        return _ffa200
      case '#ffd600':
        return _ffd600
      case 'loading':
        return _666666
      default:
        break
    }
  }

  return _007cba
}

export default getPracticeIconByHex
