/**
 * ******************************************
 * Designed to be used by views/CallPro/CallPro.tsx
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React from 'react'

// My components
import BaseAppBar from 'components/AppBar/AppBar'
import AppBarTitleSection from 'components/AppBar/AppBarTitleSection'
import AppBarToolbar from 'components/AppBar/AppBarToolbar'
import AppBarTitle from 'components/AppBar/AppBarTitle'
import AppBarTitleSectionFilter from 'components/AppBar/AppBarTitleSectionFilter'
import ToolbarDrawerIconButon from 'components/AppBar/ToolbarDrawerIconButon'
import TabBar from 'views/CallPro/TabBar'

export default function AppBar() {
  return (
    <BaseAppBar>
      <AppBarTitleSection>
        <AppBarToolbar>
          <ToolbarDrawerIconButon />
          <AppBarTitle>Scoreboard</AppBarTitle>
        </AppBarToolbar>
        <AppBarTitleSectionFilter />
      </AppBarTitleSection>
      <TabBar />
    </BaseAppBar>
  )
}
