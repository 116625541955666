import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContent: {
      padding: '0px 34px',
    },
    headerMessage: {
      marginBottom: 18,
      fontSize: 18,
    },
    dialogActionContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '24px 34px',
    },
  })
)

export default useStyles
