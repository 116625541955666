import React from 'react'

import ApprovedStatusIcon from '@material-ui/icons/ThumbUp'
import OrderedStatusIcon from '@material-ui/icons/ShoppingBasket'
import PrintedStatusIcon from '@material-ui/icons/Print'
import ShippedStatusIcon from '@material-ui/icons/LocalShipping'
import DeliveredStatusIcon from '@material-ui/icons/MarkunreadMailbox'
import { MailingStatus } from 'models/Mailing'

interface IMailingStatusIcon {
  status?: MailingStatus
  className?: string
}

const MailingStatusIcon = (props: IMailingStatusIcon) => {
  const { status, className } = props
  switch (status) {
    case MailingStatus.APPROVED:
      return <ApprovedStatusIcon className={className} />
    case MailingStatus.PRINTED:
      return <OrderedStatusIcon className={className} />
    case MailingStatus.ORDERED:
      return <PrintedStatusIcon className={className} />
    case MailingStatus.SHIPPED:
      return <ShippedStatusIcon className={className} />
    case MailingStatus.DELIVERED:
      return <DeliveredStatusIcon className={className} />
    case MailingStatus.DELIVERED_IN_FIRST_CALL:
      return <DeliveredStatusIcon className={className} />
    default:
      return <></>
  }
}

export default MailingStatusIcon
