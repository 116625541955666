/**
 * ******************************************
 * Designed to be used by src/views/CallPro/CallPro.tsx
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React from 'react'

// Mui
import { Tabs, Tab, useMediaQuery, Theme } from '@material-ui/core'

// My util
import useStyles from './TabBar.style'
import { useStores } from 'config/store'
import { CallConfig } from 'config/callpro.config'

// My component
import CampaignFilterTypeSelect from 'containers/CampaignFilterTypeSelect'
import DateFilterTypeSelect from 'containers/DateFilterTypeSelect'

export default function TabBar() {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const { dashboardUIStore } = useStores()
  const tab = dashboardUIStore.currentTabOpened.index

  const titles = ['Overview', 'Front Desk', 'All Calls']

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    const tab = CallConfig.tabs[newValue]

    if (tab === dashboardUIStore.currentTabOpened.value) {
      return
    }

    dashboardUIStore.refreshTab(tab, { reset: true })
  }

  return (
    <div className={classes.root}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        classes={{
          root: classes.tabsRoot,
          flexContainer: classes.tabsFlexContainer,
          scroller: classes.tabsScroller,
        }}
        variant={isDesktop ? 'standard' : 'fullWidth'}
      >
        {titles.map((label, index) => (
          <Tab
            key={index}
            value={index}
            label={label}
            className={classes.tabItem}
          />
        ))}
      </Tabs>
      <div className={classes.filters}>
        <DateFilterTypeSelect />
        <CampaignFilterTypeSelect />
      </div>
    </div>
  )
}
