import React, { useState } from 'react'
import { Observer } from 'mobx-react'
import {
  Typography,
  createStyles,
} from '@material-ui/core'
import OutsideClickListener from 'react-outside-click-handler'
import _get from 'lodash/get'

import { default as styled } from 'styled-components'
import { repository as accountRepo } from '../../models/Account'
import mapsStore from 'src/config/store/MapsStore'
import PracticeColorPicker from './PracticeColorPicker'

const styles = createStyles({
  body: { height: '100%', width: '100%' },
  content: {
    backgroundColor: '#f7f7f7',
    padding: 10,
    height: '100%',
    width: '100%'
  },
  addressText: { color: '#adadad', wordBreak: 'break-all', fontSize: 13 },
  loader: {
    color: '#757575',
    position: 'absolute',
    bottom: 16,
    left: 42
  },
  footer: {
    zIndex: 9999,
    position: 'relative'
  }
})

export const PracticeInfoBox = (props) => {
  const { id, onClose } = props
  const account = accountRepo.get(id)

  if (!account) {
    return null
  }

  const practiceName = _get(account, 'name', 'Unknown Practice')
  const address = _get(account, 'address', 'Unknown Address')

  const [visibleOfficeColor, setVisibleOfficeColor] = useState(false)

  return (
    <Observer
      render={() => {
        return <OutsideClickListener
        onOutsideClick={() => {
          if (!visibleOfficeColor) {
            onClose && onClose()
          }
        }}
      >
        <React.Fragment>
          <Container id="officeInfoBox">
            <Header>{practiceName}</Header>
            <div style={styles.body}>
              <div style={styles.content}>
                <Typography style={styles.addressText}>
                  Office Address:
                </Typography>
                <Typography
                  style={styles.addressText}
                >{address}</Typography>
              </div>
            </div>
            <div id="officeColorPicker" style={styles.footer}>
              <div
                style={{
                  backgroundColor: account.color || '#007cba',
                  height: 15,
                  width: 15,
                  marginTop: 8,
                  cursor: 'pointer'
                }}
              />
            </div>
            { mapsStore.isPickingOfficeColor && <span style={styles.loader} >Updating color...</span> }
          </Container>
            <PracticeColorPicker
              id={id}
              visible={mapsStore.visibleOfficeColor}
              onClose={() => {
                setVisibleOfficeColor(false)
              }}
            />
        </React.Fragment>
      </OutsideClickListener>
      }}
    />
  )
}

export default PracticeInfoBox

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  position: relative;
  background: #fff;
  border-radius: 0;
  width: 320px;
  ::before {
    top: calc(100% + 2px);
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: rgba(0, 0, 0, 0.25);
    border-width: 17px;
    margin-left: -17px;
  }
  ::after {
    top: calc(100% - 1px);
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 16px;
    margin-left: -16px;
  }
`

const Header = styled.div`
  max-width: 100%;
  flex: 0 0 auto;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 1em;
  margin-bottom: 0.5rem;
`
