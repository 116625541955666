import React, { Component } from 'react'

import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles'
import { Scrollbars } from 'react-custom-scrollbars'

import page from 'services/analytics/page'
import ReferralBanner from 'views/Ads/ReferralBanner'

const styles = createStyles({
  content: {
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 25,
    paddingRight: 25,
  },
})

interface PageProps extends WithStyles<typeof styles> {
  track: { name: string; category: any; properties: any }
  title: string
  style?: React.CSSProperties
}

type Props = PageProps

class Page extends Component<Props> {
  componentDidMount() {
    const { track } = this.props
    
    if (track) {
      page(track.name, track.category, track.properties)
    }
  } 

  render() {
    const { children, classes, title, style } = this.props
    const withBannerPages = ['Mailings', 'Calls']

    const showBanner = localStorage.getItem('showBanner') ?? 'true'
    
    return (
      <div style={{ flex: '1 1 auto' }}>
        <Scrollbars>
          {withBannerPages.includes(title) && showBanner === 'true' ? <ReferralBanner /> : '' }
          <div
            className={title === 'Maps' ? '' : classes.content}
            style={style}
          >
            {children}
          </div>
        </Scrollbars>
      </div>
    )
  }
}

export default withStyles(styles)(Page)
