import _get from 'lodash/get'

export interface DesignImageUrls {
  frontFull: string
  frontLastModified?: string
  backFull: string
  backLastModified?: string
  frontThumbnail: string
  frontThumbnailLastModified?: string
  backThumbnail: string
  backThumbnailLastModified?: string
  previousFrontFull: string
  previousBackFull: string
  previousFrontLastModified?: string
  previousBackLastModified?: string
  previousFrontThumbnailLastModified?: string
  previousBackThumbnailLastModified?: string
}

export const getDesignImageUrls = (design: any): DesignImageUrls => {
  return {
    frontFull: _get(design, 'currentVersion.frontDesignUrl'),
    backFull: _get(design, 'currentVersion.backDesignUrl'),
    frontThumbnail: _get(design, 'currentVersion.frontDesignThumbnailUrl'),
    backThumbnail: _get(design, 'currentVersion.backDesignThumbnailUrl'),
    frontLastModified: _get(design, 'currentVersion.frontDesignLastModified'),
    backLastModified: _get(design, 'currentVersion.backDesignLastModified'),
    frontThumbnailLastModified: _get(
      design,
      'currentVersion.frontDesignThumbnailLastModified'
    ),
    backThumbnailLastModified: _get(
      design,
      'currentVersion.backDesignThumbnailLastModified'
    ),
    previousFrontFull: _get(
      design,
      'currentVersion.previousVersion.frontDesignUrl'
    ),
    previousBackFull: _get(
      design,
      'currentVersion.previousVersion.backDesignUrl'
    ),
    previousFrontLastModified: _get(
      design,
      'currentVersion.previousVersion.frontDesignLastModified'
    ),
    previousBackLastModified: _get(
      design,
      'currentVersion.previousVersion.backDesignLastModified'
    ),
    previousFrontThumbnailLastModified: _get(
      design,
      'currentVersion.previousVersion.frontDesignThumbnailLastModified'
    ),
    previousBackThumbnailLastModified: _get(
      design,
      'currentVersion.previousVersion.backDesignThumbnailLastModified'
    ),
  }
}
