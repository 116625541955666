import React from 'react'
import { observer } from 'mobx-react'
import Skeleton from '@material-ui/lab/Skeleton'

import { KPICardContainer, KPIGradeEvaluator, KPIValues } from '../../KPICard'
import { LinearProgress } from 'components/CallPro/LinearProgress'

import { useStyles } from './StaffPlacedCallerOnHold.styles'
import { formatNumber } from 'helpers/utility'
import { ProTip } from '../../ProTip'

import ProTipImage from '../../../../../assets/img/call-pro/pro-tips/pro-tip-caller-on-hold.png'
import GradeToolTipValue from '../../GradeToolTipValue'
import SeeIndividualMetric from './SeeIndividualMetric/SeeIndividualMetric'
import { useReactQuery } from './SeeIndividualMetric/_hooks'
import EmptyBarChart from 'components/CallPro/EmptyCharts/EmptyBarChart'

interface StaffPlacedCallerOnHoldInterface {
  isAccordionContent?: boolean
}

const StaffPlacedCallerOnHold = (props: StaffPlacedCallerOnHoldInterface) => {
  const classes = useStyles()
  const { isAccordionContent } = props
  const { isFetching, data } = useReactQuery()
  const summary = data!['summary']

  if (isFetching) {
    return (
      <Skeleton
        variant="rect"
        width={'100%'}
        height={'100%'}
        style={{ borderRadius: 10, minHeight: '29rem' }}
      />
    )
  }

  const total = summary.total ?? 0
  const onHold = summary.onHold ?? 0
  const gradeName = summary.onHoldGrade ?? 'A'
  const notOnHold = total - onHold
  const onHoldPercentage = summary.onHoldPercentage ?? 0
  const notOnHoldPercentage = 100 - onHoldPercentage

  if (!total) {
    return <EmptyBarChart />
  }

  return (
    <KPICardContainer
      isAccordionContent={isAccordionContent}
      title="STAFF PLACED CALLER ON HOLD"
      tooltip="These are the calls that were placed on hold at some point after being answered by the staff."
    >
      <KPIGradeEvaluator
        isAccordionContent={isAccordionContent}
        value={onHoldPercentage}
        total={formatNumber(total)}
        totalText="Calls"
        gradeName={gradeName}
      />
      <div className={classes.progressWrapper}>
        <LinearProgress
          hideProgressDetails={true}
          value={onHold}
          total={total}
        />
      </div>
      <KPIValues
        isAccordionContent={isAccordionContent}
        data={[
          {
            percentage: onHoldPercentage,
            label: 'On hold',
            color: '#4498E7',
            details: onHold,
          },
          {
            percentage: notOnHoldPercentage,
            label: 'Not on hold',
            color: '#d4d4d4',
            details: notOnHold,
          },
        ]}
      />
      <SeeIndividualMetric />
      <ProTip
        title="Don't make them wait. Why is keeping the hold time to less than one minute a game-changer"
        content={GradeToolTipValue('Staff Placed Caller On Hold', gradeName)}
        image={ProTipImage}
      />
    </KPICardContainer>
  )
}

export default observer(StaffPlacedCallerOnHold)
