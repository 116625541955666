// INTERFACE
interface Grade {
  grade: string
  min: number
  max: number
}

// MAIN FUNCTION
const GradeEvaluator = (
  data: { percentage?: number; time?: number },
  gradeSystem: Grade[]
): string | undefined => {
  const { percentage, time } = data
  let roundedPercentage: number, evaluatedGrade: Grade | undefined

  // 1. Round off the provided percentage
  if (percentage) {
    roundedPercentage = Math.ceil(percentage)

    // 2. Find grading match for the percentage
    evaluatedGrade = gradeSystem.find(
      ({ min, max }) =>
        roundedPercentage >= min && (roundedPercentage <= max || max === 0)
    )
  }

  if (time) {
    evaluatedGrade = gradeSystem.find(
      ({ min, max }) => time >= min && (time <= max || max === 0)
    )
  }

  // 3. Return matched grade
  return evaluatedGrade && evaluatedGrade.grade
}

export default GradeEvaluator
