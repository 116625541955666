import React, { Component } from 'react'
import Drawer from '@material-ui/core/Drawer'
import appState from 'config/store/AppState'

import { MailingDetails } from './MailingDetails'
import { observer } from 'mobx-react'
import { useStyles } from './MailingDetailsDrawer.styles'
import { Theme, useMediaQuery } from '@material-ui/core'
import classNames from 'classnames'

interface IMailingDetailsDrawer {
  open: boolean
  onClose: () => void
}

export const MailingDetailsDrawer = (props: IMailingDetailsDrawer) => {
  const { open, onClose } = props
  const classes = useStyles()
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  return (
    <div className={classes.container}>
      <Drawer
        classes={{
          paper: classNames(
            classes.paper,
            mobileDevice ? classes.fullWidth : null
          ),
          root: appState.isRestricted() && classes.toBack,
        }}
        anchor="right"
        open={open}
        onClose={onClose}
      >
        <div tabIndex={0} role="button">
          <MailingDetails onClose={onClose} />
        </div>
      </Drawer>
    </div>
  )
}

export default observer(MailingDetailsDrawer)
