import EventEmitter from 'eventemitter3'
import localforage from 'localforage'
import _get from 'lodash/get'
import {
  observable,
  action,
  computed,
  createAtom,
  when,
  flow,
} from 'mobx'
import { matchPath } from 'react-router'

import processAuthentication from 'src/services/users/processAuthentication'
import authenticateUser from 'src/services/users/authenticateUser'
import resetPassword from 'src/services/users/resetPassword'
import generateActivationLink from 'src/services/users/generateActivationLink'

export const SCOREBOARD_ACCESS_HEADER = 'X-Mvp-Scoreboard-Token'
const USER_HIDDEN_ACCOUNTS = 'Apps/USER_HIDDEN_ACCOUNTS'
const AUTH_SESSION = 'App/AUTH_SESSION'
const AUTH_GRANTS = 'App/AUTH_GRANTS'

export class AppState {
  @observable accessToken = null
  @observable tokenType = null
  @observable expiresAt = null

  // Account info
  @observable contactId = null
  @observable profileEmail = null
  @observable profileName = null
  @observable profilePicture = null
  @observable accounts = []
  @observable roles = []

  // Application global states
  @observable criticalError = null
  @observable loading = null

  // Flash messages
  @observable isFlashing = false
  @observable flash = null
  @observable flashQueue = []

  // Navigation State
  @observable isDrawerOpen = false

  @observable expiredSession = false

  grants = new observable.map({})

  // Route options
  redirectLocation = null
  restrictedRoutes = ['/calls', '/calls/:callId', '/settings', '/campaigns/:id']

  tokenRenewalTimeout = null
  clock = null
  authNotifier

  constructor() {
    this.authNotifier = new EventEmitter()
    this.clock = new Clock()
    this.setLoading()
    this.checkSavedSession()
      .then((result) => {
        if (result) {
          let { grants, ...session } = result
          Promise.all([this.setSession(session, grants)]).then(() => {
            this.setLoading(false)
          })
        }
        this.setLoading(false)
      })
      .catch((error) => {
        this.setLoading(false)
        // Catch this error for environments without local storage
        console.log(error)
      })
  }

  onAuthChange(callback) {
    this.authNotifier.on('auth.change', callback)
  }

  async checkSavedSession() {
    const session = await localforage.getItem(AUTH_SESSION)
    const grants = await localforage.getItem(AUTH_GRANTS)
    if (session === null) {
      return false
    }
    const { accessToken, expiresAt, tokenType, picture, userInfo } = session
    return {
      accessToken,
      expiresAt,
      tokenType,
      picture,
      userInfo,
      grants,
    }
  }

  @action.bound
  async login(login, passwordless) {
    const session = await authenticateUser(login, passwordless)
    if (session) {
      await this.setSession(session)
    }
    return session
  }

  @action
  async processAuthentication(hash) {
    const session = await processAuthentication(hash)
    if (session) {
      await this.setSession(session)
    }
    return session
  }

  @action.bound
  async resetPassword(email) {
    return resetPassword(email)
  }

  @action.bound
  async generateActivationLink(email, code) {
    return generateActivationLink(email, code)
  }

  @action.bound
  setNavDrawer(state) {
    this.isDrawerOpen = state
  }

  @action.bound
  async setSession(session, grants = []) {
    if (!session) {
      return this.clearSession()
    }

    const { accessToken, expiresAt, tokenType, picture, userInfo } = session
    this.accessToken = accessToken
    this.expiresAt = expiresAt
    this.tokenType = tokenType

    const { id, email, name, accounts, roles } = Object.assign(
      {
        id: null,
        email: null,
        name: null,
        accounts: [],
        roles: [],
      },
      userInfo
    )

    accounts.sort((a, b) => {
      a = a.name.toUpperCase()
      b = b.name.toUpperCase()
      return a > b ? 1 : a < b ? -1 : 0
    })

    this.accounts.replace(accounts)
    this.contactId = id
    this.profileName = name
    this.profilePicture = picture
    this.profileEmail = email
    this.roles = roles
    if (grants) {
      this.grants.replace(grants)
    }

    if (this.isAuthenticated) {
      this.setRenewalTimeout(this.expiresAt)
      this.authNotifier.emit('auth.change', true)
      await localforage.setItem(AUTH_SESSION, session)
      if (grants) {
        await localforage.setItem(AUTH_GRANTS, grants)
      }
    } else {
      await this.clearSession()
    }
  }

  /**
   * @this AppState
   */
  @action.bound
  clearSession = flow(function* () {
    yield localforage.removeItem(AUTH_SESSION)
    yield localforage.removeItem(USER_HIDDEN_ACCOUNTS)
    yield localforage.removeItem(AUTH_GRANTS)

    this.accessToken = null
    this.expiresAt = null
    this.tokenType = null
    this.tokenRenewalTimeout && clearTimeout(this.tokenRenewalTimeout)
    this.authNotifier.emit('auth.change', false)
    this.grants.clear()
  })

  @computed get isAuthenticated() {
    const result =
      Boolean(this.accessToken) && this.clock.time() < this.expiresAt
    return result
  }

  @computed get grantHeaders() {
    const headers = {}
    for (const [key, value] of this.grants.entries()) {
      const expiration = (_get(value, 'expires') || 0) * 1000
      if (expiration > this.clock.time()) {
        headers[key] = _get(value, 'value')
      }
    }
    return headers
  }

  @action.bound isLimitedAccess() {
    return this.roles.includes('limited-access-user')
  }

  @action.bound isRestricted() {
    const currentPath = window.location.pathname

    if (!this.isLimitedAccess()) {
      return false
    }

    return !!this.restrictedRoutes.filter(
      (route) => !!matchPath(currentPath, route)
    ).length
  }

  @action
  setCriticalError(message) {
    this.criticalError = message
  }

  @action
  setLoading(loadingText = 'Loading ...') {
    this.loading = loadingText
  }

  setRenewalTimeout(expiresIn) {
    this.tokenRenewalTimeout && clearTimeout(this.tokenRenewalTimeout)
    if (Date.now() <= expiresIn * 1000) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.clearSession()
        this.expiredSession = true
      }, expiresIn - Date.now())
    }
  }

  @action.bound
  setExpiredSession(value) {
    this.expiredSession = value
  }

  @action.bound
  toggleFlash(toggle) {
    this.isFlashing = toggle
  }

  @action.bound
  flashMessage(message = '', actions = [], type = 'default') {
    this.flashQueue.push({
      key: Date.now(),
      type,
      message,
      actions,
    })

    if (this.isFlashing) {
      // immediately begin dismissing current message
      // to start showing new one
      this.isFlashing = false
    } else {
      this.processFlashQueue()
    }
  }

  @action.bound
  errorMessage(message = '', actions = []) {
    this.flashMessage(message, actions, 'error')
  }

  @action.bound
  warningMessage(message = '', actions = []) {
    this.flashMessage(message, actions, 'warning')
  }

  @action.bound
  infoMessage(message = '', actions = []) {
    this.flashMessage(message, actions, 'info')
  }

  @action.bound
  successMessage(message = '', actions = []) {
    this.flashMessage(message, actions, 'success')
  }

  @action.bound
  processFlashQueue() {
    if (this.flashQueue.length > 0) {
      this.flash = this.flashQueue.shift()
      this.isFlashing = true
    } else {
      this.isFlashing = false
    }
  }

  @action
  setRedirect(location) {
    this.redirectLocation = location
  }
}

class Clock {
  atom
  currentDateTime
  intervalHandler = null

  constructor() {
    this.atom = createAtom(
      'AppClock',
      () => this.start(),
      () => this.stop()
    )
  }

  time = () => {
    if (this.atom.reportObserved()) {
      return this.currentDateTime
    } else {
      return Date.now().valueOf()
    }
  }

  tick() {
    this.currentDateTime = Date.now().valueOf()
    this.atom.reportChanged()
  }

  start() {
    this.tick()
    this.intervalHandler = setInterval(() => this.tick(), 1000)
  }

  stop() {
    clearInterval(this.intervalHandler)
    this.intervalHandler = null
  }
}

export const isAuthenticated = async () => {
  return when(() => AppState.isAuthenticated)
}

export default new AppState()
