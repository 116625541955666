import { observable, computed, action } from 'mobx'

import Repository from './Repository'

export const repository = new Repository()

export default class Geodata {

  @observable isLoading = false
  @observable isError = false

  id
  type
  geodata
  centroid = null
  boundingBox = null

}