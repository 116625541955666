import { observable } from 'mobx'
import Repository from '../Repository'

export const repository = new Repository<OfferInquiry>()

export default class OfferInquiry {
  @observable id: string = ''
  @observable name: string = ''
  @observable offerId: string = ''
  @observable offerName: string = ''
  @observable callId: string = ''

  constructor(offerPlacement: any) {
    this.id = offerPlacement.id
    this.name = offerPlacement.name
    this.offerId = offerPlacement.offerId
    this.offerName = offerPlacement.offerName
    this.callId = offerPlacement.callId
  }
}
