import React from 'react'
import { Link } from '@material-ui/core'
import { runInAction } from 'mobx'
import { useStores } from 'config/store'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'
import { CallsStore } from 'config/store/CallPro/Call/CallsStore'
import _ from 'lodash'

// styles
import { useStyles } from './AllInaccurateReferralSourcesSection.style'

type SectionProps = {
  showAllCallSources: boolean
}

const AllInaccurateReferralSourcesSection = (props: SectionProps) => {
  const { showAllCallSources } = props
  const classes = useStyles()

  const { callsStore, dashboardUIStore } = useStores()
  const { referralSource: _referralSource } = callsStore

  const inaccurateReferralSource = _referralSource.records.filter(
    (item) => item.wrongReferralSource
  )

  const groupedData = _.groupBy(_referralSource.records, 'name')
  const allReferralSource = _.map(groupedData, (items, name) => ({
    name,
    value: _.sumBy(items, 'value'),
  }))

  const list = showAllCallSources
    ? allReferralSource
    : inaccurateReferralSource

  const total = list.length
  const isEmpty = total === 0

  const handleItemClick = (itemName: string) => {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS

      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters(
        showAllCallSources
          ? {
              outcome,
              callerSource: [itemName],
            }
          : {
              outcome,
              callerSource: [itemName],
              wrongSourceMentioned: true,
            }
      )
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  return (
    <section className={classes.root}>
      <div className={classes.container}>
        <div className={classes.title}>
          All Inaccurate Referral Sources {isEmpty ? '' : `(${total})`}
        </div>
        <Table list={list} onItemClick={handleItemClick} />
      </div>
    </section>
  )
}

type TableProp = {
  list: Array<{name: string, value: number}>
  onItemClick: (itemName: string) => void
}

const Table = (props: TableProp) => {
  const classes = useStyles()
  const { list, onItemClick } = props

  const handleItemClick = (itemName: string) => () => {
    onItemClick(itemName)
  }

  return (
    <>
      <div className={classes.row}>
        <div className={classes.rowData}>#</div>
        <div className={classes.rowData}>Referral Source</div>
        <div className={classes.rowData}>Count</div>
      </div>
      {list.map((item, index) => {
        return (
          <div className={classes.row} key={index}>
            <div className={classes.rowData}>{index + 1}</div>
            <div className={classes.rowData}>
              <Link
                component="button"
                variant="body1"
                underline="none"
                color="initial"
                onClick={handleItemClick(item.name)}
                classes={{ root: classes.link }}
              >
                {item.name}
              </Link>
            </div>
            <div className={classes.rowData}>{item.value}</div>
          </div>
        )
      })}
    </>
  )
}

export default AllInaccurateReferralSourcesSection
