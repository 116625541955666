import React, { useState } from 'react'
import { observer } from 'mobx-react'
import cx from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './MailingDetailsTab.styles'
import { MailingInfo } from './MailingInfo'
import { MailingDesign } from './MailingDesign'
import { MailingMap } from './MailingMap'
import { MailingCalls } from './MailingCalls'
import Mailing from 'models/Mailing'

interface IMailingDetailsTab {
  mailing: Mailing
}
interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography component="div">{children}</Typography>}
    </div>
  )
}

export const MailingDetailsTab = (props: IMailingDetailsTab) => {
  const { mailing } = props
  const classes = useStyles()
  const [tabIndex, setTabIndex] = useState(0)
  const tabContents = [
    {
      title: 'Details',
      panel: <MailingInfo />,
    },
    {
      title: 'Design',
      panel: <MailingDesign />,
    },
    {
      title: 'Calls',
      panel: <MailingCalls />,
    },
  ]

  if (mailing.campaignType === 'MVP Mail') {
    tabContents.splice(tabContents.length - 1, 0, {
      title: 'Map',
      panel: <MailingMap />,
    })
  }

  const titles = tabContents.map((tab) => tab.title)
  const panels = tabContents.map((tab) => tab.panel)

  return (
    <>
      <Tabs
        value={tabIndex}
        variant="scrollable"
        scrollButtons="auto"
        classes={{
          root: classes.tabs,
          indicator: classes.indicator,
        }}
      >
        {titles.map((tab, key) => (
          <Tab
            key={key}
            className={cx(
              classes.label,
              tabIndex === key ? classes.active : null
            )}
            classes={{
              root: classes.tabRoot,
            }}
            onClick={() => setTabIndex(key)}
            label={tab}
          />
        ))}
      </Tabs>
      <Scrollbars>
        {panels.map((panel, key) => (
          <TabPanel key={key} value={tabIndex} index={key}>
            {panel}
          </TabPanel>
        ))}
      </Scrollbars>
    </>
  )
}

export default observer(MailingDetailsTab)
