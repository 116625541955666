import _get from 'lodash/get'
import { getDesignImageUrls, DesignImageUrls } from '../getDesignImageUrls'

export const getCampaignImageUrls = (campaign: any, design: any): DesignImageUrls => {
  const designImageUrls = getDesignImageUrls(design)
  return {
    frontFull: _get(campaign, 'frontDesignUrl'),
    backFull: _get(campaign, 'backDesignUrl'),
    frontThumbnail: _get(campaign, 'frontDesignThumbnailUrl'),
    backThumbnail: _get(campaign, 'backDesignThumbnailUrl'),
    frontLastModified: _get(campaign, 'mediaSubmitted'),
    backLastModified: _get(campaign, 'mediaSubmitted'),
    frontThumbnailLastModified: _get(
      campaign,
      'mediaSubmitted'
    ),
    backThumbnailLastModified: _get(
      campaign,
      'mediaSubmitted'
    ),
    previousFrontFull: _get(
      designImageUrls,
      'frontFull'
    ),
    previousBackFull: _get(
      designImageUrls,
      'backFull'
    ),
    previousFrontLastModified: _get(
      designImageUrls,
      'frontLastModified'
    ),
    previousBackLastModified: _get(
      designImageUrls,
      'backLastModified'
    ),
    previousFrontThumbnailLastModified: _get(
      designImageUrls,
      'frontThumbnailLastModified'
    ),
    previousBackThumbnailLastModified: _get(
      designImageUrls,
      'backThumbnailLastModified'
    ),
  }
}
