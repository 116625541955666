import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    infoContainer: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      marginBottom: 20,
    },
    info: {
      fontSize: '.9rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
      marginTop: '1rem',
      cursor: 'pointer',
    },
    infoDetail: {
      color: '#ED164E',
      fontWeight: 'bold',
      textAlign: 'center',
      '@media screen and (max-width: 780px)': {
        fontSize: 12,
      },
    },

    // TOOLTIP
    metricTooltip: {
      backgroundColor: '#FFF',
      borderRadius: '5px',
      border: '1px solid #d3d3d3',
      boxShadow: '0 4px 20px rgb(0 0 0 / 12%), 0 6px 12px rgb(0 0 0 / 8%)',
      zIndex: 10,
      transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
    },
    tooltipTitle: {
      borderBottom: '1px solid #F3F3F3',
      padding: '.7rem 1rem',
      fontSize: '.8rem',
      fontWeight: 'bold',
      color: '#007AFF',
    },
    tooltip: {
      backgroundColor: '#222',
      '&::after': {
        display: 'none',
      },
    },
    grade_icon: {
      color: '#d3d3d3',
      verticalAlign: 'middle',
    },
    container: {
      marginTop: 'auto',
    },
    disclaimer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '.8rem',
      backgroundColor: '#fbfbfb',
      padding: '.5rem',
      marginTop: 'auto',
      '@media screen and (max-width: 780px)': {
        fontSize: 10,
      },
    },
    disclaimerIcon: {
      marginRight: '.5rem',
      fontSize: '1rem',
    },
    footer: {
      marginTop: 'auto',
    },
    link: {
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  })
)
