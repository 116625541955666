import React, { useState } from 'react'

// Mui
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  useMediaQuery,
  Theme,
  ListItemSecondaryAction,
} from '@material-ui/core'

import {
  Phone as DashboardIcon,
  PermMedia as MailingsModuleIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Settings as SettingsIcon,
  PowerSettingsNew as Logout,
} from '@material-ui/icons'

// Util
import { Link, useLocation } from 'react-router-dom'
import cx from 'classnames'

// My utils
import useStyles from './NavigationBarLinks.styles'

// My components
import ConfirmDialog from 'components/MVP/Dialog/ConfirmDialog'

interface Prop {
  expanded: boolean
  onToggleClick: () => void
  onLinkClick: () => void
}

const links = ({
  classes,
  location,
  isDesktop,
}: {
  classes: ReturnType<typeof useStyles>
  location: ReturnType<typeof useLocation>
  isDesktop: boolean
}) => [
  {
    route: '/campaigns',
    primary: 'Campaigns',
    icon: <MailingsModuleIcon />,
  },
  {
    route: '/scoreboard',
    primary: 'Scoreboard',
    icon: <DashboardIcon />,
  },
  {
    route: '/maps',
    primary: 'Map IQ',
    mobileDisable: true,
    icon: (
      <>
        <span
          className={cx(
            location.pathname.includes('/maps')
              ? classes.mapIntelActive
              : classes.mapIntel
          )}
        ></span>
      </>
    ),
  },
]

const NavigationBarLinks = (prop: Prop) => {
  const { expanded, onToggleClick, onLinkClick } = prop

  const classes = useStyles()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const location = useLocation()

  const [confirmLogout, setConfirmLogout] = useState(false)
  const [isLoggedOut, setIsLoggedOut] = useState(false)

  return (
    <>
      <List component="nav" className={cx(classes.list, classes.listMain)}>
        {links({ classes, location, isDesktop }).map((link, index) => {
          const disabled = !isDesktop && link.mobileDisable === true
          return (
            <Link
              key={index}
              to={link.route}
              onClick={(e) => {
                disabled ? e.preventDefault() : onLinkClick()
              }}
            >
              <ListItem
                button
                disabled={disabled}
                classes={{
                  root: cx(
                    classes.listItem,
                    location.pathname.includes(link.route)
                      ? classes.listItemActive
                      : null
                  ),
                  button: classes.listItemButton,
                }}
              >
                <ListItemIcon className={classes.listIcon}>
                  {link.icon}
                </ListItemIcon>
                <ListItemText
                  classes={{
                    root: cx(classes.listText, !expanded ? classes.hide : null),
                    primary: classes.listTextPrimary,
                    secondary: classes.listTextSecondary,
                  }}
                  primary={link.primary}
                  secondary={
                    disabled ? (
                      <>
                        <strong>
                          <small>mobile unavailable</small>
                        </strong>
                      </>
                    ) : (
                      ''
                    )
                  }
                />
              </ListItem>
            </Link>
          )
        })}
      </List>
      <div className={cx(classes.listFooter)}>
        <List component="nav" className={classes.list}>
          <Divider className={classes.divider} />
          <Link to="/settings" onClick={onLinkClick}>
            <ListItem
              button
              classes={{
                root: cx(
                  classes.listItem,
                  '/settings' === location.pathname
                    ? classes.listItemActive
                    : null
                ),
                button: classes.listItemButton,
              }}
            >
              <ListItemIcon className={classes.listIcon}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: cx(classes.listText, !expanded ? classes.hide : null),
                  primary: classes.listTextPrimary,
                  secondary: classes.listTextSecondary,
                }}
                primary="Settings"
              />
            </ListItem>
          </Link>
          <Divider className={classes.divider} />
          <Link
            onClick={(e) => {
              if (!confirmLogout) {
                setConfirmLogout(true)
              }
              e.preventDefault()
            }}
            to="/logout"
          >
            <ListItem
              classes={{
                root: classes.listItem,
                button: classes.listItemButton,
              }}
              button
            >
              <ListItemIcon className={classes.listIcon}>
                <Logout className={classes.logoutIcon} />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                classes={{
                  root: cx(classes.listText, !expanded ? classes.hide : null),
                  primary: classes.listTextPrimary,
                  secondary: classes.listTextSecondary,
                }}
              />
            </ListItem>
          </Link>

          <Divider className={classes.divider} />
          <ListItem
            button
            classes={{
              root: classes.listItem,
              button: classes.listItemButton,
            }}
            onClick={onToggleClick}
          >
            <ListItemIcon className={classes.listIcon}>
              {expanded ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </ListItemIcon>
            <ListItemText
              classes={{
                root: cx(classes.listText, !expanded ? classes.hide : null),
                primary: cx(classes.listTextPrimary, classes.collapseLink),
                secondary: classes.listTextSecondary,
              }}
              primary="Collapse Menu"
            />
          </ListItem>
        </List>
        <ConfirmDialog
          classes={{
            root: classes.modalContainer,
          }}
          container={() => document.getElementById('main-section')}
          onCancel={() => {
            setConfirmLogout(false)
          }}
          onConfirm={() => {
            setConfirmLogout(true)
            setIsLoggedOut(true)
            window.location.href = '/logout'
          }}
          hideActions={isLoggedOut}
          open={confirmLogout}
          title={!isLoggedOut ? 'Are you sure you want to logout?' : ''}
          children={
            isLoggedOut && (
              <div className={classes.logoff}>
                <CircularProgress
                  size={60}
                  color="primary"
                  style={{ marginBottom: 16 }}
                />
                <span>Logging off ...</span>
              </div>
            )
          }
        />
      </div>
    </>
  )
}

export default NavigationBarLinks
