import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#222',
    '&::after': {
      display: 'none',
    },
  },
  grade_icon: {
    color: '#d3d3d3',
    verticalAlign: 'middle',
    height: 17,
    marginTop: -3,
    marginLeft: 4,
  },
  headerWrapper: {
    '& span': {
      fontSize: 18,
    },
    borderBottom: 'solid 1px rgb(239, 239, 239)',
    paddingBottom: 8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalLabel: {
    fontSize: 18,
    color: 'rgb(191, 191, 191)',
    fontWeight: 'normal',
    '@media screen and (max-width: 780px)': {
      fontSize: '14px !important',
    },
  },
  totalCount: {
    color: 'rgb(59, 144, 223)',
    fontWeight: 600,
    '@media screen and (max-width: 780px)': {
      fontSize: '14px !important',
    },
  },
  titleWrapper: {
    '@media screen and (max-width: 780px)': {
      display: 'none',
    },
    textTransform: 'uppercase',
  },
})
