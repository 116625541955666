import { useStores } from 'config/store'
import { formatTextByIndustry } from 'helpers/industries'
import Account from 'models/Account'

// MAIN FUNCTION
const GradeToolTipValue = (chart: string, grade: string | undefined) => {
  const { accountStore } = useStores()
  const account: Account = accountStore.accounts[0]

  // 1. Identify chart type
  const chartType = tooltipValues(account).find(
    (value) => value.chart === chart
  )
  // 2. Get find matched grade
  const tooltip =
    chartType && chartType.grade.find((value) => value.evaluation === grade)

  //  3. Return tooltip value of the matched grade
  return tooltip && tooltip.tooltipValue
}

// TOOLTIP DATA
const tooltipValues = (account: Account) => {
  return [
    {
      chart: 'Staff Placed Caller On Hold',
      grade: [
        {
          evaluation: 'A',
          tooltipValue: 'The staff did a great job in handling caller on hold.',
        },
        {
          evaluation: 'B',
          tooltipValue:
            'The staff did good but needs some improve in handling callers on hold.',
        },
        {
          evaluation: 'C',
          tooltipValue:
            'The staff must do a better job of placing callers on hold.',
        },
        {
          evaluation: 'D',
          tooltipValue: 'The staff should not put caller on hold for too long.',
        },
      ],
    },
    {
      chart: 'Staff Properly Requested To Place Caller On Hold',
      grade: [
        {
          evaluation: 'A',
          tooltipValue:
            'The staff did a great job of properly placing the caller on hold.',
        },
        {
          evaluation: 'B',
          tooltipValue:
            'The staff did a good job of properly placing caller on hold, but there is room for improvement.',
        },
        {
          evaluation: 'C',
          tooltipValue:
            'The staff must improve their ability to properly place calls on hold.',
        },
        {
          evaluation: 'D',
          tooltipValue: 'The staff should properly place the caller on hold.',
        },
      ],
    },
    {
      chart: 'Hold Time',
      grade: [
        {
          evaluation: 'A',
          tooltipValue: 'Great hold time.',
        },
        {
          evaluation: 'B',
          tooltipValue: 'Good hold time.',
        },
        {
          evaluation: 'C',
          tooltipValue: 'Hold time needs improvement.',
        },
        {
          evaluation: 'D',
          tooltipValue: 'Poor hold time.',
        },
      ],
    },
    {
      chart: 'Good Impression',
      grade: [
        {
          evaluation: 'A',
          tooltipValue: formatTextByIndustry({
            account,
            text: 'The staff did a great job overall with a positive and warm welcome to the {BUSINESS_LABEL}.',
          }),
        },
        {
          evaluation: 'B',
          tooltipValue: 'The staff did good but needs consistency.',
        },
        {
          evaluation: 'C',
          tooltipValue:
            'The staff needs improvement. Try answering in an upbeat tone.',
        },
        {
          evaluation: 'D',
          tooltipValue: formatTextByIndustry({
            account,
            text: 'The staff needs to answer the call using an upbeat tone and warm welcome to the {BUSINESS_LABEL}.',
          }),
        },
      ],
    },
    {
      chart: 'Asked For Appointment',
      grade: [
        {
          evaluation: 'A',
          tooltipValue:
            'The staff did a great job in always asking if the caller wants to schedule an appointment.',
        },
        {
          evaluation: 'B',
          tooltipValue:
            'The staff did good but needs to consistently ask if the caller wants to schedule an appointment.',
        },
        {
          evaluation: 'C',
          tooltipValue:
            'The staff needs to ask if the caller wants to schedule an appointment in all applicable calls.',
        },
        {
          evaluation: 'D',
          tooltipValue: 'The staff is not asking to schedule the appointment.',
        },
      ],
    },
    {
      chart: 'Asked For Source',
      grade: [
        {
          evaluation: 'A',
          tooltipValue: formatTextByIndustry({
            account,
            text: 'The staff did a great job asking how they heard about the doctor and {BUSINESS_LABEL} in all applicable calls.',
          }),
        },
        {
          evaluation: 'B',
          tooltipValue: formatTextByIndustry({
            account,
            text: 'The staff did good but needs to be consistent in asking how they heard about the doctor and {BUSINESS_LABEL}.',
          }),
        },
        {
          evaluation: 'C',
          tooltipValue: formatTextByIndustry({
            account,
            text: 'The staff needs to ask how they heard about the doctor and {BUSINESS_LABEL} more often.',
          }),
        },
        {
          evaluation: 'D',
          tooltipValue: formatTextByIndustry({
            account,
            text: 'The staff should start asking how they heard about the doctor and {BUSINESS_LABEL}.',
          }),
        },
      ],
    },
    {
      chart: 'Asked For Additional Appointments',
      grade: [
        {
          evaluation: 'A',
          tooltipValue:
            'The staff did a great job in always asking for additional appointments.',
        },
        {
          evaluation: 'B',
          tooltipValue:
            'The staff did good but needs to consistently ask for additional appointments.',
        },
        {
          evaluation: 'C',
          tooltipValue:
            'The staff needs to ask for additional appointments more often.',
        },
        {
          evaluation: 'D',
          tooltipValue: 'The staff is not asking for additional appointments.',
        },
      ],
    },
    {
      chart: 'Pricing Related Answers',
      grade: [
        {
          evaluation: 'A',
          tooltipValue:
            'The staff did a great job in answering pricing-related questions.',
        },
        {
          evaluation: 'B',
          tooltipValue:
            'The staff did good but needs improvement in answering pricing-related questions.',
        },
        {
          evaluation: 'C',
          tooltipValue:
            'The staff must do a better job in answering pricing-related questions.',
        },
        {
          evaluation: 'D',
          tooltipValue:
            'The staff did not answer pricing related questions properly.',
        },
      ],
    },
  ]
}

export default GradeToolTipValue
