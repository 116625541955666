import React, { PropsWithChildren } from 'react'
import { Observer } from 'mobx-react'
import { Collapse, createStyles, Grid, makeStyles } from '@material-ui/core'

import { useCallTableFilterStore } from '../store/CallTableFilterStore'

export default function CollapsibleContainer({
  children,
}: PropsWithChildren<{}>) {
  const styles = useStyles()
  const callTableFilterStore = useCallTableFilterStore()

  return (
    <Observer
      render={() => {
        const { moreFiltersOpened } = callTableFilterStore
        return (
          <Collapse in={moreFiltersOpened.get()}>
            <Grid container className={styles.root}>
              {children}
            </Grid>
          </Collapse>
        )
      }}
    />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
      border: '1px solid #d3d3d3',
      borderTop: 'none',
      borderRadius: '0px 0px 4px 4px',
      backgroundColor: '#FBFBFB',
      gap: theme.spacing(2),
    },
  })
)
