import React from 'react'
import useStyles from './CampaignsSkeleton.style'

export default function CampaignsSkeleton() {
  const classes = useStyles()
  const items = Array(4).fill(null)

  return (
    <>
      {items.map((_, index) => (
        <div
          key={`campaign-skeleton-${index}`}
          className={classes.placeholderDelivered}
        />
      ))}
    </>
  )
}
