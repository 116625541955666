import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { useStyles } from './MobileAccordion.styles'
import classNames from 'classnames'

interface MobileAccordionInterface {
  children?: React.ReactNode
  title?: string
  header?: boolean
}

const MobileAccordion = (props: MobileAccordionInterface) => {
  const { children, title, header } = props
  const classes = useStyles()
  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      elevation={0}
      classes={{
        root: classNames(
          classes.accordionRoot,
          header ? classes.headingAccordion : null
        ),
        expanded: classes.accordionExpanded,
      }}
    >
      <AccordionSummary
        classes={{
          expanded: classes.accordionSummaryExpanded,
          expandIcon: classes.expandIcon,
        }}
        expandIcon={header ? null : <ExpandMoreIcon />}
        aria-controls={title}
        id={title}
      >
        <Typography className={classes.accordionHeading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.accordionDetailsRoot,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default MobileAccordion
