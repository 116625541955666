import { makeStyles } from '@material-ui/core/styles'

// @todo technical debt update eslint
// eslint-disable-next-line no-undef
export type useStyleType = Partial<ReturnType<typeof useStyles>>

export const useStyles = makeStyles({
  root: { width: '100%' },
  emptyChart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  emptyChartLabel: {
    fontSize: '1rem',
    color: '#A4A4A4',
    fontWeight: 'bold',
    padding: '1rem',
  },
})
