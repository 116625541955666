import React, { useCallback, useEffect, useState } from 'react'
import { Observer } from 'mobx-react'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'

import API, { DATA_API } from 'helpers/api'
import accountStore from 'config/store/AccountStore'

import AsyncFilterField, { AsyncFilterFieldProp } from './AsyncFilterField'
import { useCallTableFilterStore } from '../../store/CallTableFilterStore'
import { useStores } from 'config/store'
import { repository as productRepo } from 'models/Product/Product'

type SfFieldType = {
  Product__c: string | null
  Product__r: null | { Display_Name__c: string }
}

const getSource = async (params: { accountIds: string[] }) => {
  const result = (await API.get({
    api: DATA_API,
    url: '/products/inquiries',
    params,
  })) as []

  const mapResponse = (a: SfFieldType): AsyncFilterFieldProp['source'][0] => ({
    label: a?.Product__r?.Display_Name__c ?? 'N/A',
    value: a?.Product__c ?? '',
  })

  const servicesMap = new Map<
    AsyncFilterFieldProp['source'][0]['value'],
    AsyncFilterFieldProp['source'][0]
  >()
  const services = (result ?? [])
    .filter((a: SfFieldType) => !_isNil(a?.Product__c))
    .map(mapResponse)
  services.forEach((a) => servicesMap.set(a.value, a))

  return Array.from(servicesMap.values())
}

const KEY = 'service'
const LABEL = 'Services'

export default function ServiceFilterField() {
  const { callsStore } = useStores()
  const store = useCallTableFilterStore()
  const filter = store.filterRegistry.get(KEY)
  const initialValue = callsStore.filters[KEY] ?? []

  const [loading, setLoading] = useState(false)

  const initialSource = initialValue.map((id: string) => {
    const product = productRepo.get(id)
    return { label: product.displayName, value: id }
  })
  const [source, setSource] =
    useState<AsyncFilterFieldProp['source']>(initialSource)

  useEffect(() => {
    store.registerFilter(KEY, {
      label: LABEL,
      value: initialValue,
      active: Boolean(initialValue && initialValue.length),
      type: 'LIMITED',
    })
  }, [])

  useEffect(() => {
    const accountIds = (accountStore.selectedAccounts || []).slice()
    setLoading(true)
    getSource({ accountIds }).then((picklist) => {
      setLoading(false)
      setSource(picklist)
    })
  }, [accountStore.selectedAccounts])

  const onChange = useCallback((value: AsyncFilterFieldProp['value']) => {
    store.setFilterValue(KEY, _isEmpty(value) ? undefined : value)
    store.runFilter()
  }, [])

  return (
    <Observer
      render={() => {
        const isShown = filter?.active ?? false
        const value = filter?.value ?? []

        return (
          <AsyncFilterField
            multiple
            loading={loading}
            label={LABEL}
            value={value}
            source={source}
            shown={isShown}
            onChange={onChange}
          />
        )
      }}
    />
  )
}
