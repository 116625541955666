import React, { useState } from 'react'
import { Observer } from 'mobx-react'
import { runInAction } from 'mobx'
import _orderBy from 'lodash/orderBy'
import moment from 'moment-timezone'
import CircularProgress from '@material-ui/core/CircularProgress'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Link } from '@material-ui/core'

import { dateFormat } from '../../../config/callpro.config'
import { useStores } from 'config/store'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'
import InsuranceStore from 'config/store/CallPro/Insurance/InsuranceStore'

import { useInsuranceCardStyles } from './Insurance.style'

import CustomTooltip from '../CustomTooltip/CustomTooltip'
import CallDetailsDrawer from 'components/CallPro/SideDrawer/CallDetailsDrawer/CallDetailsDrawer'
import AllInsuranceDrawer from 'components/CallPro/SideDrawer/AllInsurance/AllInsuranceDrawer'

import { ChartCard } from '../ChartCard'
import { LinearProgress } from '../LinearProgress'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'

interface InsuranceInterface {
  isAccordionContent?: boolean
}

type InsuranceItem =
  (typeof InsuranceStore)['insuranceInquiriesBarChartData'][number]

// MAIN FUNCTION
const Insurance = (props: InsuranceInterface) => {
  const { isAccordionContent } = props
  const classes = useInsuranceCardStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { insuranceStore, callsStore, dashboardUIStore } = useStores()

  const handleToggleDrawer = () => setDrawerOpen((value) => !value)

  // TOOLTIP CONTENT
  const tooltipContent = (
    <div className={classes.metricTooltip}>
      <div className={classes.tooltipTitle}>Click to see the full list</div>
    </div>
  )

  const handleItemClick = (item: InsuranceItem) => () => {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS
      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters({ outcome, insurance: [item.id] })
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  return (
    <Observer
      render={() => {
        const {
          insuranceInquiriesBarChartData,
          insuranceInquiresLoading,
          insuranceStart,
        } = insuranceStore
        const total = insuranceInquiriesBarChartData.reduce(
          (accumulator, currentElement) => accumulator + currentElement.value,
          0
        )

        return (
          <>
            <ChartCard
              headerTitle="TOP REQUESTED INSURANCES"
              tooltipText="This shows the insurance providers requested or discussed during the call."
              headerTotalText="Total Calls"
              headerTotalValue={total}
              isAccordionContent={isAccordionContent}
            >
              {insuranceInquiresLoading ? (
                <div
                  style={{
                    textAlign: 'center',
                    padding: '2rem',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : insuranceInquiriesBarChartData.length > 0 ? (
                <>
                  {insuranceInquiriesBarChartData
                    .slice(0, 5)
                    .map((insuranceInquiry, key) => {
                      return (
                        <LinearProgress
                          key={`item-${key}`}
                          value={insuranceInquiry.value}
                          total={total}
                          label={
                            <Link
                              component="button"
                              variant="body1"
                              underline="none"
                              color="initial"
                              onClick={handleItemClick(insuranceInquiry)}
                              classes={{ root: classes.link }}
                            >
                              {insuranceInquiry.name}
                            </Link>
                          }
                        />
                      )
                    })}
                  <div className={classes.footer}>
                    <div className={classes.container}>
                      <div className={classes.infoContainer}>
                        <div className={classes.info}>
                          <CustomTooltip content={tooltipContent}>
                            <div
                              className={classes.infoDetail}
                              onClick={handleToggleDrawer}
                            >
                              All Insurances (
                              {insuranceInquiriesBarChartData.length})
                            </div>
                          </CustomTooltip>
                        </div>
                      </div>
                    </div>
                    {insuranceStore.insuranceStart && (
                      <div className={classes.disclaimer}>
                        <InfoOutlinedIcon className={classes.disclaimerIcon} />
                        The data used in this graph is derived from the calls we
                        scored since {moment(insuranceStart).format(dateFormat)}
                        .
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <EmptyBarChart />
              )}
            </ChartCard>
            <CallDetailsDrawer
              drawerOpen={drawerOpen}
              handleCloseDrawer={handleToggleDrawer}
              title="All Requested Insurance"
            >
              <AllInsuranceDrawer />
            </CallDetailsDrawer>
          </>
        )
      }}
    />
  )
}

export default Insurance
