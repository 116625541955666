import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { Redirect } from 'react-router-dom'
import cx from 'classnames'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router'

import request from '../../assets/img/request.svg'
import success from '../../assets/img/success.svg'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import colors from '../../components/Theme/themes/default/colors'

const isRequesting = observable.box(false)
const isReqFailed = observable.box(false)

// initialise color here because it's not included in branding
const lightGrey = 'rgb(126, 127, 127)'
const styles = ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%',
    backgroundColor: colors.darkGrey,
    borderTop: 'solid 5px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  success: {
    borderTopColor: colors.green,
  },
  request: {
    borderTopColor: lightGrey,
  },
  status: {
    marginTop: 30,
    fontSize: 20
  },
  substat: {
    color: colors.lightGrey,
    fontSize: 15,
    marginBottom: 80
  },
  footer: {
    textAlign: 'center',
    '& p': {
      fontSize: 14,
      color: colors.mediumGrey
    },
    '& a': {
      color: colors.darkPink
    }
  },
  btnReq: {
    marginTop: 50,
    marginBottom: 20,
    padding: '8px 30px',
    fontSize: 15,
    textTransform: 'inherit',
    color: '#fff !important',
    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.26)'
    }
  },
  colorSuccess: { color: colors.green },
  colorRequest: { color: lightGrey },
  iconSuccess: {
    color: '#fff'
  },
  iconRequest: {
    color: lightGrey
  },
  imgStat: {
    height: 160,
    width: 160
  },
  loader: {
    marginLeft: 8,
    marginRight: -8
  },
  offsetTop: {
    marginTop: 60
  }
})

@inject('appState')
@withRouter
@withStyles(styles)
@observer
export default class ActivationLinkStatus extends Component {
  render() {
    try {
      const { classes } = this.props
      const { status, message, email, code } = this.props.location.state
      const isReq = isRequesting.get()
      const isFailed = isReqFailed.get()
      const isSent = status === 'sent'
      const isError = status === 'error'
      const isExpired = status === 'expired'

      return(
        <div className={cx(classes.container, isSent ? classes.success : classes.request)}>
          { isSent && <img className={classes.imgStat} src={success} alt='sent' /> }
          { (isError || isExpired) && <img className={classes.imgStat} src={request} alt='request' /> }

          { isSent && <h3 className={cx(classes.colorSuccess, classes.status)}>New link successully sent!</h3> }
          { (isError || isExpired) && <h3 className={cx(classes.colorRequest, classes.status)}>{message}.</h3> }

          { isSent && <p className={classes.substat}>Check your email for your new account activation link.</p> }
          <section className={classes.footer}>
            { isSent && <p>Problem getting new link?</p> }
            { isExpired &&
              <Button className={classes.btnReq}
                      disabled={isReq || isFailed}
                      onClick={() => {
                        const { appState, history } = this.props
                        isRequesting.set(true)

                        appState.generateActivationLink(email, code).then(status => {
                          if(status) {
                            history.push({
                              pathname: 'activation',
                              state: {
                                status: 'sent'
                              }
                            })
                          }
                        }).catch(error => {
                          isReqFailed.set(true)
                          isRequesting.set(false)
                          console.log(error)
                        })
                      }}
                      variant='contained'
                      color='primary'>{ isReq ? 'Sending activation link...' : 'Request New Link' }
                        { isReq && <CircularProgress className={classes.loader} size={18} color="primary" /> }
                      </Button>
            }
            { !isExpired && !isSent && <Button className={classes.btnReq} variant='contained' color='primary' href="/login">Login</Button>}
            <p className={isError && classes.offsetTop}>
              { isSent && 'Email us at' }
              { isExpired && isFailed ? 'There was a problem requesting the link, please contact us at' : !isError && 'or email us at' }
              { isError && 'Having trouble activating your account?' }<br/>
              { isError && 'Please email us at ' }
              <a href='mailto:contact@mvpmailhouse.com'>contact@mvpmailhouse.com</a></p>
          </section>
        </div>
      )
    } catch(error) {
      return <Redirect to={{ pathname: '/login'}} />
    }
  }
}