// ***********************************************
// REUSABLE SIDE DRAWER FOR DISPLAYING ANY DETAILS

import React, { PropsWithChildren } from 'react'
import Drawer from '@material-ui/core/Drawer'

import CloseIcon from '@material-ui/icons/Close'

interface SideDrawerContainerProps {
  title: string
  drawerOpen: boolean
  handleCloseDrawer: () => void
}

// STYLES
import { useStyles } from './CallDetailsDrawer.style'
import { makeStyles, createStyles } from '@material-ui/core/styles'

// === MAIN
const SideDrawerContainer: React.FC<SideDrawerContainerProps> & {
  section: typeof Section
} = ({ title, drawerOpen, handleCloseDrawer, children }) => {
  const classes = useStyles()

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
      <div className={classes.sideDrawer}>
        <div className={classes.titleWrapper}>
          <div> {title}</div>
          <CloseIcon
            className={classes.closeIcon}
            onClick={handleCloseDrawer}
          />
        </div>

        {children}
      </div>
    </Drawer>
  )
}

export default SideDrawerContainer

SideDrawerContainer['section'] = Section

function Section(props: PropsWithChildren<{ title: string | JSX.Element }>) {
  const classes = useSectionStyles()
  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.title}>{props.title}</div>
          {props.children}
        </div>
      </div>
    </>
  )
}

const useSectionStyles = makeStyles(
  createStyles({
    root: {
      minHeight: '100%',
      overflow: 'auto',
      backgroundColor: '#F4F4F4',
      padding: '.85rem',
    },

    container: {
      backgroundColor: '#fff',
      borderRadius: '10px',
      padding: '1rem',
    },

    title: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      paddingBottom: '.7rem',
    },
  })
)
