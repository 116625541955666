import React from 'react'
import { useStores } from 'config/store'
import { observer } from 'mobx-react'

import MailingRecentCalls from 'components/Mailings/MailingRecentCalls'

export const MailingCalls = () => {
  const { mailingsStore } = useStores()
  const mailing = mailingsStore.drawer

  if (!mailing) {
    return <></>
  }

  return (
    <>
      <MailingRecentCalls campaignId={mailing.id} />
    </>
  )
}

export default observer(MailingCalls)
