import React from 'react'
import { Switch, Tooltip, Button } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useStyles } from './AlertType.styles'
import Dropdown from 'components/MVP/Dropdown/Dropdown'
import { alertTypeDefaultFrequency } from '../ManageReceivers/ManageReceivers'

export type NotificationSettingsData = {
  id: string
  contactId: string
  name: string
  alertType: string
  frequency: string
  email: boolean
  sms: boolean
}
interface AlertTypeProps {
  isPrimaryContact: boolean
  alertType: string
  tooltip: string
  data: NotificationSettingsData
  onFrequencyChange: (data: NotificationSettingsData, frequency: string) => void
  onEmailChange: (data: NotificationSettingsData, checked: boolean) => void
  onSmsChange: (data: NotificationSettingsData, checked: boolean) => void
  handleShowModal: (alertType: string) => void
  alertTypeFrequencyOptions: string[]
}

const AlertType: React.FC<AlertTypeProps> = ({
  isPrimaryContact,
  alertType,
  tooltip,
  data,
  onFrequencyChange,
  onEmailChange,
  onSmsChange,
  handleShowModal,
  alertTypeFrequencyOptions
}) => {
  const classes = useStyles()

  return (
    <div className={classes.row}>
      <div className={classes.col}>
        <label>
          {alertType}{' '}
          <Tooltip
            title={tooltip}
            placement="bottom-start"
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <InfoOutlinedIcon className={classes.grade_icon} />
          </Tooltip>
        </label>
      </div>
      <div className={classes.col}>
        <div className={classes.switchWrapper}>
          <Switch
            checked={data.email}
            color="primary"
            name="email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onEmailChange(data, e.target.checked)}
          />
          <label>Email</label>
        </div>
         <div className={classes.switchWrapper}>
          <Switch
            checked={data.sms}
            color="primary"
            name="sms"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSmsChange(data, e.target.checked)}
          />
          <label>SMS</label>
        </div>
      </div>
      <div className={classes.col}>
        <Dropdown
          name="frequency"
          value={data.frequency === '' ? alertTypeDefaultFrequency[alertType] : data.frequency}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onFrequencyChange(data, e.target.value)}
          options={alertTypeFrequencyOptions}
          placeholder="Choose Frequency"
        />
      </div>
      {isPrimaryContact && 
        <div className={classes.col}>
          <Button
            onClick={() => handleShowModal(alertType)}
            variant="outlined"
            color="primary"
          >
            Manage Receivers
          </Button>
        </div>
      }
    </div>
  )
}

export default AlertType
