import { makeStyles, createStyles } from '@material-ui/core'

const borderStyle = '1px solid #d3d3d3'

const useStyles = makeStyles((theme) =>
  createStyles({
    searchIconContainer: {
      border: borderStyle,
      borderRight: 'none',
      width: 'initial',
    },
    searchIcon: {
      width: theme.spacing(6),
    },
    textFieldRoot: {
      flex: 1,
    },
    textFieldInput: {
      borderRadius: 0,
      backgroundColor: 'white',
    },
    filterSelect: {
      border: borderStyle,
      borderLeft: 'none',
      borderRadius: 0,
      minWidth: '8rem',
    },
    filterSelectLabel: {
      '& span:first-child': {
        flex: 1,
        textAlign: 'initial',
      },
    },
  })
)

export default useStyles
