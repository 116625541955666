import React, { Component } from 'react'
import { observer } from 'mobx-react'

import withStyles from '@material-ui/core/styles/withStyles'

import CircularProgress from '@material-ui/core/CircularProgress'

import MapsStore from 'src/config/store/MapsStore'
import { defaultFont } from 'src/assets/jss/mvp'

const styles = theme => ({
  root: {
    ...defaultFont,
    position: 'absolute',
    zIndex: 999,
		top: 0,
		left: 0,
		height: '100vh',
		width: '100vw',
		transition: '0.3s ease opacity',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
	},
	loaderContent: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
		height: '100%',
		backgroundSize: 'cover'
	},
	loaderCaption: {
    ...defaultFont,
    marginTop: 15,
    fontWeight: 400,
    fontSize: '18px',
    color: '#666'
  }
})

@withStyles(styles)
@observer
export default class LoadingOverlay extends Component {

  render() {
		const { classes, ...props } = this.props

		return (
				<div style={{display: MapsStore.isLoading ? 'block' : 'none'}} className={classes.root}>
					<div className={classes.loaderContent}>
						<div className={classes.loaderIcon}>
							<CircularProgress size={60} color="primary" />
						</div>
						<div className={classes.loaderCaption}>Loading map...</div>
					</div>
				</div>
			)
	}
}
