import AppState from 'config/store/AppState'
import processAccessToken from 'services/grants/processAccessToken'

async function authRequestInterceptor(error: any) {
  const response = error.response

  // Do something with response error
  if (response && (response.status === 401 || response.status === 403)) {
    // Maybe try to refresh the access token
    // or maybe alert Auth service that token is invalid (AuthService.checkSession)
    AppState.clearSession()
    AppState.errorMessage('You have been logged out')
  }

  if (response && response.status === 409) {
    const token = AppState.accessToken
    const session = await processAccessToken(token)
    await AppState.setSession(session)
    window.location.href = '/calls'
  }

  return Promise.reject(error)
}

export default [authRequestInterceptor]
