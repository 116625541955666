import React from 'react'
import { Link } from '@material-ui/core'
import { runInAction } from 'mobx'
import { useStores } from 'config/store'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'

// helpers
import { formatCampaignReferralSourcesName } from 'helpers/campaignSources'

// styles
import { useStyles } from './AllTopCampaignSourcesSection.style'

type Item = ReturnType<
  typeof useStores
>['callsStore']['topCampaignSource']['records'][number]

const AllTopCampaignSourcesSection = () => {
  const classes = useStyles()

  const { callsStore, dashboardUIStore } = useStores()
  const { topCampaignSource: list } = callsStore

  const total = list.records.length
  const isEmpty = total === 0

  const handleItemClick = (item: Item) => {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS

      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters({
        outcome,
        campaignType: [item.campaignType],
        mediaType: [item.mediaType],
      })
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  return (
    <section className={classes.root}>
      <div className={classes.container}>
        <div className={classes.title}>
          All Campaign Referral Sources {isEmpty ? '' : `(${total})`}
        </div>
        <Table list={list.records} onItemClick={handleItemClick} />
      </div>
    </section>
  )
}

type TableProp = {
  list: Item[]
  onItemClick: (item: Item) => void
}

const Table = (props: TableProp) => {
  const classes = useStyles()
  const { list, onItemClick } = props

  const handleItemClick = (item: Item) => () => {
    onItemClick(item)
  }

  return (
    <>
      <div className={classes.row}>
        <div className={classes.rowData}>#</div>
        <div className={classes.rowData}>Campaign Type</div>
        <div className={classes.rowData}>Count</div>
      </div>
      {list.map((item, index) => {
        return (
          <div className={classes.row} key={index}>
            <div className={classes.rowData}>{index + 1}</div>
            <div className={classes.rowData}>
              <Link
                component="button"
                variant="body1"
                underline="none"
                color="initial"
                onClick={handleItemClick(item)}
                classes={{ root: classes.link }}
              >
                {formatCampaignReferralSourcesName(item)}
              </Link>
            </div>
            <div className={classes.rowData}>{item.value}</div>
          </div>
        )
      })}
    </>
  )
}

export default AllTopCampaignSourcesSection
