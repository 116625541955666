import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(
  createStyles({
    root: {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
    },
    flex: {
      display: 'flex',
      columnGap: 8,
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    flex1: {
      flex: 1,
    },
    icon: {
      color: '#d4d4d4',
      fontSize: '1rem',
    },
    text: {
      color: '#727272',
      fontSize: '12px',
    },
    textEllipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    help: {
      cursor: 'help',
    },

    // Tooltip
    tooltip: {
      background: 'rgba(40, 40, 40, 1)',
      marginTop: 2,
      marginLeft: 16,
      '&:after': {
        borderWidth: 0,
        marginLeft: 0,
      },
    },
    arrow: {
      color: 'rgba(40, 40, 40, 1)',
    },
  })
)
