import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { observer } from 'mobx-react'
import cx from 'classnames'

// COMPONENTS
import CustomTable from '../../Table/Table'
import Card from '../../Cards/Card'
import {
  callProTrackingLineColumns,
  renderTrackingLineColumns,
} from 'components/CallPro/TrackingLines/TrackingLineColumns'
import LoadingData from 'components/ProgressIndicator/LoadingData'

// STATE MANAGEMENT
import { useStores } from 'config/store'

// STYLES
import { useStyles, UseStyleType } from './TrackingLines.style'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

interface TrackingLinesProp {
  classes?: UseStyleType
}

const TrackingLines = observer((prop: TrackingLinesProp) => {
  const { classes } = prop
  const { trackingLinesStore } = useStores()
  const styles = useStyles()
  const [editRowIndex, setEditRowIndex] = useState<number | undefined>()

  const {
    filteredRows,
    isLoading,
    orderBy,
    dir,
    currentPage,
    pageSize,
    paginatedRows,
    setSearchString,
    setCurrentPage,
    setPageSize,
    toggleSorting,
    showInActive,
    toggleShowInActive,
  } = trackingLinesStore

  const onColumnSort = (column: string) => {
    toggleSorting(column)
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page)
  }

  const onChangePageSize = (pageSize: number) => {
    setPageSize(pageSize)
  }

  const handleRowMouseEnter = (index: number) => {
    setEditRowIndex(index)
  }
  const handleRowMouseLeave = (index: number) => {
    setEditRowIndex(undefined)
  }

  return (
    <Card
      title="Tracking Lines"
      classes={{
        root: cx(styles.cardRoot, classes?.root),
        content: styles.cardContent,
      }}
    >
      <div className={styles.filters}>
        <TextField
          className={styles.searchInput}
          id="input-with-icon-grid"
          placeholder="Search name or number"
          onChange={(event: any) => {
            setSearchString(event.target.value)
          }}
        />
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={showInActive}
              onChange={() => toggleShowInActive()}
              name="show-active"
            />
          }
          label="Show in-active numbers"
        />
      </div>

      <CustomTable
        columns={callProTrackingLineColumns}
        rows={renderTrackingLineColumns({
          trackingLines: JSON.parse(JSON.stringify(paginatedRows)),
          currentPage,
          pageSize,
          editRowIndex,
        })}
        defaultRowPerPage={10}
        isLoading={isLoading}
        orderBy={orderBy}
        dir={dir}
        currentPage={currentPage}
        totalRowsCount={filteredRows.length}
        onColumnSort={onColumnSort}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onRowMouseEnter={handleRowMouseEnter}
        onRowMouseLeave={handleRowMouseLeave}
      />
      <LoadingData hidden={!isLoading} />
    </Card>
  )
})

export default TrackingLines
