import React, { PropsWithChildren } from 'react'

import { Theme } from '@material-ui/core/styles/createTheme'
import Typography from '@material-ui/core/Typography'
import { makeStyles, createStyles } from '@material-ui/core/styles'

type Prop = PropsWithChildren<{
  title?: string
}>

export default function HeaderTop(props: Prop) {
  const classes = useStyles()
  const { title, children } = props
  return (
    <div className={classes?.container}>
      <Typography className={classes?.title} variant="h6">
        {title}
      </Typography>
      <div className={classes?.toolbar}>{children}</div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      minHeight: 50,
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '10px 24px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    title: {
      color: '#141414',
      fontSize: '1.4em',
      fontWeight: 700,
      flex: '1 0 auto',
    },
    toolbar: {
      flex: '0 0 auto',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'initial',
      },
    },
  })
)
