import React, { useState, useEffect } from 'react'

// MUI
import { ErrorOutline } from '@material-ui/icons'
import { Table } from '@material-ui/core'

// Util
import _flatten from 'lodash/flatten'
import { observer } from 'mobx-react'
import { useHistory, useParams } from 'react-router-dom'

// My util
import useMailingsStyles from './Campaigns.style'
import MailingModel, { repository as mailsRepo } from 'models/Mailing'
import { useStores } from 'config/store'

// My components
import MailingCard from 'components/Mailings/MailingCard'
import { MailingDetailsDrawer } from '../../components/Mailings/MailingDetailsDrawer'
import TablePagination from 'components/MVP/Pagination/Pagination'
import PageContainer from 'components/Page/PageContainer'
import CampaignAppBar from 'views/Campaigns/CampaignAppBar'
import TabPanel from 'views/Campaigns/TabPanel'
import AllStats from 'views/Campaigns/AllStats'
import CampaignsSkeleton from 'views/Campaigns/CampaignsSkeleton'

const Campaigns: React.FC = () => {
  const { mailingsStore } = useStores()
  const { tab } = mailingsStore

  useEffect(() => {
    mailingsStore.makeReactions()
    mailingsStore.dirty(true)
    return () => mailingsStore.dispose()
  }, [mailingsStore])

  return (
    <>
      <PageContainer>
        <CampaignAppBar />
        <TabPanel key="overview" index="overview" value={tab}>
          <AllStats />
          <CampaignList />
        </TabPanel>
      </PageContainer>
      <MailingDrawer />
    </>
  )
}

export default observer(Campaigns)

const MailingDrawer = () => {
  const { mailingsStore } = useStores()
  const history = useHistory()
  const { mailingId } = useParams<{ mailingId: string }>()
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  useEffect(() => {
    let mailing: MailingModel | undefined = undefined
    let newMailing: MailingModel | undefined = undefined
    let newId: string | undefined = undefined

    setDrawerOpen(!!mailingId)

    if (mailingId) {
      mailing = mailsRepo.get(mailingId)

      if (!mailing) {
        newMailing = new MailingModel()
        newMailing.id = mailingId

        newId = mailsRepo.upsert(newMailing)
        mailing = mailsRepo.get(newId)

        selectMailing(mailing)
      }
    }
  }, [mailingId])

  const selectMailing = (mailing: { id: string; [K: string]: any }) => {
    if (mailing) {
      mailing.update()
      setDrawerOpen(true)
      mailingsStore.setDrawer(mailing as any)
    }
  }

  const handleCloseDrawer = () => {
    setDrawerOpen(false)
    history.push('/campaigns')
  }

  return <MailingDetailsDrawer open={drawerOpen} onClose={handleCloseDrawer} />
}

const CampaignList = observer(() => {
  const classes = useMailingsStyles()
  const { mailingsStore } = useStores()
  const history = useHistory()

  const {
    totalCountDelivered,
    pageSize,
    currentPage,
    isLoadingDeliveredMailings: isLoading,
  } = mailingsStore

  const handleViewMailing = (mailing: MailingModel) => {
    mailingsStore.setDrawer(mailing as any)
    history.push(`/campaigns/${mailing.id}`)
  }

  if (isLoading) {
    return (
      <div className={classes.gridContainer}>
        <CampaignsSkeleton />
      </div>
    )
  }

  if (!totalCountDelivered) {
    return (
      <div className={classes.noResults}>
        <ErrorOutline /> You don't have any delivered mailings
      </div>
    )
  }

  return (
    <>
      {totalCountDelivered ? (
        <div className={classes.gridContainer}>
          {mailingsStore.itemsPerPageDelivered
            .slice()
            .map((mailing: MailingModel, index: number) => {
              return (
                <MailingCard
                  index={index}
                  key={mailing.id}
                  mailing={mailing}
                  onViewAction={handleViewMailing}
                />
              )
            })}
        </div>
      ) : (
        <></>
      )}

      {totalCountDelivered > 4 ? (
        <Table>
          <TablePagination
            totalItems={totalCountDelivered}
            currentPage={currentPage}
            pageSize={pageSize}
            onChangePage={(page: number) => {
              mailingsStore.setCurrentPage(page)
            }}
            onChangePageSize={(pageSize: number) => {
              mailingsStore.setPageSize(pageSize)
            }}
          />
        </Table>
      ) : (
        <></>
      )}
    </>
  )
})
