import React, { useCallback, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
} from '@material-ui/core'

import CustomTooltip from 'components/CallPro/CustomTooltip/CustomTooltip'
import CallDetailsDrawer from 'components/CallPro/SideDrawer/CallDetailsDrawer/CallDetailsDrawer'

import { useReactQuery } from './_hooks'
import { Table } from './_table'

const BUTTON_LABEL = 'See Individual Metric'
const TOOLTIP_CONTENT = 'Click to see the full list'
const DRAWER_TITLE = 'Staff asked for appointment'

/**
 * If you want to make adjustments where you can extend and access
 * the low level components used inside this component.
 * Please extract and make the component available for reusablility.
 * Checkout how mantine.dev made their Modal component reusable.
 */
export default function SeeIndividualMetric() {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { isFetching, data } = useReactQuery({ enabled: drawerOpen })

  const list: Parameters<typeof Table>[number]['data'] = data!['records']
  const total = list.length

  const handleClick = useCallback(() => setDrawerOpen(true), [])
  const handleCloseDrawer = useCallback(() => setDrawerOpen(false), [])

  const sectionTitle = <>{DRAWER_TITLE + (total ? ` (${total})` : '')}</>

  return (
    <>
      <Box display={'flex'} justifyContent={'center'} mb={2} mt={'auto'}>
        <CustomTooltip content={TOOLTIP_CONTENT}>
          <Button
            color="secondary"
            onClick={handleClick}
            classes={{ label: classes.label }}
            size="small"
          >
            {BUTTON_LABEL}
          </Button>
        </CustomTooltip>
      </Box>
      <CallDetailsDrawer
        drawerOpen={drawerOpen}
        handleCloseDrawer={handleCloseDrawer}
        title={DRAWER_TITLE}
      >
        <CallDetailsDrawer.section title={sectionTitle}>
          {isFetching ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={18} />
            </Box>
          ) : (
            <Table data={list} />
          )}
        </CallDetailsDrawer.section>
      </CallDetailsDrawer>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      color: '#ED164E',
      fontWeight: 'bold',
      textTransform: 'initial',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
  })
)
