import React from 'react'
import ToolTip from '@material-ui/core/Tooltip'
import { InfoOutlined } from '@material-ui/icons'

// STYLES
import { useStyles } from './KPICardContainer.styles'
import classNames from 'classnames'

interface KPICardContainerInterface {
  title: string
  tooltip?: string
  children: React.ReactNode
  isAccordionContent?: boolean
}

const KPICardContainer = (props: KPICardContainerInterface) => {
  const { title, tooltip, children, isAccordionContent } = props
  const classes = useStyles()

  return (
    <div
      className={classNames(
        classes.container,
        isAccordionContent ? classes.containerForAccordion : null
      )}
    >
      {isAccordionContent ? null : (
        <header className={classes.header}>
          <h3>{title}</h3>
          {tooltip ? (
            <ToolTip
              id="staff-placed-caller-on-hold"
              title={tooltip}
              placement="bottom-start"
              classes={{
                tooltip: classes.tooltip,
              }}
            >
              <InfoOutlined className={classes.grade_icon} />
            </ToolTip>
          ) : null}
        </header>
      )}

      {children}
    </div>
  )
}

export default KPICardContainer
