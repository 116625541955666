import React, { Ref, useRef, useState } from 'react'

// Mui
import {
  Fade,
  ListItemIcon,
  ListItemText,
  MenuItem as MuiMenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ChevronRight } from '@material-ui/icons'

// My component
import Menu, { Prop as MenuProps } from './Menu'

export interface Prop {
  key: string
  label: string | JSX.Element
  selected: boolean
  // anchorEl?: Element | ((element: Element) => Element) | null
  icon?: JSX.Element
  onClick?: () => void
  onClose?: () => void
  menuProps?: MenuProps
}

export default function MenuItem(props: Prop) {
  const {
    label,
    menuProps = { items: [] },
    icon,
    selected = false,
    onClick,
    ...restProps
  } = props
  const classes = useStyles()

  const [openSubmenu, setOpenSubmenu] = useState(false)
  const buttonRef = useRef()

  const handleSelect = (callback?: () => void) => () => {
    setOpenSubmenu(!callback)
    callback && callback()
  }

  function handleClose() {
    setOpenSubmenu(false)
  }

  function renderMenu() {
    if (!menuProps) {
      return null
    }

    const { items, ...restMenuProps } = menuProps

    return (
      <Menu
        {...restMenuProps}
        items={items}
        getContentAnchorEl={null}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        TransitionComponent={Fade}
        transitionDuration={10}
        open={openSubmenu}
        onClose={handleClose}
        style={{
          marginTop: -8,
          marginLeft: 4,
        }}
      />
    )
  }

  return (
    <>
      <MuiMenuItem
        onClick={handleSelect(onClick)}
        selected={selected}
        {...restProps}
        button
        // @ts-ignore
        ref={buttonRef}
      >
        {icon ? (
          <>
            <ListItemIcon classes={{ root: classes.icon }}>{icon}</ListItemIcon>
            <ListItemText classes={{ root: classes.label }} primary={label} />
          </>
        ) : (
          label
        )}
        {menuProps ? <ChevronRight style={{ color: '#727272' }} /> : null}
      </MuiMenuItem>
      {renderMenu()}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '1em',
    minWidth: '1em',
  },

  label: {
    padding: '0 16px',
  },

  filterItemSelected: {
    color: theme.palette.primary.main,
  },

  filterItemIcon: {
    width: 16,
    height: 16,
  },
}))
