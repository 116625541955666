import React from 'react'
import Media from 'components/AudioPlayer/Media'
import PlayPause from 'components/AudioPlayer/PlayPause'
import { Player } from 'react-media-player'
import Call from 'models/Call'
import useSidePanel from 'helpers/hooks/useSidePanel'

interface RecordingInterface {
  call: Call
  handleAudioPlayPause: (id: string) => void
  currentlyPlaying: string
}

export const Recording = ({
  call,
  handleAudioPlayPause,
  currentlyPlaying,
}: RecordingInterface) => {
  const { drawerOpen } = useSidePanel()
  return (
    <Media>
      <span>
        <span className="media-player">
          <Player src={call.callAudio} vendor={'audio'} />
        </span>
        <span className="media-controls">
          <PlayPause
            id={call.id}
            handleAudioPlayPause={handleAudioPlayPause}
            currentlyPlaying={currentlyPlaying}
            callDuration={call.callDuration}
            stopAudio={drawerOpen}
          />
        </span>
      </span>
    </Media>
  )
}
