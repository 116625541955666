import API, { DATA_API } from 'helpers/api'

interface deleteReviewCommentArgs {
  id: string
}

export const deleteReviewComment = async ({ id }: deleteReviewCommentArgs) => {
  await API.delete({
    api: DATA_API,
    url: `/designs/review-comments/${id}`,
  })
}
