import _get from 'lodash/get'
import Call, { repository as callsRepo } from 'models/Call'
import Appointment, { repository as appointmentsRepo } from 'models/Appointment'
import { has } from 'lodash'

const processCallData = (result: any) => {
  const call = new Call({
    id: result.Id,
    campaignTypeDetails: result.campaignTypeDetails
  })

  call.callDateTime = has(result, 'callDateTime.byTimeZone')
    ? result.callDateTime.byTimeZone
    : ''
  call.callAudio = result.Call_Audio__c
  call.callDuration = result.Duration__c
  call.callerPhoneNumber =
    result.phoneNumberPretty || result.Caller_Phone_Number__c
  call.callerPhoneNumberRaw = result.Caller_Phone_Number__c
  call.appointmentsCounter = result.Appointments_Counter__c
  call.outcome = result.callOutcome

  // Campaign info
  call.campaignId = _get(result, 'Tracking_Number__r.MVP_Campaign__c')
  call.campaignName = _get(result, 'Tracking_Number__r.MVP_Campaign__r.Name')
  call.campaignTargetDeliveryWeek = _get(
    result,
    'Tracking_Number__r.MVP_Campaign__r.Target_Delivery_Week__c'
  )
  call.campaignType = _get(
    result,
    'Tracking_Number__r.MVP_Campaign__r.Campaign_Type__c'
  )
  call.campaignMediaType = _get(result, 'MVP_Campaign__r.Media_Type__c')
  call.campaignLabel = _get(result, 'MVP_Campaign__r.Label__c')

  call.accountId = _get(result, 'Tracking_Number__r.MVP_Campaign__r.Account__c')

  call.offers = _get(result, 'offers.offerPlacements')
  call.accountOffers = _get(result, 'offers.accountOffers')
  call.insurances = _get(result, 'insurances')
  call.services = _get(result, 'services')

  // Appointments
  /**
   * @todo Normalize this later if possible
   */
  const appointments = _get(result, 'Appointments__r.records', [])
    .map((row: any) => {
      const appointment = new Appointment({
        id: row.Id,
      })

      appointment.code = row.Name
      appointment.dateTime = row.Date_Time__c
      appointment.firstName = row.Patient_First_Name__c
      appointment.lastName = row.Patient_Last_Name__c
      appointment.outcome = row.callOutcome
      appointment.previousAppointmentId = _get(
        row,
        'Previous_Appointment_Reference__r.Id'
      )
      appointment.contactId = _get(row, 'Contact__r.Id')
      appointment.contactName = _get(row, 'Contact__r.Name')
      appointment.contactEmail = _get(row, 'Contact__r.Email')

      return appointmentsRepo.upsert(appointment)
    })
    .reverse()

  call.appointments.replace(appointments)

  // Latest Score
  call.scoreId = _get(result, 'Latest_Score__r.Id')
  call.opportunity = _get(result, 'Latest_Score__r.Appointment_Opportunity__c')
  call.patientType = _get(result, 'Latest_Score__r.Patient_Type__c')
  call.callAnsweredBy = _get(result, 'Latest_Score__r.Call_Answered_By__c')
  call.ivrOutcome = _get(result, 'Latest_Score__r.IVR_Outcome__c')
  call.handlerName = _get(result, 'Latest_Score__r.Call_Handler__r.MVP_Name__c')
  call.callerName = _get(result, 'Latest_Score__r.Caller__r.Name')
  call.callerIdName = _get(result, 'Caller_ID_Name__c')
  call.sourceMentioned = _get(result, 'Latest_Score__r.Caller_Provided_Source__c')
  call.voicemailLeftMessage = _get(
    result,
    'Latest_Score__r.Voicemail_Left_Message__c'
  )
  call.voicemailWantsAppointment = _get(
    result,
    'Latest_Score__r.Voicemail_Wants_Appointment__c'
  )
  call.highlights = _get(result, 'Latest_Score__r.Highlights__c')
  call.followUpOpportunity = _get(
    result,
    'Latest_Score__r.Follow_up_Opportunity__c'
  )
  call.followUpReason = _get(result, 'Latest_Score__r.Follow_up_Reason__c')
  call.resolveDate = _get(result, 'Latest_Score__r.Resolved_Date__c')
  call.callbackHandler = _get(result, 'Latest_Score__r.Callback_Handler__c')
  call.callbackOutcomeOpportunity = _get(
    result,
    'Latest_Score__r.Callback_Outcome_Opportunity_Result__c'
  )
  call.callbackOutcomeResult = _get(
    result,
    'Latest_Score__r.Callback_Outcome_Result__c'
  )
  call.callbackOutcomeSummary = _get(
    result,
    'Latest_Score__r.Callback_Outcome_Summary__c'
  )
  call.nonOpportunityReason = _get(
    result,
    'Latest_Score__r.Non_opportunity_Reason__c'
  )
  call.noAppointmentScheduledReason = _get(
    result,
    'Latest_Score__r.No_Appointment_Scheduled_Reason__c'
  )

  // Related calls if available
  const related = result.related
  if (related) {
    // Only update relatedCalls property if we have actual related call records
    call.relatedCalls.replace(
      related.map((record: any) => {
        const call = processCallData(record)
        return callsRepo.upsert(call, { skipMergeOnProps: ['relatedCalls'] })
      })
    )
  }

  return call
}

export default processCallData
