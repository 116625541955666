import React, {
  useState,
  Fragment
} from 'react'
import { withRouter } from 'react-router'
import v from 'validator'
import { Warning } from '@material-ui/icons'
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

import loginFormStyle from '../../assets/jss/material-dashboard-react/loginFormStyle'
import CustomInputWithIcon from '../../components/CustomInput/CustomInputWithIcon'
import Button from '../../components/CustomButtons/Button'
import styles from './ResetPassword.styles'
import appState from '../../config/store/AppState'

const ErrorMessage = withStyles(styles)((props) => {
  const { classes, message } = props
  if (message) {
    return (
      <div className={`${classes.toastError} ${classes.slideDown}`} >
        <Warning/> {message}
      </div>
    )
  } else {
    return true
  }
})

const ResetPassword = (props) => {
  const { classes } = props

  const [ isLoading, setIsLoading ] = useState(false)
  const [ email, setEmail ] = useState('')
  const [ error, setError ] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    setIsLoading(true)
    setError(null)

    if(!v.isEmail(email)) {
      setIsLoading(false)
      setError('Invalid Email')
    } else {
      setIsLoading(true)
      try {
        await appState.resetPassword(email)
        props.history.push('/')
        appState.successMessage('Please check your email for the password reset link.')
      } catch (e) {
        const response = e.response
        if (response && response.data && response.data.message) {
          message = response.data.message
        }
        setIsLoading(false)
        setError('There is an error recovering your password. Please try again later.')
      }
    }
  }

  const handleChangeEmail = event => {
    setEmail(event.target.value)
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Fragment>
          <div className={classes.formContainer}>
            <form
              className={classes.form}
              onSubmit={handleSubmit}
            >
              <h3 className={classes.header}>FORGOT PASSWORD?</h3>
              <div className={classes.setupAccountBody}>
                <div className={classes.emailDetailsWrapper}>
                  <p className={classes.emalInstruction}>Enter your email address to reset your password. You may need to check your spam folder or unblock info@mvpmailhouse.com.</p>
                </div>
                <h3 className={classes.setPassword}>Email</h3>
                <CustomInputWithIcon
                  type='text'
                  id="email"
                  placeholder='youremail@example.com'
                  icon={<loginFormStyle.icons.Mail className={classes.icon} />}
                  onChange={handleChangeEmail}
                  formControlProps={{
                    fullWidth: true
                  }}
                />

                <Button
                  classes={{
                    button: `${classes.submitButton} ${classes.btn}`,
                    disabled: classes.submitButtonDisabled
                  }}
                  type='submit'
                  color="primary"
                  fullWidth
                  disabled={!email || isLoading}
                >
                  <span style={{
                    textTransform: 'none',
                    display: 'inline-flex',
                    alignItems: 'center'
                  }}>
                    {isLoading ? 'Sending Password Recovery' : 'Recover Password'}
                    {
                      isLoading ?
                        <CircularProgress size={14} style={{ marginLeft: 5, color: '#fff', opacity: 0.9 }} /> :
                        null
                    }
                  </span>
                </Button>
              </div>
            </form>
            <ErrorMessage message={error} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}

export default withRouter(withStyles(styles)(ResetPassword))