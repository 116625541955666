import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Select from 'react-select'
import cx from 'classnames'
import tooltip from 'src/assets/jss/material-dashboard-react/toolTip'

import {
  defaultFont
} from 'src/assets/jss/mvp'

const styles = theme => ({
	tooltip: {
		...tooltip
  },
  tooltipNoPointer: {
    '&:before': {
      display: 'none'
    }
  }
})

@withStyles(styles)
export default class MVPTooltip extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedOption: '',
    }

  }

  render() {
    const { classes, caption, customStyle, direction, noPointer, className } = this.props
    return (
      <label
        className={`${classes.tooltip} ${className}
          ${noPointer ? classes.tooltipNoPointer : ''}
          `}
        style={{...customStyle}}>
				{caption}
			</label>
    )
  }
}

