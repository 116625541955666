import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'constants/constants'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row-reverse',
    height: '100vh',
  },
  contentWrapper: {
    position: 'relative',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '40rem',
  },
  mobileContainer: {
    minWidth: '100%',
  },
  closeWrapper: {
    padding: '0px',
    position: 'absolute',
    right: '16px',
    top: '13px',
    opacity: '.5',
  },
  header: {
    flex: '0 0 auto',
    backgroundColor: '#232323',
    display: 'flex',
    alignItems: 'center',
    padding: '18px 22px',
    color: '#fff',
    position: 'relative',
    '& h1': {
      flex: '0 0 auto',
      fontSize: '20px',
      margin: 0,
      marginRight: 5,
    },
    '& h1 + span': {
      flex: '0 0 auto',
      fontWeight: '600',
      fontSize: '20px',
      margin: 0,
    },
  },
  body: {
    display: 'flex',
    flex: '1 1 auto',
    fontFamily: 'Roboto',
  },
  bodyWrapper: {
    flex: '1 1 auto',
  },
  footer: {
    flex: '0 0 auto',
  },
  close: { padding: 0 },
  closeIcon: {
    color: '#fff',
  },
  loading: {
    filter: 'blur(2px)',
  },
  loader: {
    transition: '0.3s ease opacity',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    position: 'absolute',
    padding: 0,
    margin: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  loaderContent: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  loaderIcon: {},
  loaderCaption: {
    marginTop: 15,
    fontWeight: 400,
    fontSize: '18px',
    color: '#666',
  },

  title: {
    color: COLORS.neutral.shade500,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 20,
    minHeight: 43,
    background: COLORS.neutral.shade50,
    fontWeight: 'bold',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  approveBtn: {
    width: '100%',
    textTransform: 'capitalize',
    fontSize: '22px',
    borderRadius: '0px',
    padding: '15px',
  },
  modalOverlay: {
    position: 'absolute',
    top: '0px',
    background: 'rgba(255, 255, 255, .94)',
    height: '100%',
    width: '100%',
    'z-index': '999',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h3': {
      fontFamily: 'Roboto',
      fontWeight: '600',
      fontSize: '20px',
    },
    '& p': {
      fontFamily: 'Roboto',
      width: '220px',
      fontSize: '16px',
      margin: '0 auto',
      lineHeight: '1.3',
      marginBottom: '40px',
      marginTop: '10px',
    },
    '& button': {
      width: '220px',
      margin: '0 auto',
      borderRadius: '0px',
      marginBottom: '14px',
      textTransform: 'capitalize',
    },
  },
})
