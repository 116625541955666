export function getEnv(key: string) {
  return process.env[key]
}

export function getAppEnv(key: string) {
  return getEnv(`REACT_APP_${key}`)
}

export default {
  app: {
    baseUrl: getAppEnv('BASEURL'),
  },

  auth0: {
    clientIdPasswordless: getAppEnv('AUTH0_CLIENT_ID_PASSWORDLESS'),
    clientId: getAppEnv('AUTH0_CLIENT_ID'),
    domain: getAppEnv('AUTH0_DOMAIN'),
    audience: getAppEnv('AUTH0_AUDIENCE'),
    realm: getAppEnv('AUTH0_REALM'),
    scopes: getAppEnv('AUTH0_SCOPES'),
    realmLimited: getAppEnv('AUTH0_REALM_LIMITED'),
  },

  convertApi: {
    secret: getAppEnv('CONVERT_API_SECRET')
  },

  sentry: {
    dsn: getAppEnv('SENTRY_DSN'),
  },

  services: {
    maps: {
      baseUrl: getAppEnv('MAPS_URL'),
    },
    identity: {
      baseUrl: getAppEnv('IDENTITY_URL'),
    },
    data: {
      baseUrl: getAppEnv('DATA_URL'),
    },
    progress: {
      baseUrl: getAppEnv('PROGRESS_URL'),
    },
  },
}
