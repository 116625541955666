import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import cx from 'classnames'

import { observer } from 'mobx-react'
import { useStyles } from './MailingDesign.styles'
import { useStores } from 'config/store'

import mailingPlaceholder from 'src/assets/img/mvp-logo.png'
import { MailingDesignPreview } from './MailingDesignPreview'

interface IMailingDesign {}

export const MailingDesign = (props: IMailingDesign) => {
  const classes = useStyles()
  const { mailingsStore } = useStores()
  const mailing = mailingsStore.drawer

  if (!mailing) {
    return <div />
  }

  const [design, setDesign] = useState({
    frontDesignImageUrl: mailing.frontDesignThumbnailUrl,
    backDesignImageUrl: mailing.backDesignThumbnailUrl,
    isFailedFront: false,
    isFailedBack: false,
    isFrontLoaded: false,
    isBackLoaded: false,
    showPreview: false,
    previewFace: '',
  })

  const {
    frontDesignImageUrl,
    backDesignImageUrl,
    isFailedFront,
    isFailedBack,
    isFrontLoaded,
    isBackLoaded,
    showPreview,
    previewFace,
  } = design

  const handleMissingDesignFront = () => {
    setDesign({
      ...design,
      isFailedFront: true,
      isFrontLoaded: true,
    })
  }

  const handleMissingDesignBack = () => {
    setDesign({
      ...design,
      isFailedBack: true,
      isBackLoaded: true,
    })
  }

  const handleDesignFrontLoaded = () => {
    setDesign({
      ...design,
      isFrontLoaded: true,
    })
  }

  const handleDesignBackLoaded = () => {
    setDesign({
      ...design,
      isBackLoaded: true,
    })
  }

  const handleClosePreview = () => {
    setDesign({
      ...design,
      showPreview: false,
    })
  }

  const handleFrontPreview = () => {
    setDesign({
      ...design,
      previewFace: 'front',
      showPreview: true,
    })
  }

  const handleBackPreview = () => {
    setDesign({
      ...design,
      previewFace: 'back',
      showPreview: true,
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.thumbWrapper}>
        {isFrontLoaded ? (
          <></>
        ) : (
          <CircularProgress
            className={classes.loader}
            size={50}
            color="primary"
          />
        )}
        <img
          onClick={() => {
            if (!isFailedFront || frontDesignImageUrl !== undefined) {
              handleFrontPreview()
            }
          }}
          onError={handleMissingDesignFront}
          onLoad={handleDesignFrontLoaded}
          className={cx(classes.designImg, {
            [classes.mvpImagePlaceholder]:
              isFailedFront || frontDesignImageUrl === undefined,
          })}
          src={
            isFailedFront || frontDesignImageUrl === undefined
              ? mailingPlaceholder
              : frontDesignImageUrl
          }
        />
      </div>
      {mailing.campaignType !== 'CallPro' ? (
        <div className={classes.thumbWrapper}>
          {isBackLoaded ? (
            <></>
          ) : (
            <CircularProgress
              className={classes.loader}
              size={50}
              color="primary"
            />
          )}
          <img
            style={{
              cursor:
                !isFailedBack && backDesignImageUrl !== undefined
                  ? 'pointer'
                  : 'default',
            }}
            onClick={() => {
              if (!isFailedBack && backDesignImageUrl !== undefined) {
                handleBackPreview()
              }
            }}
            onError={handleMissingDesignBack}
            onLoad={handleDesignBackLoaded}
            className={cx(classes.designImg, {
              [classes.mvpImagePlaceholder]:
                isFailedBack || backDesignImageUrl === undefined,
            })}
            src={
              isFailedBack || backDesignImageUrl === undefined
                ? mailingPlaceholder
                : backDesignImageUrl
            }
          />
        </div>
      ) : null}

      {showPreview ? (
        <MailingDesignPreview
          previewFace={previewFace}
          closePreview={handleClosePreview}
          isSingleDesign={mailing.campaignType === 'CallPro'}
        />
      ) : null}
    </div>
  )
}

export default observer(MailingDesign)
