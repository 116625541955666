// @flow

import _isEmpty from 'lodash/isEmpty'

import API, { DATA_API } from 'helpers/api'
import { repository as appointmentRepo } from 'src/models/Appointment'

import accountStore from 'config/store/AccountStore'
import Appointment from '../../models/Appointment'
import _ from 'lodash'

/**
 *
 * @param {object} options
 */
export default async (_filters: any) => {
  const { page, pageSize, sort, dir, filters, searchQuery } = _filters
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    return {
      meta: {},
      records: [],
    }
  }

  const data = {
    accountIds,
    page,
    pageSize,
    sort,
    dir,
    filters,
    query: searchQuery,
  }

  const result = await API.get({
    api: DATA_API,
    url: '/appointments',
    params: data,
  })

  const { meta, records } = result

  const appointments = records.map((row: any) => {
    const appointment = new Appointment({
      id: row.Id,
    })

    appointment.firstName = _.get(row, 'Patient_First_Name__c')
    appointment.lastName = _.get(row, 'Patient_Last_Name__c')
    appointment.dateTime = _.get(row, 'Date_Time__c')
    appointment.answeredBy = _.get(
      row,
      'Call__r.Latest_Score__r.Call_Answered_By__c'
    )
    appointment.callHandler = _.get(
      row,
      'Call__r.Latest_Score__r.Call_Handler__r.MVP_Name__c'
    )
    appointment.callSummary = _.get(
      row,
      'Call__r.Latest_Score__r.Highlights__c'
    )
    appointment.outcome = _.get(row, 'callOutcome')
    appointment.callDuration = _.get(row, 'Call__r.Duration__c')
    appointment.callId = _.get(row, 'Call__r.Id')
    appointment.phoneNumber = _.get(row, 'Phone_Number__c')
    appointment.callAudioURL = _.get(row, 'Call__r.Call_Audio__c')
    appointment.callResolveDate = _.get(
      row,
      'Call__r.Latest_Score__r.Resolved_Date__c'
    )
    appointment.count = _.get(row, 'Call__r.Appointments_Counter__c')

    appointment.campaignTargetDeliveryWeek = _.get(
      row,
      'Call__r.MVP_Campaign__r.Target_Delivery_Week__c'
    )
    appointment.campaignType = _.get(
      row,
      'Call__r.MVP_Campaign__r.Campaign_Type__c'
    )

    return appointmentRepo.upsert(appointment)
  })

  return {
    meta: {
      totalCount: meta.total_count ?? 0,
      pageCount: meta.page_count ?? 0,
      perPage: meta.per_page ?? 0,
      page: meta.page ?? 0,
    },
    records: appointments,
  }
}
