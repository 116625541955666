import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  // OVERRIDE CARD
  cardRoot: {
    position: 'relative',
  },

  searchFilter: {},
  filtersMobile: {
    '& $searchInput': {
      margin: '0px 10px',
    },
    '& $searchFilter': {
      margin: '0px 10px 20px',
    },
  },

  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },

  // SEARCH

  searchInput: {
    display: 'flex',
    marginBottom: 16,
  },

  titleWrapper: {
    display: 'flex',
    '& > div': {
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'initial',
      alignItems: 'center',
      gap: '0.325rem',
      cursor: 'help',
    },
  },

  tooltip: {
    backgroundColor: '#222',
    '&::after': {
      display: 'none',
    },
  },
  grade_icon: {
    color: '#d3d3d3',
    verticalAlign: 'middle',
  },
}))
