import {
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
} from '@material-ui/core'
import {
  MoreVert,
} from '@material-ui/icons'
import DownloadCsv from '../../Icons/DownloadCsv'
import { withStyles } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import Star from '../../../assets/img/patientList/patientMarkers/star.svg'
import CircularCheck from '../../../assets/img/patientList/patientMarkers/check.svg'
import numeral from 'numeral'
import React, {
  useState,
  Fragment
} from 'react'
import moment from 'moment'
import env from '../../../env'

import PatientListItemHover from './PatientListItemHover'
import getPatientIconByHex from '../../PatientList/PatientListIconSet'

import { repository as patientListRepo } from 'src/models/PatientList'
import { repository as layerRepo } from 'src/models/PatientListLayer'
import { PatientColors } from '../../PatientList/PatientListIconSet'


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    marginBottom: 0,
    marginTop: 0,
    padding: 0,
    '& >span': {
      width: '20px',
      height: '20px'
    },
    '&:before': {
      borderBottom: 'solid 1px rgb(236,235,236)',
      background: 'none'
    }
  },
  wrapper: {
    width: '100%',
    minHeight: '60px !important',
    paddingLeft: 6,


    position: 'relative',
    padding: '0 0 0 6px',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: '6px 0'
    }
  },
  summary: {
    display: 'flex',
    // flex: '1 1 0',
    flexDirection: 'column',
    minWidth: 0,
    overflow: 'hidden',
    marginLeft: 0,
    flex: 1,
    '& h1': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    '& h5': {
      margin: '0',
      fontSize: '15px',
      fontWeight: 'initial',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    '& span': {
      fontSize: '12px',
      color: 'rgb(136,136,136)'
    }
  },
  colorWrapper: {
    position: 'relative',
    flex:'0 0 44px',
    display: 'flex',
    marginRight: 8,
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    backgroundColor: 'transparent',
    padding: 17,
    boxShadow: '0 0'
  },
  auditSummary: {
    display: 'flex',
    '& span': {
      display: 'flex',
      alignItems: 'center'
    },
    '& span:before': {
      content: '"•"',
      marginLeft: 4,
      marginRight: 4,
      fontSize: 'large',
      color: '#d2d2d2'
    },
    '& img': {
      marginRight: 4,
      width: 17
    }
  },
  option: {
    marginLeft: 8
  },
  listItemIcon: {
    minWidth: 32
  }
})

const PatientListItem = (props) => {
  const { classes, id } = props
  const patientList = patientListRepo.get(id)
  const patientListLayer = layerRepo.get(id)
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuRef, setMenuRef] = useState(undefined)
  const [isShowMenu, setShowMenu] = useState(false)

  const handlePopoverOpen = (e) => {
    setOpen(true)
    setAnchorEl(e.currentTarget)
  }

  const handlePopoverClose = () => {
    setOpen(false)
  }

  const handleToggleCheckbox = (id) => () => {
    const layer = layerRepo.get(id)
    layer && layer.toggleVisibility()
  }

  return (
    <Observer
      render={() => {
        const { summary, audit, getAuditSummary } = patientList
        const { visible, icon } = patientListLayer
        const name = patientList.name || 'Patient List'
        const quantity = (summary && summary.patientCount) || 0
        const auditResult = audit

        let strongMatchesPercentage = 0
        let weakMatchesPercentage = 0
        if (auditResult || getAuditSummary.length) {
          if (quantity && getAuditSummary.strongMatches.total) {
            strongMatchesPercentage = getAuditSummary.strongMatches.total / quantity
          }
          if (quantity && getAuditSummary.weakMatches.total) {
            weakMatchesPercentage = getAuditSummary.weakMatches.total / quantity
          }
        }
        return (
          <Fragment>
            { auditResult &&
              <Popover
                className={classes.popover}
                classes={{
                  paper: classes.paper
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                onClose={handlePopoverClose}
              >
                <PatientListItemHover
                  auditResult={auditResult}
                  patientList={patientList}
                />
              </Popover>
            }
            <div
              className={classes.container}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <div className={classes.wrapper}>
                <Checkbox
                  color='primary'
                  checked={visible}
                  onClick={handleToggleCheckbox(id)}
                  value={id}
                />
                <div className={classes.summary}>
                  <h5>{name}</h5>
                  <span className={classes.auditSummary}>
                    {numeral(quantity).format('0,0')} Patients
                    { auditResult &&
                      <React.Fragment>
                        <span><img src={Star}/>{numeral(strongMatchesPercentage).format('0%')}</span>
                        <span><img src={CircularCheck}/>{numeral(weakMatchesPercentage).format('0%')}</span>
                      </React.Fragment>
                    }
                  </span>
                </div>
                <div className={classes.colorWrapper}>
                  <img src={getPatientIconByHex(icon, false, false)}/>
                  { auditResult &&
                    <div className={classes.option} ref={setMenuRef}>
                      <IconButton
                        aria-label='More'
                        aria-owns={isShowMenu ? 'long-menu' : undefined}
                        aria-haspopup='true'
                        onClick={event => {
                          event.stopPropagation()
                          event.preventDefault()
                          setShowMenu(true)
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id='long-menu'
                        anchorEl={menuRef}
                        open={isShowMenu}
                        onClose={event => {
                          event.stopPropagation()
                          event.preventDefault()
                          setShowMenu(false)
                        }}
                        PaperProps={{
                          style: {
                            width: 160
                          }
                        }}
                      >
                        <MenuItem
                          key='audit-download'
                          onClick={event => {
                            event.stopPropagation()
                            event.preventDefault()
                            setShowMenu(false)
                            window.location.href = `${env.services.maps.baseUrl}/patient-lists/${id}.csv`
                          }}
                          disabled={false}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <DownloadCsv/>
                          </ListItemIcon>
                          <ListItemText primary={'Download CSV'} />
                        </MenuItem>
                      </Menu>
                    </div>
                  }
                </div>
              </div>
            </div>
          </Fragment>
        )
      }}
    />
  )
}

export default withStyles(styles)(PatientListItem)
