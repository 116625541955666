import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { Switch, CircularProgress, Button, Dialog } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Dropdown from 'components/MVP/Dropdown/Dropdown'
import { useStyles } from './ManageReceivers.style'
import { NotificationSettingsData } from '../AlertType/AlertType'
import kebabCase from 'lodash/kebabCase'
import AppState from 'config/store/AppState'
interface ManageReceiversProps {
  alertType: string
  openDialog: boolean
  allNotificationSettings: Map<string, NotificationSettingsData>
  setAllUpdatedSettings: Dispatch<SetStateAction<Map<string, NotificationSettingsData>>>
  allUpdatedSettings: Map<string, NotificationSettingsData>
  setOpenDialog: Dispatch<SetStateAction<boolean>>
  setConfirmCancel: (isOpen: boolean) => void
  status: string
  setCurrentlyUpdatedReceiversSetting: Dispatch<SetStateAction<Map<string, NotificationSettingsData>>>
  currentlyUpdatedReceiversSetting: Map<string, NotificationSettingsData>
}

export const alertTypeDefaultFrequency: { [key: string]: string } = {
  'Performance Breakdown Alert': 'Weekly',
  'Missed Call Alert': 'Real-time',
  'En Route Alert': 'Weekly',
  'First Opportunity Alert': 'Real-time',
  'New Appointment Call Alert': 'Real-time',
  'New Opportunity Call Alert': 'Real-time'
}

export const alertTypeFrequencyOptions: {[key: string]: string[]} = {
  'Performance Breakdown Alert': ['Daily', 'Weekly'],
  'Missed Call Alert': ['Real-time', 'Daily', 'Weekly'],
  'En Route Alert': ['Weekly'],
  'First Opportunity Alert': ['Real-time'],
  'New Appointment Call Alert': ['Real-time', 'Daily', 'Weekly'],
  'New Opportunity Call Alert': ['Real-time', 'Daily', 'Weekly'],
}

const ManageReceivers: React.FC<ManageReceiversProps> = ({ 
  alertType, 
  openDialog, 
  allNotificationSettings,
  setAllUpdatedSettings,
  allUpdatedSettings,
  setOpenDialog,
  setConfirmCancel,
  status,
  setCurrentlyUpdatedReceiversSetting,
  currentlyUpdatedReceiversSetting
}) => {
  const classes = useStyles()

  const filterMap = (_map: Map<string, NotificationSettingsData>, alertType: string) => {
    return new Map(
      Array.from(_map).filter(([_key, value]) => {
        if (value.alertType === alertType && value.contactId !== AppState.contactId) {
          return true
        }
    
        return false
      })
    )
  }

  const alertTypeOptions = alertTypeFrequencyOptions[alertType]
  const receiversData = new Map([...filterMap(allNotificationSettings, alertType), ...filterMap(allUpdatedSettings, alertType)])
  const currentReceiverSettings = new Map([...receiversData, ...currentlyUpdatedReceiversSetting])

  const handleApply = () => {
    setAllUpdatedSettings(new Map([...allUpdatedSettings, ...currentlyUpdatedReceiversSetting]))
    setOpenDialog(false)
  }

  const onReceiverFrequencyChange = (value: NotificationSettingsData, frequency: string) => {
    const key = kebabCase(alertType + '-' + value.contactId)
    const _value = {...value, frequency: frequency}
    setCurrentlyUpdatedReceiversSetting(new Map(currentlyUpdatedReceiversSetting.set(key, {...value, ..._value})))
  }

  const onReceiverEmailChange = (value: NotificationSettingsData, checked: boolean) => {
    const key = kebabCase(alertType + '-' + value.contactId)
    const _value = {...value, email: checked}
    setCurrentlyUpdatedReceiversSetting(new Map(currentlyUpdatedReceiversSetting.set(key, {...value, ..._value})))
  }

  const onReceiverSmsChange = (value: NotificationSettingsData, checked: boolean) => {
    const key = kebabCase(alertType + '-' + value.contactId)
    const _value = {...value, sms: checked}
    setCurrentlyUpdatedReceiversSetting(new Map(currentlyUpdatedReceiversSetting.set(key, {...value, ..._value})))
  }

  return (
    <>
      <Dialog open={openDialog} fullWidth={true} maxWidth='md'>
        <MuiDialogTitle>
          <h4 className={classes.modalTitle}>{`${alertType} Receivers`}</h4>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Body 
            status={status}
            contacts={currentReceiverSettings} 
            onReceiverFrequencyChange={onReceiverFrequencyChange}
            onReceiverEmailChange={onReceiverEmailChange}
            onReceiverSmsChange={onReceiverSmsChange}
            alertTypeOptions={alertTypeOptions}
            alertType={alertType}
          />
        </MuiDialogContent>
        <MuiDialogActions>
          <Footer 
            handleApply={handleApply} 
            setConfirmCancel={setConfirmCancel}
          />
        </MuiDialogActions>
      </Dialog>
    </>
  )
}

type BodyProps = {
  contacts: Map<string, NotificationSettingsData>
  onReceiverFrequencyChange: (value: NotificationSettingsData, frequency: string) => void
  onReceiverEmailChange: (value: NotificationSettingsData, checked: boolean) => void
  onReceiverSmsChange: (value: NotificationSettingsData, checked: boolean) => void
  status: string
  alertTypeOptions: string[]
  alertType: string
}
const Body = ({ 
  contacts, 
  onReceiverFrequencyChange, 
  onReceiverEmailChange, 
  onReceiverSmsChange, 
  status, 
  alertTypeOptions,
  alertType }: BodyProps) => {
  const classes = useStyles()

  if (status === 'loading') {
    return (
      <div>
        <CircularProgress size={14} className={classes.spinner} />
      </div>
    )
  }

  if (status === 'error') {
    return <div>Error fetching data</div>
  }

  return (
    <div className={classes.tableWrapper}>
      <div className={classes.tableHeader}>
        <div>Staff</div>
        <div>Receive alert from</div>
        <div>Frequency</div>
      </div>
      {
        Array.from(contacts.values()).map((value: NotificationSettingsData) => {
          return (
          <>
          <div className={classes.tableRow}>
            <div className={classes.col}>
              <label>
                {value.name}{' '}
              </label>
            </div>
            <div className={classes.col}>
              <div className={classes.switchWrapper}>
                <Switch
                  checked={value.email}
                  color="primary"
                  name="contact_email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onReceiverEmailChange(value, e.target.checked)}
                />
                <label>Email</label>
              </div>
              <div className={classes.switchWrapper}>
                <Switch
                  checked={value.sms}
                  color="primary"
                  name="contact_sms"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onReceiverSmsChange(value, e.target.checked)}
                />
                <label>SMS</label>
              </div>
            </div>
            <div className={classes.col}>
              <Dropdown
                name="contact_frequency"
                value={value.frequency === '' ? alertTypeDefaultFrequency[alertType] : value.frequency}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onReceiverFrequencyChange(value, e.target.value)}
                options={alertTypeOptions ?? ['Real-time', 'Daily', 'Weekly']}
                placeholder="Choose Frequency"
              />
            </div>
          </div>
          </>
          )
        })
      }
    </div>
  )
}

type FooterProps = {
  handleApply: () => void
  setConfirmCancel: (isOpen: boolean) => void
}

const Footer = ({ handleApply, setConfirmCancel }: FooterProps) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.footerActions}>
        <Button
        onClick={() => setConfirmCancel(true)}
        variant="outlined"
        color="primary"
        style={{ marginRight: 8 }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleApply}
          variant="contained"
          color="primary"
        >
          Apply
        </Button>
      </div>
    </>
  )
}

export default ManageReceivers