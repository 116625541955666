import React, { useState } from 'react'
import { DialogActions, DialogContent, Typography } from '@material-ui/core'

import useStyles from './DownloadCallsDialog.style'
import useFilters from 'components/CallPro/DownloadCallsButton/hooks/useFilters'
import useActions from 'components/CallPro/DownloadCallsButton/hooks/useActions'
import Dialog from 'components/CallPro/DownloadCallsButton/components/Dialog'
import DialogTitle from 'components/CallPro/DownloadCallsButton/components/DialogTitle'
import DownloadFilters from 'components/CallPro/DownloadCallsButton/components/DownloadFilters'
import DownloadCheckboxes from 'components/CallPro/DownloadCallsButton/components/DownloadCheckboxes'
import DialogActionButton from 'components/CallPro/DownloadCallsButton/components/DialogActionButton'
import useCurrentUser from 'components/CallPro/DownloadCallsButton/hooks/useCurrentUser'
import AlertError from 'components/CallPro/DownloadCallsButton/components/Alert'

type Prop = {
  open?: boolean
  requestTooLarge?: boolean
  setOpen?: (open: boolean) => void
}

export default function DownloadCallsDialog({
  open = false,
  requestTooLarge = false,
  setOpen = (_) => {},
}: Prop) {
  const styles = useStyles()
  const filters = useFilters()
  const { profileEmail } = useCurrentUser()
  const { directDownloadAction, requestLargeDataAction, flashSuccessMessage } =
    useActions()

  const [checked, setChecked] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const handleClose = () => {
    if (!submitting) {
      setOpen(false)
      setError('')
      setChecked(false)
    }
  }

  const handleCheck = (value: boolean) => setChecked(value)

  const handleDirectDownloadClick = async () => {
    setError('')
    setSubmitting(true)
    try {
      await directDownloadAction()
      setSubmitting(false)
      setOpen(false)
      setChecked(false)
    } catch (e) {
      console.error(e)
      setSubmitting(false)
      setError('Download failed. Please try again.')
    }
  }

  const handleRequestLargeDataClick = async () => {
    setError('')
    setSubmitting(true)
    try {
      await requestLargeDataAction()
      flashSuccessMessage(
        'Request sent successfully. Please expect an email from your marketing coordinator.'
      )
      setSubmitting(false)
      setOpen(false)
      setChecked(false)
    } catch (e) {
      console.error(e)
      setSubmitting(false)
      setError('Download request failed. Please try again.')
    }
  }

  const handleAlertErrorClose = () => setError('')

  return (
    <>
      <Dialog open={open} setOpen={setOpen} escapable={!submitting}>
        <DialogTitle onClose={handleClose}>Download</DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <Typography component="div" className={styles.headerMessage}>
            {requestTooLarge ? (
              <>
                <strong>Data is too large for direct download.</strong> We will
                <br /> instead send the data to{' '}
                <Typography color="primary" component="div">
                  <strong>{profileEmail}</strong>
                </Typography>
              </>
            ) : (
              <>
                You can download your call data by clicking Direct Download
                button below.
              </>
            )}
          </Typography>
          <DownloadFilters filters={filters} />
          <AlertError error={error} onClose={handleAlertErrorClose} />
          <DownloadCheckboxes setChecked={handleCheck} />
        </DialogContent>
        <DialogActions className={styles.dialogActionContainer}>
          <DialogActionButton
            checked={checked}
            submitting={submitting}
            onClick={
              requestTooLarge
                ? handleRequestLargeDataClick
                : handleDirectDownloadClick
            }
          >
            {requestTooLarge ? 'Request' : 'Direct Download'}
          </DialogActionButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
