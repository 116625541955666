import React from 'react'
import { Observer } from 'mobx-react'
import {
  Checkbox,
  createStyles,
  DialogContent,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Paper,
  Tooltip,
} from '@material-ui/core'
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons'

import { useCallTableFilterStore } from '../../store/CallTableFilterStore'

type Prop = {}

export default function FilterList({}: Prop) {
  const styles = useStyles()
  const store = useCallTableFilterStore()
  const { visibleFilters } = store

  const handleCheck =
    (key: string) =>
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      store.toggleFilter(key, checked)
    }

  return (
    <Paper component={DialogContent} className={styles.root}>
      <FormGroup>
        <Observer
          render={() => {
            const fields = visibleFilters.map((filter) => {
              const key = filter?.id
              const label = filter?.label ?? 'N/A'
              const active = filter?.active ?? false
              const disabled = filter?.disabled ?? false
              const message = filter?.message ?? ''

              return (
                <FilterFieldTooltip key={key} message={message}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={active}
                        onChange={handleCheck(key)}
                        name={key}
                        color="primary"
                      />
                    }
                    label={
                      Boolean(message) ? (
                        <div className={styles.label}>
                          {label}
                          <InfoOutlinedIcon className={styles.grade_icon} />
                        </div>
                      ) : (
                        label
                      )
                    }
                    disabled={disabled}
                  />
                </FilterFieldTooltip>
              )
            })
            return <>{fields}</>
          }}
        />
      </FormGroup>
    </Paper>
  )
}

function FilterFieldTooltip(prop: {
  children: JSX.Element
  message: string | JSX.Element
}) {
  const styles = useStyles()
  const hidden = !Boolean(prop.message)

  if (hidden) {
    return prop.children
  }

  return (
    <Tooltip
      title={prop.message}
      classes={{
        tooltip: styles.tooltip,
        arrow: styles.tooltipArrow,
      }}
      arrow
    >
      {prop.children}
    </Tooltip>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(0.5),
    },
    label: {
      display: 'flex',
      gap: '0.325rem',
    },
    tooltip: {
      backgroundColor: '#222',
      margin: 0,
      '&::after': { display: 'none' },
    },
    tooltipArrow: {
      '&::before': { backgroundColor: '#222' },
    },
    grade_icon: { color: '#d3d3d3' },
  })
)
