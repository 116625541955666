import React, { useEffect, useState } from 'react'
import { Box, Divider } from '@material-ui/core'

import SearchField from './components/SearchField'
import CollapsibleContainer from './components/Container'
import OfferFilterField from './components/FilterField/OfferFilterField'
import ServiceFilterField from './components/FilterField/ServiceFilterField'
import InsuranceFilterField from './components/FilterField/InsuranceFilterField'
import NonOpportunityReasonFilterField from './components/FilterField/NonOpportunityReasonFilterField'
import CallAnsweredByFilterField from './components/FilterField/CallAnsweredByFilterField'
import PaymentTypeFilterField from './components/FilterField/PaymentTypeFilterField'
import CallerSourceFilterField from './components/FilterField/CallerSourceFilterField'
import VoiceMailFilterField from './components/FilterField/VoiceMailFilterField'
import OnHoldFilterField from './components/FilterField/OnHoldFilterField'
import { ResetFilterButton, AddFilterButton } from './components/Buttons'
import AddFilterDialog from './components/AddFilterDialog/AddFilterDialog'

import { useStores } from 'config/store'
import { useCallTableFilterStore } from './store/CallTableFilterStore'
import CallerGenderFilterField from 'components/CallPro/CallTableFilters/components/FilterField/CallerGenderFilterField'
import NeedsFollowUpReasonFilterField from 'components/CallPro/CallTableFilters/components/FilterField/NeedsFollowUpReasonFilterField'
import NoAppointmentWasScheduledReasonFilterField from 'components/CallPro/CallTableFilters/components/FilterField/NoAppointmentWasScheduledReasonFilterField'
import IVROutcomeFilterField from 'components/CallPro/CallTableFilters/components/FilterField/IVROutcomeFilterField'
import CallerLanguageFilterField from 'components/CallPro/CallTableFilters/components/FilterField/CallerLanguageFilterField'
import PatientTypeFilterField from 'components/CallPro/CallTableFilters/components/FilterField/PatientTypeFilterField'
import MediaTypeFilterField from 'components/CallPro/CallTableFilters/components/FilterField/MediaTypeFilterField'
import CampaignTypeFilterField from './components/FilterField/CampaignTypeFilterField'
import WrongSourceMentioned from './components/FilterField/WrongSourceMentionedFilterField'

type Prop = {}

export default function CallTableFilters({}: Prop) {
  const { accountStore, globalFilterStore } = useStores()
  const { selectedAccounts } = accountStore
  const { campaignFilter, dateFilter } = globalFilterStore
  const store = useCallTableFilterStore()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    store && store.reset()
    setLoaded(true)
  }, [
    store,
    selectedAccounts,
    campaignFilter.campaignFilterType,
    dateFilter.dateFilterType,
    dateFilter.dateRange,
  ])

  return (
    <Box px={2} pb={2}>
      {loaded ? (
        <>
          <SearchField />
          <CollapsibleContainer>
            <OfferFilterField />
            <ServiceFilterField />
            <InsuranceFilterField />
            <NonOpportunityReasonFilterField />
            <CampaignTypeFilterField />
            <CallAnsweredByFilterField />
            <CallerGenderFilterField />
            <PaymentTypeFilterField />
            <CallerSourceFilterField />
            <MediaTypeFilterField />
            <NeedsFollowUpReasonFilterField />
            <NoAppointmentWasScheduledReasonFilterField />
            <IVROutcomeFilterField />
            <VoiceMailFilterField />
            <CallerLanguageFilterField />
            <PatientTypeFilterField />
            <WrongSourceMentioned />
            <OnHoldFilterField />
            <ResetFilterButton />
            <Divider orientation="vertical" flexItem />
            <AddFilterButton />
          </CollapsibleContainer>
          <AddFilterDialog />
        </>
      ) : null}
    </Box>
  )
}
