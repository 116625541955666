import { observable, action, computed, runInAction } from 'mobx'
import { repository as offerInquiry } from 'models/offers/OfferInquiry'
import getOfferInquiries from 'services/offers/getOfferInquiries'
import dashboardUIStore from '../DashboardUIStore'

interface OfferInquiriesBarChart {
  id: string
  name: string
  value: number
}

export class OfferStore {
  @observable offerStart: string = ''
  @observable offerInquiriesLoading: boolean = false

  @observable private offerInquiryIds = observable.array<string>([])

  // --- ACTION w/ SIDE EFFECTS - Refresher --- //

  @action
  async refreshOverviewTab({ reset = false } = {}) {
    if (reset) {
      this.reset()
    }

    const filters = dashboardUIStore.globalFiltersQuery
    await this.fetchOfferInquiries({ filters })
  }

  // --- ACTION w/ SIDE EFFECTS --- //
  @action
  async fetchOfferInquiries(options: {} = {}) {
    this.offerInquiriesLoading = true

    const data = await getOfferInquiries(options)
    const { records, startRecordDate } = data

    runInAction(() => {
      if (records) {
        this.offerInquiryIds.replace(records)
      }

      this.offerStart = startRecordDate
      this.offerInquiriesLoading = false
    })
  }

  // --- ACTION w/o SIDE EFFECTS --- //
  @action
  private reset() {
    this.offerStart = ''
    this.offerInquiriesLoading = false
  }

  // --- COMPUTED --- //

  @computed
  get offerInquiriesBarChartData() {
    const offerInquiries: OfferInquiriesBarChart[] = []

    this.offerInquiryIds
      .map((id) => offerInquiry.get(id))
      .forEach((offerInq) => {
        const index = offerInquiries.findIndex((o) => o.id === offerInq.offerId)

        if (index > -1) {
          offerInquiries[index] = {
            ...offerInquiries[index],
            value: offerInquiries[index].value + 1,
          }
        } else {
          offerInquiries.push({
            id: offerInq.offerId,
            name: offerInq.offerName,
            value: 1,
          })
        }
      })

    const sorted = offerInquiries.sort((a, b) => b.value - a.value)
    return sorted
  }
}

export default new OfferStore()
