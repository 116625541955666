/* global google */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import { withGoogleMap, GoogleMap } from 'react-google-maps'
import { MAP } from 'react-google-maps/lib/constants'
import withStyles from '@material-ui/core/styles/withStyles'

import mapStyleDefault, {
  hover as mapStyleHover,
} from 'src/components/Maps/Maps.styles'
import InfoBox from 'src/components/Maps/InfoBox'
import MapDetails from 'src/components/Maps/MapDetails'
import LoadingOverlay from 'src/components/Maps/LoadingOverlay'
import MapsStore from 'src/config/store/MapsStore'
import { repository as MailingZones } from 'src/models/MailingZone'
import { repository as MailingAreas } from 'src/models/MailingArea'
import PatientListMarkers from '../../components/Maps/PatientListPanel/PatientListMarkers'
import OfficeMarkers from '../../components/Maps/OfficeMarkers'

const styles = (theme) => ({
  root: {},

  map: {
    flex: '1 1 auto',
    minWidth: 0,
  },

  mapWrapper: {
    display: 'flex',
    transition: 'filter 0.3s ease',
  },
})

@withStyles(styles)
export default class Maps extends Component {
  infoBox
  mapView

  constructor(props) {
    super(props)
    this.infoBox = React.createRef()
    this.mapView = React.createRef()
    this.mapPageContainer = React.createRef()
  }

  static contextTypes = {
    [MAP]: PropTypes.object,
  }

  features = {}
  infoBoxIdentifier = ''

  /**
   *
   */
  setupMap(currentMap) {
    MapsStore.setMap(currentMap)
    MapsStore.refreshMailingAreas()

    const overlay = new google.maps.OverlayView()
    overlay.draw = function () {}
    overlay.setMap(currentMap)
    MapsStore.setMapOverlay(overlay)

    currentMap.data.setStyle({
      fillOpacity: 0.3,
      strokeWeight: 0.5,
      strokeOpacity: 0.7,
      visible: false,
    })

    let selectedGeoDataId = ''
    let hoveredGeoDataId = ''
    const mapPageContainer = ReactDOM.findDOMNode(this.mapPageContainer.current)

    if (MapsStore.isLoading) {
      mapPageContainer.style.filter = 'blur(3px)'
    }

    MapsStore.loadingNotifier.on('maps.loading.change', () => {
      if (!MapsStore.isLoading) {
        mapPageContainer.style.filter = 'blur(0px)'
      }
    })

    currentMap.data.addListener('mouseover', (event) => {
      const geodataId = event.feature.getProperty('group')
      hoveredGeoDataId = geodataId
      MapsStore.applyStyles(geodataId, mapStyleHover)
    })

    currentMap.data.addListener('mouseout', (event) => {
      const geodataId = event.feature.getProperty('group')
      const zone = MailingZones.findByGeodataId(geodataId)
      if (zone) {
        MapsStore.applyStyles(geodataId, mapStyleDefault)
      }

      if (MapsStore.showInfoBox) {
        MapsStore.applyStyles(selectedGeoDataId, mapStyleHover)
      }
    })

    currentMap.data.addListener('click', (event) => {
      const infoBox = ReactDOM.findDOMNode(this.infoBox.current)
      const mapView = ReactDOM.findDOMNode(this.mapView.current)

      selectedGeoDataId = event.feature.getProperty('group')

      if (hoveredGeoDataId === selectedGeoDataId) {
        MapsStore.resetDefault(selectedGeoDataId)
        MapsStore.applyStyles(selectedGeoDataId, mapStyleHover)
      }

      if (this.infoBoxIdentifier !== event.feature.getProperty('group')) {
        this.infoBoxIdentifier = ''
      }

      if (this.infoBoxIdentifier === '' || MapsStore.showInfoBox === false) {
        let object = MailingZones.findByGeodataId(
          event.feature.getProperty('group')
        )
        if (!object) {
          object = MailingAreas.findByGeodataId(
            event.feature.getProperty('group')
          )
        }
        if (!object) {
          return
        }

        MapsStore.infoBoxItem.set(object)

        const overlay = MapsStore.getMapOverlay()
        const pixel = overlay
          .getProjection()
          .fromLatLngToContainerPixel(event.latLng)

        MapsStore.showInfoBox = true
        this.infoBoxIdentifier = event.feature.getProperty('group')

        const mbr = mapView.getBoundingClientRect()
        const ibr = infoBox.getBoundingClientRect()

        let clientX = pixel.x + 10
        let clientY = pixel.y + 10

        if (clientY + ibr.height + mbr.top > mbr.bottom) {
          clientY = mbr.bottom - ibr.height - 10 - mbr.top
        }

        if (clientX + ibr.width + mbr.left > mbr.right) {
          clientX = mbr.right - ibr.width - 10 - mbr.left
        }

        infoBox.style.left = `${clientX}px`
        infoBox.style.top = `${clientY}px`
      }
    })
  }

  componentWillUnmount() {
    MapsStore.setMap(undefined)
  }

  render() {
    const { classes } = this.props
    const MapComponent = withGoogleMap(() => (
      <GoogleMap
        defaultZoom={10.8}
        defaultCenter={{ lat: -95.7129, lng: 37.0902 }}
        ref={(ref) => {
          if (!ref) {
            return
          }
          const currentMap = ref.context[MAP]
          this.setupMap(currentMap)
        }}
      >
        <OfficeMarkers />
        <PatientListMarkers />
      </GoogleMap>
    ))

    return (
      <div>
        <div className={classes.mapWrapper} ref={this.mapPageContainer}>
          <div className={classes.map}>
            <InfoBox ref={this.infoBox} />
            <div ref={this.mapView}>
              <MapComponent
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '100vh' }} />}
                mapElement={<div style={{ height: '100%' }} />}
              />
            </div>
          </div>
          <MapDetails />
        </div>
        <LoadingOverlay />
      </div>
    )
  }
}
