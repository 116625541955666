import { observable, action, computed, runInAction } from 'mobx'
import getInsurances from 'services/insurances/getInsurances'
import { repository as insuranceInquiry } from 'models/insurance/InsuranceInquiry'
import dashboardUIStore from '../DashboardUIStore'

interface InsuranceInquiriesBarChart {
  id: string
  name: string
  value: number
}

export class InsuranceStore {
  @observable insuranceStart: string = ''
  @observable insuranceInquiresLoading: boolean = false
  @observable private insuranceInquiryIds = observable.array<string>([])

  // --- ACTION w/ SIDE EFFECTS - Refresher --- //

  @action
  async refreshOverviewTab({ reset = false } = {}) {
    if (reset) {
      this.reset()
    }

    const filters = dashboardUIStore.globalFiltersQuery
    await this.fetchInsurances({ filters })
  }

  // --- ACTION w/ SIDE EFFECTS --- //

  @action
  async fetchInsurances(options: {} = {}) {
    this.insuranceInquiresLoading = true

    const data = await getInsurances(options)
    const { records, startRecordDate } = data

    runInAction(() => {
      if (records) {
        this.insuranceInquiryIds.replace(records)
      }

      this.insuranceStart = startRecordDate
      this.insuranceInquiresLoading = false
    })
  }

  // --- ACTION w/o SIDE EFFECTS --- //

  @action
  private reset() {
    this.insuranceStart = ''
    this.insuranceInquiresLoading = false
  }

  // --- COMPUTED --- //

  @computed
  get insuranceInquiriesBarChartData() {
    const insuranceInquiries: InsuranceInquiriesBarChart[] = []

    this.insuranceInquiryIds
      .map((id) => insuranceInquiry.get(id))
      .forEach((insuranceInq) => {
        const index = insuranceInquiries.findIndex(
          (o) => o.id === insuranceInq.insuranceId
        )

        if (index > -1) {
          insuranceInquiries[index] = {
            ...insuranceInquiries[index],
            value: insuranceInquiries[index].value + 1,
          }
        } else {
          insuranceInquiries.push({
            id: insuranceInq.insuranceId,
            name: insuranceInq.insuranceName,
            value: 1,
          })
        }
      })

    return insuranceInquiries.sort((a, b) => b.value - a.value)
  }
}

export default new InsuranceStore()
