import API, { DATA_API } from 'helpers/api'
import { Grade } from './grade'

export async function getStaffListAskedForSource({
  accountIds,
  filters,
  signal,
}: getStaffListAskedForSourceInput) {
  if (!accountIds.length) {
    return { summary: {}, records: [] } as never as Response
  }

  const data = {
    accountIds,
    filters,
  }

  const source = API.getCancelToken().source()

  const promise = API.get<Response>({
    api: DATA_API,
    url: '/calls/metrics/staff-asked-for-source',
    params: data,
    cancelToken: source.token,
  })

  // Cancel the request if TanStack Query signals to abort
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })

  return promise
}

export type getStaffListAskedForSourceInput = {
  accountIds: string[]
  filters: {}
  signal?: AbortSignal
}

export type Response = {
  summary: {
    total: number
    askedForSource: number
    askedForSourcePercentage: number
    askedForSourceGrade: Grade
  }
  records: Array<{
    callHandlerId: string
    callHandlerName: string
    total: number
    askedForSourceTotal: number
    askedForSourcePercentage: number
    askedForSourceGrade: Grade
  }>
}
