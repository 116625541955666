import API, { IDENTITY_API } from 'src/helpers/api'

/**
 * Sends setup link
 *
 * @param {string} id
 */
export const sendSetupLink = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await API.post({
        api: IDENTITY_API,
        url: `/contacts/${id}/send-setup`,
      })

      resolve(true)
    } catch (e) {
      console.log(e)
      reject(false)
    }
  })
}

export default sendSetupLink
