import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import Edit from '@material-ui/icons/Edit'

import { useStores } from 'config/store'
import { ACTIVE_CONDITIONS, POSTCARD_CAMPAIGN_TYPES } from 'constants/constants'

export interface CallProTrackingLineColumns {
  id: string
  name: string
  sortable: boolean
}

export const callProTrackingLineColumns: CallProTrackingLineColumns[] = [
  {
    id: '',
    name: '',
    sortable: false,
  },
  {
    id: 'campaignName',
    name: 'Name',
    sortable: true,
  },
  {
    id: 'number',
    name: 'MVP Tracking Number',
    sortable: true,
  },
  {
    id: 'destinationNumber',
    name: 'Destination Number',
    sortable: true,
  },
  {
    id: 'status',
    name: 'Status',
    sortable: true,
  },
  {
    id: 'type',
    name: 'Type',
    sortable: true,
  },
]

export const useStyles = makeStyles(() => ({
  active: {
    color: 'rgb(237, 22, 78)',
  },
  campaignName: {
    marginTop: 10,

    '& input': {
      backgroundColor: 'transparent',
    },
  },

  editIcon: {
    position: 'absolute',
    width: 18,
    top: 14,
    color: '#8b8b8b',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

interface renderTrackingLineInterface {
  trackingLines: any[]
  currentPage: number
  pageSize: number
  editRowIndex?: number
}

// TODO: Refactor create a object type config
export const renderTrackingLineColumns = ({
  trackingLines,
  currentPage,
  pageSize,
  editRowIndex,
}: renderTrackingLineInterface) => {
  const { trackingLinesStore } = useStores()

  const classes = useStyles()
  const [editKeys, setEditKeys] = useState<number[]>([])
  const [newTrackingFriendlyName, setNewTrackingFriendlyName] = useState<{
    id?: string
    value?: string
  }>()

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        setEditKeys([])
      }

      if (event.key === 'Enter') {
        handleOnBlur(newTrackingFriendlyName)
        event.preventDefault()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    // 👇️ clean up event listener
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [newTrackingFriendlyName])

  const handleClickEdit = (key: number): void => {
    setEditKeys([key])
  }

  const handleOnBlur = async (
    newTrackingFriendlyName:
      | {
          id?: string
          value?: string
        }
      | undefined
  ): Promise<void> => {
    setEditKeys([])

    if (newTrackingFriendlyName) {
      const updatedName = {
        id: newTrackingFriendlyName.id ?? '',
        name: newTrackingFriendlyName.value ?? '',
      }

      trackingLinesStore.setFriendlyName(updatedName)

      await trackingLinesStore.updateFriendlyName({
        id: updatedName.id,
        friendlyName: updatedName.name,
      })
    }
  }

  const getStatus = (status: string) => {
    let trackingStatus = ''

    if (ACTIVE_CONDITIONS.find((o) => o === status)) {
      trackingStatus = 'Active - Receiving Calls'
    }

    if (status === 'New') {
      trackingStatus = 'Provisioned'
    }

    if (status === 'Released') {
      trackingStatus = 'Cancelled'
    }

    return <div className={classes.active}>{trackingStatus}</div>
  }

  const getTrackingLineType = (campaignType: string) => {
    if (POSTCARD_CAMPAIGN_TYPES.find((o) => o === campaignType)) {
      return <span>Postcard / Direct Mail</span>
    } else {
      return <span>-</span>
    }
  }

  const getTrackingFriendlyName = (data: {
    id: string
    name: string
    key: number
  }) => {
    const { id, key, name } = data

    return (
      <div>
        <div style={{ position: 'relative' }}>
          {editKeys.includes(key) ? (
            <div>
              <TextField
                className={classes.campaignName}
                defaultValue={name}
                onBlur={() => handleOnBlur(newTrackingFriendlyName)}
                onChange={(event: any) => {
                  setNewTrackingFriendlyName({
                    id,
                    value: event.target.value,
                  })
                }}
                name={id}
                id="friendly-name-input"
              />
            </div>
          ) : (
            <span>
              {name}
              <span style={{ marginRight: 4 }}></span>
              {editRowIndex === key && (
                <Edit
                  onClick={() => handleClickEdit(key)}
                  className={classes.editIcon}
                />
              )}
            </span>
          )}
        </div>
      </div>
    )
  }

  return trackingLines.map((tLine: any, key: number) => {
    return {
      rowJsx: [
        (currentPage - 1) * pageSize + (key + 1),
        getTrackingFriendlyName({
          id: tLine.id,
          name: tLine.friendlyName,
          key,
        }),
        tLine.number,
        tLine.destinationNumber,
        getStatus(tLine.status),
        getTrackingLineType(tLine.campaignType),
      ],
      id: tLine.id,
    }
  })
}
