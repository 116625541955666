import React from 'react'
import { Observer } from 'mobx-react'
import {
  createStyles,
  Dialog,
  DialogProps,
  makeStyles,
} from '@material-ui/core'

import { useCallTableFilterStore } from '../../store/CallTableFilterStore'
import DialogTitle from './DialogTitle'
import FilterList from './FilterList'

type Prop = {}

export default function AddFilterDialog({}: Prop) {
  const styles = useStyles()
  const callTableFilterStore = useCallTableFilterStore()

  const handleDialogClose: DialogProps['onClose'] = (_, reason) =>
    callTableFilterStore.addFilterDialogOpened.set(false)

  return (
    <Observer
      render={() => {
        const { addFilterDialogOpened } = callTableFilterStore
        return (
          <Dialog
            maxWidth="xs"
            onClose={handleDialogClose}
            open={addFilterDialogOpened.get()}
            PaperProps={{ elevation: 0, square: true }}
            classes={{ paper: styles.dialogPaper }}
          >
            <DialogTitle />
            <FilterList />
          </Dialog>
        )
      }}
    />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogPaper: {
      background: 'none',
      boxShadow: 'none',
    },
  })
)
