import API, { DATA_API } from 'helpers/api'

interface closeDesignVersionArgs {
  id: string
}

export const closeDesignVersion = async ({ id }: closeDesignVersionArgs) => {
  await API.post({
    api: DATA_API,
    url: `/designs/${id}/close`,
  })
}
