/**
 * ******************************************
 * Designed to be used by src/views/Campaigns/CampaignTabBar.tsx
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React, { useState } from 'react'

// MUI
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Typography, useMediaQuery } from '@material-ui/core'
import { Check } from '@material-ui/icons'

// Util
import cx from 'classnames'

// My util
import { useStores } from 'config/store'

// My component
import Select from 'components/AppBar/Select'
import Menu from 'components/MVP/Menus/Menu'

export default function CampaignSortSelect() {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const { mailingsStore } = useStores()
  const { orderBy: currentOrderBy, dir: currentDir } = mailingsStore

  const [anchorEl, setAnchorEl] = useState<null | Element>(null)
  const sortMenu = Boolean(anchorEl)

  function handleOpenMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleCloseMenu() {
    setAnchorEl(null)
  }

  const handleSelectSort = (key: string, dir: string) => () => {
    mailingsStore.setSorting(key, dir)
    setAnchorEl(null)
  }

  function sortingLabel(orderBy: string, dir: string | null) {
    const item = SORT_OPTIONS.find((item) => item.key === orderBy)

    if (!item) {
      return 'Sort item not found'
    }

    let label = item.label
    if (dir === 'desc') {
      label += ` - ${item.desc} to ${item.asc}`
    } else if (dir === 'asc') {
      label += ` - ${item.asc} to ${item.desc}`
    }
    return label
  }

  const sortLabel =
    currentOrderBy && currentDir
      ? `Sorted by ${sortingLabel(currentOrderBy, currentDir)}`
      : 'Sort'

  return (
    <>
      <Select
        label={<span className={classes.labelSpan}>{sortLabel}</span>}
        classes={{ label: classes.label }}
        onClick={handleOpenMenu}
      />
      <Menu
        anchorEl={anchorEl}
        open={sortMenu}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            width: isDesktop ? undefined : anchorEl?.clientWidth,
          },
        }}
        items={SORT_OPTIONS.map((item) => {
          let sortDir = currentDir

          if (currentOrderBy !== item.key) {
            sortDir = null
          }

          const isSelected =
            currentOrderBy === item.key && sortDir === currentDir
          const label = sortingLabel(item.key, sortDir)

          return {
            key: label,
            selected: isSelected,
            icon: (
              <Check
                style={{
                  visibility: isSelected ? undefined : 'hidden',
                }}
              />
            ),
            label: (
              <span className={cx({ [classes.labelSelected]: isSelected })}>
                {label}
              </span>
            ),
            menuProps: {
              open: false,
              items: ['desc', 'asc'].map((itemDir) => {
                const itemLabel = sortingLabel(item.key, itemDir)
                const itemSelected =
                  currentOrderBy === item.key && currentDir === itemDir
                return {
                  key: itemLabel,
                  onClick: handleSelectSort(item.key, itemDir),
                  selected: isSelected,
                  label: (
                    <span
                      className={cx({
                        [classes.labelSelected]: itemSelected,
                      })}
                    >
                      {itemLabel}
                    </span>
                  ),
                }
              }),
            },
          }
        })}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontWeight: 500,
    alignItems: 'center',
    color: '#D3D3D3',
    fontSize: '14px',
    margin: 0,
  },
  labelSpan: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    [theme.breakpoints.up('md')]: {
      width: '10rem',
    },
  },
  labelSelected: {
    color: theme.palette.primary.main,
  },
}))

const SORT_OPTIONS = [
  {
    key: 'deliveryWeek',
    label: 'Campaign Date',
    asc: 'Oldest',
    desc: 'Newest',
  },
  {
    key: 'leadsCount',
    label: 'Leads',
    asc: 'Lowest',
    desc: 'Highest',
  },
  {
    key: 'appointmentsCount',
    label: 'Appointments',
    asc: 'Lowest',
    desc: 'Highest',
  },
  {
    key: 'conversionRate',
    label: 'Conversion Rate',
    asc: 'Lowest',
    desc: 'Highest',
  },
]
