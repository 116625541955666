import React, { PropsWithChildren } from 'react'
import {
  createStyles,
  DialogTitle as MuiDialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { useCallTableFilterStore } from '../../store/CallTableFilterStore'

type Prop = {}

export default function DialogTitle({}: Prop) {
  const styles = useStyles()
  const callTableFilterStore = useCallTableFilterStore()

  const handleCloseClick = () => {
    callTableFilterStore.addFilterDialogOpened.set(false)
  }

  return (
    <Paper
      className={styles.root}
      elevation={1}
      component={ExtendedMuiDialogTitle}
    >
      <Typography variant="inherit" color="textSecondary">
        Add filter
      </Typography>
      <IconButton
        aria-label="close"
        className={styles.closeButton}
        onClick={handleCloseClick}
      >
        <Close />
      </IconButton>
    </Paper>
  )
}

function ExtendedMuiDialogTitle({ children, ...other }: PropsWithChildren<{}>) {
  const styles = useStyles()
  return (
    <MuiDialogTitle disableTypography {...other}>
      {children}
    </MuiDialogTitle>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(0.5),
      padding: '12px 24px',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      color: theme.palette.grey[500],
    },
  })
)
