import { observable, computed } from 'mobx'
import _head from 'lodash/head'
import _get from 'lodash/get'
import Repository from '../Repository'
import { repository as householdRepo } from './../Household'

import check from '../../assets/img/patientList/patientMarkers/check.svg'
import star from '../../assets/img/patientList/patientMarkers/star.svg'

export const repository = new Repository()

export default class HouseholdMarker {
  @observable id
  @observable lat = undefined
  @observable lng = undefined
  @observable isCallAudioPlaying = false

  @computed get position() {
    if (this.lat && this.lng) {
      // eslint-disable-next-line no-undef
      const patientCoordinates = new google.maps.LatLng(this.lat, this.lng)
      return patientCoordinates.toJSON()
    }

    const { matches } = this.getStrongestMatch()

    for (const match of matches) {
      if (match.latitude && match.longitude) {
        return {
          lat: match.latitude,
          lng: match.longitude
        }
      }
    }

    return {
      lat: undefined,
      lng: undefined
    }
  }

  @computed get household() {
    return householdRepo.get(this.id)
  }

  @computed get auditMatch() {
    const household = householdRepo.get(this.id)
    return household && household.consolidatedMatch || null
  }

  @computed get infoBoxHeader() {
    const { matches, matchType } = this.getStrongestMatch()
    const isMatchFound = ['strongMatches', 'weakMatches'].includes(matchType || '')
    const household = householdRepo.get(this.id)
    let production = household && household.production || 0

    if (matches.length > 1) {
      return {
        isMatchFound,
        production,
        main: `${matches.length} Patients`,
        sub: ''
      }
    }

    for (const match of matches) {
      if (match.patientFirstName) {
        return {
          isMatchFound,
          production,
          main: `${match.patientFirstName} ${match.patientLastName || ''}`,
          sub: match.callerNumber || ''
        }
      }

      if (match.appointmentFirstName) {
        return {
          isMatchFound,
          production,
          main: `${match.appointmentFirstName} ${match.appointmentLastName || ''}`,
          sub: match.callerNumber || ''
        }
      }

      if (match.callerName) {
        return {
          isMatchFound,
          production,
          main: `${match.callerName}`,
          sub: match.callerNumber || ''
        }
      }

      if (match.callerNumber) {
        return {
          isMatchFound,
          production,
          main: match.callerNumber || '',
          sub: ''
        }
      }
    }

    return {
      isMatchFound,
      production,
      main: 'New Patient Household',
      sub: ''
    }
  }

  @computed get call() {
    const strongMatches = this.getMatches('strongMatches')
    if (strongMatches.length >= 1) {
      const call = _head(strongMatches) || { callAudioUrl: '', callAudioDuration: 0 }
      return call
    } else {
      return {}
    }
  }

  @computed get icon() {
    const { matches, matchType } = this.getStrongestMatch()

    if (matchType === 'strongMatches' && matches.length > 0) {
      // eslint-disable-next-line no-undef
      return { url: star, size: new google.maps.Size(20, 20) }
    }

    if (matchType === 'weakMatches' && matches.length > 0) {
      // eslint-disable-next-line no-undef
      return { url: check, size: new google.maps.Size(20, 20) }
    }

    return {
      url: undefined,
      size: undefined
    }
  }

  @computed get address() {
    const household = householdRepo.get(this.id)
    return household && household.address || null
  }

  @computed get auditedAddress() {
    const { matches } = this.getStrongestMatch()
    const match = _head(matches)
    return match && match.patientAddress || ''
  }

  getStrongestMatch() {
    const strongMatches = this.getMatches('strongMatches')
    if (strongMatches.length) {
      return { matches: strongMatches, matchType: 'strongMatches' }
    }

    const weakMatches = this.getMatches('weakMatches')
    if (weakMatches.length) {
      return { matches: weakMatches, matchType: 'weakMatches' }
    }

    const others = this.getMatches('others')
    if (others.length) {
      return { matches: others, matchType: 'others' }
    }

    return { matches: [], matchType: 'noMatchesFound' }
  }

  getMatches(type) {
    const household = householdRepo.get(this.id)
    const consolidatedMatch = household && household.consolidatedMatch

    if (type === 'strongMatches') {
      return consolidatedMatch && consolidatedMatch.strongMatches || []
    } else if (type === 'weakMatches') {
      return consolidatedMatch && consolidatedMatch.weakMatches || []
    } else if (type === 'others') {
      return consolidatedMatch && consolidatedMatch.others || []
    } else {
      return []
    }
  }
}
