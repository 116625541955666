import React, { Component } from 'react'
import { withMediaProps } from 'react-media-player'
import { VolumeUp, VolumeOff } from '@material-ui/icons'

class MuteUnmute extends Component {
  shouldComponentUpdate({ media }) {
    return this.props.media.isMuted !== media.isMuted
  }

  _handleMuteUnmute = () => {
    this.props.media.muteUnmute()
  }

  render() {
    const { className, style, media } = this.props
    return (
      <div
        className={className}
        style={{cursor: 'pointer', marginRight: '8px'}}
        onClick={this._handleMuteUnmute}
      >
        {media.isMuted ? <VolumeOff/> : <VolumeUp/>}
      </div>
    )
  }
}

export default withMediaProps(MuteUnmute)