import API, { DATA_API } from 'helpers/api'

interface rejectReviewCommentArgs {
  id: string
}

export const rejectReviewComment = async ({ id }: rejectReviewCommentArgs) => {
  await API.post({
    api: DATA_API,
    url: `/designs/review-comments/${id}/reject`,
  })
}
