import React from 'react'

// styles
import { useStyles } from './AllInaccurateReferralSourcesDrawer.style'
import AllInaccurateReferralSourcesSection from './AllInaccurateReferralSourcesSection/AllInaccurateReferralSourcesSection'

type DrawerProps = {
  showAllCallSources: boolean
}

const AllInaccurateReferralSourcesDrawer = (props: DrawerProps) => {
  const { showAllCallSources } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <AllInaccurateReferralSourcesSection showAllCallSources={showAllCallSources} />
    </div>
  )
}

export default AllInaccurateReferralSourcesDrawer