import React, { Component } from 'react'
import { observer } from 'mobx-react'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import MailingIcon from '@material-ui/icons/PermMedia'
import { Scrollbars } from 'react-custom-scrollbars'

import { repository as MailingAreas } from 'src/models/MailingArea'
import { repository as MailingZones } from 'src/models/MailingZone'

import MapsStore from 'src/config/store/MapsStore'
import LocationDetails from './LocationDetails'
import MailingAreaPanel from './MailingAreaPanel'
import PatientListPanel from './PatientListPanel/PatientListPanel'

const styles = (theme) => ({
  root: {
    width: 400,
    height: '100vh',
    userSelect: 'none',
    right: 0,
    background: '#fff',
    overflowX: 'hidden',
  },
  header: {
    color: '#fff',
    background: '#ED154E',
    fontSize: '24px',
    padding: '16px',
  },
  address: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    borderBottom: 'solid 4px #D3D3D3',
  },
  accordionItem: {
    margin: '0px !important',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '700',
    fontFamily: 'Roboto',
    fontSize: '17px',
    '& svg': {
      width: 20,
      height: 20,
      marginRight: '16px',
      marginLeft: '-6px',
    },
  },
  expansionPanel: {
    padding: '0px !important',
  },
  expansionPanelSub: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '&:before': {
      content: '""',
      width: '100%',
      height: '1px',
      position: 'relative',
      'z-index': '999',
      '-webkit-box-shadow': '0px 0px 5px 0px rgba(50, 50, 50, 0.75)',
      '-moz-box-shadow': '0px 0px 5px 0px rgba(50, 50, 50, 0.75)',
      'box-shadow': '0px 0px 5px 0px rgba(50, 50, 50, 0.75)',
    },
  },
  expandCollapse: {
    color: 'rgb(229,8,62)',
  },
  panelSummary: {
    minHeight: '48px !important',
    height: '48px !important',
  },
  panelSummaryExpandIcon: {
    position: 'absolute',
    right: 8,
    marginRight: 0,
  },
  noItems: {
    padding: '12px 24px',
    fontSize: 14,
  },
})

@withStyles(styles)
@observer
export default class MapDetails extends Component {
  handleTogglePanel = (event, areaId, checked) => {
    if (event && event.target && event.target.type === 'checkbox') {
      return
    }

    const expandedAreas = MapsStore.expandedAreaPanels.slice()
    const index = expandedAreas.indexOf(areaId)
    if (undefined === checked) {
      checked = index === -1
    }

    if (checked && index === -1) {
      MapsStore.toggleAreaPanel(areaId, true)
    } else if (!checked && index > -1) {
      MapsStore.toggleAreaPanel(areaId, false)
    }
  }

  /**
   *
   * @param event
   */
  handleToggleZone = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const value = event.target.value
    const mailingZone = MailingZones.get(value)
    if (mailingZone) {
      const checked = MapsStore.selectedGeodataIds.includes(
        mailingZone.geodataId
      )
      MapsStore.toggleAreaOrZone(mailingZone, !checked)
    }
  }

  /**
   *
   * @param event
   */
  handleToggleArea = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const value = event.target.value
    const mailingArea = MailingAreas.get(value)
    if (mailingArea) {
      if (mailingArea.zones.length === 0) {
        const areaChecked = MapsStore.selectedGeodataIds.includes(
          mailingArea.geodataId
        )
        MapsStore.toggleAreaOrZone(mailingArea, !areaChecked)
      } else {
        let zonesChecked = true

        mailingArea.zones
          .map((id) => {
            const zone = MailingZones.get(id)
            if (
              zone &&
              !MapsStore.selectedGeodataIds.includes(zone.geodataId)
            ) {
              zonesChecked = false
            }
            return id
          })
          .forEach((id) => {
            const zone = MailingZones.get(id)
            MapsStore.toggleAreaOrZone(zone, !zonesChecked)
          })
      }
    }
  }

  render() {
    const { classes } = this.props
    const mailingAreas = MapsStore.mailingAreas.map((id) =>
      MailingAreas.get(id)
    )
    const { patientLists } = MapsStore
    return (
      <div className={classes.root}>
        <Scrollbars>
          <header className={classes.header}>
            {MapsStore.officeLocations.length === 1
              ? MapsStore.officeLocations.values().next().value.officeName
              : 'All Accounts'}
          </header>
          <div className={classes.address}>
            <LocationDetails />
          </div>
          <ExpansionPanel
            className={classes.accordionItem}
            elevation={1}
            square={true}
            defaultExpanded={true}
          >
            <ExpansionPanelSummary
              classes={{
                expandIcon: classes.panelSummaryExpandIcon,
              }}
              className={classes.panelSummary}
              expandIcon={
                MapsStore.isLoading || !mailingAreas.length ? (
                  false
                ) : (
                  <ExpandMoreIcon className={classes.expandCollapse} />
                )
              }
            >
              <Typography className={classes.heading}>
                <MailingIcon /> Mailing Areas
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              className={`${classes.expansionPanel} ${classes.expansionPanelSub}`}
            >
              {mailingAreas.length ? (
                mailingAreas
                  .filter((area) => {
                    return !area.archived
                  })
                  .map((area) => {
                    return (
                      <MailingAreaPanel
                        key={area.id}
                        mailingArea={area}
                        expanded={MapsStore.expandedAreaPanels.includes(
                          area.id
                        )}
                        onToggleArea={this.handleToggleArea}
                        onToggleZone={this.handleToggleZone}
                        onTogglePanel={this.handleTogglePanel}
                      />
                    )
                  })
              ) : (
                <div className={classes.noItems}>No items found ...</div>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <PatientListPanel />
        </Scrollbars>
      </div>
    )
  }
}
