import { observable, computed } from 'mobx'
import Repository from './Repository'

export const repository = new Repository()

/**
 *
 */
export default class Account {
  @observable id?: string
  @observable color?: string
  @observable locationLatitude?: string
  @observable locationLongitude?: string
  @observable address?: string
  @observable name?: string
  @observable industry?: string
  @observable clientLabel?: string
  @observable businessLabel?: string

  @computed get hasCoordinate() {
    return this.locationLatitude && this.locationLongitude
  }
}
