import { observable } from 'mobx'

export class ConfigStore {
  /**
   * Date/Time formats based on moment
   * @link {https://momentjs.com/docs/#/displaying/format/}
   */
  @observable longDateFormat = 'MMM D, YYYY'
  @observable dateFormat = 'M/D/YY'
  @observable timeFormat = 'h:mm a'
  // @observable longDateTimeFormat = ''
  // @observable dateTimeFormat = ''
}

export default new ConfigStore()
