import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    formControl: {},
    bold: {
      fontWeight: 'bold',
    },
    underlined: {
      textDecorationLine: 'underline',
    },
  })
)

export default useStyles
