import _merge from 'lodash/merge'

// List of props settings go here
import button from './button'
import input from './input'

export default theme => _merge(
  {},
  button(theme),
  input(theme)
)