/**
 * ******************************************
 * Designed to be used with components/Page/AppBarTitleSection.tsx
 * example:
 * ```jsx
 * <AppBar>
 *  <AppBarTitleSection>
 *    <AppBarTitle>App Page Title</AppBarTitle>
 *  </AppBarTitleSection>
 * </AppBar>
 * ```
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React from 'react'

// MUI
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

interface Prop {
  children?: React.ReactNode
}

export default function AppBarTitle(props: Prop) {
  if (typeof props.children === 'object') {
    return <>{props.children}</>
  }

  const classes = useStyles()
  return (
    <Typography className={classes?.root} variant="h6">
      {props.children}
    </Typography>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    color: '#141414',
    fontSize: '1.4em',
    fontWeight: 700,
  },
}))
