import _get from 'lodash/get'

import { webAuth }  from 'src/helpers/auth0'
import getRelatedAccounts from 'src/services/users/getRelatedAccounts'

/**
 * Helper function for processing the authentication response format
 * from the identity backend
 *
 */
const processAuthentication = async (hash) => {
  return new Promise((resolve, reject) => {
    webAuth.parseHash({ hash: hash }, (err, authResult) => {
      if (err) {
        console.log(err)

        // Invalid state
        if (err.error === 'invalid_token') {
          return resolve(undefined)
        }

        return reject(new Error('Login failed.'))
      }

      const {
        accessToken,
        tokenType,
        idTokenPayload,
      } = authResult

      const id = _get(idTokenPayload, [
        'https://mvpmailhouse.com/user_metadata',
        'sf_contact_id'
      ])
      const name = _get(idTokenPayload, 'name')
      const picture = _get(idTokenPayload, 'picture')
      const exp = _get(idTokenPayload, 'exp')
      const expiresAt = exp * 1000
      getRelatedAccounts({
        headers: {
          authorization: `${tokenType} ${accessToken}`
        }
      }).then(accounts => {
          const session = {
            expiresAt,
            accessToken,
            tokenType,
            picture,
            userInfo: {
              id,
              name,
              accounts
            }
          }
          resolve(session)
        })
        .catch(error => {
          console.log(error)
          reject(new Error('Failed to retrieve accounts list'))
        })
    })
  })
}

export default processAuthentication
