import React, { useEffect, useState } from 'react'
import { useStores } from 'config/store'
import moment from 'moment'

// STYLE
import { useStyles } from './ResolveOutcome.style'

const ResolveOutcome = () => {
  const classes = useStyles()
  const { callStore, frontDeskStore } = useStores()
  const { frontDeskStaff } = frontDeskStore
  const call = callStore.drawer
  const [callHandler, setCallHandler] = useState('')

  const getCallHandlerName = () =>
    frontDeskStaff.find((staff) => {
      if (staff.id === call?.callbackHandler) {
        setCallHandler(staff.name)
      }
    })

  useEffect(() => {
    getCallHandlerName()
  }, [])

  if (!call) {
    return <div />
  }

  return (
    <section className={classes.callDetailsContainer}>
      <div className={classes.callDetails}>
        <div className={classes.title}>Callback Outcome</div>
        <div>
          <div className={classes.row}>
            <div className={classes.rowLabel}>Resolved</div>
            <div className={classes.rowData}>
              {moment(call.resolveDate).format('M/D/YY')}
            </div>
            <div className={classes.rowLabel}>Callback Handler</div>
            <div className={classes.rowData}>{callHandler}</div>
          </div>
          <div className={classes.row}>
            <div className={classes.rowLabel}>Outcome</div>
            <div className={classes.rowData}>{call.callbackOutcomeResult}</div>
            <div className={classes.rowLabel}>Opportunity</div>
            <div className={classes.rowData}>
              {call.callbackOutcomeOpportunity}
            </div>
          </div>
          <div className={classes.row2}>
            <div className={classes.row2label}>Callback Summary</div>
            <div>{call.callbackOutcomeSummary}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResolveOutcome
