import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Observer } from 'mobx-react'

// @ts-ignore
import PasswordInput from '../CustomInput/PasswordInput'
import { useStyles } from './PasswordSettings.styles'
import settingsStore from '../../config/store/SettingsStore'
// @ts-ignore
import AppState from '../../config/store/AppState'
import { Button, CircularProgress } from '@material-ui/core'

const newPassErrMsgs = {
  newPassReq: 'New password is required',
  confirmPassReq: 'Confirm password is required',
  matchPass: 'Your new password and confirmation password do not match',
  diffPass: 'New password should not be the same as current password',
}

const PasswordSettings = () => {
  const classes = useStyles()
  const [showForm, setShowForm] = useState(false)

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [currentPasswordError, setCurrentPasswordError] = useState('')
  const [newPasswordError, setNewPasswordError] = useState('')

  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false)

  const [passStrengthErrors, setPassStrengthErrors] = useState({
    uppercaseAlpha: false,
    lowercaseAlpha: false,
    number: false,
    length: false,
  })

  const currentPasswordRef = useRef<HTMLInputElement>(null)
  const newPasswordRef = useRef<HTMLInputElement>(null)

  const validatePassStrength = () => {
    setPassStrengthErrors({
      lowercaseAlpha: !/[a-z]/.test(newPassword),
      uppercaseAlpha: !/[A-Z]/.test(newPassword),
      number: !/[0-9]/.test(newPassword),
      length: newPassword.length < 8,
    })
  }

  useEffect(() => {
    const activated = settingsStore.activated

    activated &&
      setCurrentPasswordError(
        currentPassword === '' ? 'Current password is required' : ''
      )
    try {
      if (newPassword === '') {
        throw new Error(newPassErrMsgs.newPassReq)
      } else if (currentPassword === newPassword) {
        throw new Error(newPassErrMsgs.diffPass)
      }

      validatePassStrength()

      if (confirmPassword === '') {
        throw new Error(newPassErrMsgs.confirmPassReq)
      } else if (newPassword !== confirmPassword) {
        throw new Error(newPassErrMsgs.matchPass)
      }

      setNewPasswordError('')
    } catch (e) {
      setNewPasswordError(e.message)
    }
  }, [currentPassword, newPassword, confirmPassword])

  useEffect(() => {
    if (showForm) {
      const activated = settingsStore.activated
      if (activated) {
        currentPasswordRef.current?.focus()
      } else {
        newPasswordRef.current?.focus()
      }
    }
  }, [showForm])

  const handleChangeCurrentPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentPassword(event.target.value)
  }

  const handleChangeNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value)
  }

  const handleChangeConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value)
  }

  const handleShowForm = () => {
    setShowForm(true)
  }

  const handleHideForm = () => {
    setCurrentPassword('')
    setNewPassword('')
    setConfirmPassword('')
    setShowForm(false)
  }

  const handleSavePassword = async () => {
    try {
      setIsUpdatingPassword(true)

      await settingsStore.updateUserPassword({
        oldPassword: currentPassword,
        newPassword: confirmPassword,
      })
      settingsStore.activated = Date.now()

      AppState.successMessage('Your password has been updated')

      handleHideForm()
    } catch (error) {
      AppState.errorMessage(error.message)
    } finally {
      setIsUpdatingPassword(false)
    }
  }

  const hasPasswordStrengthError =
    Object.values(passStrengthErrors).includes(true)

  const generatePassStrengthErrorsSection = () => {
    if (
      [newPassErrMsgs.newPassReq, newPassErrMsgs.diffPass].includes(
        newPasswordError
      )
    ) {
      return <div className={classes.error}>{newPasswordError}</div>
    }

    if (hasPasswordStrengthError) {
      return (
        <div className={classes.errorPassStrength}>
          <div className={classes.error}>
            Password requirements:
            <ul>
              <li
                className={
                  !passStrengthErrors['length']
                    ? classes.errorPartialPass
                    : undefined
                }
              >
                must be at least 8 characters
              </li>
              <li
                className={
                  !passStrengthErrors['lowercaseAlpha']
                    ? classes.errorPartialPass
                    : undefined
                }
              >
                must have a lowercase letter
              </li>
              <li
                className={
                  !passStrengthErrors['uppercaseAlpha']
                    ? classes.errorPartialPass
                    : undefined
                }
              >
                must have an uppercase letter
              </li>
              <li
                className={
                  !passStrengthErrors['number']
                    ? classes.errorPartialPass
                    : undefined
                }
              >
                must have a number
              </li>
            </ul>
          </div>
        </div>
      )
    }

    return <div className={classes.error} />
  }

  const generateConfirmPassErrorsSection = () => {
    if (hasPasswordStrengthError) {
      return <div className={classes.error} />
    }

    if (
      [newPassErrMsgs.matchPass, newPassErrMsgs.confirmPassReq].includes(
        newPasswordError
      )
    ) {
      return <div className={classes.error}>{newPasswordError}</div>
    }

    return <div className={classes.error} />
  }

  return (
    <Observer
      render={() => {
        const { activated } = settingsStore
        return (
          <Fragment>
            {showForm && (
              <Fragment>
                {activated && (
                  <div className={classes.inputRowContainer}>
                    <div className={classes.labelContainer}>
                      <label>Current Password</label>
                    </div>
                    <div className={classes.fieldContainer}>
                      <PasswordInput
                        inputRef={currentPasswordRef}
                        id="currentPassword"
                        value={currentPassword}
                        onChange={handleChangeCurrentPassword}
                      />
                    </div>
                    <div className={classes.error}>{currentPasswordError}</div>
                  </div>
                )}
                <div className={classes.inputRowContainer}>
                  <div className={classes.labelContainer}>
                    <label>New Password</label>
                  </div>
                  <div className={classes.fieldContainer}>
                    <PasswordInput
                      inputRef={newPasswordRef}
                      id="newPassword"
                      value={newPassword}
                      onChange={handleChangeNewPassword}
                      onBlur={validatePassStrength}
                    />
                  </div>
                  {generatePassStrengthErrorsSection()}
                </div>
                <div className={classes.inputRowContainer}>
                  <div className={classes.labelContainer}>
                    <label>Confirm Password</label>
                  </div>
                  <div className={classes.fieldContainer}>
                    <PasswordInput
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={handleChangeConfirmPassword}
                    />
                  </div>
                  {generateConfirmPassErrorsSection()}
                </div>
              </Fragment>
            )}
            {!showForm ? (
              <div>
                <div className={classes.inputRowContainer}>
                  <div className={classes.labelContainer}>
                    <label>Password</label>
                  </div>
                  <div className={classes.fieldContainer}>
                    <div className={classes.passwordContainer}>
                      <Button
                        onClick={handleShowForm}
                        variant="outlined"
                        disabled={settingsStore.isLoading === true}
                        color="primary"
                      >
                        {settingsStore.activated
                          ? 'Change Password'
                          : 'Setup Password'}
                      </Button>
                      {!settingsStore.activated && (
                        <div className={classes.error}>
                          *Your password is not yet set. Please set it up to
                          enable password login.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.confirmSection}>
                <Button color="primary" onClick={handleHideForm}>
                  Cancel
                </Button>
                <Button
                  disabled={
                    (activated && currentPasswordError !== '') ||
                    newPasswordError !== '' ||
                    hasPasswordStrengthError ||
                    isUpdatingPassword
                  }
                  onClick={handleSavePassword}
                  variant="contained"
                  color="primary"
                >
                  {isUpdatingPassword ? (
                    <CircularProgress size={14} className={classes.spinner} />
                  ) : null}
                  Save
                </Button>
              </div>
            )}
          </Fragment>
        )
      }}
    />
  )
}

export default PasswordSettings
