import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { runInAction } from 'mobx'
import { Link, CircularProgress } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { dateFormat } from 'config/callpro.config'
import { useStores } from 'config/store'
import { CallsStore } from 'config/store/CallPro/Call/CallsStore'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'

import { LinearProgress } from '../LinearProgress'
import { ChartCard } from 'components/CallPro/ChartCard'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'
import { useStyles } from './CallerGender.styles'

interface CallerGenderInterface {
  isAccordionContent?: boolean
}

type CallerGenderItem = CallsStore['callerGender']['records'][number]

const CallerGender = (props: CallerGenderInterface) => {
  const { isAccordionContent } = props
  const { callsStore, dashboardUIStore } = useStores()
  const classes = useStyles()

  const { callerGender: _callerGender, loadingStates } = callsStore
  const callerGender = _callerGender.records
  const firstRecordDate = _callerGender.firstRecordDate

  const total = callerGender.reduce(
    (accumulator, currentElement) => accumulator + currentElement.value,
    0
  )

  const handleItemClick = (item: CallerGenderItem) => () => {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS
      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters({ outcome, callerGender: [item.name] })
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  return (
    <ChartCard
      headerTitle="CALLER GENDER"
      tooltipText="This shows the potential gender breakdown of the callers."
      headerTotalText="Total Calls"
      headerTotalValue={total}
      isAccordionContent={isAccordionContent}
    >
      {loadingStates.callerGender ? (
        <div
          style={{
            textAlign: 'center',
            padding: '2rem',
          }}
        >
          <CircularProgress />
        </div>
      ) : total > 0 ? (
        callerGender.map((gender, key) => {
          return (
            <LinearProgress
              key={`item-${key}`}
              value={gender.value}
              total={total}
              label={
                <Link
                  component="button"
                  variant="body1"
                  underline="none"
                  color="initial"
                  onClick={handleItemClick(gender)}
                  classes={{ root: classes.link }}
                >
                  {gender.name}
                </Link>
              }
            />
          )
        })
      ) : (
        <EmptyBarChart />
      )}
      {firstRecordDate.length ? (
        <div className={classes.disclaimer}>
          <InfoOutlinedIcon className={classes.disclaimerIcon} />
          The data used in this graph is derived from the calls we scored since{' '}
          {moment(firstRecordDate).format(dateFormat)}.
        </div>
      ) : null}
    </ChartCard>
  )
}

export default observer(CallerGender)
