import { observable, action } from 'mobx'

import Repository from './Repository'
import getMailingById from 'services/mailings/getMailingById'

export const repository = new Repository<Mailing>()

export enum MailingStatus {
  APPROVED = 'approved',
  ORDERED = 'ordered',
  PRINTED = 'printed',
  SHIPPED = 'shipped',
  DELIVERED = 'delivered',
  DELIVERED_IN_FIRST_CALL = 'deliveredInFirstCall',
}

export default class Mailing {
  @observable isLoading = false
  @observable isError = false

  @observable id: string
  @observable name?: string
  @observable label?: string
  @observable accountId?: string
  @observable deliveryWeek?: string
  @observable campaignType?: string
  @observable mediaType?: string
  @observable quantity: number = 0
  @observable orderDate?: string
  @observable approvalDate?: string
  @observable approvedById?: string
  @observable approvedBy?: string
  @observable printDate?: string
  @observable shipmentDate?: string
  @observable deliveryDate?: string
  @observable appointmentsCount?: number = 0
  @observable leadsCount?: number = 0
  @observable frontDesignUrl?: string
  @observable frontDesignThumbnailUrl?: string
  @observable backDesignUrl?: string
  @observable backDesignThumbnailUrl?: string
  @observable mapUrl?: string
  @observable mapThumbnailUrl?: string
  @observable status?: MailingStatus
  @observable conversionRate?: number = 0
  @observable matchedLastAudit: string = ''
  @observable matchedNewPatients: number = 0
  @observable matchedTotalProduction: number = 0
  @observable matchedTopPatients: string = ''
  @observable createdDate: string = ''
  @observable zone: string = ''
  @observable zoneColor: string = ''
  @observable officeColor: string = ''
  @observable qrScans: number = 0
  @observable zoneGeoData: any = {}
  @observable officeLocation: {
    latitude: number
    longitude: number
  } = { latitude: 0, longitude: 0 }
  accountName: string = ''

  isDirty: boolean = false
  isAudited: boolean = false
  totalProduction: number = 0
  totalNewPatients: number = 0
  matchesResult: any[] = []

  constructor() {
    this.id = ''
  }

  @action.bound
  loading(isLoading = true) {
    this.isLoading = isLoading
  }

  @action.bound
  async update(forceRefresh = false) {
    if (forceRefresh || this.isDirty || typeof this.isDirty === 'undefined') {
      this.loading(true)
      try {
        await getMailingById(this.id)
        this.isDirty = false
        this.loading(false)
      } catch (e) {
        /**
         * @todo Do something with the error
         */
        this.loading(false)
      }
    }
    this.loading(false)
  }
}
