import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/react'
import env from '../env'

export const configureSentry = () => {
  if (
    process.env.NODE_ENV === 'staging' ||
    process.env.NODE_ENV === 'production'
  ) {
    Sentry.init({
      dsn: env.sentry.dsn,
      environment: process.env.NODE_ENV,
      sampleRate: 1.0,
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    })
    return
  }
}
