import React from 'react'
import { Collapse, IconButton } from '@material-ui/core'
import { Alert as MuiAlert } from '@material-ui/lab'
import { Close as CloseIcon } from '@material-ui/icons'

type Prop = {
  error?: string
  onClose?: () => void
}

export default function AlertError({ error = '', onClose = () => {} }: Prop) {
  const handleClose = () => onClose()

  return (
    <>
      <Collapse in={!!error}>
        <MuiAlert
          variant="outlined"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </MuiAlert>
      </Collapse>
    </>
  )
}
