const getPrettyPhoneNumber = (input) => {
    const hasAreaCode = /^\+1/.test(input)
    const phoneNumber = input.replace(/\D/, '')
  
    if (phoneNumber.length < 10) {
      return false
    }
  
    if (phoneNumber.length === 10) {
      return hasAreaCode ? input : `+1${phoneNumber}`
    }
  
    return hasAreaCode ? input : `+${phoneNumber}`
}

export const isPhoneValid = (input) => {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(input)
}

export default getPrettyPhoneNumber

