import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { observer } from 'mobx-react'
import { useStyles } from './MailingMap.styles'
import { useStores } from 'config/store'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
//@ts-ignore
import { MAP } from 'react-google-maps/lib/constants'
import getPracticeIconByHex from 'components/Icons/Markers/PracticeColorIconSet'
import { bbox } from '@turf/turf'
import { Theme, useMediaQuery } from '@material-ui/core'
import classNames from 'classnames'

interface IMailingMap {}

export const MailingMap = (props: IMailingMap) => {
  const classes = useStyles()
  const { mailingsStore } = useStores()
  const mailing = mailingsStore.drawer
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )
  const [zone, setZone] = useState<{
    geojson: []
    officeIcon: string | google.maps.Icon | google.maps.Symbol
    zoneColor: string
    bounds: {}
  }>({
    geojson: [],
    officeIcon: '',
    zoneColor: '',
    bounds: {},
  })

  if (!mailing) {
    return <div />
  }

  useEffect(() => {
    mailingsStore.getMailingZone({
      mailingId: mailing.id,
      zoneId: mailing.zone,
    })
  }, [])

  useEffect(() => {
    if (!_.isEmpty(mailing.zoneGeoData)) {
      const geodata = mailing.zoneGeoData[0].geodata

      const fbox = bbox(geodata)
      const bounds = {
        west: fbox[0],
        south: fbox[1],
        east: fbox[2],
        north: fbox[3],
      }
      setZone({
        geojson: geodata,
        officeIcon: {
          url: getPracticeIconByHex(
            mailing.officeColor ? mailing.officeColor : 'loading'
          ),
          scaledSize: new google.maps.Size(24, 24),
        },
        zoneColor: mailing.zoneColor,
        bounds,
      })
    }
  }, [mailing.zoneGeoData])

  const MapComponent = withGoogleMap(() => (
    <GoogleMap
      defaultZoom={10.8}
      defaultCenter={{
        lat: mailing.officeLocation.latitude,
        lng: mailing.officeLocation.longitude,
      }}
      options={{
        disableDefaultUI: true,
      }}
      ref={(ref) => {
        if (!ref) {
          return
        }
        const currentMap = ref.context[MAP]
        currentMap.fitBounds(zone.bounds, -30)
        currentMap.data.setStyle({
          fillOpacity: 0.5,
          strokeWeight: 0.5,
          strokeOpacity: 0.7,
          fillColor: zone.zoneColor,
        })
        currentMap.data.addGeoJson(zone.geojson)
      }}
    >
      <Marker
        position={{
          lat: mailing.officeLocation.latitude,
          lng: mailing.officeLocation.longitude,
        }}
        icon={zone.officeIcon}
      />
    </GoogleMap>
  ))

  return (
    <div className={classes.root}>
      <div
        className={classNames(
          classes.map,
          mobileDevice ? classes.mapMobile : null
        )}
      >
        {_.isEmpty(mailing.zoneGeoData) ? (
          <div className={classes.loadingMap}>
            <p>Loading Map...</p>
          </div>
        ) : (
          <MapComponent
            containerElement={
              <div
                style={{
                  height: 'calc(100vh - 425px)',
                  width: 600,
                  position: 'absolute',
                  top: 0,
                }}
              />
            }
            mapElement={<div style={{ height: '100%', width: '100%' }} />}
          />
        )}
      </div>
    </div>
  )
}

export default observer(MailingMap)
