import API, { IDENTITY_API } from '../../helpers/api'

/**
 * Verify Activation Code if expired or not
 *
 * @param {string} email
 * @param {string} code
 * @param {string} password
 */
export const verifyActivationCode = async (email, code) => {
  await API.post({
      api: IDENTITY_API,
      url: 'contacts/activate/verify',
      data: {
        email,
        code,
      },
      skipAuthentication: true
    })

  return true
}

export default verifyActivationCode
