import React from 'react'

import {
  KPICardContainer,
  KPIGradeEvaluator,
  KPIValues,
} from '../../FrontDesk/KPICard'
import { LinearProgress } from 'components/CallPro/LinearProgress'

import { useStyles } from './GoodImpression.styles'
import { useStores } from 'config/store'
import { formatNumber } from 'helpers/utility'
import { ProTip } from '../ProTip'

import ProTipImage from '../../../../assets/img/call-pro/pro-tips/pro-tip-reschedule.png'
import GradeToolTipValue from '../GradeToolTipValue'
import { getGradeName } from '../Utils'
import { formatTextByIndustry } from 'helpers/industries'
import Account from 'models/Account'
import SeeIndividualMetric from './SeeIndividualMetric/SeeIndividualMetric'

interface GoodImpressionInterface {}

const GoodImpression = (props: GoodImpressionInterface) => {
  const classes = useStyles()
  const { frontDeskStore, accountStore } = useStores()
  const { officeHandlingRate } = frontDeskStore

  const account: Account = accountStore.accounts[0]
  const clientLabel = account.clientLabel ?? ''

  const total = officeHandlingRate.validAskedForGoodImpressionCount
  const goodPercentage =
    (officeHandlingRate.askedForGoodImpressionCount / total) * 100
  const needsImprovementPercentage =
    ((total - officeHandlingRate.askedForGoodImpressionCount) / total) * 100

  const grading = [
    {
      name: 'A',
      min: 86,
      max: undefined,
    },
    {
      name: 'B',
      min: 70,
      max: 85,
    },
    {
      name: 'C',
      min: 60,
      max: 69,
    },
    {
      name: 'D',
      min: 0,
      max: 59,
    },
  ]

  return (
    <KPICardContainer
      title="GOOD IMPRESSION"
      tooltip="Staff answers the call and clearly states their name and the practice name using an upbeat tone and speaking clearly with appropriate volume."
    >
      <KPIGradeEvaluator
        value={goodPercentage}
        total={formatNumber(
          officeHandlingRate.validAskedForGoodImpressionCount
        )}
        totalText="Calls"
        grading={grading}
      />
      <div className={classes.progressWrapper}>
        <LinearProgress
          hideProgressDetails={true}
          value={officeHandlingRate.askedForGoodImpressionCount}
          total={total}
        />
      </div>
      <KPIValues
        data={[
          {
            percentage: goodPercentage,
            label: 'Good',
            color: '#4498E7',
            details: officeHandlingRate.askedForGoodImpressionCount,
          },
          {
            percentage: needsImprovementPercentage,
            label: 'Needs improvement',
            color: '#d4d4d4',
            details: total - officeHandlingRate.askedForGoodImpressionCount,
          },
        ]}
      />
      <SeeIndividualMetric />
      <ProTip
        title={`Potential new ${clientLabel.toLowerCase()} did not schedule on first call? It's okay, make the most of the new lead, be helpful & offer to follow-up`}
        content={GradeToolTipValue(
          'Good Impression',
          getGradeName(grading, goodPercentage)
        )}
        image={ProTipImage}
      />
    </KPICardContainer>
  )
}

export default GoodImpression
