import localforage from 'localforage'
import { isEmpty, last } from 'lodash'
import moment from 'moment-timezone'
import { observable, action, computed, toJS } from 'mobx'

import getSfUser from 'src/services/users/getSfUser'

const USER_HIDDEN_ACCOUNTS = 'Apps/USER_HIDDEN_ACCOUNTS'
import appState from './AppState'

export class AccountStore {
  @observable _hiddenAccounts = []

  @observable hiddenAccounts = []
  @observable hideSections = []
  @observable sfUserData = {}

  constructor() {
    localforage.getItem(USER_HIDDEN_ACCOUNTS, (error, value) => {
      if (!error && value) {
        this.hiddenAccounts.replace(value)
      }
    })
  }

  @action
  toggleAccounts(accounts, toggle = undefined) {
    accounts.forEach((account) => this.toggleAccount(account, toggle))
  }

  // GET ACCOUNT TIMEZONE
  @computed get timezone() {
    const { accounts } = appState
    let timezone = ''

    // USE THE LAST SELECTED ACCOUNT AS TIMEZONE
    accounts.forEach((account) => {
      if (
        !isEmpty(this.selectedAccounts) &&
        last(this.selectedAccounts) === account.id
      ) {
        timezone = account.timeZone
      }
    })

    const abbrs = {
      EST: 'Eastern Standard Time',
      EDT: 'Eastern Daylight Time',
      CST: 'Central Standard Time',
      CDT: 'Central Daylight Time',
      MST: 'Mountain Standard Time',
      MDT: 'Mountain Daylight Time',
      PST: 'Pacific Standard Time',
      PDT: 'Pacific Daylight Time',
    }

    // FORMAT TO FULL TIMEZONE NAME
    const getFullTimeZoneName = (timezone) => {
      const abbrTimeZone = moment.tz(timezone).zoneAbbr()
      return abbrTimeZone !== '' ? abbrs[abbrTimeZone] : ''
    }

    return getFullTimeZoneName(timezone)
  }

  @action
  toggleAccount(account, toggle = undefined) {
    const isHidden = this._hiddenAccounts.includes(account)
    const toggleOn = toggle === undefined ? isHidden : toggle
    if (toggleOn) {
      isHidden &&
        this._hiddenAccounts.splice(this._hiddenAccounts.indexOf(account), 1)
    } else {
      !isHidden && this._hiddenAccounts.push(account)
    }
  }

  @action
  retrieveHidden() {
    this._hiddenAccounts = toJS(this.hiddenAccounts)
  }

  @action
  applyHidden() {
    this.hiddenAccounts = toJS(this._hiddenAccounts)
    this._hiddenAccounts = []
    localforage.setItem(USER_HIDDEN_ACCOUNTS, this.hiddenAccounts.slice())
  }

  /**
   *
   * @return {any[]}
   */
  @computed get accounts() {
    const accountsMap = new Map()
    const mapAccount = (account, orphan = false) => {
      // This account is a parent
      if (!account.parent || orphan) {
        if (!accountsMap.has(account.id)) {
          return accountsMap.set(account.id, {
            id: account.id,
            name: account.name,
            industry: account.industry,
            businessLabel: account.businessLabel,
            clientLabel: account.clientLabel,
            subAccounts: {},
            subAccountIds: [],
          })
        }
        return
      }

      // This account is a child
      // First add the child's parent (if it is included in the list of accounts)
      const parent = appState.accounts.find(
        (findParent) => findParent.id === account.parent
      )
      if (parent) {
        // Add the child account under the parent account
        parent && mapAccount(parent)

        const parentAccount = accountsMap.get(parent.id)
        parentAccount.subAccounts[account.id] = account
        parentAccount.subAccountIds.push(account.id)
        return
      }

      // The account is a child but parent does not exist...treat child as an
      // orphan account
      mapAccount(account, true)
    }

    appState.accounts.forEach((account) => {
      // Sanitize
      if (!account) {
        return
      }
      mapAccount(account)
    })

    return Array.from(accountsMap.values())
  }

  /**
   *
   * @return {any[]}
   */
  @computed get _selectedAccounts() {
    if (appState.accounts.length <= 1) {
      return appState.accounts.slice(0, 1).map((account) => account.id)
    }
    const accounts = appState.accounts
      .filter((account) => {
        const isParent = !!account.children.length
        const isParentHidden =
          isParent &&
          account.children.every((v) => this._hiddenAccounts.includes(v))
        if (isParent && isParentHidden) {
          return false
        }
        return !this._hiddenAccounts.includes(account.id)
      })
      .map((account) => account.id)

    return accounts
  }

  /**
   *
   * @return {any[]}
   */
  @computed get selectedAccounts() {
    if (appState.accounts.length <= 1) {
      const accountId = appState.accounts
        .slice(0, 1)
        .map((account) => account.id)
      accountId.length &&
        getSfUser(accountId).then((data) => (this.sfUserData = data))
      return accountId
    }

    const accounts = []
    for (const account of appState.accounts) {
      const isChildOrSingle = account.children.length === 0
      const isHidden = this.hiddenAccounts.includes(account.id)

      if (!isChildOrSingle || isHidden) {
        continue
      }

      accounts.push(account.id)
    }

    const isAccountsEmpty = Boolean(accounts[0])
    isAccountsEmpty &&
      getSfUser(accounts[0]).then((data) => (this.sfUserData = data))

    return accounts
  }
}

export default new AccountStore()
