import { observable, action, computed, runInAction, values } from 'mobx'
import getTeamLeaderboards from 'services/calls/getTeamLeaderboards'
import getContacts from 'services/contacts/getContactsByAccount'
import {
  SortCriteria,
  StaffInterface,
  CallOfficeHandlingRateInterface,
  CallOnHoldMetricsInterface,
} from 'interfaces/Call.interface'
import accountStore from 'config/store/AccountStore'
import { camelize } from 'helpers/text'
import dashboardUIStore from '../DashboardUIStore'

import getOfficeHandlingRate from 'services/calls/getOfficeHandlingRate'
import getPricingRelatedAnswers from 'services/calls/getPricingRelatedAnswers'
import getCallOnHoldMetrics from 'services/calls/getCallOnHoldMetrics'

// CONFIGS
import { CallConfig } from '../../../callpro.config'

export class FrontDeskStore {
  @observable sortBy: string = 'appointments'
  @observable loadingStates = {
    leaderBoard: false,
    leaderBoardByConversionRate: false,
    frontDeskKPI: false,
    priceRelatedAnswer: false,
    frontDeskStaff: false,
    callOnHoldMetrics: false,
  }
  @observable pricingRelatedAnswers: any[] = [] // TODO: Create type
  @observable pricingRelatedAnswersStart: string = ''
  @observable leaderBoard = observable.array<StaffInterface>([])
  @observable leaderBoardByConversionRate = observable.array<StaffInterface>([])
  @observable frontDeskStaff = observable.array<StaffInterface>([])
  @observable officeHandlingRate: CallOfficeHandlingRateInterface =
    CallConfig.initialValues.officeHandlingRate
  @observable nameSortDir: 'desc' | 'asc' | undefined = undefined
  @observable rankSortDir: 'desc' | 'asc' | undefined = undefined
  @observable callOnHoldMetrics: CallOnHoldMetricsInterface = {
    staffAskedToPlaceOnHold: 0,
    staffPlacedCallerOnHold: 0,
    totalCalls: 0,
  }

  // --- ACTION w/ SIDE EFFECTS - Refresher --- //

  @action
  async refreshOverviewTab({ reset = false } = {}) {
    if (reset) {
      this.reset()
    }
    await this.fetchFrontDeskStaff()
  }

  @action
  async refreshFrontDeskTab({ reset = false } = {}) {
    if (reset) {
      this.reset()
    }

    await Promise.all([
      this.fetchFrontDeskStaff(),
      this.fetchTeamLeaderboards(),
      this.fetchLeaderboardByConversionRate(),
      this.fetchOfficeHandlingRate(),
      this.fetchPriceRelatedAnswers(),
      this.fetchCallOnHoldMetrics(),
    ])
  }

  @action
  async refreshAllCallsTab({ reset = false } = {}) {
    if (reset) {
      this.reset()
    }
    await this.fetchFrontDeskStaff()
  }

  @action
  async refreshTeamLeaderboards() {
    await this.fetchTeamLeaderboards()
  }

  // --- ACTION w/ SIDE EFFECTS --- //

  @action
  private async fetchFrontDeskStaff() {
    this.loadingStates.frontDeskStaff = true

    const accountIds = (accountStore.selectedAccounts || []).slice()

    const data = await getContacts({ accountIds })

    runInAction(() => {
      this.frontDeskStaff.replace(data)
      this.loadingStates.frontDeskStaff = false
    })
  }

  @action
  async fetchTeamLeaderboards() {
    this.loadingStates.leaderBoard = true
    this.rankSortDir = undefined
    this.nameSortDir = undefined

    const accounts = (accountStore.selectedAccounts || []).slice()
    const filters = {
      accounts,
      sortBy: camelize(this.sortBy),
      filters: dashboardUIStore.globalFiltersQuery,
    }

    const data = await getTeamLeaderboards(filters)

    runInAction(() => {
      this.leaderBoard = data.records
      this.loadingStates.leaderBoard = false
    })
  }

  @action
  private async fetchLeaderboardByConversionRate() {
    this.loadingStates.leaderBoardByConversionRate = true

    const accounts = (accountStore.selectedAccounts || []).slice()
    const filters = {
      accounts,
      sortBy: camelize(SortCriteria.CONVERSIONRATE),
      filters: dashboardUIStore.globalFiltersQuery,
    }

    const data = await getTeamLeaderboards(filters)

    runInAction(() => {
      this.leaderBoardByConversionRate = data.records
      this.loadingStates.leaderBoardByConversionRate = false
    })
  }

  @action
  private async fetchOfficeHandlingRate() {
    this.loadingStates.frontDeskKPI = true

    const accountIds = (accountStore.selectedAccounts || []).slice()
    const filters = {
      accountIds,
      filters: dashboardUIStore.globalFiltersQuery,
    }

    const data = await getOfficeHandlingRate(filters)
    runInAction(() => {
      this.officeHandlingRate = data
      this.loadingStates.frontDeskKPI = false
    })
  }

  @action
  private async fetchPriceRelatedAnswers() {
    this.loadingStates.priceRelatedAnswer = true

    const accountIds = (accountStore.selectedAccounts || []).slice()
    const filters = {
      accountIds,
      filters: dashboardUIStore.globalFiltersQuery,
    }

    const data = await getPricingRelatedAnswers(filters)
    const { records, startRecordDate } = data

    runInAction(() => {
      this.pricingRelatedAnswers = records
      this.pricingRelatedAnswersStart = startRecordDate
      this.loadingStates.priceRelatedAnswer = false
    })
  }

  @action
  private async fetchCallOnHoldMetrics() {
    this.loadingStates.callOnHoldMetrics = true

    const accountIds = (accountStore.selectedAccounts || []).slice()
    const filters = {
      accountIds,
      filters: dashboardUIStore.globalFiltersQuery,
    }

    const records = await getCallOnHoldMetrics(filters)

    runInAction(() => {
      this.callOnHoldMetrics = records
      this.loadingStates.callOnHoldMetrics = false
    })
  }

  @action.bound
  toggleRankSort() {
    this.nameSortDir = undefined
    this.rankSortDir = this.rankSortDir === 'asc' ? 'desc' : 'asc'
    this.leaderBoard = this.leaderBoard.sort(
      (a: StaffInterface, b: StaffInterface) => {
        return this.rankSortDir === 'asc' ? a.rank - b.rank : b.rank - a.rank
      }
    )
  }

  /**
   * @TODO: Sorting should be done in the endpoint.
   * Since we don't do pagination for displaying staffs and the endpoint is not yet optimized, sorting is done in front-end for now.
   */
  @action.bound
  toggleNameSort() {
    this.rankSortDir = undefined
    this.nameSortDir = this.nameSortDir === 'asc' ? 'desc' : 'asc'
    this.leaderBoard = this.leaderBoard.sort(
      (a: StaffInterface, b: StaffInterface) => {
        if (this.nameSortDir === 'asc') {
          return a.name.localeCompare(b.name)
        } else {
          return b.name.localeCompare(a.name)
        }
      }
    )
  }

  // --- ACTION w/o SIDE EFFECTS --- //

  @action
  private reset() {
    this.sortBy = 'appointments'
    this.loadingStates = {
      leaderBoard: false,
      leaderBoardByConversionRate: false,
      frontDeskKPI: false,
      priceRelatedAnswer: false,
      frontDeskStaff: false,
      callOnHoldMetrics: false,
    }
  }

  @action
  setSortBy(value: string): void {
    this.sortBy = value
  }

  // --- COMPUTED --- //

  @computed
  get leaderboardAllCallCount(): number {
    return this.leaderBoard.reduce(
      (accumulator, currentelement) =>
        accumulator + currentelement.callMadeCount,
      0
    )
  }

  @computed
  get leaderboardOpportunityCount(): number {
    return this.leaderBoard.reduce(
      (accumulator, currentelement) =>
        accumulator + currentelement.opportunityCallCount,
      0
    )
  }

  @computed
  get leaderboardAppointmentCount(): number {
    return this.leaderBoard.reduce(
      (accumulator, currentelement) =>
        accumulator + currentelement.appointmentsMadeCount,
      0
    )
  }

  @computed
  get leaderboardAppointmentCallCount(): number {
    return this.leaderBoard.reduce(
      (accumulator, currentelement) =>
        accumulator + currentelement.appointmentsCallCount,
      0
    )
  }

  @computed
  get leaderboardAverageConversionRate(): number {
    return Math.round(
      (this.leaderboardAppointmentCallCount /
        this.leaderboardOpportunityCount) *
        100
    )
  }

  @computed
  get leaderboardStaffCount(): number {
    return this.leaderBoard.length
  }

  @computed
  get isLoading() {
    const loading = values(this.loadingStates)
    return loading.some(Boolean)
  }
}

export default new FrontDeskStore()
