import React, { forwardRef } from 'react'

// Mui
import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuProps,
} from '@material-ui/core'

// My component
import MenuItem, { Prop as MenuItemProp } from './MenuItem'

export interface Prop extends Partial<MenuProps> {
  items: MenuItemProp[]
  open: boolean
  onClose?: () => void
}

export default function Menu(props: Prop) {
  const {
    items = [],
    open,
    anchorEl,
    anchorOrigin,
    transformOrigin,
    // anchorOrigin = {
    //   vertical: 'bottom',
    //   horizontal: 'right',
    // },
    // transformOrigin = {
    //   vertical: 'top',
    //   horizontal: 'right',
    // },
    onClose,
    ...restProps
  } = props

  return (
    <MuiMenu
      anchorEl={anchorEl}
      getContentAnchorEl={undefined}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      onClose={onClose}
      {...restProps}
    >
      {items.map(({ selected, ...restProps }) => {
        return <MenuItem selected={selected} {...restProps} />

        // return (
        //   <MenuItem
        //     key={restProps.key}
        //     selected={selected}
        //     label={restProps.label}
        //     icon={restProps.icon}
        //     onClick={restProps.onClick}
        //     onClose={restProps.onClose}
        //     menuProps={restProps.menuProps}
        //   />
        // )
      })}
    </MuiMenu>
  )
}
