import React from 'react'
import { Observer } from 'mobx-react'
import { useLocation } from 'react-router'

import logo from 'assets/img/mvp-logo.png'
import { Theme } from 'components/Login/LoginForm'
import LoginForm from 'components/Login/LoginForm'
import MVPFlashMessages from 'components/MVP/MVPFlashMessages'
import appState from 'config/store/AppState'
import ReferralAd from 'views/Ads/ReferralAd'
import useStyles from './Login.styles'

const Login: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  return (
    <div className={classes.body}>
      <MVPFlashMessages />
      <div className={classes.container}>
        <Observer
          render={() => {
            if (appState.expiredSession) {
              appState.errorMessage(
                'Your session has expired. Please login again.'
              )
              appState.toggleFlash(true)
            }
            return (
              <React.Fragment>
                <div className={classes.formWrapper}>
                  <LoginForm theme={Theme.Dark} location={location} />
                  <div className={classes.footer}>
                    <img
                      className={classes.logo}
                      src={logo}
                      alt="MVP Mailhouse"
                    />
                  </div>
                </div>
                <ReferralAd />
              </React.Fragment>
            )
          }}
        />
      </div>
    </div>
  )
}

export default Login
