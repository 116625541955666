import React, { useCallback } from 'react'

// Mui
import { Drawer, Divider, Theme, useMediaQuery } from '@material-ui/core'

// Util
import { observer } from 'mobx-react'
import cx from 'classnames'

// My util
import { useStores } from 'config/store'
import useStyles from './NavigationBar.styles'

// My components
import UserAccountSelector from 'components/MVP/Navigation/UserAccountSelector'
import NavigationBarLinks from 'components/MVP/Navigation/NavigationBarLinks'

const NavigationBar = () => {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const { appState } = useStores()
  const { setNavDrawer } = appState

  const { isDrawerOpen } = appState
  // Drawer width is always expanded in mobile so that
  // Mui drawer will do the slide in-out to hide/show drawer.
  const isDesktopDrawerOpen = isDesktop ? isDrawerOpen : true

  const handleToggleDrawer = useCallback(() => {
    appState.setNavDrawer(!isDrawerOpen)
  }, [isDrawerOpen])

  const handleDrawerOnClose = useCallback(() => {
    !isDesktop && setNavDrawer(false)
  }, [isDesktop, setNavDrawer])

  const handleLinkClick = useCallback(() => {
    !isDesktop && setNavDrawer(false)
  }, [isDesktop, setNavDrawer])

  return (
    <>
      <div className={classes.root}>
        <Drawer
          variant={isDesktop ? 'permanent' : 'temporary'}
          classes={{
            paper: cx(classes.drawerPaper, {
              [classes.drawerPaperClose]: !isDesktopDrawerOpen,
            }),
          }}
          open={isDrawerOpen}
          onClose={handleDrawerOnClose}
        >
          <div className={classes.logoPanel}>
            <span
              className={cx(classes.logo, classes.logoExpanded, {
                [classes.hide]: !isDesktopDrawerOpen,
              })}
            />
            <span
              className={cx(classes.logo, classes.logoCollapsed, {
                [classes.hide]: isDesktopDrawerOpen,
              })}
            />
          </div>

          <Divider className={classes.divider} />

          <UserAccountSelector drawerOpen={handleToggleDrawer} />

          <Divider className={classes.divider} />

          <NavigationBarLinks
            expanded={isDesktopDrawerOpen}
            onToggleClick={handleToggleDrawer}
            onLinkClick={handleLinkClick}
          />
        </Drawer>
        {isDesktop && (
          <div
            className={cx(
              classes.hoverBar,
              isDrawerOpen ? classes.hoverBarExpanded : null
            )}
            onClick={handleToggleDrawer}
          />
        )}
      </div>
    </>
  )
}

export default observer(NavigationBar)
