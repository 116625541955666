import React from 'react'
import AllOfferSection from './AllOfferSection/AllOfferSection'

// STYLES
import { useStyles } from './AllOfferDrawer.style'

const AllOfferDrawer = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AllOfferSection />
    </div>
  )
}

export default AllOfferDrawer
