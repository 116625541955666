import React from 'react'
import AllServiceSection from './AllServiceSection/AllServiceSection'

// STYLES
import { useStyles } from './AllServiceDrawer.style'

const AllServiceDrawer = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AllServiceSection />
    </div>
  )
}

export default AllServiceDrawer
