import React from 'react'
import Appointment from 'models/Appointment'
import moment from 'moment'
import { useStyles } from './AppointmentPatientLabel.style'

interface AppointmentPatientInterface {
  appointment: Appointment
}

export const AppointmentPatientLabel = ({
  appointment,
}: AppointmentPatientInterface) => {
  const classes = useStyles()
  const patientName = `${appointment.firstName ?? '--'} ${
    appointment.lastName ?? '--'
  }`

  return (
    <div className={classes.colStackedText}>
      <div>{patientName}</div>
      <small>{appointment.phoneNumber ?? '-'}</small>
    </div>
  )
}
