import axios from 'axios'
import env from '../../env'

/**
 * Checks if contact email exists. 
 * If email is not available account activation email is sent from the endpoint
 *
 *
 * @param {string} email
 */
export const checkUserEmail = async (email) => {
    const endpoint = `${env.services.identity.baseUrl}/users/check`
    const response = await axios.post(endpoint, { email })

    return response
}

export default checkUserEmail
