import React from 'react'
import {
  Observer,
} from 'mobx-react'
import cx from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'

import AppState from '../../config/store/AppState'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  default: null
}

const contentStyles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[800],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

const FlashContent = withStyles(contentStyles)(
  (props) => {
    const { classes, className, message, onClose, variant, ...other } = props
    const Icon = variantIcon[variant]

    return (
      <SnackbarContent
        className={cx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            {Icon ? <Icon className={cx(classes.icon, classes.iconVariant)} /> : null}
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    )
  }
)

export default () => {
  const {
    toggleFlash,
    processFlashQueue
  } = AppState

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    toggleFlash(false)
  }

  const handleExited = () => processFlashQueue()

  return (
    <Observer
      render={() => {
        const {
          isFlashing,
          flash,
        } = AppState
        const {
          key,
          type,
          message,
          actions,
        } = flash || {}

        return (
          <Snackbar
            key={key}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={isFlashing}
            autoHideDuration={5000}
            onClose={handleClose}
            TransitionProps={{
              onExited: handleExited
            }}
            ContentProps={{
              'aria-describedby': 'flash-message',
            }}
            action={actions}
          >
            <FlashContent
              onClose={handleClose}
              variant={type}
              message={message}
            />
          </Snackbar>
        )
      }}
    />
  )
}
