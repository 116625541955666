import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: '24px 34px 17px',
    },
    title: {
      fontSize: '1.625rem',
      fontWeight: 'bold',
      lineHeight: 1,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
)

export default useStyles
