import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: 'solid 1px #e9e6e7',
      borderRadius: 10,
      padding: '12px 16px',
      height: 'fit-content',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    containerForAccordion: {
      width: '100%',
      border: 'none',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: 'solid 1px #e9e6e7',
      width: '100%',
      paddingBottom: 8,
      marginBottom: 20,
      '& h3': {
        fontWeight: 'bold',
        fontSize: 17,
        position: 'relative',
      },
    },
    tooltip: {
      backgroundColor: '#222',
      '&::after': {
        display: 'none',
      },
    },
    grade_icon: {
      color: '#d3d3d3',
      verticalAlign: 'middle',
      fontSize: '1.2rem',
      marginLeft: 10,
    },
  })
)
