import React from 'react'

// STYLE
import { useStyles } from './AppointmentTable.style'

import { repository as appointmentsRepo } from 'src/models/Appointment'
import moment from 'moment'
import cx from 'classnames'
import Call from 'models/Call'

interface IAppointmentTable {
  call: Call | null
}

const AppointmentTable = (props: IAppointmentTable) => {
  const classes = useStyles()
  const call = props.call

  if (!call) {
    return <div />
  }

  return (
    <section className={classes.appDetailsContainer}>
      <div className={classes.apptDetails}>
        <div className={classes.title}>Scheduled Appointments</div>
        <table className={classes.table}>
          {!!call.appointments.length && (
            <tr className={classes.row}>
              <thead className={classes.thead}>Name</thead>
              <thead className={classes.thead}>Appointment Date</thead>
              <thead className={classes.thead}>Appointment Time</thead>
            </tr>
          )}
          <tbody>
            {call.appointments.length > 0 ? (
              call.appointments.slice().map((id, key) => {
                const appointment = appointmentsRepo.get(id)
                appointment.isLoading

                return (
                  <tr
                    className={cx(
                      classes.row,
                      appointment.isTemp && classes.tempStatus
                    )}
                  >
                    <td className={classes.td}>{appointment.fullName}</td>
                    <td className={classes.td}>
                      {appointment.dateTime
                        ? moment(appointment.dateTime).format('M/D/YY')
                        : '-'}
                    </td>
                    <td className={classes.td}>
                      {appointment.dateTime
                        ? moment(appointment.dateTime).format('h:mm a')
                        : '-'}
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr className={cx(classes.row)}>
                <div className={classes.noAppointment}>No appointment set</div>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default AppointmentTable
