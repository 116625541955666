import AppState from 'config/store/AppState'
import API, { DATA_API } from 'helpers/api'
import { processContactSettingsData } from './processContactSettingsData'


export default async () => {
  const contactId = AppState.contactId
  const name = AppState.profileName
  const result = await API.get({
    api: DATA_API,
    url: `/settings/contact/${contactId}`,
  })

  const { notification } = result

  return processContactSettingsData(notification, contactId, name)
}