import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useFrontDeskStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    tableRow: {
      color: '#ED154E',
    },
    active_row: {
      backgroundColor: '#fff5f8',
    },
    active_column: {
      color: '#ED154E !important',
      fontWeight: 'bold',
    },
    // Tab
    tab_label: {
      textTransform: 'capitalize',
      fontSize: '.9rem',
      backgroundColor: '#FADCE5',
      fontWeight: 'bold',
    },

    // LEADERBOARD
    leaderboard_container: {
      margin: '1rem 0',
      display: 'grid',
      gridTemplateColumns: '1.5fr 1fr',
    },

    leaderboardMobile: {
      '&$leaderboard_container': {
        overflow: 'hidden',
      },
    },

    // LEADERBOARD TABLE
    leaderboard_table: {
      borderRadius: '10px 0 0 10px',
      display: 'flex',
    },

    // STAFF DETAILS
    leaderboard_staff: {
      backgroundColor: '#fff',
      borderRadius: '0 10px 10px 0',
      height: '100%',
    },
    leaderboard_staff_container: {
      border: '1px solid #d3d3d3',
      borderLeft: '0',
      display: 'block',
      height: '100%',
      borderRadius: '0 10px 10px 0',
    },
    leaderboard_staff_wrapper: {
      height: '100%',
      padding: '.5rem 2rem',
      borderRadius: '0 10px 0 0',
      backgroundColor: '#fbfbfb',
    },
    leaderboard_staff_details: {},
    leaderboard_staff_rank: {
      backgroundColor: '#fff',
      padding: '.2rem .7rem',
      border: ' 1px solid #e7e7e7',
      width: 'fit-content',
      fontWeight: 'bold',
    },
    leaderboard_staff_name: {
      fontSize: '1.8rem',
      marginTop: '1rem',
      fontWeight: 'bold',
      lineHeight: 1,
    },
    leaderboard_staff_office: {
      marginBottom: '1.5rem',
    },
    leaderboard_staff_stats_container: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      height: '100%',
    },

    leaderboard_staff_stats_box: {
      border: '1px solid #e1e1e1',
      borderRight: '1px solid #e1e1e1',
      padding: '1rem 1.5rem',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
    leaderboard_staff_stats_icon: {
      width: '2rem',
      color: '#ED154E',
      opacity: '70%',
    },
    leaderboard_staff_stats_label: {
      fontSize: '.9rem',
      fontWeight: 'bold',
      color: '#67585c',
      lineHeight: '1.3',
      marginBottom: 20,
    },
    leaderboard_staff_stats_value: {
      fontSize: '2.4rem',
      marginBottom: '1rem',
    },
    leaderboard_staff_stats_value_disabled: {
      color: '#bbbebf',
    },
    percentageSign: {
      fontSize: '2rem',
    },
    percentageSignDisabled: {
      color: '#bbbebf',
    },

    // CHIP COLORS
    green_chip: {
      color: '#56B863 !important',
      backgroundColor: '#EBF9F1 !important',
      fontWeight: 'bold',
      padding: '.5rem 1rem',
    },
    orange_chip: {
      color: '#F2A63B !important',
      backgroundColor: '#FFF9DE !important',
      fontWeight: 'bold',
      padding: '.5rem 1rem',
    },
    red_chip: {
      color: '#E13426 !important',
      backgroundColor: '#FFF0F0 !important',
      fontWeight: 'bold',
      padding: '.5rem 1rem',
    },
    disabled_chip: {
      color: '#8c9aa9 !important',
      backgroundColor: '#f4f4f4 !important',
      fontWeight: 'bold',
      padding: '.5rem 1rem',
    },

    // GRADE
    grade: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '.5rem',
      marginBottom: '1rem',
      marginTop: '1rem',
      cursor: 'pointer',
    },
    grade_icon: {
      color: '#d3d3d3',
    },
    grade_tip: {
      width: '4rem',
      height: '2rem',
    },
    evaluation: {
      fontSize: '.8rem',
      gap: '.8rem',
    },
    evaluationDisabled: {
      color: '#8c9aa9',
    },

    // TOOLTIP
    tooltip: {
      backgroundColor: '#222',
      '&::after': {
        display: 'none',
      },
    },
    cardWrapper: { marginBottom: '1.5rem' },
    accordionWrapper: {
      borderRadius: 10,
      overflow: 'hidden',
      border: '1px solid #d3d3d3',
      marginBottom: 12,
    },
  })
)
