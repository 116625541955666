import API, { DATA_API } from 'src/helpers/api'

export default async ({ id, name }) => {
  const result = await API.put({
    api: DATA_API,
    url: `/mailings/${id}`,
    data: {
      name,
    },
  })

  return result
}
