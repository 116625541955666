import React from 'react'

import { KPICardContainer, KPIGradeEvaluator, KPIValues } from '../KPICard'
import { LinearProgress } from 'components/CallPro/LinearProgress'

import { useStyles } from './AskedForAppointment.styles'
import { useStores } from 'config/store'
import { formatNumber } from 'helpers/utility'
import { ProTip } from '../ProTip'

import ProTipImage from '../../../../assets/img/call-pro/pro-tips/pro-tip-offers.png'
import GradeToolTipValue from '../GradeToolTipValue'
import { getGradeName } from '../Utils'
import SeeIndividualMetric from './SeeIndividualMetric/SeeIndividualMetric'

interface AskedForAppointmentInterface {
  isAccordionContent?: boolean
}

const AskedForAppointment = (props: AskedForAppointmentInterface) => {
  const classes = useStyles()
  const { isAccordionContent } = props
  const { frontDeskStore } = useStores()
  const { officeHandlingRate } = frontDeskStore

  const total = officeHandlingRate.validAskedForAppointment
  const askedForAppointmentPercentage =
    (officeHandlingRate.askedForAppointmentCount / total) * 100
  const didNotAskPercentage =
    ((total - officeHandlingRate.askedForAppointmentCount) / total) * 100

  const grading = [
    {
      name: 'A',
      min: 86,
      max: undefined,
    },
    {
      name: 'B',
      min: 70,
      max: 85,
    },
    {
      name: 'C',
      min: 60,
      max: 69,
    },
    {
      name: 'D',
      min: 0,
      max: 59,
    },
  ]

  return (
    <KPICardContainer
      isAccordionContent={isAccordionContent}
      title="ASKED FOR APPOINTMENT"
      tooltip="The rate at which staff asks the caller if they would like to schedule an appointment during opportunity calls. Excludes calls where the caller requested an appointment upfront."
    >
      <KPIGradeEvaluator
        isAccordionContent={isAccordionContent}
        value={askedForAppointmentPercentage}
        total={formatNumber(officeHandlingRate.validAskedForAppointment)}
        totalText="Calls"
        grading={grading}
      />
      <div className={classes.progressWrapper}>
        <LinearProgress
          hideProgressDetails={true}
          value={officeHandlingRate.askedForAppointmentCount}
          total={total}
        />
      </div>
      <KPIValues
        isAccordionContent={isAccordionContent}
        data={[
          {
            percentage: askedForAppointmentPercentage,
            label: 'Asked',
            color: '#4498E7',
            details: officeHandlingRate.askedForAppointmentCount,
          },
          {
            percentage: didNotAskPercentage,
            label: 'Did not ask',
            color: '#d4d4d4',
            details: total - officeHandlingRate.askedForAppointmentCount,
          },
        ]}
      />
      <SeeIndividualMetric />
      <ProTip
        title="Schedule new patients by leveraging any advertised offers"
        content={GradeToolTipValue(
          'Asked For Appointment',
          getGradeName(grading, askedForAppointmentPercentage)
        )}
        image={ProTipImage}
      />
    </KPICardContainer>
  )
}

export default AskedForAppointment
