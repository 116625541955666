import React from 'react'
import { observer } from 'mobx-react'
import cx from 'classnames'
import { useStores } from 'config/store'

import Grid from '@material-ui/core/Grid'
import { preparePercentageValues } from './Utils'

// STYLES
import { useFrontDeskStyles } from './FrontDesk.styles'
import GradeEvaluator from './GradeEvaluator'
import {
  CallDashboardTabs,
  StaffInterface,
  CallOutcome,
} from 'interfaces/Call.interface'
import { CustomSkeleton } from './TableSkeleton'

interface StaffProfileProps {
  selectedStaff: StaffInterface
}

const StatPlaceholder = (props: any) => {
  const {
    isLoading,
    width,
    height,
    text,
    variant,
    style,
    className,
    handleClick,
  } = props

  return (
    <>
      {isLoading ? (
        <CustomSkeleton
          variant={variant ?? 'circle'}
          width={width}
          height={height}
        />
      ) : (
        <>
          <span style={style} className={className} onClick={handleClick}>
            {text || 0}
          </span>
        </>
      )}
    </>
  )
}

const StaffProfile = (props: StaffProfileProps) => {
  const classes = useFrontDeskStyles()
  const { selectedStaff } = props

  const { frontDeskStore, callsStore, dashboardUIStore } = useStores()
  const { loadingStates } = frontDeskStore

  const handleOpportunityCallsClick = () => {
    dashboardUIStore.setCurrentTabbedOpened(CallDashboardTabs.ALL_CALLS)
    callsStore.resetByTab(CallDashboardTabs.ALL_CALLS)
    callsStore.setFilters({
      callHandlerId: selectedStaff.id,
      outcome: CallOutcome.ALL_CALLS,
    })
    callsStore.refreshAllCallsTab()
  }

  // DEFAULT GRADING SYSTEM
  /*****************************************************/
  const defaultGradeSystem = [
    { grade: 'A', min: 85, max: 0 },
    { grade: 'B', min: 70, max: 84 },
    { grade: 'C', min: 30, max: 69 },
    { grade: 'D', min: 0, max: 29 },
  ]

  /*CHART PERCENTAGE VALUES*/
  /*****************************************************/
  const valueAskedForAppointment = preparePercentageValues({
    percentage: selectedStaff.askedforAppointmentRate,
  })

  const valueAskedForSource = preparePercentageValues({
    percentage: selectedStaff.askedForSourceRate,
  })

  const valueGoodImpression = preparePercentageValues({
    percentage: selectedStaff.goodImpressionRate,
  })

  /*FINAL CHART GRADE DATA*/
  /*****************************************************/
  const askedAppointmentGrade = GradeEvaluator(
    { percentage: valueAskedForAppointment.valueNumber },
    defaultGradeSystem
  )
  const askedSourceGrade = GradeEvaluator(
    { percentage: valueAskedForSource.valueNumber },
    defaultGradeSystem
  )
  const goodImpressionGrade = GradeEvaluator(
    { percentage: valueGoodImpression.valueNumber },
    defaultGradeSystem
  )

  const hasOpportunityCall = selectedStaff.opportunityCallCount > 0

  return (
    <div className={classes.leaderboard_staff_container}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} sm={5}>
          <div className={classes.leaderboard_staff_wrapper}>
            <div className={classes.leaderboard_staff_details}>
              {loadingStates.leaderBoard ? (
                <>
                  <CustomSkeleton variant="text" width={'100%'} height={70} />
                </>
              ) : (
                <>
                  <div className={classes.leaderboard_staff_name}>
                    {selectedStaff.name || 'Staff Name'}
                  </div>
                </>
              )}

              <div className={classes.leaderboard_staff_office}>
                <span>{selectedStaff.accountName}</span>
              </div>

              <Grid
                container
                spacing={0}
                className={classes.leaderboard_staff_rank}
              >
                <div>
                  {loadingStates.leaderBoard ? (
                    <>
                      <CustomSkeleton
                        variant="text"
                        width={'100%'}
                        height={20}
                      />
                    </>
                  ) : (
                    <>
                      <span style={{ fontSize: '.9rem', color: '#67585c' }}>
                        Rank:{' '}
                      </span>
                    </>
                  )}

                  <StatPlaceholder
                    style={{
                      fontSize: '1rem',
                      margin: '.5rem',
                    }}
                    variant="text"
                    isLoading={loadingStates.leaderBoard}
                    width={50}
                    height={25}
                    text={selectedStaff.rank}
                  />
                </div>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={7}>
          {' '}
          <div className={classes.leaderboard_staff_stats_container}>
            <Grid
              item
              className={classes.leaderboard_staff_stats_box}
              style={{ paddingBottom: '0' }}
            >
              <StatPlaceholder
                variant="text"
                className={classes.leaderboard_staff_stats_label}
                isLoading={loadingStates.leaderBoard}
                width={79}
                height={25}
                text={'Opportunity Calls'}
              ></StatPlaceholder>

              <StatPlaceholder
                className={classes.leaderboard_staff_stats_value}
                isLoading={loadingStates.leaderBoard}
                width={50}
                height={50}
                text={selectedStaff.opportunityCallCount}
                style={{ cursor: 'pointer' }}
                handleClick={handleOpportunityCallsClick}
              />
            </Grid>
            <Grid
              item
              className={classes.leaderboard_staff_stats_box}
              style={{ paddingBottom: '0' }}
            >
              <StatPlaceholder
                variant="text"
                className={classes.leaderboard_staff_stats_label}
                isLoading={loadingStates.leaderBoard}
                width={79}
                height={25}
                text={'Appointments'}
              ></StatPlaceholder>

              <StatPlaceholder
                className={classes.leaderboard_staff_stats_value}
                isLoading={loadingStates.leaderBoard}
                width={50}
                height={50}
                text={selectedStaff.appointmentsMadeCount}
              />
            </Grid>
            <Grid
              item
              className={classes.leaderboard_staff_stats_box}
              style={{ paddingBottom: '0' }}
            >
              <StatPlaceholder
                variant="text"
                className={classes.leaderboard_staff_stats_label}
                isLoading={loadingStates.leaderBoard}
                width={79}
                height={25}
                text={'Appointment Calls'}
              ></StatPlaceholder>

              <StatPlaceholder
                className={classes.leaderboard_staff_stats_value}
                isLoading={loadingStates.leaderBoard}
                width={50}
                height={50}
                text={selectedStaff.appointmentsCallCount}
              />
            </Grid>
            <Grid
              item
              className={classes.leaderboard_staff_stats_box}
              style={{ paddingBottom: '0', borderRight: '0' }}
            >
              <StatPlaceholder
                variant="text"
                className={classes.leaderboard_staff_stats_label}
                isLoading={loadingStates.leaderBoard}
                width={79}
                height={25}
                text={'Conversion Rate'}
              ></StatPlaceholder>
              <Grid>
                <StatPlaceholder
                  className={classes.leaderboard_staff_stats_value}
                  isLoading={loadingStates.leaderBoard}
                  width={50}
                  height={50}
                  text={selectedStaff.conversionRate}
                />
                {!loadingStates.leaderBoard && (
                  <span className={classes.percentageSign}>%</span>
                )}
              </Grid>
            </Grid>

            <Grid item className={classes.leaderboard_staff_stats_box}>
              <StatPlaceholder
                variant="text"
                className={classes.leaderboard_staff_stats_label}
                isLoading={loadingStates.leaderBoard}
                width={79}
                height={25}
                text={`Asked for Appointments (${selectedStaff.askedForAppointmentCount})`}
              ></StatPlaceholder>{' '}
              <Grid>
                <StatPlaceholder
                  className={cx(classes.leaderboard_staff_stats_value, {
                    [classes.leaderboard_staff_stats_value_disabled]:
                      !hasOpportunityCall,
                  })}
                  isLoading={loadingStates.leaderBoard}
                  width={50}
                  height={50}
                  text={selectedStaff.askedforAppointmentRate}
                />
                {!loadingStates.leaderBoard && (
                  <span
                    className={cx(classes.percentageSign, {
                      [classes.percentageSignDisabled]: !hasOpportunityCall,
                    })}
                  >
                    %
                  </span>
                )}
              </Grid>
              {loadingStates.leaderBoard ? <></> : <></>}
            </Grid>
            <Grid item className={classes.leaderboard_staff_stats_box}>
              <StatPlaceholder
                variant="text"
                className={classes.leaderboard_staff_stats_label}
                isLoading={loadingStates.leaderBoard}
                width={79}
                height={25}
                text={`Asked for Source (${selectedStaff.askedForSourceCount})`}
              ></StatPlaceholder>
              <Grid>
                <StatPlaceholder
                  className={cx(classes.leaderboard_staff_stats_value, {
                    [classes.leaderboard_staff_stats_value_disabled]:
                      !hasOpportunityCall,
                  })}
                  isLoading={loadingStates.leaderBoard}
                  width={50}
                  height={50}
                  text={selectedStaff.askedForSourceRate}
                />
                {!loadingStates.leaderBoard && (
                  <span
                    className={cx(classes.percentageSign, {
                      [classes.percentageSignDisabled]: !hasOpportunityCall,
                    })}
                  >
                    %
                  </span>
                )}
              </Grid>
              {loadingStates.leaderBoard ? <></> : <></>}
            </Grid>
            <Grid
              item
              className={classes.leaderboard_staff_stats_box}
              style={{
                borderRight: '0',
              }}
            >
              <StatPlaceholder
                variant="text"
                className={classes.leaderboard_staff_stats_label}
                isLoading={loadingStates.leaderBoard}
                width={79}
                height={25}
                text={`Good Impression (${selectedStaff.goodImpressionCount})`}
              ></StatPlaceholder>
              <Grid>
                <StatPlaceholder
                  className={cx(classes.leaderboard_staff_stats_value, {
                    [classes.leaderboard_staff_stats_value_disabled]:
                      !hasOpportunityCall,
                  })}
                  isLoading={loadingStates.leaderBoard}
                  width={50}
                  height={50}
                  text={selectedStaff.goodImpressionRate}
                />
                {!loadingStates.leaderBoard && (
                  <span
                    className={cx(classes.percentageSign, {
                      [classes.percentageSignDisabled]: !hasOpportunityCall,
                    })}
                  >
                    %
                  </span>
                )}
              </Grid>
              {loadingStates.leaderBoard ? <></> : <></>}
            </Grid>
            <Grid
              item
              className={classes.leaderboard_staff_stats_box}
              style={{
                borderRight: '0',
              }}
            >
              <StatPlaceholder
                variant="text"
                className={classes.leaderboard_staff_stats_label}
                isLoading={loadingStates.leaderBoard}
                width={79}
                height={25}
                text={`Asked for Addt. Appointments (${selectedStaff.askedforAdditionalAppointmentCount})`}
              ></StatPlaceholder>
              <Grid>
                <StatPlaceholder
                  className={cx(classes.leaderboard_staff_stats_value, {
                    [classes.leaderboard_staff_stats_value_disabled]:
                      !hasOpportunityCall,
                  })}
                  isLoading={loadingStates.leaderBoard}
                  width={50}
                  height={50}
                  text={selectedStaff.askedforAdditionalAppointmentRate}
                />
                {!loadingStates.leaderBoard && (
                  <span
                    className={cx(classes.percentageSign, {
                      [classes.percentageSignDisabled]: !hasOpportunityCall,
                    })}
                  >
                    %
                  </span>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <div></div>
    </div>
  )
}

export default observer(StaffProfile)
