import { useQuery } from '@tanstack/react-query'
import { useStores } from 'config/store'
import {
  getStaffListAskedForSource,
  Response,
} from 'services/calls/metrics/getStaffListAskedForSource'

export function useReactQuery(args: { enabled?: boolean } = {}) {
  const { enabled = true } = args
  const { dashboardUIStore, accountStore } = useStores()
  const accountIds = (accountStore.selectedAccounts || []).slice()
  const filters = dashboardUIStore.globalFiltersQuery
  const key = JSON.stringify({ filters, accountIds })

  return useQuery({
    enabled,
    queryKey: ['calls', 'metrics', 'staff-asked-for-source', key],
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    placeholderData: { summary: {}, records: [] } as never as Response,
    queryFn: async ({ signal }) => {
      return getStaffListAskedForSource({ signal, accountIds, filters })
    },
  })
}
