import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const DownloadCsv = (props) => {
  const _props = {
    ...props,
    viewBox: '-4 0 24 24'
  }
  return (
    <SvgIcon {..._props} >
      <path id="file-delimited" d="M13,9h5.5L13,3.5V9M6,2h8l6,6V20a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V4A1.993,1.993,0,0,1,6,2m8,13V11H10v4h2.3c.3,2-.3,3-2.6,4.38l1.15.82A7.263,7.263,0,0,0,14,15Z" transform="translate(-4 -2)" fill="#787878"/>
    </SvgIcon>
  )
}

export default DownloadCsv
