import _get from 'lodash/get'
import _uniq from 'lodash/uniq'
import API, { MAPS_API } from 'src/helpers/api'
import processPatientList from './factory/processPatientList'
import { repository as patientListRepo } from 'src/models/PatientList'

/**
 *
 * @param {object} options
 * @return MailingArea[]
 */
const getPatientList = async (accounts, { cancelToken } = {}) => {
  const result = await API.get({
    api: MAPS_API,
    url: '/accounts/patient-lists',
    params: {
      accounts: accounts.toString()
    },
    cancelToken: cancelToken.token
  }) || []

  result.sort((a, b) => {
    if (!a.audit !== !b.audit) {
      return (b.audit || '').toString().localeCompare(a.audit || '')
    }
    return new Date(b.metadata.modifiedAt) - new Date(a.metadata.modifiedAt)
  })

  const patientLists = result.map(row => {
    const patientList = processPatientList(row)
    return patientListRepo.upsert(patientList)
  })

  return patientLists
}

export default getPatientList
