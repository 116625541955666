import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'
import withStyles from '@material-ui/core/styles/withStyles'
import { Redirect } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'

import page from 'src/services/analytics/page'

const propTypes = {}

const defaultProps = {}

const styles = (theme) => ({})

@inject(
  'appState',
  'mapsStore',
  'mailingsStore',
  'settingsStore'
)
@withStyles(styles)
class Logout extends Component {
  state = {
    cleared: false,
    isAutoLogin: false,
    isAuthenticated: false,
    isAuthenticating: true,
  }

  componentDidMount() {
    const { track, mapsStore, mailingsStore, settingsStore } =
      this.props

    if (track) {
      page('Logout')
    }

    this.props.appState
      .clearSession()
      .then(() => {
        this.setState({ cleared: true, isAuthenticating: false })
        mapsStore.cleanStore()
        mailingsStore.cleanStore()
        settingsStore.cleanStore()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  render() {
    const { classes } = this.props
    return this.state.cleared ? (
      this.state.isAuthenticating ? (
        'Logging in ...'
      ) : (
        <Redirect to="/login" />
      )
    ) : this.state.isAuthenticated ? (
      <Redirect to="/campaigns" />
    ) : (
      'Logging off ...'
    )
  }
}

Logout.propTypes = propTypes
Logout.defaultProps = defaultProps

export default Logout
