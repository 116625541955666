import React, { useState } from 'react'
import moment from 'moment'
import cx from 'classnames'
import Call from 'models/Call'

import { getTagStyle } from 'helpers/callOutcome'
import { Recording } from 'components/CallPro/Recording/Recording'
import { MailingType } from 'components/CallPro/MailingType/MailingType'
import CallerCell from 'components/CallPro/Tables/CallerCell'
import Tooltip from '@material-ui/core/Tooltip'
import DescriptionIcon from '@material-ui/icons/Description'
import PhoneCallbackSharp from '@material-ui/icons/PhoneCallbackSharp'

import CallsBadge from 'components/Calls/CallsBadge'
import medalImg from 'src/assets/img/medal.png'
// STORE
import { useStyles } from './CallsTableColumns.style'
import { Outcome } from 'models/Call'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import { formatTextByIndustry } from 'helpers/industries'
import Account from 'models/Account'

export interface CallProColumns {
  id: string
  name: string
  sortable: boolean
  styles?: any
}

export const callProCallsColumns: (account: Account) => CallProColumns[] = (
  account
) => {
  return [
    {
      id: '',
      name: '',
      sortable: false,
    },
    {
      id: 'callDateTime',
      name: 'Date & Time',
      sortable: true,
    },
    {
      id: 'caller',
      name: 'Caller',
      sortable: false,
    },
    {
      id: 'patientType',
      name: formatTextByIndustry({
        account,
        text: `{CLIENT_LABEL} Type`,
      }),
      sortable: false,
    },
    {
      id: 'mailing',
      name: 'Type',
      sortable: false,
    },
    {
      id: 'answeredBy',
      name: 'Answered by',
      sortable: true,
    },
    {
      id: 'outcome',
      name: 'Outcome',
      sortable: false,
    },
    {
      id: '',
      name: 'Call Summary',
      sortable: false,
    },
    {
      id: 'duration',
      name: 'Recording',
      sortable: true,
    },
  ]
}

interface renderCallProDashboardColumnsArgs {
  calls: Call[]
  pageSize: number
  currentPage: number
  stopAudio: boolean
}

export const getLabelWrapper = (
  outcome: Outcome[],
  apptCount: number,
  classes: any,
  resolved: boolean,
  disableApptCount?: boolean
) => {
  let label = ''

  if (outcome.includes(Outcome.APPOINTMENT)) {
    if (disableApptCount) {
      label = 'Appointment'
    } else {
      label = `${apptCount} Appointment${apptCount > 1 ? 's' : ''}`
    }
  } else {
    label = outcome[0]
  }

  const singleTagStyle = getTagStyle(outcome[0], classes)

  const mergedTagStyle = (item: Outcome) => {
    return item.includes('Appointment')
      ? getTagStyle(Outcome.APPOINTMENT, classes)
      : getTagStyle(item, classes)
  }

  return (
    <div>
      {outcome.length > 1 ? (
        <div style={{ position: 'relative', width: 'fit-content' }}>
          <CustomTooltip
            content={
              <div className={classes.tooltipWrapper}>
                {outcome.map((item, index) => {
                  // remove the first tag since it's already showed
                  if (index !== 0) {
                    return (
                      <span
                        key={item}
                        style={{
                          marginBottom: index === outcome.length - 1 ? 0 : 10,
                        }}
                        className={cx(classes.label, mergedTagStyle(item))}
                      >
                        {item}
                      </span>
                    )
                  }
                })}
              </div>
            }
          >
            <span
              className={cx(classes.label, mergedTagStyle(label as Outcome))}
            >
              {label}
            </span>
          </CustomTooltip>
          <span className={classes.moreTabWrapper}> +{outcome.length - 1}</span>
        </div>
      ) : (
        <span className={cx(classes.label, singleTagStyle)}>{label}</span>
      )}

      <span>
        {resolved && (
          <Tooltip
            title="This call was resolved by a callback."
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <PhoneCallbackSharp className={classes.callbackIcon} />
          </Tooltip>
        )}
      </span>
    </div>
  )
}

export const renderCallProDashboardColumns = ({
  calls,
}: renderCallProDashboardColumnsArgs): any => {
  const classes = useStyles()
  const [currentlyPlaying, setIsCurrentlyPlaying] = useState('')

  const getAnsweredByLabel = (ivrOutcome: string, handlerName: string) => {
    if (ivrOutcome === 'Routed to voicemail') {
      return 'IVR / Voicemail'
    }

    if (ivrOutcome === 'Routed to staff') {
      const givenName =
        handlerName === 'Not Given' || handlerName === undefined
          ? '/ Name not given'
          : `/ ${handlerName}`
      return `IVR ${givenName}`
    }

    if (ivrOutcome === 'Routed to staff but not answered') {
      return 'IVR / Not answered'
    }

    if (ivrOutcome === 'Caller disconnected during IVR') {
      return 'IVR / Caller disconnected during IVR'
    }

    if (ivrOutcome === 'Routed to voicemail') {
      return 'IVR / Routed to voicemail'
    }
  }

  return calls.map((call: Call, key: number) => {
    const id = call.id
    const resolved = !!call.resolveDate
    const appointmentsCount = call.appointmentsCounter ?? 0
    const label = getLabelWrapper(
      call.outcome ?? [],
      appointmentsCount,
      classes,
      resolved
    )
    const dateTime = moment(call.callDateTime)
    let answeredBy =
      call.callAnsweredBy === 'Staff' ? call.handlerName : call.callAnsweredBy
    answeredBy = answeredBy?.replace('///None//', '')

    if (call.callAnsweredBy === 'IVR') {
      answeredBy = getAnsweredByLabel(
        call.ivrOutcome ?? '',
        call.handlerName ?? ''
      )
    }

    return {
      rowJsx: [
        <span className={classes.count}>
          {appointmentsCount <= 0 ? (
            <CallsBadge />
          ) : (
            <img src={medalImg} style={{ marginTop: '17px' }} />
          )}
          {appointmentsCount > 0 ? (
            <span className={classes.appointmentsCount}>
              {appointmentsCount}
            </span>
          ) : null}
        </span>,
        dateTime.isValid() ? dateTime.format('M/D/YY @ h:mm a') : '-',
        <CallerCell call={call} classes={classes} />,
        call.patientType,
        <MailingType call={call} />,
        answeredBy,
        label,
        <Tooltip
          hidden={!call.highlights}
          title={call.highlights ?? ''}
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <div>
            <DescriptionIcon className={classes.highlightIcon} />
          </div>
        </Tooltip>,
        <Recording
          handleAudioPlayPause={(id) => {
            setIsCurrentlyPlaying(id)
          }}
          call={call}
          currentlyPlaying={currentlyPlaying}
        />,
      ],
      id: id,
    }
  })
}
