import { createStyles, makeStyles } from '@material-ui/core/styles'

import MapIq from 'assets/img/mapiq.svg'
import MapIqActive from 'assets/img/mapiq-active.svg'

const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listMain: {
      marginBottom: 'auto',
    },
    listFooter: {
      marginTop: 'auto',
    },
    listItem: {
      paddingLeft: 22,
      paddingRight: 22,
      paddingTop: 16,
      paddingBottom: 18,
    },
    listItemButton: {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
      },
    },
    listItemActive: {
      boxShadow: 'inset 3px 0 #ED154E',
      backgroundColor: 'rgba(255, 255, 255, 0.03) !important',
      '& $listIcon': {
        color: '#ED154E',
      },
    },
    listIcon: {
      color: '#fff',
      fontSize: '24px',
      marginRight: 10,
      transform: 'scale(0.9)',
      minWidth: 36,
    },
    listText: {
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      margin: 0,
    },
    listTextPrimary: {
      fontSize: '16px',
      color: '#fff',
      fontFamily: 'Roboto',
    },
    listTextSecondary: {
      color: '#727272',
    },
    expanded: {},
    badge: {
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
    },
    badgeExpanded: {
      position: 'absolute',
      left: 220,
    },
    badgeInner: {
      right: 0,
    },
    divider: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    hide: {
      opacity: 0,
    },
    collapseLink: {
      color: 'rgba(211, 211, 211, 0.5)',
    },
    mapIntel: {
      background: `url(${MapIq}) no-repeat`,
      height: 23,
      width: 24,
    },
    mapIntelActive: {
      background: `url(${MapIqActive}) no-repeat`,
      height: 23,
      width: 24,
    },
    logoutIcon: {
      position: 'relative',
      right: '2px',
    },
    logoff: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '8px 20px 20px',
    },
    modalContainer: {
      // @ts-ignore
      position: 'absolute !important',
      filter: 'blur(0px) !important',
      '& *': {
        filter: 'blur(0px) !important',
      },
    },
  })
)

export default useStyles
