export default class AppError extends Error {
  public data: any
  public status: any

  constructor(message: any, data = {}, status = 500) {
    super(message)
    this.data = data
    this.status = status
  }
}
