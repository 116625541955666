import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '10px 22px',
      textTransform: 'capitalize',
      fontSize: 16,
    },
  })
)

export default useStyles
