import API, { DATA_API } from 'src/helpers/api'
import _isEmpty from 'lodash/isEmpty'

export default async (filters) => {
  if (_isEmpty(filters.accountIds)) {
    return {
      meta: {},
      records: [],
    }
  }

  const result = await API.get({
    api: DATA_API,
    url: `/get-office-handling-rate`,
    params: filters,
  })

  const { records } = result

  return records
}
