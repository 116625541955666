export const getEnumIndex = (list: any, find: string): number => {
  let index = 0
  Object.entries(list).map((val, _index) => {
    if (val[1] === find) {
      index = _index
    }
  })

  return index
}

// Convert input into number with comma
export const formatNumber = (input: string | number): string => {
  const nf = new Intl.NumberFormat('en-US')
  return nf.format(Number(input))
}
