import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import flatten from '@turf/flatten'

import getPracticeIconByHex from '../../components/Icons/Markers/PracticeColorIconSet'

import { Mailing, CarrierRoute } from '@mvp/mvp-react'
import API, { DATA_API, MAPS_API } from '../../helpers/api'

export interface MailingDetails {
  mailings: Mailing[]
  carrierRoutes: CarrierRoute[]
  officeMarkerUrl: string
  officeMarkerCoordinates: google.maps.LatLngLiteral
  parentAccount?: {
    officeMarkerUrl: string
    officeMarkerCoordinates: google.maps.LatLngLiteral
  }
}

export const getMailingByDesign = async (
  accountId: string,
  designId: string
) => {
  const mongoIds: string[] = []
  const carrierRoutes: any[] = []
  const mailingResults =
    (await API.get({
      api: DATA_API,
      url: `/accounts/${accountId}/designs/${designId}/mailings`,
    })) || []

  if (_isEmpty(mailingResults)) {
    return undefined
  }

  const mailings = _get(mailingResults, 'records')

  mailings.map((result: any) => {
    !mongoIds.includes(result.mongoId) && mongoIds.push(result.mongoId)
  })

  let geodataResults = []
  try {
    geodataResults = !_isEmpty(mongoIds) ? [] : await API.get({
      api: MAPS_API,
      url: `/geodata?ids=${mongoIds.toString()}`,
    })
  } catch (e) {
    console.error(e)
  }

  const geoJsons = geodataResults.filter((record: any) => {
    return mongoIds.includes(record.id)
  })

  if (!_isEmpty(geoJsons)) {
    mailings.map((mailing: any) => {
      const geoJson = geoJsons.filter((geo: any) => geo.id === mailing.mongoId)
      const geodata = flatten(_get(geoJson, '0.geodata'))
      const features = Array.from(_get(geodata, 'features', []))
      const _carrierRoutes = features.map((data) => {
        const { geometry, properties } = data

        return {
          carrierRoute: properties?.geocode,
          paths: [
            geometry?.coordinates.map((coord: any) => {
              return coord.map((c: any) => {
                return {
                  lat: c[1],
                  lng: c[0],
                }
              })
            }),
          ],
          options: {
            fillColor: mailing.zoneColor,
            fillOpacity: 0.4,
            strokeColor: mailing.zoneColor,
            strokeWeight: 1,
            strokeOpacity: 0.4,
            clickable: true,
            visible: true,
            zIndex: 1000,
          },
        }
      })
      carrierRoutes.push(_carrierRoutes)
    })
  }
  const _parentAccount = mailings.filter((mailing: any) => {
    return !_isEmpty(mailing.parentAccount)
  })

  const response: MailingDetails = {
    mailings: mailings as Mailing[],
    carrierRoutes: carrierRoutes.flat(1) as CarrierRoute[],
    officeMarkerUrl: getPracticeIconByHex(_get(mailings, '0.officeColor')),
    officeMarkerCoordinates: {
      lat: _get(mailings, '0.officeLocation.latitude'),
      lng: _get(mailings, '0.officeLocation.longitude'),
    } as google.maps.LatLngLiteral,
  }

  if (!_isEmpty(_parentAccount)) {
    // TODO: Update this directly to parent account from current given account
    response.parentAccount = {
      officeMarkerUrl: getPracticeIconByHex(
        _get(mailings, '0.parentAccount.officeColor')
      ),
      officeMarkerCoordinates: {
        lat: _get(mailings, '0.parentAccount.officeLocation.latitude'),
        lng: _get(mailings, '0.parentAccount.officeLocation.longitude'),
      } as google.maps.LatLngLiteral,
    }
  }

  return response
}
