import ApiHelper, { IDENTITY_API } from 'src/helpers/api'
import AppState from 'src/config/store/AppState'

/**
 * Activates the user account to use the user-supplied password
 * as login credentials
 *
 * @param {string} email
 * @param {string} password
 * @param {string} code
 */
export const activateUser = (email, password, code) => {
  return new Promise(async (resolve, reject) => {
    try {
      await ApiHelper.request({
        method: 'post',
        api: IDENTITY_API,
        url: '/contacts/activate',
        data: {
          email,
          code,
          password
        },
        skipAuthentication: true
      })
  
      await AppState.login({ email, password }, undefined)

      resolve(true)
    } catch(e) {
      console.log(e)
      reject(false)
    }
  })
 }

export default activateUser
