import React from 'react'

// core material ui components
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Skeleton from '@material-ui/lab/Skeleton'

// Icons
import { observer } from 'mobx-react'
import { Theme, useMediaQuery } from '@material-ui/core'

interface CustomSkeleton {
  height: string | number
  width: string | number
  variant: string | undefined | null
}

const TableSkeleton = () => {
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  return (
    <>
      {[...Array(5)].map((num) => (
        <TableRow>
          {[...Array(mobileDevice ? 2 : 5)].map((num) => (
            <TableCell align="left">
              <Skeleton animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

export const CustomSkeleton = (props: CustomSkeleton) => {
  const { width, height, variant } = props
  return <Skeleton variant={variant as any} width={width} height={height} />
}

export default observer(TableSkeleton)
