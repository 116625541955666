import React from 'react'
import { observer } from 'mobx-react'

// COMPONENTS
import OpportunityCalls from '../OpportunityCalls/OpportunityCalls'
import CustomTab from './Tabs/Tabs'
import AppointmentCalls from '../AppointmentCalls/AppointmentCalls'
import Appointments from '../Appointments/Appointments'
import MissedCalls from '../MissedCalls/MissedCalls'
import NonOpportunityCalls from '../NonOpportunityCalls/NonOpportunityCalls'
import AllStats from '../AllStats/AllStats'
import AllCalls from '../AllCalls/AllCalls'
import FollowupOpportunityCalls from '../FollowupOpportunityCalls/FollowupOpportunityCalls'

// UTITLITY
import { formatNumber } from 'helpers/utility'
import {
  CallSummaryCountInterface,
  TabContents,
} from 'interfaces/Call.interface'

// STATE STORE
import { useStores } from 'config/store'

const AllCallsPage = observer(() => {
  const { callsStore } = useStores()

  const getCalls = (count: CallSummaryCountInterface): TabContents[] => {
    return [
      {
        title: `Opportunity Calls (${formatNumber(count.opportunity) ?? 0})`,
        panel: <OpportunityCalls />,
      },
      {
        title: `Appointment Calls (${
          formatNumber(count.callsWithAppointment) ?? 0
        })`,
        panel: <AppointmentCalls />,
      },
      {
        title: `Appointments (${formatNumber(count.appointment) ?? 0})`,
        panel: <Appointments />,
      },
      {
        title: `Missed Calls (${formatNumber(count.missed) ?? 0})`,
        panel: <MissedCalls />,
      },
      {
        title: `Follow-up Calls (${formatNumber(count.followUp) ?? 0})`,
        panel: <FollowupOpportunityCalls />,
      },
      {
        title: `Non-Opportunity (${formatNumber(count.nonOpportunity) ?? 0})`,
        panel: <NonOpportunityCalls />,
      },
      {
        title: `All Calls (${formatNumber(count.all) ?? 0})`,
        panel: <AllCalls />,
      },
    ]
  }

  return (
    <>
      <AllStats />
      <CustomTab
        tabContents={getCalls(callsStore.callSummaryCount)}
        disabled={callsStore.isAllCallSubTabsLoading}
      />
    </>
  )
})

export default AllCallsPage
