import React, { useCallback, useEffect, useState } from 'react'
import { Observer } from 'mobx-react'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'

import API, { DATA_API } from 'helpers/api'
import accountStore from 'config/store/AccountStore'

import AsyncFilterField, { AsyncFilterFieldProp } from './AsyncFilterField'
import { useCallTableFilterStore } from '../../store/CallTableFilterStore'
import { useStores } from 'config/store'
import { repository as offerRepo } from 'models/offers/Offer'

type SfFieldType = {
  Offer__c: string | null
  Offer__r: null | { Display_Name__c: string }
}

const getSource = async (params: { accountIds: string[] }) => {
  const result = (await API.get({
    api: DATA_API,
    url: '/offers/inquiries',
    params,
  })) as []

  const mapResponse = (a: SfFieldType): AsyncFilterFieldProp['source'][0] => ({
    label: a?.Offer__r?.Display_Name__c ?? 'N/A',
    value: a?.Offer__c ?? '',
  })

  const offersMap = new Map<
    AsyncFilterFieldProp['source'][0]['value'],
    AsyncFilterFieldProp['source'][0]
  >()
  const offers = (result ?? [])
    .filter((a: SfFieldType) => !_isNil(a?.Offer__c))
    .map(mapResponse)
  offers.forEach((a) => offersMap.set(a.value, a))

  return Array.from(offersMap.values())
}

const KEY = 'offer'
const LABEL = 'Offers'

export default function OfferFilterField() {
  const { callsStore } = useStores()
  const store = useCallTableFilterStore()
  const filter = store.filterRegistry.get(KEY)
  const initialValue = callsStore.filters[KEY] ?? []

  const [loading, setLoading] = useState(false)

  const initialSource = initialValue.map((id: string) => {
    const offer = offerRepo.get(id)
    return { label: offer.displayName, value: id }
  })
  const [source, setSource] =
    useState<AsyncFilterFieldProp['source']>(initialSource)

  useEffect(() => {
    store.registerFilter(KEY, {
      label: LABEL,
      value: initialValue,
      active: Boolean(initialValue && initialValue.length),
      type: 'LIMITED',
    })
  }, [])

  useEffect(() => {
    const accountIds = (accountStore.selectedAccounts || []).slice()
    setLoading(true)
    getSource({ accountIds }).then((picklist) => {
      setLoading(false)
      setSource(picklist)
    })
  }, [accountStore.selectedAccounts])

  const onChange = useCallback((value: AsyncFilterFieldProp['value']) => {
    store.setFilterValue(KEY, _isEmpty(value) ? undefined : value)
    store.runFilter()
  }, [])

  return (
    <Observer
      render={() => {
        const isShown = filter?.active ?? false
        const value = filter?.value ?? []

        return (
          <AsyncFilterField
            multiple
            loading={loading}
            label={LABEL}
            value={value}
            source={source}
            shown={isShown}
            onChange={onChange}
          />
        )
      }}
    />
  )
}
