import { createStyles } from '@material-ui/core'

const styles = createStyles({
  root: {
    borderRadius: 5,
    overflow: 'hidden'
  },
  header: {
    padding: '15px 40px',
    background: '#141414',
    color: '#ffffff'
  },
  title: {
    fontSize: 20,
    marginBottom: 3
  },
  subtitle: {
    fontSize: 12,
    color: '#787878',
    marginTop: 3
  },
  body: {
    width: 490,
    height: 150,
    display: 'flex',
    textAlign: 'center'
  },
  part: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    outline: '1px solid #f5f5f5'
  },
  summary: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    flex: '1'
  },
  count: {
    flex: '1',
    marginLeft: 12,
    fontSize: 32,
    fontWeight: 'bold'
  },
  percent: {
    marginLeft: 8,
    fontSize: 16,
    color: '#9b9b9b',
  },
  label: {
    textTransform: 'uppercase',
    color: '#9b9b9b',
    fontWeight: 'bold',
    fontSize: 15,
    marginTop: 13
  },
  detailed: {
    display: 'flex',
    marginTop: 6
  },
  detail: {
    color: 'black'
  },
  percentDetail: {
    fontSize: 16,
    color: '#9b9b9b',
    marginLeft: 8
  },
  footer: {
    padding: '10px 40px',
    background: '#f5f5f5',
    color: '#787878',
    fontSize: 12
  }
})

export default styles
