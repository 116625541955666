import React from 'react'
import moment from 'moment'
import cx from 'classnames'
// @ts-ignore
import Tooltip from '../../../MVP/MVPTooltip'
import MailingStatusIcon from './MailingStatusIcon'
import { useStyles } from './MailingStatusSteps.styles'
import { useStores } from 'config/store'
import { MailingStatus } from 'models/Mailing'

const formatDate = (theDate: string) => {
  const week = moment(theDate)
  return week.isValid() ? week.format('MM/DD/YYYY') : '-'
}

interface IMailingStatusSteps {
  active?: MailingStatus
}

const MailingStatusSteps = (props: IMailingStatusSteps) => {
  const classes = useStyles()
  const { active } = props

  const statuses = [
    MailingStatus.ORDERED,
    MailingStatus.APPROVED,
    MailingStatus.PRINTED,
    MailingStatus.SHIPPED,
    MailingStatus.DELIVERED,
  ]

  let activeIndex = 0

  if (active) {
    activeIndex = statuses.indexOf(
      active === MailingStatus.DELIVERED ||
        active === MailingStatus.DELIVERED_IN_FIRST_CALL
        ? MailingStatus.DELIVERED
        : active
    )
  }

  const { mailingsStore } = useStores()
  const mailing = mailingsStore.drawer

  if (!mailing) {
    return <></>
  }

  return (
    <div className={classes.root}>
      {statuses.map((status, index) => {
        let tooltipCustomStyle: any = {
          top: '-45px',
          fontSize: 13,
        }

        const hasOrderDate =
          status === MailingStatus.ORDERED && mailing.orderDate?.length
        const hasApprovalDate =
          status === MailingStatus.APPROVED && mailing.approvalDate?.length
        const hasPrintedDate =
          status === MailingStatus.PRINTED && mailing.printDate?.length
        const hasShippedDate =
          status === MailingStatus.SHIPPED && mailing.shipmentDate?.length
        const hasDeliveredDate =
          status === MailingStatus.DELIVERED && mailing.deliveryDate?.length

        if (
          !hasOrderDate &&
          !hasApprovalDate &&
          !hasPrintedDate &&
          !hasShippedDate &&
          !hasDeliveredDate
        ) {
          tooltipCustomStyle = {
            ...tooltipCustomStyle,
            display: 'none',
          }
        }

        return (
          <div
            key={status}
            className={cx({
              [classes.wrapper]: true,
              [classes.done]: index < activeIndex,
              [classes.active]: index === activeIndex,
            })}
          >
            <span className={classes.iconWrapper}>
              {index <= activeIndex ? (
                <Tooltip
                  className={cx({
                    [classes.tooltipLeft]:
                      status === MailingStatus.ORDERED ||
                      status === MailingStatus.APPROVED,
                    [classes.tooltipRight]:
                      status === MailingStatus.SHIPPED ||
                      status === MailingStatus.DELIVERED,
                    [classes.tooltipCenter]: status === MailingStatus.PRINTED,
                  })}
                  customStyle={tooltipCustomStyle}
                  caption={
                    hasApprovalDate ? (
                      <span>
                        The mailing was approved by{' '}
                        <strong>{mailing.approvedBy}</strong> on{' '}
                        <strong>
                          {formatDate(mailing.approvalDate ?? '')}
                        </strong>
                      </span>
                    ) : hasOrderDate ? (
                      <span>
                        The mailing was ordered on{' '}
                        <strong>{formatDate(mailing.orderDate ?? '')}</strong>
                      </span>
                    ) : hasPrintedDate ? (
                      <span>
                        The mailing was printed on{' '}
                        <strong>{formatDate(mailing.printDate ?? '')}</strong>
                      </span>
                    ) : hasShippedDate ? (
                      <span>
                        The mailing was shipped on{' '}
                        <strong>
                          {formatDate(mailing.shipmentDate ?? '')}
                        </strong>
                      </span>
                    ) : hasDeliveredDate ? (
                      <span>
                        The mailing was delivered on{' '}
                        <strong>
                          {formatDate(mailing.deliveryDate ?? '')}
                        </strong>
                      </span>
                    ) : null
                  }
                />
              ) : null}
              <MailingStatusIcon className={classes.icon} status={status} />
            </span>
            <span className={classes.label}>{status}</span>
          </div>
        )
      })}
    </div>
  )
}

export default MailingStatusSteps
