import React from 'react'
import { observer } from 'mobx-react'

import { default as DumbCard } from '../../Cards/Card'
import Grid from '@material-ui/core/Grid'
import { useStores } from 'config/store'
import { ResponsiveContainer } from 'recharts'
import Skeleton from '@material-ui/lab/Skeleton'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'

// STYLES
import { useStyles } from './OfficeHandlingChart.styles'
import { useIsEmptyChart, preparePercentageValues } from './Utils'
import { KPIChart } from 'interfaces/Call.interface'
import { GoodImpression } from './GoodImpression'
import { AskedForSource } from './AskedForSource'
import { PricingRelatedAnswers } from './PricingRelatedAnswers'
import { AskedForAppointment } from './AskedForAppointment'
import { AskedForAdditionalAppointment } from './AskedForAdditionalAppointment'
import { CallerInteractedWithIVR } from './CallerInteractedWithIVR'

const OfficeHandlingChart = () => {
  const { frontDeskStore } = useStores()
  const { pricingRelatedAnswers, loadingStates } = frontDeskStore
  const classes = useStyles()

  const emptyAskedForAppointment = useIsEmptyChart(
    KPIChart.ASKED_FOR_APPOINTMENT
  )
  const emptyAskedForSource = useIsEmptyChart(KPIChart.ASKED_FOR_SOURCE)
  const emptyGoodFirstImpression = useIsEmptyChart(
    KPIChart.GOOD_FIRST_IMPRESSION
  )
  const emptyAskedForAdditionalAppointments = useIsEmptyChart(
    KPIChart.ASKED_FOR_ADDITIONAL_APPOINTMENTS
  )

  const emptyCallerInteractedWithIVR = useIsEmptyChart(
    KPIChart.CALLER_INTERACTED_WITH_IVR
  )

  return (
    <DumbCard title="Front Desk Key Performance Indicators (All Staff)">
      <div className={classes.container}>
        <div className={classes.chart_group}>
          {loadingStates.frontDeskKPI ? (
            <Skeleton
              variant="rect"
              width={482}
              height={260}
              style={{
                borderRadius: 10,
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            />
          ) : (
            <>
              {!emptyAskedForAppointment ? (
                <AskedForAppointment />
              ) : (
                <div className={classes.emptyChart}>
                  <EmptyBarChart />
                </div>
              )}
            </>
          )}

          {loadingStates.frontDeskKPI ? (
            <Skeleton
              variant="rect"
              width={482}
              height={260}
              style={{
                borderRadius: 10,
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            />
          ) : (
            <>
              {!emptyAskedForSource ? (
                <AskedForSource />
              ) : (
                <div className={classes.emptyChart}>
                  <EmptyBarChart />
                </div>
              )}
            </>
          )}

          {loadingStates.frontDeskKPI ? (
            <Skeleton
              variant="rect"
              width={482}
              height={260}
              style={{
                borderRadius: 10,
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            />
          ) : (
            <>
              {!emptyGoodFirstImpression ? (
                <GoodImpression />
              ) : (
                <div className={classes.emptyChart}>
                  <EmptyBarChart />
                </div>
              )}
            </>
          )}
        </div>

        <div className={classes.chart_group}>
          <div>
            <div>
              {loadingStates.frontDeskKPI ? (
                <Skeleton
                  variant="rect"
                  width={482}
                  height={260}
                  style={{
                    borderRadius: 10,
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                />
              ) : (
                <>
                  {!emptyAskedForAdditionalAppointments ? (
                    <AskedForAdditionalAppointment />
                  ) : (
                    <div className={classes.emptyChart}>
                      <EmptyBarChart />
                    </div>
                  )}
                </>
              )}
            </div>
            <div>
              {loadingStates.frontDeskKPI ? (
                <Skeleton
                  variant="rect"
                  width={482}
                  height={260}
                  style={{
                    borderRadius: 10,
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                />
              ) : (
                <>
                  {!emptyCallerInteractedWithIVR ? (
                    <CallerInteractedWithIVR />
                  ) : null}
                </>
              )}
            </div>
          </div>
          <div
            style={{
              gridColumn: '2 / 4',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {loadingStates.priceRelatedAnswer ? (
                <Grid item xs={12}>
                  <Skeleton
                    variant="rect"
                    height={432}
                    style={{
                      marginTop: '1rem',
                      marginBottom: '1rem',
                      borderRadius: 10,
                    }}
                  />
                </Grid>
              ) : (
                <ResponsiveContainer width="100%">
                  {pricingRelatedAnswers.length > 0 ? (
                    <PricingRelatedAnswers />
                  ) : (
                    <div className={classes.emptyChart}>
                      <EmptyBarChart />
                    </div>
                  )}
                </ResponsiveContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </DumbCard>
  )
}

export default observer(OfficeHandlingChart)
