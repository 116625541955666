import React from 'react'
import AllInsuranceSection from './AllInsuranceSection/AllInsuranceSection'

// STYLES
import { useStyles } from './AllInsuranceDrawer.style'

const AllInsuranceDrawer = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AllInsuranceSection />
    </div>
  )
}

export default AllInsuranceDrawer
