import API, { MAPS_API } from 'helpers/api'
import _ from 'lodash'
import { repository as mailsRepo } from '../../models/Mailing'

interface IGetMailingZone {
  mailingId: string
  zoneId: string
}

export const getMailingZone = async ({
  mailingId,
  zoneId,
}: IGetMailingZone): Promise<string | undefined> => {
  const result = await API.get({
    api: MAPS_API,
    url: `/geodata?ids=${zoneId}`,
  })

  if (_.isEmpty(result)) {
    return
  }

  const mailing = mailsRepo.get(mailingId)
  mailing.zoneGeoData = result

  return mailsRepo.upsert(mailing)
}
