import React from 'react'
import { Link } from '@material-ui/core'
import { runInAction } from 'mobx'

import { useStores } from 'config/store'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'

// STYLES
import { useStyles } from './AllInsuranceSection.style'
import InsuranceStore from 'config/store/CallPro/Insurance/InsuranceStore'

const AllInsuranceSection = () => {
  const classes = useStyles()

  const { insuranceStore, callsStore, dashboardUIStore } = useStores()
  const { insuranceInquiriesBarChartData: insurances } = insuranceStore

  const total = insurances.length
  const isEmpty = total === 0

  function handleItemClick(item: Insurance) {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS
      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters({ outcome, insurance: [item.id] })
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  return (
    <section className={classes.root}>
      <div className={classes.container}>
        <div className={classes.title}>
          All Requested Insurance {isEmpty ? '' : `(${total})`}
        </div>
        {isEmpty ? (
          <div className={classes.rowEmpty}>Insurance list is empty</div>
        ) : (
          <InsuranceTable
            insurances={insurances}
            onItemClick={handleItemClick}
          />
        )}
      </div>
    </section>
  )
}

type Insurance =
  (typeof InsuranceStore)['insuranceInquiriesBarChartData'][number]

type InsuranceTableProp = {
  insurances: Insurance[]
  onItemClick: (item: Insurance) => void
}

const InsuranceTable = (props: InsuranceTableProp) => {
  const classes = useStyles()
  const { insurances, onItemClick } = props

  const handleItemClick = (item: Insurance) => () => {
    onItemClick(item)
  }

  return (
    <>
      <div className={classes.row}>
        <div className={classes.rowData}>#</div>
        <div className={classes.rowData}>Insurance Name</div>
        <div className={classes.rowData}>Count</div>
      </div>
      {insurances.map((item, index) => {
        return (
          <div className={classes.row}>
            <div className={classes.rowData}>{index + 1}</div>
            <div className={classes.rowData}>
              <Link
                component="button"
                variant="body1"
                underline="none"
                color="initial"
                onClick={handleItemClick(item)}
                classes={{ root: classes.link }}
              >
                {item.name}
              </Link>
            </div>
            <div className={classes.rowData}>{item.value}</div>
          </div>
        )
      })}
    </>
  )
}

export default AllInsuranceSection
