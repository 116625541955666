import API, { DATA_API } from 'helpers/api'

export interface SelectDesignConceptArgs {
  designId?: string
  name?: string
  accountId?: string
  mediaType?: string
  campaignIds?: string[]
  startingPoint?: string
  secondaryStartingPoint?: string
  frontOrientation?: string
  backOrientation?: string
  archiveDesignIds?: string[]
}

export const selectDesignConcept = async (options: SelectDesignConceptArgs) => {
  const {
    designId,
    name,
    accountId,
    mediaType,
    campaignIds,
    startingPoint,
    secondaryStartingPoint,
    frontOrientation,
    backOrientation,
    archiveDesignIds,
  } = options
  const result = await API.post({
    api: DATA_API,
    data: {
      designId,
      name,
      accountId,
      mediaType,
      campaignIds,
      startingPoint,
      secondaryStartingPoint,
      frontOrientation,
      backOrientation,
      archiveDesignIds,
    },
    url: `/designs/select-concept`,
  })

  return result
}
