import _isArray from 'lodash/isArray'
import { toJS, isObservable, IObservable } from 'mobx'

interface BaseObject {
  id: string
  [K: string]: any
}

export default class Repository<T extends BaseObject> {
  ids: string[] = []
  objects = new Map()

  /**
   *
   */
  upsert(o: T, options: any = null) {
    if (this.ids.includes(o.id)) {
      const obj = this.objects.get(o.id)
      if ('undefined' === typeof obj) {
        this.ids.push(o.id)
        this.objects.set(o.id, o)
        return o.id
      }
      this._merge(obj, o, options)
      return o.id
    }

    this.ids.push(o.id)
    this.objects.set(o.id, o)
    return o.id
  }

  /**
   *
   * @param {object|string} o
   */
  delete(o: T | string) {
    const id = typeof o === 'string' ? o : o.id

    if (this.ids.includes(id)) {
      const index = this.ids.indexOf(id)
      this.ids.splice(index, 1)
      this.objects.delete(id)
    }
  }

  /**
   *
   */
  replace(oldObject: T, newObject: T, options: any = null) {
    this.delete(oldObject)
    return this.upsert(newObject, options)
  }

  /**
   *
   * @param {string} id
   */
  get(id: string): T {
    return this.objects.get(id)
  }

  /**
   *
   * @private
   */
  _merge(o1: T, o2: T, options: any = null) {
    const skipMergeOnProps = (options && options.skipMergeOnProps) || []
    for (const prop in o2) {
      if (
        o2.hasOwnProperty(prop) &&
        (typeof o2[prop] === 'undefined' ||
          ((o2[prop] as IObservable) instanceof Object && skipMergeOnProps.includes(prop)))
      ) {
        continue
      }

      const jsObj = toJS(o2[prop])
      if (_isArray(jsObj) && isObservable(o1[prop]) && o1[prop].replace) {
        o1[prop].replace(jsObj)
      } else if ((jsObj as IObservable) instanceof Map && o1[prop].merge) {
        o1[prop].merge(jsObj)
      } else {
        o1[prop] = jsObj
      }
    }
  }
}
