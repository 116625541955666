import { withStyles, WithStyles } from '@material-ui/core'
import React, { useState } from 'react'

import CloseIcon from '@material-ui/icons/Close'
import referralBannerImg from 'assets/img/ads/referral/referralBanner-img.svg'
import styles from './ReferralBanner.styles'

interface ReferralBannerProps extends WithStyles<typeof styles> {}

const ReferralBanner = (props: ReferralBannerProps) => {
  const { classes } = props
  const [showBanner, setShowBanner] = useState<boolean>(true)

  const handleCloseBanner = (): void => {
    localStorage.setItem('showBanner', 'false')
    setShowBanner(false)
  }

  if (!showBanner) {
    return <React.Fragment />
  }
  return (
    <div className={classes.wrapper}>
      <img
        className={classes.img}
        src={referralBannerImg}
        alt="MVP Mailhouse"
      />
      <div className={classes.content}>
        <div className={classes.title}>
          Get <span className={classes.highlight}>$100</span> for every customer
          you refer! Your friend will also get{' '}
          <span className={classes.highlight}>250 FREE</span> handout cards!
        </div>
        <div className={classes.subText}>
          MVP Mailhouse will give out a $100 for every signed up office from
          your referral. You will receive it when your friend sends out their
          first mailing.
          <a
            className={classes.submitLink}
            href="https://mvpmailhouse.com/refer"
            target="_blank"
          >
            {' '}
            Submit a referral.
          </a>
        </div>
      </div>
      <CloseIcon className={classes.close} onClick={handleCloseBanner} />
    </div>
  )
}

export default withStyles(styles)(ReferralBanner)
