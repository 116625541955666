import _isEmpty from 'lodash/isEmpty'
import _kebabCase from 'lodash/kebabCase'
import moment from 'moment'
import fileSaver from 'file-saver'

import api, { DATA_API, vanillaHttp } from 'helpers/api'
import accountStore from 'config/store/AccountStore'

type Filters = { [key: string]: any }

export default async function directDownloadCalls(filters: Filters, fileName: string) {
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    return
  }

  const { slt } = await requestDirectDownload()

  const params = {
    slt,
    localDateTime: moment().toISOString(true),
    accountIds,
    filters,
  }

  // Download the contents
  const blob = await vanillaHttp.get<Blob>({
    params,
    api: DATA_API,
    url: '/calls/download.xlsx',
    responseType: 'blob',
  })

  fileSaver.saveAs(blob, `${_kebabCase(fileName)}.xlsx`)
}

async function requestDirectDownload() {
  return api.post<{ slt: string }>({
    api: DATA_API,
    url: '/calls/request-download',
  })
}
