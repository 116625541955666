import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {},

  callDetailsWrapper: {
    minHeight: '100%',
    overflow: 'auto',
  },

  buttonWrapper: {
    width: '100%',
    padding: '1rem .85rem',
  },

  button: {
    width: '100%',
    padding: '.5rem',
    fontWeight: 'bold',
  },
  loader: {
    transition: '0.3s ease opacity',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    position: 'absolute',
    padding: 0,
    margin: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  loaderContent: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  loaderCaption: {
    marginTop: 15,
    fontWeight: 400,
    fontSize: '18px',
    color: '#666',
  },
})
