import React from 'react'
import { observer } from 'mobx-react'

import { default as DumbCard } from '../../../Cards/Card'

// STYLES
import { useStyles } from './CallOnHoldMetrics.styles'

import { StaffPlacedCallerOnHold } from './StaffPlacedCallerOnHold'
import StaffAskedToPlaceCallerOnHold from './StaffAskedToPlaceCallerOnHold/StaffAskedToPlaceCallerOnHold'
import { HoldTime } from './HoldTIme'

const CallOnHoldMetricsChart = () => {
  const classes = useStyles()

  return (
    <DumbCard title="Call On Hold Metrics">
      <div className={classes.chart_group}>
        <>
          <StaffPlacedCallerOnHold />
          <StaffAskedToPlaceCallerOnHold />
          <HoldTime />
        </>
      </div>
    </DumbCard>
  )
}

export default observer(CallOnHoldMetricsChart)
