import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import * as queryString from 'query-string'
import { isEmpty } from 'lodash'
import { createBrowserHistory } from 'history'

import { PreviousLocationProvider } from 'routes/history'
import Login from 'views/Login/Login'
import Logout from 'views/Login/Logout'
import Verify from 'views/Login/Verify'
import MainLayout from 'layouts/MainLayout'
import SetupPage from 'views/Setup/SetupPage'
import Activation from 'views/Setup/ActivationLinkStatus'
import ResetPassword from 'views/Password/ResetPassword'
import Grants from 'views/Grants'
import LimitedAccessGrant from 'views/Grants/LimitedAccessGrant'
import { BrowserView, MobileView } from 'react-device-detect'
import MobileOverlayLayout from 'layouts/MobileOverlayLayout/MobileOverlayLayout'

const history = createBrowserHistory()

interface AppRouterProps {
  appState: any
}

const AppRouter: React.FC<AppRouterProps> = ({ appState }) => {
  return (
    <Router history={history}>
      <PreviousLocationProvider>
        <Switch>
          <Route path="/grant" component={Grants} />
          <Route
            path="/resetPassword"
            render={() =>
              appState.isAuthenticated ? (
                <Redirect to="/campaigns" />
              ) : (
                <ResetPassword />
              )
            }
          />
          <Route
            path="/setup"
            render={(props) =>
              appState.isAuthenticated && !appState.isLimitedAccess() ? (
                <Redirect
                  to={{
                    pathname: '/campaigns',
                    state: { from: props.location },
                  }}
                />
              ) : (
                <SetupPage />
              )
            }
          />
          <Route
            path="/verify"
            render={(renderProps) => (
              <Verify
                queryString={queryString.parseUrl(renderProps.location.search, {
                  decode: false,
                })}
              />
            )}
          />
          <Route
            path="/logout"
            render={(renderProps) => <Logout location={renderProps} />}
          />
          <Route
            path="/activation"
            render={(renderProps) => <Activation location={renderProps} />}
          />
          <Route
            path="/login"
            render={(renderProps) => {
              let queryStringParams = queryString.parse(
                renderProps.location.search
              )

              if (appState.isAuthenticated) {
                let redirectTo = '/'

                try {
                  redirectTo =
                    appState.redirectLocation.pathname === '/'
                      ? '/campaigns'
                      : appState.redirectLocation.pathname
                } catch (e) {
                  redirectTo = '/campaigns'
                }

                appState.setRedirect(null)
                return <Redirect to={redirectTo} />
              }
              if (queryStringParams.action === 'mock') {
                return (
                  <Redirect
                    to={{
                      pathname: '/logout',
                      state: { queryStringParams: queryStringParams },
                    }}
                  />
                )
              } else {
                return <Login />
              }
            }}
          />

          <Route
            render={(props) => {
              const { history, location } = props
              const { email, token } = queryString.parse(location.search, {
                decode: false,
              })

              if (!isEmpty(email) && !isEmpty(token)) {
                return (
                  <LimitedAccessGrant
                    location={location}
                    history={history}
                    queryParams={{ email, token }}
                  />
                )
              }

              if (appState.isAuthenticated) {
                return <MainLayout />
              } else {
                return <Login />
              }
            }}
          />
        </Switch>
      </PreviousLocationProvider>
    </Router>
  )
}

export default AppRouter
