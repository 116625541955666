import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => {
  return {
    form: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 70,
      marginTop: 'auto',
      marginBottom: 'auto',
      padding: 20,
      '& h1': {
        color: '#fff',
        fontSize: 30,
        fontWeight: 600,
        marginTop: 40,
        marginBottom: 40,
        textAlign: 'center',
        [theme.breakpoints.down(750)]: {
          lineHeight: '1.3',
        },
      },
    },
    red: {
      color: '#f8003d !important',
    },
    black: {
      color: 'rgb(20,20,20) !important',
    },
    warning: {
      background: 'rgb(255,241,241)',
      color: 'rgb(255,21,34)',
      padding: '8px',
      fontSize: '14px',
      lineHeight: '1.4',
      borderRadius: '5px',
      marginTop: '-30px',
      marginBottom: '15px',
    },
    formTopIcon: {
      height: 65,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    submit: {
      background: '#ED154E',
      color: '#fff',
      textTransform: 'capitalize',
      fontWeight: 600,
      marginTop: 35,
      marginBottom: 35,
      fontSize: '1.1rem',
      padding: 10,
      '&:hover': {
        background: '#d21345',
      },
    },
    alternate: {
      color: '#7F7F7F',
      fontSize: 15,
      textAlign: 'center',
      cursor: 'pointer',
    },
    footer: {
      marginTop: 'auto',
      textAlign: 'center',
    },
    loader: {
      marginLeft: 12,
      height: '25px !important',
      width: '25px !important',
      color: '#efa8b0',
      position: 'absolute',
      right: 25,
    },
    heading: {
      display: 'flex',
      justifyContent: 'center',
      lineHeight: '1.1 !important',
      marginLeft: '0px !important',
      minWidth: '0px !important',
    },
    subHeader: {
      fontSize: '1rem',
      color: '#D3D3D3',
      fontWeight: 'normal',
      marginBottom: '1rem',
      marginTop: 20,
      lineHeight: 1.5,
      [theme.breakpoints.down(750)]: {
        fontSize: 13,
      },
    },
    btnDisabled: {
      color: '#efa8b0 !important',
      position: 'relative',
    },
    overflow: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '0px !important',
    },
    sub: {
      color: '#D3D3D3',
      textAlign: 'center',
      fontSize: '1rem',
      marginBottom: 40,
    },
    footerLinks: {
      display: 'flex',
      color: '#7F7F7F',
      justifyContent: 'space-between',
      marginTop: '10px',
      fontSize: '15px',
      '& a': {
        cursor: 'pointer',
      },
    },
    loaderResend: {
      color: '#7F7F7F',
      width: '16px !important',
      height: '16px !important',
      position: 'relative',
      top: '3px',
      right: '4px',
    },
    headingEmail: {
      fontSize: '20px !important',
      marginTop: '20px !important',
      marginBottom: '60px !important',
    },
    resend: {
      fontSize: 14,
      marginBottom: 20,
      '& a': {
        fontWeight: 'bold',
        cursor: 'pointer',
      },
    },
    resendLoader: {
      position: 'absolute',
      top: 3,
      right: 25,
    },
  }
})

export default styles
