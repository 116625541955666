import { makeStyles, Theme } from '@material-ui/core/styles'
import { defaultFont } from 'assets/jss/mvp'

export const useMailingCardActionsStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    borderTop: '1px solid rgba(0, 0, 0, 0.06)',
    padding: '9px 9px 9px',
    alignItems: 'center',
  },

  ordered: {
    backgroundColor: '#F22151',
    paddingBottom: 4,
    borderBottom: '5px solid #B7003D',

    '& $pill': {
      borderColor: '#AE0132',
      borderSize: '0.5px',
      backgroundColor: '#E11148',
    },

    '& $pillIcon, & $pillText': {
      color: '#fff',
    },

    '& $button': {
      color: '#fff',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
      },
    },

    '& $buttonIcon': {
      color: '#fff',
    },
  },

  pill: {
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '5px 10px',
    borderRadius: 24,
    height: 24,
    backgroundColor: '#f2f2f2',
    border: '1px solid #D3D3D3',
    marginRight: theme.spacing(1),
  },

  pillIcon: {
    width: 16,
    height: 16,
    color: '#B7003D',
    marginRight: 5,
  },

  pillText: {
    ...defaultFont,
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'capitalize',
    color: '#B7003D',
  },

  button: {
    ...defaultFont,
    marginLeft: 'auto',
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'none',
    paddingLeft: 6,
    paddingRight: 6,
  },

  buttonIcon: {
    width: 15,
    height: 15,
    transform: 'scaleX(-1)',
    marginLeft: 4,
  },
}))
