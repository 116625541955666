import parseDate from 'date-fns/parseISO'
import compareDateAsc from 'date-fns/compareAsc'

import { CommentMarker, CommentStatus, CardFace } from '@mvp/mvp-react'

export const parseReviewComment = (c: any, currentVersionId: string) => {
  const datePosted = parseDate(c.createdDate)

  let status = parseReviewCommentStatus(c.status)
  let replies = c.replies ?? []

  replies = replies
    .map((r: any) => {
      return {
        id: r.id,
        postedBy: r.author.name,
        datePosted: parseDate(r.createdDate),
        status: r.status,
        message: r.body,
        nextDesignVersionId: r.nextDesignVersionId,
      }
    })
    .sort((prev: any, next: any) =>
      compareDateAsc(prev.datePosted, next.datePosted)
    )

  if (replies.length > 0) {
    const lastReply = replies.find((r: any) => {
      return r.nextDesignVersionId === currentVersionId
    })

    if (typeof lastReply !== 'undefined') {
      status = parseReviewCommentStatus(lastReply.status)
    }
  }

  const comment: CommentMarker = {
    id: c.id,
    cardFace: c.side === 'Front' ? CardFace.Front : CardFace.Back,
    x: c.x,
    y: c.y,
    dateOriginallyPosted: datePosted,
    messages: [
      {
        id: c.id,
        postedBy: c.author.name,
        datePosted,
        message: c.body,
      },
      ...replies.map(
        (r: any): CommentMarker => {
          return {
            ...r,
            datePosted: r.datePosted,
          }
        }
      ),
    ],
    status,
  }

  return comment
}

const parseReviewCommentStatus = (status: string): CommentStatus => {
  let result = CommentStatus.Pending

  if (status === 'Accepted') {
    return CommentStatus.Accepted
  }

  if (status === 'Rejected') {
    return CommentStatus.Rejected
  }

  return result
}
