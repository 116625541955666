import React, { useState, useEffect } from 'react'
import cx from 'classnames'

import {
  FormControl,
  Input,
  InputLabel,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '../LoginForm'

const styles = makeStyles({
  red: {
    color: '#f8003d !important',
  },

  black: {
    color: 'rgb(20,20,20) !important',
  },

  passwordField: {
    marginTop: 20,
    height: '100%',
  },
  hideField: {
    height: 0,
    overflow: 'hidden',
    marginTop: 0,
  },
  focusedLabel: {
    fontSize: 12,
    color: '#ED154E !important',
  },
  label: {
    fontSize: 16,
  },
  inputField: {
    background: 'transparent',
    border: 'none',
    color: '#fff',
    fontSize: 17,
    borderBottom: 'solid 1px #ED154E !important',
    fontWeight: 'normal',
    padding: 8,
    '&:hover:not(:focus)': {
      border: 'none',
    },
  },
  inputFieldFocused: {
    '&:focus': {
      borderColor: 'transparent',
    },
  },
  toggle: {
    color: '#7F7F7F',
    padding: 4,
    position: 'relative',
    right: -11,
    '& svg': {
      height: 20,
      width: 20,
    },
  },
})

interface PasswordFieldProps {
  onChange: Function
  passwordFieldVisible: boolean
  theme: Theme
}

const PasswordField: React.FC<PasswordFieldProps> = (props) => {
  const { onChange, passwordFieldVisible, theme } = props
  const classes = styles()
  const [password, setPassword] = useState('')
  const [masked, setMasked] = useState(false)

  useEffect(() => {
    onChange(password)
  }, [password])

  const handleToggleVisibility = () => {
    setMasked(!masked)
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPassword(event.currentTarget.value)
  }

  return (
    <FormControl
      className={cx(
        classes.passwordField,
        !passwordFieldVisible && classes.hideField
      )}
    >
      <InputLabel
        classes={{
          shrink: classes.focusedLabel,
        }}
        className={classes.label}
        htmlFor="password"
      >
        Enter password
      </InputLabel>
      <Input
        type={masked ? 'text' : 'password'}
        classes={{
          input: cx(classes.inputField, theme === Theme.Light && classes.black),
          focused: classes.inputFieldFocused,
        }}
        id="password"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleToggleVisibility}
              className={classes.toggle}
            >
              {masked ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        value={password}
        onChange={handleChange}
      />
    </FormControl>
  )
}

export default PasswordField
