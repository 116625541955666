import _get from 'lodash/get'
import moment from 'moment'
import { flatten } from '@turf/turf'

import { CarrierRoute, MailingDetails } from '@mvp/mvp-react'

import getPracticeIconByHex from 'components/Icons/Markers/PracticeColorIconSet'
import API, { MAPS_API } from 'helpers/api'

export default async function (campaign: any): Promise<MailingDetails> {
  const tdw = _get(campaign, 'deliveryWeek')
  const mongoId = _get(campaign, 'mongoId')

  const mailings = [{
    ...campaign,
    tracking: _get(campaign, 'trackingNumbers.0.name', ''),
    tdw: tdw ? moment(tdw).format('MMM DD, YYYY') : '',
  }]

  let carrierRoutes: CarrierRoute[] = []
  if (mongoId) {
    carrierRoutes = await getCarrierRoutes(mongoId, {
      color: _get(campaign, 'zoneColor')
    })
  }

  return {
    ...campaign,
    mailings,
    carrierRoutes,
    approvalDate: _get(campaign, 'approvalDate'),
    approvedBy: _get(campaign, 'approvedBy'),
    ...getOfficeDetails(campaign),
    parentAccount: getOfficeDetails(_get(campaign, 'parentAccount'))
  }
}

const getOfficeDetails = (campaign: any) => {
  return {
    officeMarkerUrl: getPracticeIconByHex(
      _get(campaign, 'officeColor')
    ),
    officeMarkerCoordinates: {
      lat: _get(campaign, 'officeLocation.latitude'),
      lng: _get(campaign, 'officeLocation.longitude'),
    } as google.maps.LatLngLiteral,
  }
}

const getCarrierRoutes = async (mongoId: string, {
  color
}: { color: string }) => {
  const api = MAPS_API
  const url = `/geodata?ids=${mongoId}`
  const geos = await API.get({ api, url })
  const geodata = flatten(_get(geos, '0.geodata'))
  const features = Array.from(_get(geodata, 'features', []))
  const carrierRoutes = features.map((data: any) => {
    const { geometry, properties } = data

    return {
      carrierRoute: properties?.geocode,
      paths: [
        geometry?.coordinates.map((coord: any) => {
          return coord.map((c: any) => {
            return {
              lat: c[1],
              lng: c[0],
            }
          })
        }),
      ],
      options: {
        fillColor: color,
        fillOpacity: 0.4,
        strokeColor: color,
        strokeWeight: 1,
        strokeOpacity: 0.4,
        clickable: true,
        visible: true,
        zIndex: 1000,
      },
    }
  })
  return carrierRoutes
}
