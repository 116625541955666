import React from 'react'
import { Player } from 'react-media-player'
// @ts-ignore
import CallDetailsAudio from '../CallDetailsAudio'
// @ts-ignore
import Media from '../../../AudioPlayer/Media'

// helpers
import { formatCampaignReferralSourcesName } from 'helpers/campaignSources'

// STYLES
import { useStyles } from './CallDetails.style'
import moment from 'moment'
import Call from 'models/Call'
import classNames from 'classnames'
import { Theme, useMediaQuery } from '@material-ui/core'
import { formatTextByIndustry } from 'helpers/industries'
import { formatStaffName } from 'helpers/text'
import { useStores } from 'config/store'
import Account from 'models/Account'

interface ICallDetails {
  call: Call | null
}

const CallDetails = (props: ICallDetails) => {
  const classes = useStyles()
  const { accountStore } = useStores()
  const call = props.call
  const dateTime = moment(call?.callDateTime)
  const callerName = call?.callerName ?? call?.callerIdNameFmt
  const tdw = moment(call?.campaignTargetDeliveryWeek)
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )
  const account: Account = accountStore.accounts[0]

  if (!call) {
    return <div></div>
  }

  const getCallDetailsHeader = () => {
    if (call.followUpOpportunity && call.resolveDate === null) {
      return 'Missed / Follow-up Call'
    }
    if (call.resolveDate) {
      return 'Resolved Missed / Follow-up Call'
    }
    if (call.appointmentsCounter && call.appointmentsCounter > 0) {
      return 'Appointment Call'
    }
    if (call.opportunity) {
      return 'Opportunity Call'
    }
    if (!call.opportunity) {
      return 'Non-Opportunity Call'
    }
  }

  const answeredBy =
    call.callAnsweredBy === 'Staff' ? call.handlerName : call.callAnsweredBy

  return (
    <section
      className={classNames(
        classes.callDetailsContainer,
        mobileDevice ? classes.callDetailsContainerMobile : null
      )}
    >
      <div className={classes.callDetails}>
        <div className={classes.callType}>{getCallDetailsHeader()}</div>
        <div className={classes.tableWrapper}>
          <div className={classes.cell}>Name</div>
          <div className={classNames(classes.cell, classes.rowData)}>{callerName || '-'}</div>
          <div className={classes.cell}>Caller Number</div>
          <div className={classNames(classes.cell, classes.rowData)}>{call.callerPhoneNumber}</div>
          <div className={classes.cell}>Call Date & Time</div>
          <div className={classNames(classes.cell, classes.rowData)}>{dateTime.isValid() ? dateTime.format('M/D/YY @h:mm:a') : ''}</div>
          <div className={classes.cell}>Campaign TDW</div>
          <div className={classNames(classes.cell, classes.rowData)}>
            {tdw.isValid() ? tdw.format('D MMM') : ''}
          </div>
          <div className={classes.cell}>
            {formatTextByIndustry({
              account,
              text: '{CLIENT_LABEL} Type',
            })}
          </div>
          <div className={classNames(classes.cell, classes.rowData)}>{call.patientType}</div>
          <div className={classes.cell}>Answered By</div>
          <div className={classNames(classes.cell, classes.rowData)}>{formatStaffName(answeredBy)}</div>
          <div className={classes.cell}>Referral Source</div>
          <div className={classNames(classes.cell, classes.rowData)}>{formatCampaignReferralSourcesName(call.campaignTypeDetails)}</div>
          <div className={classes.cell}>Source Mentioned</div>
          <div className={classNames(classes.cell, classes.rowData)}>{call.sourceMentioned ?? ''}</div>
          <div className={classNames(classes.cell, classes.cellSpanAll)}>
              <div>Call Summary</div>
              <div className={classes.rowData}>{call.highlights}</div>
          </div>
        </div>
      </div> {/* End Call Details  */}
      
      <div className={classes.audio}>
        <Media>
          <div>
            <div className={classes.media}>
              <Player src={call.callAudio} vendor={'audio'} autoPlay={false} />
            </div>
            <div className="media-controls">
              <CallDetailsAudio callDuration={call.callDuration} />
            </div>
          </div>
        </Media>
      </div>
    </section>
  )
}

export default CallDetails
