import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },

    // TOOLTIP
    tooltip: {
      backgroundColor: '#222',
      '&::after': {
        display: 'none',
      },
    },

    emptyChart: {
      display: 'flex',
      minHeight: '100%',
      alignItems: 'center',
      padding: '50px 0px',
      border: 'solid 1px #eee',
      borderRadius: 10,
    },

    // KEY PERFORMANCE CHARTS
    container: {
      display: 'grid',

      gap: '1rem',
    },
    chart_group: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '1rem',
    },
    chart_card: {
      border: '1px solid #e9e6e7',
      padding: '1rem',
    },
    chart_title: {
      fontSize: '1rem',
      padding: '1rem',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      backgroundColor: '#fbfbfb',
      width: '100%',
    },
    chart: {
      display: 'grid',
      placeContent: 'center',
    },
    grade: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '.5rem',
      marginBottom: '1rem',
      cursor: 'pointer',
    },
    grade_icon: {
      color: '#d3d3d3',
      verticalAlign: 'middle',
      fontSize: '1.3rem',
    },
    grade_tip: {
      width: '4rem',
      height: '2rem',
    },
    evaluation: {
      backgroundColor: '#fbfbfb',
      fontSize: '.9rem',
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      gap: '.8rem',
    },
    green_chip: {
      color: '#219653 !important',
      backgroundColor: '#E4FCE4 !important',
      fontWeight: 'bold',
      marginTop: '1rem',
      padding: '.5rem 1rem',
    },
    red_chip: {
      color: '#F50D0D !important',
      backgroundColor: '#FFF0F0 !important',
      fontWeight: 'bold',
      marginTop: '1rem',
      padding: '.5rem 1rem',
    },
    disclaimer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '.8rem',
      marginTop: 'auto',
      backgroundColor: '#fbfbfb',
      padding: '.5rem',
      '@media screen and (max-width: 780px)': {
        fontSize: 10,
      },
    },
    disclaimerIcon: {
      marginRight: '.5rem',
      fontSize: '1rem',
    },
    legend: {
      marginBottom: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
      '& p': {
        position: 'relative',
        paddingLeft: 17,

        '&:nth-child(1):before': {
          background: '#4398E7',
        },
        '&:nth-child(2):before': { background: '#A9CBEA' },
        '&:nth-child(3):before': {
          background: '#E1E1E1',
        },
        '&:before': {
          content: '""',
          width: 10,
          height: 10,
          position: 'absolute',
          top: 3,
          display: 'inline-block',
          left: 0,
        },
        fontSize: 12,
        marginBottom: 0,
        marginTop: 4,
        color: '#818181',
      },
    },
    gray: {
      '&:last-child:before': {
        background: '#E1E1E1 !important',
      },
    },
    progressWrapper: {
      marginTop: 24,
    },
  })
)
