import React, { Component } from 'react'
import { Settings } from '@material-ui/icons'
import { withMediaProps, utils } from 'react-media-player'
import withStyles from '@material-ui/core/styles/withStyles'
const { formatTime } = utils

const styles = (theme) => ({
  playBtnStyle: {
    position: 'relative',
    marginRight: '8px',
    cursor: 'pointer',
    zIndex: '999',
    color: '#c9c9c9',
    height: '20px',
    width: '20px',
    '&:hover+ul': {
      display: 'block',
    },
  },
  speedSelector: {
    display: 'none',
    background: '#232323',
    position: 'absolute',
    paddingLeft: 0,
    bottom: 13,
    left: '23px',
    paddingBottom: '30px',
    '& li': {
      listStyle: 'none',
      padding: '4px',
      width: '100px',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '11px',
      cursor: 'pointer',
      '&:not(:first-child):hover': {
        backgroundColor: '#131313',
      },
    },
    '&:hover': {
      display: 'block',
    },
  },
  active: {
    backgroundColor: '#e9414d !important',
  },
})

@withStyles(styles)
class Speed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSpeed: 1,
    }
  }

  _handleSpeed = (speed) => (e) => {
    this.props.media.setSpeed(speed)
    this.setState({ activeSpeed: speed })
    e.stopPropagation()
  }

  render() {
    const { media } = this.props
    const { classes } = this.props

    return (
      <div>
        <Settings
          onClick={this._handleSpeed}
          className={classes.playBtnStyle}
          color="primary"
        />
        <ul className={classes.speedSelector}>
          <li style={{ color: '#e9414d', fontWeight: '700' }}>Change Speed</li>
          <li
            className={this.state.activeSpeed === 0.25 ? classes.active : null}
            onClick={this._handleSpeed(0.25)}
          >
            0.25x
          </li>
          <li
            className={this.state.activeSpeed === 0.5 ? classes.active : null}
            onClick={this._handleSpeed(0.5)}
          >
            0.5x
          </li>
          <li
            className={this.state.activeSpeed === 0.75 ? classes.active : null}
            onClick={this._handleSpeed(0.75)}
          >
            0.75x
          </li>
          <li
            className={this.state.activeSpeed === 1 ? classes.active : null}
            onClick={this._handleSpeed(1)}
          >
            Normal
          </li>
          <li
            className={this.state.activeSpeed === 1.25 ? classes.active : null}
            onClick={this._handleSpeed(1.25)}
          >
            1.25x
          </li>
          <li
            className={this.state.activeSpeed === 1.5 ? classes.active : null}
            onClick={this._handleSpeed(1.5)}
          >
            1.5x
          </li>
          <li
            className={this.state.activeSpeed === 2 ? classes.active : null}
            onClick={this._handleSpeed(2)}
          >
            2x
          </li>
        </ul>
      </div>
    )
  }
}

export default withMediaProps(Speed)
