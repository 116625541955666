import React, { useState, useEffect, useRef } from 'react'
import { useRouteMatch, useHistory } from 'react-router'
import _isEmpty from 'lodash/isEmpty'
import _has from 'lodash/has'
import { CircularProgress } from '@material-ui/core'
import parseDate from 'date-fns/parseISO'
import isDateAfter from 'date-fns/isAfter'

import {
  ThemeProvider,
  DesignReview as DesignReviewComponent,
  DesignReviewProvider,
  DesignReviewProviderRef,
  Workflow,
  ReviewCommentHandlers,
  CommentMarker,
  Mailing,
  CarrierRoute,
  DesignVersionStatus,
  DesignStatus,
  DesignReviewViewedAs,
  Contact,
} from '@mvp/mvp-react'

import AppState from 'config/store/AppState'

import { getDesigns } from 'services/designs/getDesigns'
import { startReviewTracking } from 'services/designs/startReviewTracking'
import { postReviewComment } from 'services/designs/postReviewComment'
import { updateReviewComment } from 'services/designs/updateReviewComment'
import { deleteReviewComment } from 'services/designs/deleteReviewComment'
import { closeDesignVersion } from 'services/designs/closeDesignVersion'
import { getMailingByDesign } from 'services/designs/getMailingByDesign'

import { CenteredContainer } from './CenteredContainer'
import { getDesignImageUrls, DesignImageUrls } from './getDesignImageUrls'
import { getProps } from './getProps'
import { parseReviewComment } from './parseReviewComment'
import { acceptReviewComment } from 'services/designs/acceptReviewComment'
import { rejectReviewComment } from 'services/designs/rejectReviewComment'
import env from 'env'

interface MailingDetails {
  mailings: Mailing[]
  carrierRoutes: CarrierRoute[]
  officeMarkerUrl: string
  officeMarkerCoordinates: google.maps.LatLngLiteral
  parentAccount?: {
    officeMarkerUrl: string
    officeMarkerCoordinates: google.maps.LatLngLiteral
  }
}

interface DesignData {
  id: string
  account: {
    name: string
  }
  name: string
  comments: any[]
  currentVersion: {
    id: string
    version: number
    status: DesignVersionStatus
    comments: any[]
    file: string
    started: string
    frontDesignLastModified?: string
    backDesignLastModified?: string
    frontDesignThumbnailLastModified?: string
    backDesignThumbnailLastModified?: string
    customerViewed?: string
  }
  nextReview: DesignStatus
  currentApprovalLevel: DesignStatus
  lastReviewCompleted: string
  initialReviewVersion: string
  marketingReviewVersion: string
  mediaType: string
  customerReviewCompleted?: string
  customerReviewVersion?: string
  customerReviewer?: Contact
}

const DesignReview = () => {
  const match = useRouteMatch<{ accountId: string; designId: string }>()
  const history = useHistory()
  const designId = match.params.designId
  const accountId = match.params.accountId

  const [design, setDesign] = useState<DesignData | null>(null)
  const [nextReview, setNextReview] = useState<DesignStatus>(
    DesignStatus.CustomerReview
  )

  const [
    designImageUrls,
    setDesignImageUrls,
  ] = useState<DesignImageUrls | null>(null)
  const [initialComments, setInitialComments] = useState<CommentMarker[]>([])
  const designReviewProviderRef = useRef<DesignReviewProviderRef>({
    comments: [],
  })

  const [mailingDetails, setMailingDetails] = useState<MailingDetails>()
  const [accountName, setAccountName] = useState<string | undefined>()

  const [loading, setIsLoading] = useState(true)
  const [readyForCustomerReview, setReadyForCustomerReview] = useState(false)
  const [workflow, setWorkflow] = useState(Workflow.Request)
  const [designName, setDesignName] = useState('')
  const [currentVersionId, setCurrentVersionId] = useState<string | null>(null)
  const [currentVersionNumber, setCurrentVersionNumber] = useState<
    number | null
  >(null)
  const [
    currentVersionStatus,
    setCurrentVersionStatus,
  ] = useState<DesignVersionStatus | null>(null)
  const [mediaType, setMediaType] = useState<string>('')

  const [isFromDesignConcept, setIsFromDesignConcept] = useState(false)

  useEffect(() => {
    const asyncEffect = async () => {
      if (designId !== null) {
        const results = await getDesigns([designId])
        const design: DesignData = results[0]
        if (_isEmpty(design)) {
          await history.push('/')
          AppState.errorMessage(
            'There was a problem getting the designs. Please try again later.'
          )
        } else {
          const comments = design.comments ?? []

          setDesign(design)
          setNextReview(design.nextReview)
          setDesignName(design.name)
          setAccountName(design.account.name)
          setCurrentVersionId(design.currentVersion.id)
          setCurrentVersionNumber(design.currentVersion.version)
          setMediaType(design.mediaType)

          if (
            design.currentVersion.status === DesignVersionStatus.Approved ||
            design.nextReview === DesignStatus.FinalInspection
          ) {
            try {
              const _mailingDetails = await getMailingByDesign(
                accountId,
                designId
              )
              setMailingDetails(_mailingDetails)
            } catch (e) {
              setMailingDetails(undefined)
            }
          }

          setCurrentVersionStatus(design.currentVersion.status)

          setReadyForCustomerReview(
            design.nextReview === 'Customer Review' &&
            design.marketingReviewVersion !== null &&
            design.currentApprovalLevel === 'Marketing Review'
          )

          const location: any = history.location.state || {}
          setIsFromDesignConcept(
            _has(location, 'fromDesignConceptSelection') ??
            location.fromDesignConceptSelection
          )

          let lastReviewCompletedDate = design.lastReviewCompleted
            ? parseDate(design.lastReviewCompleted)
            : undefined

          const curVersionComments = design.currentVersion.comments
          const [pendingComments, acceptedComments] = curVersionComments.reduce(
            (acc, c): [number, number] => {
              if (c.status === 'Accepted') {
                return [acc[0], acc[1] + 1]
              }

              if (c.status === 'Pending') {
                return [acc[0] + 1, acc[1]]
              }

              return acc
            },
            [0, 0]
          )
          const hasNoPendingComment = pendingComments === 0

          let w: Workflow = design.currentVersion.comments.reduce(
            (acc: Workflow, c: any) => {
              lastReviewCompletedDate = lastReviewCompletedDate ?? new Date(0)

              const postedDate = parseDate(c.createdDate)

              if (isDateAfter(lastReviewCompletedDate, postedDate)) {
                return acc
              }

              return Workflow.Review
            },
            Workflow.Request
          )

          if (w === Workflow.Review) {
            const draftNewComment = design.currentVersion.comments.find(
              (c) => c.status === 'Draft'
            )

            if (draftNewComment) {
              w = Workflow.Request
            }
          }

          if (w === Workflow.Review && hasNoPendingComment) {
            if (acceptedComments === curVersionComments.length) {
              w = Workflow.Request
            }
          }

          setWorkflow(w)

          setInitialComments(
            comments.map((c: any) => {
              return parseReviewComment(c, design.currentVersion.id)
            })
          )

          setDesignImageUrls(getDesignImageUrls(design))
          setIsLoading(false)

          // Sets the customer viewed time when the design is reviewed by customer
          if (_isEmpty(design.currentVersion.customerViewed)) {
            await startReviewTracking({ id: design.currentVersion.id })
          }
        }
      }
    }

    asyncEffect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { mc, currentUser } = getProps()

  if (!designId) {
    return <CenteredContainer>No design concepts selected.</CenteredContainer>
  }

  if (loading) {
    return (
      <CenteredContainer>
        <CircularProgress />
      </CenteredContainer>
    )
  }

  if (
    currentVersionId === null ||
    currentVersionNumber === null ||
    currentVersionStatus === null ||
    nextReview === null ||
    designName === null
  ) {
    return <CenteredContainer>Design status not set.</CenteredContainer>
  }
  if (designImageUrls === null) {
    return <CenteredContainer>No designs selected.</CenteredContainer>
  }

  if (
    nextReview !== DesignStatus.FinalInspection &&
    nextReview !== DesignStatus.Approved &&
    !readyForCustomerReview
  ) {
    return (
      <CenteredContainer>
        This design is not ready for customer review.
      </CenteredContainer>
    )
  }

  const isRequested = [
    DesignVersionStatus.Requested,
    DesignVersionStatus.Wip,
    DesignVersionStatus.Rejected,
  ].includes(currentVersionStatus)

  const isApproved = [
    DesignStatus.FinalInspection,
    DesignStatus.Approved
  ].includes(nextReview)

  const handlers: ReviewCommentHandlers = {
    postReviewComment: async (args) => {
      const reviewComment = await postReviewComment({
        designId,
        ...args,
      })

      return reviewComment
    },
    updateReviewComment: async (id, updates) => {
      const reviewComment = await updateReviewComment(id, updates)
      return reviewComment
    },
    deleteReviewComment: async (id) => {
      await deleteReviewComment({ id })
    },
    acceptReviewComment: async (id) => {
      await acceptReviewComment({ id })
    },
    rejectReviewComment: async (id) => {
      await rejectReviewComment({ id })
    },
    submitDesignReview: async () => {
      try {
        await closeDesignVersion({ id: designId })
        const _mailingDetails = await getMailingByDesign(accountId, designId)
        setMailingDetails(_mailingDetails)
      } catch (e) {
        AppState.errorMessage(
          'Something went wrong while submitting the design.'
        )
      }
    },
    submitRevisions: async () => { },
    refreshDesign: async () => { },
    campaignMediaApprove: async () => { },
    campaignMediaReject: async () => { }
  }
  return (
    <ThemeProvider>
      <DesignReviewProvider
        ref={designReviewProviderRef}
        mc={mc}
        viewedAs={DesignReviewViewedAs.Customer}
        workflow={workflow}
        nextReview={nextReview}
        currentUser={{
          name: currentUser,
          roles: [],
        }}
        conceptDesigner={{}}
        customerName={accountName}
        mailingDetails={mailingDetails}
        handlers={handlers}
        fromDesignConceptSelection={isFromDesignConcept}
        mediaType={mediaType}
        api={{
          convertapi: {
            secret: env.convertApi.secret
          }
        }}
        designDetails={{
          name: designName,
          versionNumber: currentVersionNumber,
          currentVersionId: currentVersionId,
          customerReviewCompleted: design?.customerReviewCompleted,
          customerReviewVersion: design?.customerReviewVersion,
          customerReviewer: design?.customerReviewer,
          currentVersionFrontUrl: designImageUrls.frontFull,
          currentVersionFrontLastModified: designImageUrls.frontLastModified,
          currentVersionBackUrl: designImageUrls.backFull,
          currentVersionBackLastModified: designImageUrls.backLastModified,
          currentVersionFrontThumbnailUrl: designImageUrls.frontThumbnail,
          currentVersionBackThumbnailUrl: designImageUrls.backThumbnail,
          previousVersionFrontUrl: designImageUrls.previousFrontFull,
          previousVersionBackUrl: designImageUrls.previousBackFull,
          previousVersionFrontLastModified:
            designImageUrls.previousFrontLastModified,
          previousVersionBackLastModified:
            designImageUrls.previousBackLastModified,
          initialComments: isApproved ? [] : initialComments,
          isRequested: isRequested,
          isApproved: isApproved,
        }}
      >
        <DesignReviewComponent />
      </DesignReviewProvider>
    </ThemeProvider>
  )
}

export default DesignReview
