import API, { DATA_API } from 'src/helpers/api'

export default async ({ id, friendlyName }) => {
  const result = await API.put({
    api: DATA_API,
    url: '/tracking-lines',
    data: {
      id,
      friendlyName,
    },
  })

  return result
}
