import _get from 'lodash/get'
import _uniq from 'lodash/uniq'
import API, { MAPS_API } from 'src/helpers/api'

/**
 *
 */
const updateAccountColor = async (id, hex) => {
  const result = await API.post({
    api: MAPS_API,
    url: '/accounts/color',
    data: {
      id,
      color: hex
    }
  })

  if (!result) {
    return undefined
  }

  return result
}

export default updateAccountColor

