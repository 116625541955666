/**
 * ******************************************
 * Designed to be used with ./PageContainer.tsx
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React, { PropsWithChildren } from 'react'

// MUI
import { useMediaQuery } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import MuiAppBar from '@material-ui/core/AppBar'

// Just a dropdown button without menu items 😜
export default function AppBar(props: PropsWithChildren<{}>) {
  const classes = useStyle()
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  return (
    <MuiAppBar
      position={mobileDevice ? 'relative' : 'sticky'}
      elevation={0}
      className={classes.root}
    >
      {props.children}
    </MuiAppBar>
  )
}

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    flex: '0 0 auto',
    boxShadow: '0 1px 0 rgba(0, 0, 0, 0.125)',
  },
}))
