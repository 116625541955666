// @flow

import _isEmpty from 'lodash/isEmpty'
import API, { DATA_API } from 'src/helpers/api'
import TrackingLines, {
  repository as trackingLinesRepo,
} from 'src/models/TrackingLines'

/**
 *
 * @param {object} options
 */
export default async ({ accountIds, sort, filters }) => {
  if (_isEmpty(accountIds)) {
    return {
      meta: {},
      records: [],
    }
  }

  const data = {
    accountIds,
    sort,
    filters,
  }

  // TODO: Add server side query
  const result = await API.get({
    api: DATA_API,
    url: '/tracking-lines/account',
    params: data,
  })

  const trackingLines = result.map((row) => {
    const tLines = new TrackingLines({
      id: row.Id,
      campaignName: row.MVP_Campaign__r.Name,
      campaignId: row.MVP_Campaign__r.Id,
      number: row.Name,
      status: row.Status__c,
      type: row.Type__c || '--',
      destinationNumber: row.Destination_Phone_Number__c,
      callsCount: row.MVP_Campaign__r.Calls_Count__c,
      friendlyName: row.Friendly_Name__c || row.MVP_Campaign__r.Name,
      campaignType: row.MVP_Campaign__r.Campaign_Type__c,
    })

    return trackingLinesRepo.upsert(tLines)
  })

  return {
    records: trackingLines,
  }
}
