import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      display: 'flex',
      '& $col': {
        padding: '8px 50px',
      },
      '& $col:first-child': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '& $col:last-child': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    rowHeading: {
      marginTop: 16,
      marginBottom: 12,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    col: {
      flexBasis: '25%',
      display: 'flex',
      alignItems: 'center',
    },
    select: {
      width: '100%',
      background: '#fff',
    },
    fullWidth: {
      width: '100%',
    },
    switchWrapper: {
      marginRight: 12,
    },

    heading: {
      color: '#141414',
      margin: '0',
      fontSize: '20px !important',
      'font-weight': '600 !important',
      fontFamily: 'Roboto',
    },

    separator: {
      border: 'none',
      borderBottom: 'solid 1px rgb(234,234,234)',
      '&:last-child': {
        border: 'none',
      },
    },

    tooltip: {
      backgroundColor: '#222',
      '&::after': {
        display: 'none',
      },
    },

    grade_icon: {
      color: '#d3d3d3',
      verticalAlign: 'middle',
      height: 18,
      width: 18,
      marginLeft: 4,
    },
    spinner: {
      marginRight: 8,
      color: '#333',
      opacity: 0.9,
    },
  })
)
