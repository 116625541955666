import _isEmpty from 'lodash/isEmpty'
import API, { DATA_API } from 'src/helpers/api'

import accountStore from 'src/config/store/AccountStore'

export default async ({ filters }) => {
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    return {
      meta: {},
      records: [],
    }
  }

  const data = {
    accountIds,
    filters,
  }

  const result = await API.get({
    api: DATA_API,
    url: `/get-caller-gender`,
    params: data,
  })

  const { records, firstRecordDate } = result

  return {
    records,
    firstRecordDate,
  }
}
