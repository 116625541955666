import React, { useState } from 'react'
import cx from 'classnames'
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

import useStyles from './DownloadCheckboxes.style'

type Prop = {
  setChecked?: (value: boolean) => void
}

export default function DownloadCheckboxes({ setChecked = (_) => {} }: Prop) {
  const styles = useStyles()

  const [fields, setFields] = useState<{ [key: string]: boolean }>({
    termsAndConditions: false,
    iAmAuthorized: false,
  })

  const error = Object.values(fields).every(Boolean) === false

  const handleChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFields((old) => {
        const newState = { ...old, [name]: event.target.checked }
        const allChecked = Object.values(newState).every(Boolean)
        setChecked(allChecked)
        return newState
      })
    }

  const labels: { [key: string]: JSX.Element } = {
    termsAndConditions: (
      <>
        I accept the{' '}
        <span className={cx(styles.bold, styles.underlined)}>
          terms and conditions
        </span>
        .
      </>
    ),
    iAmAuthorized: (
      <>
        I am an{' '}
        <span className={cx(styles.bold, styles.underlined)}>
          authorized receiver
        </span>{' '}
        of the data.
      </>
    ),
  }
  const fieldNames = Object.keys(fields)

  return (
    <FormControl
      required
      error={error}
      component="fieldset"
      className={styles.formControl}
    >
      <FormGroup>
        {fieldNames.map((name) => (
          <FormControlLabel
            key={name}
            control={
              <Checkbox
                checked={fields[name]}
                onChange={handleChange(name)}
                name={name}
                color="primary"
              />
            }
            label={labels[name]}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}
