import React from 'react'
import Call from 'models/Call'
import moment from 'moment'
import { useStyles } from './MailingType.style'
import { isEmpty } from 'lodash'

interface MailingTypeInterface {
  call?: Call
}

export const MailingType = ({ call }: MailingTypeInterface) => {
  if (!call) {
    return <></>
  }

  const classes = useStyles()
  const week = moment(call.campaignTargetDeliveryWeek)
  const { campaignType, campaignMediaType, campaignLabel } = call
  const campaignMediaTypeText = campaignMediaType ?? ''
  const campaignLabelText = campaignLabel ?? ''
  let weekText = '', separator = ''

  if (week.isValid() && !isEmpty(campaignType)) {
    weekText = `${week.format('MM/DD/YY')} - ${campaignType}`
  }

  if (!isEmpty(campaignMediaTypeText) && !isEmpty(campaignLabelText)) {
    separator = ' - '
  }


  return (
    <div className={classes.colStackedText}>
      <div>{weekText}</div>
      <div>{campaignMediaTypeText}{separator}{campaignLabelText}</div>
    </div>
  )
}
