import _get from 'lodash/get'

import { useStores } from 'config/store'
import { AppState } from 'config/store/AppState'
import { CallsStore } from 'config/store/CallPro/Call/CallsStore'
import { DashboardUIStore } from 'config/store/CallPro/DashboardUIStore'
import directDownloadCalls from 'services/calls/directDownloadCalls'
import requestLargeExportCalls from 'services/calls/requestLargeExportCalls'
import useAccountLabel from 'helpers/hooks/useAccountLabel'

export default function useActions() {
  const { callsStore, dashboardUIStore, appState } = useStores()
  const selectedAccountLabel = useAccountLabel()

  return {
    directDownloadAction: directDownloadAction({
      callsStore,
      dashboardUIStore,
      fileName: selectedAccountLabel
    }),
    requestLargeDataAction: requestLargeDataAction({
      callsStore,
      dashboardUIStore
    }),
    flashSuccessMessage: flashSuccessMessage({ appState }),
  }
}

const directDownloadAction =
  (args: { callsStore: CallsStore; dashboardUIStore: DashboardUIStore, fileName: string }) =>
  async () => {
    const excludedFilters = [
      'offer',
      'service',
      'insurance',
      'nonOpportunityReason',
      'answeredBy',
      'paymentType',
      'callerSource',
      'voicemailOutcome',
      'onHold',
    ]
    const filterKeys = Object.keys(args.callsStore.fetchCallsFilters)
    const callFilters: { [key: string]: any } = {}

    filterKeys.forEach((a) => {
      if (!excludedFilters.includes(a)) {
        callFilters[a] = _get(args.callsStore.fetchCallsFilters, a)
      }
    })

    const filters = {
      ...callFilters,
      ...args.dashboardUIStore.globalFiltersQuery,
    }
    await directDownloadCalls(filters, args.fileName)
  }

const requestLargeDataAction =
  (args: { callsStore: CallsStore; dashboardUIStore: DashboardUIStore}) =>
  async () => {
    const filters = {
      ...args.callsStore.fetchCallsFilters,
      ...args.dashboardUIStore.globalFiltersQuery,
    }
    await requestLargeExportCalls(filters)
  }

const flashSuccessMessage =
  (args: { appState: AppState }) => (message: string) => {
    const { appState } = args
    appState.successMessage(message)
  }
