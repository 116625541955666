import React from 'react'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'

// My components
import HeaderTop from './HeaderTop'
import HeaderBottom from './HeaderBottom'

const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: '#fff',
    flex: '0 0 auto',
    boxShadow: '0 1px 0 rgba(0, 0, 0, 0.125)',
  },
}))

interface Prop {
  title?: string
  topToolbar?: React.ReactNode
  bottomToolbar?: React.ReactNode
}

const Header = (props: Prop) => {
  const classes = useStyles()
  return (
    <AppBar position="sticky" className={classes?.appBar} elevation={0}>
      {props?.topToolbar ? (
        <HeaderTop title={props?.title}>{props.topToolbar}</HeaderTop>
      ) : (
        <HeaderTop title={props?.title} />
      )}
      {props?.bottomToolbar ? (
        <HeaderBottom>{props.bottomToolbar}</HeaderBottom>
      ) : null}
    </AppBar>
  )
}

export default Header
