import AppState from 'src/config/store/AppState'

const identify = () => {
  const {
    profileName,
    contactId
  } = AppState

  const analytics = window.analytics

  analytics.identify(contactId, {
    name: profileName
  })
}

export default identify
