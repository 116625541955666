import API, { DATA_API } from 'helpers/api'

interface startReviewTrackingArgs {
  id: string
}

export const startReviewTracking = async ({ id }: startReviewTrackingArgs) => {
  await API.post({
    api: DATA_API,
    url: `/designs/${id}/review/start`,
  })
}
