// ##############################
// // // LoginForm styles
// #############################

import { defaultFont } from 'src/assets/jss/mvp'
import {
  primaryColor,
  primaryDarkColor,
  dangerColor,
  successColor,
} from 'src/assets/jss/material-dashboard-react'
import { Mail, Lock } from '@material-ui/icons/'

const loginFormStyle = {
  icons: {
    Mail,
    Lock,
  },
  icon: {
    color: primaryDarkColor,
    height: '16px',
    width: '16px',
    marginRight: '8px',
    marginLeft: '4px',
  },
  form: {
    backgroundColor: '#fff',
    width: '410px',
    margin: '0 auto',
  },
  header: {
    textTransform: 'uppercase',
    backgroundColor: 'rgb(239, 239, 239)',
    borderTop: 'solid 5px',
    borderTopColor: primaryColor,
    fontFamily: 'Oswald',
    textAlign: 'center',
    padding: '16px',
    margin: '0px',
    fontSize: '25px',
    fontWeight: '400',
    color: '#232323',
  },
  body: {
    padding: '44px',
  },
  forgotPassword: {
    '&:hover': {
      color: '#B7003D',
    },
    color: primaryColor,
    fontFamily: 'Roboto',
    float: 'right',
    fontSize: '12px',
    fontWeight: '400',
    marginTop: '-30px',
  },
  submitButton: {
    fontSize: '15px',
    width: '100%',
    marginTop: '36px',
    boxShadow: 'none',
    transition: '0.3s ease opacity',
    willChange: 'opacity',
  },
  submitButtonDisabled: {
    opacity: 0.25,
  },
  createAccountLabel: {
    ...defaultFont,
    textAlign: 'right',
    display: 'block',
    fontWeight: '100',
    fontSize: '12px',
    color: '#232323',
  },
  createAccountLink: {
    color: primaryColor,
    fontWeight: '400',
  },
  heading: {
    color: '#fff',
    fontFamily: 'Oswald',
    fontWeight: '300',
    fontSize: '28px',
    whiteSpace: 'nowrap',
  },
  headingSub: {
    color: '#fff',
    fontSize: '17px',
    fontWeight: 300,
    fontFamily: 'Roboto',
  },
  fieldError: {
    marginTop: '-33px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: primaryColor,
    'background-color': 'rgba(237, 21, 78, .10)',
    padding: '8px',
    display: 'flex',
    'align-items': 'center',
    'box-shadow': '0px 4px 7px -2px rgba(50, 50, 50, 0.13)',
    'margin-bottom': '10px',
    '& svg': {
      height: '16px',
      width: '16px',
      marginRight: '8px',
    },
  },
  iconButton: {
    color: 'rgb(201, 201, 201)',
    height: '33px',
    width: '33px',
    '& svg': {
      height: '22px',
      width: '22px',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  toastError: {
    background: '#e9414d',
    color: 'white',
    display: 'flex',
    padding: '12px',
    marginTop: '12px',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    alignItems: 'center',
    '& svg': {
      height: '18px',
      width: '18px',
      marginRight: '8px',
    },
  },
  '@keyframes slideDown': {
    from: {
      transform: 'translate3d(0, -100%, 0)',
      visibility: 'visible',
    },
    to: {
      transform: 'translate3d(0, 0, 0)',
    },
  },
  slideDown: {
    width: '411px',
    position: 'absolute',
    animation: 'slideDown .5s',
    'transform-origin': 'center bottom',
  },
}

export default loginFormStyle
