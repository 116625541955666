import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import { CircularProgress } from '@material-ui/core'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { ThemeProvider, DesignConcepts, DesignStatus } from '@mvp/mvp-react'
import { getDesigns } from 'services/designs/getDesigns'
import { validateDesignConcept } from 'services/designs/validateDesignConcept'

import { getDesignConcepts } from './getDesignConcepts'
import {
  selectDesignConcept,
  SelectDesignConceptArgs,
} from 'services/designs/selectDesignConcept'
import DesignConceptSelection from './DesignConceptSelection'

const DesignConceptSelectionLoader = () => {
  const history = useHistory()
  const [designConcepts, setDesignConcepts] = useState<DesignConcepts>({})
  const [loading, setIsLoading] = useState(true)
  const queryParams = new URLSearchParams(useLocation().search)
  const designIdsParam = queryParams.get('designIds')
  const campaignIdsParam = queryParams.get('campaignIds')

  const hasCampaignIdsParam =
    campaignIdsParam !== null && campaignIdsParam.length > 0
  const hasDesignIdsParam = designIdsParam !== null && designIdsParam.length > 0

  useEffect(() => {
    const asyncEffect = async () => {
      if (hasDesignIdsParam) {
        const designIds = (designIdsParam as string).split(',')
        const redirectToDesignReview = async (design: any) => {
          await history.push({
            pathname: `/accounts/${design.accountId}/designs/${design.id}`,
            state: { fromDesignConceptSelection: false },
          })
        }
        const isValidRedirect = (design: any) => {
          return (
            !_isEmpty(design) &&
            (design.nextReview === DesignStatus.CustomerReview ||
              design.currentApprovalLevel === 'Approved' ||
              design.nextReview === DesignStatus.FinalInspection)
          )
        }
        const _validateDesigns = await validateDesignConcept(designIds)

        const designs = await getDesigns(designIds)

        const validDesignsForReview = designs.filter((design) => {
          return !design.archived && design.nextReview === 'Customer Review'
        }, false)

        if (validDesignsForReview.length === 1) {
          redirectToDesignReview(validDesignsForReview[0])
        }

        for (let design of designs) {
          if (isValidRedirect(design)) {
            if (designs.length === 1) {
              const opts: SelectDesignConceptArgs = { designId: design.id }

              if (campaignIdsParam !== null && campaignIdsParam.length > 0) {
                opts.campaignIds = campaignIdsParam.split(',')
              }

              await selectDesignConcept(opts)
              redirectToDesignReview(design)
              break
            }

            const hasArchives = _get(_validateDesigns, 'hasArchives')
            if (design.archived && hasArchives) {
              const _design = _get(_validateDesigns, 'archivedDetails[0]')
              redirectToDesignReview(_design)
              break
            }
          }
        }

        setDesignConcepts(getDesignConcepts(designs))
        setIsLoading(false)
      }
    }
    asyncEffect()
  }, [])

  if (!hasDesignIdsParam) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        No design concepts selected.
      </div>
    )
  }

  if (loading) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <ThemeProvider>
      <DesignConceptSelection designConcepts={designConcepts} />
    </ThemeProvider>
  )
}

export default DesignConceptSelectionLoader
