import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { COLORS } from 'constants/constants'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 'auto',
      padding: 20,
      background: COLORS.neutral.shade50,
      display: 'flex',
      alignItems: 'start',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    bulb: {
      height: 25,
      marginRight: 20,
      [theme.breakpoints.down('md')]: {
        marginBottom: 12,
        marginRight: 0,
      },
    },
    mainContent: {
      '& p': {
        lineHeight: '1.5',
        fontSize: 13,
        marginTop: 0,
        marginBottom: 8,
        [theme.breakpoints.down('md')]: {
          textAlign: 'center',
          fontSize: 12,
        },
      },
      '& a': {
        fontSize: 13,
        color: COLORS.primary.default,
        textDecoration: 'underline',
        fontWeight: 'bold',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
          textAlign: 'center',
          width: '100%',
          display: 'block',
          fontSize: 12,
        },
      },
    },

    heading: {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: 20,
      textAlign: 'left',
      padding: 20,
      background: COLORS.black,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        textAlign: 'center',
        fontSize: 18,
        paddingLeft: 40,
        paddingRight: 40,
      },
    },
    modalContainer: {
      background: 'rgba(0,0,0,.7)',
      position: 'fixed',
      top: 0,
      zIndex: 9999,
      left: 0,
      width: '100vw',
      height: '100vh',
      textAlign: 'center',
    },
    imageContainer: {
      height: 'calc(100vh - 82px)',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        height: '100%',
        padding: '20px 0px',
        [theme.breakpoints.down('md')]: {
          padding: 8,
          height: 'auto',
          width: '100%',
        },
      },
      [theme.breakpoints.down('md')]: {
        height: 'auto',
      },
    },
    download: {
      marginLeft: 'auto',
      marginRight: 16,
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        padding: '4px 8px',
        fontSize: 12,
        marginTop: 10,
      },
    },
    closeIcon: {
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        top: 8,
        right: 8,
      },
    },
    downloadButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        marginTop: 8,
      },
    },
  })
)
