// Lib
import { observable, action, computed, runInAction } from 'mobx'
import _isEmpty from 'lodash/isEmpty'

// Store
import appState from 'config/store/AppState'

// Models
import Appointment, { repository as appoinmentsRepo } from 'models/Appointment'

// Configs
import { CallConfig } from '../../../callpro.config'

// Services
import getAppointments from 'services/calls/getAppointments'

// Interfaces
import {
  CallOutcome,
  CallPaginationInterface,
  CallSortDirection,
  CallDashboardTabs,
  StaffInterface,
} from 'interfaces/Call.interface'

import dashboardUIStore from '../DashboardUIStore'

interface CallListPaginated {
  [key: number]: string[]
  totalCount: number
}

export class AppointmentStore {
  @observable filters: {
    answeredBy?: StaffInterface | null
    callHandlerId?: string | null
    hasAudio?: boolean | null
    outcome?: CallOutcome
  } = {
    answeredBy: null,
    callHandlerId: null,
    hasAudio: null,
  }
  @observable pagination: CallPaginationInterface =
    CallConfig.initialValues.pagination

  @observable private appointmentsMadeIdsPaginated: CallListPaginated = {
    totalCount: 0,
  }

  @observable loadingStates = {
    appointmentsMade: false,
  }

  @observable outcome: CallOutcome | undefined

  @action
  resetByTab() {
    this.resetFilters()
    this.resetPagination()
    this.resetPaginatedAppointments()
    this.resetLoadingStates()
  }

  @action
  private resetPagination() {
    this.pagination = CallConfig.initialValues.pagination
  }

  @action
  private resetFilters() {
    const answeredBy = null
    const callHandlerId = null
    const outcome = undefined

    this.filters = { answeredBy, callHandlerId, outcome }
  }

  @action
  private resetPaginatedAppointments() {
    this.appointmentsMadeIdsPaginated = { totalCount: 0 }
  }

  @action
  resetAppointmentsTab() {
    this.resetFilters()
    this.resetPagination()
  }

  @action
  async refreshAppointments() {
    await this.fetchAppointments()
  }

  @action
  private resetLoadingStates() {
    this.loadingStates = {
      appointmentsMade: false,
    }
  }

  @action
  private setLoadingByOutcome(value: boolean) {
    this.loadingStates.appointmentsMade = value
  }

  @action
  private async fetchAppointments() {
    if (appState.isRestricted()) {
      return
    }

    const globalFilters = dashboardUIStore.globalFiltersQuery
    const page = this.pagination.page

    // // Update Loading
    this.setLoadingByOutcome(true)

    // // Fetch Appointments
    const data = await getAppointments({
      ...this.pagination,
      filters: { ...globalFilters, ...this.filters },
    })

    const { meta, records } = data
    const totalCount = meta.totalCount

    runInAction(() => {
      this.setLoadingByOutcome(false)

      this.appointmentsMadeIdsPaginated[page] = records
      this.appointmentsMadeIdsPaginated.totalCount = totalCount
    })
  }

  @action.bound
  setFilters(filters: {
    answeredBy?: StaffInterface | null
    callHandlerId?: string | null
    hasAudio?: boolean | null
    outcome: CallOutcome
  }): void {
    this.filters = filters
  }

  @action.bound
  setPaginationPage(page: number) {
    this.pagination.page = page
  }

  @action.bound
  setSearchQuery(searchQuery: string = ''): void {
    this.pagination.searchQuery = searchQuery
  }

  @action.bound
  setPaginationSize(size: number) {
    this.pagination.pageSize = size
    this.pagination.page = CallConfig.initialValues.pagination.page
    this.resetPaginatedCalls()
  }

  @action.bound
  toggleSorting(key: string): void {
    this.pagination.sort = key
    this.pagination.dir =
      this.pagination.dir === CallSortDirection.DESC
        ? CallSortDirection.ASC
        : CallSortDirection.DESC
    this.pagination.page = 1
  }

  @action
  private resetPaginatedCalls() {
    this.appointmentsMadeIdsPaginated = { totalCount: 0 }
  }

  /**
   * @param {boolean} param.reset - When you want to reset state + do a refresh
   */
  @action
  async refreshAllAppointmentsTab({ reset = false }: { reset?: boolean } = {}) {
    if (reset) {
      this.resetByTab()
    }

    await Promise.all([this.fetchAppointments()])
  }

  @computed
  get appointmentsMadeTotalCount(): number {
    return this.appointmentsMadeIdsPaginated.totalCount
  }

  @computed
  get appointmentsMadePerPage(): Appointment[] {
    return this.appointmentsMadeIdsPaginated[this.pagination.page]
      ? this.appointmentsMadeIdsPaginated[this.pagination.page].map((id) =>
          appoinmentsRepo.get(id)
        )
      : this.appointmentsMadeIdsPaginated[this.pagination.page - 1]
      ? this.appointmentsMadeIdsPaginated[this.pagination.page - 1].map((id) =>
          appoinmentsRepo.get(id)
        )
      : []
  }
}

export default new AppointmentStore()
