import React from 'react'

// Mui
import { makeStyles } from '@material-ui/core/styles'

// Util
import { Observer } from 'mobx-react'
import { Switch, Route, Redirect } from 'react-router-dom'

// My util
import routes from 'routes'
import { useStores } from 'config/store'

// My component
import Header from 'components/MVP/Header/Header'
import MVPFlashMessages from 'components/MVP/MVPFlashMessages'
import NavigationBar from 'components/MVP/Navigation/NavigationBar'
import BlockAccessOverlay from 'views/Grants/BlockAccessOverlay'
import PageComponent from './Page'

export default function MainLayout() {
  const classes = useStyle()
  const { appState } = useStores()

  return (
    <>
      <Observer
        render={() => {
          if (appState.expiredSession) {
            return <Redirect to="/logout" />
          }

          return (
            <>
              <MVPFlashMessages />
              <div className={classes.root}>
                <Switch>
                  {routes.map((route: any, key: any) => {
                    if (route.redirect) {
                      return (
                        <Redirect
                          key={key}
                          exact={Boolean(route.exact)}
                          from={route.path}
                          to={route.to}
                        />
                      )
                    }

                    return (
                      <Route
                        key={key}
                        path={route.path}
                        exact={Boolean(route.exact)}
                        render={(props) => (
                          <>
                            <NavigationBar />
                            <div className={classes.mainPanel}>
                              {[
                                'Maps',
                                'Design Concept Selection',
                                'Design Review',
                                'Revision Review',
                                'Campaign Media Review',
                                'Campaigns',
                                'Scoreboard',
                              ].includes(route.title) ? null : (
                                <Header
                                  title={route.title}
                                  topToolbar={
                                    route.toolbars && route.toolbars.top
                                  }
                                  bottomToolbar={
                                    route.toolbars && route.toolbars.bottom
                                  }
                                />
                              )}
                              <main className={classes.content}>
                                {appState.isRestricted() && (
                                  <BlockAccessOverlay />
                                )}
                                <PageComponent
                                  track={route.track}
                                  title={route.title}
                                  style={
                                    [
                                      'Design Concept Selection',
                                      'Design Review',
                                      'Revision Review',
                                      'Campaign Media Review',
                                    ].includes(route.title)
                                      ? {
                                          paddingTop: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          paddingRight: 0,
                                        }
                                      : {}
                                  }
                                >
                                  <route.page {...props} />
                                </PageComponent>
                              </main>
                            </div>
                          </>
                        )}
                      />
                    )
                  })}
                </Switch>
              </div>
            </>
          )
        }}
      />
    </>
  )
}

const useStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100vh',
    flexGrow: 1,
    zIndex: 1,
    overflow: 'visible',
    position: 'relative',
    backgroundColor: '#f3f3f3',
  },

  mainPanel: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },

  blurContent: {
    '& *': {
      filter: 'blur(3px)',
    },
  },

  content: {
    display: 'flex',
    flex: '1 1 auto',
  },
}))
