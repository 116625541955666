import React, { PropsWithChildren } from 'react'

import numeral from 'numeral'
import cx from 'classnames'

import Panorama from '@material-ui/icons/Panorama'
import Home from '@material-ui/icons/Home'

import Mailing from 'models/Mailing'
import useStyles from './MailingTextInfo.style'
import Tooltip from '@material-ui/core/Tooltip'

export default function MailingTextInfo({ mailing }: { mailing: Mailing }) {
  const classes = useStyles()
  const quantity = mailing.quantity ? (
    <>&bull; {numeral(mailing.quantity).format('0,0')} pcs</>
  ) : (
    <></>
  )
  return (
    <>
      <div className={cx(classes.root, classes.text)}>
        <div className={classes.flex}>
          <Panorama className={classes.icon} fontSize="small" />
          <span className={cx(classes.flex1, classes.textEllipsis)}>
            {mailing.name} {quantity}
          </span>
        </div>
        <TooltipWrapper mailing={mailing}>
          <div className={classes.flex}>
            <Home className={classes.icon} fontSize="small" />
            <span className={cx(classes.flex1, classes.textEllipsis)}>
              {mailing.accountName}
            </span>
          </div>
        </TooltipWrapper>
      </div>
    </>
  )
}

function TooltipWrapper(
  props: PropsWithChildren<{
    mailing: Mailing
  }>
) {
  const classes = useStyles()
  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      title={props.mailing.accountName}
      placement="bottom"
    >
      <div className={classes.help}>{props.children}</div>
    </Tooltip>
  )
}
