import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton/IconButton'
import { PlayArrow, Pause } from '@material-ui/icons'
import { withMediaProps, utils } from 'react-media-player'
import SeekBar from 'src/components/AudioPlayer/SeekBar'
import Volume from 'src/components/AudioPlayer/Volume'
import Speed from 'src/components/AudioPlayer/Speed'

const { formatTime } = utils
import withStyles from '@material-ui/core/styles/withStyles'

const styles = (theme) => ({
  playBtnStyle: {
    position: 'relative',
    top: '-2px',
    marginRight: '8px',
    cursor: 'pointer',
  },
  containerStyle: {
    backgroundColor: '#232323',
    color: '#fff',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    paddingRight: '1rem',
    paddingTop: '6px',
    position: 'relative',
  },
  timeStyle: {
    'font-size': '12px',
    'font-weight': '400',
    'min-width': '48px',
    display: 'block',
    'margin-top': '-6px',
    'line-height': '1.3',
    'text-align': 'center',
  },
})

@withStyles(styles)
class CallDetailsAudio extends Component {
  constructor(props) {
    super(props)
    this.state = { time: formatTime(0), isPlayed: false }
    this.playButtonRef = React.createRef()
  }

  // shouldComponentUpdate({ media }) {
  //   return this.props.media.isPlaying !== media.isPlaying
  // }

  _handlePlayPause = (e) => {
    this.props.media.playPause()
    this.interval = setInterval(
      () => this.setState({ time: formatTime(this.props.media.currentTime) }),
      1000
    )
    this.setState({ isPlayed: true })
    if (e) {
      e.stopPropagation()
    }
  }

  render() {
    const { media, classes } = this.props
    const timeIndicator = this.props.media.duration / 60 >= 1 ? 'min' : 'sec'

    return (
      <div className={classes.containerStyle}>
        {media.isPlaying ? (
          <IconButton
            className={classes.playBtnStyle}
            color="primary"
            onClick={this._handlePlayPause}
          >
            <Pause />
          </IconButton>
        ) : (
          <IconButton
            ref={this.playButtonRef}
            className={classes.playBtnStyle}
            color="primary"
            onClick={this._handlePlayPause}
          >
            <PlayArrow />
          </IconButton>
        )}
        <Speed media={this.props.media} />
        <SeekBar />
        <Volume />
        {this.state.isPlayed ? (
          <time className={classes.timeStyle}>
            {' '}
            {this.state.time} {timeIndicator}
          </time>
        ) : (
          <time className={classes.timeStyle}>
            {formatTime(this.props.callDuration)} {timeIndicator}
          </time>
        )}
      </div>
    )
  }
}

export default withMediaProps(CallDetailsAudio)
