import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  recentCallsWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    '& h2': {
      fontFamily: 'Roboto',
      fontSize: '17px',
      color: '#282828',
      marginBottom: 14,
    },
    '& > table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      flexBasis: '100%',
      fontFamily: 'Roboto',
      fontSize: 14,
      marginBottom: 24,
      '& td': {
        padding: 5,
      },
      '& tr:nth-child(odd)': {
        backgroundColor: 'rgb(241,241,241)',
      },
    },
    '& a': {
      margin: '14px 0px',
      color: 'rgb(225,40,61)',
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '14px',
      marginTop: '0',
    },
  },
  backButton: {
    width: '100%',
    backgroundColor: 'rgb(244, 244, 244)',
    marginBottom: '-5px !important',
    marginTop: '0px !important',
    textTransform: 'uppercase',
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    paddingTop: '8px',
    cursor: 'pointer',
  },
}))
