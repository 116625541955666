import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  callDetailsContainer: {
    backgroundColor: '#F4F4F4',
    padding: '0 .85rem .85rem .85rem',
  },

  callDetails: {
    backgroundColor: '#fff',
    padding: '1rem',
  },

  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: '.7rem',
  },

  row: {
    display: 'grid',
    gridTemplateColumns: '8rem 1fr 7rem 1fr',
  },

  rowLabel: {
    border: '1px solid #ddd',
    borderRight: 'none',
    borderBottom: 'none',
    padding: '.5rem',
    fontSize: '.85rem',

    display: 'flex',
    alignItems: 'center',
  },

  rowData: {
    border: '1px solid #ddd',
    borderRight: 'none',
    borderBottom: 'none',
    padding: '.5rem',
    fontSize: '.85rem',
    fontWeight: 'bold',
    lineHeight: '1rem',

    display: 'flex',
    alignItems: 'center',

    '&:last-child': {
      borderRight: '1px solid #ddd',
    },
  },

  row2: {
    display: 'grid',
    gridTemplateRows: '1.8rem 1fr',
    border: '1px solid #ddd',
    padding: '.5rem',
    fontSize: '.85rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
  },

  row2label: {
    fontSize: '.85rem',
    fontWeight: 'normal',
    // color: '#929292',
  },

  audio: {
    border: '1px solid #ddd',
  },

  media: {
    display: 'none',
  },
})
