import React, { useCallback, useEffect, useState } from 'react'
import { Observer } from 'mobx-react'
import _isEmpty from 'lodash/isEmpty'

import API, { DATA_API } from 'helpers/api'

import AsyncFilterField, { AsyncFilterFieldProp } from './AsyncFilterField'
import { useCallTableFilterStore } from '../../store/CallTableFilterStore'
import { useStores } from 'config/store'

const getSource = async () => {
  const result = await API.get({
    api: DATA_API,
    url: '/object/score/field',
    params: { names: ['ivrOutcome'] },
  })
  const { ivrOutcome = {} } = result
  const { picklist = [] } = ivrOutcome
  return picklist
}

const KEY = 'ivrOutcome'
const LABEL = 'IVR outcome'

export default function IVROutcomeFilterField() {
  const { callsStore } = useStores()
  const store = useCallTableFilterStore()
  const filter = store.filterRegistry.get(KEY)
  const initialValue = callsStore.filters[KEY] ?? []

  const [loading, setLoading] = useState(false)

  const initialSource = initialValue.map((value: string) => {
    return { label: value, value: value }
  })
  const [source, setSource] =
    useState<AsyncFilterFieldProp['source']>(initialSource)

  useEffect(() => {
    store.registerFilter(KEY, {
      label: LABEL,
      value: initialValue,
      active: Boolean(initialValue && initialValue.length),
    })

    setLoading(true)
    getSource().then((picklist) => {
      setLoading(false)
      setSource(picklist)
    })
  }, [])

  const onChange = useCallback((value: AsyncFilterFieldProp['value']) => {
    store.setFilterValue(KEY, _isEmpty(value) ? undefined : value)
    store.runFilter()
  }, [])

  return (
    <Observer
      render={() => {
        const isShown = filter?.active ?? false
        const value = filter?.value ?? []

        return (
          <AsyncFilterField
            multiple
            loading={loading}
            label={LABEL}
            value={value}
            source={source}
            shown={isShown}
            onChange={onChange}
          />
        )
      }}
    />
  )
}
