import { Paper } from '@material-ui/core'
import React from 'react'
import numeral from 'numeral'
import {
  withStyles,
  WithStyles
} from '@material-ui/core/styles'
import dot from '../../../assets/img/patientList/patientMarkers/dot.svg'
import star from '../../../../src/assets/img/patientList/patientMarkers/star.svg'
import check from '../../../../src/assets/img/patientList/patientMarkers/check.svg'

import getPatientIconByHex from '../../PatientList/PatientListIconSet'

import styles from './PatientListItemHover.style'


const PatientListItemHover = (props) => {
  const { classes, patientList } = props
  const { summary, icon, audit } = patientList
  const { patientCount: quantity, production, invalidAddress } = summary ||
    { patientCount: 0, production: 0, invalidAddress: 0 }
  const { totalMailings = 0 } = audit
  const {
    strongMatches,
    weakMatches,
    noMatches,
    totalCampaigns,
  } = patientList.getAuditSummary

  const parts = [
    {
      label: 'New Patients',
      icon: getPatientIconByHex(icon, false, false),
      iconStyle: {
        width: 16,
        height: 16
      },
      count: quantity,
      production: production,
      countPercent: false,
      productionPercent: false
    },
    {
      label: 'Matched',
      icon: star,
      iconStyle: {
        width: 24,
        height: 24
      },
      count: strongMatches.total,
      production: strongMatches.production,
      countPercent: quantity && strongMatches.total / quantity || 0,
      productionPercent: production && strongMatches.production / production || 0
    },
    {
      label: 'Targeted',
      icon: check,
      iconStyle: {
        width: 30,
        height: 30
      },
      count: weakMatches.total,
      production: weakMatches.production,
      countPercent: quantity && weakMatches.total / quantity || 0,
      productionPercent: production && weakMatches.production / production || 0
    }
  ]

  const isShowPercentage = parts.some(item =>
    typeof item.countPercent === 'boolean' && item.countPercent !== false || item.countPercent > 0
  )
  const isShowProduction = parts.some(item => item.production > 0)
  const needsAttention = (noMatches && noMatches.total || 0) + invalidAddress

  return (
    <Paper classes={{ root: classes.root }}>
      <div className={classes.header}>
        <div className={classes.title}>Campaign Audit Summary</div>
        <div className={classes.subtitle}>
          <span>{numeral(totalCampaigns).format('0,0')} Campaigns</span>
          <span> • </span>
          <span>{numeral(totalMailings).format('0a')} Delivered Mailings</span>
        </div>
      </div>
      <div className={classes.body}>
        {parts.map((part, i) => {
          return (
            <div key={part.label} className={classes.part}>
              <div className={classes.summary}>
                <img src={part.icon} className={classes.icon} style={part.iconStyle} />
                <div className={classes.count}>{numeral(part.count).format('0,0')}</div>
                {
                  (part.countPercent !== false && isShowPercentage) &&
                  <div className={classes.percent}>({numeral(part.countPercent).format('0%')})</div>
                }
              </div>

              {
                (isShowPercentage && isShowProduction) &&
                <div className={classes.detailed}>
                  <div className={classes.detail}>${numeral(part.production).format('0,0')}</div>
                  {
                    part.productionPercent !== false &&
                    <div className={classes.percentDetail}>({numeral(part.productionPercent).format('0%')})</div>
                  }
                </div>
              }

              <div className={classes.label}>{part.label}</div>
            </div>
          )
        })}
      </div>
      {needsAttention > 0 && <div className={classes.footer}>
        {(
          () => {
            const total = numeral(needsAttention).format('0,0')
            const address = 'address' + (needsAttention > 1 && 'es' || '')
            const need = 'need' + (needsAttention > 1 && '' || 's')
            return <React.Fragment>
              <strong>Note: {total}</strong> patient {address} in your list {need} your attention.
            </React.Fragment>
          }
        )()}
      </div>
      }
    </Paper>
  )
}

export default withStyles(styles)(PatientListItemHover)
