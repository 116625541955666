// @flow

import _isEmpty from 'lodash/isEmpty'
import API, { DATA_API } from 'src/helpers/api'
import OfferInquiry, {
  repository as offerInquiryRepo,
} from 'src/models/offers/OfferInquiry'
import Offer, { repository as offerRepo } from 'src/models/offers/Offer'

import accountStore from 'src/config/store/AccountStore'

/**
 *
 * @param {object} options
 */
export default async ({ filters }) => {
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    return {
      meta: {},
      records: [],
    }
  }

  const data = {
    accountIds,
    filters,
  }

  const result = await API.get({
    api: DATA_API,
    url: '/offers/inquiries',
    params: data,
  })

  const sortedResult = result.sort((a, b) => {
    return a.Call__r.Call_Date__c - b.Call__r.Call_Date__c
  })

  const offers = sortedResult
    .filter((o) => !_isEmpty(o.Offer__c) && !_isEmpty(o.Offer__r))
    .map((row) => {
      const offerInquiry = new OfferInquiry({
        id: row.Id,
        name: row.Name,
        offerId: row.Offer__c,
        offerName: row.Offer__r.Display_Name__c,
        callId: row.Call__c,
      })
      const offer = new Offer({
        id: offerInquiry.offerId,
        displayName: offerInquiry.offerName,
      })

      offerRepo.upsert(offer)
      return offerInquiryRepo.upsert(offerInquiry)
    })

  const startDate = sortedResult.length
    ? sortedResult[0].Call__r.Call_Date__c
    : ''

  const newOffers = offers.map((id) => {
    const offer = offerInquiryRepo.get(id)
    return offer.offerName
  })

  return {
    startRecordDate: startDate,
    records: offers,
  }
}
