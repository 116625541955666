import _get from 'lodash/get'
import Mailing from 'src/models/Mailing'

const processMailData = (result) => {
  const mailing = new Mailing()

  mailing.id = result.id
  mailing.name = result.name
  mailing.label = result.label
  mailing.accountId = result.accountId
  mailing.deliveryWeek = result.deliveryWeek
  mailing.campaignType = result.campaignType
  mailing.quantity = result.quantity
  mailing.orderDate = result.orderDate
  mailing.approvalDate = result.approvalDate
  mailing.approvedById = result.approvedById
  mailing.approvedBy = result.approvedBy
  mailing.shipmentDate = result.shipmentDate
  mailing.deliveryDate = result.deliveryDate
  mailing.printDate = result.printDate
  mailing.leadsCount = result.leadsCount
  mailing.appointmentsCount = result.appointmentsCount
  mailing.conversionRate = result.conversionRate
  mailing.frontDesignUrl = result.frontDesignUrl
  mailing.frontDesignThumbnailUrl = result.frontDesignThumbnailUrl
  mailing.backDesignUrl = result.backDesignUrl
  mailing.backDesignThumbnailUrl = result.backDesignThumbnailUrl
  mailing.mapUrl = result.mapUrl
  mailing.mapThumbnailUrl = result.mapThumbnailUrl
  mailing.recentCalls = result.recentCalls
  mailing.status = result.status
  mailing.matchedNewPatients = result.matchedNewPatients
  mailing.matchedTotalProduction = result.matchedTotalProduction
  mailing.matchedTopPatients = result.matchedTopPatients
  mailing.matchedLastAudit = result.matchedLastAudit
  mailing.createdDate = result.createdDate
  mailing.accountName = result.accountName
  mailing.zone = result.mongoId
  mailing.zoneColor = result.zoneColor
  mailing.officeColor = result.officeColor
  mailing.officeLocation = result.officeLocation
  mailing.qrScans = result.qrScans
  mailing.mediaType = result.mediaType

  return mailing
}

export default processMailData
