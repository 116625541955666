import processMailingZoneData from './processMailingZoneData'
import MailingArea, { CampaignType } from 'src/models/MailingArea'
import { repository as zonesRepo } from 'src/models/MailingZone'

const processMailingAreaData = result => {
  const area = new MailingArea()

  if (result.campaignType === CampaignType.NewMover) {
    result = transformNewmoverResult(result)
  }

  area.id = result.id
  area.name = result.name
  area.accountId = result.accountId
  area.campaignType = result.campaignType
  area.quantity = result.quantity
  area.archived = result.archived
  area.color = result.color
  area.listProviderUrl = result.listProviderUrl
  area.mailingDateFrom = result.mailingDateFrom
  area.mailingDateTo = result.mailingDateTo
  area.mailingsCount = result.mailingsCount
  area.geodataId = result.geodataId

  const carrierRoutes = result.carrierRoutes.filter(a => !!a)
  area.carrierRoutes.replace(carrierRoutes)
  area.zones.replace(result.zones.map(row => {
    const zone = processMailingZoneData(row)
    return zonesRepo.upsert(zone)
  }))

  return area
}

const transformNewmoverResult = result => {
  result.carrierRoutes = []
  if (result.zones && result.zones[0]) {
    result.carrierRoutes = result.zones[0].carrierRoutes
    result.color = result.zones[0].color
    result.zones = []
  }
  return result
}

export default processMailingAreaData
