import { makeStyles, createStyles } from '@material-ui/styles'

export default makeStyles(
  createStyles({
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))',
      // display: 'flex',
      flexWrap: 'wrap',
      gridGap: '1.5rem',
      justifyContent: 'space-between',
      // justifyContent: 'flex-start',
      marginBottom: 20,
      // '& > div': {
      //   flex: '0 1 21rem',
      // },
      // '@media (min-width: 1366px)': {
      //   gridTemplateColumns: 'repeat(auto-fill, 18.3rem)',
      // },
      // '@media (min-width: 1600px)': {
      //   gridTemplateColumns: 'repeat(auto-fill, 21rem)',
      // },
    },

    noResults: {
      fontSize: '26px',
      height: '150px',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#d4d4d4',
      display: 'flex',
      borderTop: 'solid 1px rgb(229,229,229)',
      '& svg': {
        fontSize: '32px',
        marginRight: '8px',
      },
    },
  })
)
