import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonRoot: {
      color: theme.palette.primary.main,
      fontSize: 12,
      textTransform: 'capitalize',
      // paddingTop: 8,
      // paddingBottom: 8,
      // paddingLeft: 10,
      // paddingRight: 10,

      // Bolder border (comment it out if want lighter)
      paddingTop: '6px !important',
      paddingBottom: '6px !important',
      paddingLeft: '8px !important',
      paddingRight: '8px !important',
    },
    buttonLabel: {
      letterSpacing: '0.6px',
      // lineHeight: '8px',
      // Bolder border (comment it out if want lighter)
      lineHeight: '10px !important',
    },
    buttonOutlinedPrimary: {
      // borderColor: theme.palette.primary.main,
      // Bolder border (comment it out if want lighter)
      borderColor: 'rgb(237 21 78 / 50%)',
      borderWidth: '2px !important',
    },
  })
)

export default useStyles
