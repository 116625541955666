import API, { DATA_API } from 'src/helpers/api'

/**
 *
 * @param {string} id the id of the mail object to fetch
 *
 * @return {string} the id of the call object
 */
export default async (id, {
  recentCalls = false,
  trackingNumbers = false,
  includeMediaMetaData = false
}) => {
  const params = {
    recentCalls,
    trackingNumbers,
    includeMediaMetaData
  }

  const result = await API.get({
    api: DATA_API,
    url: `/mailings/${id}`,
    params
  })

  return result
}
