import _flatten from 'lodash/flatten'
import { observable, action, computed, runInAction } from 'mobx'
import { repository as productInquiry } from 'models/Product/ProductInquiry'
import getProductInquiries from 'services/products/getProductInquiries'
import dashboardUIStore from '../DashboardUIStore'

interface ProductInquiriesBarChart {
  id: string
  name: string
  value: number
}

export class ProductStore {
  @observable productStart: string = ''
  @observable productInquiriesLoading: boolean = false
  @observable private productInquiryIds = observable.array<string>([])

  // --- ACTION w/ SIDE EFFECTS - Refresher --- //

  @action
  async refreshOverviewTab({ reset = false } = {}) {
    if (reset) {
      this.reset()
    }

    const filters = dashboardUIStore.globalFiltersQuery
    await this.fetchProductInquiries({ filters })
  }

  // --- ACTION w/ SIDE EFFECTS --- //

  @action
  async fetchProductInquiries(options: {} = {}) {
    this.productInquiriesLoading = true

    const data = await getProductInquiries(options)
    const { records, startRecordDate } = data

    runInAction(() => {
      if (records) {
        this.productInquiryIds.replace(records)
      }
      this.productStart = startRecordDate
      this.productInquiriesLoading = false
    })
  }

  // --- ACTION w/o SIDE EFFECTS --- //

  @action
  private reset() {
    this.productStart = ''
    this.productInquiriesLoading = false
  }

  // --- COMPUTED --- //

  @computed
  get productInquiriesBarChartData() {
    const productInquiries: ProductInquiriesBarChart[] = []

    this.productInquiryIds
      .map((id) => productInquiry.get(id))
      .forEach((productInq) => {
        const index = productInquiries.findIndex(
          (o) => o.id === productInq.productId
        )

        if (index > -1) {
          productInquiries[index] = {
            ...productInquiries[index],
            value: productInquiries[index].value + 1,
          }
        } else {
          productInquiries.push({
            id: productInq.productId,
            name: productInq.productName,
            value: 1,
          })
        }
      })

    return productInquiries.sort((a, b) => b.value - a.value)
  }
}

export default new ProductStore()
