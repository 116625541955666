import React, { useCallback } from 'react'
import { Button } from '@material-ui/core'
import { Clear as ClearIcon, Add as AddIcon } from '@material-ui/icons'
import { useCallTableFilterStore } from '../store/CallTableFilterStore'

export function ResetFilterButton() {
  const store = useCallTableFilterStore()

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    store.resetFilter()
  }, [])

  return (
    <Button
      startIcon={<ClearIcon />}
      style={{ textTransform: 'initial', color: '#A0ABBB' }}
      onClick={handleClick}
    >
      Reset filters
    </Button>
  )
}

export function AddFilterButton() {
  const store = useCallTableFilterStore()

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    store.addFilterDialogOpened.set(true)
  }, [])

  return (
    <Button
      color="primary"
      startIcon={<AddIcon />}
      style={{ textTransform: 'initial' }}
      onClick={handleClick}
    >
      Add filter
    </Button>
  )
}
