import { useStores } from 'config/store'
import { KPIChart } from 'interfaces/Call.interface'
import React from 'react'
import { Sector } from 'recharts'
import { useFrontDeskStyles } from './FrontDesk.styles'

interface PercentagePreparerInterface {
  percentage: number
  decimalPlaces?: number | 0
}

export interface IGrading {
  name: string
  min: number
  max?: number
}
;[]

export const getGradeColor = (value: number, grading: IGrading[]) => {
  const gradeName = grading
    .filter((grade) => {
      const max = grade.max ? grade.max >= value : true

      if (grade.min <= value && max) {
        return grade
      }
    })
    .map((grade) => grade.name)

  // Return chip style according to grade value
  return getGradeColorByName(gradeName[0])
}

export const getGradeColorByName = (name: string) => {
  const classes = useFrontDeskStyles()
  switch (name) {
    case 'A':
    case 'B':
      return classes.green_chip
    case 'C':
      return classes.orange_chip
    case 'D':
      return classes.red_chip
  }
}

/**
 * Used in Office Handling Chart rendering pie charts
 @param props
**/
export const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    label1,
    label2,
    value,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text
        style={{ fontSize: '28px' }}
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={'#000'}
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey + 4}
        textAnchor={textAnchor}
        fill="#333"
      >{`${(percent * 100).toFixed(0)}%`}</text>
    </g>
  )
}

export const preparePercentageValues = (props: PercentagePreparerInterface) => {
  const { percentage, decimalPlaces } = props
  const valueRounded = Math.round(percentage).toFixed(decimalPlaces)
  const valueStringify = `${valueRounded}%`
  const valueNumber = +valueRounded

  return { valueRounded, valueStringify, valueNumber }
}

/**
 * Custom hook to check if the metrics use to display a chart has value or > 0
 **/
export const useIsEmptyChart = (chart: KPIChart): boolean => {
  const { frontDeskStore } = useStores()
  const { officeHandlingRate } = frontDeskStore

  const {
    appointmentRate,
    askedForAppointmentCount,
    validAskedForAppointment,
    askedForAppointmentCallerMentioned,

    askedForSourceRate,
    askedForSourceCount,
    validAskedForSource,
    askedForSourceCallerMentioned,

    askedForGoodImpressionRate,
    askedForGoodImpressionCount,
    validAskedForGoodImpressionCount,

    askedForAdditionalAppointmentRate,
    askedForAdditionalAppointmentCount,
    validAskedForAdditionalAppointment,
    askedForAdditionalAppointmentCallerMentioned,

    callerInteractedWithIVRCount,
    validCallerInteractedWithIVRCount,
  } = officeHandlingRate


  switch (chart) {
    case KPIChart.ASKED_FOR_APPOINTMENT:
      return (
        !appointmentRate &&
        !askedForAppointmentCount &&
        !validAskedForAppointment &&
        !askedForAppointmentCallerMentioned
      )
    case KPIChart.ASKED_FOR_SOURCE:
      return (
        !askedForSourceRate &&
        !askedForSourceCount &&
        !validAskedForSource &&
        !askedForSourceCallerMentioned
      )
    case KPIChart.GOOD_FIRST_IMPRESSION:
      return (
        !askedForGoodImpressionRate &&
        !askedForGoodImpressionCount &&
        !validAskedForGoodImpressionCount
      )

    case KPIChart.CALLER_INTERACTED_WITH_IVR:
      return !callerInteractedWithIVRCount || !validCallerInteractedWithIVRCount
    case KPIChart.ASKED_FOR_ADDITIONAL_APPOINTMENTS:
      return (
        !askedForAdditionalAppointmentRate &&
        !askedForAdditionalAppointmentCount &&
        !validAskedForAdditionalAppointment &&
        !askedForAdditionalAppointmentCallerMentioned
      )
  }
}

export const getGradeName = (grading: IGrading[], value: number) => {
  return grading
    .filter((grade) => {
      const max = grade.max ? grade.max >= value : true

      if (grade.min <= value && max) {
        return grade
      }
    })
    .map((grade) => grade.name)[0]
}
