import React, { useState, useEffect, useRef } from 'react'
import { useRouteMatch } from 'react-router'
import { CircularProgress } from '@material-ui/core'
import {
  ThemeProvider,
  DesignReview as DesignReviewComponent,
  DesignReviewProvider,
  DesignReviewProviderRef,
  DesignReviewViewedAs,
  DesignReviewExperience
} from '@mvp/mvp-react'

import env from 'env'

import { CenteredContainer } from '../CenteredContainer'
import { getProps } from '../getProps'

import getMailingById from 'services/mailings/getMailingById'
import getDesign, { GetDesignInterface } from './getDesign'
import useCampaignReview from './useCampaignReview'
import useHandlers from './useHandlers'
import { MailingDetails } from 'services/designs/getMailingByDesign'
import getMailingDetails from './getMailingDetails'

const CampaignMediaReview: React.FC<unknown> = () => {
  const match = useRouteMatch<{ campaignId: string }>()
  const campaignId = match.params.campaignId
  const designReviewProviderRef = useRef<DesignReviewProviderRef>({
    comments: [],
  })
  const { mc, currentUser } = getProps()
  const [campaign, setCampaign] = useState<any>()
  const [mailingDetails, setMailingDetails] = useState<MailingDetails>()
  const [design, setDesign] = useState<GetDesignInterface>()
  const [loading, setLoading] = useState(true)
  const {
    workflow,
    nextReview,
    designImageUrls,
    accountName,
    designName,
    currentVersionId,
    currentVersionNumber,
    mediaType,
    initialComments,
    isRequested,
    isApproved,
    isInReview,
  } = useCampaignReview(campaign, design)
  const handlers = useHandlers({
    campaign,
    design,
    initialComments
  })

  useEffect(() => {
    const asyncEffect = async () => {
      const campaign = campaignId ? await getMailingById(campaignId, {
        trackingNumbers: true,
        includeMediaMetaData: true,
      }) : null

      const [mailingDetails, design] = await Promise.all([
        campaign ? getMailingDetails(campaign) : undefined,
        campaign?.design ? getDesign(campaign?.design) : undefined
      ])

      setCampaign(campaign)
      setDesign(design)
      setMailingDetails(mailingDetails)
      setLoading(false)
    }
    asyncEffect()
  }, [])

  if (loading) {
    return <CenteredContainer><CircularProgress /></CenteredContainer>
  }

  if (!isInReview) {
    return (
      <CenteredContainer>
        This campaign is not ready for review.
      </CenteredContainer>
    )
  }

  return (
    <ThemeProvider>
      <DesignReviewProvider
        ref={designReviewProviderRef}
        mc={mc}
        experience={DesignReviewExperience.CampaignReview}
        viewedAs={DesignReviewViewedAs.Customer}
        workflow={workflow}
        nextReview={nextReview}
        currentUser={{
          name: currentUser,
          roles: [],
        }}
        conceptDesigner={{}}
        customerName={accountName}
        mailingDetails={mailingDetails}
        handlers={handlers}
        fromDesignConceptSelection={false}
        mediaType={mediaType}
        api={{
          convertapi: {
            secret: env.convertApi.secret
          }
        }}
        designDetails={{
          name: designName,
          versionNumber: currentVersionNumber ?? 0,
          currentVersionId: currentVersionId ?? '',
          customerReviewCompleted: design?.customerReviewCompleted,
          customerReviewVersion: design?.customerReviewVersion,
          customerReviewer: design?.customerReviewer,
          currentVersionFrontUrl: designImageUrls?.frontFull ?? '',
          currentVersionFrontLastModified: designImageUrls?.frontLastModified ?? '',
          currentVersionBackUrl: designImageUrls?.backFull ?? '',
          currentVersionBackLastModified: designImageUrls?.backLastModified ?? '',
          currentVersionFrontThumbnailUrl: designImageUrls?.frontThumbnail ?? '',
          currentVersionBackThumbnailUrl: designImageUrls?.backThumbnail ?? '',
          previousVersionFrontUrl: designImageUrls?.previousFrontFull ?? '',
          previousVersionBackUrl: designImageUrls?.previousBackFull ?? '',
          previousVersionFrontLastModified:
            designImageUrls?.previousFrontLastModified ?? '',
          previousVersionBackLastModified:
            designImageUrls?.previousBackLastModified ?? '',
          initialComments: isApproved ? [] : initialComments,
          isRequested: isRequested,
          isApproved: isApproved,
        }}
      >
        <DesignReviewComponent />
      </DesignReviewProvider>
    </ThemeProvider>
  )
}

export default CampaignMediaReview
