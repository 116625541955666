import _isEmpty from 'lodash/isEmpty'

import API, { DATA_API } from 'src/helpers/api'
import { repository as mailsRepo } from 'src/models/Mailing'
import accountStore from 'src/config/store/AccountStore'

import processMailData from './processMailData'

/**
 *
 * @param {object} options
 * @param {CancelTokenSource|undefined} cancelToken
 */
export default async ({
    page,
    pageSize,
    sort,
    dir,
    filters,
    cancelToken
  }) => {

  const accounts = accountStore.selectedAccounts || []

  if(_isEmpty(accounts)) {
    return {
      meta: {
        totalCount: 0,
        pageCount: 0,
        pageSize: 0,
        page: 0,
      },
      records: []
    }
  }

  const data = {
    accounts: accounts,
    page,
    pageSize,
    sort,
    dir,
    filters
  }

  const result = await API.get({
    api: DATA_API,
    url: '/mailings',
    params: data,
    cancelToken: cancelToken && cancelToken.token
  })

  const {
    meta,
    records
	} = result

  const mails = records.map(row => {
    const mail = processMailData(row)
    return mailsRepo.upsert(mail)
  })

  return {
    meta: {
      totalCount: meta.totalCount,
      pageCount: meta.pageCount,
      pageSize: meta.pageSize,
      page: meta.page,
    },
    records: mails
  }
}
