import jwtDecode from 'jwt-decode'
import _get from 'lodash/get'
import _has from 'lodash/has'
import { SCOREBOARD_ACCESS_HEADER } from 'src/config/store/AppState'

import { authClient } from 'src/helpers/auth0'
import getRelatedAccounts from 'src/services/users/getRelatedAccounts'

/**
 * Helper function for processing an authentication when the access token
 * is provided
 */
const processAccessToken = async (accessToken, options = {}) => {
  options = Object.assign(
    {
      scoreboardGrant: null,
    },
    options
  )
  return new Promise((resolve, reject) => {
    const jwt = jwtDecode(accessToken)
    const roles =
      _has(jwt, 'https://mvpmailhouse.com/roles') &&
      jwt['https://mvpmailhouse.com/roles']
    authClient.userInfo(accessToken, (err, authResult) => {
      if (err) {
        console.log(err)
        return reject(new Error('Login failed.'))
      }
      const { name, picture, email } = authResult
      const { exp } = jwt
      const id =
        _has(authResult, 'https://mvpmailhouse.com/user_metadata') &&
        authResult['https://mvpmailhouse.com/user_metadata'].sf_contact_id
      const expiresAt = exp * 1000
      const tokenType = 'Bearer'
      getRelatedAccounts({
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
          ...((options.scoreboardGrant && {
            [SCOREBOARD_ACCESS_HEADER]: options.scoreboardGrant,
          }) ||
            {}),
        },
      })
        .then((accounts) => {
          const session = {
            expiresAt,
            accessToken,
            tokenType,
            picture,
            userInfo: {
              id,
              name: name,
              accounts,
              email,
              roles,
            },
          }
          resolve(session)
        })
        .catch((error) => {
          console.log(error)
          reject(new Error('Failed to retrieve accounts list'))
        })
    })
  })
}

export default processAccessToken
