import ApiHelper, { IDENTITY_API } from 'src/helpers/api'

/**
 * Sends activation link
 *
 *
 * @param {string} email
 * @param {string} code
 */
export const generateActivationLink = async (email, code) => {
  const response = await ApiHelper.post({
    api: IDENTITY_API,
    url: 'contacts/refresh-activation-link',
    data: {
      email,
      code
    }
  })

  return response
}

export default generateActivationLink