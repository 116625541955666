import { observable, computed, action } from 'mobx'

import Repository from './Repository'

export const repository = new Repository<Appointment>()

export default class Appointment {
  @observable isLoading = false
  @observable isError = false

  @observable id: string
  @observable code?: string
  @observable dateTime?: string
  @observable lastName?: string
  @observable firstName?: string
  @observable contactId?: string
  @observable scoreId?: string
  @observable contactName?: string
  @observable contactEmail?: string
  @observable outcome?: any
  @observable previousAppointmentId?: string
  @observable isTemp?: boolean
  @observable call?: string
  @observable callId?: string
  @observable campaign?: string
  @observable answeredBy?: string
  @observable callHandler?: string
  @observable callSummary?: string
  @observable type?: string
  @observable targetDeliveryDate?: Date
  @observable callAudioURL?: string
  @observable callDuration?: string
  @observable phoneNumber?: string
  @observable callResolveDate?: string
  @observable count?: number

  @observable campaignTargetDeliveryWeek?: string
  @observable campaignType?: string

  constructor(appointment: any) {
    this.id = appointment.id
  }

  @computed
  get fullName() {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`
    }

    if (this.firstName) {
      return this.firstName
    }

    if (this.lastName) {
      return this.lastName
    }

    return '-'
  }

  @action.bound
  loading(isLoading = true) {
    this.isLoading = isLoading
  }
}
