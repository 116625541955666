export const ACTIVE_CONDITIONS = [
  'Active',
  'Idle - no calls within the last 90 days',
  'DO NOT RELEASE',
  'For Review',
  '>13 months',
  'For release',
]

export const POSTCARD_CAMPAIGN_TYPES = [
  'MVP Mail',
  'New Mover Mail',
  'New Mover Mail (Print Only)',
  'My Patient Mail',
  'Custom',
  'Handout Cards',
]

export const COLORS = {
  neutral: {
    default: '#64748B',
    shade50: '#F7F8F9',
    shade100: '#E7EAEE',
    shade200: '#D0D5DD',
    shade300: '#B8C0CC',
    shade400: '#A0ABBB',
    shade500: '#64748B',
    shade600: '#4B5768',
    shade700: '#323A46',
    shade800: '#191D23',
    shade900: '#0D0F11',
  },
  primary: {
    default: '#ED164E',
    shade50: '#fef3f6',
    shade100: '#fde8ed',
    shade200: '#fbc5d3',
    shade300: '#f8a2b8',
    shade400: '#F57F9E',
    shade500: '#ED164E',
    shade600: '#B7003D',
    shade700: '#870a2b',
    shade800: '#5a071d',
    shade900: '#3c0513',
  },
  secondary: {
    default: '#FCD718',
    shade50: '#FFFDF3',
    shade100: '#FFF9DC',
    shade200: '#FEF3BA',
    shade300: '#FEED97',
    shade400: '#FCE465',
    shade500: '#FCD718',
    shade600: '#F5CE03',
    shade700: '#BFA002',
    shade800: '#6D5C01',
    shade900: '#362E01',
  },
  ternary: {
    default: '#5A96E1',
    shade50: '#F6FAFE',
    shade100: '#E4EFFD',
    shade200: '#C9DFFA',
    shade300: '#AECEF8',
    shade400: '#92BEF5',
    shade500: '#5A96E1',
    shade600: '#1471E6',
    shade700: '#0F55AD',
    shade800: '#0A3973',
    shade900: '#051C3A',
  },
  success: {
    default: '#10B981',
    shade50: '#ECFDF5',
    shade100: '#D1FAE5',
    shade200: '#A7F3D0',
    shade300: '#6EE7B7',
    shade400: '#34D399',
    shade500: '#10B981',
    shade600: '#059669',
    shade700: '#047857',
    shade800: '#065F46',
    shade900: '#064E3B',
  },
  warning: {
    default: '#F59E0B',
    shade50: '#FFF3EB',
    shade100: '#FBEADF',
    shade200: '#FDDDC8',
    shade300: '#FDC788',
    shade400: '#FFB74B',
    shade500: '#FA9E03',
    shade600: '#D97706',
    shade700: '#B45309',
    shade800: '#92400E',
    shade900: '#78350F',
  },
  error: {
    default: '#EF4444',
    shade50: '#FEF2F2',
    shade100: '#FEE2E2',
    shade200: '#FECACA',
    shade300: '#FCA5A5',
    shade400: '#F87171',
    shade500: '#EF4444',
    shade600: '#DC2626',
    shade700: '#B91C1C',
    shade800: '#991B1B',
    shade900: '#7F1D1D',
  },
  white: '#FFFFFF',
  black: '#141414',
}
