import React, { useEffect, useState, useRef } from 'react'
import { Observer } from 'mobx-react'
import withStyles from '@material-ui/core/styles/withStyles'
import _get from 'lodash/get'
import { Button } from '@material-ui/core'

import ProfileSettings from 'src/components/Settings/ProfileSettings'
import AlertSettings from 'src/components/Settings/AlertSettings'
import AppState from 'src/config/store/AppState'
import settingsStore from 'src/config/store/SettingsStore'
import MVPFlashMessages from 'components/MVP/MVPFlashMessages'

const styles = () => ({
  container: {
    padding: '28px 24px',
    backgroundColor: '#fff',
  },
})

const Settings = (props) => {
  const { classes } = props

  const refAlertSettings = useRef()

  const getUserSettings = async () => {
    try {
      await settingsStore.getUserSettings()
    } catch (e) {
      console.error(e)
      AppState.errorMessage(
        'Something went wrong retrieving your settings. Please try again.'
      )
    } finally {
      settingsStore.isLoading = false
    }
  }

  if (!AppState.isRestricted()) {
    useEffect(() => {
      getUserSettings()
    }, [])
  }

  const saveNotificationSettings = () => {
    const result = refAlertSettings.current.saveNotificationSettings()
    if (!result || result === undefined) {
      AppState.errorMessage('Something went wrong')
    } else {
      AppState.successMessage('Settings updated')
    }
    AppState.toggleFlash(true)
  }
  
  return (
    <Observer
      render={() => (
        <>
          <MVPFlashMessages />
          <div className={classes.container}>
            <ProfileSettings />
            <AlertSettings 
              ref={refAlertSettings}
              isPrimaryContact={settingsStore.isPrimaryContact}
            />
          </div>
          <div
            className={classes.container}
            style={{ marginTop: 20, display: 'flex', justifyContent: 'end' }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button 
              onClick={() => saveNotificationSettings()}
              variant="contained" 
              color="primary">
              Save
            </Button>
          </div>
        </>
      )}
    />
  )
}

export default withStyles(styles)(Settings)
