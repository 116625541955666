// CORE
import React from 'react'

// STYLES
import { useOverviewCardStyles } from './Overview.style'

// COMPONENTS
import AllStats from 'components/CallPro/AllStats/AllStats'
import Offers from 'components/CallPro/Offers/Offers'
import Services from 'components/CallPro/Services/Services'
import Insurance from 'components/CallPro/Insurance/Insurance'
import CallsPerHour from 'components/CallPro/CallsPerHour/CallsPerHour'
import OverviewMissedAndFollowUpCalls from 'components/CallPro/OverviewMissedAndFollowUpCalls/OverviewMissedAndFollowUpCalls'
import TrackingLines from 'components/CallPro/TrackingLines/TrackingLines'
import { CallerGender } from 'components/CallPro/CallerGender'
import { PaymentTypes } from 'components/CallPro/PaymentTypes'
import { useStores } from 'config/store'
import { InaccurateReferralSources } from '../InaccurateReferralSources'
import { CallNeedsFollowUp } from '../CallNeedsFollowUp'
import { TopCampaignSources } from '../TopCampaignSources'
import { NoApptScheduledReason } from '../NoApptScheduledReason'
import { NonOpportunityReason } from '../NonOpportunityReason'
import { AnsweredBy } from '../AnsweredBy'
import { IVROutcome } from '../IVROutcome'
import { CallerLanguage } from '../CallerLanguage'
import { VoicemailOutcome } from '../VoicemailOutcome'
import { PatientTypes } from '../PatientTypes'
import { Observer } from 'mobx-react'
import { Theme, useMediaQuery } from '@material-ui/core'
import { MobileAccordion } from 'components/MobileAccordion'
import { formatTextByIndustry } from 'helpers/industries'
import Account from 'models/Account'

const Overview = () => {
  const classes = useOverviewCardStyles()
  const { callsStore, accountStore } = useStores()
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  const account: Account = accountStore.accounts[0]

  if (mobileDevice) {
    return (
      <div className={classes.root}>
        <AllStats />
        <div className={classes.accordionWrapper}>
          <MobileAccordion title="TOP REQUESTED OFFERS">
            <Offers isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="TOP REQUESTED SERVICES">
            <Services isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="TOP NON OPPORTUNITY REASONS">
            <NonOpportunityReason isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="CALL ANSWERED BY">
            <AnsweredBy isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="CALLER GENDER">
            <CallerGender isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="PAYMENT TYPES">
            <PaymentTypes isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="TOP REQUESTED INSURANCES">
            <Insurance isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="TOP INACCURATE REFERRAL SOURCES">
            <InaccurateReferralSources isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="TOP REASONS CALL NEEDS FOLLOW UP">
            <CallNeedsFollowUp isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="TOP CAMPAIGN REFERRAL SOURCES">
            <TopCampaignSources isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="TOP REASONS NO APPOINTMENT WAS SCHEDULED">
            <NoApptScheduledReason isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="IVR OUTCOME">
            <IVROutcome isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="VOICEMAIL OUTCOME">
            <VoicemailOutcome isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="CALLER LANGUAGE">
            <CallerLanguage isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion
            title={formatTextByIndustry({
              account,
              text: `{CLIENT_LABEL} TYPES`,
            })}
          >
            <PatientTypes isAccordionContent={true} />
          </MobileAccordion>

          <MobileAccordion title="OPPORTUNITY CALLS BY HOUR">
            <CallsPerHour isAccordionContent={true} />
          </MobileAccordion>
        </div>

        <Observer
          render={() => {
            const { missedAndFollowupCallsTotalCount } = callsStore
            return missedAndFollowupCallsTotalCount > 0 ? (
              <div className={classes.col1}>
                <OverviewMissedAndFollowUpCalls />
              </div>
            ) : (
              <></>
            )
          }}
        />
        <TrackingLines classes={{ root: classes.col1 }} />
      </div>
    )
  } else {
    return (
      <div className={classes.root}>
        <AllStats />
        <div className={classes.col2}>
          <Offers />
          <Services />
        </div>
        <div className={classes.col2}>
          <NonOpportunityReason />
          <AnsweredBy />
        </div>
        <div className={classes.col2}>
          <CallerGender />
          <PaymentTypes />
        </div>
        <div className={classes.col2}>
          <Insurance />
          <InaccurateReferralSources />
        </div>
        <div className={classes.col2}>
          <CallNeedsFollowUp />
          <TopCampaignSources />
        </div>
        <div className={classes.col2}>
          <NoApptScheduledReason />
          <IVROutcome />
        </div>
        <div className={classes.col2}>
          <VoicemailOutcome />
          <CallerLanguage />
        </div>
        <div className={classes.col2}>
          <PatientTypes />
          <CallsPerHour />
        </div>

        <Observer
          render={() => {
            const { missedAndFollowupCallsTotalCount } = callsStore
            return missedAndFollowupCallsTotalCount > 0 ? (
              <div className={classes.col1}>
                <OverviewMissedAndFollowUpCalls />
              </div>
            ) : (
              <></>
            )
          }}
        />
        <TrackingLines classes={{ root: classes.col1 }} />
      </div>
    )
  }
}

export default Overview
