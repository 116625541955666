/**
 * ******************************************
 * Designed to be used with views/Campaigns/Campaigns.tsx
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React from 'react'

// My components
import AppBar from 'components/AppBar/AppBar'
import AppBarTitleSection from 'components/AppBar/AppBarTitleSection'
import AppBarToolbar from 'components/AppBar/AppBarToolbar'
import AppBarTitle from 'components/AppBar/AppBarTitle'
import AppBarTitleSectionFilter from 'components/AppBar/AppBarTitleSectionFilter'
import ToolbarDrawerIconButon from 'components/AppBar/ToolbarDrawerIconButon'
import CampaignTabBar from 'views/Campaigns/CampaignTabBar'

export default function CampaignAppBar() {
  return (
    <AppBar>
      <AppBarTitleSection>
        <AppBarToolbar>
          <ToolbarDrawerIconButon />
          <AppBarTitle>Campaigns</AppBarTitle>
        </AppBarToolbar>
        <AppBarTitleSectionFilter />
      </AppBarTitleSection>
      <CampaignTabBar />
    </AppBar>
  )
}
