import React, { Dispatch, SetStateAction, useEffect } from 'react'
import clsx from 'clsx'

// core material ui components
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import ToolTip from '@material-ui/core/Tooltip'

// Icons
import { observer } from 'mobx-react'
import { useStores } from 'config/store'
import TableSkeleton from './TableSkeleton'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

// Styles
import { useLeaderboardStyles } from './Leaderboard.styles'
import { truncateText } from '../../../helpers/text'
import { camelize } from 'helpers/text'
import { StaffInterface, SortCriteria } from 'interfaces/Call.interface'
import { toJS } from 'mobx'
import { useFrontDeskStyles } from './FrontDesk.styles'
import { useStyles as useToolTipStyle } from './OfficeHandlingChart.styles'
import { formatNumber } from 'helpers/utility'
import { TableSortLabel, Theme, useMediaQuery } from '@material-ui/core'
import classNames from 'classnames'

interface LeaderBoardProps {
  selectedStaff: StaffInterface
  setSelectedStaff: Dispatch<SetStateAction<StaffInterface>>
}

const LeaderBoard = (props: LeaderBoardProps) => {
  const classes = useLeaderboardStyles()
  const frontDeskClass = useFrontDeskStyles()
  const toolTipClass = useToolTipStyle()
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  const { frontDeskStore } = useStores()
  const {
    loadingStates,
    leaderBoard,
    sortBy,
    leaderboardAllCallCount,
    leaderboardOpportunityCount,
    leaderboardAppointmentCount,
    leaderboardAverageConversionRate,
    leaderboardStaffCount,
    leaderboardAppointmentCallCount,
    toggleNameSort,
    nameSortDir,
    toggleRankSort,
    rankSortDir,
  } = frontDeskStore
  const { selectedStaff, setSelectedStaff } = props

  const selectStaff = (staff: StaffInterface) => {
    setSelectedStaff(staff)
  }

  const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    frontDeskStore.setSortBy(event.target.value as string)
    frontDeskStore.refreshTeamLeaderboards()
  }

  useEffect(() => {
    if (!mobileDevice) {
      leaderBoard && leaderBoard.length
        ? setSelectedStaff(leaderBoard[0])
        : setSelectedStaff({} as StaffInterface)
    } else {
      setSelectedStaff({} as StaffInterface)
    }
  }, [leaderBoard])

  useEffect(() => {
    setSelectedStaff({} as StaffInterface)
  }, [])

  return (
    <div
      className={classNames(
        classes.leaderboard,
        mobileDevice ? classes.leaderboardMobile : null
      )}
    >
      <div className={classes.leaderboard__title}>
        <h1>Staff Leaderboard</h1>
        <div className={classes.leaderboard__rank}>
          <p>Rank by</p>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.dropdownContainer}
          >
            <Select
              id="demo-simple-select-outlined"
              value={camelize(sortBy)}
              onChange={handleSortChange}
              className={classes.leaderboard_table_rank_dropdown}
            >
              {Object.values(SortCriteria).map((sortCriteria) => (
                <MenuItem key={sortCriteria} value={camelize(sortCriteria)}>
                  {sortCriteria}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className={classes.leaderboard_table_content}>
        <Table stickyHeader aria-label="sticky table">
          {loadingStates.leaderBoard ? (
            <TableSkeleton></TableSkeleton>
          ) : (
            <>
              <TableHead>
                <TableRow className={classes.leaderboard_table_labels}>
                  <TableCell
                    className={classes.leaderboard_table_label}
                    padding="none"
                    style={{
                      width: '10%',
                      textAlign: 'center',
                    }}
                  >
                    <TableSortLabel
                      active={!!rankSortDir}
                      direction={rankSortDir}
                      onClick={toggleRankSort}
                    >
                      Rank
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.leaderboard_table_label}
                    padding="none"
                    style={{
                      width: '25%',
                      paddingLeft: '1rem',
                    }}
                    align="left"
                  >
                    <TableSortLabel
                      active={!!nameSortDir}
                      direction={nameSortDir}
                      onClick={toggleNameSort}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.leaderboard_table_label,
                      !nameSortDir && sortBy === camelize(SortCriteria.CALLS)
                        ? classes.active_column
                        : null
                    )}
                    padding="none"
                    style={{ width: '15%' }}
                    align="center"
                  >
                    <ToolTip
                      title="The total number of calls answered by staff. This may include test calls, spam calls, etc."
                      placement="top"
                      classes={{ tooltip: frontDeskClass.tooltip }}
                    >
                      <div>
                        <span>Calls</span>
                        {!mobileDevice ? (
                          <InfoOutlinedIcon
                            className={toolTipClass.grade_icon}
                            style={{
                              verticalAlign: 'middle',
                              marginLeft: '0.15rem',
                            }}
                          />
                        ) : null}
                      </div>
                    </ToolTip>
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.leaderboard_table_label,
                      !nameSortDir &&
                        sortBy === camelize(SortCriteria.OPPORTUNITY)
                        ? classes.active_column
                        : null
                    )}
                    padding="none"
                    style={{ width: '15%' }}
                    align="center"
                  >
                    <ToolTip
                      title="The total number of calls answered by staff that were scored as valid appointment opportunities."
                      placement="top"
                      classes={{ tooltip: frontDeskClass.tooltip }}
                    >
                      <div>
                        <span>Opportunities</span>
                        {!mobileDevice ? (
                          <InfoOutlinedIcon
                            className={toolTipClass.grade_icon}
                            style={{
                              verticalAlign: 'middle',
                              marginLeft: '0.15rem',
                            }}
                          />
                        ) : null}
                      </div>
                    </ToolTip>
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.leaderboard_table_label,
                      !nameSortDir &&
                        sortBy === camelize(SortCriteria.APPOINTMENTS)
                        ? classes.active_column
                        : null
                    )}
                    padding="none"
                    style={{ width: '15%' }}
                    align="center"
                  >
                    <ToolTip
                      title="This is the total number of all scheduled appointments including when multiple appointments are scheduled on the same call."
                      placement="top"
                      classes={{ tooltip: frontDeskClass.tooltip }}
                    >
                      <div>
                        <span>Appointments</span>
                        {!mobileDevice ? (
                          <InfoOutlinedIcon
                            className={toolTipClass.grade_icon}
                            style={{
                              verticalAlign: 'middle',
                              marginLeft: '0.15rem',
                            }}
                          />
                        ) : null}
                      </div>
                    </ToolTip>
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.leaderboard_table_label,
                      !nameSortDir &&
                        sortBy === camelize(SortCriteria.APPT_CALLS)
                        ? classes.active_column
                        : null
                    )}
                    padding="none"
                    style={{ width: '15%' }}
                    align="center"
                  >
                    <ToolTip
                      title="The total number of unique calls that resulted in one or more appointments being scheduled by staff."
                      placement="top"
                      classes={{ tooltip: frontDeskClass.tooltip }}
                    >
                      <div>
                        <span>Appt Calls</span>
                        {!mobileDevice ? (
                          <InfoOutlinedIcon
                            className={toolTipClass.grade_icon}
                            style={{
                              verticalAlign: 'middle',
                              marginLeft: '0.15rem',
                            }}
                          />
                        ) : null}
                      </div>
                    </ToolTip>
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.leaderboard_table_label,
                      sortBy === camelize(SortCriteria.CONVERSIONRATE)
                        ? classes.active_column
                        : null
                    )}
                    style={{ width: '20%', padding: '0px 8px' }}
                    align="center"
                  >
                    <ToolTip
                      title="This is the average rate at which the staff converts answered opportunity calls into scheduled appointments."
                      placement="top"
                      classes={{ tooltip: frontDeskClass.tooltip }}
                    >
                      <div>
                        <span>Conv Rate</span>
                        {!mobileDevice ? (
                          <InfoOutlinedIcon
                            className={toolTipClass.grade_icon}
                            style={{
                              verticalAlign: 'middle',
                              marginLeft: '0.15rem',
                            }}
                          />
                        ) : null}
                      </div>
                    </ToolTip>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {leaderBoard.map((entry: any) => (
                  <TableRow
                    className={clsx(
                      classes.leaderboard_table_data_row,
                      entry.callHandlerId === selectedStaff.callHandlerId
                        ? classes.active_row
                        : null
                    )}
                    onClick={() => {
                      const staff = toJS(entry)
                      selectStaff(staff as StaffInterface)
                    }}
                  >
                    <TableCell
                      className={classes.leaderboard_table_data}
                      align="center"
                      style={{
                        fontSize: '.8rem',
                      }}
                    >
                      {entry.rank ?? 0}
                    </TableCell>
                    <TableCell
                      className={classes.leaderboard_table_data}
                      component="th"
                      scope="row"
                      style={{
                        fontWeight: 'bold',
                        fontSize: '.8rem',
                        paddingLeft: '1rem',
                      }}
                    >
                      {truncateText(entry.name, 25)}
                    </TableCell>
                    <TableCell
                      className={classes.leaderboard_table_data}
                      align="center"
                    >
                      <span
                        style={{
                          verticalAlign: 'middle',
                          fontSize: '.8rem',
                          color: '#222',
                          fontWeight: 600,
                        }}
                      >
                        {entry.callMadeCount}
                      </span>
                    </TableCell>
                    <TableCell
                      className={classes.leaderboard_table_data}
                      align="center"
                    >
                      <span
                        style={{
                          verticalAlign: 'middle',
                          fontSize: '.8rem',
                          color: '#222',
                          fontWeight: 600,
                        }}
                      >
                        {entry.opportunityCallCount}
                      </span>
                    </TableCell>
                    <TableCell
                      className={classes.leaderboard_table_data}
                      align="center"
                    >
                      <span
                        style={{
                          verticalAlign: 'middle',
                          fontSize: '.8rem',
                          color: '#222',
                          fontWeight: 600,
                        }}
                      >
                        {entry.appointmentsMadeCount}
                      </span>
                    </TableCell>

                    <TableCell
                      className={classes.leaderboard_table_data}
                      align="center"
                    >
                      <span
                        style={{
                          verticalAlign: 'middle',
                          fontSize: '.8rem',
                          color: '#222',
                          fontWeight: 600,
                        }}
                      >
                        {entry.appointmentsCallCount}
                      </span>
                    </TableCell>

                    <TableCell
                      className={classes.leaderboard_table_data}
                      align="center"
                    >
                      <span
                        style={{
                          verticalAlign: 'middle',
                          fontSize: '.8rem',
                          color: '#222',
                          fontWeight: 600,
                        }}
                      >
                        {entry.conversionRate || 0}%
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              <TableFooter>
                <TableRow className={classes.leaderboard_table_labels}>
                  <TableCell
                    className={classes.leaderboard_table_label}
                    padding="none"
                    style={{
                      width: '10%',
                      textAlign: 'center',
                      position: 'sticky',
                      bottom: 0,
                      background: '#fafafa',
                    }}
                  >
                    {leaderboardStaffCount || 0}
                  </TableCell>
                  <TableCell
                    className={classes.leaderboard_table_label}
                    padding="none"
                    style={{
                      width: '30%',
                      paddingLeft: '1rem',
                      position: 'sticky',
                      bottom: 0,
                      background: '#fafafa',
                    }}
                    align="left"
                  >
                    Totals
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.leaderboard_table_label,
                      !nameSortDir && sortBy === camelize(SortCriteria.CALLS)
                        ? classes.active_column
                        : null
                    )}
                    padding="none"
                    style={{
                      width: '15%',
                      position: 'sticky',
                      bottom: 0,
                      background: '#fafafa',
                    }}
                    align="center"
                  >
                    {formatNumber(leaderboardAllCallCount) || 0}
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.leaderboard_table_label,
                      !nameSortDir &&
                        sortBy === camelize(SortCriteria.OPPORTUNITY)
                        ? classes.active_column
                        : null
                    )}
                    padding="none"
                    style={{
                      width: '15%',
                      position: 'sticky',
                      bottom: 0,
                      background: '#fafafa',
                    }}
                    align="center"
                  >
                    {leaderboardOpportunityCount || 0}
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.leaderboard_table_label,
                      !nameSortDir &&
                        sortBy === camelize(SortCriteria.APPOINTMENTS)
                        ? classes.active_column
                        : null
                    )}
                    padding="none"
                    style={{
                      width: '15%',
                      position: 'sticky',
                      bottom: 0,
                      background: '#fafafa',
                    }}
                    align="center"
                  >
                    {formatNumber(leaderboardAppointmentCount) || 0}
                  </TableCell>

                  <TableCell
                    className={clsx(
                      classes.leaderboard_table_label,
                      !nameSortDir &&
                        sortBy === camelize(SortCriteria.APPT_CALLS)
                        ? classes.active_column
                        : null
                    )}
                    padding="none"
                    style={{
                      width: '15%',
                      position: 'sticky',
                      bottom: 0,
                      background: '#fafafa',
                    }}
                    align="center"
                  >
                    {formatNumber(leaderboardAppointmentCallCount) || 0}
                  </TableCell>

                  <TableCell
                    className={clsx(
                      classes.leaderboard_table_label,
                      !nameSortDir &&
                        sortBy === camelize(SortCriteria.CONVERSIONRATE)
                        ? classes.active_column
                        : null
                    )}
                    style={{
                      width: '15%',
                      position: 'sticky',
                      bottom: 0,
                      background: '#fafafa',
                    }}
                    align="center"
                  >
                    {leaderboardAverageConversionRate || 0}%
                  </TableCell>
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
      </div>
    </div>
  )
}

export default observer(LeaderBoard)
