// ##############################
// // // CustomInputWithIcon styles
// #############################

import {
  primaryColor,
  primaryDarkColor,
  dangerColor,
  successColor,
  defaultFont
} from 'src/assets/jss/material-dashboard-react.jsx'

const customInputStyleWithIcon = {
  disabled: {
    '&:before': {
      backgroundColor: 'transparent !important'
    }
  },
  underline: {
    '&:hover:not($disabled):before,&:before': {
      display: 'none',
      height: '1px !important'
    },
    '&:after': {
      backgroundColor: 'transparent'
    },
    '&:before': {
      height: '0px !important'
    },
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: '400',
    boxShadow: '0 1px #BA1040',
    paddingBottom: 0,
    '&$disabled': {
      boxShadow: 'none'
    }
  },
  underlineError: {
    '&:after': {
      backgroundColor: dangerColor
    }
  },
  underlineSuccess: {
    '&:after': {
      backgroundColor: successColor
    }
  },
  labelRoot: {
    ...defaultFont,
    color: primaryDarkColor,
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '3.5px',
  },
  labelRootError: {
    color: dangerColor
  },
  labelRootSuccess: {
    color: successColor
  },
  feedback: {
    position: 'absolute',
    top: '18px',
    right: '0',
    zIndex: '2',
    display: 'block',
    width: '24px',
    height: '24px',
    textAlign: 'center',
    pointerEvents: 'none'
  },
  input: {
    ...defaultFont,
    fontSize: '14px',
    fontWeight: '500',
    borderWidth: 0,
    backgroundColor: 'transparent',
    '&:hover': {
      borderWidth: 0
    },
    '&:hover:not(:focus)': {
      borderWidth: 0
    }
  },
  marginTop: {
    marginTop: '16px'
  },
  formControl: {
    paddingBottom: '10px',
    paddingTop: '12px',
    margin: '0 0 28px 0',
    position: 'relative',
    'label + &': {
      marginTop: '1rem'
    }
  }
}

export default customInputStyleWithIcon
