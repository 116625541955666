import React, { useState } from 'react'
import { Observer } from 'mobx-react'
import { Marker } from 'react-google-maps'
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer'

import toothIcon from 'src/assets/img/office.svg'
import mapsStore from 'src/config/store/MapsStore'
import { repository as patientListLayerRepo } from 'src/models/PatientListLayer'
import { repository as markerRepo } from 'src/models/PatientListLayer/HouseholdMarker'
import getPatientIconByHex from '../../PatientList/PatientListIconSet'
import HouseholdMarkerInfobox from './HouseholdMarkerInfobox'


const clusterStyles = [
  {
    textColor: '#00000000',
    url: toothIcon,
    height: 6.5,
    width: 6.5,
    textSize: 0
  }
]

const PatientListMarkers = props => {
  const { clustered } = props
  const { patientListLayers: layers } = mapsStore
  const [visibleMarkerInfo, setVisibleMarkerInfo] = useState(undefined)

  const handleMarkerClick = id => () => {
    setVisibleMarkerInfo(id)
  }

  const handleCloseMarkerInfo = marker => () => {
    marker.isCallAudioPlaying = false
    setVisibleMarkerInfo(undefined)
  }

  const renderMarkers = layers => {
    return <React.Fragment>
      {
        layers.map(id => {
          const layer = patientListLayerRepo.get(id)
          if (!layer || !layer.visible) {
            return null
          }

          return layer.markers.map(id => {
            const marker = markerRepo.get(id)
            let icon = {
              url: marker.icon.url || getPatientIconByHex(layer.icon, false, true),
              // eslint-disable-next-line no-undef
              scaledSize: marker.icon.size || new google.maps.Size(6.5, 6.5)
            }
            if (!marker) {
              return null
            }
            return <Marker
              key={`patient-list-marker--${id}`}
              id={`patient-list-marker--${id}`}
              position={marker.position}
              icon={icon}
              onClick={handleMarkerClick(id)}
            >
              {
                visibleMarkerInfo === id && <HouseholdMarkerInfobox
                  id={id}
                  handleClose={handleCloseMarkerInfo(marker)}
                />
              }
            </Marker>
          })
        })
      }
    </React.Fragment>
  }

  return <Observer
    render={() => {
      if (clustered) {
        return <MarkerClusterer
          enableRetinaIcons
          averageCenter
          styles={clusterStyles}
          minimumClusterSize={10}
        >
          {renderMarkers(layers)}
        </MarkerClusterer>
      }

      return renderMarkers(layers)
    }}
  />
}

export default PatientListMarkers
