import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '.85rem',
    },

    container: {
      backgroundColor: '#fff',
      borderRadius: '10px',
      padding: '1rem',
    },

    title: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      paddingBottom: '.7rem',
    },

    row: {
      display: 'grid',
      gridTemplateColumns: '7rem 1fr 7rem',
      '@media screen and (max-width: 780px)': {
        gridTemplateColumns: '4rem 1fr 4rem',
      },
      '&:nth-of-type(2)': {
        '& $rowData': {
          fontWeight: 'bold',
          color: theme.palette.common.black,
        },
      },
      '&:last-child': {
        '& $rowData': {
          borderBottom: '1px solid #ddd',
        },
      },
    },

    rowData: {
      border: '1px solid #ddd',
      borderRight: 'none',
      borderBottom: 'none',
      padding: '.5rem',
      fontSize: '.85rem',

      display: 'flex',
      alignItems: 'center',

      '&:last-child': {
        borderRight: '1px solid #ddd',
      },
    },

    rowEmpty: {
      textAlign: 'center',
      paddingTop: 14,
    },

    link: {
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  })
)
