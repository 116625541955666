import { createStyles, makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'constants/constants'

const globalStyles = {
  root: {
    borderTop: '1px solid rgba(0, 0, 0, 0.06)',
    padding: 16,
    display: 'flex',
    gap: 8,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  statContainer: {
    display: 'flex',
    'flex-direction': 'column',
    alignItems: 'center',
    rowGap: 10,
    cursor: 'help',
  },
  divider: {
    backgroundColor: '#f0f0f0',
  },
  statNumber: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '1em',
  },
  statLabel: {
    fontSize: 10,
    lineHeight: '0.6125em',
  },
  appointments: {
    color: COLORS.primary.default,
  },
  opportunities: {
    color: COLORS.neutral.shade300,
  },
  conversionRateContainer: {
    padding: 6,
    backgroundColor: COLORS.primary.shade100,
    borderRadius: 4,
  },
  conversionRate: {
    color: COLORS.primary.shade900,
  },
  tooltip: {
    backgroundColor: '#222',
    '&::after': {
      display: 'none',
    },
  },
}

export const useLargeStyles = makeStyles({
  root: {
    ...globalStyles.root,
    gap: 20,
    paddingTop: 25,
    paddingBottom: 25,
  },
  statContainer: {
    ...globalStyles.statContainer,
  },
  divider: {
    ...globalStyles.divider,
  },
  statNumber: {
    ...globalStyles.statNumber,
    fontSize: 30,
  },
  statLabel: {
    ...globalStyles.statLabel,
    fontSize: 14,
  },
  appointments: {
    ...globalStyles.appointments,
  },
  opportunities: {
    ...globalStyles.opportunities,
  },
  conversionRateContainer: {
    ...globalStyles.conversionRateContainer,
    borderRadius: 10,
    padding: 17,
  },
  conversionRate: {
    ...globalStyles.conversionRate,
  },
  tooltip: {
    ...globalStyles.tooltip,
  },
})

export const useDefaultStyles = makeStyles({
  root: {
    ...globalStyles.root,
  },
  statContainer: {
    ...globalStyles.statContainer,
  },
  divider: {
    ...globalStyles.divider,
  },
  statNumber: {
    ...globalStyles.statNumber,
  },
  statLabel: {
    ...globalStyles.statLabel,
  },
  appointments: {
    ...globalStyles.appointments,
  },
  opportunities: {
    ...globalStyles.opportunities,
  },
  conversionRateContainer: {
    ...globalStyles.conversionRateContainer,
  },
  conversionRate: {
    ...globalStyles.conversionRate,
  },
  tooltip: {
    ...globalStyles.tooltip,
  },
})
