import React, { useState, useEffect, Fragment } from 'react'
import { passwordlessAuth } from 'helpers/auth0'
import { useHistory } from 'react-router-dom'
import { Location } from 'history'
import cx from 'classnames'
import { has } from 'lodash'

import lock from '../../assets/img/lock.svg'
import check from '../../assets/img/check.svg'

import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

import styles from './LoginForm.styles'

import checkUserEmail from 'services/users/checkUserEmail'
import { isPhoneValid } from 'helpers/phoneNumber'
import AppState from 'config/store/AppState'
import settingsStore from 'config/store/SettingsStore'

import EmailOrPhoneField from './components/EmailOrPhoneField'
import PasswordField from './components/PasswordField'
import VerificationCodeField from './components/VerificationCodeField'
import appState from 'config/store/AppState'

export enum Theme {
  Light = 'light',
  Dark = 'dark',
}

interface LoginProps {
  location: Location
  theme: Theme
  handleShowLinkForm?: Function
}

const LoginForm: React.FC<LoginProps> = (props) => {
  const { location: _location, theme, handleShowLinkForm } = props
  const history = useHistory()
  const classes = styles()

  const [emailOrPhone, setEmailOrPhone] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')
  const [showPasswordField, setShowPasswordField] = useState(true)
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [resend, setResend] = useState(false)
  const [redirectPath, setRedirectPath] = useState('')

  const verb = AppState.isRestricted() ? 'Verify' : 'Sign In'

  const validateFields = (email: string, phone: string) => {
    const isEmail = email.includes('@')
    const isPhone = isPhoneValid(phone)

    return {
      email: isEmail,
      phone: isPhone,
    }
  }

  const isEmail = (field: string) => validateFields(field, '').email
  const isPhone = (field: string) => validateFields('', field).phone

  const authParams = (
    field: string
  ):
    | { connection: string; email?: string; phoneNumber?: string }
    | undefined => {
    if (isEmail(field)) {
      return {
        connection: 'email',
        email: field,
      }
    }
    if (isPhone(field)) {
      return {
        connection: 'sms',
        phoneNumber: field,
      }
    }
  }

  function errorSubmit() {
    AppState.errorMessage('There was a problem sending the verification code')
    setIsAuthenticating(false)
  }
  const handleTogglePassword = () => {
    setShowPasswordField(!showPasswordField)
  }
  const handleSubmit = async (isResend: boolean) => {
    appState.setExpiredSession(false)
    setResend(isResend)
    let options = authParams(emailOrPhone)
    let isActivationSent = false
    const isEmail = validateFields(emailOrPhone, '').email
    const isPhone = validateFields('', emailOrPhone).phone

    if (showPasswordField) {
      setIsAuthenticating(true)
      try {
        await AppState.login(
          {
            email: emailOrPhone,
            password,
          },
          undefined
        )
        history.push(redirectPath)
        AppState.toggleFlash(false)
      } catch (e) {
        AppState.errorMessage('Invalid email/password combination')
        setIsAuthenticating(false)
        AppState.toggleFlash(true)
      }
    } else {
      !isResend && setIsAuthenticating(true)
      if (!emailOrPhone.length) {
        AppState.errorMessage('Email or phone number is required')
        setIsAuthenticating(false)
      } else {
        if (!isEmail && !isPhone) {
          AppState.errorMessage('Invalid email/phone')
          setIsAuthenticating(false)
        } else {
          if (isEmail) {
            try {
              await checkUserEmail(emailOrPhone)
            } catch (e) {
              if (e instanceof Error && e.message.includes('404')) {
                isActivationSent = true
              } else {
                errorSubmit()
              }
            }
          }

          if (isActivationSent) {
            setIsAuthenticating(false)
            AppState.successMessage(
              'Successfully sent! Please check your email'
            )
          } else if (options) {
            passwordlessAuth.passwordlessStart(
              {
                ...options,
                send: 'link',
              },
              (err, res) => {
                if (!err) {
                  setIsAuthenticating(false)
                  if (isResend) {
                    setResend(false)
                    AppState.successMessage('Resent verification successfully!')
                  } else {
                    setOtpSent(true)
                  }
                } else {
                  errorSubmit()
                }
              }
            )
          }
        }
      }
    }
  }
  const handleSubmitCode = async () => {
    setIsAuthenticating(true)
    let options = authParams(emailOrPhone)
    try {
      await AppState.login(undefined, {
        ...options,
        verificationCode: code,
      })
      history.push(redirectPath)
      AppState.toggleFlash(false)
      setIsAuthenticating(false)
    } catch (e) {
      setIsAuthenticating(false)
      console.log(e)
      AppState.errorMessage('Invalid verification code')
    }
  }

  if (!AppState.isLimitedAccess()) {
    useEffect(() => {
      history.push('/login')
      if (has(_location, 'pathname') && has(_location, 'search')) {
        setRedirectPath(`${_location.pathname}${_location.search}`)
      }
    }, [])
  }
  useEffect(() => {
    !isAuthenticating && AppState.toggleFlash(false)
  }, [isAuthenticating])

  return (
    <Fragment>
      {!otpSent ? (
        <form onSubmit={(e) => e.preventDefault()} className={classes.form}>
          <img src={lock} alt="Lock" className={classes.formTopIcon} />
          <h1 className={cx(theme === Theme.Light && classes.black)}>
            Log in to MVP Mailhouse
            <div className={classes.subHeader}>
              Use your email address or mobile number to verify your identity.
            </div>
          </h1>
          {AppState.isRestricted() && !showPasswordField && (
            <div className={classes.warning}>
              You are currently logged in with <strong>limited access.</strong>
              <br />
              Verify your identity to view this page
            </div>
          )}
          <EmailOrPhoneField
            defaultValue={
              location.pathname === '/login' ? '' : AppState.profileEmail || ''
            }
            theme={theme}
            passwordFieldVisible={showPasswordField}
            onChange={(emailOrPhone: string) => {
              setEmailOrPhone(emailOrPhone)
            }}
          />
          <PasswordField
            theme={theme}
            passwordFieldVisible={showPasswordField}
            onChange={(password: string) => {
              setPassword(password)
            }}
          />
          <Button
            color="primary"
            onClick={() => handleSubmit(false)}
            disabled={isAuthenticating}
            type="submit"
            classes={{
              root: classes.submit,
              disabled: classes.btnDisabled,
            }}
          >
            {showPasswordField ? verb : 'Send Me Verification Link'}
            {isAuthenticating && (
              <CircularProgress className={classes.loader} />
            )}
          </Button>
          {!settingsStore.activated && AppState.isAuthenticated ? (
            <a
              onClick={() => handleShowLinkForm && handleShowLinkForm(true)}
              className={classes.alternate}
            >
              {verb} using <strong>setup link</strong>
            </a>
          ) : (
            <a onClick={handleTogglePassword} className={classes.alternate}>
              {showPasswordField ? (
                <React.Fragment>
                  {verb} using <strong>magic link</strong> or{' '}
                  <strong>code</strong>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {verb} using <strong>password</strong>
                </React.Fragment>
              )}
            </a>
          )}
        </form>
      ) : (
        <form onSubmit={(e) => e.preventDefault()} className={classes.form}>
          <img src={check} alt="Check" className={classes.formTopIcon} />
          <h1
            className={cx(
              classes.overflow,
              theme === Theme.Light && classes.black,
              theme === Theme.Light && classes.heading
            )}
          >
            {isEmail(emailOrPhone) && 'Click the magic link in your email'}
            {isPhone(emailOrPhone) && 'We sent the verification code'}
          </h1>
          <p
            className={cx(classes.sub, theme === Theme.Light && classes.black)}
          >
            {isEmail(emailOrPhone) &&
              'Or copy the verification code from your email and enter it below.'}
            {isPhone(emailOrPhone) &&
              'Copy the verification code and enter it below.'}
          </p>
          <VerificationCodeField
            theme={theme}
            onChange={(code: string) => {
              setCode(code)
            }}
            emailOrPhone={emailOrPhone}
          />
          <Button
            color="primary"
            onClick={handleSubmitCode}
            disabled={isAuthenticating}
            type="submit"
            classes={{
              root: classes.submit,
              disabled: classes.btnDisabled,
            }}
          >
            Submit Code
            {isAuthenticating && (
              <CircularProgress className={classes.loader} />
            )}
          </Button>
          <div className={classes.footerLinks}>
            <a
              onClick={() => {
                setOtpSent(false)
                AppState.toggleFlash(false)
              }}
            >
              &#8592; Back
            </a>
            <a onClick={() => handleSubmit(true)}>
              {resend && <CircularProgress className={classes.loaderResend} />}
              Resend {validateFields(emailOrPhone, '').email ? 'Email' : 'Code'}
            </a>
          </div>
        </form>
      )}
    </Fragment>
  )
}

export default LoginForm
