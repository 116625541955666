import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useStatCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: '#F3F3F3',
    },
    card: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: '30%',
      backgroundColor: '#fff',
      borderRadius: '10px',
      border: '1px solid #d3d3d3',
      cursor: 'pointer',

      '&:hover $cardIcon ': {
        backgroundColor: '#323A46',
      },

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        flex: '100%',
      },
    },
    cardDetails: {
      padding: '16px',
      display: 'flex',
      borderRadius: '10px 10px 0 0',
      gap: '16px',
      flexGrow: 1,
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
    },
    cardIcon: {
      backgroundColor: '#4B5768',
      borderRadius: '4px',
      padding: '4px 20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      transition: 'all 0.2s cubic-bezier(.25,.8,.25,1)',

      [theme.breakpoints.up('sm')]: {
        padding: '10px 20px',
      },
    },
    cardCount: {
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },
    cardCountLabel: {
      fontSize: '12px',
      color: '#4B5768',

      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
    },
    cardCountValue: {
      fontSize: '24px',
      fontWeight: 'bold',
      lineHeight: '120%',

      [theme.breakpoints.up('sm')]: {
        fontSize: '32px',
      },
    },
    cardActions: {
      backgroundColor: '#fff',
      borderRadius: '10px',
      display: 'none',
      alignItems: 'center',

      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: '#F9FAFB',
        borderRadius: '0 0 10px 10px',
      },
    },
    cardActionView: {
      color: '#ED154E',
      fontWeight: 'bold',
      fontSize: '14px',
      padding: '16px',
    },
    skeleton: {
      display: 'flex',
    },

    // TOOLTIP
    metricTooltip: {
      position: 'absolute',
      top: '95%',
      backgroundColor: '#FFF',
      borderRadius: '5px',
      border: '1px solid #d3d3d3',
      boxShadow: '0 4px 20px rgb(0 0 0 / 12%), 0 6px 12px rgb(0 0 0 / 8%)',
      zIndex: 999,
      width: '100%',
      transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
    },
    tooltipTitle: {
      borderBottom: '1px solid #F3F3F3',
      padding: '8px 16px',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#4B5768',
    },
    tooltipBody: {
      padding: '.8rem 1rem',
      fontSize: '14px',
      paddingBottom: '1.1rem',
      lineHeight: '140%',
      fontWeight: 'normal',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    tooltipNote: {
      color: '#747373',
      padding: '.5rem 1rem',
      marginTop: '-.8rem',
      paddingBottom: '1.1rem',
      fontStyle: 'italic',
      fontSize: '.85rem',
    },
  })
)
