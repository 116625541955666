export const defaultFont = {
  fontFamily: '"Roboto", sans-serif',
  fontWeight: 400,
  lineHeight: '1em',
  fontStyle: 'normal',
}

export const secondaryFont = {
  fontFamily: 'Oswald, sans-serif',
  fontWeight: '300',
  lineHeight: '1em',
}
