/**
 * ******************************************
 * Originally designed to be used in an AppBar.
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React, { useCallback, useEffect, useState } from 'react'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import {
  FormControl,
  Select as MuiSelect,
  MenuItem,
  Divider,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

// Util
import moment from 'moment'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { format } from 'date-fns'

// My util
import { useStores } from 'config/store'
import { DateFilterType } from 'config/store/GlobalFilterStore'

// My components
import DateRangePickerDialog from 'containers/DateRangePickerDialog'
import { DateRange } from 'react-day-picker'

const DateFilterTypeSelect = () => {
  const classes = useStyles()

  const { globalFilterStore } = useStores()
  const { dateFilter } = globalFilterStore
  const { dateRange, dateFilterType } = dateFilter

  const [inputDateFilterType, setInputDateFilterType] = useState(dateFilterType)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [inputDeliveryWeek, setInputDeliveryWeek] = useState<{
    from: Date | undefined
    to: Date | undefined
  }>({
    from: undefined,
    to: undefined,
  })
  const [dateRangeInteracted, setDateRangeInteracted] = useState(false)

  useEffect(() => {
    // Set date range picker interacted to false,
    // when date range picker is closed.
    if (!showDatePicker) {
      setDateRangeInteracted(false)
    }
  }, [showDatePicker])

  function handleDateFilterTypeChange(
    event: React.ChangeEvent<{
      value: unknown
    }>
  ) {
    const dateFilterType = event.target.value as DateFilterType

    runInAction(() => {
      setInputDateFilterType(dateFilterType)

      if (['custom'].includes(dateFilterType) === false) {
        const { getFilter } =
          DateFilters[dateFilterType as DateFiltersExcludeCustom] ?? {}
        const range = getFilter
          ? getFilter()
          : { from: undefined, to: undefined }
        dateFilter.setDateRange(range)
      }

      // If date filter type is a "custom". Set the temporary input state
      // but don't update global store yet so we can do undo later.
      if (dateFilterType !== 'custom') {
        dateFilter.setDateFilterType(dateFilterType)
      }
    })
  }

  function handleOpenCustomDateRange() {
    setInputDeliveryWeek(dateRange)
    setShowDatePicker(true)
  }

  function handleCancel() {
    const { from } = inputDeliveryWeek

    // 1. If no day is selected (User only click once).
    // 2. User hasn't interacted with the picker.
    if (!from || !dateRangeInteracted) {
      setInputDateFilterType(dateFilterType)
    }

    setShowDatePicker(false)
  }

  function handleApply() {
    const { from, to } = inputDeliveryWeek ?? {}

    if (!from) {
      alert('Please specify date range or click cancel')
      return
    }

    runInAction(() => {
      dateFilter.setDateRange({ from, to: to ?? from })
      dateFilter.setDateFilterType(inputDateFilterType)
      setShowDatePicker(false)
    })
  }

  const handleOnSelect = useCallback(
    (day: DateRange | undefined) => {
      if (day) {
        setInputDeliveryWeek({ from: day.from, to: day.to })
        setDateRangeInteracted(true)
      }
    },
    [setInputDeliveryWeek, setDateRangeInteracted]
  )

  return (
    <>
      <FormControl variant="outlined" className={classes.root} size="small">
        <MuiSelect
          value={inputDateFilterType}
          onChange={handleDateFilterTypeChange}
          className={classes.select}
          IconComponent={ExpandMore}
          renderValue={
            dateFilterType === 'custom'
              ? (value) => {
                  const { from, to } = dateRange
                  if (from && to) {
                    const pattern = 'MMM d, yyyy'
                    return `${format(from, pattern)} - ${format(to, pattern)}`
                  }
                  return 'Custom Date Range'
                }
              : undefined
          }
        >
          {Object.entries(DateFilters).map((item) => {
            const [id, filterProp] = item
            return (
              <MenuItem key={`campaign-date-${id}`} value={id}>
                {filterProp.label}
              </MenuItem>
            )
          })}
          <Divider />
          <MenuItem
            key="campaign-date-custom"
            value="custom"
            onClick={handleOpenCustomDateRange}
          >
            Custom Date Range
          </MenuItem>
        </MuiSelect>
      </FormControl>
      <DateRangePickerDialog
        showDatePicker={showDatePicker}
        range={inputDeliveryWeek}
        handleOnSelect={handleOnSelect}
        handleApply={handleApply}
        handleCancel={handleCancel}
      />
    </>
  )
}

export default observer(DateFilterTypeSelect)

const useStyles = makeStyles((_) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  select: {
    fontSize: '.9rem',
    minWidth: '10rem',
  },
}))

type DateFiltersExcludeCustom = Exclude<DateFilterType, 'custom'>
interface DateFilter {
  label: string
  getFilter: () => { from: Date; to: Date }
}
export const DateFilters: {
  [key in DateFiltersExcludeCustom]: DateFilter
} = {
  all: {
    label: 'All Time',
    getFilter: () => ({
      from: moment('2018').startOf('year').utc(true).toDate(),
      to: moment().utc().endOf('day').toDate(),
    }),
  },
  day: {
    label: 'Day',
    getFilter: () => ({
      from: moment().utc().startOf('day').toDate(),
      to: moment().utc().endOf('day').toDate(),
    }),
  },
  week: {
    label: 'Week ',
    getFilter: () => ({
      from: moment().utc().startOf('week').toDate(),
      to: moment().utc().endOf('week').toDate(),
    }),
  },
  month: {
    label: 'Month',
    getFilter: () => ({
      from: moment().utc().startOf('month').toDate(),
      to: moment().utc().endOf('month').utc().toDate(),
    }),
  },
  year: {
    label: 'Year',
    getFilter: () => ({
      from: moment().utc().startOf('year').toDate(),
      to: moment().utc().endOf('year').utc().toDate(),
    }),
  },
}
