import React, { useCallback, useEffect } from 'react'
import { Observer } from 'mobx-react'

import FilterField, { FilterFieldProp } from './FilterField'
import { useCallTableFilterStore } from '../../store/CallTableFilterStore'

export default function OnHoldFilterField() {
  const key = 'onHold'
  const label = 'On hold calls'
  const store = useCallTableFilterStore()

  useEffect(() => {
    store.registerFilter(key, { label })
  }, [])

  const source: FilterFieldProp['source'] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  const onChange = useCallback((value: FilterFieldProp['value']) => {
    store.setFilterValue(key, value ?? null)
    store.runFilter()
  }, [])

  return (
    <Observer
      render={() => {
        const filter = store.filterRegistry.get(key)
        const isShown = filter?.active ?? false
        const value = filter?.value ?? null

        return (
          <FilterField
            multiple={false}
            label={label}
            value={value}
            source={source}
            shown={isShown}
            onChange={onChange}
          />
        )
      }}
    />
  )
}
