import React from 'react'
import { Typography } from '@material-ui/core'

import useStyles from './DownloadFilters.style'

type Prop = {
  filters: string[]
}

export default function DownloadFilters({ filters = [] }: Prop) {
  const styles = useStyles()
  return (
    <>
      <Typography className={styles.filterByTitle}>Filters applied</Typography>
      <div className={styles.filterByContainer}>
        <ul className={styles.filterByListContainer}>
          {filters.map((filter) => (
            <li key={filter}>{filter}</li>
          ))}
        </ul>
      </div>
    </>
  )
}
