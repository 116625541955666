import API, { DATA_API } from 'src/helpers/api'

export const updateMailingZoneColor = async ({id ,color}) => {
	await API.put({
    api: DATA_API,
    url: `/mailing-zones/${id}/color`,
    data: {
      color
    }
  })

  return true
}


export default updateMailingZoneColor
