import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import moment from 'moment'

import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import PrevMonthIcon from '@material-ui/icons/KeyboardArrowLeft'
import NextMonthIcon from '@material-ui/icons/KeyboardArrowRight'
import DatePickerIcon from '@material-ui/icons/DateRange'
import DatePickerClearIcon from '@material-ui/icons/Close'

import { DayPicker, DateUtils } from 'react-day-picker'

import { datePickerStyles } from './datePicker.styles'

const styles = (theme) => ({
  ...datePickerStyles,

  dpContainer: {
    width: '100%',
  },

  dpNavbarHeader: {
    display: 'flex',
  },
  dpNavbarTitle: {
    flex: '1 0 auto',
    color: '#fff',
    padding: '12px 15px 8px',
    backgroundColor: '#ED154E',
    fontSize: '14px',
    fontWeight: 600,
  },
  dpNavbarBody: {
    display: 'flex',
    alignItems: 'center',
  },
  dpNavbarButton: {
    flex: '0 0 auto',
    height: 36,
    width: 36,
    margin: 12,
    padding: 0,
  },
  dpNavbarCaption: {
    flex: '1 0 auto',
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  dpNavbarFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0.6rem 1rem',
    backgroundColor: '#F8F8F8',
  },
  pickerIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',
    opacity: 1,
    '&:hover': {
      opacity: 0.8,
    },
  },
  hidden: {
    display: 'none',
  },
  singleSelected: {
    '&:not($disabled):not($outside)': {
      color: '#fff',
    },
    '&:not($disabled):not($outside):before': {
      backgroundColor: 'rgba(237, 21, 78, 1)',
    },
  },
})

const Navbar = withStyles(styles)(
  ({ classes, onPreviousClick, month, onNextClick }) => {
    return (
      <div className={classes.dpNavbar}>
        <div className={classes.dpNavbarHeader}>
          <div className={classes.dpNavbarTitle}>Select Date</div>
        </div>
        <div className={classes.dpNavbarBody}>
          <React.Fragment>
            <IconButton
              className={classes.dpNavbarButton}
              onClick={() => onPreviousClick()}
              color="primary"
            >
              <PrevMonthIcon />
            </IconButton>
            <div className={classes.dpNavbarCaption}>
              {moment(month).format('MMM YYYY')}
            </div>
            <IconButton
              className={classes.dpNavbarButton}
              onClick={() => onNextClick()}
              color="primary"
            >
              <NextMonthIcon />
            </IconButton>
          </React.Fragment>
        </div>
      </div>
    )
  }
)

const propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

const defaultProps = {
  value: '',
  label: '',
}

@inject('configStore')
@withStyles(styles)
@observer
export default class MVPDatePicker extends Component {
  constructor(props) {
    super(props)
    this.dateTimeRef = React.createRef()
    this.state = {
      show: false,
      selectedDay: props.value,
    }
  }

  handleMainButtonClick = () => {
    this.setState({ show: true })
  }

  handleDayClick = (day, modifiers) => {
    const { classes } = this.props
    if (modifiers[classes.disabled]) {
      return
    }
    this.setState({
      selectedDay: modifiers.selected ? undefined : day,
    })
  }

  formatDate(value) {
    const {
      configStore: { dateFormat },
    } = this.props

    if (!value) {
      return ''
    }

    const momentValue = moment(value)
    return momentValue.isValid() ? momentValue.format(dateFormat) : ''
  }

  handleClearDatePicker = (event) => {
    const { onChange } = this.props
    this.setState({
      selectedDay: undefined,
    })
    onChange(undefined)
    event.stopPropagation()
  }

  handleClickCancel = (event) => {
    const { value } = this.props
    this.setState({
      selectedDay: value,
      show: false,
    })
    event.stopPropagation()
  }

  handleClickOk = () => {
    const { onChange } = this.props
    const { selectedDay } = this.state
    onChange(selectedDay)
    this.setState({ show: false })
  }

  render() {
    const { classes, id, label, inputProps, pickerProps } = this.props
    const { show, selectedDay } = this.state

    const modifiers = {
      [classes.singleSelected]: selectedDay,
    }

    return (
      <div className={classes.dpContainer} ref={this.dateTimeRef}>
        <React.Fragment>
          <TextField
            id={id}
            label={label}
            className={classes.textField}
            value={`${this.formatDate(selectedDay)}`}
            margin="none"
            fullWidth
            placeholder={this.props.placeholder}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!selectedDay ? (
                    <DatePickerIcon
                      color="primary"
                      className={classes.pickerIcon}
                    />
                  ) : (
                    <DatePickerClearIcon
                      color="primary"
                      onClick={this.handleClearDatePicker}
                      className={classes.pickerIcon}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            onClick={this.handleMainButtonClick}
            {...inputProps}
          />

          <Popover
            anchorEl={this.dateTimeRef.current}
            open={show}
            onClose={this.handleClickCancel}
            anchorPosition={{
              top: 5,
              left: 0,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <DayPicker
              mode="single"
              classNames={classes}
              selected={selectedDay}
              onSelect={this.handleDayClick}
              navbarElement={<Navbar />}
              {...pickerProps}
            />
            <div className={classes.dpNavbarFooter}>
              <Button onClick={this.handleClickCancel}>Cancel</Button>
              <Button color="primary" onClick={this.handleClickOk}>
                OK
              </Button>
            </div>
          </Popover>
        </React.Fragment>
      </div>
    )
  }
}

MVPDatePicker.propTypes = propTypes
MVPDatePicker.defaultProps = defaultProps
