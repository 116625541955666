import React, { useEffect } from 'react'

import env from 'env'
import { authClient } from '../../helpers/auth0'
import appState from 'src/config/store/AppState'
import settingsStore from 'src/config/store/SettingsStore'
import processAccessToken from 'src/services/grants/processAccessToken'

import CircularProgress from '@material-ui/core/CircularProgress'

const getRedirect = (location) => {
  const params = new URLSearchParams(location.search)
  params.delete('email')
  params.delete('token')
  return `${location.pathname}?${params.toString()}`
}

const LimitedAccessGrant = (props) => {
  const { history, location, queryParams } = props
  const { email, token } = queryParams
  const redirect = getRedirect(location)

  useEffect(() => {
    const authenticate = () => {
      authClient.oauthToken(
        {
          realm: env.auth0.realmLimited,
          audience: env.auth0.audience,
          scope: env.auth0.scopes,
          grantType: 'http://auth0.com/oauth/grant-type/password-realm',
          username: email,
          password: token,
        },
        async (err, authResult) => {
          try {
            const session = await processAccessToken(authResult.accessToken)
            settingsStore.cleanStore()
            await appState.setSession(session)
            await settingsStore.getUserSettings()
            history.push(redirect)
          } catch (e) {
            history.push('/login')
            appState.toggleFlash(true)
            appState.errorMessage('Unauthorized access.')
          }
        }
      )
    }
    if (appState.isAuthenticated) {
      if (email === appState.profileEmail) {
        history.push(redirect)
      } else {
        appState.clearSession()
        authenticate()
      }
    } else {
      authenticate()
    }
  }, [])

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <CircularProgress size={60} color="primary" />
      <span
        style={{
          marginTop: 16,
        }}
      >
        Authenticating ...
      </span>
    </div>
  )
}

export default LimitedAccessGrant
