import API, { DATA_API } from 'helpers/api'

export const validateDesignConcept = async (
  designId: string[]
): Promise<any[]> => {
  const params = {
    designId
  }

  const result = await API.get({
    api: DATA_API,
    url: '/designs/validate-concept',
    params,
  })

  return result
}
