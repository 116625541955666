import React from 'react'

import { observer } from 'mobx-react'
import cx from 'classnames'

import { useTheme } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'

import Mailing from 'models/Mailing'
import {
  useLargeStyles,
  useDefaultStyles,
} from './DeliveredMailingCardInfo.style'

const DeliveredMailingCardInfo: React.FC<{
  mailing: Mailing
  size?: 'large'
}> = ({ mailing, size }) => {
  const classes = size === 'large' ? useLargeStyles() : useDefaultStyles()
  const theme = useTheme()

  return (
    <div className={classes.root}>
      <Tooltip
        title="This is the total number of scheduled appointments, including missed calls where a voicemail was left with contact information and details for scheduling an appointment."
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <div className={classes.statContainer}>
          <span className={cx(classes.statNumber, classes.appointments)}>
            {mailing.appointmentsCount}
          </span>
          <div className={classes.statLabel}>Appointments</div>
        </div>
      </Tooltip>

      <Divider className={classes.divider} orientation="vertical" flexItem />

      <Tooltip
        title="This is the total number of calls we scored as valid appointment opportunities, including potential opportunities from missed calls, unanswered calls, and voicemails that require follow-up."
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <div className={classes.statContainer}>
          <span className={cx(classes.statNumber, classes.opportunities)}>
            {mailing.leadsCount}
          </span>
          <div className={classes.statLabel}>Opportunities</div>
        </div>
      </Tooltip>

      <Divider className={classes.divider} orientation="vertical" flexItem />

      <Tooltip
        title="This is the average rate at which the staff converts answered opportunity calls into scheduled appointments."
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <div
          className={cx(classes.statContainer, classes.conversionRateContainer)}
        >
          <span className={cx(classes.statNumber, classes.conversionRate)}>
            {(mailing.conversionRate ?? 0) > 100 ? 100 : mailing.conversionRate}
            %
          </span>
          <div className={classes.statLabel}>Conversion</div>
        </div>
      </Tooltip>
    </div>
  )
}

export default observer(DeliveredMailingCardInfo)
