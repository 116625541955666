// CORE
import React, { useState } from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import MUITable from '@material-ui/core/Table'
import Pagination from '@material-ui/lab/Pagination'
import {
  FormControl,
  MenuItem,
  Select,
  Theme,
  useMediaQuery,
} from '@material-ui/core'

// COMPONENTS
import { CallProColumns } from 'components/CallPro/Tables/CallsTableColumns'
import EmptyBarChart from 'components/CallPro/EmptyCharts/EmptyBarChart'

// STYLES
import { useStylesDefault, useStylesCondensed } from './Table.styles'

interface CustomTableInterface {
  columns: CallProColumns[]
  rows: any[]
  defaultRowPerPage: number
  isLoading?: boolean
  orderBy: string | null
  dir: any
  totalRowsCount?: number
  currentPage: number
  hidePagination?: boolean
  variant?: 'condensed'
  onColumnSort?: (column: string) => void
  onChangePageSize?: (pageSize: number) => void
  onChangePage?: (page: number) => void
  handleSelectCall?: (id: string) => void
  onRowMouseEnter?: (index: number) => void
  onRowMouseLeave?: (index: number) => void
}

const CustomTable = (props: CustomTableInterface) => {
  const {
    columns,
    rows,
    defaultRowPerPage,
    isLoading,
    orderBy,
    dir,
    totalRowsCount,
    currentPage,
    hidePagination,
    variant,
    onColumnSort,
    onChangePageSize,
    onChangePage,
    handleSelectCall,
    onRowMouseEnter,
    onRowMouseLeave,
  } = props

  const classes =
    variant === 'condensed' ? useStylesCondensed() : useStylesDefault()

  const createSortHandler = (col: CallProColumns) => {
    if (col.sortable) {
      onColumnSort?.(col.id)
    }
  }

  const openCallDetails = (id: string) => {
    handleSelectCall?.(id)
  }

  const renderEmptyState = () => {
    return (
      <TableRow>
        <TableCell colSpan={9}>
          <EmptyBarChart classes={{ root: classes.emptyBarChart }} />
        </TableCell>
      </TableRow>
    )
  }

  const renderBody = () => {
    return (
      <>
        {rows.map((row, index) => (
          <TableRow
            className={classes.row}
            key={index}
            onClick={() => openCallDetails(row.id)}
            onMouseEnter={() => onRowMouseEnter && onRowMouseEnter(index)}
            onMouseLeave={() => onRowMouseLeave && onRowMouseLeave(index)}
          >
            {row.rowJsx.map((value: any, cellIndex: number) => (
              <TableCell
                key={`body-cell-${cellIndex}`}
                className={classes.bodyCell}
              >
                {value}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    )
  }

  return (
    <div>
      <TableContainer
        className={classes.root}
        style={{
          minHeight: rows.length > 9 || isLoading ? '49rem' : 'auto',
          overflowX: variant === 'condensed' ? 'hidden' : 'auto',
        }}
      >
        <MUITable>
          <TableHead classes={{ root: classes.tableHead }}>
            {totalRowsCount || isLoading ? (
              <TableRow>
                {columns.map((col, index) => (
                  <TableCell
                    key={`head-cell-${index}`}
                    sortDirection="desc"
                    className={classes.headerCell}
                    style={col?.styles ?? {}}
                  >
                    {col.sortable ? (
                      <TableSortLabel
                        active={!!(orderBy === col.id && dir)}
                        direction={dir || undefined}
                        onClick={() => createSortHandler(col)}
                      >
                        {col.name}
                      </TableSortLabel>
                    ) : (
                      <>{col.name}</>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ) : null}
          </TableHead>
          <TableBody>
            {rows.length || isLoading ? renderBody() : renderEmptyState()}
          </TableBody>
        </MUITable>
      </TableContainer>
      {!hidePagination ? (
        <CustomPagination
          totalRowsCount={totalRowsCount ?? 0}
          currentPage={currentPage}
          defaultRowPerPage={defaultRowPerPage}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
          variant={variant}
        />
      ) : null}
    </div>
  )
}

interface CustomPaginationProps {
  totalRowsCount: number
  currentPage: number
  defaultRowPerPage: number
  variant?: 'condensed'
  onChangePage?: (page: number) => void
  onChangePageSize?: (pageSize: number) => void
}

const CustomPagination = (props: CustomPaginationProps) => {
  const {
    totalRowsCount,
    currentPage,
    defaultRowPerPage,
    variant,
    onChangePage,
    onChangePageSize,
  } = props
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowPerPage)
  const classes =
    variant === 'condensed' ? useStylesCondensed() : useStylesDefault()
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  if (!totalRowsCount || totalRowsCount <= defaultRowPerPage) {
    return <></>
  }

  const handleChangePage = (event: any, page: number) => {
    onChangePage?.(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    const pageSize = Number(event.target.value)
    setRowsPerPage(pageSize)
    onChangePageSize?.(pageSize)
  }

  return (
    <div
      style={{
        display: 'flex',
        padding: '1rem',
        justifyContent: 'flex-end',
        flexDirection: mobileDevice ? 'column' : 'row',
      }}
    >
      <div style={{ marginRight: '0.5rem' }}>
        <Pagination
          count={Math.ceil((totalRowsCount ?? 0) / rowsPerPage)}
          variant="outlined"
          shape="rounded"
          color="primary"
          page={currentPage}
          onChange={handleChangePage}
          classes={{ ul: classes.paginationItems }}
        />
      </div>
      <div>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rowsPerPage}
            label="Page Size"
            onChange={handleChangeRowsPerPage}
            classes={{ root: classes.pagination_select }}
          >
            <MenuItem value={10}>10 / page</MenuItem>
            <MenuItem value={15}>15 / page</MenuItem>
            <MenuItem value={30}>30 / page</MenuItem>
            <MenuItem value={50}>50 / page</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

export default CustomTable
