import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  root: {
    backgroundColor: '#eee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: 146,
    objectFit: 'contain',
    cursor: 'pointer',

    '& img': {
      minHeight: 155,
      position: 'relative',
      zIndex: 100,
      maxHeight: 155,
      overflow: 'hidden',
    },
  },
  mvpImagePlaceholder: {
    objectFit: 'contain',
    filter: 'grayscale(100%) contrast(0) opacity(0.1)',
  },
  loader: {
    position: 'absolute',
    zIndex: 90,
  },
  status: {
    position: 'absolute',
    zIndex: 999,
    marginTop: 22,
    color: '#cacaca',
  },
})
