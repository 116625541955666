import { makeStyles } from '@material-ui/core/styles'

export const useStylesDefault = makeStyles(() => ({
  root: {
    '& .MuiTablePagination-select': {
      height: 36,
    },
  },

  row: {
    borderTop: '1px solid #ececec',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#FDE7ED',
    },
  },

  tableHead: {
    backgroundColor: '#f8f8f8',
  },

  headerCell: {
    fontWeight: 'bold',
    color: '#444',
    lineHeight: '40px',
    fontSize: '.9rem',
    padding: '4px 1rem',
    borderTop: '1px solid #efefef',
    borderBottom: '1px solid #efefef',
    textTransform: 'capitalize',
  },

  bodyCell: {
    padding: '0px 16px !important',
    height: '4.2rem',
    fontSize: '.85rem',
  },

  // EMPTY STATE
  emptyBarChart: {
    margin: '100px 0',
  },

  // PAGINATION
  pagination_select: {
    padding: '.4rem .7rem',
    borderRadius: '4px',
    backgroundColor: '#fff',
  },

  paginationItems: {
    '& li': {
      marginBottom: 10,
    },
  },
}))

export const useStylesCondensed = makeStyles(() => ({
  root: {
    '& .MuiTablePagination-select': {
      height: 36,
    },
  },

  row: {
    borderTop: '1px solid #ececec',
    cursor: 'pointer',

    backgroundColor: 'transparent !important',

    '&:hover': {
      backgroundColor: '#FDE7ED !important',
    },
  },

  tableHead: {
    backgroundColor: '#f8f8f8',
    '& tr': {
      backgroundColor: '#fff !important',
    },
  },

  headerCell: {
    fontWeight: 'bold',
    color: '#444',
    lineHeight: '40px',
    fontSize: '.9rem',
    padding: '2px 1rem',
    borderTop: '1px solid #efefef',
    borderBottom: '1px solid #efefef',
    textTransform: 'capitalize',
  },

  bodyCell: {
    padding: '0px 16px !important',
    height: '1.2rem',
    fontSize: '.85rem',
  },

  // EMPTY STATE
  emptyBarChart: {
    margin: '100px 0',
  },

  // PAGINATION
  pagination_select: {
    padding: '.4rem .7rem',
    borderRadius: '4px',
    backgroundColor: '#fff',
  },

  paginationItems: {
    '& li': {
      marginBottom: 10,
    },
  },
}))
