// LIB
import { observable, action, computed, runInAction, values } from 'mobx'
import _isNil from 'lodash/isNil'

// STORE
import appState from 'config/store/AppState'

// MODELS
import Call, { repository as callsRepo } from 'models/Call'

// CONFIGS
import {
  CallConfig,
  CallerGender,
  FollowUpReason,
  NoApptScheduledReason,
  NonOpptyReason,
  PaymentTypes,
  AnsweredBy,
  IVROutcome,
  VoicemailOutcome,
  CallerLanguage,
  PatientTypes,
  TopCampaignSource,
  InaccurateReferralSource,
} from '../../../callpro.config'

// SERVICES
import getCallSummaryCounts from 'services/calls/getCallSummaryCounts'
import getCalls from 'services/calls/getCalls'
import getTotalCallsPerHour from 'services/calls/getTotalCallsPerHour'
import getCallerGender from 'services/calls/getCallerGender'
import getPaymentTypes from 'services/calls/getPaymentTypes'
import getFollowUpReason from 'services/calls/getFollowUpReason'
import getNoApptScheduledReason from 'services/calls/getNoApptScheduledReason'
import getNonOpptyReason from 'services/calls/getNonOpptyReason'
import getAnsweredBy from 'services/calls/getAnsweredBy'
import getIVROutcome from 'services/calls/getIVROutcome'
import getVoicemailOutcome from 'services/calls/getVoicemailOutcome'
import getCallerLanguage from 'services/calls/getCallerLanguage'
import getPatientTypes from 'services/calls/getPatientTypes'
import getTopCampaignSource from 'services/calls/getTopCampaignSource'
import getReferralSource, {
  ReferralSourceInterface,
} from 'services/calls/getReferralSource'

// INTERFACES
import {
  CallFilters,
  CallOutcome,
  CallPaginationInterface,
  CallSortDirection,
  CallsPerHourInterface,
  CallSummaryCountInterface,
  CallDashboardTabs,
  AggregatedPicklistInterface,
} from 'interfaces/Call.interface'

import dashboardUIStore from '../DashboardUIStore'

interface CallListPaginated {
  [key: number]: string[]
  totalCount: number
}

export class CallsStore {
  // --- CALL LIST CONTROLS --- //

  @observable filters = CallConfig.filters.call
  @observable pagination: CallPaginationInterface =
    CallConfig.initialValues.pagination

  // --- PAGINATED CALLS --- //

  @observable private opportunityIdsPaginated: CallListPaginated = {
    totalCount: 0,
  }
  @observable private appointmentIdsPaginated: CallListPaginated = {
    totalCount: 0,
  }
  @observable private missedIdsPaginated: CallListPaginated = { totalCount: 0 }
  @observable private followUpIdsPaginated: CallListPaginated = {
    totalCount: 0,
  }
  @observable private missedAndFollowupIdsPaginated: CallListPaginated = {
    totalCount: 0,
  }
  @observable private nonOpportunityIdsPaginated: CallListPaginated = {
    totalCount: 0,
  }
  @observable private allIdsPaginated: CallListPaginated = { totalCount: 0 }

  // --- ALL STATS --- //

  @observable callSummaryCount: CallSummaryCountInterface =
    CallConfig.initialValues.summaryCount

  // --- CALLS PER HOUR --- //

  @observable callsPerHour: CallsPerHourInterface[] = []
  @observable callsPerHourStart: string = ''

  @observable callerGender = {
    records: CallerGender,
    firstRecordDate: '',
  }
  @observable paymentTypes = {
    records: PaymentTypes,
    firstRecordDate: '',
  }

  @observable followUpReason = {
    records: FollowUpReason,
    firstRecordDate: '',
  }
  @observable noApptScheduledReason = {
    records: NoApptScheduledReason,
    firstRecordDate: '',
  }
  @observable nonOpportunityReason = {
    records: NonOpptyReason,
    firstRecordDate: '',
  }
  @observable answeredBy = {
    records: AnsweredBy,
    firstRecordDate: '',
  }

  @observable ivrOutcome = {
    records: IVROutcome,
    firstRecordDate: '',
  }

  @observable voicemailOutcome = {
    records: VoicemailOutcome,
    firstRecordDate: '',
  }

  @observable callerLanguage = {
    records: CallerLanguage,
    firstRecordDate: '',
  }

  @observable patientTypes = {
    records: PatientTypes,
    firstRecordDate: '',
  }

  @observable topCampaignSource = {
    records: TopCampaignSource,
    firstRecordDate: '',
  }

  @observable referralSource = {
    records: InaccurateReferralSource,
    firstRecordDate: '',
  }

  // --- LOADING STATES--- //

  @observable loadingStates = {
    callsSummaryCount: false,
    callsPerHour: false,
    missedAndFollowupCall: false,
    opportunityCall: false,
    appointmentCall: false,
    missedCall: false,
    followUpCall: false,
    nonOpportunityCall: false,
    allCall: false,
    callerGender: false,
    paymentTypes: false,
    followUpReason: false,
    noApptScheduledReason: false,
    nonOpportunityReason: false,
    answeredBy: false,
    ivrOutcome: false,
    voicemailOutcome: false,
    callerLanguage: false,
    patientTypes: false,
    topCampaignSource: false,
    referralSource: false,
  }

  @computed
  get isLoading() {
    const loading = values(this.loadingStates)
    return loading.some(Boolean)
  }

  @computed
  get isAllCallSubTabsLoading() {
    const loading = this.loadingStates
    const bools = [
      loading.opportunityCall,
      loading.appointmentCall,
      loading.missedCall,
      loading.followUpCall,
      loading.nonOpportunityCall,
      loading.allCall,
    ]
    return bools.some(Boolean)
  }

  // --- ACTION w/ SIDE EFFECTS - Refresher --- //

  /**
   * @param {boolean} param.reset - When you want to reset state + do a refresh
   */
  @action
  async refreshOverviewTab({ reset = false }: { reset?: boolean } = {}) {
    if (reset) {
      this.resetByTab(CallDashboardTabs.OVERVIEW)
    }

    await Promise.all([
      this.fetchCallSummaryCount(),
      this.fetchCalls(),
      this.fetchTotalCallsPerHour(),
      this.fetchCallerGender(),
      this.fetchPaymentTypes(),
      this.fetchFollowUpReason(),
      this.fetchNoApptScheduledReason(),
      this.fetchNonOpptyReason(),
      this.fetchAnsweredBy(),
      this.fetchIVROutcome(),
      this.fetchVoicemailOutcome(),
      this.fetchCallerLanguage(),
      this.fetchPatientTypes(),
      this.fetchTopCampaignSource(),
      this.fetchInaccurateReferralSource(),
    ])
  }

  /**
   * @param {boolean} param.reset - When you want to reset state + do a refresh
   */
  @action
  async refreshAllCallsTab({ reset = false }: { reset?: boolean } = {}) {
    if (reset) {
      this.resetByTab(CallDashboardTabs.ALL_CALLS)
    }
    await Promise.all([this.fetchCallSummaryCount(), this.fetchCalls()])
  }

  /**
   * This is useful when you only want to refresh (api refetching) the call
   * lists. We can also make refresh function as public utilities for components
   * or external functions. Then fetchCalls() function can stay private function
   * only used in this store.
   */
  @action
  async refreshCalls() {
    await this.fetchCalls()
  }

  // --- ACTION w/ SIDE EFFECTS --- //

  @action
  private async fetchCalls() {
    if (appState.isRestricted()) {
      return
    }

    const globalFilters = dashboardUIStore.globalFiltersQuery
    const dashboardTab = dashboardUIStore.currentTabOpened.value
    const outcome = this.filters.outcome
    const page = this.pagination.page

    // Update Loading
    this.setLoadingByOutcome(true, { outcome, dashboardTab })

    // Fetch Calls
    const data = await getCalls({
      ...this.pagination,
      filters: { ...this.fetchCallsFilters, ...globalFilters },
    })
    const { meta, records } = data
    const totalCount = meta.totalCount

    // Update Store
    runInAction(() => {
      this.setLoadingByOutcome(false, { outcome, dashboardTab })

      switch (outcome) {
        case CallOutcome.APPOINTMENT:
          this.appointmentIdsPaginated[page] = records
          this.appointmentIdsPaginated.totalCount = totalCount
          break

        case CallOutcome.OPPORTUNITY:
          this.opportunityIdsPaginated[page] = records
          this.opportunityIdsPaginated.totalCount = totalCount
          break

        case CallOutcome.MISSED_CALL:
          this.missedIdsPaginated[page] = records
          this.missedIdsPaginated.totalCount = totalCount
          break

        case CallOutcome.FOLLOWUP:
          this.followUpIdsPaginated[page] = records
          this.followUpIdsPaginated.totalCount = totalCount
          break

        case CallOutcome.NON_OPPORTUNITY:
          this.nonOpportunityIdsPaginated[page] = records
          this.nonOpportunityIdsPaginated.totalCount = totalCount
          break

        case CallOutcome.MISSED_AND_FOLLOWUP:
          this.missedAndFollowupIdsPaginated[page] = records
          this.missedAndFollowupIdsPaginated.totalCount = totalCount
          break

        default:
          this.allIdsPaginated[page] = records
          this.allIdsPaginated.totalCount = totalCount
          break
      }
    })
  }

  @action
  private async fetchCallSummaryCount() {
    this.loadingStates.callsSummaryCount = true
    const data = await getCallSummaryCounts({
      filters: dashboardUIStore.globalFiltersQuery,
    })
    runInAction(() => {
      this.callSummaryCount = data
      this.loadingStates.callsSummaryCount = false
    })
  }

  @action
  private async fetchTotalCallsPerHour() {
    this.loadingStates.callsPerHour = true
    const data: {
      records: CallsPerHourInterface[]
      startRecordDate: string
    } = await getTotalCallsPerHour({
      filters: dashboardUIStore.globalFiltersQuery,
    })

    runInAction(() => {
      const { records, startRecordDate } = data
      this.callsPerHour = records
      this.callsPerHourStart = startRecordDate
      this.loadingStates.callsPerHour = false
    })
  }

  @action
  private async fetchCallerGender() {
    this.loadingStates.callerGender = true
    const data: {
      records: AggregatedPicklistInterface[]
      firstRecordDate: string
    } = await getCallerGender({
      filters: dashboardUIStore.globalFiltersQuery,
    })

    runInAction(() => {
      const { records, firstRecordDate } = data
      this.callerGender.records = records ?? []
      this.callerGender.firstRecordDate = firstRecordDate ?? ''
      this.loadingStates.callerGender = false
    })
  }

  @action
  private async fetchPaymentTypes() {
    this.loadingStates.paymentTypes = true
    const data: {
      records: AggregatedPicklistInterface[]
      firstRecordDate: string
    } = await getPaymentTypes({
      filters: dashboardUIStore.globalFiltersQuery,
    })

    runInAction(() => {
      const { records, firstRecordDate } = data
      this.paymentTypes.records = records ?? []
      this.paymentTypes.firstRecordDate = firstRecordDate ?? ''
      this.loadingStates.paymentTypes = false
    })
  }

  @action
  private async fetchFollowUpReason() {
    this.loadingStates.followUpReason = true
    const data: {
      records: AggregatedPicklistInterface[]
      firstRecordDate: string
    } = await getFollowUpReason({
      filters: dashboardUIStore.globalFiltersQuery,
    })

    runInAction(() => {
      const { records, firstRecordDate } = data
      this.followUpReason.records = records ?? []
      this.followUpReason.firstRecordDate = firstRecordDate ?? ''
      this.loadingStates.followUpReason = false
    })
  }

  @action
  private async fetchNoApptScheduledReason() {
    this.loadingStates.noApptScheduledReason = true
    const data: {
      records: AggregatedPicklistInterface[]
      firstRecordDate: string
    } = await getNoApptScheduledReason({
      filters: dashboardUIStore.globalFiltersQuery,
    })

    runInAction(() => {
      const { records, firstRecordDate } = data
      this.noApptScheduledReason.records = records ?? []
      this.noApptScheduledReason.firstRecordDate = firstRecordDate ?? ''
      this.loadingStates.noApptScheduledReason = false
    })
  }

  @action
  private async fetchNonOpptyReason() {
    this.loadingStates.nonOpportunityReason = true
    const data: {
      records: AggregatedPicklistInterface[]
      firstRecordDate: string
    } = await getNonOpptyReason({
      filters: dashboardUIStore.globalFiltersQuery,
    })

    runInAction(() => {
      const { records, firstRecordDate } = data
      this.nonOpportunityReason.records = records ?? []
      this.nonOpportunityReason.firstRecordDate = firstRecordDate ?? ''
      this.loadingStates.nonOpportunityReason = false
    })
  }

  @action
  private async fetchAnsweredBy() {
    this.loadingStates.answeredBy = true
    const data: {
      records: AggregatedPicklistInterface[]
      firstRecordDate: string
    } = await getAnsweredBy({
      filters: dashboardUIStore.globalFiltersQuery,
    })

    runInAction(() => {
      const { records, firstRecordDate } = data
      this.answeredBy.records = records ?? []
      this.answeredBy.firstRecordDate = firstRecordDate ?? ''
      this.loadingStates.answeredBy = false
    })
  }

  @action
  private async fetchIVROutcome() {
    this.loadingStates.ivrOutcome = true
    const data: {
      records: AggregatedPicklistInterface[]
      firstRecordDate: string
    } = await getIVROutcome({
      filters: dashboardUIStore.globalFiltersQuery,
    })

    runInAction(() => {
      const { records, firstRecordDate } = data
      this.ivrOutcome.records = records ?? []
      this.ivrOutcome.firstRecordDate = firstRecordDate ?? ''
      this.loadingStates.ivrOutcome = false
    })
  }

  @action
  private async fetchVoicemailOutcome() {
    this.loadingStates.voicemailOutcome = true
    const data: {
      records: AggregatedPicklistInterface[]
      firstRecordDate: string
    } = await getVoicemailOutcome({
      filters: dashboardUIStore.globalFiltersQuery,
    })

    runInAction(() => {
      const { records, firstRecordDate } = data
      this.voicemailOutcome.records = records ?? []
      this.voicemailOutcome.firstRecordDate = firstRecordDate ?? ''
      this.loadingStates.voicemailOutcome = false
    })
  }

  @action
  private async fetchCallerLanguage() {
    this.loadingStates.callerLanguage = true
    const data: {
      records: AggregatedPicklistInterface[]
      firstRecordDate: string
    } = await getCallerLanguage({
      filters: dashboardUIStore.globalFiltersQuery,
    })

    runInAction(() => {
      const { records, firstRecordDate } = data
      this.callerLanguage.records = records ?? []
      this.callerLanguage.firstRecordDate = firstRecordDate ?? ''
      this.loadingStates.callerLanguage = false
    })
  }

  @action
  private async fetchTopCampaignSource() {
    this.loadingStates.topCampaignSource = true
    const data = await getTopCampaignSource({
      filters: dashboardUIStore.globalFiltersQuery,
    })

    runInAction(() => {
      const { records, firstRecordDate } = data
      this.topCampaignSource.records = records ?? []
      this.topCampaignSource.firstRecordDate = firstRecordDate ?? ''
      this.loadingStates.topCampaignSource = false
    })
  }

  @action
  private async fetchInaccurateReferralSource() {
    this.loadingStates.referralSource = true
    const data = await getReferralSource({
      filters: dashboardUIStore.globalFiltersQuery,
    })

    runInAction(() => {
      const { records, firstRecordDate } = data
      this.referralSource.records = records ?? []
      this.referralSource.firstRecordDate = firstRecordDate ?? ''
      this.loadingStates.referralSource = false
    })
  }

  @action
  private async fetchPatientTypes() {
    this.loadingStates.patientTypes = true
    const data: {
      records: AggregatedPicklistInterface[]
      firstRecordDate: string
    } = await getPatientTypes({
      filters: dashboardUIStore.globalFiltersQuery,
    })

    runInAction(() => {
      const { records, firstRecordDate } = data
      this.patientTypes.records = records ?? []
      this.patientTypes.firstRecordDate = firstRecordDate ?? ''
      this.loadingStates.patientTypes = false
    })
  }

  // --- ACTION w/o SIDE EFFECTS --- //

  @action
  resetByTab(tab: CallDashboardTabs) {
    const outcome =
      tab === CallDashboardTabs.OVERVIEW
        ? CallOutcome.MISSED_AND_FOLLOWUP
        : CallOutcome.OPPORTUNITY

    this.resetFilters({ outcome })
    this.resetPagination()
    this.resetPaginatedCalls()
    this.resetAllStats()
    this.resetCallsPerHour()
    this.resetLoadingStates()
  }

  @action
  resetAllCallsSubTab() {
    this.resetFilters()
    this.resetPagination()
  }

  @action
  private resetPagination() {
    this.pagination = CallConfig.initialValues.pagination
  }

  @action
  private resetFilters(args?: { outcome: CallOutcome }) {
    const outcome = args?.outcome ?? CallOutcome.OPPORTUNITY
    const answeredBy = undefined
    const callHandlerId = null

    this.filters = { outcome, answeredBy, callHandlerId }
  }

  @action
  private resetPaginatedCalls() {
    this.opportunityIdsPaginated = { totalCount: 0 }
    this.appointmentIdsPaginated = { totalCount: 0 }
    this.missedIdsPaginated = { totalCount: 0 }
    this.followUpIdsPaginated = { totalCount: 0 }
    this.nonOpportunityIdsPaginated = { totalCount: 0 }
    this.allIdsPaginated = { totalCount: 0 }
  }

  @action
  private resetAllStats() {
    this.callSummaryCount = CallConfig.initialValues.summaryCount
  }

  @action
  private resetCallsPerHour() {
    this.callsPerHour = []
    this.callsPerHourStart = ''
  }

  @action
  private resetLoadingStates() {
    this.loadingStates = {
      callsSummaryCount: false,
      callsPerHour: false,
      missedAndFollowupCall: false,
      opportunityCall: false,
      appointmentCall: false,
      missedCall: false,
      followUpCall: false,
      nonOpportunityCall: false,
      allCall: false,
      callerGender: false,
      paymentTypes: false,
      followUpReason: false,
      noApptScheduledReason: false,
      nonOpportunityReason: false,
      answeredBy: false,
      ivrOutcome: false,
      voicemailOutcome: false,
      callerLanguage: false,
      patientTypes: false,
      topCampaignSource: false,
      referralSource: false,
    }
  }

  @action
  private setLoadingByOutcome(
    value: boolean,
    criteria: {
      outcome: CallOutcome
      dashboardTab: CallDashboardTabs
    }
  ) {
    const { outcome } = criteria
    switch (outcome) {
      case CallOutcome.APPOINTMENT:
        this.loadingStates.appointmentCall = value
        break
      case CallOutcome.OPPORTUNITY:
        this.loadingStates.opportunityCall = value
        break
      case CallOutcome.MISSED_CALL:
        this.loadingStates.missedCall = value
        break
      case CallOutcome.MISSED_AND_FOLLOWUP:
        this.loadingStates.missedAndFollowupCall = value
        break
      case CallOutcome.FOLLOWUP:
        this.loadingStates.followUpCall = value
        break
      case CallOutcome.NON_OPPORTUNITY:
        this.loadingStates.nonOpportunityCall = value
        break
      default:
        this.loadingStates.allCall = value
    }
  }

  @action.bound
  setFilters<T extends CallFilters & {}>(filters: T): void {
    this.filters = filters
  }

  @action.bound
  appendFilters(
    filters:
      | CallFilters
      | {
          [key: string]:
            | undefined
            | null
            | string
            | number
            | boolean
            | Array<undefined | null | string | number | boolean>
        }
  ): void {
    this.filters = { ...this.filters, ...filters }
  }

  @action.bound
  setPaginationPage(page: number) {
    this.pagination.page = page
  }

  @action.bound
  setPaginationSize(size: number) {
    this.pagination.pageSize = size
    this.pagination.page = CallConfig.initialValues.pagination.page
    this.resetPaginatedCalls()
  }

  @action.bound
  toggleSorting(key: string): void {
    this.pagination.sort = key
    this.pagination.dir =
      this.pagination.dir === CallSortDirection.DESC
        ? CallSortDirection.ASC
        : CallSortDirection.DESC
    this.pagination.page = 1
  }

  @action.bound
  setSearchQuery(searchQuery: string = ''): void {
    this.pagination.searchQuery = searchQuery
  }

  // --- COMPUTED --- //

  @computed
  get fetchCallsFilters() {
    const keys = Object.keys(this.filters) as Array<keyof CallFilters>
    const hasValueKeys = keys.filter((key) => {
      const value = this.filters[key]
      if (_isNil(value)) {
        return false
      }
      if (Array.isArray(value)) {
        return value.length
      }
      return true
    })
    const filters: {
      opportunity?: boolean
    } & Partial<CallFilters> = {}

    hasValueKeys.forEach((key) => (filters[key] = this.filters[key] as any))

    const { outcome, ...rest } = filters

    switch (outcome) {
      case CallOutcome.ALL_CALLS:
        return rest
      case CallOutcome.NON_OPPORTUNITY:
        rest.opportunity = false
        rest.hasAudio = true
        return rest
      case CallOutcome.OPPORTUNITY:
        rest.opportunity = true
        return rest
    }

    filters.opportunity = true

    return filters
  }

  @computed
  get opportunityCallsPerPage(): Call[] {
    return this.opportunityIdsPaginated[this.pagination.page]
      ? this.opportunityIdsPaginated[this.pagination.page].map((id) =>
          callsRepo.get(id)
        )
      : this.opportunityIdsPaginated[this.pagination.page - 1]
      ? this.opportunityIdsPaginated[this.pagination.page - 1].map((id) =>
          callsRepo.get(id)
        )
      : []
  }

  @computed
  get opportunityCallsTotalCount(): number {
    return this.opportunityIdsPaginated.totalCount
  }

  @computed
  get appointmentCallsTotalCount(): number {
    return this.appointmentIdsPaginated.totalCount
  }

  @computed
  get appointmentsPerPage(): Call[] {
    return this.appointmentIdsPaginated[this.pagination.page]
      ? this.appointmentIdsPaginated[this.pagination.page].map((id) =>
          callsRepo.get(id)
        )
      : this.appointmentIdsPaginated[this.pagination.page - 1]
      ? this.appointmentIdsPaginated[this.pagination.page - 1].map((id) =>
          callsRepo.get(id)
        )
      : []
  }

  @computed
  get appointmentsTotalCount(): number {
    return this.appointmentIdsPaginated.totalCount
  }

  @computed
  get missedCallsPerPage(): Call[] {
    return this.missedIdsPaginated[this.pagination.page]
      ? this.missedIdsPaginated[this.pagination.page].map((id) =>
          callsRepo.get(id)
        )
      : this.missedIdsPaginated[this.pagination.page - 1]
      ? this.missedIdsPaginated[this.pagination.page - 1].map((id) =>
          callsRepo.get(id)
        )
      : []
  }

  @computed
  get missedCallTotalCount(): number {
    return this.missedIdsPaginated.totalCount
  }

  @computed
  get followUpCallsPerPage(): Call[] {
    return this.followUpIdsPaginated[this.pagination.page]
      ? this.followUpIdsPaginated[this.pagination.page].map((id) =>
          callsRepo.get(id)
        )
      : this.followUpIdsPaginated[this.pagination.page - 1]
      ? this.followUpIdsPaginated[this.pagination.page - 1].map((id) =>
          callsRepo.get(id)
        )
      : []
  }

  @computed
  get followUpTotalCount(): number {
    return this.followUpIdsPaginated.totalCount
  }

  @computed
  get nonOpportunityCallsPerPage(): Call[] {
    return this.nonOpportunityIdsPaginated[this.pagination.page]
      ? this.nonOpportunityIdsPaginated[this.pagination.page].map((id) =>
          callsRepo.get(id)
        )
      : this.nonOpportunityIdsPaginated[this.pagination.page - 1]
      ? this.nonOpportunityIdsPaginated[this.pagination.page - 1].map((id) =>
          callsRepo.get(id)
        )
      : []
  }

  @computed
  get nonOpportunityCallsTotalCount(): number {
    return this.nonOpportunityIdsPaginated.totalCount
  }

  @computed
  get missedAndFollowupCallsPerPage(): Call[] {
    return this.missedAndFollowupIdsPaginated[this.pagination.page]
      ? this.missedAndFollowupIdsPaginated[this.pagination.page].map((id) =>
          callsRepo.get(id)
        )
      : this.missedAndFollowupIdsPaginated[this.pagination.page - 1]
      ? this.missedAndFollowupIdsPaginated[this.pagination.page - 1].map((id) =>
          callsRepo.get(id)
        )
      : []
  }

  @computed
  get missedAndFollowupCallsTotalCount(): number {
    return this.missedAndFollowupIdsPaginated.totalCount
  }

  @computed
  get allCallsPerPage(): Call[] {
    return this.allIdsPaginated[this.pagination.page]
      ? this.allIdsPaginated[this.pagination.page].map((id) =>
          callsRepo.get(id)
        )
      : this.allIdsPaginated[this.pagination.page - 1]
      ? this.allIdsPaginated[this.pagination.page - 1].map((id) =>
          callsRepo.get(id)
        )
      : []
  }

  @computed
  get allCallsTotalCount(): number {
    return this.allIdsPaginated.totalCount
  }
}

export default new CallsStore()
