import React from 'react'
import { Observer } from 'mobx-react'
import { OfficeColors } from '../Icons/Markers/PracticeColorIconSet'

const colors = OfficeColors

export const PracticeColorPickker = (props) => {
  const { id, visible, onClose } = props
  return (
    <Observer
      render={() =>
          <div style={{
              visibility: visible ? 'visible' : 'hidden',
              position: 'absolute',
              display: 'flex',
              bottom: 16,
              right: 50,
              zIndex: 10000}}>
            {
              colors.map((color) => {
                return <span className="officeColor" key={color} data-id={id} data-color={color} style={{
                  cursor: 'pointer',
                  background: color,
                  height: 15,
                  width: 15,
                  marginRight: 3
                }}></span>
              })
            }
          </div>
      }
    />
  )
}

export default PracticeColorPickker
