import { makeStyles } from '@material-ui/core/styles'

// @todo technical debt update eslint
// eslint-disable-next-line no-undef
export type useStyleType = Partial<ReturnType<typeof useCardStyles>>

export const useCardStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #d3d3d3',
    padding: '.5rem',
    borderRadius: '10px',
    '@media screen and (max-width: 780px)': {
      border: 'none',
      padding: 0,
      width: '100%',
    },
  },

  header: {
    justifyContent: 'space-between',
    '& .MuiCardHeader-title': {
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    '& .MuiCardHeader-subheader': {
      fontSize: '0.8rem',
      fontWeight: 'normal',
      marginTop: 5,
      color: '#232323',
    },
  },

  headerAction: {
    marginTop: 'initial',
  },

  accordionContentHeader: {
    padding: 0,
  },

  accordionContent: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  content: {
    height: 'calc(100% - 66px)',
    display: 'flex',
    flexDirection: 'column',
  },
})
