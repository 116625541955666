import API, { DATA_API } from 'helpers/api'

interface acceptReviewCommentArgs {
  id: string
}

export const acceptReviewComment = async ({ id }: acceptReviewCommentArgs) => {
  await API.post({
    api: DATA_API,
    url: `/designs/review-comments/${id}/accept`,
  })
}
