/**
 * ******************************************
 * Originally designed to be used by containers/DateFilterTypeSelect.tsx
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React, { useState } from 'react'

// Mui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  useMediaQuery,
  Theme,
} from '@material-ui/core'

// Util
import { DateRange, DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'

// My util
import { useDatePickerStyles } from 'components/MVP/Pickers/datePicker.styles'
import classNames from 'classnames'

interface Prop {
  showDatePicker: boolean
  range: {
    from: Date | undefined
    to: Date | undefined
  }
  handleOnSelect: (day: DateRange | undefined) => void
  handleApply: () => void
  handleCancel: () => void
}

export default function DateRangePickerDialog(prop: Prop) {
  const {
    showDatePicker,
    range: { from, to },
    handleOnSelect,
    handleApply,
    handleCancel,
  } = prop
  const classes = useStyles()
  const datePickerClasses = useDatePickerStyles()
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  const defaultSelected: DateRange = {
    from,
    to,
  }
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected)

  return (
    <Dialog
      open={showDatePicker}
      onClose={handleCancel}
      classes={{
        paper: classNames(
          classes.dialogPaper,
          mobileDevice ? classes.dialogPaperMobile : null
        ),
      }}
    >
      <DialogTitle>
        <strong>Custom Date Range</strong>
      </DialogTitle>
      <DialogContent>
        <div className={classes.control}>
          {!mobileDevice ? (
            <div className={classes.dateIndicators}>
              <label>Start Date</label>
              <label>End Date</label>
            </div>
          ) : null}

          <DayPicker
            classNames={datePickerClasses}
            mode="range"
            numberOfMonths={2}
            selected={range}
            onSelect={(day) => {
              handleOnSelect(day)
              setRange(day)
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleCancel()
            setRange(undefined)
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button onClick={handleApply} color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialogPaper: { maxWidth: '42rem' },
  dialogPaperMobile: {
    maxWidth: '100%',
    width: '100%',
    margin: 0,
  },
  control: {
    '& div': {
      fontWeight: '400',
      fontFamily: 'Roboto',
    },
    '& div[role*="listbox"]': {
      position: 'relative',
      zIndex: '999',
    },
  },
  dateIndicators: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    background: '#ED154E',
    color: '#fff',

    fontSize: '14px',
    '& label': {
      width: '100%',
      padding: '5px 15px',
      border: 'dashed 1px #f5bccb',
    },
  },
})
