// @flow

import _isEmpty from 'lodash/isEmpty'
import API, { DATA_API } from 'src/helpers/api'
import ProductInquiry, {
  repository as productInquiryRepo,
} from 'src/models/Product/ProductInquiry'
import Product, { repository as productRepo } from 'src/models/Product/Product'

import accountStore from 'src/config/store/AccountStore'

/**
 *
 * @param {object} options
 */
export default async ({ filters }) => {
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    return {
      meta: {},
      records: [],
    }
  }

  const data = {
    accountIds,
    filters,
  }

  const result = await API.get({
    api: DATA_API,
    url: '/products/inquiries',
    params: data,
  })

  const sortedResult = result.sort((a, b) => {
    return a.Call__r.Call_Date__c - b.Call__r.Call_Date__c
  })

  const products = result
    .filter((p) => !_isEmpty(p.Product__c) && !_isEmpty(p.Product__r))
    .map((row) => {
      const productInquiry = new ProductInquiry({
        id: row.Id,
        name: row.Name,
        productId: row.Product__c,
        productName: row.Product__r.Display_Name__c,
        callId: row.Call__c,
      })
      const product = new Product({
        id: productInquiry.productId,
        displayName: productInquiry.productName,
      })

      productRepo.upsert(product)
      return productInquiryRepo.upsert(productInquiry)
    })

  const startDate = sortedResult.length
    ? sortedResult[0].Call__r.Call_Date__c
    : ''

  return {
    startRecordDate: startDate,
    records: products,
  }
}
