import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { observer, Observer } from 'mobx-react'
import _first from 'lodash/first'

import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import withStyles from '@material-ui/core/styles/withStyles'

import getPracticeIconByHex from '../Icons/Markers/PracticeColorIconSet'
import { repository as accountRepo } from '../../models/Account'
import { repository as mailingAreas } from '../../models/MailingArea'
import MapsStore from 'src/config/store/MapsStore'
import { defaultFont } from 'src/assets/jss/mvp'

const styles = theme => ({
  container: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    height: 48
  },
  marker: {
    width: 25,
    height: 25
  },
  label: {
    ...defaultFont,
    fontSize: 14
  },

  loaderIcon: {
    display: 'flex',
    flex: '1 0 auto',
    justifyContent: 'space-around',
    padding: 10
  }
})

const LocationDetails = ({ classes, ...props }) => {
  const locations = [...MapsStore.officeLocations.values()]
  let locationLabel = 'Office Locations'
  if (locations.length === 1) {
    locationLabel = _first(locations).officeName
  }

  return (
    <React.Fragment>
      { locations.length >= 1 &&
        <Observer render={() => {
          const firstArea = mailingAreas.get(MapsStore.mailingAreas[0])
          const office = accountRepo.get(firstArea.accountId)

          return <div className={classes.container}>
            <React.Fragment>
              <FormGroup row>
                <FormControlLabel
                  classes={{
                    label: classes.label
                  }}
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      color="primary"
                      checked={MapsStore.showMarkers}
                      onChange={(event, checked) => MapsStore.toggleMarkers(checked)}
                    />
                  }
                  label={locationLabel}
                />
              </FormGroup>
              { office && <img src={getPracticeIconByHex(office.color)} className={classes.marker} /> }
            </React.Fragment>
          </div>
        }}/>
      }
    </React.Fragment>
  )
}

LocationDetails.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

export default withStyles(styles)(observer(LocationDetails))
