import API, { DATA_API } from 'helpers/api'
import { Grade } from './grade'

export async function getStaffListPlacedCallerOnHold({
  accountIds,
  filters,
  signal,
}: GetStaffListPlacedCallerOnHoldInput) {
  if (!accountIds.length) {
    return { summary: {}, records: [] } as never as Response
  }

  const data = {
    accountIds,
    filters,
  }

  const source = API.getCancelToken().source()

  const promise = API.get<Response>({
    api: DATA_API,
    url: '/calls/metrics/staff-hold-count',
    params: data,
    cancelToken: source.token,
  })

  // Cancel the request if TanStack Query signals to abort
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })

  return promise
}

type GetStaffListPlacedCallerOnHoldInput = {
  accountIds: string[]
  filters: {}
  signal?: AbortSignal
}

export type Response = {
  summary: {
    total: number
    onHold: number
    askedOnHold: number
    onHoldPercentage: number
    askedOnHoldPercentage: number
    onHoldGrade: Grade
    askedOnHoldGrade: Grade
  }
  records: Array<{
    callHandlerId: string
    callHandlerName: string
    total: number
    staffPlacedCallerOnHoldTotal: number
    staffAskedToPlaceOnHoldTotal: number
    staffPlacedCallerOnHoldPercentage: number
    staffAskedToPlaceOnHoldPercentage: number
    staffPlacedCallerOnHoldGrade: Grade
    staffAskedToPlaceOnHoldGrade: Grade
  }>
}
