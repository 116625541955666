import React, { PropsWithChildren } from 'react'

// Mui
import { Modal, Button, Fade, Backdrop } from '@material-ui/core'
import { Close } from '@material-ui/icons'

// My util
import useStyles from './UserAccountSelectorModal.styles'

type Prop = PropsWithChildren<{
  title: string
  isOpen: boolean
  onClose: () => void
  applyCallback: () => void
}>

const UserAccountSelectorModal = (props: Prop) => {
  const classes = useStyles()
  const { isOpen, onClose, children, title, applyCallback } = props

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.root}>
          <div className={classes.paper}>
            <header className={classes.heading}>
              <span className={classes.title}>{title}</span>
              <Close onClick={onClose} />
            </header>
            <div className={classes.bodyWrapper}>{children}</div>
            <footer className={classes.buttonWrapper}>
              <Button
                color="primary"
                className={classes.btnGray}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={applyCallback}>
                Apply
              </Button>
            </footer>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default UserAccountSelectorModal
