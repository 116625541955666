import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(
  createStyles({
    container: {
      cursor: 'help',
    },
    tooltip: {
      background: 'rgba(40, 40, 40, 1)',
      '&:after': {
        borderWidth: '0px',
        marginLeft: '0px',
      },
    },
    title: {
      fontWeight: 'bold',
      marginBottom: '1rem',
    },
  })
)
