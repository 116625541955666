import React from 'react'
import { observer } from 'mobx-react'
import { formatNumber } from 'helpers/utility'
import Skeleton from '@material-ui/lab/Skeleton'
import { secondsToHours, secondsToMinutes } from 'date-fns'

import { KPICardContainer, KPIGradeEvaluator } from '../../KPICard'
import { AvgTime } from './AvgTime'

// STYLES
import { ProTip } from '../../ProTip'

import ProTipImage from '../../../../../assets/img/call-pro/pro-tips/pro-tip-caller-on-hold.png'
import GradeToolTipValue from '../../GradeToolTipValue'
import SeeIndividualMetric from './SeeIndividualMetric/SeeIndividualMetric'
import { useReactQuery } from './SeeIndividualMetric/_hooks'
import EmptyBarChart from 'components/CallPro/EmptyCharts/EmptyBarChart'

interface HoldTimeInterface {
  isAccordionContent?: boolean
}

const HoldTime = (props: HoldTimeInterface) => {
  const { isAccordionContent } = props
  const { isFetching, data } = useReactQuery()

  if (isFetching) {
    return (
      <Skeleton
        variant="rect"
        width={'100%'}
        height={'100%'}
        style={{ borderRadius: 10, minHeight: '29rem' }}
      />
    )
  }

  const summary = data!['summary']
  const totalCalls = summary.total
  const gradeName = summary.grade
  const avgSeconds = summary.averageSeconds
  const hours = secondsToHours(avgSeconds)
  const minutes = secondsToMinutes(avgSeconds - hours * 3600)
  const seconds = avgSeconds - minutes * 60

  if (!avgSeconds) {
    return <EmptyBarChart />
  }

  return (
    <KPICardContainer
      isAccordionContent={isAccordionContent}
      title="HOLD TIME"
      tooltip="This is the average duration of the calls that were placed on hold."
    >
      <KPIGradeEvaluator
        isAccordionContent={isAccordionContent}
        value={seconds}
        total={formatNumber(totalCalls)}
        totalText="Calls"
        gradeName={gradeName}
      />
      <AvgTime
        isAccordionContent={isAccordionContent}
        data={{
          seconds,
          minutes,
          hours,
        }}
      />
      <SeeIndividualMetric />
      <ProTip
        title="Don't make them wait. Why is keeping the hold time to less than one minute a game-changer"
        content={GradeToolTipValue(
          'Staff Properly Requested To Place Caller On Hold',
          gradeName
        )}
        image={ProTipImage}
      />
    </KPICardContainer>
  )
}

export default observer(HoldTime)
