import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import AppState, { isAuthenticated } from 'config/store/AppState'
import AppError from 'helpers/http/AppError'

interface RequestConfig extends Partial<AxiosRequestConfig> {
  api: string
  skipAuthentication?: boolean
}

type Endpoints = { [key: string]: string }

class Http {
  client: AxiosInstance
  endpoints: Endpoints

  constructor(endpoints: Endpoints) {
    this.client = axios.create()
    this.endpoints = endpoints
  }

  getCancelToken() {
    return axios.CancelToken
  }

  async post<T = any>(options: RequestConfig) {
    options.method = 'post'
    return this.call<T>(options)
  }

  async put<T = any>(options: RequestConfig) {
    options.method = 'put'
    return this.call<T>(options)
  }

  async get<T = any>(options: RequestConfig) {
    options.method = 'get'
    return this.call<T>(options)
  }

  async delete<T = any>(options: RequestConfig) {
    options.method = 'delete'
    return this.call<T>(options)
  }

  async request<T = any>(options: RequestConfig) {
    const baseURL = this.endpoints[options.api]
    return this.client.request<T>({ ...options, baseURL })
  }

  async call<T = any>(
    options: RequestConfig = {} as RequestConfig
  ): Promise<T> {
    let { headers, skipAuthentication } = options

    const isLoggedIn = await AppState.isAuthenticated
    if (!isLoggedIn && !skipAuthentication) {
      await isAuthenticated()
    }

    const accessToken = AppState.accessToken
    const authorizationHeaders = {} as any
    if (accessToken) {
      authorizationHeaders['Authorization'] = `Bearer ${accessToken}`
    }
    headers = Object.assign(
      authorizationHeaders || {},
      AppState.grantHeaders || {},
      headers
    )

    options.headers = headers

    try {
      const baseURL = this.endpoints[options.api]
      const response = await this.client.request<T>({ ...options, baseURL })

      if (response.config.responseType === 'blob') {
        return response.data
      }

      const { status, message, data } = response.data as any

      if (status === 'error') {
        throw new Error(message)
      }

      if (status === 'fail') {
        throw new Error('API call validation failed')
      }

      return data
    } catch (error) {
      const response = error.response
      if (response && response.data && response.data.message) {
        throw new AppError(
          response.data.message,
          response.data.data,
          response.status
        )
      }
      throw error
    }
  }
}

export default Http
