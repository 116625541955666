import React, { useState } from 'react'
import { Observer } from 'mobx-react'
import { Marker } from 'react-google-maps'
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'
import getPracticeIconByHex from '../Icons/Markers/PracticeColorIconSet'
import mapsStore from 'src/config/store/MapsStore'
import PracticeInfoBox from './PracticeInfoBox'
import { repository as accountRepo } from '../../models/Account'
import updateAccountColor from '../../services/accounts/updateAccountColor'
import googleDefaultCloseIcon from '../../assets/img/close.svg'

import {
  createStyles,
  withStyles,
  WithStyles
} from '@material-ui/core'

const styles = createStyles({
  infobox: {
    margin: '3px',
    marginLeft: '20px',
    overflow: 'visible!important',
    '& > img': {
      top: 22,
      right: 6,
      width: 14,
      height: 14,
      zIndex: 1,
      overflow: 'visible!important'
    }
  }
})

const OfficeMarkers = props => {
  const { classes } = props
  const { officeLocations, showMarkers } = mapsStore
  const [visibleOfficeInfoBox, setVisibleOfficeInfoBox] = useState(undefined)

  const handleClickMarker = (id) => {
    setVisibleOfficeInfoBox(id)
  }

  return (
    <React.Fragment>
      <Observer
        render={() => {
          if (!visibleOfficeInfoBox) {
            return null
          }
          const visibleOffice = accountRepo.get(visibleOfficeInfoBox)
          if (!visibleOffice) {
            return null
          }
          const position = new google.maps.LatLng(
            visibleOffice.locationLatitude,
            visibleOffice.locationLongitude,
            false
          )
          return visibleOfficeInfoBox && <InfoBox
            zIndex={9999}
            options={{
              boxClass: classes.infobox,
              position,
              alignBottom: true,
              pixelOffset: new google.maps.Size(-180, -40),
              closeBoxMargin: '0',
              closeBoxURL: googleDefaultCloseIcon,
              enableEventPropagation: false
            }}
            onDomReady={() => {
              const colorPicker = document.getElementById('officeColorPicker')
              const practiceColor = document.getElementsByClassName('officeColor')

              colorPicker.addEventListener('click', (e) => {
                mapsStore.visibleOfficeColor = true
              })

              for (let color of practiceColor) {
                color.addEventListener('click', (e) => {
                  const id = color.getAttribute('data-id')
                  const hexColor = color.getAttribute('data-color')

                  mapsStore.isPickingOfficeColor = true
                  mapsStore.visibleOfficeColor = false
                  updateAccountColor(id, hexColor)
                    .then((account) => {
                      accountRepo.upsert(account)
                      mapsStore.isPickingOfficeColor = false
                    })
                    .catch(err => console.log('Something went wrong: ', err))
                })
              }
            }}
            onCloseClick={() => {
              setVisibleOfficeInfoBox(undefined)
            }}
          >
            <PracticeInfoBox
              id={visibleOfficeInfoBox}
              onClose={() => {
                setVisibleOfficeInfoBox(undefined)
              }}
            />
          </InfoBox>
        }}
      />
      <Observer
        render={() => {
          return Array.from(officeLocations.values()).map(location => {
            const locationHasCoordinate = !!(
              location.longitude && location.latitude
            )
            const icon = {
              url: getPracticeIconByHex(location.color
                ? location.color : 'loading'
              ),
              scaledSize: new google.maps.Size(24, 24)
            }

            return locationHasCoordinate && mapsStore.showMarkers && <Marker
              key={`office-marker--${location.officeId}`}
              position={{
                lat: location.latitude,
                lng: location.longitude
              }}
              icon={icon}
              zIndex={1000}
              onClick={() => { handleClickMarker(location.officeId) }}
            />
          })
        }}
      />
    </React.Fragment>
  )
}

export default withStyles(styles)(OfficeMarkers)
