import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'constants/constants'

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 15,
  },

  designImg: {
    width: '100%',
    position: 'relative',
    zIndex: 200,
  },
  mvpImagePlaceholder: {
    objectFit: 'contain',
    filter: 'grayscale(100%) contrast(0) opacity(0.1)',
  },
  loader: {
    position: 'absolute',
    zIndex: 100,
  },
  thumbWrapper: {
    backgroundColor: 'rgb(239,239,239)',
    border: 'solid 5px rgb(239,239,239)',
    marginTop: 8,
    marginBottom: 16,
    width: '100%',
    height: 'auto',
    minHeight: '262px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  status: {
    position: 'absolute',
    'z-index': '999',
    marginTop: '22px',
    color: '#cacaca',
  },
}))
