import { makeStyles, Theme } from '@material-ui/core/styles'

export const useDeliveredMailingCardActionsStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      borderTop: '1px solid rgba(0, 0, 0, 0.06)',
      padding: 8,
      alignItems: 'center',
    },

    button: {
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '16px',
      fontWeight: 400,
      textTransform: 'none',
      padding: '4px 12px',
    },
  })
)
