import React from 'react'

// styles
import { useStyles } from './AllTopCampaignSourcesDrawer.style'
import AllTopCampaignSourcesSection from './AllTopCampaignSourcesSection/AllTopCampaignSourcesSection'

const AllTopCampaignSourcesDrawer = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <AllTopCampaignSourcesSection />
    </div>
  )
}

export default AllTopCampaignSourcesDrawer