import _isEmpty from 'lodash/isEmpty'
import API, { DATA_API } from 'helpers/api'
import accountStore from 'config/store/AccountStore'

export default async ({ filters }: any): Promise<any> => {
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    return {
      meta: {},
      records: [],
    }
  }

  const data = {
    accountIds,
    filters,
  }

  const result = await API.get({
    api: DATA_API,
    url: '/calls/get-caller-language',
    params: data,
  })

  const { records, firstRecordDate } = result

  return {
    records,
    firstRecordDate,
  }
}
