import React, { useCallback, useEffect } from 'react'

// Util
import { observer } from 'mobx-react'
import { useLocation } from 'react-router'

// My util
import { useCallProStyles } from './CallPro.style'
import { useStores } from 'config/store'
import { emailRoutes } from 'routes'

// My components
import Overview from 'components/CallPro/Overview/Overview'
import FrontDesk from 'components/CallPro/FrontDesk/FrontDesk'
import AllCalls from 'components/CallPro/AllCallsPage/AllCallsPage'
import AppBar from 'views/CallPro/AppBar'
import TabPanel from 'views/CallPro/TabPanel'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'

const panels = [<Overview />, <FrontDesk />, <AllCalls />]

const CallPro: React.FC = () => {
  const classes = useCallProStyles()
  const { dashboardUIStore, callsStore, globalFilterStore } = useStores()

  const location = useLocation<{ reset?: boolean }>()

  useEffect(() => {
    const { dateFilter } = globalFilterStore
    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get('from');
    const to = queryParams.get('to');

    if (from && to) {
      dateFilter.setDateRange({ from: new Date(from), to: new Date(to)})
      dateFilter.setDateFilterType('custom')  
    }

    if (location.pathname.includes('all-calls')) {
      dashboardUIStore.setCurrentTabbedOpened(CallDashboardTabs.ALL_CALLS)
    }

    if (location.pathname === emailRoutes.missedCalls) {
      callsStore.setFilters({ outcome: CallOutcome.MISSED_CALL })
    }

    if (location.pathname === emailRoutes.appointmentCalls) {
      callsStore.setFilters({ outcome: CallOutcome.APPOINTMENT })
    }

    if (location.pathname === emailRoutes.opportunityCalls) {
      callsStore.setFilters({ outcome: CallOutcome.OPPORTUNITY })
    }

    callsStore.refreshCalls()
    dashboardUIStore.makeReactions()
    refresh()
    return () => dashboardUIStore.dispose()
  }, [])

  const refresh = useCallback(() => {
    const state = location.state
    // state.reset could be set from different component using
    // history.push(<route>, {reset: false})
    if (state === undefined || state.reset === true) {
      dashboardUIStore.refreshCurrentTab()
    }
  }, [location])

  return (
    <div className={classes.root}>
      <AppBar />
      {panels.map((panel, key) => (
        <TabPanel
          key={key}
          value={dashboardUIStore.currentTabOpened.index}
          index={key}
        >
          {panel}
        </TabPanel>
      ))}
    </div>
  )
}

export default observer(CallPro)
