import { useState, useEffect } from 'react'
import {
  Workflow,
  CommentMarker,
  DesignVersionStatus,
  DesignStatus,
  ReviewCommentStatus,
} from '@mvp/mvp-react'
import produce from 'immer'

import { GetDesignInterface } from './getDesign'
import { DesignImageUrls } from '../getDesignImageUrls'
import { parseReviewComment } from '../parseReviewComment'
import { getCampaignImageUrls } from './getCampaignImageUrls'

interface CampaignReviewState {
  nextReview: DesignStatus
  designImageUrls: DesignImageUrls | null
  initialComments: CommentMarker[]
  accountName: string | undefined
  workflow: Workflow
  designName: string
  currentVersionId: string | null
  currentVersionNumber: number | null
  currentVersionStatus: DesignVersionStatus
  mediaType: string
  isFromDesignConcept: boolean
}

export default function useCampaignReview(
  campaign?: any,
  design?: GetDesignInterface
) {
  const [state, setState] = useState<CampaignReviewState>({
    nextReview: DesignStatus.CustomerReview,
    designImageUrls: null,
    initialComments: [],
    accountName: undefined,
    workflow: Workflow.Request,
    designName: '',
    currentVersionId: null,
    currentVersionNumber: null,
    currentVersionStatus: DesignVersionStatus.Requested,
    mediaType: '',
    isFromDesignConcept: false,
  })

  useEffect(() => {
    if (!design) {
      return
    }
    const nextReview = getNextReview(campaign)
    const currentVersionStatus = getCurrentVersionStatus(campaign)
    const designName = design.name
    const accountName = design.account.name
    const currentVersionId = design.currentVersion.id
    const currentVersionNumber = design.currentVersion.version
    const mediaType = design.mediaType
    const workflow = Workflow.Request
    const initialComments = getComments(design)
    const designImageUrls = getCampaignImageUrls(campaign, design)

    setState(
      produce(draftState => {
        draftState.nextReview = nextReview
        draftState.designImageUrls = designImageUrls
        draftState.initialComments = initialComments
        draftState.accountName = accountName
        draftState.workflow = workflow
        draftState.designName = designName
        draftState.currentVersionId = currentVersionId
        draftState.currentVersionNumber = currentVersionNumber
        draftState.currentVersionStatus = currentVersionStatus
        draftState.mediaType = mediaType
        draftState.isFromDesignConcept = false
      })
    )
  }, [campaign, design])

  const isRequested = state.currentVersionStatus ? [
    DesignVersionStatus.Requested,
    DesignVersionStatus.Wip,
    DesignVersionStatus.Rejected,
  ].includes(state.currentVersionStatus) : false

  const isApproved = state.currentVersionStatus === DesignVersionStatus.Approved

  const isInReview = [
    DesignVersionStatus.Approved,
    DesignVersionStatus.InReview,
  ].includes(state.currentVersionStatus)

  return {
    ...state,
    isRequested,
    isApproved,
    isInReview,
  }
}

const getNextReview = (campaign: any) => {
  const isCampaignReviewCompleted = !!campaign.approvalDate
  return isCampaignReviewCompleted
    ? DesignStatus.Approved
    : DesignStatus.CustomerReview
}

const getCurrentVersionStatus = (campaign: any) => {
  const isInDesigner = !campaign.mediaSubmitted
  const isDesignStarted = !!campaign.mediaStarted
  const isInPrePress = !campaign.prePressCompleted
  const isCampaignReviewCompleted = !!campaign.approvalDate
  let currentVersionStatus = DesignVersionStatus.Requested

  if (isInDesigner) {
    currentVersionStatus = isDesignStarted
      ? DesignVersionStatus.Wip
      : DesignVersionStatus.Requested
  } else if (isInPrePress) {
    currentVersionStatus = DesignVersionStatus.Wip
  } else {
    currentVersionStatus = isCampaignReviewCompleted
      ? DesignVersionStatus.Approved
      : DesignVersionStatus.InReview
  }

  return currentVersionStatus
}

const getComments = (design: GetDesignInterface) => {
  return (design.comments ?? [])
    .filter((c: any) => c.status === ReviewCommentStatus.Draft)
    .map((c: any) => parseReviewComment(c, design.currentVersion.id))
}
