import { observable, runInAction } from 'mobx'
import _debounce from 'lodash/debounce'
import _isNil from 'lodash/isNil'
import _isArray from 'lodash/isArray'

import callsStore from 'config/store/CallPro/Call/CallsStore'

export type FilterValue = undefined | null | string | number | boolean

export type FilterType = {
  id: string
  label: string
  active: boolean
  disabled: boolean
  hidden: boolean
  /**
   * - STANDARD = Nothing special.
   * - LIMITED  = Fields with the same type "Limited" can only be shown limitedly.
   */
  type: 'STANDARD' | 'LIMITED'
  message: string
  value: FilterValue | Array<FilterValue>
  beforeFilter?: (value: FilterType['value']) => FilterType['value']
}

export default class CallTableFilterStore {
  searchQuery = observable.box('')
  moreFiltersOpened = observable.box(false)
  addFilterDialogOpened = observable.box(false)
  filterRegistry = observable.map<string, FilterType>()

  // # Non-observable properties
  debounceRunFilter = _debounce(this.runFilter, 1000)
  readonly rules = { numberOfLimitedShownFields: 2 }

  get filters() {
    return [...this.filterRegistry.values()]
  }

  get visibleFilters() {
    return this.filters.filter((a) => !a.hidden)
  }

  get activeVisibleFilters() {
    return this.visibleFilters.filter((a) => a.active)
  }

  get activeFilters() {
    return this.filters.filter((a) => a.active)
  }

  reset() {
    runInAction(() => {
      this.moreFiltersOpened.set(false)
      this.addFilterDialogOpened.set(false)
      this.searchQuery.set('')
      this.activeFilters.forEach((a) => {
        a.value = undefined
        a.active = false
      })
    })
  }

  resetFilter = (opt?: { isRunFilter?: boolean }) => {
    const { isRunFilter = true } = opt ?? {}
    runInAction(() => {
      this.searchQuery.set('')
      this.activeFilters.forEach((a) => (a.value = undefined))
      isRunFilter && this.runFilter()
    })
  }

  searchByQuery(query: string) {
    this.searchQuery.set(query)
    this.debounceRunFilter()
  }

  registerFilter(
    id: string,
    meta: Pick<FilterType, 'label'> & Partial<Omit<FilterType, 'id'>>
  ) {
    runInAction(() => {
      const active = meta.active ?? false
      const value = meta.value ?? null
      const disabled = meta.disabled ?? false
      const type = meta.type ?? 'STANDARD'
      const message = meta.message ?? ''
      const hidden = meta.hidden ?? false

      if (!this.moreFiltersOpened.get()) {
        const openFiltersWhenHasValue = Array.isArray(value)
          ? Boolean(value.length)
          : !_isNil(value)
        this.moreFiltersOpened.set(openFiltersWhenHasValue)
      }

      this.filterRegistry.set(id, {
        id,
        value,
        active,
        disabled,
        type,
        message,
        hidden,
        ...meta,
      })
    })
  }

  toggleFilter(key: string, shown?: boolean) {
    runInAction(() => {
      const filter = this.filterRegistry.get(key)
      if (_isNil(filter)) {
        return
      }

      filter.active = shown ?? filter.active
      const value = filter.value
      this.setFilterValue(key, undefined)

      const hasValue = !_isNil(value) && (_isArray(value) ? value.length : true)
      const isNotActiveAndHasValue = !filter.active && hasValue
      isNotActiveAndHasValue && this.runFilter()

      this.disableLimitedFiltersViolatedRule()
    })
  }

  setFilterValue(key: string, value: FilterType['value']) {
    runInAction(() => {
      const filter = this.filterRegistry.get(key)
      if (filter !== undefined) {
        filter.value = value
      }
    })
  }

  runFilter() {
    const moreFilters: { [key: string]: FilterType['value'] } = {}
    this.filters.forEach((a) => {
      moreFilters[a.id] = a['beforeFilter'] ? a.beforeFilter(a.value) : a.value
    })

    runInAction(() => {
      callsStore.setSearchQuery(this.searchQuery.get())
      callsStore.appendFilters(moreFilters)
      callsStore.setPaginationPage(1)
      callsStore.refreshCalls()
    })
  }

  // @todo move this somewhere
  private disableLimitedFiltersViolatedRule() {
    const limitedFilters = this.filters.filter((a) => a.type === 'LIMITED')
    const limitedActiveFilters = limitedFilters.filter((a) => a.active)
    const limitedNotActiveFilters = limitedFilters.filter((a) => !a.active)
    const isLimitedRuleViolated =
      limitedActiveFilters.length >= this.rules.numberOfLimitedShownFields
    limitedNotActiveFilters.forEach((a) => {
      a.disabled = isLimitedRuleViolated
      a.message = isLimitedRuleViolated
        ? 'You cannot use the Offers, Services, and Insurance filters at the same time. You can only combine 2 out of the 3 filters.'
        : ''
    })
  }
}

const callTableFilterStore = new CallTableFilterStore()

export function useCallTableFilterStore() {
  return callTableFilterStore
}
