import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { useStyles } from './MailingRecentCalls.style'
import { useStyles as useLabelStyles } from '../../CallPro/Tables/CallsTableColumns.style'

import { getLabelWrapper } from 'components/CallPro/Tables/CallsTableColumns'
import { useStores } from 'config/store'

import { Recording } from 'components/CallPro/Recording/Recording'
import CustomTable from 'components/Table/Table'
import LoadingData from 'components/ProgressIndicator/LoadingData'

import Call, { repository as callsRepo } from 'models/Call'
import CallDetails from 'components/CallPro/SideDrawer/CallDetails/CallDetails'
import { Button } from '@material-ui/core'
import AppointmentTable from 'components/CallPro/SideDrawer/AppointmentTable/AppointmentTable'

interface MailingRecentCallsProps {
  campaignId: string
}

const MailingRecentCalls: React.FC<MailingRecentCallsProps> = (props) => {
  const classes = useStyles()
  const labelClasses = useLabelStyles()
  const { mailingsStore } = useStores()
  const [calls, setCalls] = useState<Call[]>()
  const [loading, setLoading] = useState(true)
  const [currentlyPlaying, setIsCurrentlyPlaying] = useState('')
  const [singleCallDetails, setSingleCallDetails] = useState('')
  const mailing = mailingsStore.drawer

  if (!mailing) return <></>

  const [filters, setFilters] = useState<{
    id: string
    pageSize: number | null
    sort: string
    dir: 'asc' | 'desc'
    filters: {}
  }>({
    id: mailing.id,
    pageSize: 9,
    sort: 'callDateTime',
    dir: 'desc',
    filters: {
      outcome: 'Appointment',
      opportunity: true,
    },
  })

  useEffect(() => {
    setLoading(true)
    mailingsStore
      .fetchRecentCalls({ ...filters })
      .then((calls: any) => {
        setCalls(calls.records.map((id: string) => callsRepo.get(id)))
      })
      .catch((e: any) => console.warn(e))
      .finally(() => {
        setLoading(false)
      })
  }, [filters])

  const columns = [
    {
      id: 'callDateTime',
      name: 'Date',
      sortable: true,
    },
    {
      id: 'caller',
      name: 'Caller',
      sortable: false,
    },
    {
      id: 'patientType',
      name: 'Type',
      sortable: true,
    },
    {
      id: 'outcome',
      name: 'Outcome',
      sortable: false,
    },
    {
      id: 'duration',
      name: 'Recording',
      sortable: true,
    },
  ]

  const rows = calls?.map((call: Call) => {
    const id = call.id
    const resolved = !!call.resolveDate
    const appointmentsCount = call.appointments.length
    const label = getLabelWrapper(
      call.outcome ?? [],
      appointmentsCount,
      labelClasses,
      resolved
    )
    const dateTime = moment(call.callDateTime)
    let callerName = call.callerName ?? call.callerIdNameFmt
    callerName = callerName ? `${callerName.substring(0, 12)}...` : '---'

    return {
      rowJsx: [
        dateTime.isValid() ? dateTime.format('M/D/YY @ h:mm a') : '-',
        callerName,
        call.patientType,
        label,
        <Recording
          handleAudioPlayPause={(id) => {
            setIsCurrentlyPlaying(id)
          }}
          call={call}
          currentlyPlaying={currentlyPlaying}
        />,
      ],
      id: id,
    }
  })

  const toggleDir = (filters: any, column: string) => {
    if (filters.sort === column && filters.dir === 'asc') {
      return 'desc'
    } else {
      return 'asc'
    }
  }

  if (singleCallDetails.length) {
    return (
      <div className={classes.recentCallsWrapper}>
        <a
          onClick={() => {
            setSingleCallDetails('')
          }}
          className={classes.backButton}
        >
          ← go back
        </a>
        <CallDetails call={callsRepo.get(singleCallDetails)} />
        <AppointmentTable call={callsRepo.get(singleCallDetails)} />
      </div>
    )
  } else {
    return (
      <>
        <div className={classes.recentCallsWrapper}>
          {loading ? (
            <LoadingData hidden={false} />
          ) : (
            <>
              <CustomTable
                columns={columns}
                rows={rows ?? []}
                defaultRowPerPage={10}
                isLoading={loading}
                orderBy={filters.sort}
                dir={filters.dir}
                variant={'condensed'}
                onColumnSort={(column: string) => {
                  setFilters({
                    ...filters,
                    sort: column,
                    dir: toggleDir(filters, column),
                  })
                }}
                totalRowsCount={calls?.length ?? 0}
                hidePagination={true}
                handleSelectCall={(id: string) => {
                  setSingleCallDetails(id)
                }}
              />
              {rows && rows.length >= 9 && filters.pageSize ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    width: '100%',
                    marginTop: 8,
                    borderRadius: 0,
                  }}
                  onClick={() => setFilters({ ...filters, pageSize: null })}
                >
                  View All
                </Button>
              ) : null}
            </>
          )}
        </div>
      </>
    )
  }
}

export default MailingRecentCalls
