import React from 'react'
import AllAnsweredBySection from './AllAnsweredBySection/AllAnsweredBySection'

// STYLES
import { useStyles } from './AllAnsweredByDrawer.style'

const AllAnsweredByDrawer = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AllAnsweredBySection />
    </div>
  )
}

export default AllAnsweredByDrawer
