import axios from 'axios'
import MailingArea from 'src/models/MailingArea'
import MailingZone from 'src/models/MailingZone'
import updateMailingAreaColor from 'src/services/mailingAreas/updateMailingAreaColor'
import updateMailingZoneColor from 'src/services/mailingAreas/updateMailingZoneColor'
import { action, observable, reaction } from 'mobx'
import _get from 'lodash/get'
import _first from 'lodash/first'
import _isArray from 'lodash/isArray'
import EventEmitter from 'eventemitter3'
import bbox from '@turf/bbox'
import bboxPolygon from '@turf/bbox-polygon'
import union from '@turf/union'

import accountStore from 'src/config/store/AccountStore'
import getMailingAreas from 'src/services/mailingAreas/getMailingAreas'
import getAccount from 'src/services/accounts/getAccount'
import { repository as MailingAreas } from 'src/models/MailingArea'
import { repository as MailingZones } from 'src/models/MailingZone'
import { repository as GeodataRepo } from 'src/models/Geodata'
import marker from 'src/assets/img/office.svg'
import mapStyleDefault from 'src/components/Maps/Maps.styles'
import refreshPatientLists from './Map/refreshPatientLists'

const defaultOfficeCoord = {
  lat: 39.321,
  lng: -111.0937,
} // utah

/* global google */
export class MapsStore {
  overlayRef = null
  mapRef = null
  cancelToken = null

  @observable officeLocations = new Map()
  @observable isLoading = false
  @observable error = null

  @observable isDirty = true
  @observable mailingAreas = []
  @observable geodata = new Map()
  @observable selectedGeodataIds = []
  @observable expandedAreaPanels = []

  @observable features = new Map()
  @observable markers = new Map()
  @observable showMarkers = true
  @observable showInfoBox = false
  @observable isPickingOfficeColor = false
  @observable visibleOfficeColor = false

  @observable patientLists = []
  @observable patientListLayers = []

  infoBoxItem = null

  constructor() {
    this.loadingNotifier = new EventEmitter()
    this.infoBoxItem = observable.box(null)

    this.monitorDirty()
    this.monitorMarkers()
    this.monitorLoading()
    this.monitorSelectedAccounts()
  }

  monitorSelectedAccounts() {
    return reaction(
      () => accountStore.selectedAccounts.slice(),
      () => {
        this.dirty(true)
      }
    )
  }

  monitorMarkers() {
    return reaction(
      () => this.showMarkers,
      (showMarkers) => {
        this.markers.forEach((marker) => marker.setVisible(showMarkers))
      }
    )
  }

  monitorDirty() {
    return reaction(
      () => this.isDirty,
      (isDirty) => {
        if (isDirty && this.mapRef) {
          this.refreshMailingAreas()
        }
      }
    )
  }

  monitorLoading() {
    return reaction(
      () => this.isLoading,
      (isLoading) => {
        this.loadingNotifier.emit('maps.loading.change', isLoading)
      }
    )
  }

  @action
  refreshMailingAreas() {
    // No need to refresh if not dirty
    if (!this.isDirty) {
      this.initializeMapComponents()
      return
    }

    this.isLoading = true
    this.isDirty = false
    if (this.cancelToken) {
      this.cancelToken.cancel('getMailingAreas aborted.')
      this.cancelToken = null
    }

    refreshPatientLists(accountStore.selectedAccounts.slice().toString())

    getMailingAreas({ cancelToken: this.cancelToken })
      .then((results) => {
        const { mailingAreas, locations } = results

        this.selectedGeodataIds.clear()
        this.mailingAreas.replace(
          mailingAreas.filter((id) => {
            const area = MailingAreas.get(id)
            const carrierRoutes = area.carrierRoutes.filter(a => !!a)
            return !area.archived
              && !!carrierRoutes.length
          })
        )
        this.officeLocations.clear()
        locations.forEach((location) => {
          this.officeLocations.set(location.officeId, location)
          if (location.latitude && location.longitude) {
            getAccount(location.officeId).then((account) => {
              if (account) {
                this.officeLocations.set(location.officeId, location)
              }
            })
          }
        })
        this.initializeMapComponents()
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.log('[MailingStore:getMailingAreas] error', error)
          this.fail(error)
        }
      })
      .then(() => {
        this.isLoading = false
      })
  }

  @action
  toggleAreaPanel(areaId, toggle = true) {
    if (toggle) {
      this.expandedAreaPanels.push(areaId)
    } else {
      const index = this.expandedAreaPanels.indexOf(areaId)
      this.expandedAreaPanels.splice(index, 1)
    }
  }

  @action
  toggleMarkers(show) {
    this.showMarkers = show
  }

  @action
  toggleGeodataId(geodataIds, toggle = undefined) {
    if (!_isArray(geodataIds)) {
      return this.toggleGeodataId([geodataIds], toggle)
    }

    geodataIds.forEach((geodataId) => {
      const index = this.selectedGeodataIds.indexOf(geodataId)
      if (undefined === toggle) {
        toggle = index === -1
      }

      if (toggle && index === -1) {
        this.selectedGeodataIds.push(geodataId)
      } else if (!toggle && index > -1) {
        this.selectedGeodataIds.remove(geodataId)
      }
    })
  }

  @action
  dirty(isDirty = true) {
    this.isDirty = isDirty
  }

  @action
  fail(error) {
    if (error instanceof Error) {
      this.error = error.message
    } else {
      this.error = null
    }
  }

  /**
   *
   * @param areaOrZone
   * @param toggle
   */
  toggleAreaOrZone(areaOrZone, toggle) {
    const color = areaOrZone.color || '#000000'
    this.attachGeodata(areaOrZone.geodataId)
    this.applyStyles(areaOrZone.geodataId, {
      strokeColor: color,
      fillColor: color,
      visible: toggle,
    })
    this.toggleGeodataId(areaOrZone.geodataId, toggle)
  }

  setMap(ref) {
    this.mapRef = ref
  }

  getMap() {
    return this.mapRef
  }

  setMapOverlay(ref) {
    this.overlayRef = ref
  }

  getMapOverlay() {
    return this.overlayRef
  }

  @action
  initializeMapComponents() {
    this.features.clear()

    // Clear the map
    this.mapRef &&
      this.mapRef.data &&
      this.mapRef.data.forEach((feature) => {
        this.mapRef.data.remove(feature)
      })

    this.markers.clear()

    const findViewBoundsOfArea = (area) => {
      const bboxes = []
      let bounds = null
      if (area.zones && area.zones.length) {
        area.zones.forEach((zoneId) => {
          const zone = MailingZones.get(zoneId)
          const geo = GeodataRepo.get(zone.geodataId)
          if (geo) {
            bboxes.push(geo.boundingBox)
          }
        })
      } else {
        const areaGeo = GeodataRepo.get(area.geodataId)
        if (areaGeo) {
          bboxes.push(areaGeo.boundingBox)
        }
      }

      if (bboxes.length) {
        let result = bboxPolygon(bboxes.pop())
        while (bboxes.length) {
          const box2 = bboxPolygon(bboxes.pop())
          result = union(result, box2)
        }
        const fbox = bbox(result)
        bounds = {
          west: fbox[0],
          south: fbox[1],
          east: fbox[2],
          north: fbox[3],
        }
      }

      return bounds
    }

    const setBoundsByAvailableLocation = () => {
      const location = _first(Array.from(
        this.officeLocations.values()
      ))
      let latlng = defaultOfficeCoord
      if (location && location.longitude && location.latitude) {
        latlng = {
          lat: location.latitude,
          lng: location.longitude,
        }
      }
      this.mapRef.setCenter(latlng)
      this.mapRef.setZoom(10.5)
    }

    if (this.mailingAreas.length) {
      const selected = this.selectedGeodataIds.slice()
      if (selected.length) {
        // We already have something selected
        const bboxes = []
        selected.forEach((geodataId) => {
          let zoneArea = MailingAreas.findByGeodataId(geodataId)
          if (!zoneArea) {
            zoneArea = MailingZones.findByGeodataId(geodataId)
          }
          if (zoneArea) {
            this.toggleAreaOrZone(zoneArea, true)
          }
          const geo = GeodataRepo.get(geodataId)
          bboxes.push(geo.boundingBox)
        })
        if (bboxes.length) {
          let result = bboxPolygon(bboxes.pop())
          while (bboxes.length) {
            const box2 = bboxPolygon(bboxes.pop())
            result = union(result, box2)
          }
          const fbox = bbox(result)
          const bounds = {
            west: fbox[0],
            south: fbox[1],
            east: fbox[2],
            north: fbox[3],
          }
          this.mapRef.fitBounds(bounds, -30)
        }
      } else {
        // Select the first mailing area if we have nothing selected yet
        const firstArea = MailingAreas.get(this.mailingAreas[0])
        if (firstArea) {
          if (firstArea.zones && firstArea.zones.length) {
            firstArea.zones.forEach((zoneId) => {
              const zone = MailingZones.get(zoneId)
              this.toggleAreaOrZone(zone, true)
            })
          } else {
            this.toggleAreaOrZone(firstArea, true)
          }

          const bounds = findViewBoundsOfArea(firstArea)
          if (bounds) {
            this.mapRef.fitBounds(bounds, -30)
          } else {
            setBoundsByAvailableLocation()
          }

          if (this.expandedAreaPanels.length === 0) {
            this.toggleAreaPanel(firstArea.id)
          }
        }
      }
    } else {
      setBoundsByAvailableLocation()
    }
  }

  @action
  attachGeodata(geodataId) {
    if (!this.mapRef) {
      console.warn('Cannot attach geodata if map reference is not set')
      return false
    }

    const attached = [...this.features.keys()]
    if (attached.includes(geodataId)) {
      return this.features.get(geodataId)
    }

    const geodata = GeodataRepo.get(geodataId)

    if (geodata) {
      const features = this.mapRef.data.addGeoJson(geodata.geodata)
      this.features.set(geodataId, features)
      return features
    }

    // What to do with non-existent geodata? Try to retrieve from endpoint again?
  }

  /**
   *
   * @param {String|String[]} geodataId
   * @param styles
   */
  applyStyles(geodataId, styles = {}) {
    if (_isArray(geodataId)) {
      geodataId.forEach((id) => this.applyStyles(id, styles))
      return
    }

    const attached = [...this.features.keys()]
    if (!attached.includes(geodataId)) {
      return
    }
    const features = this.features.get(geodataId)
    features.forEach((feature) => {
      if (!styles) {
        this.mapRef.data.revertStyle(feature)
      } else {
        this.mapRef.data.overrideStyle(feature, styles)
      }
    })
  }

  panTo(geodataId) {
    const geo = GeodataRepo.get(geodataId)
    this.mapRef.panTo({
      lng: _get(geo, 'centroid.geometry.coordinates[0]'),
      lat: _get(geo, 'centroid.geometry.coordinates[1]'),
    })
  }

  attachMarker(name, lng, lat) {
    const mapMarker = new google.maps.Marker({
      icon: {
        url: marker,
        scaledSize: new google.maps.Size(24, 24),
      },
      position: { lat, lng },
      map: this.mapRef,
      visible: true,
    })
    return mapMarker
  }

  @action.bound
  async updateFeatureColor(item, color) {
    try {
      if (item instanceof MailingArea) {
        return updateMailingAreaColor(
          {
            id: item.id,
            color,
          },
          {
            campaignType: item.campaignType,
          }
        )
      } else if (item instanceof MailingZone) {
        return updateMailingZoneColor({
          id: item.id,
          color,
        })
      }
    } catch (error) {
      throw error
    }
  }

  @action
  setFeatureColor(item, newColor) {
    if (item) {
      item.color = newColor
      this.attachGeodata(item.geodataId)
      this.applyStyles(item.geodataId, {
        strokeColor: newColor,
        fillColor: newColor,
      })
    }
  }

  @action
  resetDefault(excludedGeoDataId) {
    let geoDataIds = this.features.keys()

    for (let geodataId of geoDataIds) {
      if (geodataId != excludedGeoDataId) {
        this.applyStyles(geodataId, mapStyleDefault)
      }
    }
  }

  @action
  cleanStore() {}
}

const mapsStore = new MapsStore()
export default mapsStore
