import React from 'react'
import {
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import useStyles from './DialogTitle.style'

interface DialogTitleProps {
  children: React.ReactNode
  onClose: () => void
}

export default function DialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props
  const styles = useStyles()

  return (
    <MuiDialogTitle disableTypography className={styles.root} {...other}>
      <Typography variant="h6" className={styles.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}
