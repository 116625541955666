
//
export default {
  black:      '#141414',
  darkGrey:   '#232323',
  lightPink:  '#ED154E',
  lightGrey:  '#D3D3D3',
  darkPink:   '#B7003D',
  mediumGrey: '#727272',
  gold:       '#EFC75E',
  green:      '#0D951B',
  red:        '#F50D0D'
}