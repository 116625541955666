import React, { useState } from 'react'
import { createStyles, Box, makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ExpandMore } from '@material-ui/icons'
import _isNil from 'lodash/isNil'

import { FilterFieldProp } from './FilterField'

export type AsyncFilterFieldProp = FilterFieldProp & {
  loading?: boolean
  onInputChange?: (value: string) => void
}

export default function AsyncFilterField({
  label,
  source,
  onChange,
  onInputChange,
  loading = false,
  value = null,
  multiple = false,
  shown = true,
}: AsyncFilterFieldProp) {
  const styles = useStyles()
  const sourceMap = new Map(source.map((a) => [a.value, a]))
  const [_inputValue, _setInputValue] = useState('')

  return (
    <Box display={!shown ? 'none' : undefined}>
      <Autocomplete
        autoComplete
        value={value}
        inputValue={_inputValue}
        multiple={multiple}
        loading={loading}
        options={source.map((a) => a.value)}
        getOptionLabel={(option) =>
          _isNil(option) ? '' : sourceMap.get(option)?.label ?? ''
        }
        onChange={(_, newValue: AsyncFilterFieldProp['value']) => {
          const _newValue = newValue ?? (multiple ? [] : null)
          onChange && onChange(_newValue)
        }}
        onInputChange={(_, newValue, reason) => {
          if (reason !== 'reset') {
            _setInputValue(newValue)
            onInputChange !== undefined && onInputChange(newValue)
          }
        }}
        onClose={(_, reason) => {
          if (reason === 'blur') {
            _setInputValue('')
          }
        }}
        noOptionsText={
          _inputValue?.length ? undefined : 'Start typing to search'
        }
        filterSelectedOptions
        disableCloseOnSelect
        popupIcon={<ExpandMore />}
        limitTags={1}
        style={{ width: 256 }}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            InputLabelProps={{
              classes: { root: styles.textFieldLabel },
            }}
            classes={{
              root: styles.textField,
            }}
          />
        )}
      />
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      background: 'white',
    },
    textFieldLabel: {
      '&.MuiInputLabel-marginDense:not(.MuiInputLabel-shrink)': {
        transform: 'translate(14px, 14px) scale(1)',
      },
    },
  })
)
