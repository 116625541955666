import API, { DATA_API } from 'src/helpers/api'

export default async (filters) => {
  const result = await API.get({
    api: DATA_API,
    url: `/get-team-leaderboard`,
    params: filters,
  })

  return result
}
