const _ = require('lodash')

export function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export function groupStringByMatches(
  input: string,
  exp: RegExp
): Array<[string, boolean]> {
  const results = <[string, boolean][]>[]
  let nextStart = 0

  exp.lastIndex = 0
  let match: RegExpExecArray | null = null

  do {
    match = exp.exec(input)

    if (match !== null) {
      if (match.index > nextStart) {
        results.push([input.slice(nextStart, match.index), false])
      }

      results.push([match[0], true])
      nextStart = exp.lastIndex
    }
  } while (match)

  const remaining = input.slice(nextStart)

  if (remaining) {
    results.push([remaining, false])
  }

  return results
}

export function groupPhoneNumberByMatches(
  input: string,
  exp: RegExp
): Array<[string, boolean]> {
  const isStandardPhoneNumber =
    input.length === 12 && input.slice(0, 2) === '+1'
  const newInput = isStandardPhoneNumber ? input.slice(1) : input

  const results = groupStringByMatches(newInput, exp)

  if (!isStandardPhoneNumber) {
    return results
  }

  let newResults = <[string, boolean][]>[]

  const isCountryCodeMatched = results[0][1]

  if (isCountryCodeMatched) {
    newResults.push(['+', false])
    newResults.push(['1', true])
    newResults.push([' ', false])
  }

  results[0] = [results[0][0].slice(1), results[0][1]]
  newResults.push(['(', false])

  const closedParenthesisIndex = 2
  const dashIndex = 5
  let currentIndex = 0
  let addedClosedParenthesis = false
  let addedDash = false

  const addParenthesis = (currentIndex: number, r: [string, boolean]) => {
    const relativeIndex = currentIndex - r[0].length
    const index = Math.abs(relativeIndex - closedParenthesisIndex)

    const appendedItems = appendStringToMatchedGroup(index, r, ') ')
    newResults = [...newResults, ...appendedItems]
    addedClosedParenthesis = appendedItems.length > 1
  }

  const addDash = (currentIndex: number, r: [string, boolean]) => {
    const relativeIndex = currentIndex - r[0].length
    const index = Math.abs(relativeIndex - dashIndex)

    const appendedItems = appendStringToMatchedGroup(index, r, '-')
    newResults = [...newResults, ...appendedItems]
    addedDash = appendedItems.length > 1
  }

  for (const r of results) {
    currentIndex += r[0].length

    if (addedClosedParenthesis && addedDash) {
      newResults.push(r)
      continue
    }

    const shouldAddParenthesis =
      currentIndex >= closedParenthesisIndex && !addedClosedParenthesis
    const shouldAddDash = currentIndex >= dashIndex && !addedDash

    if (shouldAddParenthesis && shouldAddDash) {
      const relativeIndex = currentIndex - r[0].length
      const parenthesisIndexAdjusted = Math.abs(
        relativeIndex - closedParenthesisIndex
      )
      let dashIndexAdjusted = Math.abs(relativeIndex - dashIndex)

      const parenthesisItems = appendStringToMatchedGroup(
        parenthesisIndexAdjusted,
        r,
        ') '
      )

      if (parenthesisItems.length > 1) {
        newResults.push(parenthesisItems[0])
        newResults.push(parenthesisItems[1])
        addedClosedParenthesis = true
      } else {
        newResults = [...newResults, ...parenthesisItems]
      }

      if (addedClosedParenthesis) {
        dashIndexAdjusted = dashIndexAdjusted - parenthesisIndexAdjusted - 1
        const dashItems = appendStringToMatchedGroup(
          dashIndexAdjusted,
          parenthesisItems[2],
          '-'
        )
        newResults = [...newResults, ...dashItems]
        addedDash = dashItems.length > 1
        continue
      }

      const dashItems = appendStringToMatchedGroup(dashIndexAdjusted, r, '-')
      newResults = [...newResults, ...dashItems]
      addedDash = dashItems.length > 1
      continue
    }

    if (shouldAddParenthesis) {
      addParenthesis(currentIndex, r)
      continue
    }

    if (shouldAddDash) {
      addDash(currentIndex, r)
      continue
    }

    newResults.push(r)
  }

  return newResults
}

function appendStringToMatchedGroup(
  index: number,
  r: [string, boolean],
  stringToAppend: string
): Array<[string, boolean]> {
  if (r[0].length > index) {
    const newOne: [string, boolean] = [r[0].slice(0, index + 1), r[1]]
    const newTwo: [string, boolean] = [stringToAppend, false]
    const newThree: [string, boolean] = [r[0].slice(index + 1), r[1]]
    return [newOne, newTwo, newThree]
  }

  return [r]
}

// TRUNCATE LONG LABELS
export const truncateText = (value: string, limit: number = 18) => {
  if (value.length < limit || !_.isString(value)) return value
  return `${value.substring(0, limit)}...`
}

export const formatStaffName = (value: string | undefined) => {
  if (!value) return ''
  return _.startCase(value.toLowerCase().replace('//none//', ''))
}
