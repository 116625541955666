import React from 'react'
import AllNoApptScheduledReasonSection from './AllNoApptScheduledReasonSection/AllNoApptScheduledReasonSection'

// STYLES
import { useStyles } from './AllNoApptScheduledReasonDrawer.style'

const AllNoApptScheduledReasonDrawer = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AllNoApptScheduledReasonSection />
    </div>
  )
}

export default AllNoApptScheduledReasonDrawer
