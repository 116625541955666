type Item = {
  recordType: string
  mediaType: string
  campaignType: string
}

export const formatCampaignReferralSourcesName = (item: Item) => {
  // CallPro or MVP Core
  if (item.recordType !== 'MVP Printed') {
    return `${item.recordType} - ${item.mediaType}`
  }

  // MVP Printed
  if (item.campaignType === 'Custom') {
    return `${item.recordType} - ${item.mediaType}`
  }

  return `${item.recordType} - ${item.campaignType}`
}