import { observable, computed, action, toJS } from 'mobx'

import getCallById from 'services/calls/getCallById'
import apiCreateAppointment from 'services/calls/createAppointment'
import apiResolveCall from 'services/calls/resolveCall'
import AppState from 'config/store/AppState'

import Appointment, { repository as appointmentsRepo } from './Appointment'
import Repository from './Repository'
import { ResolveFields } from 'interfaces/Call.interface'

type CampaignTypeMetadata = {
  recordType: string
  mediaType: string
  campaignType: string
}

export const repository = new Repository<Call>()

export enum Outcome {
  APPOINTMENT = 'Appointment',
  MISSED_CALL = 'Missed Call',
  FOLLOWUP = 'Follow-up Needed',
  CANCELLED = 'Cancelled',
  RESCHEDULED = 'Rescheduled',
  OPPORTUNITY = 'Opportunity',
  NON_OPPORTUNITY = 'Non-Opportunity',
}
export default class Call {
  // State
  @observable isLoading: boolean = false

  // Details
  @observable id: string
  @observable callDateTime?: string
  @observable callAudio?: string
  @observable callDuration?: number
  @observable callerPhoneNumber?: string
  @observable callerPhoneNumberRaw?: string
  @observable appointmentsCounter?: number
  @observable patientType?: string
  @observable highlights?: string
  @observable outcome?: Outcome[]

  @observable accountId?: string

  // Campaign info
  @observable campaignId?: string
  @observable campaignName?: string
  @observable campaignLabel?: string
  @observable campaignTargetDeliveryWeek?: string
  @observable campaignType?: string
  @observable campaignTypeDetails: CampaignTypeMetadata
  @observable campaignMediaType?: string
  

  appointments = observable.array<string>([])

  // Latest Score
  @observable scoreId?: string
  @observable callerName?: string
  @observable callerIdName?: string
  @observable opportunity?: boolean
  @observable callAnsweredBy?: string
  @observable handlerName?: string
  @observable voicemailLeftMessage?: boolean
  @observable voicemailWantsAppointment?: boolean
  @observable followUpOpportunity?: boolean
  @observable followUpReason?: string
  @observable resolveDate?: string
  @observable callbackHandler?: string
  @observable callbackOutcomeOpportunity?: string
  @observable callbackOutcomeResult?: string
  @observable callbackOutcomeSummary?: string
  @observable nonOpportunityReason?: string
  @observable noAppointmentScheduledReason?: string
  @observable ivrOutcome?: string
  @observable sourceMentioned?: string

  relatedCalls = observable.array<Call>([])
  offers = observable.array<any>([])
  accountOffers = observable.array<any>([])
  services = observable.array<any>([])
  insurances = observable.array<any>([])

  @observable isDirty?: boolean

  constructor(call: any) {
    this.id = call.id
    this.campaignTypeDetails = call.campaignTypeDetails
  }

  @action.bound
  tempCreateAppointment(appointment: Appointment) {
    const id = appointmentsRepo.upsert(appointment)

    if (!this.appointments.includes(id)) {
      this.appointments.push(id)
    }
  }

  @action.bound
  async resolveCall(data: ResolveFields) {
    const tempAppointments = this.appointments
      .map((id) => {
        return appointmentsRepo.get(id)
      })
      .filter((appt: Appointment) => appt.isTemp)

    await apiResolveCall({ appointments: tempAppointments, data })
  }

  @action.bound
  createAppointment(appointment: Appointment) {
    appointment.loading(true)
    const id = appointmentsRepo.upsert(appointment)

    if (!this.appointments.includes(id)) {
      this.appointments.push(id)
    }

    apiCreateAppointment(
      this,
      appointment.dateTime,
      appointment.firstName,
      appointment.lastName,
      appointment.scoreId
    )
      .then((newAppointment: Appointment) => {
        appointmentsRepo.replace(appointment, newAppointment)
        this.appointments.remove(appointment.id)
        this.appointments.push(newAppointment.id)

        AppState.successMessage(
          `New appointment for ${newAppointment.fullName} created`
        )
      })
      .catch(() => {
        appointment.loading(false)
        appointment.isError = true
      })
  }

  @action.bound
  loading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  @action.bound
  async update() {
    this.isLoading = true
    try {
      await getCallById(this.id)
      this.isLoading = false
    } catch (e) {
      /**
       * @todo Do something with the error
       */
      console.log(e)
    }
  }

  @action.bound
  deleteAppt(id: string) {
    this.appointments.remove(id)
  }

  getCallerNameMatched(exp: RegExp | undefined): string | undefined {
    if (typeof exp === 'undefined') {
      return this.callerName ?? this.callerIdNameFmt
    }

    if (exp.test(this.callerName ?? '')) {
      return this.callerName
    }

    if (exp.test(this.callerIdNameFmt ?? '')) {
      return this.callerIdNameFmt
    }

    return this.callerName
  }

  @computed
  get caller() {
    if (this.callerName) return this.callerName

    if (this.callerIdName) return this.callerIdNameFmt as string

    return this.callerPhoneNumber
  }

  @computed
  get callerIdNameFmt(): string | undefined {
    if (!this.callerIdName) return

    return this.callerIdName
      .trim()
      .split(' ')
      .map((s) => {
        if (!s) return ''

        return `${s[0].toUpperCase()}${s.slice(1).toLowerCase()}`
      })
      .join(' ')
  }

  @computed
  get accountName() {
    const account = AppState.accounts.find(
      ({ id }: { id: string }) => id === this.accountId
    )

    if (account === null || typeof account == 'undefined') {
      return null
    }

    return account.name
  }

  @computed
  get answeredBy() {
    const handler = this.handlerName
    const answeredBy = this.callAnsweredBy

    if (handler && answeredBy) {
      return `${handler}`
    }
    if (answeredBy) {
      return answeredBy
    }
    return ''
  }
}
