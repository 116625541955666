import { observable, computed, action } from 'mobx'

export default class PatientListLayer {
  @observable id
  @observable visible = false
  @observable icon = 'default'
  @observable markers = []

  @action toggleVisibility(visible) {
    this.visible = visible === undefined ? !this.visible : visible
  }
}
