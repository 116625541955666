// @flow

import _isEmpty from 'lodash/isEmpty'
import _has from 'lodash/has'
import API, { DATA_API } from 'src/helpers/api'
import InsuranceInquiry, {
  repository as insuranceInquiryRepo,
} from 'src/models/insurance/InsuranceInquiry'
import Insurance, {
  repository as insuranceRepo,
} from 'src/models/insurance/Insurance'

import accountStore from 'src/config/store/AccountStore'

/**
 *
 * @param {object} options
 */
export default async ({ filters }) => {
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    return {
      meta: {},
      records: [],
    }
  }

  const data = {
    accountIds,
    filters,
  }

  const result = await API.get({
    api: DATA_API,
    url: '/insurances/inquiries',
    params: data,
  })

  const sortedResult = result.sort((a, b) => {
    return a.Call__r.Call_Date__c - b.Call__r.Call_Date__c
  })

  const insurances = result.map((row) => {
    const insuranceInquiry = new InsuranceInquiry({
      id: row.Id,
      name: row.Name,
      callId: row.Call__c,
      insuranceId: row.Insurance_Provider__c,
      insuranceName: row.Insurance_Provider__r.Name,
      insuranceAcceptanceStatus: row.Insurance_Acceptance_Status__c,
    })
    const insurance = new Insurance({
      id: insuranceInquiry.insuranceId,
      name: insuranceInquiry.insuranceName,
    })

    insuranceRepo.upsert(insurance)
    return insuranceInquiryRepo.upsert(insuranceInquiry)
  })

  const startDate = sortedResult.length
    ? sortedResult[0].Call__r.Call_Date__c
    : ''

  return {
    startRecordDate: startDate,
    records: insurances,
  }
}
