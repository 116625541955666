import React from 'react'
import {
  Button,
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

interface Prop extends DialogProps {
  title?: string
  hideActions: boolean
  cancelLabel?: string
  confirmLabel?: string
  onCancel: () => void
  onConfirm: () => void
}

export default function ConfirmDialog({
  title,
  children,
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
  hideActions,
  ...props
}: Prop) {
  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      {...props}
    >
      {title ? (
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      ) : null}
      <DialogContent>{children}</DialogContent>
      {!hideActions && (
        <DialogActions>
          <Button onClick={onCancel} variant="outlined" color="primary">{!cancelLabel ? 'Cancel': cancelLabel}</Button>
          <Button onClick={onConfirm} variant="contained" color="primary">{!confirmLabel ? 'Ok': confirmLabel}</Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
