import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

import ScoreboardGrant from './ScoreboardGrant'

const Grants = () => {
  return (
    <Switch>
      <Route
        path="/grant/scoreboard"
        exact
        component={ScoreboardGrant}
      />
    </Switch>
  )
}

export default Grants
