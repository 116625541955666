import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  appDetailsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: '#F4F4F4',
    padding: '.85rem',
    paddingTop: '0',
  },

  apptDetails: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '0 0 10px 10px ',
    padding: '1rem',
  },

  title: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },

  timeline: {
    minHeight: '100px',
  },

  step: {
    margin: '.4rem 0',
    fontWeight: 'bold',
  },

  removeLeftDefaultContent: {
    '&:before': {
      flex: 0,
      padding: 0,
    },
  },

  dot: {
    color: '#fff',
    width: '2rem',
    height: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ED164E',
  },

  placeholder: {
    color: '#A6A6A6',
    fontSize: '.85rem',
  },

  fieldContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  },

  textarea: {
    '& .MuiInputBase-multiline': {
      padding: '0',
    },
  },

  dateTime: {
    color: '#ed154e',
    marginTop: '.5rem',
  },

  inputRoot: {
    width: '100%',
    fontSize: '.85rem',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ed154e',
      border: '1px solid #ed154e',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ed154e',
      border: '1px solid #ed154e',
    },
  },

  inputRoot2: {
    width: '50%',
    fontSize: '.85rem',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ed154e',
      border: '1px solid #ed154e',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ed154e',
      border: '1px solid #ed154e',
    },
  },

  datePicker: {
    marginBottom: 8,
    '& input': {
      background: '#fff !important',
      padding: '10.46px 14px',
    },
  },

  // TABLE
  table: {
    width: '100%',
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f6f5f5',
    },
  },

  tempStatus: {
    '& td': {
      fontWeight: 'bold',
    },
  },

  thead: {
    border: '1px solid #ddd',
    borderRight: 'none',
    padding: '.5rem',
    fontSize: '.85rem',
    // color: '#929292',

    width: '100%',
    alignItems: 'center',

    '&:last-child': {
      borderRight: '1px solid #ddd',
    },
  },

  td: {
    border: '1px solid #ddd',
    borderRight: 'none',
    borderTop: 'none',
    padding: '.5rem',
    fontSize: '.85rem',
    fontWeight: 'normal',

    width: '100%',
    alignItems: 'center',

    '&:last-child': {
      borderRight: '1px solid #ddd',
    },
  },

  row2: {
    display: 'grid',
    gridTemplateRows: '1.8rem 1fr',
    border: '1px solid #ddd',
    padding: '.5rem',
    fontSize: '.85rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
  },

  row2label: {
    fontSize: '.85rem',
    fontWeight: 'normal',
    // color: '#929292',
  },

  addBtn: {
    color: '#ED164E',
    padding: '.5rem',
    fontWeight: 'bold',
    border: '1px dashed #ed154e',
    marginTop: '1rem',
    width: '100%',
    backgroundColor: '#ffdbe4',
    borderRadius: '4px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#f0afbf',
    },
  },

  apptBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '.5rem',
  },

  positionBtns: {
    justifyContent: 'space-between',
  },

  cancelApptBtn: {
    color: '#858484',
    padding: '.5rem 1rem',
    fontWeight: 'bold',
    marginTop: '1rem',
    backgroundColor: '#ddd',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',

    '&:hover': {
      backgroundColor: '#c4c1c1',
    },
  },

  btnWrapper: {
    display: 'flex',
    gap: '.5rem',
  },

  addApptBtn: {
    color: '#fff',
    padding: '.5rem 2rem',
    fontWeight: 'bold',
    marginTop: '1rem',
    backgroundColor: '#ed154e',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',

    '&:hover': {
      backgroundColor: '#c61040',
    },
  },

  deleteApptBtn: {
    color: '#ff0000',
    padding: '.5rem 1.4rem',
    fontWeight: 'bold',
    marginTop: '1rem',
    backgroundColor: '#ffd8d8',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'none',

    '&:hover': {
      backgroundColor: '#ffb0b0',
    },
  },

  showBtn: {
    display: 'flex',
  },

  form: {
    display: 'flex',
    width: '100%',
    gap: '0.5rem',
    padding: '0.5rem 0',
    marginTop: '1rem',
  },

  errorLabel: {
    margin: 0,
    boxShadow: 'none',
    color: '#ed154e',
  },
})
