import AppState from 'src/config/store/AppState'
import API, { DATA_API } from 'src/helpers/api'
import { repository as callsRepo } from 'src/models/Call'

import processCallData from './processCallData'

/**
 *
 * @param {string} id the id of the call object to fetch
 *
 * @return {string} the id of the call object
 */
export default async (id) => {
  const accounts = AppState.accounts || []
  const accountIds = accounts.map((item) => item.id)

  const params = {
    accounts: accountIds,
    related: 1,
  }

  const result = await API.get({
    api: DATA_API,
    url: `/calls/${id}`,
    params,
  })

  const {
    // meta,
    records,
  } = result

  // We are only interested in the first record
  if (records[0]) {
    const call = processCallData(records[0])
    const mergeRelated = records[0] && records[0].related

    return callsRepo.upsert(
      call,
      (!mergeRelated && {
        skipMergeOnProps: ['relatedCalls'],
      }) ||
        undefined
    )
  }

  // If there is not first record available, assume that
  // the API was not able to find it
  throw new Error(`Call ${id} not found`)
}
