import React, { useState } from 'react'
import { Button } from '@material-ui/core'

import useStyles from './DownloadCallsButton.style'
import DownloadCallsDialog from 'components/CallPro/DownloadCallsButton/DownloadCallsDialog'

type Prop = {
  callCount?: number
  recipientEmail?: string
}

export default function DownloadCallsButton({ callCount = 0 }: Prop) {
  const styles = useStyles()
  const [open, setOpen] = useState(false)

  const isDataTooLarge = callCount > 10000

  const handleOpenDialog = (open: boolean) => () => {
    setOpen(open)
  }

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        classes={{
          root: styles.buttonRoot,
          label: styles.buttonLabel,
          outlinedPrimary: styles.buttonOutlinedPrimary,
        }}
        onClick={handleOpenDialog(true)}
      >
        Download
      </Button>
      <DownloadCallsDialog
        open={open}
        setOpen={setOpen}
        requestTooLarge={isDataTooLarge}
      />
    </>
  )
}
