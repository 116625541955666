import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #ebe7e7',
    borderTop: '1px solid #ebe7e7',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fbfbfb',
    flexDirection: 'column-reverse',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  tabsRoot: {
    height: '64px',
    padding: '0 1.5rem',
    '&.MuiTabs-flexContainer': {
      flex: 1,
    },
  },
  tabsFlexContainer: {
    flex: 1,
    '&.MuiTab-root': {
      flex: 1,
    },
  },
  tabsScroller: {
    display: 'flex',
    color: '#000',
  },

  tabItem: {
    height: '100%',
    textTransform: 'capitalize',
    fontSize: '.95rem',
    fontWeight: 'bold',
  },

  filters: {
    display: 'flex',
    padding: '1rem 1.5rem',
    gap: '.5rem',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '24px',
      padding: '0',
    },
  },
}))

export default useStyles
