import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    color: '#262626',
    // backgroundColor: theme.palette.background.default,
  },
  tabs: {
    '& .MuiTabs-scrollButtonsDesktop': {
      [theme.breakpoints.down('md')]: {
        display: 'inherit',
      },
    },
    '& .MuiTabs-scrollButtons': {
      transition: '0.3s width',
      '&.Mui-disabled': {
        width: 0,
      },
    },
  },
  indicator: {
    backgroundColor: '#ed154e',
  },
  label: {
    textTransform: 'none',
    maxWidth: '16rem',
    fontWeight: 'bold',
  },
  active: {
    color: '#ed154e',
    fontWeight: 'bold',
    backgroundColor: '#FADCE5',
  },
}))
