import React, { Component } from 'react'
import { observer } from 'mobx-react'
import numeral from 'numeral'
import moment from 'moment'
import onClickOutside from 'react-onclickoutside'

import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import CloseIcon from '@material-ui/icons/Close'

import MapsStore from 'src/config/store/MapsStore'
import { repository as Mailings } from 'src/models/Mailing'
import MailingZone from 'src/models/MailingZone'
import MailingArea from 'src/models/MailingArea'
import { defaultFont } from 'src/assets/jss/mvp'

const styles = theme => ({
  root: {
    ...defaultFont,
    position: 'absolute',
    zIndex: 999,
    width: 300,
    background: '#fff',
    padding: 12,
    paddingTop: 8,
    '& header': {
      fontWeight: 600,
      fontSize: 15,
      paddingBottom: 8,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    }
  },
  zoneColor: {
    width: 13,
    height: 13,
    background: 'red',
    display: 'inline-block',
    marginRight: '8px'
  },
  mailingWrapper: {
    display: 'flex',
    marginTop: 12,
    marginBottom: 18,
    '& img': {
      width: 85,
      height: 47,
      marginRight: 8
    }
  },
  mailing: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 12,
    '& h4': {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.2,
      padding: '2px 0'
    },
    '& span': {
      fontSize: 12,
      color: 'rgb(138,137,138)'
    }
  },
  mailingDetails: {
    padding: 0,
    '& p': {
      margin: '0px 0px 8px',
      fontSize: 13,
      lineHeight: 1
    },
    '& p:last-child': {
      marginBottom: 0
    },
    '& span': {
      color: 'rgb(225,40,61)',
      fontWeight: 600
    }
  },
  close: {
    marginLeft: 'auto',
    width: 24,
    height: 24
  },
  closeIcon: {
    width: 16,
    height: 16,
    top: -8,
    position: 'relative'
  },
  hidden: {
    display: 'none'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    '& th': {
      fontSize: '12px',
      fontWeight: 700,
      backgroundColor: '#232323',
      color: '#fff',
      padding: 4,
      border: '1px solid #d3d3d3'
    },
    '& td': {
      backgroundColor: '#f9f9f9',
      textAlign: 'center',
      fontSize: '12px',
      fontWeight: 700,
      padding: 4,
      border: '1px solid #d3d3d3'
    }
  },
  stackedImage: {
    position: 'relative',
    top: 8,
    left: 10,
    '& *': {
      position: 'absolute',
      backgroundSize: 'contain',
      width: 65,
      height: 36
    },
    '& *:last-child': {
      position: 'relative',
      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);'
    },
    '& *:nth-child(1)': {
      top: -8,
      left: -8
    },
    '& *:nth-child(2)': {
      top: -4,
      left: -4
    }
  }
})

@withStyles(styles)
@onClickOutside
@observer
export default class InfoBox extends Component {

  handleClose = () => {
    MapsStore.showInfoBox = false
    MapsStore.resetDefault()
  }

  handleClickOutside = () => {
    if (MapsStore.showInfoBox) {
      this.handleClose()
    }
  }

  render() {
    const { classes, ...props } = this.props

    /** @var {MailingZone} item */
    let item = MapsStore.infoBoxItem.get()
    if (!item || (!(item instanceof MailingZone) && !(item instanceof MailingArea))) {
      // Fallback
      item = new MailingZone()
    }

    const mailings = item instanceof MailingZone ? item.mailings.map(id => Mailings.get(id)) : []

    return (
      <Paper
        className={classes.root}
        elevation={0}
        square
        style={{ display: MapsStore.showInfoBox ? undefined : 'none' }}
      >
        <header>
          <span className={classes.zoneColor} style={{ backgroundColor: item.color }}/>
          <span>{item.name}</span>
          <IconButton className={classes.close} aria-label="Close" onClick={this.handleClose}>
            <CloseIcon className={classes.closeIcon}/>
          </IconButton>
        </header>

        {
          mailings.length ?

            <div className={classes.mailingWrapper}>
              <div className={classes.stackedImage}>
                {
                  mailings.map((mailing, index) => {
                    return (
                      index < 3 ?
                        <img
                          key={mailing.id}
                          style={{
                            backgroundImage: `url(${mailing.frontDesignThumbnailUrl})`,
                            backgroundColor: '#ededed'
                          }}
                        /> : false
                    )
                  })
                }
              </div>
              <div className={classes.mailing}>
                <h4>{item.mailings.length} Mailings</h4>
                <span>{numeral(item.quantity).format('0,0')} pcs</span>
              </div>
            </div> :

            null
        }

        <div className={classes.mailingDetails}>
          <table className={classes.table}>
            <thead>
            <tr>
              <th width="32.5%">Date</th>
              <th width="22.5%">Leads</th>
              <th width="22.5%">Appts</th>
              <th width="22.5%">Conv</th>
            </tr>
            </thead>
            <tbody>
              {
                mailings.length ?
                  mailings.map(mailing => {
                    /** @var {Mailing} mailing */
                    return (
                      <tr key={mailing.id}>
                        <td>{mailing.deliveryWeek ? moment(mailing.deliveryWeek).format('D MMM YYYY') : '--'}</td>
                        <td>{mailing.leadsCount ? mailing.leadsCount : '--'}</td>
                        <td>{mailing.appointmentsCount ? mailing.appointmentsCount : '--'}</td>
                        <td>{mailing.conversionRate ? numeral(mailing.conversionRate / 100).format('0%') : '--'}</td>
                      </tr>
                    )
                  }) :
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'left' }}>No mailings found ...</td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
      </Paper>
    )
  }
}
