import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { observer } from 'mobx-react'
import cx from 'classnames'
import moment from 'moment'
import _difference from 'lodash/difference'
import _indexOf from 'lodash/indexOf'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton/IconButton'
import ExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary'
import ExpandIcon from '@material-ui/icons/ExpandMore'
import ColorPickerIcon from '@material-ui/icons/Stop'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'

import MailingArea from 'src/models/MailingArea'
import MapsStore from 'src/config/store/MapsStore'
import { repository as MailingZones } from 'src/models/MailingZone'
import BodyEnd from 'src/components/MVP/Helpers/BodyEnd'
import ColorPicker from 'src/components/Maps/ColorPicker'
import MailingZoneCard from './MailingZoneCard'

const COLOR_OPTIONS = [
'#F50D0D',
'#F77935',
'#FFA200',
  '#FFD600',
  '#0FBB58',
  '#1AC4AD',
  '#009AEE',
  '#007CBA',
  '#5117EE',
  '#B605D5',
  '#FC4491',
  '#666666'
]

const styles = theme => ({
  panelExpanded: {
    marginTop: '0 !important'
  },
  panelSummaryContent: {
  },

  panelSummaryExpanded: {
    '&$panelSummaryContent': {
      margin: '12px 0'
    }
  },

  panelSummaryExpandIcon: {
    position: 'absolute !important',
    right: 8,
    marginRight: 0,
  },

  subAccordionItem: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    marginBottom: '0px !important',
    marginTop: 0,
    padding: 0,
    '& >span': {
      width: '20px',
      height: '20px',
    },
    '&:before': {
      borderBottom: 'solid 1px rgb(236,235,236)',
      background: 'none'
    }
  },

  subPanelSummary: {
    display: 'flex',
    width: '100%',
    minHeight: '60px !important',
    paddingLeft: 6,
    paddingRight: 0,
    position: 'relative',
    '& >div': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  subPanelActive: {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      width: 4,
      height: '100%',
      background: 'rgb(229,8,62)'
    }
  },
  subAccordionItemSummary: {
    flex: '1 0 auto',
    marginLeft: 0,
    '& h5': {
      lineHeight: '1rem',
      fontSize: '15px'
    },
    '& span': {
      fontSize: '13px',
      color: 'rgb(136,136,136)'
    }
  },

  areaColorWrapper: {
    position: 'relative',
    width: 56
  },

  subAccordionBody: {
    position: 'relative',
    marginBottom: '-14px',
    width: '100%',
    marginTop: '-8px',
    '&:before': {
      content: '""',
      width: 'calc(100% + 43px)',
      position: 'absolute',
      height: '1px',
      background: 'rgb(236,235,236)',
      top: '0px',
      left: '-22px',
    },
  },

  collapseContainer: {
    width: '100%'
  },

  panelDetails: {
    paddingRight: 0,
    paddingLeft: 0
  },

  expandCollapse: {
    color: '#ED154E'
  },

  emptyZone: {
    marginTop: 0,
  },

  loader: {
    display: 'flex',
    flex: '1 0 auto',
    justifyContent: 'space-around',
    padding: 10
  },

  checkbox: {
    width: 48,
    height: 48
  },
})

const mailingAreaDateRange = mailingArea => {
  const from = mailingArea.mailingDateFrom
  const to = mailingArea.mailingDateTo

  if (!from && !to) {
    return 'No mailing dates'
  }

  if (!from) {
    return `To ${moment(mailingArea.mailingDateFrom).format('MMM YYYY')}`
  } else if (!to) {
    return `From ${moment(mailingArea.mailingDateFrom).format('MMM YYYY')}`
  }

  return `${moment(mailingArea.mailingDateFrom).format('MMM YYYY')} - ${moment(mailingArea.mailingDateTo).format('MMM YYYY')}`
}

@withStyles(styles)
@observer
export default class MailingAreaPanel extends React.Component {

  state = {
    showColorPicker: new Map(),
    colorPicker: {},
    positionX: null,
    positionY: null
  }

  constructor(props) {
    super(props)
    this.pickerContainerRef = React.createRef()
  }

  /**
   *
   */
  handleColorPicker = zoneId => event => {
    event.stopPropagation()
    event.preventDefault()
    const showColorPicker = this.state.showColorPicker.set(zoneId, true)

    let positionX = event.pageX
    let positionY = event.pageY
    if (this.pickerContainerRef.current) {
      const rect = this.pickerContainerRef.current.getBoundingClientRect()
      positionX = rect.left
      positionY = rect.top + rect.height / 2
    }
    this.setState({
      showColorPicker,
      positionX,
      positionY
    })
  }

  /**
   *
   */
  handleCloseColorPicker = zoneId => () => {
    const showColorPicker = this.state.showColorPicker.set(zoneId, false)
    this.setState({ showColorPicker })
  }


  render() {
    const {
      classes,
      mailingArea,
      expanded,
      onToggleArea,
      onToggleZone,
      onTogglePanel
    } = this.props

    const {
      showColorPicker,
      positionX,
      positionY
    } = this.state

    const selectedGeodataIds = MapsStore.selectedGeodataIds.slice()

    if (MapsStore.isLoading) {
      return (
        <div className={classes.loader}>
          <CircularProgress size={26} color="primary" />
        </div>
      )
    }

    const hasZones = mailingArea.zones.length > 0
    const mailingZones = mailingArea.zones.map(id => MailingZones.get(id))
    let checked = false
    let indeterminate = false
    let active = false

    let zoneIds = []
    if (hasZones) {
      zoneIds = mailingZones.map(zone => zone.geodataId)
      const notSelected = _difference(zoneIds, selectedGeodataIds)
      if (notSelected.length === 0) {
        // All zones have been checked
        checked = true
        active = true
      } else if (notSelected.length < zoneIds.length) {
        checked = true
        indeterminate = true
        active = true
      }
    } else {
      checked = (_indexOf(selectedGeodataIds, mailingArea.geodataId) !== -1)
    }

    return (
      <ExpansionPanel
        classes={{
          expanded: classes.panelExpanded,
        }}
        className={classes.subAccordionItem}
        expanded={expanded}
        TransitionProps={{
          classes: {
            container: classes.collapseContainer
          }
        }}
      >
        <ExpansionPanelSummary
          classes={{
            root: cx({
              [ classes.subPanelSummary ]: true,
              [ classes.subPanelActive ]: active
            }),
            expanded: classes.panelSummaryExpanded,
            content: classes.panelSummaryContent,
            expandIcon: classes.panelSummaryExpandIcon
          }}
          onClick={(event) => onTogglePanel(event, mailingArea.id)}
          expandIcon={
            hasZones ?
              <ExpandIcon
                className={classes.expandCollapse}
              /> :
              false
          }
        >
          <Checkbox className={classes.checkbox}
                    color="primary"
                    checked={checked}
                    indeterminate={indeterminate}
                    onClick={onToggleArea}
                    value={mailingArea.id || null}
          />
          <div className={classes.subAccordionItemSummary}>
            <h5>{mailingArea.name}</h5>
            {
              hasZones ?
                <span>
                  {mailingAreaDateRange(mailingArea)}
                    {' '}&bull;{' '}
                    {`${mailingArea.zones.length} zones`}
                </span> :
                <span className={classes.emptyZone} />
            }
          </div>
          {
            hasZones ?
              null :
              <div className={classes.areaColorWrapper} ref={this.pickerContainerRef}>
                {
                  showColorPicker.get(mailingArea.id) ?
                    <BodyEnd>
                      <ColorPicker
                        close={this.handleCloseColorPicker(mailingArea.id)}
                        item={mailingArea}
                        colorOptions={COLOR_OPTIONS}
                        position={{ x: positionX, y: positionY }}
                      />
                    </BodyEnd> :
                    false
                }
                <IconButton
                  onClick={this.handleColorPicker(mailingArea.id)}
                  className={classes.areaColor}
                >
                  <ColorPickerIcon style={{ color: mailingArea.color }} />
                </IconButton>
              </div>
          }
        </ExpansionPanelSummary>
        {
          hasZones ?
            <ExpansionPanelDetails className={classes.panelDetails}>
              <div className={classes.subAccordionBody}>
                {
                  mailingZones.map(zone => {
                    return (
                      <MailingZoneCard
                        key={zone.id}
                        zone={zone}
                        onToggleZone={onToggleZone}
                      />
                    )
                  })
                }
              </div>
            </ExpansionPanelDetails> :
            null
        }
      </ExpansionPanel>
    )
  }
}

MailingAreaPanel.propTypes = {
  mailingArea: PropTypes.instanceOf(MailingArea),
  expanded: PropTypes.bool,
  onTogglePanel: PropTypes.func,
  onToggleArea: PropTypes.func,
  onToggleZone: PropTypes.func
}

MailingAreaPanel.defaultProps = {
  expanded: false,
  onTogglePanel: () => {},
  onToggleArea: () => {},
  onToggleZone: () => {}
}

