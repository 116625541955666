import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import CallDetails from '../CallDetails/CallDetails'
import ResolveDetails from '../ResolveDetails/ResolveDetails'
import AppointmentForm from '../AppointmentForm/AppointmentForm'
import Call from 'models/Call'

// STYLES
import { useStyles } from './ResolveCallDetailsWrapper.style'
import { CircularProgress } from '@material-ui/core'
import { useStores } from 'config/store'
import { Observer } from 'mobx-react'
import { ResolveFields } from 'interfaces/Call.interface'
import appState from 'config/store/AppState'
import useSidePanel from 'helpers/hooks/useSidePanel'

const ResolveCallDetailsWrapper = () => {
  const classes = useStyles()
  const [validateForm, setValidateForm] = useState<number>(0)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { callsStore } = useStores()
  const { handleCloseDrawer } = useSidePanel()

  const handleSubmit = () => {
    const inc = validateForm ?? 0
    setValidateForm(inc + 1)
  }

  const handleValidateFields = (
    isValid: boolean,
    data: ResolveFields,
    call: Call | null
  ) => {
    if (isValid && call) {
      setSubmitting(true)
      call
        .resolveCall({
          ...data,
          callId: call.id,
          scoreId: call.scoreId,
        })
        .then(() => {
          //TODO: Find a way to force re-render OverviewMissedAndFollowUpCalls.tsx component to update
          // the table w/o refetching
          callsStore.refreshOverviewTab()
        })
        .finally(() => {
          setSubmitting(false)
          handleCloseDrawer()
        })
    } else {
      appState.errorMessage('Please complete the required fields.')
    }
  }

  return (
    <Observer
      render={() => {
        const { callStore } = useStores()
        const call = callStore.drawer

        return !call && callStore.isLoading ? (
          <div className={classes.loader}>
            <div className={classes.loaderContent}>
              <CircularProgress size={60} color="primary" />
              <div className={classes.loaderCaption}>Loading details...</div>
            </div>
          </div>
        ) : (
          <div className={classes.callDetailsWrapper}>
            <CallDetails call={call} />
            <ResolveDetails
              validateForm={validateForm}
              formData={(isValid, data) =>
                handleValidateFields(isValid, data, call)
              }
            />
            <AppointmentForm />

            <div className={classes.buttonWrapper}>
              <Button
                disabled={submitting}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSubmit}
              >
                {submitting ? 'Saving...' : 'Resolve Call'}
              </Button>
            </div>
          </div>
        )
      }}
    />
  )
}

export default ResolveCallDetailsWrapper
