import React, { useState } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { runInAction } from 'mobx'
import { Link, CircularProgress } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { dateFormat } from 'config/callpro.config'
import { useStores } from 'config/store'
import { CallDashboardTabs, CallOutcome } from 'interfaces/Call.interface'

import { LinearProgress } from '../LinearProgress'
import { ChartCard } from 'components/CallPro/ChartCard'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import { useStyles } from './TopCampaignSources.style'
import CallDetailsDrawer from 'components/CallPro/SideDrawer/CallDetailsDrawer/CallDetailsDrawer'
import AllTopCampaignSourcesDrawer from '../SideDrawer/AllTopCampaignSources/AllTopCampaignSourcesDrawer'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'

// helpers
import { formatCampaignReferralSourcesName } from 'helpers/campaignSources'

interface TopCampaignSourcesInterface {
  isAccordionContent?: boolean
}

const TopCampaignSources = (props: TopCampaignSourcesInterface) => {
  const { isAccordionContent } = props
  const { callsStore, dashboardUIStore } = useStores()
  const classes = useStyles()
  const { topCampaignSource: _campaignType, loadingStates } = callsStore
  const campaignType = _campaignType.records
  const firstRecordDate = _campaignType.firstRecordDate

  const total = campaignType.reduce(
    (accumulator, currentElement) => accumulator + currentElement.value,
    0
  )
  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleToggleDrawer = () => setDrawerOpen((value) => !value)

  const handleItemClick = (item: (typeof campaignType)[number]) => () => {
    runInAction(() => {
      const tab = CallDashboardTabs.ALL_CALLS
      const outcome = CallOutcome.ALL_CALLS
      dashboardUIStore.setCurrentTabbedOpened(tab)
      callsStore.setFilters({
        outcome,
        campaignType: [item.campaignType],
        mediaType: [item.mediaType],
      })
      callsStore.setPaginationPage(1)
      callsStore.refreshAllCallsTab()
    })
  }

  // TOOLTIP CONTENT
  const tooltipContent = (
    <div className={classes.metricTooltip}>
      <div className={classes.tooltipTitle}>Click to see the full list</div>
    </div>
  )

  return (
    <>
      <ChartCard
        headerTitle="TOP CAMPAIGN REFERRAL SOURCES"
        tooltipText="The top campaign media sources that led callers to contact you."
        headerTotalText="Total Calls"
        headerTotalValue={total}
        isAccordionContent={isAccordionContent}
      >
        {loadingStates.topCampaignSource ? (
          <div
            style={{
              textAlign: 'center',
              padding: '2rem',
            }}
          >
            <CircularProgress />
          </div>
        ) : campaignType.length > 0 ? (
          <>
            {campaignType.slice(0, 5).map((reason, key) => {
              return (
                <LinearProgress
                  key={`item-${key}`}
                  value={reason.value}
                  total={total}
                  label={
                    <Link
                      component="button"
                      variant="body1"
                      underline="none"
                      color="initial"
                      onClick={handleItemClick(reason)}
                      classes={{ root: classes.link }}
                    >
                      {formatCampaignReferralSourcesName(reason)}
                    </Link>
                  }
                />
              )
            })}
            <div className={classes.footer}>
              <div className={classes.container}>
                <div className={classes.infoContainer}>
                  <div className={classes.info}>
                    <CustomTooltip content={tooltipContent}>
                      <div
                        className={classes.infoDetail}
                        onClick={handleToggleDrawer}
                      >
                        All Campaign Referral Sources (
                        {_campaignType.records.length})
                      </div>
                    </CustomTooltip>
                  </div>
                </div>
              </div>
              {firstRecordDate.length ? (
                <div className={classes.disclaimer}>
                  <InfoOutlinedIcon className={classes.disclaimerIcon} />
                  The data used in this graph is derived from the calls we
                  scored since {moment(firstRecordDate).format(dateFormat)}.
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <EmptyBarChart />
        )}
      </ChartCard>
      <CallDetailsDrawer
        drawerOpen={drawerOpen}
        handleCloseDrawer={handleToggleDrawer}
        title="All Campaign Referral Sources"
      >
        <AllTopCampaignSourcesDrawer />
      </CallDetailsDrawer>
    </>
  )
}

export default observer(TopCampaignSources)
