import React from 'react'
import { observer } from 'mobx-react'

import { default as DumbCard } from '../../Cards/Card'
import Grid from '@material-ui/core/Grid'
import { useStores } from 'config/store'
import { truncateText } from '../../../helpers/text'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import EmptyBarChart from '../EmptyCharts/EmptyBarChart'
import Skeleton from '@material-ui/lab/Skeleton'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ToolTip from '@material-ui/core/Tooltip'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'

const CuztomizedLabel = (props: any) => {
  const { x, y, fill, value } = props
  return (
    <text
      x={x + 45}
      y={y}
      dy={16}
      fontSize="14"
      fontFamily="sans-serif"
      fill={'white'}
      textAnchor="middle"
    >
      {value}%
    </text>
  )
}

export const useStyles = makeStyles({
  legend: {
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& p': {
      position: 'relative',
      paddingLeft: 17,

      '&:first-child:before': {
        background: '#4398E7',
      },

      '&:before': {
        content: '""',
        width: 10,
        height: 10,
        position: 'absolute',
        top: 3,
        display: 'inline-block',
        left: 0,
      },
      fontSize: 14,
      marginBottom: 0,
      marginTop: 16,
      color: '#818181',
    },
  },
  tooltip: {
    backgroundColor: '#222',
    '&::after': {
      display: 'none',
    },
  },

  grade_icon: {
    color: '#d3d3d3',
    verticalAlign: 'middle',
  },

  mobileCardHeading: {
    '& span': {
      fontSize: 16,
    },
    '& $grade_icon': {
      height: 20,
      width: 20,
    },
  },
})

const ConversionRateChart = () => {
  const { frontDeskStore } = useStores()
  const { leaderBoardByConversionRate, loadingStates } = frontDeskStore
  const classes = useStyles()
  const maxBarGraphLength = 10
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  const renderConversionRateChart = (): any => {
    const filteredLeaderboard = leaderBoardByConversionRate.filter((e: any) => {
      return e.staffType !== null && e.conversionRate > 0
    })

    const getContainerWidth = (width: number) => {
      return filteredLeaderboard.length > maxBarGraphLength
        ? filteredLeaderboard.length * width
        : '100%'
    }

    const mobileContainerWidth = getContainerWidth(150)
    const containerWidth = getContainerWidth(100)

    return (
      <div
        style={{
          overflowX:
            filteredLeaderboard.length > maxBarGraphLength || mobileDevice
              ? 'scroll'
              : 'hidden',
          overflowY: 'hidden',
        }}
      >
        <ResponsiveContainer
          width={mobileDevice ? mobileContainerWidth : containerWidth}
          height={250}
        >
          <BarChart
            data={filteredLeaderboard}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis
              dataKey="name"
              tickFormatter={(value: any, index: number) => {
                return truncateText(value)
              }}
              tick={{
                fontSize:
                  leaderBoardByConversionRate.length > maxBarGraphLength
                    ? 10
                    : 14,
              }}
              interval={0}
            />
            <YAxis domain={[0, 100]} />
            <Tooltip />
            <Bar
              dataKey="conversionRate"
              fill="#4498E7"
              barSize={80}
              name="Conversion Rate"
              label={<CuztomizedLabel />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }

  // MAIN FUNCTION
  return (
    <DumbCard
      title={
        <ToolTip
          id="all-calls"
          title='These are the average rates at which each staff converts answered opportunity calls into scheduled appointments.'
          placement="bottom-start"
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <div className={classes.mobileCardHeading}>
            <span>Conversion Rate By Staff </span>
            {!mobileDevice ? (
              <InfoOutlinedIcon className={classes.grade_icon} />
            ) : null}
          </div>
        </ToolTip>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <>
            {loadingStates.leaderBoardByConversionRate ? (
              <Skeleton
                variant="rect"
                height={250}
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
              />
            ) : (
              <>
                {leaderBoardByConversionRate.length > 0 ? (
                  renderConversionRateChart()
                ) : (
                  <EmptyBarChart />
                )}
                <div className={classes.legend}>
                  <p>Conversion rate</p>
                </div>
              </>
            )}
          </>
        </Grid>
      </Grid>
    </DumbCard>
  )
}

export default observer(ConversionRateChart)
