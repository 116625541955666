import React from 'react'
import cx from 'classnames'

import withStyles from '@material-ui/core/styles/withStyles'
import Avatar from '@material-ui/core/Avatar'

import {
  PermPhoneMsg as UnsuccessfulIcon
} from '@material-ui/icons'

import {
  defaultFont
} from 'src/assets/jss/mvp'
import medal from 'src/assets/img/medal.png'

const styles = theme => ({
  badge: {
    ...defaultFont,
    borderRadius: 0,
    width: 30,
    height: 30,
    fontSize: '12px',
    fontWeight: 'bold',
    paddingTop: 12,
    paddingLeft: 2,
    backgroundColor: 'transparent',
    backgroundImage: `url(${medal})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    '& span': {
      position: 'relative',
      top: '-2px'
    }
  },
  icon: {
    width: 24,
    height: 24,
    verticalAlign: 'middle'
  },
  successful: {
    color: '#FFCC33'
  },
  unsuccessful: {
    color: '#727272'
  }
})

const CallsBadge = ({ classes, content,...props }) => (
  content > 0 ?
    <Avatar
      classes={{
        root: classes.badge
      }}
    ><span>{content}</span></Avatar> :
    <UnsuccessfulIcon className={cx(classes.icon, classes.unsuccessful)} />
)

export default withStyles(styles)(CallsBadge)
