import { useCallback, useState, useEffect } from 'react'
import { ReviewCommentHandlers, CommentMarker } from '@mvp/mvp-react'

import AppState from 'config/store/AppState'
import { postReviewComment } from 'services/designs/postReviewComment'
import { updateReviewComment } from 'services/designs/updateReviewComment'
import { deleteReviewComment } from 'services/designs/deleteReviewComment'
import { approveCampaignMedia } from 'services/mailings/approveCampaignMedia'
import { rejectCampaignMedia } from 'services/mailings/rejectCampaignMedia'

interface UseHandlers {
  campaign: any
  design: any
  initialComments: CommentMarker[]
}

export default ({
  campaign,
  design,
  initialComments
}: UseHandlers) => {
  const [commentCount, setCommentCount] = useState(0)

  useEffect(() => {
    setCommentCount(initialComments.length)
  }, [initialComments])

  const handlers = useCallback((): ReviewCommentHandlers => {
    return {
      postReviewComment: async (args: any) => {
        const reviewComment = await postReviewComment({
          designId: design.id,
          ...args,
        })
        setCommentCount(commentCount + 1)
        return reviewComment
      },
      updateReviewComment: async (id: string, updates: any) => {
        const reviewComment = await updateReviewComment(id, updates)
        return reviewComment
      },
      deleteReviewComment: async (id: string) => {
        await deleteReviewComment({ id })
        setCommentCount(commentCount - 1)
      },
      submitDesignReview: async () => {
        try {
          const id = campaign.id
          if (commentCount) {
            await rejectCampaignMedia({ id })
          } else {
            await approveCampaignMedia({ id })
          }
        } catch (e) {
          AppState.errorMessage(
            'Something went wrong while submitting the design.'
          )
          throw e
        }
      },
      acceptReviewComment: async () => { },
      rejectReviewComment: async () => { },
      submitRevisions: async () => { },
      refreshDesign: async () => { },
      campaignMediaApprove: async () => { },
      campaignMediaReject: async () => { }
    }
  }, [design, commentCount])

  return handlers()
}
