import _isEmpty from 'lodash/isEmpty'

import accountStore from 'src/config/store/AccountStore'
import API, { DATA_API } from 'src/helpers/api'

/**
 *
 * @param {object} options
 */
export default async ({ filters, cancelToken }) => {
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    return {
      all: 0,
      leads: 0,
      appointments: 0,
      conversionRate: 0,
      missed: 0,
      followUps: 0,
    }
  }

  const data = {
    accounts: accountIds,
    filters,
  }

  const result = await API.get({
    api: DATA_API,
    url: '/mailings/summary',
    params: data,
    cancelToken: cancelToken && cancelToken.token,
  })

  const { all, leads, appointments, conversionRate, missed, followUps } = result

  return {
    all,
    leads,
    appointments,
    conversionRate,
    missed,
    followUps,
  }
}
