import { StaffInterface } from 'interfaces/Call.interface'

interface getCallHandlerOutput {
  callHandlerId: string | null
  answeredBy: any
}

const getCallHandler = (handler: StaffInterface | null): getCallHandlerOutput => {
  const callHandlerId = handler && handler.id != 'nonStaff' ? handler.id : null
  let answeredByType = handler && handler.id == 'nonStaff' ? handler.name : null

  if (handler && handler.id == 'nonStaff' && handler.name === 'Unknown') {
    answeredByType = null
  }

  return { callHandlerId, answeredBy: answeredByType }
}

export default getCallHandler
