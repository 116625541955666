import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    fontFamily: 'Roboto',
  },
  paper: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  toBack: {
    'z-index': '0 !important',
  },
  fullWidth: {
    width: '100%',
  },
})
