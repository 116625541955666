import React from 'react'

import { observer } from 'mobx-react'

import Card from '@material-ui/core/Card'

import MailingCardInfo from 'components/Mailings/MailingCardInfo'
import MailingCardActions from 'components/Mailings/MailingCardActions'
import DeliveredMailingCardInfo from 'components/Mailings/DeliveredMailingCardInfo'
import DeliveredMailingCardActions from 'components/Mailings/DeliveredMailingCardActions'
import AuditedMailingCardInfo from 'components/Mailings/AuditedMailingCardInfo'
import MailingThumbnail from 'components/Mailings/MailingThumbnail/MailingThumbnail'
import MailingTextInfo from './MailingTextInfo'

import Mailing from 'models/Mailing'

const MailingCard: React.FC<{
  index: number
  mailing: Mailing
  onViewAction: (data: Mailing) => void
}> = ({ index, mailing, onViewAction }) => {
  const handleAction = (action: string, mailing: Mailing) => {
    if (action === 'view') {
      onViewAction(mailing)
    }
  }

  const isDelivered = ['delivered', 'deliveredInFirstCall'].includes(
    mailing.status ?? ''
  )
  const isAudited = mailing.matchedNewPatients > 0

  return (
    <Card variant="outlined">
      <MailingCardInfo mailing={mailing} index={index} />
      <MailingThumbnail mailing={mailing} onAction={handleAction} />
      <MailingTextInfo mailing={mailing} />

      {isDelivered ? (
        isAudited ? (
          <AuditedMailingCardInfo mailing={mailing} />
        ) : (
          <DeliveredMailingCardInfo mailing={mailing} />
        )
      ) : (
        <></>
      )}

      {isDelivered ? (
        <DeliveredMailingCardActions
          mailing={mailing}
          onAction={handleAction}
        />
      ) : (
        <MailingCardActions mailing={mailing} onAction={handleAction} />
      )}
    </Card>
  )
}

export default observer(MailingCard)
