import API, { IDENTITY_API } from '../../helpers/api'

const updatePassword = async (data: {
  oldPassword: string
  newPassword: string
}) => {
  await API.put({
    api: IDENTITY_API,
    url: 'user/password',
    data: {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    },
  })
  return true
}

export default updatePassword
