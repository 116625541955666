import React, { useEffect, useState } from 'react'
import Img from 'react-image'

import { observer } from 'mobx-react'
import { useStyles } from './MailingDesignPreview.styles'
import { useStores } from 'config/store'

import CircularProgress from '@material-ui/core/CircularProgress'
import { FormatAlignLeft, ErrorOutline, Close } from '@material-ui/icons'

// @ts-ignore
import BodyEnd from '../../../../../MVP/Helpers/BodyEnd'
import moment from 'moment'

interface IMailingDesignPreview {
  closePreview: () => void
  previewFace: 'front' | 'back' | string
  isSingleDesign?: boolean
}

const formatDate = (date: string | undefined) => {
  const week = moment(date)

  return week.isValid() ? week.format('MM/DD/YYYY') : '-'
}

const camelCaseToWords = (data: string | undefined = '') => {
  const result = data.replace(/([A-Z])/g, ' $1')
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1)

  return finalResult
}

export const MailingDesignPreview = (props: IMailingDesignPreview) => {
  const { closePreview, previewFace, isSingleDesign } = props
  const classes = useStyles()
  const { mailingsStore } = useStores()
  const [face, setFace] = useState({
    frontActive: true,
    backActive: false,
  })

  const { frontActive, backActive } = face

  const handleClose = () => {
    closePreview()
  }

  const handleFront = () => {
    setFace({
      ...face,
      frontActive: true,
      backActive: false,
    })
  }

  const handleBack = () => {
    setFace({
      ...face,
      frontActive: false,
      backActive: true,
    })
  }

  useEffect(() => {
    if (previewFace === 'front') {
      setFace({
        ...face,
        frontActive: true,
        backActive: false,
      })
    } else {
      setFace({
        ...face,
        frontActive: false,
        backActive: true,
      })
    }
  }, [])

  const mailing = mailingsStore.drawer

  if (!mailing) {
    return <></>
  }

  const mailingDate = mailing.deliveryWeek
    ? moment(mailing.deliveryWeek).format('MMM D')
    : ''

  return (
    <BodyEnd>
      <div className={classes.root}>
        <div className={classes.previewContainer}>
          <div className={classes.preview}>
            <h2>
              Mailing {mailingDate} - {mailing.campaignType}
            </h2>
            {!isSingleDesign ? (
              <nav>
                <a
                  onClick={handleFront}
                  className={`${frontActive ? classes.active : ''}`}
                >
                  Front
                </a>

                <a
                  onClick={handleBack}
                  className={`${backActive ? classes.active : ''}`}
                >
                  Back
                </a>
              </nav>
            ) : null}
            <div className={classes.imgPreview}>
              <Img
                src={`${
                  frontActive ? mailing.frontDesignUrl : mailing.backDesignUrl
                }`}
                loader={
                  <CircularProgress className={classes.loader} size={50} />
                }
              />
            </div>
          </div>

          <div className={classes.statusContainer}>
            <Close className={classes.closeBtn} onClick={handleClose} />
            <div className={classes.statusHeading}>
              <FormatAlignLeft className={classes.statusHeadingIcon} />
              <h4>
                Status:{' '}
                <span className={classes.status}>
                  {camelCaseToWords(mailing.status)}
                </span>
              </h4>
            </div>
            <div className={classes.statusBody}>
              {mailing.status === 'ordered' ? (
                <div className={classes.statusItem}>
                  This mailing design was ordered on{' '}
                  <strong>{formatDate(mailing.orderDate)}</strong>
                </div>
              ) : null}
              {mailing.status === 'approved' ? (
                <div className={classes.statusItem}>
                  This mailing design was approved by{' '}
                  <strong>{mailing.approvedBy}</strong>
                </div>
              ) : null}
              {mailing.status === 'printed' ? (
                <div className={classes.statusItem}>
                  This mailing design was printed on{' '}
                  <strong>{mailing.printDate}</strong>
                </div>
              ) : null}
              {mailing.status === 'shipped' ? (
                <div className={classes.statusItem}>
                  This mailing design was shipped on{' '}
                  <strong>{mailing.shipmentDate}</strong>
                </div>
              ) : null}
              {mailing.status === 'delivered' ||
              mailing.status === 'deliveredInFirstCall' ? (
                <div className={classes.statusItem}>
                  This mailing design was approved by{' '}
                  <strong>{mailing.approvedBy}</strong> and delivered on{' '}
                  <strong>{formatDate(mailing.deliveryDate)}</strong>
                </div>
              ) : null}
              {!mailing.deliveryDate?.length ? (
                <div className={classes.emptyBody}>
                  <ErrorOutline className={classes.emptyIcon} />
                  <p>
                    <strong>Need to request design changes?</strong>
                  </p>
                  <p>
                    Email us at{' '}
                    <a href="mailto:mvpmailhouse@gmail.com">
                      mvpmailhouse@gmail.com
                    </a>
                    <br />
                    We are happy to help tailor-fit your mailings to meet your
                    personal preferences.
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </BodyEnd>
  )
}

export default observer(MailingDesignPreview)
