import React, { useState } from 'react'
import { Select, MenuItem, FormControl, makeStyles } from '@material-ui/core'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '&>div': {
      marginTop: 0,
    },
  },
  placeholder: {
    position: 'absolute',
    fontSize: 14.4,
    top: 12,
    zIndex: 1,
    marginLeft: 16,
  },
  select: {
    zIndex: 2,
    background: 'transparent',
  },
}))

interface DropdownProps {
  options: string[]
  placeholder?: string
  onChange: (event: React.ChangeEvent<any>) => void
  value?: string
  name: string
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  placeholder,
  onChange,
  value,
  name,
}) => {
  const classes = useStyles()
  return (
    <FormControl
      classes={{
        root: classes.formControl,
      }}
    >
      {_.isEmpty(value) ? (
        <label className={classes.placeholder}>{placeholder}</label>
      ) : null}
      <Select
        classes={{
          root: classes.select,
        }}
        name={name}
        labelId="dropdown-label"
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default Dropdown
