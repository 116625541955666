/**
 * ******************************************
 * Originally designed to be used in an AppBar.
 * If you have a more customized design for your use case.
 * Build your own and practice component composition.
 * ******************************************
 */
import React from 'react'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, Select as MuiSelect, MenuItem } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

// Util
import { observer } from 'mobx-react'

// My util
import { useStores } from 'config/store'

// My component
import { CampaignFilterType } from 'config/store/GlobalFilterStore'

const CampaignTypes: [CampaignFilterType, string][] = [
  ['all', 'All Campaigns'],
  ['mvp-mail', 'Mailing Campaigns'],
  ['non-mailing', 'Non-Mailing Campaigns'],
]

const CampaignFilterTypeSelect = () => {
  const classes = useStyles()

  const { globalFilterStore } = useStores()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    globalFilterStore.campaignFilter.setCampaignFilterType(
      event.target.value as CampaignFilterType
    )
  }

  return (
    <FormControl variant="outlined" className={classes.root} size="small">
      <MuiSelect
        value={globalFilterStore.campaignFilter.campaignFilterType}
        onChange={handleChange}
        className={classes.select}
        IconComponent={ExpandMore}
      >
        {CampaignTypes.map((item) => (
          <MenuItem key={`campaign-type-${item[0]}`} value={item[0]}>
            {item[1]}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}

export default observer(CampaignFilterTypeSelect)

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  select: {
    fontSize: '.9rem',
    minWidth: '10rem',
  },
}))
