import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'constants/constants'

export const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    '& tr td:first-child': {
      width: '35%',
    },
    '& td': {
      border: 'solid 1px #eee',
      padding: '10px 15px',
    },
  },
  mobileTable: {
    '& tr td:first-child': {
      width: '46%',
    },
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
    color: COLORS.neutral.shade300,
    marginRight: 8,
    marginBottom: -4,
  },
  label: {
    fontSize: '14px',
    fontWeight: 500,
    color: COLORS.neutral.shade500,
    marginRight: 4,
  },
  text: {
    flex: '1',
    fontWeight: 400,
    fontSize: '13px',
    color: COLORS.neutral.shade500,
  },
}))
