import { observable, computed, action } from 'mobx'
import moment from 'moment'
import { repository as Mailings } from './Mailing'
import Repository from './Repository'

/**
 *
 */
class MailingZoneRepository extends Repository {

  /**
   *
   * @param {String} geodataId
   * @returns {any}
   */
  findByGeodataId(geodataId) {
    for (let zone of this.objects.values()) {
      if (zone.geodataId === geodataId) {
        return zone
      }
    }
    return undefined
  }
}

export const repository = new MailingZoneRepository()

/**
 *
 */
export default class MailingZone {

  @observable isLoading = false
  @observable isError = false

  @observable id
  @observable name
  @observable mailingAreaId
  @observable quantity
  @observable color
  @observable listProviderUrl
  @observable geodataId
  @observable carrierRoutes = []
  @observable mailings = []

  @computed get totalAppointments() {
    let total = 0
    this.mailings.forEach(mailingId => {
      const mailing = Mailings.get(mailingId)
      if (mailing) {
        total += mailing.appointmentsCount
      }
    })
    return total
  }

  @computed get totalLeads() {
    let total = 0
    this.mailings.forEach(mailingId => {
      const mailing = Mailings.get(mailingId)
      if (mailing) {
        total += mailing.leadsCount
      }
    })
    return total
  }

  @computed get conversionRate() {
    if (!this.totalLeads) {
      return 0
    }
    return this.totalAppointments / this.totalLeads
  }

  /**
   *
   */
  @computed get targetDeliveryDates() {
    return this.mailings.reduce((prev, mailingId) => {
      /** @var {Mailing} mailing */
      const mailing = Mailings.get(mailingId)
      if (mailing && 'string' === typeof mailing.deliveryWeek) {
        if (!prev[0]) {
          prev[0] = mailing.deliveryWeek
        }
        if (!prev[1]) {
          prev[1] = mailing.deliveryWeek
        }

        if (String(mailing.deliveryWeek).localeCompare(prev[0]) === -1) {
          prev[0] = mailing.deliveryWeek
        }

        if (String(mailing.deliveryWeek).localeCompare(prev[1]) === 1) {
          prev[1] = mailing.deliveryWeek
        }
      }
      return prev
    }, [null, null])
  }
}