import { createStyles } from '@material-ui/core'

import referralBg from 'assets/img/ads/referral/referralAd-bg.svg'

const styles = createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#FFF',
    height: '100vh',
    fontSize: '4rem',
    color: '#FADCE5',
    backgroundImage: `url(${referralBg})`,
    backgroundPosition: 'bottom 105% right 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    ['@media (max-width:1024px)']: {
      display: 'none',
    }
  },
  header: {
    color: 'rgb(20, 20, 20)',
    fontStyle: 'italic',
    fontWeight: 'bold',
    borderBottom: '2px solid #000',
    lineHeight: '.9',
    fontSize: '2.9rem',
    marginBottom: '4.1rem',
    fontFamily: 'Roboto',
  },
  image: {
    width: '34rem',
    marginBottom: '3.2rem',
    marginRight: '1rem',
    transition: 'transform .3s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  action: {
    border: '1px solid #ED154E',
    color: '#ED154E',
    width: '20rem',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    padding: 8,
    marginBottom: '1.7rem',
    marginRight: '.5rem',
  },
  url: {
    fontSize: '1rem',
    color: '#929292',
    marginBottom: '1.5rem',
    '&:hover': {
      color: '#ED154E',
    },
  },
})


export default styles
