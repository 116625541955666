import { flow } from 'mobx'
import axios from 'axios'
import _get from 'lodash/get'
import getPatientList from './../../../services/patientList/getPatientList'
import mapsStore from '../MapsStore'
import { repository as patientListRepo } from 'src/models/PatientList'
import { repository as householdRepo } from 'src/models/Household'
import HouseholdMarker, { repository as markerRepo } from 'src/models/PatientListLayer/HouseholdMarker'
import PatientListLayer, { repository as layerRepo } from './../../../models/PatientListLayer'


const refreshPatientLists = flow(
  function* (accounts) {
    const cancelToken = mapsStore.cancelToken = axios.CancelToken.source()
    const ids = yield getPatientList(accounts, { cancelToken })
    mapsStore.patientLists.replace(ids)

    const layers = processLayers(ids)
    mapsStore.patientListLayers.replace(layers)
  }
)

const processLayers = patientLists => {
  return patientLists.map(id => {
    const patientList = patientListRepo.get(id)
    if (!patientList) {
      return
    }

    const households = (_get(patientList, 'households', []) || []).slice()
    const markers = households.map(id => {
      const household = householdRepo.get(id)
      const marker = new HouseholdMarker()
      marker.id = household.id
      marker.lat = household.lat
      marker.lng = household.lng
      return markerRepo.upsert(marker)
    })

    const layer = new PatientListLayer()
    layer.id = patientList.id
    layer.visible = false
    layer.icon = patientList.icon
    layer.markers.replace(markers)

    return layerRepo.upsert(layer)
  })
}

export default refreshPatientLists
