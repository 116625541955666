import { defaultFont } from './index'

// Theming for navbars/menu items
export default theme => ({
  MuiTypography: {
    root: {
      ...defaultFont
    },

    h6: {
      ...defaultFont
    }
  },
})
